import React, { useState } from "react";
import Modal from "../modal";
import Button from "../button";
import "./index.scss";
import NewCountButton from "../newCount-Button";
import Toaster from "../toaster";
import { LocalStorage } from "../../utils/storage";
import Rating from "../rating";
import { exportFeedback, feedback } from "../../actions/feedback";
import Input from "../input";
import back from "../../assets/images/goback.svg";
import { useHistory } from "react-router-dom";

const FeedbackPopup = (props) => {
  const { data, CartShow, handleBack, itemData ,setToaster, hadleGetFeedback} = props;
  const [count, setCount] = useState([]);
  const [feedbackDta, setFeedback] = useState([]);
  const [comment, setComment] = useState("");
  const [userComment, setUserComment] = useState("");
  const [isMsg, setIsMsg] = useState({
    toastType: false,
    toastText: "",
    isShow: false,
  });

  let arr = [];
  let dataItem = {};
  const rate = (id, item, name) => {
    dataItem = {
      fb_ques_id: id,
      fb_ques: name,
      guest_ans: item,
      ans_wt: item,
    };

    setCount((count) => ({ ...count, [id]: item }));
    arr.push(dataItem);
    let feedbackDtaCpy = [...feedbackDta];
    const findIndex = feedbackDtaCpy.findIndex((data)=>data.fb_ques_id === id);
    if(findIndex >=0){
      feedbackDtaCpy[findIndex] = dataItem
      setFeedback(feedbackDtaCpy);
    }else{
      setFeedback((feedbackDta) => [...feedbackDta, dataItem]);
    }
  };

  const sendFeedback = () => {
    const payload = {
      feedback_rating: feedbackDta[0].ans_wt,
      fb_comments: comment,
      responseInfo: feedbackDta,
    };
    const queryParams = LocalStorage.get("feedbackId");
    // setIsMsg({
    //   toastType: true,
    //   toastText: "Feedback Submitted",
    //   isShow: true,
    // });

    exportFeedback(
      payload,
      (res) => {
        if (res && res.content && res.response.status === 200) {
            setToaster({
              toastType: true,
              toastText: res.response.message,
              isShow: true,
            });
            CartShow();
            hadleGetFeedback()
        }
      },
      queryParams
    );
  };

  const { innerWidth } = window;
  const product = LocalStorage.get("pastOrder");

  const handleDisble =()=>{
    let isDisbled = false;
    if(feedbackDta.length !== data.length){
       isDisbled  = true
    }else{
      isDisbled = false
    }
     return isDisbled
  }

  return (
    <>
      {innerWidth <= 1023 ? (
        <Modal
          modalCustomClass="modalCustomFeedback"
          onCloseHandler={CartShow}
          isMobileFullLayout
        >
          <div className="modalCustomFeedback_modalData">
            <div className="modalCustomFeedback_modalData_headerWrapper">
              <img
                src={back}
                className="headerWrapper_img"
                onClick={() => CartShow()}
                alt="goback"
              />
              <h2 className="headerWrapper_headLabel">Rate your order</h2>
            </div>
            <div className="modalCustomFeedback_modalData_divider" />
            <div className="modalCustomFeedback_modalData_wholeWrapper">
              {/* <div className="modalCustomFeedback_modalData_bodySection">
                <div className="bodySection">
                  <p className="bodySection_heading">Item</p>
                  <div className="bodySection_heading_wholeWrapper">
                    <div className="section">
                      <div className="wholeWrapper_subSection">
                        <p
                          className={`${
                            product?.cart_items[0].is_veg === "true"
                              ? `wholeWrapper_subSection_veg`
                              : `wholeWrapper_subSection_nonVeg`
                          }`}
                        ></p>
                        <p className="wholeWrapper_subSection_name">
                          {itemData}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modalCustomFeedback_modalData_divider" /> */}
              {data &&
                data.map((item) => {
                  return (
                    <div className="modalCustomFeedback_modalData_question">
                      <p className="modalCustomFeedback_modalData_question_nameLabel">
                        {item?.ques}
                      </p>
                      <Rating
                        items={item?.options}
                        id={item.quesId}
                        data={data}
                        count={count}
                        rate={rate}
                        name={item.ques}
                      />
                    </div>
                  );
                })}
              <h1 className="modalCustomFeedback_modalData_comment">
                Share your Experience with BBK Order?
              </h1>
              <Input
                type="text"
                onChange={(id, value) => {
                  setComment(value);
                }}
                value={comment}
                customClass="modalCustomFeedback_modalData_input"
                placeholder="Write your comments"
              />
              <div className="modalCustomFeedback_modalData_btnDiv">
                <Button
                  className="modalCustomFeedback_modalData_btnDiv_cancelBtn"
                  onClick={() => CartShow()}
                  content="Cancel"
                />
                <Button
                  onClick={sendFeedback}
                  className="modalCustomFeedback_modalData_btnDiv_doneBtn"
                  content="Done"
                />
              </div>
            </div>
          </div>
          {isMsg.isShow && (
            <Toaster
              toastType={isMsg.toastType}
              toastText={isMsg.toastText}
              closeToast={() =>
                setIsMsg({
                  isShow: false,
                })
              }
            />
          )}
        </Modal>
      ) : (
        <>
          <Modal
            modalCustomClass="modalCustomFeedback"
            onCloseHandler={CartShow}
            isMobileFullLayout
          >
            <div className="modalCustomFeedback_modalData">
              <p className="modalCustomFeedback_modalData_headLabel">
                Add Review
              </p>
              <div className="modalCustomFeedback_modalData_divider" />
              {data &&
                data.map((item) => {
                  return (
                    <div className="modalCustomFeedback_modalData_question">
                      <p className="modalCustomFeedback_modalData_question_nameLabel">
                        {item?.ques}
                      </p>
                      <Rating
                        items={item?.options}
                        id={item.quesId}
                        data={data}
                        count={count}
                        rate={rate}
                        name={item.ques}
                      />
                    </div>
                  );
                })}
              <h1 className="modalCustomFeedback_modalData_comment">
                Share your Experience with BBK Order?
              </h1>
              <Input
                type="text"
                onChange={(id, value) => {
                  setComment(value);
                }}
                value={comment}
                customClass="modalCustomFeedback_modalData_input"
                placeholder="Write your comments"
              />
              <div className="modalCustomFeedback_modalData_btnDiv">
                <Button
                  className="modalCustomFeedback_modalData_btnDiv_cancelBtn"
                  onClick={() => CartShow()}
                  content="Cancel"
                />
                <Button
                  onClick={sendFeedback}
                  className="modalCustomFeedback_modalData_btnDiv_doneBtn"
                  content="Done"
                  disabled={handleDisble()}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
export default FeedbackPopup;
