import "./index.scss";
import React, { useState, Component, useEffect } from "react";
import PropTypes from "prop-types";
import AlertPopup from "../../components/alert-popup";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
import ORDERRATE from "../../assets/images/order-rate.svg";
import Discount from "../../assets/images/discount_img.png";
import Button from "../../components/button";
import CountButton from "../../components/count-button";
import Modal from "../../components/modal";
import ProductDetail from "../productDetail";
import AddOnCheck from "../addOnCheck";
import Spice from "../../assets/images/spice.svg";
import VideoButton from "../../components/video-button";
import AddToCart from "../../components/addToCart-popup";
import NewButton from "../../components/newCount-Button";
import { LocalStorage } from "../../utils/storage";
import Cart from "../cart-PopUp";
import { Analytics } from "../../helpers/analytics";
import {
  handleIsShowStrikeAmt,
  isAddonsAvailble,
} from "../../helpers/sharedFunction";
import ImgNotAvailable from "../../assets/images/image-not-available.jpg";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
import DistanceCategoryInfo from "../../components/DistanceCategoryInfo";

const RecommendedCard = (props) => {
  const {
    content,
    preparation,
    onClickModal,
    onShowModal,
    // cartShow,
    id,
    onUpdateContent,
    netcoreEvent,
    addonPopup,
    isNegate,
    change,
    setIsNegate,
    handleAlertModal,
    addNewProduct,
    setLoderById,
    loaderById,
    homeData,
    addNewProductPage
  } = props;
  const [modalShow, setModalShow] = useState(false);
  const [extras, setExtras] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [addSubCount, setAddSubCount] = useState(0);
  const [itemData, setItemData] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isDistanceCategory,setIsDistanceCategory] = useState();
  const [distanceCategory, setDistanceCategory] = useState(5);

  let arr = [];
  let cartArr = [];
  const { innerWidth } = window;
  const onItemCheck = () => {
    setIsChecked(!isChecked);
  };
  let str;
  const history = useHistory();
  // let countItem = 0;
  // let cart = LocalStorage.get('CartProducts')
  // if(change) {
  //   cart && cart.length > 0 && cart.map((i) => {
  //     if (i.item_id === content.item_id) {
  //       countItem = countItem + 1
  //       // content.count = i.count+item.count
  //     }

  //   })

  // }
  // console.log("recommmmmonded", JSON.stringify(content));

  let cartLocal = LocalStorage.get("CartProducts");

  // cartLocal && cartLocal.length > 0 && cartLocal.map((i) => {
  //   if (i.item_id === content.item_id) {
  //     str = i
  //   }
  // })

  const onDataChecked = (item) => {
    const updateExtra = extras.map((items) => {
      items.items.map((itm) => {
        if (itm.item_id === item.item_id) {
          itm.checked = !item.checked;
        }
        return itm;
      });
      return items;
    });
    setExtras(updateExtra);
  };

  const subCount = (item, value) => {
    const copyItem = { ...item };
    cartLocal &&
      cartLocal.length > 0 &&
      cartLocal.map((i) => {
        if (i.item_id === item.item_id && !i.complimentary_item) {
          item = i;
          // item.groups = copyItem.groups
        }
      });
    // content.count = 0;
    if (value === "add" || value === "nocontent") {
      if (item.count === 0 && item._id) {
        delete item._id;
      }

      //item not having addons
      // let isAddonAvailable = false;
      // item.groups &&
      //   item.groups.map((grp) => {
      //     if (grp.items && grp.items.length > 0) {
      //       isAddonAvailable = true;
      //     }
      //   });
      const addonsAvailble = homeData && isAddonsAvailble(homeData, item);

      if (!addonsAvailble && item.count >= 1) {
        content.count = item.count + 1;
        item.count = item.count + 1;
        setLoderById((prevState) => ({
          ...prevState,
          [item.item_id]: true,
        }));
        Analytics.addToCardEvent(item, true);
        onUpdateContent(item, [], true);
        return;
      }

      setIsNegate(true);
      value === "add" && addonPopup(item, true);
      LocalStorage.set("addId", item?._id);
      // cartLocal && cartLocal.length > 0 && cartLocal.map((i) => {
      //   if (i.item_id === item.item_id){
      //     content.count = i.count;
      //   }
      // })
      // item.count = item.count + 1;
      // item._id = item._id;
      // LocalStorage.set('addId', item._id);
    } else {
      if (item.count > 0) {
        //item having multiple addongs
        const isDublicateElement = isFindDuplicateElement(item);
        if (isDublicateElement) {
          handleAlertModal();
          return;
        }
        setIsNegate(true);
        content.count = item.count - 1;
        item.count = item.count - 1;
        LocalStorage.set("addId", item._id);
        LocalStorage.set("addUp", false);
        Analytics.removeFromCartEvent(item);
        setLoderById((prevState) => ({
          ...prevState,
          [item.item_id]: true,
        }));

        onUpdateContent(item, [], true);
      } else {
        item.count = 0;
        onUpdateContent(item);
      }
      // content.count = 0;
    }

    // // item.count = 0;
    // // Analytics.logEventWithVariable(
    // //   Analytics.Order.productView,
    // //   JSON.stringify(content)
    // // );
    // if (value === "nocontent") {
    //   // onShowModal(true);
    //   setModalShow(true);
    //   setExtras(item.groups);
    //   item.count = item.count + 1;
    // }
    // if (value === "add") {
    //   //  
    //   addonPopup(item);
    //   LocalStorage.set('addId', item?._id);
    //   // item.count = item.count + 1;
    // } else {
    //   if (item.count > 0) {
    //     item.count = item.count - 1;
    //     LocalStorage.set('addId', item?._id);
    //     LocalStorage.set('addUp', false);
    //   } else {
    //     item.count = 0;
    //   }
    // }
    // setAddSubCount(item.count);
    // onUpdateContent(item, extras, modalShow);
    // // setAddSubCount(addSubCount - 1);
  };

  // const popKill = () => {

  // }

  const addCount = () => {
    //  
    setAddSubCount(addSubCount + 1);
    cartArr.push({ Quantity: addSubCount, "Food Id": id });
    LocalStorage.set("Food Details", cartArr);
  };
  const itemList = [
    {
      id: 0,
      item_name: "Extra",
      item_long_description: "",
      item_final_price: "",
      item_image_url: Spice,
    },
  ];

  const onDataUpdate = (item) => {
    onUpdateContent(item, extras, true);
  };

  const cartShow = () => {
    //  
    Analytics.logEventWithVariable(
      Analytics.Order.productView,
      JSON.stringify(content)
    );
    setModalShow(!modalShow);
    // handleAddProductModal()
    setExtras(content.groups);
    // content.count = content.count + 1;
    // for (let i = 0; i < content.groups.length; i++) {
    //   for (let j = 0; j < content.groups[i].items.length; j++) {
    //     content.groups[i].items[j].checked = false;
    //     arr.push(content.groups[i].items[j]);
    //   }
    // }
    // setExtras(arr);
  };

  const closeModal = () => {
    Analytics.logEventWithVariable(
      Analytics.Order.productView,
      JSON.stringify(content)
    );
    setModalShow(!modalShow);
    // setExtras(content.groups);
  };

  // let flag = false;
  // let cnt = 0;
  // let test;
  // cartLocal.map((itm) => {
  //   cartLocal.map((item) => {
  //     if (itm.item_id === item.item_id) {
  //       if(content.item_id == item.item_id) {
  //         cnt = cnt + 1;
  //         if (cnt > 1) {
  //           flag = true
  //           test = itm.count + item.count;
  //         }
  //       }
  //       else {
  //         flag = false
  //       }

  //     }
  //     else {
  //       flag = false
  //     }

  //   })
  // })

  // modalShow && localStorage.setItem("item_id", content.item_id);

  const isFindDuplicateElement = (data) => {
    let isDuplicate = false;
    const idArr = [];
    cartLocal &&
      cartLocal.map((item) => {
        if (data && data.item_id === item.item_id) {
          idArr.push(item.item_id);
        }
      });
    if (idArr.length >= 2) {
      isDuplicate = true;
    }
    return isDuplicate;
  };
  // console.log("card data",content,content.item_tags, content.item_tags.includes('3'));
  useEffect(() => {
    let localValue = LocalStorage.get("isDistanceCategory");
    if(localValue !== undefined) {
      setIsDistanceCategory(localValue);
    }
  },[]);

  return (
    <div className="item item-card">
      {innerWidth <= 1023 ? (
        <>
          <div className="card_descriptionData_space">
            <div>
              <div className="bestseller_div">
                <div
                  className={`item_descriptionContainer_type ${content?.item_tags.includes('2') && "nonVeg"
                    }`}
                />
                {content?.bestseller == "1" && (
                  <p className="item_bestseller">Bestseller</p>
                )}
              </div>
              <div className="card_descriptionData_item">
                <div className="card_descriptionContainer_price">
                  <p className="itemName">{content?.item_name}</p>
                </div>
                <p className="card_descriptionContainer_price">
                  {handleIsShowStrikeAmt(
                    content?.item_strike_through_price,
                    content?.item_final_price
                  ) && (
                      <span className="card_descriptionContainer_strike-price">
                        &#8377;{content?.item_strike_through_price}
                      </span>
                    )}
                  &#8377;{content?.item_final_price}
                </p>
                {content?.item_short_description?.length > 0 ? (
                  <p className={`card_descriptionData_descriptionLabel`}>
                    {showMore ? (
                      content?.item_short_description
                    ) : (
                      <p
                        className={`${!showMore && "card_descriptionData_three-dots"
                          }`}
                      >
                        {content?.item_short_description}
                      </p>
                    )}
                    {/* {content?.item_short_description} */}

                    <p
                      className="card_descriptionData_show-more"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? "Less" : "More"}
                    </p>
                  </p>
                ) : (
                  ""
                )}
                <div className="discount_text_wrapper">
                  {content?.discount_text && (
                    <>
                      <img src={Discount} className="item_descriptionData_discount_img" alt="Discount" />
                      <span className="item_descriptionData_discountlabel">
                        {content.discount_text}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="card_descriptionData">
              <img
                src={
                  content && content?.square_img && content?.square_img
                    ? content?.square_img
                    : ImgNotAvailable
                }
                className="card_imgContainer_img"
                alt="logo"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = ImgNotAvailable;
                }}
              />
              {
                isDistanceCategory && content.item_tags.includes('3') &&
                <DistanceCategoryInfo/>
              }
              <NewButton
                className="card_descriptionData_addBtn_just"
                head="nocontent"
                customSubIconClass="card_customSubIconClass"
                customAddIconClass="card_customAddIconClass"
                subCount={subCount}
                content={content?.count}
                cartShow={cartShow}
                id={id}
                item={content}
                change={true}
                addNewProduct={addNewProductPage}
                isLoading={loaderById[content?.item_id]}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={content?.count === 0 && `item_productWrapper`}
            onClick={() =>
              content?.count === 0 &&
              addNewProductPage(content)
            }
          >
            <div className="item_imgContainer distance_category_wrapper">
              <img
                src={
                  content && content?.item_image_url && content?.item_image_url
                    ? content?.item_image_url
                    : ImgNotAvailable
                }
                className="item_imgContainer_img"
                alt="logo"
                width="100%"
                height="200"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = ImgNotAvailable;
                }}
              />
              {content?.bestseller == "1" && (
                <p className="item_bestseller">Bestseller</p>
              )}
              <p className="item_imgContainer_preparation">{preparation}</p>
              {
                isDistanceCategory && content.item_tags.includes('3') &&
                <DistanceCategoryInfo/>
              }
            </div>
            <div className="item_descriptionContainer">
              <div>
                <div
                  className={`item_descriptionContainer_type ${content?.item_tags.includes('2') && "nonVeg"
                    }`}
                />
              </div>
              {innerWidth <= 1023 ? (
                <div className="wholeWrapper_item">
                  <div className="item_descriptionContainer_foodContainer">
                    <p className="itemName">{content?.item_name}</p>
                  </div>
                  <p className="item_descriptionContainer_price">
                    {handleIsShowStrikeAmt(
                      content?.item_strike_through_price,
                      content.item_final_price
                    ) && (
                        <span className="item_descriptionContainer_strike-price">
                          &#8377;{content?.item_strike_through_price}
                        </span>
                      )}
                    &#8377;{content?.item_final_price}
                  </p>
                </div>
              ) : (
                <>
                  <div className="item_descriptionContainer_foodContainer">
                    <p className="itemName">{content?.item_name}</p>
                  </div>
                  <p className="item_descriptionContainer_price">
                    {handleIsShowStrikeAmt(
                      content?.item_strike_through_price,
                      content?.item_final_price
                    ) && (
                        <span className="item_descriptionContainer_strike-price">
                          &#8377;{content?.item_strike_through_price}
                        </span>
                      )}
                    &#8377;{content?.item_final_price}
                  </p>
                </>
              )}
            </div>
            <div className="item_descriptionData">
              <p className="item_descriptionData_descriptionLabel">
                {content?.item_short_description}
              </p>
              <div className="item_descriptionData_orderRate">
                {!content?.no_of_times_ordered ||
                  Number(content?.no_of_times_ordered) === 0 ? (
                  ""
                ) : (
                  <>
                    {innerWidth <= 1023 ? (
                      <div className="item_descriptionData_orderRate_wholeWrapper">
                        <img
                          src={ORDERRATE}
                          className="item_descriptionData_orderRate_img"
                          alt="order_rate"
                        />
                      </div>
                    ) : (
                      <img
                        src={ORDERRATE}
                        className="item_descriptionData_orderRate_img"
                        alt="order_rate"
                      />
                    )}
                    <p className="item_descriptionData_orderRate_label">
                      {Number(content?.no_of_times_ordered)} People order in
                      last week
                    </p>
                  </>
                )}
              </div>
              <div className="discount_text_wrapper">
                {content?.discount_text && (
                  <>
                    <img src={Discount} className="item_descriptionData_discount_img" alt="discount" />
                    <span className="item_descriptionData_discountlabel">
                      {content.discount_text}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* {console.log("recommendedcard", content.count)} */}
          <div>
            <NewButton
              className="item_descriptionData_addBtn"
              head="nocontent"
              customSubIconClass="item_customSubIconClass"
              customAddIconClass="item_customAddIconClass"
              subCount={subCount}
              content={content?.count}
              cartShow={cartShow}
              id={id}
              item={content}
              change={true}
              addNewProduct={addNewProduct}
              isLoading={loaderById[content?.item_id]}
            />  
          </div>
        </>
      )}
    </div>
  );
};

export default RecommendedCard;
