import React from "react";
import "./index.scss";
import timeImg from "../../assets/images/time.svg";
import bottomnow from '../../assets/images/bottomnow.png';
import { LocalStorage } from "../../utils/storage";
const Option = (props) => {
  const { data, img, selectedOption, onChangeInput, customClassName, address, present } = props;
  const { innerWidth } = window;
  let locationData = LocalStorage.get('location');
  return (
    <div className={`option ${customClassName}`}>
      {img === "true" && <img src={timeImg} className="option_img" alt="img not loaded" />}
      {innerWidth <= 1023 ? (
        <div className="option_options" onClick={onChangeInput}>
          {selectedOption === "orderNow" ? "Order Now" : "Order Later"}
        </div>
      ) : (
        <div className="option_options" onClick={onChangeInput}>
          {
            locationData && locationData.lat === "" ? 'Change' : selectedOption === "orderNow" ? "Order Now" : "Order Later"
          }
        </div>
      )}

      {
        present ?
          ''
          :
          <img src={bottomnow} className='option_options1' alt="bottomnow" />

      }

      {/* <select id="cars" className="option_options" onChange={onChangeInput}> */}
      {/* <option className='option_options_opt' value='orderNow'>
          Order Now
        </option>
        <option className='option_options_opt' value='orderLater'>
          Order Later
        </option> */}
      {/* </select> */}
    </div>
  );
};
export default Option;
