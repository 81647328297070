import React from 'react';
import './index.scss';
import message from '../../assets/images/message.svg';
import Accept from '../../assets/images/detail.png';
import Call from '../../assets/images/contact.png';

const OutletDetails = (props) => {
  const { data } = props;

  return (
    <div className='outletDetails'>
      <div className='outletDetails_data'>
        <div className='outletDetails_data_main'>
          <div className='outletDetails_data_main_one'>
            <p className='outletDetails_data_main_one_name'>
              {data?.display_name}
            </p>
          </div>
          <div className='outletDetails_data_main_two'>
            <p className='outletDetails_data_main_two_sub'>{data?.address}</p>
          </div>
        </div>
        {/* <a href={`tel:${data?.communication?.communication_mobile}`}>
          <img
            src={Call}
            alt='call'
            className='outletDetails_data_message'
          />
        </a> */}
      </div>
    </div>
  );
};

export default OutletDetails;




// <img
// src={Call}
// alt='call'
// className='outletDetails_data_message'
// onClick={() => {
//   // console.log(
//     window.open(
//       `tel:${data?.communication?.communication_mobile}`,
//       '_system'
//     )
//   // );
// }}
// />
