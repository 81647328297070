import React from "react";
import "./index.scss";
import thunmbnail from "../../assets/images/thumbnail.png";
import videoPlay from "../../assets/images/videoPlay.svg";
const VideoCard = (props) => {
  const { data  , instruction_video} = props;
  const { innerWidth } = window;

  const videoUrl = instruction_video  && instruction_video[0] && 
  instruction_video[0].video_url
  ? instruction_video[0].video_url : 'https://www.youtube.com/embed/H6nE1Nla3u0';
  

  return (
    <div className="videoCard">
      <iframe
        className="videoCard_video"
        width="350"
        height="200"
        src={'https://www.youtube.com/embed/H6nE1Nla3u0'}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
};
export default VideoCard;
