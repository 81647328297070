import React, { useState } from "react";
import "./index.scss";
import Button from "../button";
import searchnotfound from "../../assets/images/searchnotfound.svg";
import ImgNotAvailable from "../../assets/images/image-not-available.jpg";

const SearchDropdown = (props) => {
  const { data, cartShow } = props;
  return (
    <div className="searchDrop">
      {data.length > 0 ? (
        data.map((item) => {
          return (
            <div
              id={item?.item_id}
              onClick={() => cartShow(item)}
              className="searchDrop_item"
            >
              <img src={item.item_image_url}
                onError={(e) => { e.target.onerror = null; e.target.src = ImgNotAvailable }}
                className="searchDrop_item_img"
                alt="ImgNotAvailable" />
              <p className="searchDrop_item_nameLabel">{item?.item_name}</p>
            </div>
          );
        })
      ) : (
        <div className="searchDrop_notFoundDiv">
          <img
            src={searchnotfound}
            className="searchDrop_notFoundDiv_notFound"
            alt="search not found"
          />
          <h1 className="searchDrop_notFoundDiv_nameLabel">No items found</h1>
        </div>
      )}
    </div>
  );
};
export default SearchDropdown;
