import './index.scss';
import React from 'react';

import PropTypes from 'prop-types';

import loader from '../../assets/images/loader.png';

const Loader = (props) => {
  const { fitContent, theme } = props;
  const dom = (
    <div className={`loader_wrapper ${fitContent} ${theme}`}>
      <div className={`loader ${fitContent} ${theme}`}>
        <img src={loader} alt='load-icon' width='100' height='100' />
      </div>
    </div>
  );
  return dom;
};

Loader.propTypes = {
  fitContent: PropTypes.string,
};

Loader.defaultProps = {
  fitContent: '',
};

export default Loader;
