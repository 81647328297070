import branch from "branch-sdk";

//pendinggg
  export const setIdentityBranch = (props) => {
    try {
      branch.setIdentity(props);
    } catch (error) {
      console.log('sent identity error', error);
    }
  };
  
  //done
  export const logOutIdentityBranch = () => {
    try {
      branch.logout();
    } catch (error) {
      console.log('sent logOutIdentityBranch error', error);
    }
  };

  //pemdinggg
  export const registrationBranch = (props) => {
    let params = {
      description: 'User Registration',
      customData: {
        registration_id: props,
      },
    };

    branch.logEvent('User Registration', params);
  };
  
  //pendinggg
  export const loginBranchEvent = (props) => {
    try {
      let params = {
        description: 'User Login',
        customData: {
          registration_id: props,
        },
      };
    
      branch.logEvent('User Login', params);
    } catch (error) {
      console.log('loginBranchEvent', error);
    }
  };
  
  //Done
  export const viewCartBranch = async (props) => {
    try {
      let buos = [];
      for (const item of props.cart) {
        let formUrl = `https://biryanibykilo.com/item/${item.name}/${item._id}`;
        let identifier = `item/${item?.name}/${item._id}`;
        let buo = await branch.createBranchUniversalObject(identifier, {
          canonicalUrl: formUrl,
          title: item?.name,
          contentMetadata: {
            quantity: parseInt(item?.item_count),
            price: parseInt(item.price),
            sku: item?._id,
            productName: item?.name,
          },
        });
        buos.push(buo);
      }
      let params = {
        description: 'View Cart',
        currency: 'INR',
        revenue: parseInt(props.totalamount),
        shipping: parseInt(props.deliverCharge),
        tax: parseInt(props.tax),
        customData: {
          store_name: props.storeName,
          store_city: props.storeCity,
          order_type: props.orderType,
        },
      };
  
      branch.logEvent('View Cart', params, buos);
    } catch (error) {
      console.log('add tocart error', error);
    }
  };

  // while selecting confirm address in checkout page //done
  export const addAddressInfoBranch = (props) => {
    let params = {
      description: 'Add Address Info',
      currency: 'INR',
      revenue: parseFloat(props.revenue).toFixed(2),
      shipping: parseFloat(props.shipping).toFixed(2),
      tax: parseFloat(props.tax).toFixed(2),
      customData: {
        store_name: props.storeName,
        store_city: props.storeCity,
        order_type: props.orderType,
      },
    };
    branch.logEvent('AddAddressInfo', params, []);
    console.log('AddAddressInfo', 'triggered');
  };
  
  // while selecting select paymnt method in checkout page //Done
  export const addPaymentInfoBranch = async (props) => {
    try {
      let params = {
        description: 'Add Payment Info',
        currency: 'INR',
        revenue: parseFloat(props.revenue).toFixed(2),
        shipping: parseFloat(props.shipping).toFixed(2),
        tax: parseFloat(props.tax).toFixed(2),
        customData: {
          store_name: props.storeName,
          store_city: props.storeCity,
          order_type: props.orderType,
        },
      };
  
      branch.logEvent("Add Payment Info", params, []);
      console.log('Add Payment Info', 'triggered');
    } catch (error) {
      console.log('Add Payment Info error', error);
    }
  };
  
  // while selecting place order (checkout API) in cart page // Done
  export const purchaseBranch = async (props) => {
    try {
      let buos = [];
      for (const item of props.cart) {
        let formUrl = `https://biryanibykilo.com/item/${item.name}/${item._id}`;
        let identifier = `item/${item.name}/${item._id}`;
        let buo = await branch.createBranchUniversalObject(identifier, {
          canonicalUrl: formUrl,
          title: item.name,
          contentMetadata: {
            quantity: parseInt(item.item_count),
            price: parseInt(item.price),
            sku: item._id,
            productName: item.name,
          },
        });
        buos.push(buo);
      }
      let params = {
        description: 'Purchase',
        transactionID: props.transactionId,
        currency: 'INR',
        revenue: parseFloat(props.revenue).toFixed(2),
        shipping: parseFloat(props.shipping).toFixed(2),
        tax: parseFloat(props.tax).toFixed(2),
        customData: {
          store_name: props.storeName,
          store_city: props.storeCity,
          order_type: props.orderType,
        },
      };
      branch.logEvent("Purchase", params, buos);
    } catch (error) {
      console.log('purchaseBranch erro', error);
    }
  };