import React, { useEffect } from 'react';
import Button from '../button';
import './index.scss';

const checkoutDetails = (props) => {
  const { header, info, show, onSignUp, onLoginClick } = props;
  const { innerWidth } = window;

  return (
    <div className='details'>
      {show ? (
        <div className='details_div'>
          <div className='details_header'>
            <p>{header}</p>
          </div>
          <div className='details_info'>
            <p>{info}</p>
          </div>
        </div>
      ) : (
        <div className='details_data'>
          {innerWidth <= 1023 ? (
            <>
              <div className='details_wholeWrapper'>
                <p className='details_phrase'>Have a account?&nbsp;</p>
                <Button
                  content='Log In'
                  className='details_login'
                  onClick={onLoginClick}
                  customClass='checkoutCustomClass'
                />
              </div>
              <p className='details_divider'></p>
            </>
          ) : (
            <Button
              content='Have a account?Log In'
              className='details_login'
              onClick={onLoginClick}
            />
          )}
          {/* {innerWidth <= 1023 ? (
            <div className='button_wholeWrapper'>
              <Button
                content='New to BBK?&nbsp;'
                onClick={onSignUp}
                className='button_signup'
              />
              <p className='button_phrase'>Sign up</p>
            </div>
          ) : (
            <Button content='New User? Sign Up' onClick={onSignUp} />
          )} */}
        </div>
      )}
    </div>
  );
};

export default checkoutDetails;
