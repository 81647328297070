import PropTypes from 'prop-types';
import { LocalStorage } from '../../utils/storage';


import Button from "../button";
import "./index.scss"

const AlertLocationChange = (props) => {
  const {
    changeLocationAlert,
    handleChangeLocation,
    handleAlertChangeLocation,
  } = props;

  const locationDetails = LocalStorage.get("location");

  return (
    <>
      {changeLocationAlert  && 
      locationDetails && locationDetails?.orderFor === "delivery" &&
      (
      <div className='location-change'>
        <div className='location-change_wrapper'>
          <div>
            <h6 className='location-change_message'>
            Your current location is different from the location selected by you. 
            <br/> Do you want to change the location?
            </h6>
            <div className='location-change_btn-wrapper'>
              <Button
                type='button'
                className='location-change_btn'
                content='Yes'
                onClick={() => handleChangeLocation()}
              />
              <Button
                type='button'
                className='location-change_btn'
                content='No'
                onClick={() => handleAlertChangeLocation()}
              />
            </div>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

AlertLocationChange.propTypes = {
    changeLocationAlert: PropTypes.bool,
    handleChangeLocation: PropTypes.func,
    handleAlertChangeLocation: PropTypes.func,
  };
  
  AlertLocationChange.defaultProps = {
    changeLocationAlert: false,
    handleChangeLocation: () => {},
    handleAlertChangeLocation: () => {},
  };
  

export default AlertLocationChange;
