import React from 'react';
import './index.scss';

const MediaCard = (props) => {
    const {data} = props;
    return (
        <div className='mediaCard'
        onClick={() => window.open(data?.site_url)}
        >
            <img 
            src={data?.image_url}
            className="mediaCard_img"
            alt="img"
            />
        </div>
    )
}
export default MediaCard;