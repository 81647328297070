import React, { isValidElement, useState } from 'react';
import './index.scss';
import ItemCheckBox from '../../components/item-checkbox';

const AddOnCheck = (props) => {
  const { headLabel, data, isChecked, onClickCheck, className,groupId } = props;
  return (
    <div className={`addOnCheck ${className}`}>
      {data && data.length > 0 && (
        <p className='addOnCheck_headLabel'>{headLabel}</p>
      )}
      {data &&
        data.map((item) => {
          return (
            <ItemCheckBox
              data={item}
              className='addOnCheck_list'
              key={item.id}
              onClickCheck={() => onClickCheck(item , groupId)}
              isChecked={item.checked}
            />
          );
        })}
    </div>
  );
};
export default AddOnCheck;
