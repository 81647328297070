const nameRegex = /^[a-zA-Z ]{1,50}$/;
const amountRegex = /^(?:0|[1-9]\d*)(?:\.(?!.*000)\d+)?$/
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const mobileRegex = /^([+][9][1]|[9][1]|[0]){0,1}([1-9]{1})([0-9]{9})$/

export const regex = {
    nameRegex,
    amountRegex,
    emailRegex,
    mobileRegex
}