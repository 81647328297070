/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from "react";
import Fingerprint2 from "fingerprintjs2";
import { Helmet } from "react-helmet";
import "./index.scss";
import $, { event } from "jquery";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import Dexie from "dexie";
import CategoryCard from "../../containers/category-card";
import ToggleSwitch from "../../components/toggle-switch";
import BbkMedia from "../../containers/bbk-media";
import BlogCard from "../../containers/blog-card";
import OffersCard from "../../components/offers-card";
import Subsription from "../../containers/subscription";
import Carousel from "react-multi-carousel";
import back from "../../assets/images/goback.svg";

import {
  getMenuList,
  getHomePage,
  handleSaveInterest,
} from "../../actions/home";
import { updateProfile } from "../../actions/profile";
import ProductCardlisting from "../../containers/productCardListing";
import Modal from "../../components/modal";
import "react-multi-carousel/lib/styles.css";
import HorizontalScroll from "../../components/horizontal-scroll";
import OfferPopup from "../../containers/offerPopup";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
import BrowseMenu from "../../assets/images/browse-menu.svg";
import Footer from "../../containers/footer";
import ItemDetailFooter from "../../containers/item-detail-footer";
import { addToCart, getCartItems } from "../../actions/cart";
import { getStaticPage } from "../../actions/staticpages";
import ImageCard from "../../components/image-card";
import Button from "../../components/button";
import { getProfile } from "../../actions/profile";
import { Analytics } from "../../helpers/analytics";
import HeaderMobile from "../../containers/header-mobile";
import LastOrderContain from "../../containers/last-order-contain";
import CreateAccount from "../../containers/createAccount";
import { sendOtp, verifyOtp, makeProfile } from "../../actions/login";
import Header from "../../containers/header";
import BasicDetails from "../../containers/basicDetails";
import OtpVerification from "../../containers/otpVerification";
import Switch from "../../components/switch";
import moment from "moment";
import RepeatCustomizationPopup from "../../components/repeat-customization-popup";
import AddToCart from "../../components/addToCart-popup";
//delivery
import RadioButton from "../../components/radioButton";
// import SearchAutoComplete from "../searchAutocomplete";
import SearchAutoComplete from "../../containers/searchAutocomplete";
import Detect from "../../assets/images/detectLoc.svg";
// import MapModal from "../mapModal";
import MapModal from "../../containers/mapModal";
import LocationSearch from "../../assets/images/homeLocation.svg";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { OutletMapping, getSkipLocation } from "../../actions/home";
import StoreCard from "../../components/store-card";
import AlertPopup from "../../components/alert-popup";
import IntervalDrop from "../../components/interval-dropdown";
import Cartsidebar from "../../containers/cartSidebar";
// import Spice from "../../assets/images/spice.svg";
import { orderList } from "../../actions/orders";
import MultipleOrderCard from "../../components/multiple-order-card";
import { Authentication } from "../../actions/authentication";
import Loader from "../../components/Loader";
import {
  handleGetTotalAmt,
  handleProductForGuestUser,
  handleIsLocationSelected,
  handleParseAmount,
} from "../../helpers/sharedFunction";
import righting1 from "../../assets/images/right.svg";
import {
  loginNetCoreEvent,
  categoryClickNetCoreEvent,
  signUpNetCoreEvent,
  netCoreOrderTypeSelection,
  newsLetterSubsribeNetCoreEvent,
} from "../../helpers/netcore";
import LocationImg from "../../assets/images/locationIcon.svg";
import Toaster from "../../components/toaster";
import SelectLocationAlert from "../../components/select-location-alert";
import downArrow from "../../assets/images/customize.svg";
import { homePageMeta } from "../../helpers/metaTitleDescription";
import loaderGif from "../../assets/images/Loader-Web-Gif.gif";
import { useLocation, useParams } from "react-router-dom";
import SavedAddress from "../../components/saved-address";
import { getSavedAddress } from "../../actions/home";
import {
  setIdentityBranch,
  loginBranchEvent,
  registrationBranch,
} from "../../utils/branch";
import { getDeliveryDistance, getLocationAvailable } from "../../actions/location";
import axios from "axios";
import searchImg from "../../assets/images/searchImg.png";
import RenderMaps from "../../containers/MapsCommonApi";
import remove from "../../assets/images/close.svg";
import fastdelivery from "../../assets/images/fast-delivery.png";
import takeaway_icon from "../../assets/images/take-away (1).png";
import TakeawayView from "../../components/takeawayview";

const ProductListing = (props) => {
  const urlparams = useLocation();
  const productStoredData = LocalStorage.get("CartProducts") || {};
  const urlValue = urlparams.pathname.split("/")[3];
  const urlCity = urlparams.pathname.split("/")[2];
  let takeaway = urlparams.pathname.split("/")[1];
  const [addressData, setAddressData] = useState([]);

  const { showButton, showLocation } = props;
  const Spice =
    "https://www.cubesnjuliennes.com/wp-content/uploads/2020/07/Chicken-Biryani-Recipe.jpg";
  const [addSubCount, setAddSubCount] = useState(0);
  const [homeData, setHomeData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [categoryValue, setCategoryValue] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [subscribe, setSubscribe] = useState([
    { name: "Subscribe", id: "subscribe", checked: false },
  ]);
  const [activeSubscribe, setActiveSubscribe] = useState("unsubscribe");
  const [itemTags, setItemTags] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [delTime, setDeliverytime] = useState({});
  const [blog, setBlog] = useState([]);
  const [lastOrder, setLastOrder] = useState({});
  const [currentData, setCurrent] = useState({});
  const [newDataMain, setNew] = useState([]);

  const [imageData, setImageData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [carousalMedia, setCarousalMedia] = useState([]);
  const [filter, setFilterData] = useState(false);
  const [showPopup, setShowPopup] = useState({});
  const history = useHistory();
  const [categoryModal, setCategoryModal] = useState(false);
  const [cartData, setCartData] = useState({ count: 0, price: 0, data: [] });
  const [bottomShow, setBottomShow] = useState(false);
  const [taxCharges, setTaxCharges] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [profileInfo, setProfileInfo] = useState({});
  const [imgProfile, setImgProfile] = useState("");
  const [categoryStatus, setCategoryStatus] = useState(false);
  const [loginStatus, setloginStatus] = useState(
    LocalStorage.get("isLoggedIn") || false
  );
  const [showLoader, setShowLoader] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  const [alertLocation, setAlertLocation] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const scrollToTop = () => {
    document
      .getElementsByClassName("stick-to-top")[0]
      .scrollIntoView({ top: 0, left: 0, behavior: "smooth" });
    setScroll(false);
  };

  //addons
  const [customizePopup, setCustomizePopup] = useState(false);
  const [customizeData, setCustomizeData] = useState({});
  const [productModal, setProductModal] = useState(false);
  const [productDataAdd, setProductDataAdd] = useState({});
  const [extras, setExtras] = useState([]);
  const [itemData2, setItemData2] = useState([]);
  const [isAddons, setIsAddons] = useState(false);
  const [addons, setAddons] = useState([]);

  const [isNegate, setIsNegate] = useState(false);
  //login
  const [stat, setStat] = useState("stage1");
  const [mobileNumber, setMobileNumber] = useState("");
  const [displayMobileNumber, setDisplayMobileNumber] = useState("");
  const [loginId, setLoginId] = useState("");
  const [errorTxt, setErrorTxt] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorTxt1, setErrorTxt1] = useState("");
  const [successTxt, setSuccessTxt] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [basicDetailsData, setBasicDetailsData] = useState();
  const [showCartSidebar, setShowCartSidebar] = useState(false);
  const [showScroll, setScroll] = useState(false);
  const scrollElements = useRef([]);
  const scrollToCategory = useRef(null);

  const [loginFlow, setLoginFLow] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [deliveryFlow, setDeliveryFlow] = useState(
    takeaway == "takeaway" ? true : false
  );
  const [isVideo, setIsVideo] = useState(false);
  const [addNewCartBtnLoader, setAddNewBtnLoader] = useState(false);
  const [multipleAddonAlert, setMultipleAddonAlert] = useState(false);
  const [loaderById, setLoderById] = useState({});
  const [outletLoader, setOutletLoader] = useState(false);
  const [interestModal, setInterestModal] = useState(false);
  const [isLoginBtnLoading, setLoginBtnLoading] = useState(false);
  const [otp, setOtp] = useState("");
  // const [activeMethod, setActiveMethod] = useState("delivery");
  // {
  //   LocalStorage.set("active-type", activeMethod);
  // }
  const storedActiveMethod = LocalStorage.get("location")?.orderFor
    ? LocalStorage.get("location")?.orderFor
    : localStorage.getItem("activeMethod") || "delivery";
  const [activeMethod, setActiveMethod] = useState(storedActiveMethod);
  const [toggleBestSeller, setToggleBestSeller] = useState([
    { tag_name: "Best Sellers", tag_id: "3", checked: false },
  ]);
  const [distanceCategory, setDistanceCategory] = useState(
    { tag_name: "within 45 min", tag_id: "4", checked: false }
  );
  const [isDistanceItemAvail, setIsDistanceItemAvail] = useState(false);


  const handleVideo = (data) => {
    setIsVideo(!isVideo);
  };

  const onItemCheck = () => {
    setIsChecked(!isChecked);
  };

  const onDataUpdate = (item) => {
    // debugger;
    // console.log("onDataUpdate", item);
    onUpdateContent(item, extras, undefined, true);
    // setProductModal(!productModal);
  };

  let cartArr = [];

  const itemList = [
    {
      id: 0,
      item_name: "Extra",
      item_long_description: "",
      item_final_price: "",
      item_image_url: Spice,
    },
  ];

  const onDataChecked = (item, groupId, extraData = extras) => {
    const updateExtra = extraData.map((items) => {
      items.items.map((itm) => {
        if (groupId === items.group_id && itm.item_id === item.item_id) {
          itm.checked = !item.checked;
          // itm.item_count = itemCount
        }
        return itm;
      });
      return items;
    });
    const checkedItems = [];

    const findExtraIndex = extraData.findIndex(
      (data) => data.group_id === groupId
    );

    updateExtra &&
      updateExtra[findExtraIndex] &&
      updateExtra[findExtraIndex].items &&
      updateExtra[findExtraIndex].items.map((item) => {
        if (item.checked) {
          checkedItems.push(item.checked);
        }
      });

    if (checkedItems.length == updateExtra[findExtraIndex].group_maximum) {
      updateExtra &&
        updateExtra[findExtraIndex] &&
        updateExtra[findExtraIndex].items &&
        updateExtra[findExtraIndex].items.forEach((item) => {
          if (!item.checked) {
            item.disabled = true;
          }
        });
    } else {
      updateExtra &&
        updateExtra[findExtraIndex] &&
        updateExtra[findExtraIndex].items &&
        updateExtra[findExtraIndex].items.forEach((item) => {
          item.disabled = false;
        });
    }
    const productData = productDataAdd;
    productData.groups = updateExtra;
    setProductDataAdd(productData);
    setExtras(updateExtra);
  };

  const cartShow = () => {
    setProductModal(!productModal);

    //
    Analytics.logEventWithVariable(
      Analytics.Order.productView,
      JSON.stringify(productDataAdd)
    );
  };

  //delivery
  const switchData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Takeaway" },
  ];

  const radio = [
    { id: "orderNow", name: "Order Now", checked: true },
    { id: "orderLater", name: "Order Later", checked: false },
  ];

  const responsiveRadio = [
    { id: "orderNow", name: "Now", checked: true },
    { id: "orderLater", name: "Later", checked: false },
  ];
  const [active, setActive] = useState(
    takeaway == "takeaway" ? "takeaway" : "delivery"
  );
  const [radioData, setRadioData] = useState(radio);
  const [responsiveRadioData, setResponsiveRadioData] =
    useState(responsiveRadio);
  const [activeOrder, setActiveOrder] = useState("orderNow");
  const [inputValue, setInputvalue] = useState({});
  const [popup, setPopup] = useState(false);
  const [inputData, setInputData] = useState("");
  const [guestLat, setGuestLat] = useState({});
  // const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [latLng, setLatLng] = useState({ lat: "", lng: "" });
  const [canShowLater, setCanShowLater] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [location, setLocation] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [alertLocationPopup, setAlertLocationPopup] = useState(false);
  const [data, setData] = useState([]);
  const [errorData1, setErrorData1] = useState(false);
  const [countData, setCountData] = useState({});
  const [alertData, setAlertData] = useState(false);
  const [dateData, setdateData] = useState(false);
  const [timeData, settimeData] = useState(false);
  const [modal, setModal] = useState(false);
  const [interval, setInterval] = useState("");
  const [intervalModal, setIntervalModal] = useState(false);
  const [intDate, setIntDate] = useState([]);
  const [updatedCartData, setUpdatedCartData] = useState([]);
  const [delpop, setDelPop] = useState(false);
  const [savedAddress, setSavedAddress] = useState();
  const [takeawayAlertModal, setTakeawayAlertModal] = useState("");

  const referProduct = useRef();

  let alertUpdate = "";
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const { innerWidth } = window;
  const outletData = LocalStorage.get("Outlet_Details");
  const outletId = LocalStorage.get("outlet_id");
  const guestFlowTaxCharges = LocalStorage.get("taxes");

  useEffect(() => {
    const isCategoryScroll = SessionStorage.get("isScrollToCategory");
    if (scrollToCategory.current && isCategoryScroll) {
      handleScrollToCategory();
      SessionStorage.remove("isScrollToCategory");
    }
  }, [scrollToCategory.current]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleSc = () => {
      const threshold = 200; // Adjust this value as needed
      const scrollY = window.scrollY || window.pageYOffset;
      setIsSticky(scrollY > threshold);
    };

    window.addEventListener("scroll", handleSc);

    return () => {
      window.removeEventListener("scroll", handleSc);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("activeMethod", activeMethod);
  }, [activeMethod]);

  const handleScroll = (event) => {
    let element = document.getElementById("categoryData");
    let stickyHeaderTop = $("#sticky")?.offset()?.top;

    if (stickyHeaderTop < 75) {
      if (element) {
        element.classList.add("sticky");
        setCategoryStatus(true);
      }
    } else {
      if (element) {
        element.classList.remove("sticky");
        setCategoryStatus(false);
      }
    }
  };

  const onSkipLocation = () => {
    setIsLoading(true);
    // if (!LocalStorage.get("AccessToken")) {
    Authentication((res) => {
      if (res && res.response && res.response.status === 200) {
        if (res && res.content && res.content) {
          LocalStorage.set("AccessToken", res.content.access_token);
          LocalStorage.set("RefreshToken", res.content.refresh_token);
          getSkipLocation((res) => {
            if (res && res?.response && res?.response?.status === 200) {
              LocalStorage.set("Outlet_Details", res.content);
              if (res.content.outlet_id) {
                getMenuList(
                  urlValue &&
                    urlValue?.length > 0 &&
                    res?.content?.outlet__city?.toLowerCase() !==
                    urlCity?.toLowerCase()
                    ? urlValue
                    : res.content.outlet_id,
                  (res) => {
                    if (res && res.content && res.response.status === 200) {
                      if (res.content && res?.content?.menu) {
                        if (LocalStorage.get("outlet_id")) {
                          const oldOutlet = LocalStorage.get("outlet_id");
                          if (oldOutlet !== res.content.menu.unit_id) {
                            if (LocalStorage.get("isLoggedIn")) {
                              onMenuDataSync(res.content.menu.categories);
                            } else {
                              LocalStorage.set("CartProducts", []);
                            }
                          }
                        }
                        LocalStorage.set("outlet_id", res.content.menu.unit_id);
                        const dataValue = {
                          orderFor: active,
                          orderTime: activeOrder,
                          deliveryTime: data.delivery_area_delivery_time,
                          address: inputData,
                          date: date,
                          time: time,
                          lat: latLng.lat,
                          lng: latLng.lng,
                        };
                        LocalStorage.set("location", dataValue);
                        const db = new Dexie(res.content.menu.unit_id);
                        db.version(1).stores({
                          menu: "++id, categories, item_tags, taxes",
                        });
                        const getMenuData = async () => {
                          const allData = (await db.menu.toArray()) || [];
                          if (allData && allData.length > 0) {
                            await db.menu.update(0, res.content.menu);
                          } else {
                            await db.menu.add(res.content.menu);
                          }
                          handleCategoryListData();
                          // history.push(ROUTES.HOME);
                        };
                        getMenuData();
                        setIsLoading(false);
                      }
                    } else {
                      // setAlertPopup(
                      //   "Sorry!! No Data Found. Please try again later sometime"
                      // );
                    }
                  }
                );
                getHomeBannerData(res.content.outlet_id);
              }
            }
          });
        }
      } else {
        setIsLoading(false);
      }
    });
    // }
  };

  useEffect(() => {
    let isToofani = false
    itemData.map(category => {
      category.items.map(item => {
        if (item.item_tags.includes('3')) {
          isToofani = true;
          return;
        }
      })
    })
    setIsDistanceItemAvail(isToofani);
    // console.log("isDistanceItemAvail",isDistanceItemAvail,itemData);
  }, [itemData]);
  const outletDetails = LocalStorage.get("Outlet_Details");

  const handleCategoryListData = () => {
    setIsLoading(true);
    const outletId = LocalStorage.get("outlet_id");

    getMenuList(
      urlValue &&
        urlValue?.length > 0 &&
        outletDetails?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
        ? urlValue
        : outletId,
      (res) => {

        if (res && res.content && res.response.status === 200) {
          if (res.content && res?.content?.menu) {
            Dexie.delete(outletId);
            const db = new Dexie(outletId);
            db.version(1).stores({
              menu: "++id, categories, item_tags, taxes",
            });
            const getMenuData = async () => {
              await db.menu.add(res.content.menu);
            };
            // let isToofani = false
            // res.content.menu.categories.map(category => {
            //   category.items.map(item => {
            //     if (item.item_tags.includes('3')) {
            //       isToofani = true;
            //       return;
            //     }
            //   })
            // })
            // setIsDistanceCategory(isToofani);
            getMenuData();
            // let itemId = localStorage.getItem('item_id');
            const getPosts = async () => {
              let allPosts = await db.menu.toArray();

              //         //new
              if (allPosts[0]?.categories?.length > 0) {
                updateitem1 = allPosts[0]?.categories.map((items) => {
                  let updateItem1 = [];
                  if (items.has_subcategory === "0") {
                    updateItem1 = items.items;
                  }
                  if (items.has_subcategory === "1") {
                    items.subcategories.map((item) => {
                      item.items.map((value) => {
                        updateItem1.push(value);
                      });
                    });
                    items.items = updateItem1;
                  }
                  items.itemData = updateItem1.map((item) => {
                    item.checked = false;
                    item.count = 0;
                    if (item.groups.length > 0) {
                      item.groups.map((val) => {
                        if (val.items.length > 0) {
                          val.items.map((groupVal) => {
                            groupVal.checked = false;
                            return groupVal;
                          });
                        }
                      });
                    }
                  });
                  items.vegData = updateItem1.filter((item) =>
                    item.item_tags.includes("1")
                  );
                  items.nonVeg = updateItem1.filter((item) =>
                    item.item_tags.includes("2")
                  );
                  return items;
                });
                let arr = [];
                updateitem1.map((item) => {
                  item?.items &&
                    item?.items.map((i) => {
                      arr.push(i);
                    });
                });
                setItemData2(arr);
              }
              if (allPosts && allPosts.length > 0) {
                setCategoryData(allPosts[0].categories);
                setCategoryValue(allPosts[0].categories);
                setTaxCharges(allPosts[0].taxes);
                LocalStorage.set("taxes", allPosts[0].taxes);
                if (allPosts[0].categories.length > 0) {
                  // setSelectedCategory(allPosts.categories);
                  setFilterData(false);
                  const updateitem = allPosts[0].categories.map((items) => {
                    let updateItem = [];
                    if (items.has_subcategory === "0") {
                      updateItem = items.items;
                    }
                    if (items.has_subcategory === "1") {
                      items.subcategories.map((item) => {
                        item.items.map((value) => {
                          updateItem.push(value);
                        });
                      });
                      items.items = updateItem;
                    }
                    items.itemData = updateItem.map((item) => {
                      item.checked = false;
                      item.categoryName = items.category_name;
                      item.count = 0;
                      if (item.groups.length > 0) {
                        item.groups.map((val) => {
                          if (val.items.length > 0) {
                            val.items.map((groupVal) => {
                              groupVal.checked = false;
                              return groupVal;
                            });
                          }
                        });
                      }
                      if (LocalStorage.get("CartProducts")) {
                        const productData = LocalStorage.get("CartProducts");
                        if (productData && productData.length > 0) {
                          productData.map((items) => {
                            if (items.item_id === item.item_id) {
                              item.count = items.count + item.count;
                              item._id = items._id;
                            }
                          });
                        }
                      }
                      return item;
                    });
                    items.vegData = updateItem.filter((item) =>
                      item.item_tags.includes("1")
                    );
                    items.nonVeg = updateItem.filter((item) =>
                      item.item_tags.includes("2")
                    );
                    items.bestSeller = updateItem.filter(
                      (item) => item.bestseller === "1"
                    );
                    return items;
                  });
                  setCategoryData(updateitem);
                  setCategoryValue(updateitem);

                  setItemData(updateitem);

                  // setItemData1(updateitem)
                  const itmTag = allPosts[0].item_tags.map((items) => {
                    items.checked = false;
                    return items;
                  });
                  const removeDistanceCategory = itmTag.filter((data) => {
                    if (data?.tag_id != '3') {
                      return data;
                    }
                  })
                  // setItemTags(itmTag);
                  setItemTags(removeDistanceCategory);
                }
                setHomeData(allPosts[0]);
              }
              setIsLoading(false);
            };
            getPosts();
            if (LocalStorage.get("isLoggedIn")) {
              if (LocalStorage.get("CartProducts")) {
                const updateData = LocalStorage.get("CartProducts");
                //ONLY GET API IS THERE
                onAddToCartData(updateData);
              }

              getCartItems(outlet, (res) => {
                if (res && res?.response?.status === 200) {
                  const data = onStructurize(res.content.items);
                  LocalStorage.set("CartProducts", data);
                  LocalStorage.set(
                    "CouponName",
                    res.content.billing_data.coupon_used
                  );
                  onCartDataValue(res.content);
                  // onCartDataValue(data);
                }
              });
            }
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
        // else {
        //   setAlertPopup("Sorry!! No Data Found. Please try again later sometime");
        // }
      }
    );
  };

  let updateitem1;
  useEffect(() => {
    branch.logEvent("customised testing data", "test");
    getSavedAddress((res) => {
      if (res.response?.status == 200) {
        setSavedAddress(res.content.address);
      }
    });

    window.addEventListener("mousewheel", handleScroll);
    window.addEventListener("mousewheel", handleScroll2);

    if (LocalStorage.get("profileImg")) {
      setImgProfile(LocalStorage.get("profileImg"));
    }

    getDeviceId();

    if (LocalStorage.get("UserData")) {
      getProfile((res) => {
        if (res && res?.response?.status === 200) {
          setProfileInfo(res?.content);

          if (
            res.content &&
            (res.content.is_register_on_rezol === false ||
              res.content.is_new_user === true)
          ) {
            setLoginFLow(true);
            setStat("stage3");
          }

          if (res.content.is_subscribed_to_newsletter) {
            setActiveSubscribe(res.content.is_subscribed_to_newsletter);
            subscribe.map((itm) => {
              itm.name = "Unsubscribe";
              itm.id = "unsubscribe";
              itm.checked = res.content.is_subscribed_to_newsletter;
              return itm;
            });
          }
          LocalStorage.set("profileImg", res?.content?.profile_picture);
          let newData = {
            dob: res?.content?.dob,
            email: res?.content?.email,
            mobile: res?.content?.mobile,
            name: res?.content?.first_name,
            profileInfo: res?.content?.profile_picture,
            _id: res?.content?._id,
            emailVerify:res?.content?.is_user_email_verified
          };
          LocalStorage.set("UserData", newData);
        }
      });

      //delivery
      if (latLng.lat === "" && latLng.lng === "") {
        setLocation(false);

        // onCurrentLocation();
      }
      let locationDetails = LocalStorage.get("location");
      let user1 = LocalStorage.get("UserData");

      // if (user1) {
      //  netCoreOrderTypeSelection()
      // }

      if (locationDetails) {
        setLocation(true);
        setLatLng({
          lat: locationDetails && locationDetails.lat,
          lng: locationDetails && locationDetails.lng,
        });
        // setActiveOrder(locationDetails && locationDetails.orderTime);
        // setActive(locationDetails && locationDetails.orderFor);
        setInputData(locationDetails && locationDetails.address);
        // setDate(locationDetails && locationDetails.date);
        // setTime(locationDetails && locationDetails.time);
      }
    }

    const query = "";

    getStaticPage((res) => {
      if (res && res?.response?.status === 200) {
        const root = document.getElementById("root");
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setFooterData(res && res?.content);
        LocalStorage.set('footerData',res?.content)
      }
      // else {
      //   onSkipLocation()
      // }
    }, query);

    if (LocalStorage.get("outlet_id")) {
      handleCategoryListData();
    }

    if (!LocalStorage.get("outlet_id")) {
      onSkipLocation();
    }
    let queryParams = LocalStorage.get("outlet_id");
    const dataDeliveryTime = LocalStorage.get("location") || {};
    setDeliverytime(dataDeliveryTime);
    if (LocalStorage.get("CartProducts")) {
      const productData = LocalStorage.get("CartProducts");
      if (productData && productData.length > 0) {
        const totalPrice = handleGetTotalAmt(productData);

        // let totalPrice = 0;
        // productData.map((items) => {
        //   totalPrice =
        //     parseFloat(items.item_final_price).toFixed(2) * items.count +
        //     totalPrice;
        // });

        const data = {
          count: productData.length,
          price: totalPrice,
          data: productData,
        };
        setCartData(data);
        console.log(data, "bottomShow");
        setBottomShow(true);
        // for (let i=0; i<productData?.length; i++) {
        //   temp = temp + productData[i]?.count;
        // }
      }
    }
    if (queryParams) {
      getHomeBannerData(queryParams);
    }

    let updateitem1;

    if (LocalStorage.get("outlet_id")) {
      const outlet = LocalStorage.get("outlet_id");
      const db = new Dexie(outlet);
      db.version(1).stores({
        menu: "++id, categories, item_tags, taxes",
      });
      // let itemId = localStorage.getItem('item_id');
      const getPosts = async () => {
        let allPosts = await db.menu.toArray();
        //         //new
        if (allPosts[0]?.categories?.length > 0) {
          updateitem1 = allPosts[0]?.categories.map((items) => {
            let updateItem1 = [];
            if (items.has_subcategory === "0") {
              updateItem1 = items.items;
            }
            if (items.has_subcategory === "1") {
              items.subcategories.map((item) => {
                item.items.map((value) => {
                  updateItem1.push(value);
                });
              });
              items.items = updateItem1;
            }
            items.itemData = updateItem1.map((item) => {
              item.checked = false;
              item.count = 0;
              if (item.groups.length > 0) {
                item.groups.map((val) => {
                  if (val.items.length > 0) {
                    val.items.map((groupVal) => {
                      groupVal.checked = false;
                      return groupVal;
                    });
                  }
                });
              }
              // if (LocalStorage.get("CartProducts")) {
              //   const productData = LocalStorage.get("CartProducts");
              //   if (productData && productData.length > 0) {
              //     productData.map((items) => {
              //       if (items.item_id === item.item_id) {
              //         item.count = items.count;
              //       }
              //     });
              //   }
              // }
              // return item;
            });
            items.vegData = updateItem1.filter((item) =>
              item.item_tags.includes("1")
            );
            items.nonVeg = updateItem1.filter((item) =>
              item.item_tags.includes("2")
            );
            items.bestSeller = updateItem1.filter(
              (item) => item.bestseller === "1"
            );
            return items;
          });
          let arr = [];
          updateitem1.map((item) => {
            item?.items &&
              item?.items.map((i) => {
                arr.push(i);
              });
          });
          setItemData2(arr);
          //   // for (let i=0; i <= updateitem?.length; i++) {
          //   //   // for (let j=0; updateitem[i]?.itemData?.length; j++) {
          //   //   //   for (let k=0; updateitem[i]?.itemData[j]?.length; k++) {
          //   //   //   }
          //   //   // }
          //   // }
        }
        if (allPosts && allPosts.length > 0) {
          setCategoryData(allPosts[0].categories);
          setCategoryValue(allPosts[0].categories);
          setTaxCharges(allPosts[0].taxes);
          LocalStorage.set("taxes", allPosts[0].taxes);
          if (allPosts[0].categories.length > 0) {
            // setSelectedCategory(allPosts.categories);
            setFilterData(false);
            const updateitem = allPosts[0].categories.map((items) => {
              let updateItem = [];
              if (items.has_subcategory === "0") {
                updateItem = items.items;
              }
              if (items.has_subcategory === "1") {
                items.subcategories.map((item) => {
                  item.items.map((value) => {
                    updateItem.push(value);
                  });
                });
                items.items = updateItem;
              }
              items.itemData = updateItem.map((item) => {
                item.checked = false;
                item.count = 0;
                if (item.groups.length > 0) {
                  item.groups.map((val) => {
                    if (val.items.length > 0) {
                      val.items.map((groupVal) => {
                        groupVal.checked = false;
                        return groupVal;
                      });
                    }
                  });
                }
                if (LocalStorage.get("CartProducts")) {
                  const productData = LocalStorage.get("CartProducts");
                  if (productData && productData.length > 0) {
                    productData.map((items) => {
                      if (items.item_id === item.item_id) {
                        item.count = items.count + item.count;
                        item._id = items._id;
                      }
                    });
                  }
                }
                return item;
              });
              items.vegData = updateItem.filter((item) =>
                item.item_tags.includes("1")
              );
              items.nonVeg = updateItem.filter((item) =>
                item.item_tags.includes("2")
              );
              items.bestSeller = updateItem.filter(
                (item) => item.bestseller === "1"
              );
              return items;
            });
            setCategoryData(updateitem);
            setCategoryValue(updateitem);

            setItemData(updateitem);

            // setItemData1(updateitem)
            const itmTag = allPosts[0].item_tags.map((items) => {
              items.checked = false;
              return items;
            });
            const removeDistanceCategory = itmTag.filter((data) => {
              if (data?.tag_id != '3') {
                return data;
              }
            })
            // setItemTags(itmTag);
            setItemTags(removeDistanceCategory);
            // setItemTags(itmTag);
          }
          setHomeData(allPosts[0]);
        }
      };
      getPosts();
      if (LocalStorage.get("isLoggedIn")) {
        if (LocalStorage.get("CartProducts")) {
          const updateData = LocalStorage.get("CartProducts");
          // console.log("cartdataaa", cartData.data[0]._id);

          // updateData &&
          // updateData.length > 0 &&
          // updateData.map((data) => {
          //   if(data._id !== cartData)
          // })
          // onAddToCart(updateData[0], undefined, undefined, true); ////
          //ONLY GET API IS THERE
          onAddToCartData(updateData);

          let payload = {};

          updateData &&
            updateData.map((value) => {
              let ontaxCal = [];
              (guestFlowTaxCharges ? guestFlowTaxCharges : taxCharges).map(
                (item) => {
                  value.item_taxes &&
                    value.item_taxes[0].taxes.map((values) => {
                      if (item.tax_id === values) {
                        const tax = {
                          tax_tags: values,
                          tax_value: Number(item.tax_value),
                          amount:
                            (item.tax_value * value.item_final_price) / 100,
                        };
                        ontaxCal.push(tax);
                      }
                    });
                }
              );

              let addarr = [];
              let addStr = "";
              value.addons &&
                value.addons.length > 0 &&
                value.addons.map((adds) => {
                  addarr.push(parseInt(adds.item_id));
                });

              addStr = addarr.toString();

              payload = {
                channel: LocalStorage.get("location").orderFor,
                outlet_id: outletId,
                delivery_area_id: outletData.delivery_area_id,
                purchase_detail_id: "",
                items: [
                  {
                    item_id: value.item_id,
                    item_count: value.count,
                    is_veg: value?.is_veg
                      ? value.is_veg
                      : value.item_tags.includes("1")
                        ? true
                        : false,
                    image_url: value.item_image_url,
                    price: value.single_item_price
                      ? value.single_item_price
                      : value.item_unit_price,
                    final_price: value.single_item_price
                      ? value.single_item_price
                      : value.item_final_price,
                    name: value.item_name,
                    discount: 0,
                    item_tags: ontaxCal,
                    addons: value.addons,
                    local_id: parseInt(value.item_id + addStr),
                    selected_addons: addarr,
                    category_name: value.categoryName,
                  },
                ],
                source: outletData.outlet_status_info,
              };
            });
        }
        getCartItems(outlet, (res) => {
          if (res && res?.response?.status === 200) {
            const data = onStructurize(res.content.items);
            LocalStorage.set("CartProducts", data);
            LocalStorage.set(
              "CouponName",
              res.content.billing_data.coupon_used
            );
            onCartDataValue(res.content);
          }
        });
      }
    }
  }, []);

  const getHomeBannerData = (queryParams) => {
    getHomePage(
      (res) => {
        if (res && res?.response?.status === 200) {
          setBlog(res.content?.blog_detail);
          setLastOrder(res?.content?.last_order);
          console.log(res?.content?.delivery_distance, "getHomePage");
          LocalStorage.set("minDeliveryDistance", res?.content?.delivery_distance);
          if (LocalStorage.get("isLoggedIn")) {
            // orderList((res) => {
            //   if (res && res?.content && res?.response?.status === 200) {
            //     if (res.content && res?.content) {
            setCurrent(
              res.content.past_orders &&
              res.content.past_orders.length > 0 &&
              res.content.past_orders[0]
            );
            // setData(res.content.past_orders);
            setNew(
              res?.content?.current_orders &&
              res?.content?.current_orders.length > 0 &&
              res?.content?.current_orders
            );
            //     }
            //   }
            // });
          }
          const largeWebData =
            res.content.banner.large &&
            res.content.banner.large.filter((item) => item.source === "web");
          const smalleWebData =
            res.content.banner.large &&
            res.content.banner.large.filter((item) => item.source === "app");
          const smallWebOfferData =
            res.content.banner.small &&
            res.content.banner.small.filter((item) => item.source === "app");
          const largeWebOfferData =
            res.content.banner.small &&
            res.content.banner.small.filter((item) => item.source === "web");
          if (innerWidth <= 1023) {
            setImageData(smallWebOfferData);
            setCarousalMedia(smalleWebData);
          } else {
            setImageData(largeWebOfferData);
            setCarousalMedia(largeWebData);
          }
          setOfferData(res.content?.offer_detail);
          setMediaData(res.content?.media_data);
        } else {
          onSkipLocation();
        }
      },
      urlValue &&
        urlValue?.length > 0 &&
        outletDetails?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
        ? urlValue
        : queryParams
    );
  };

  const addonPopup = (item, handler) => {
    setCustomizeData(item && item);

    setCustomizePopup(true);
    LocalStorage.set("addUp", false);
  };

  const repeatCustom = (item) => {
    setIsAddons(false);
    item.addons &&
      item.addons.length > 0 &&
      item.addons.map((i) => {
        i.item_count = parseInt(i.item_count) + 1;
      });
    LocalStorage.set("addId", item._id);
    item.count = parseInt(item.count) + 1;
    item._id = item._id;
    onUpdateContent(item, [], true);
    Analytics.addToCardEvent(item, true);
    addonPopup(item);
    setCustomizePopup(false);
  };

  const addNewProduct = (data) => {
    console.log("addnewproductttttttt", data);
    // history.push(ROUTES.ADDTOCART, { data: data });
    const isAddAvailble = handleIsLocationSelected();
    if (!isAddAvailble) {
      handleLocationAlertModal();
      return;
    }

    setIsAddons(true);
    setAddons(data.groups);
    LocalStorage.set("addUp", true);

    itemData2 &&
      itemData2.map((item) => {
        if (item.item_id === data.item_id) {
          setProductModal(!productModal);
          setProductDataAdd(item);
          setExtras(item.groups);
        }
      });
    setCustomizePopup(false);
    // history.push(ROUTES.ADDTOCART, { data: productModal });
  };

  const addNewProductPage = (data) => {
    const isAddAvailble = handleIsLocationSelected();
    if (isAddAvailble) {
      const locationDataValue = LocalStorage.get("location");
      if (savedAddress && savedAddress.length > 0) {
        const locationValue = savedAddress.find(
          (it) => it.Locality === locationDataValue.address
        );
        if (locationValue || (locationDataValue.lat && locationDataValue.lng)) {
          const updatedAddress = {
            ...locationDataValue,
            lat: locationValue?.guest_lat || locationDataValue.lat,
            lng: locationValue?.guest_long || locationDataValue.lng,
          };
          LocalStorage.set("location", updatedAddress);
          let payload = {
            latitude: updatedAddress.lat,
            longitude: updatedAddress.lng,
            is_delivery: active === "delivery" ? true : false,
            is_order_now: true,
            // activeOrder === "orderNow" ? true : false,
            order_date:
              activeOrder !== "orderNow" && date !== ""
                ? moment(date).format("YYYY-MM-DD")
                : "",
            order_time: activeOrder !== "orderNow" ? time : "",
            channel: "online_web",
          };

          OutletMapping(payload, (res) => {
            if (res && res?.response?.status === 200) {
              const outletData =
                res.content?.areas &&
                res.content?.areas[0] &&
                res.content?.areas[0];
              if (
                !outletData.is_open &&
                outletData.outlet_status_info.web_err_msg.includes(
                  "resume shortly"
                )
              ) {
                setAlertPopup(outletData.outlet_status_info.web_err_msg);
                setAlertLocationPopup(true);
                return;
              } else {
                const itemName = data.item_name
                  .replace(/\[[^\]]+\]/, "")
                  .trim()
                  .replace(/\s+/g, "-")
                  .replace(/&/g, "and")
                  .toLowerCase();
                history.push(ROUTES.ADDTOCART + "/" + itemName, {
                  data: data,
                  itemDataValue: itemData2,
                });
                setIsAddons(true);
                setAddons(data.groups);
                LocalStorage.set("addUp", true);

                itemData2 &&
                  itemData2.map((item) => {
                    if (item.item_id === data.item_id) {
                      setProductModal(!productModal);
                      setProductDataAdd(item);
                      setExtras(item.groups);
                    }
                  });
              }
            }
          });
        }
      }
    }
    if (!isAddAvailble) {
      handleLocationAlertModal();
      return;
    }

    // history.push(ROUTES.ADDTOCART, { data: productModal });
  };
  const handleClose = () => {
    setCustomizePopup(false);
  };

  const itemDataChange = (item_id, sizedItem) => {
    const filterData =
      itemData2 && itemData2.find((item) => item.item_id === item_id);
    if (filterData) {
      filterData.sized_item = sizedItem;
      filterData.duplicate = true;
      setProductDataAdd(filterData);
      setExtras(filterData.groups);
    }
  };

  const onAddToCartData = (data) => {
    const outletData = LocalStorage.get("Outlet_Details");
    const location = LocalStorage.get("location");
    const outletId = LocalStorage.get("outlet_id");
    let ontaxCal = [];

    let itemData = data.map((value) => {
      taxCharges &&
        taxCharges.length > 0 &&
        taxCharges?.map((item) => {
          value &&
            value?.item_taxes &&
            value?.item_taxes.length > 0 &&
            value?.item_taxes[0]?.taxes &&
            value?.item_taxes[0]?.taxes.map((values) => {
              if (item && item.tax_id === values) {
                const tax = {
                  tax_tags: values,
                  amount:
                    (item.tax_value *
                      (Number(parseFloat(value.item_final_price)) *
                        value.count)) /
                    100,
                };
                ontaxCal.push(tax);
              }
            });
        });
      const item = {
        item_id: value.item_id,
        item_count: value.count,
        is_veg: value.item_tags.includes("1") ? true : false,
        image_url: value.item_image_url,
        price: value.single_item_price
          ? value.single_item_price
          : value.item_unit_price,
        final_price: value.single_item_price
          ? value.single_item_price
          : value.item_final_price,
        name: value.item_name,
        discount: 0,
        item_tags: ontaxCal,
        addons: value.addons,
      };
      return item;
    });
    const itemDataValue = data.map((value) => {
      const item = {
        prid: value.item_id,
        prqt: parseInt(value.count),
        is_veg: value.item_tags.includes("1") ? "true" : "false",
        image: value.item_image_url,
        produrl: value.item_image_url,
        price: value.single_item_price
          ? Number(parseFloat(value.single_item_price).toFixed(2))
          : Number(parseFloat(value.item_final_price).toFixed(2)),
        name: value.item_name,
        discount: 0,
        addons: LocalStorage.get("Addons") && LocalStorage.get("Addons"),
      };
      return item;
    });

    const user = LocalStorage.get("UserData");
    // const script = document.createElement("script");
    // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
    // script.onload = () => {
    //   smartech("identify", "91"+user.mobile);
    //   smartech("dispatch", "Add To Cart", { items: itemDataValue });
    // };
    // document.head.appendChild(script);

    const payload = {
      channel: location.orderFor,
      outlet_id: outletId,
      items: itemData,
      source: outletData?.outlet_status_info,
    };
  };

  const onCartDataValue = (productData) => {
    if (productData && productData.items && productData.items.length > 0) {
      let totalPrice = 0;
      let Taxes = { cgst: 0, sgst: 0 };
      let cgst = 0;
      let sgst = 0;

      totalPrice = productData.billing_data.sub_total;

      const data = {
        count: productData.items.length,
        price: Math.floor(totalPrice),
        data: productData.items,
        cgst: Number(productData.billing_data.cgst).toFixed(2),
        sgst: Number(productData.billing_data.sgst).toFixed(2),
      };
      setCartData(data);
      console.log(data, "bottomShow1");
      setBottomShow(true);
    }
  };

  const onStructurize = (data) => {
    if (data && data.length > 0) {
      const dataItem = data.map((items) => {
        const item = {
          _id: items._id,
          item_name: items.name,
          item_final_price: items.single_item_price,
          item_tags: items.item_tags,
          count: Number(items.item_count),
          item_id: items.item_id,
          item_image_url: items.image_url,
          item_unit_price: items.single_item_price,
          addons: items.addons,
          complimentary_item: items.complimentary_item,
          is_veg: items.is_veg,
          _id: items._id,
          categoryName: items?.category_name,
        };
        return item;
      });
      return dataItem;
    }
    return [];
  };

  // const data = {
  //   label: "Hyderabad Biryani",
  //   img: Hyd,
  //   count: count,
  // };
  const onClickCategory = (item, value) => {
    const user = LocalStorage.get("UserData");
    if (user) {
      const payloadData = {
        mobile: user.mobile ? "91" + user.mobile : "",
        category_name: item.category_name,
        category_id: item.category_id,
      };
    }

    if (value) {
      setCategoryModal(false);
    }
    // setCount(count + 1);
    setSelectedCategory(item);
    // setItemData([item]);
    scrollToRef(item.category_id, value);
    // setFilterData(true);
    // onChangeOrder(item);
  };
  const addCount = () => {
    setAddSubCount(addSubCount + 1);
  };
  const subCount = () => {
    setAddSubCount(addSubCount - 1);
  };

  const onChangeOrder = (item) => {
    const updateData = [];
    categoryValue.forEach((items) => {
      if (items.category_name === item.category_name) {
        updateData.unshift(items);
      } else {
        updateData.push(items);
      }
    });
    setItemData(updateData);
    setCategoryData(updateData);
  };

  const netcoreEvent = (data) => {
    const user = LocalStorage.get("UserData");
    if (user && user.mobile !== "") {
    }
  };
  const onClickMenu = (value) => {
    history.push(value);
  };
  const onSwitch = (e, item, type) => {
    console.log("Toogling", e, item);
    let target = false;
    if (type === "label") {
      target = e;
    } else if (type === "checkbox") {
      target = e.target.checked;
    }
    const updateChanges = categoryData;

    let updateProducts = [];
    let vegData = [];
    let nonVeg = [];
    let bestSeller = [];
    const user = LocalStorage.get("UserData");
    if (user) {
      // const script = document.createElement("script");
      // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
      // script.onload = () => {
      //   smartech("identify", "91" + user.mobile);
      //   smartech("dispatch", "Category Viewed", {
      //     category_name: item.tag_name,
      //     category_type: "Food",
      //     category_id: item.tag_id,
      //     pageurl: item.category_image_url,
      //   });
      // };
      // document.head.appendChild(script);
    }

    const updateFilter = itemTags.map((items) => {
      if (items.tag_name === item.tag_name) {
        items.checked = target;
      } else {
        items.checked = false;
      }

      return items;
    });
    if (target === false) {
      if (toggleBestSeller[0].checked === true) {
        if (filter === false) {
          vegData = updateChanges.map((items) => {
            items.itemData = items.bestSeller;
            return items;
          });
        } else {
          const updatedCategory = [selectedCategory];
          vegData = updatedCategory.map((items) => {
            items.itemData = items.bestSeller;
            return items;
          });
        }
        updateProducts = vegData;
      } else {
        if (filter === false) {
          vegData = updateChanges.map((items) => {
            items.itemData = items.items;
            return items;
          });
        } else {
          const updatedCategory = [selectedCategory];
          vegData = updatedCategory.map((items) => {
            items.itemData = items.items;
            return items;
          });
        }
        updateProducts = vegData;
      }
    }
    if (target === true) {
      if (item.tag_name === "Vegetarian") {
        if (filter === false) {
          vegData = updateChanges.map((items) => {
            items.itemData = items.vegData;
            return items;
          });
          if (toggleBestSeller[0].checked === true) {
            vegData = updateChanges.map((items) => {
              items.itemData = items.bestSeller.filter((items) =>
                items.item_tags.includes(item.tag_id)
              );
              return items;
            });
          }
        } else {
          const updatedCategory = [selectedCategory];
          vegData = updatedCategory.map((items) => {
            items.itemData = items.vegData;
            return items;
          });
          if (toggleBestSeller[0].checked === true) {
            vegData = updatedCategory.map((items) => {
              items.itemData = items.bestSeller.filter((items) =>
                items.item_tags.includes(item.tag_id)
              );
              return items;
            });
          }
        }
        updateProducts = vegData;
      } else if (item.tag_name === "Non-vegetarian") {
        if (filter === false) {
          nonVeg = updateChanges.map((items) => {
            items.itemData = items.nonVeg;
            return items;
          });
          if (toggleBestSeller[0].checked === true) {
            nonVeg = updateChanges.map((items) => {
              items.itemData = items.bestSeller.filter((items) =>
                items.item_tags.includes(item.tag_id)
              );
              return items;
            });
          }
        } else {
          const updatedCategory = [selectedCategory];
          nonVeg = updatedCategory.map((items) => {
            items.itemData = items.nonVeg;
            return items;
          });
          if (toggleBestSeller[0].checked === true) {
            nonVeg = updatedCategory.map((items) => {
              items.itemData = items.bestSeller.filter((items) =>
                items.item_tags.includes(item.tag_id)
              );
              return items;
            });
          }
        }
        updateProducts = nonVeg;
      }
    }
    if (distanceCategory.checked) {
      updateProducts = handleDistanceCategoryFilter(updateProducts);
    }
    setItemData(updateProducts);
    setItemTags(updateFilter);
    const categoryElement = document.getElementById("categorySection");

    if (categoryElement) {
      console.log(categoryElement, "net core")
      // categoryElement.scrollIntoView({ behavior: "smooth" });
      // referProduct.current.scrollTop
      referProduct.current.scrollIntoView({ behavior: 'smooth' })
      // console.log(,"net core");
    }
  };

  const onSwitchBestSeller = (e, item, type) => {
    let target = false;
    if (type === "label") {
      target = e;
    } else if (type === "checkbox") {
      target = e.target.checked;
    }
    console.log("categoryData", categoryData);
    const updateChanges = categoryData;
    let updateProducts = [];
    const updateFilter = itemTags.find((items) => items.checked === true);
    let updatedData = [];
    let updatedToggler = item;
    updatedToggler.checked = target;

    setToggleBestSeller([updatedToggler]);
    if (target === false) {
      if (filter === false) {
        updatedData = updateChanges.map((items) => {
          if (updateFilter && updateFilter.tag_id === "1") {
            items.itemData = items.vegData;
          } else if (updateFilter && updateFilter.tag_id === "2") {
            items.itemData = items.nonVeg;
          } else {
            items.itemData = items.items;
          }
          return items;
        });
      } else {
        const updatedCategory = [selectedCategory];
        updatedData = updatedCategory.map((items) => {
          if (updateFilter && updateFilter.tag_id === "1") {
            items.itemData = items.vegData;
          } else if (updateFilter && updateFilter.tag_id === "2") {
            items.itemData = items.nonVeg;
          } else {
            items.itemData = items.items;
          }
          return items;
        });
      }
      updateProducts = updatedData;
    }
    if (target === true) {
      if (filter === false) {
        updatedData = updateChanges.map((items) => {
          if (updateFilter) {
            items.itemData = items.bestSeller.filter((items) =>
              items.item_tags.includes(updateFilter.tag_id)
            );
          } else {
            items.itemData = items.bestSeller;
          }
          return items;
        });
      } else {
        const updatedCategory = [selectedCategory];
        updatedData = updatedCategory.map((items) => {
          if (updateFilter) {
            items.itemData = items.bestSeller.filter((items) =>
              items.item_tags.includes(updateFilter.tag_id)
            );
          } else {
            items.itemData = items.bestSeller;
          }
          return items;
        });
      }
      updateProducts = updatedData;
    }
    if (distanceCategory.checked) {
      updateProducts = handleDistanceCategoryFilter(updateProducts);
    }
    setItemData(updateProducts);
    const categoryElement = document.getElementById("categorySection");
    if (categoryElement) {
      console.log(categoryElement, "net core")
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  };



  const distanceCategoryOnClick = (e, item, type) => {
    let target = false;
    if (type === "label") {
      target = e;
    } else if (type === "checkbox") {
      target = e.target.checked;
    }
    setDistanceCategory((prevState) => {
      return {
        ...prevState,
        checked: target,
      }
    });
    // return;
    const updateChanges = categoryData;
    let updateProducts = [];
    const updateFilter = itemTags.find((items) => items.checked === true);
    let updatedData = [];
    // let updatedToggler = item;
    // updatedToggler.checked = target;

    // setToggleBestSeller([updatedToggler]);
    if (toggleBestSeller[0].checked === false) { // best seller is off
      if (filter === false) {
        updatedData = updateChanges.map((items) => {
          if (updateFilter && updateFilter.tag_id === "1") {
            items.itemData = items.vegData;
          } else if (updateFilter && updateFilter.tag_id === "2") {
            items.itemData = items.nonVeg;
          } else {
            items.itemData = items.items;
          }
          return items;
        });
      } else {
        const updatedCategory = [selectedCategory];
        updatedData = updatedCategory.map((items) => {
          if (updateFilter && updateFilter.tag_id === "1") {
            items.itemData = items.vegData;
          } else if (updateFilter && updateFilter.tag_id === "2") {
            items.itemData = items.nonVeg;
          } else {
            items.itemData = items.items;
          }
          return items;
        });
      }
      updateProducts = updatedData;
    }
    if (toggleBestSeller[0].checked === true) { // best seller is on
      if (filter === false) {
        updatedData = updateChanges.map((items) => {
          if (updateFilter) {
            items.itemData = items.bestSeller.filter((items) =>
              items.item_tags.includes(updateFilter.tag_id)
            );
          } else {
            items.itemData = items.bestSeller;
          }
          return items;
        });
      } else {
        const updatedCategory = [selectedCategory];
        updatedData = updatedCategory.map((items) => {
          if (updateFilter) {
            items.itemData = items.bestSeller.filter((items) =>
              items.item_tags.includes(updateFilter.tag_id)
            );
          } else {
            items.itemData = items.bestSeller;
          }
          return items;
        });
      }
      updateProducts = updatedData;
    }
    if (target) {
      updateProducts = handleDistanceCategoryFilter(updateProducts);
    }
    setItemData(updateProducts);
    const categoryElement = document.getElementById("categorySection");

    if (categoryElement) {
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDistanceCategoryFilter = (dataList) => {
    const filteredList = dataList.map((data1) => {
      let updatedObj = data1;
      updatedObj.itemData = data1.itemData.filter((data2) => {
        return data2.item_tags.includes('3');
      });
      return updatedObj;
    });
    return filteredList;
  }

  const onOfferPopupClick = (item) => {
    setShowPopup(item);
  };

  const CustomDot = ({ onClick, ...rest }) => {
    const { active } = rest;

    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        className={active ? "dot dot__active" : "dot"}
        onClick={() => onClick()}
      />
    );
  };

  const offerDataValue = (
    <div className="productBody_imgCards">
      {offerData.map((item) => {
        return (
          <div className="productBody_imgCards_card1">
            <OffersCard data={item} onOfferClick={onOfferPopupClick} />
          </div>
        );
      })}
    </div>
  );

  let blogvalue = 0;
  const blogDataValue = (
    <div className="productBody_blogCardsDiv_blogCards">
      {blog.map((item) => {
        if (blogvalue < 3) {
          blogvalue = blogvalue + 1;
          return (
            <div
              className="productBody_blogCardsDiv_blogCards_card"
              key={blog._id}
            >
              <BlogCard data={item} id={item._id} />
            </div>
          );
        }
      })}
    </div>
  );

  const onCloseClick = () => {
    setShowPopup({});
  };

  const categoryShow = () => {
    setCategoryModal(!categoryModal);
  };

  const onBannerData = imageData && imageData.length > 0 && (
    <div className="productBody_imgCardsBanner">
      {imageData.map((item) => {
        return (
          <div className="productBody_imgCardsBanner_card">
            <ImageCard data={item} />
          </div>
        );
      })}
    </div>
  );

  const onBannerClick = (item) => {
    if (item.cta_key === "Wallet") {
      history.push(ROUTES.WALLET);
    }
    if (item.cta_key === "GiftCard") {
      history.push(ROUTES.GIFTCARD);
    }
    if (item.cta_key === "Refer And Earn") {
      history.push(ROUTES.REFER_EARN);
    }

    Analytics.logEventWithVariable(
      Analytics.Order.bannerClick,
      JSON.stringify(item)
    );
  };

  const onHittingMedia = (item) => {
    Analytics.logEventWithVariable(Analytics.Order.media, JSON.stringify(item));
  };

  const onTaxCal = (value) => {
    let ontaxCal = [];
    taxCharges.map((item) => {
      value.item_taxes &&
        value.item_taxes[0].taxes.map((values) => {
          if (item.tax_id === values) {
            const tax = {
              tax_tags: values,
              tax_value: Number(item.tax_value),
              amount: (item.tax_value * value.item_final_price) / 100,
            };
            ontaxCal.push(tax);
          }
        });
    });
    return ontaxCal;
  };

  const onAddToCart = (value, isRepeat, addNewIsRepeat, isInitialCall) => {
    let isEqual = false;
    const outletData = LocalStorage.get("Outlet_Details");
    const location = LocalStorage.get("location");
    const outletId = LocalStorage.get("outlet_id");
    const user = LocalStorage.get("UserData");

    let ontaxCal = [];
    taxCharges.map((item) => {
      value &&
        value.item_taxes &&
        value.item_taxes.length > 0 &&
        value.item_taxes[0].taxes.map((values) => {
          if (item.tax_id === values) {
            const tax = {
              tax_tags: values,
              tax_value: Number(item.tax_value),
              amount: (item.tax_value * value.item_final_price) / 100,
            };
            ontaxCal.push(tax);
          }
        });
    });
    let addonsCart = [];
    if (value && value.groups && value.groups.length > 0) {
      value.groups.map((item) => {
        if (item.items.length > 0) {
          const updateAddons = item.items.filter((val) => val.checked === true);
          if (updateAddons.length > 0) {
            updateAddons.map((vl) => {
              const itemValue = {
                item_id: vl.item_id,
                item_name: vl.item_name,
                group_id: item.group_id,
                item_count: 1,
                final_price: vl.item_final_price,
                group_name: item.group_name,
                discount: 1,
                image_url: vl.item_image_url,
                item_tags: onTaxCal(vl),
              };
              addonsCart.push(itemValue);
              LocalStorage.set("Addons", [].push(addonsCart));
            });
          }
        }
      });
    }

    let cartProducts = LocalStorage.get("CartProducts");
    let itemEqualData;
    cartProducts &&
      cartProducts.map((product) => {
        if (product.item_id === value.item_id) {
          if (product.addons.length === 0 && value.addons.length === 0) {
            itemEqualData = product;
            return itemEqualData;
          }
          if (product.addons.length > 0) {
            var productsItem = product.addons.map(function (x) {
              return x.item_id;
            });
            var addItem = value.addons.map(function (x) {
              return x.item_id;
            });
            if (productsItem.sort().join(",") === addItem.sort().join(",")) {
              itemEqualData = product;
              return itemEqualData;
            }
          }
        }
      });

    let addarr = [];
    let addStr = "";
    value &&
      value.addons &&
      value.addons.length > 0 &&
      value.addons.map((adds) => {
        addarr.push(parseInt(adds.item_id));
      });

    addStr = addarr.toString();
    const distance=LocalStorage.get('userDistance')
    if (value && value.item_id) {
      let payload = {};

      payload = {
        channel: location.orderFor,
        outlet_id: outletId,
        delivery_area_id: outletData.delivery_area_id,
        purchase_detail_id: "",
        items: [
          {
            item_id: value && value.item_id,
            item_count: value && value.count,
            is_veg: value?.is_veg
              ? value.is_veg
              : value?.item_tags && value.item_tags.includes("1")
                ? true
                : false,
            image_url: value && value?.item_image_url,
            price:
              value && value?.single_item_price
                ? value?.single_item_price
                : value?.item_unit_price,
            final_price:
              value && value?.single_item_price
                ? value?.single_item_price
                : value?.item_final_price,
            name: value && value?.item_name,
            discount: 0,
            item_tags: ontaxCal,
            addons: value && value?.addons,
            local_id: parseInt(value && value?.item_id + addStr),
            selected_addons: addarr,
            category_name: value && value?.categoryName,
          },
        ],
        source: outletData.outlet_status_info,
        user_distance:distance,
      };

      if (value && value?._id) {
        if (itemEqualData || isRepeat) {
          payload.items[0]._id = value._id;
        }
        if (addNewIsRepeat && itemEqualData) {
          payload.items[0].item_count = itemEqualData.count + value.count;

          if (
            payload.items[0].addons &&
            payload.items[0].addons.length &&
            payload.items[0].addons.length > 0
          ) {
            const updatedAddons = payload.items[0].addons.map((data) => {
              data.item_count = itemEqualData.count + data.item_count;
              return data;
            });
          }

          if (itemEqualData?._id) {
            payload.items[0]._id = itemEqualData._id;
          }
        }
      }

      addToCart(payload, (res) => {
        // debugger;
        if (res && res?.response?.status === 200) {
          getCartItems(outletId, (res) => {
            if (res && res?.response?.status === 200) {
              updateMenuList(res?.content?.items);
              setShowLoader((ids) => ({
                ...ids,
                [value._id]: false,
              }));
              if (
                res.content &&
                res.content.items &&
                res.content.items.length === 0
              ) {
                setShowCartSidebar(false);
                setBottomShow(false);
                setCartData({ count: 0 });
                console.log(data, "bottomShoww");
              } else {
                const itemDetailFooterData = {
                  count: res.content.items.length,
                  price: res.content.billing_data.sub_total,
                  data: res.content.items,
                };
                setCartData(itemDetailFooterData);
                console.log(data, "bottomShow2");
              }
              // console.log("productlisting", res.content.items?.length);
              const data = onStructurize(res.content.items);
              LocalStorage.set("CartProducts", data);
              LocalStorage.set(
                "CouponName",
                res.content.billing_data.coupon_used
              );
              setUpdatedCartData(data);
              onCartDataValue(res.content);
              if (LocalStorage.get("addUp") === true) {
                LocalStorage.set("addUp", false);
                setCustomizePopup(false);
              }
              setShowLoader((ids) => ({
                ...ids,
                [value._id]: false,
              }));
              const updateitem = categoryData.map((items) => {
                let updateItem = [];
                if (items.has_subcategory === "0") {
                  updateItem = items.items;
                }
                if (items.has_subcategory === "1") {
                  items.subcategories.map((item) => {
                    item.items.map((value) => {
                      updateItem.push(value);
                    });
                  });
                  items.items = updateItem;
                }
                items.itemData = updateItem.map((item) => {
                  item.checked = false;
                  item.count = 0;
                  if (item.groups.length > 0) {
                    item.groups.map((val) => {
                      if (val.items.length > 0) {
                        val.items.map((groupVal) => {
                          groupVal.checked = false;
                          return groupVal;
                        });
                      }
                    });
                  }
                  if (LocalStorage.get("CartProducts")) {
                    const productData = LocalStorage.get("CartProducts");
                    if (productData && productData.length > 0) {
                      productData.map((items) => {
                        if (items.item_id === item.item_id) {
                          item.count = items.count + item.count;
                          item._id = items._id;
                        }
                      });
                    }
                  }
                  return item;
                });

                return items;
              });

              setItemData(updateitem);
              LocalStorage.set("addId", "");
              LocalStorage.set("addUp", false);
              updateMenuList(res?.content?.items);
            }
            setLoderById((prevState) => ({
              ...prevState,
              [value.item_id]: false,
            }));
            setAddNewBtnLoader(false);
            handleProductModal(false);
          });
        } else if (res && res?.response?.status === 400) {
          if (isInitialCall !== true) {
            setAlertPopup(res?.response?.message);
          }
          getCartItems(outletId, (res) => {
            if (res && res?.response?.status === 200) {
              updateMenuList(res?.content?.items);
              if (
                res.content &&
                res.content.items &&
                res.content.items.length === 0
              ) {
                setShowCartSidebar(false);
                setBottomShow(false);
                setCartData({ count: 0 });
                console.log(data, "bottomShoww");
              } else {
                const itemDetailFooterData = {
                  count: res.content.items.length,
                  price: res.content.billing_data.sub_total,
                  data: res.content.items,
                };
                setCartData(itemDetailFooterData);
                console.log(data, "bottomShow2");
              }
              const data = onStructurize(res.content.items);
              LocalStorage.set("CartProducts", data);
              LocalStorage.set(
                "CouponName",
                res.content.billing_data.coupon_used
              );
              setUpdatedCartData(data);
              onCartDataValue(res.content);
              if (LocalStorage.get("addUp") === true) {
                LocalStorage.set("addUp", false);
                setCustomizePopup(false);
              }
              setShowLoader((ids) => ({
                ...ids,
                [value._id]: false,
              }));
              const updateitem = categoryData.map((items) => {
                let updateItem = [];
                if (items.has_subcategory === "0") {
                  updateItem = items.items;
                }
                if (items.has_subcategory === "1") {
                  items.subcategories.map((item) => {
                    item.items.map((value) => {
                      updateItem.push(value);
                    });
                  });
                  items.items = updateItem;
                }
                items.itemData = updateItem.map((item) => {
                  item.checked = false;
                  item.count = 0;
                  if (item.groups.length > 0) {
                    item.groups.map((val) => {
                      if (val.items.length > 0) {
                        val.items.map((groupVal) => {
                          groupVal.checked = false;
                          return groupVal;
                        });
                      }
                    });
                  }
                  if (LocalStorage.get("CartProducts")) {
                    const productData = LocalStorage.get("CartProducts");
                    if (productData && productData.length > 0) {
                      productData.map((items) => {
                        if (items.item_id === item.item_id) {
                          item.count = items.count + item.count;
                          item._id = items._id;
                        }
                      });
                    }
                  }
                  return item;
                });

                return items;
              });

              setItemData(updateitem);
              LocalStorage.set("addId", "");
              LocalStorage.set("addUp", false);
              updateMenuList(res?.content?.items);
            }
            setLoderById((prevState) => ({
              ...prevState,
              [value.item_id]: false,
            }));
            setAddNewBtnLoader(false);
            handleProductModal(false);
          });
          setToaster({
            toastType: false,
            toastText: res?.data?.response?.message,
            isShow: false,
          });
          setShowLoader((ids) => ({
            ...ids,
            [value?._id]: false,
          }));
        } else {
          setToaster({
            toastType: true,
            toastText: res?.data?.response?.message,
            isShow: true,
          });
          setShowLoader((ids) => ({
            ...ids,
            [value?._id]: false,
          }));
        }
      });
    }
  };

  const updateMenuList = (cardItems) => {
    let tempItemData = [...itemData];
    if(tempItemData?.length === 0) {
      return;
    }
    if (cardItems.length === 0) {
      tempItemData = tempItemData.map((data1) => {
        data1.itemData = data1.itemData.map(data2 => {
          data2.count = 0;
          return data2;
        })
        return data1;
      })
    } else {
      tempItemData = tempItemData.map((data1) => {
        data1.itemData = data1.itemData.map(data2 => {
          if (cardItems.find((cardData1) => cardData1.item_id === data2.item_id)) {
            data2.count = getItemTotalCount(data2.item_id, cardItems);
          } else {
            data2.count = 0;
          }
          return data2;
        })
        return data1;
      })
    }
    setItemData(tempItemData);
    const data = onStructurize(cardItems);
    LocalStorage.set("CartProducts", data);
  }

  const getItemTotalCount = (id, arrList) => {
    let totalCount = 0;
    arrList.map((data) => {
      if (data.item_id === id) {
        totalCount += Number(data.item_count);
      }
    })
    return totalCount;
  }

  const onAddonsStructure = (value) => {
    let addonsCart = [];
    if (value.groups && value.groups.length > 0) {
      value.groups.map((item) => {
        if (item.items.length > 0) {
          const updateAddons = item.items.filter((val) => val.checked === true);
          if (updateAddons.length > 0) {
            updateAddons.map((vl) => {
              const itemValue = {
                item_id: vl.item_id,
                item_name: vl.item_name,
                group_id: item.group_id,
                item_count: value.count,
                final_price: vl.item_final_price,
                group_name: item.group_name,
                discount: 1,
                image_url: vl.item_image_url,
                item_tags: onTaxCal(vl),
              };
              addonsCart.push(itemValue);
              LocalStorage.set("Addons", [].push(addonsCart));
            });
          }
        }
      });
    } else {
      if (value.addons && value.addons.length && value.addons.length > 0) {
        value.addons.map((data) => {
          const copyData = {
            item_id: data.item_id,
            item_name: data.item_name,
            group_id: data.category_id,
            item_count: value.count,
            final_price: data.single_item_price,
            group_name: data.category_name,
            discount: 1,
            image_url: data.image_url,
            item_tags: onTaxCal(data),
          };

          addonsCart.push(copyData);
        });
      }
    }

    return addonsCart;
  };

  const handleProductItems = (item, addons, isRepeat, addNewIsRepeat) => {
    const updatedProduct = handleProductForGuestUser(
      item,
      isRepeat,
      categoryData
    );
    if (
      updatedProduct &&
      updatedProduct.productData &&
      updatedProduct.productData.length > 0
    ) {
      setCartData(updatedProduct.cartData);
      console.log(data, "bottomShow4");
      setBottomShow(true);
    } else {
      setCartData(updatedProduct.cartData);
      console.log(data, "bottomShow5");
      setBottomShow(false);
    }

    setItemData(updatedProduct.updateitem);
    if (!LocalStorage.get("isLoggedIn")) {
      setLoderById({});
      setAddNewBtnLoader(false);
      handleProductModal(false);
      setCustomizePopup(false);
    }
  };

  const handleLocalItems = () => { };

  const onUpdateContent = (item, addons, isRepeat, addNewIsRepeat) => {
    if (addons && addons.length > 0) {
      item.groups = addons;
    }

    let productData = [];
    let addonData = onAddonsStructure(item);
    // LocalStorage.set('Addons',addonData?.addons);
    item.addons = addonData;

    if (LocalStorage.get("isLoggedIn")) {
      onAddToCart(item, isRepeat, addNewIsRepeat);
    }
    if (!LocalStorage.get("isLoggedIn")) {
      handleProductItems(item, addons, isRepeat, addNewIsRepeat);
    }
  };

  const onCartClick = (data) => {
    // debugger;
    const updateData = LocalStorage.get("CartProducts");
    if (innerWidth <= 1023) {
      Analytics.beginCheckoutEvent(updateData);
      history.push(ROUTES.CHECKOUT);
    } else {
      handleCartSidebar();
      Analytics.viewCartEvent(updateData);
    }
  };

  const onClickSubmit = (subscribeType) => {
    const user = LocalStorage.get("UserData");
    const payloadData = {
      usertype: subscribeType,
    };
    const payload = {
      is_subscribed_to_newsletter: subscribeType[0].checked,
    };
    updateProfile(payload, (res) => {
      if (res.response.status === 200) {
        if (subscribeType !== "subscribe") {
          setActiveSubscribe("unsubscribe");
        } else {
          setActiveSubscribe("subscribe");
        }
      }
    });
  };

  const onSubscribe = (e, item) => {
    setActiveSubscribe(item.id);
    const updateSubscribe = subscribe.map((itm) => {
      itm.checked = !item.checked;
      itm.name = !item.checked === false ? "Unsubscribe" : "Subscribe";
      itm.id = !item.checked === false ? "unsubscribe" : "subscribe";
      return itm;
    });
    onClickSubmit(updateSubscribe);
    setSubscribe(updateSubscribe);
  };

  const handleLocation = () => {
    history.push("/search");
  };

  const loggedIn = LocalStorage.get("isLoggedIn");

  const onNav = (val) => {
    setStat(val);
  };

  const onNavigate = () => {
    const { location } = history;

    setTimeout(() => {
      history.push(ROUTES.HOME);
      window.location.reload();
    }, 1000);
  };

  const onLoginClick = () => {
    const { location } = history;
    setTimeout(() => {
      history.push(ROUTES.HOME);
      window.location.reload();
    }, 1000);
  };

  const handleLocalCartItems = () => {
    const { location } = history;
    const payload = hanldeCartProducts();

    addToCart(payload, (res) => {
      if (res && res?.response?.status === 200) {
        // debugger;
      }
    });
  };

  const getMobileData = (number) => {
    setLoginBtnLoading(true);
    setMobileNumber(number);
    let displayM = number.split("");
    let displayMobile = [];
    let mobileDisplay = "";
    for (let i = 0; i < displayM.length; i++) {
      if (i == 0 || i == 1 || i == 2 || i == 8 || i == 9) {
        displayMobile.push(displayM[i]);
      } else {
        displayMobile.push("*");
      }
    }
    mobileDisplay = displayMobile.join("");
    setDisplayMobileNumber(mobileDisplay);

    // setDisplayMobile(number)
    let payload = {};
    if (isSignIn === true) {
      payload = {
        mobile: number,
        device_type: "web",
        is_signup: true,
        firebase_token: "qwerty",
        login_id: "",
        retry: false,
      };
    } else {
      payload = {
        mobile: number,
        device_type: "web",
        is_signup: false,
        firebase_token: "qwerty",
        login_id: "",
        retry: false,
      };
    }

    sendOtp(payload, (res) => {
      // setResMsg(res.response.message);
      // setToast(true);
      if (res?.response?.status === 200) {
        //WebOTP API

        const ac = new AbortController();

        //Settimeout is for aborting the WebOTP API after 1 min
        setTimeout(() => {
          ac.abort();
        }, 1 * 60 * 1000);

        if ("OTPCredential" in window) {
          try {
            if (navigator.credentials) {
              try {
                navigator.credentials
                  .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                  })
                  .then((otp) => {
                    setOtp(otp.code);
                    ac.abort();
                  })
                  .catch((err) => {
                    ac.abort();
                    console.log(err);
                  });
              } catch (err) {
                console.log(err);
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
        setLoginId(res?.content?.login_id);
        setStat("stage2");
      } else {
        // setErrorTxt(true);
        setErrorTxt1(res?.response?.message);
      }
      setErrorTxt("");
      setSuccessTxt("");
      setLoginBtnLoading(false);
    });
  };

  const getResendData = (number1) => {
    let payload = {};
    if (isSignIn === true) {
      payload = {
        mobile: mobileNumber,
        device_type: "web",
        is_signup: true,
        firebase_token: "qwerty",
        login_id: "",
        retry: false,
      };
    } else {
      payload = {
        mobile: mobileNumber,
        device_type: "web",
        is_signup: false,
        firebase_token: "qwerty",
        login_id: "",
        retry: false,
      };
    }

    sendOtp(payload, (res) => {
      if (res?.response?.status === 200) {
        //WebOTP API

        const ac = new AbortController();

        //Settimeout is for aborting the WebOTP API after 1 min
        setTimeout(() => {
          ac.abort();
        }, 1 * 60 * 1000);

        if ("OTPCredential" in window) {
          try {
            if (navigator.credentials) {
              try {
                navigator.credentials
                  .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                  })
                  .then((otp) => {
                    setOtp(otp.code);
                    ac.abort();
                  })
                  .catch((err) => {
                    ac.abort();
                    console.log(err);
                  });
              } catch (err) {
                console.log(err);
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
        setErrorTxt("");
        setLoginId(res?.content?.login_id);
        setSuccessTxt(res?.response?.message);
      }
    });
  };

  const getDeviceId = () => {
    const id = LocalStorage.get("fingerprint");
    if (!id) {
      Fingerprint2.get(function (components) {
        const values = components.map(function (component) {
          return component.value;
        });
        const murmur = Fingerprint2.x64hash128(values.join(""), 31);
        LocalStorage.set("fingerprint", murmur);
      });
    }
  };

  const getOtpData = async (otpData) => {
    let payload = {
      mobile: mobileNumber,
      otp: otpData,
      login_id: loginId,
    };
    const { location } = history;
    const deviceId = LocalStorage.get("fingerprint");

    if (deviceId) {
      payload.device_id = deviceId;
    }

    if (isSignIn === true) {
      verifyOtp(payload, (res) => {
        //

        if (res?.response?.status === 200) {
          LocalStorage.set("AccessToken", res?.content.access_token);
          LocalStorage.set("RefreshToken", res?.content.refresh_token);
          LocalStorage.set("isLoggedIn", true);
          LocalStorage.set("UserData", {
            name: res?.content.first_name,
            mobile: res?.content.mobile,
          });
          LocalStorage.set("isDOB", res?.content?.is_dob_eitable);
          handleLocalCartItems();
          Analytics.loginEvent(payload);

          //branch events

          registrationBranch(res?.content?._id);
          setIdentityBranch(res?.content?._id);
          loginBranchEvent(res?.content?._id);

          // Analytics.logEventWithVariable(
          //   Analytics.Order.login,
          //   JSON.stringify(payload)
          // );

          setErrorTxt(res?.response?.message);
          if (res.content && res.content.is_register_on_rezol === false) {
            setStat("stage3");
          } else {
            onNavigate();
          }
        } else {
          setErrorTxt(
            res?.response?.message ? res?.response?.message : "Invalid OTP!"
          );
        }
      });
    } else {
      //

      verifyOtp(payload, (res) => {
        if (res?.response?.status === 200) {
          // setStat('stage3')

          const netCoreLoginPayload = {
            date: moment(res.content.updated_at).format("DD-MM-YYYY"),
            mobile: `91${res.content.mobile}`,
            logintype: "Web",
          };

          // loginNetCoreEvent(netCoreLoginPayload);
          if (isSignIn === false) {
            setLoginFLow(false);
          }
          LocalStorage.set("AccessToken", res?.content.access_token);
          LocalStorage.set("RefreshToken", res?.content.refresh_token);
          LocalStorage.set("isLoggedIn", true);
          LocalStorage.set("UserData", {
            name: res?.content.first_name,
            mobile: res?.content.mobile,
            email: res?.content.email,
            dob: res?.content.dob,
            emailVerify:res?.content?.is_user_email_verified
          });
          LocalStorage.set("isDOB", res?.content?.is_dob_eitable);
          handleLocalCartItems();
          // onLoginClick();
          Analytics.loginEvent(payload);

          //branch events

          setIdentityBranch(res?.content?._id);
          loginBranchEvent(res?.content?._id);

          // Analytics.logEventWithVariable(
          //   Analytics.Order.login,
          //   JSON.stringify(payload)
          // );
          if (
            res.content &&
            (res.content.is_register_on_rezol === false ||
              res.content.is_new_user === true)
          ) {
            // basicDetailsData = res.content;
            setLoginFLow(true);
            setBasicDetailsData(res.content);
            setStat("stage3");
            // setBasicDetailsData(res?.content);
          } else {
            onLoginClick();
          }
          setErrorTxt(res?.response?.message);
        } else {
          setErrorTxt(
            `${res?.response?.message ? res?.response?.message : `Invalid OTP!`
            }`
          );
        }
      });
    }
  };

  const getBasicDetails = (data) => {
    setName(data.name);
    setEmail(data.email);
    setDate(data.date);
    const user = LocalStorage.get("UserData");

    const mobNumber =
      mobileNumber && mobileNumber?.length > 0 ? mobileNumber : user.mobile;

    const payload = {
      fname: data.name,
      lname: data.name,
      email: data.email,
      mobile: mobNumber,
      dob: data.date,
      referral_code: data.referralCode,
    };
    makeProfile(payload, (res) => {
      if (res?.response?.status === 200) {
        localStorage.setItem("DOB", res?.content?.data?.dob);
        localStorage.setItem("Email Id", res?.content?.data?.email);

        const createdAt = moment(res.content.created_at).format("DD-MM-YYYY");

        const enventPayload = {
          mobile: "91" + mobileNumber,
          email: data.email,
          name: data.name,
          createdAt: createdAt,
        };
        Analytics.signUpEvent(enventPayload);
        // signUpNetCoreEvent(enventPayload);
        onNavigate();
      } else {
        setErrorMsg(" Please Log!");
      }
    });
  };

  const closeHandle = () => {
    LocalStorage.set("loginflow", false);
    setLoginFLow(false);
  };

  const LoginCLick = () => {
    setLoginFLow(true);
    setIsSignIn(false);
  };

  const SigninClick = () => {
    setLoginFLow(true);
    setIsSignIn(true);
  };

  const deliveryClick = async () => {
    const coord = await getCurrentLocation();
    console.log(coord, " activer")
    if (coord.lat !== "") {
      setDeliveryFlow(true);
      Analytics.locactionSelectionEvent();
    }
  };
  const ondeliveryClick = async () => {
    const coord = await CurrentLocation();
    if (coord.lat !== "") {
      setDeliveryFlow(true);
      Analytics.locactionSelectionEvent();
    }
  };

  const onMapClose = (val) => {
    handleOpenModal(true);
    setDeliveryFlow(val);
    setErrorData(true);
    // setAlertPopup("We are unable to fetch your location.Please allow the site to access the location in browser settings.");
  };

  //strictly for Delivery
  const onChangeOrder1 = async (value) => {
    setActiveOrder(value.id);

    if (latLng.la !== "") {
      const coordinate = await getCurrentLocation();
      setLatLng(coordinate);
    }
    let dataValue = {
      orderFor: active,
      orderTime: activeOrder,
      deliveryTime: data.delivery_area_delivery_time,
      address: addressData || inputData,
      date: date,
      time: time,
      lat: latLng.lat,
      lng: latLng.lng,
    };

    // if (isTakeAway) {
    //   dataValue.address = data.address;
    //   dataValue.lat = data.latitude;
    //   dataValue.lng = data.longitude;
    // }

    LocalStorage.set("location", dataValue);

    const user = LocalStorage.get("UserData");
    const location = LocalStorage.get("location");
    const OutletData = LocalStorage.get("Outlet_Details");

    if (innerWidth <= 1023) {
      const updateOrder = responsiveRadioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setResponsiveRadioData(updateOrder);
    } else {
      //
      const updateOrder = radioData.map((item) => {
        item.checked = false;
        // item.checked = false;
        // item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }

        return item;
      });
      setRadioData(updateOrder);
    }
  };

  const onChangeMode = async (value) => {
    // if (value.id === "takeaway" && !selectedAddress) {
    //   setActive("delivery");
    //   return;
    // }
    setActive(value.id);
    if (value.id !== "delivery") {
      let coordinates = latLng;
      const locationDetails = LocalStorage.get("location");
      if (locationDetails.lat != "" && locationDetails.lng != "") {
        coordinates = { lat: locationDetails.lat, lng: locationDetails.lng };
      } else if (coordinates.lat === "" && coordinates.lng === "") {
        coordinates = await getCurrentLocation();
      }

      onConfirmLocation(coordinates, value.id);
    } else {
      setOutlets([]);
    }
  };

  const onCurrentLocations = () => {
    setAlertData(false);
    // onCurrentLocation();
  };

  const getCurrentLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position, "activer");
            if (
              position &&
              position.coords &&
              position.coords.latitude &&
              position.coords.longitude
            ) {
              const coordinates = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              resolve(coordinates);
            }
            // eslint-disable-next-line no-unused-vars
          },
          (_error) => {
            reject(_error);
            onMapClose(true);
          }
        );
      } else {
        onMapClose(false);
      }
    });
  };

  const CurrentLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (
              position &&
              position.coords &&
              position.coords.latitude &&
              position.coords.longitude
            ) {
              const coordinates = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              resolve(coordinates);
            }
            // eslint-disable-next-line no-unused-vars
          }
          // (_error) => {
          //   reject(_error);
          //   onMapClose(true);
          // }
        );
      }
      // else {
      //   onMapClose(false);
      // }
    });
  };
  const onCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    const locationDetails = {
                      location: results[0].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                    };
                    setInputvalue(locationDetails);
                    setLatLng({ lat: json.lat, lng: json.lng });
                    onMarkerPosition({ lat: json.lat, lng: json.lng });
                    setLocation(true);
                    setErrorData(false);
                    setInputData(results[0].formatted_address);
                    if (active === "delivery") {
                      setPopup(true);
                    } else {
                      onConfirmLocation(locationDetails);
                    }
                  }
                }
              },
              this
            );
          }
          // eslint-disable-next-line no-unused-vars
        },
        (_error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  const responsiveOnFindFood = () => {
    //
    if ((active === "delivery" || active === "takeaway") && inputData) {
      //
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time !== "" &&
      date !== ""
    ) {
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time === ""
    ) {
      settimeData(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      date === ""
    ) {
      setdateData(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onFindFood = (text, value) => {
    console.log("onFindFood", value);
    let val = value;
    value.lat = value.guest_lat;
    value.lng = value.guest_long;
    if (text == "savedaddress") {
      setInputData(value.Google_Address);
      const dataValue = {
        orderFor: active,
        orderTime: activeOrder,
        deliveryTime: data.delivery_area_delivery_time,
        address: value.Address1,
        addressType: value.AddressType,
        date: date,
        time: time,
        lat: value.guest_lat || latLng.lat || val.lat,
        lng: value.guest_long || latLng.lng || val.lng,
      };
      if (value.lat !== "" && latLng.lat === "") {
        setLatLng({ lat: value.guest_lat, lng: value.guest_long });
      }
      LocalStorage.set("Previouslocation", dataValue);
      LocalStorage.set("outlet_id", value.UnitId);
    }

    if (activeOrder === "orderLater") {
      if (date?.length === 0) {
        setToaster({
          toastType: true,
          toastText: "Date is required",
          isShow: true,
        });
        return;
      }
      if (time?.length === 0) {
        setToaster({
          toastType: true,
          toastText: "Time is required",
          isShow: true,
        });
        return;
      }
    }
    if (
      (active === "delivery" || active === "takeaway") &&
      (inputData || value.Address1)
    ) {
      if (text == "savedaddress") {
        alertUpdate = onConfirmLocation(value, active, value.Google_Address);
      } else {
        alertUpdate = onConfirmLocation();
      }
    } else {
      setAlertData(true);
      // onConfirmLocation();
    }
    return alertUpdate;
  };

  const onChangeInput = (value) => {
    setInputData(value);
  };

  const onBackClick = () => {
    setPopup(false);
  };

  const onChangeDate = (value) => {
    if (!value) {
      setDate("");
      return;
    }
    let gotTime = value.getDate();
    setdateData(false);
    setDate(value);
    let dateHour = new Date();
    let hours = dateHour.getHours();
    let opentime =
      LocalStorage.get("Outlet_Details").open_time?.substring(0, 2) ||
      LocalStorage.get(
        "Outlet_Details"
      )?.outlet_timing.min_start_time.substring(0, 2);
    let closetime =
      LocalStorage.get("Outlet_Details").close_time?.substring(0, 2) ||
      LocalStorage.get("Outlet_Details")?.outlet_timing.max_end_time.substring(
        0,
        2
      );
    let timeInterval = [];
    if (opentime) {
      const calcTime = moment().add(1.5, "hours").format("HH:mm");
      const splitTime = calcTime.split(":");
      const totalHours =
        handleParseAmount(splitTime[0]) + handleParseAmount(splitTime[1]) / 60;
      if (gotTime === new Date().getDate()) {
        for (let i = Math.round(totalHours); i < closetime; i++) {
          timeInterval.push({
            id: i + ":00",
            name: `${moment(i, "HH").format("hh A")} - ${moment(
              i + 1,
              "HH"
            ).format("hh A")}`,
          });
        }
      } else {
        for (let i = Math.round(opentime); i < closetime; i++) {
          timeInterval.push({
            id: i + ":00",
            name: `${moment(i, "HH").format("hh A")} - ${moment(
              i + 1,
              "HH"
            ).format("hh A")}`,
          });
        }
      }
      setIntDate(timeInterval);
    }
  };

  const onChangeTime = (value) => {
    settimeData(false);
    setTime(value);
  };

  const onCurrentLocationData = (value) => {
    setLatLng({ lat: value.latitude, lng: value.longitude });
    setInputData(value.location);
  };

  const onCenter = (value) => {
    if (value === false) {
      setLatLng({ lat: "", lng: "" });
    }
  };

  const callOutlet = (ltln, val) => {
    if (val) {
      setInputData(val);
    }
    // setLatLng({ lat: ltln.lat, lng: ltln.lng });
    let payload = {
      latitude: ltln.lat,
      longitude: ltln.lng,
      is_delivery: active === "delivery" ? true : false,
      is_order_now: true,
      // activeOrder === "orderNow" ? true : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };

    OutletMapping(payload, (res) => {
      if (res && res?.response?.status === 200) {
        LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
        setCanShowLater(true);
      } else {
        setErrorData(true);
        setAlertPopup(res?.response?.status ? res?.response?.message : "");
      }
    });
  };

  const onMarkerPosition = (value) => {
    LocalStorage.remove("Previouslocation");
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng: value },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            var formattedAddress = results[0].formatted_address;
            for (let i = 0; i < results.length; i++) {
              if (results[i]?.geometry?.location_type === "APPROXIMATE") {
                // console.log('resultsresults', results[i].geometry)
                formattedAddress = results[i].formatted_address;
                break;
              }
            }
            // eslint-disable-next-line no-lone-blocks
            setInputvalue({
              location: results[0].formatted_address,
              pincode,
              latitude: value.lat,
              longitude: value.lng,
            });
            setLatLng({ lat: value.lat, lng: value.lng });
            setInputData(formattedAddress);
          }
        }
      },
      this
    );
  };

  const onConfirmLocation = (
    locationDetails,
    type = active,
    address = inputData
  ) => {
    setPopup(false);

    let payload = {
      latitude: latLng.lat || locationDetails.lat,
      longitude: latLng.lng || locationDetails.lng,
      is_delivery: type === "delivery" ? true : false,
      is_order_now: activeOrder === "orderNow" ? true : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };
    if (locationDetails) {
      payload.latitude = locationDetails.guest_lat
        ? locationDetails.guest_lat
        : locationDetails.lat;
      payload.longitude = locationDetails.guest_long
        ? locationDetails.guest_long
        : locationDetails.lng;
      setLatLng({ lat: locationDetails.lat, lng: locationDetails.lng });
      setInputData(address);
    }

    OutletMapping(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setErrorData(false);
        setErrorData1(false);
        //
        if (res && res?.content && res?.content?.areas) {
          setOutlets(res.content.areas);
          // LocalStorage.set('DeliveryAreaPoint',res.content.areas)
          if (type === "delivery" && (locationDetails || latLng)) {
            onClickStore(res?.content?.areas[0], type === "takeaway", address, {
              lat: locationDetails.lat || latLng.lat,
              lng: locationDetails.lng || latLng.lng,
            });
          }
          setAlertData(false);
        }
        setData([]);
      } else {
        setOutlets([]);
        setErrorData(true);
        setAlertPopup(res?.response?.status ? res?.response?.message : "");
        alertUpdate = (res?.response?.status ? res?.response?.message : "");
        if (type === "delivery") {
          payload = {
            latitude: latLng.lat,
            longitude: latLng.lng,
            is_delivery: false,
            is_order_now: activeOrder === "orderNow" ? true : false,
            order_date:
              activeOrder !== "orderNow" && date !== ""
                ? moment(date).format("YYYY-MM-DD")
                : "",
            order_time: activeOrder !== "orderNow" ? time : "",
            channel: "online_web",
          };
          OutletMapping(payload, (res) => {
            if (res && res.response && res.response.status === 200) {
              if (
                res.content &&
                res.content.areas &&
                res.content.areas.length > 0
              ) {
                setErrorData1(true);
                setCountData({
                  count: res.content.areas.length,
                  distance: parseFloat(
                    res.content.max_distance_user_to_restaurant
                  ).toFixed(2),
                });
                setData(res.content.areas);
              }
            } else {
              setErrorData(true);
              setCountData({});
              setData([]);
            }
          });
        } else {
          setErrorData1(false);
          setCountData({});
          setData([]);
        }
      }
    });
    return alertUpdate;
  };

  const onCloseAlert = () => {
    setAlertPopup("");
    setAlertLocationPopup(false);
  };

  const onStoreSelect = () => {
    setOutlets(data);
    setErrorData1(false);
    setData([]);
    setErrorData(false);
    setActive("takeaway");
  };
  const [takeAwayModal, setTakeAwayModal] = useState(false);
  const openTakeAway = () => {
    setTakeAwayModal(true);
  };
  const closeTakeAway = () => {
    setTakeAwayModal(false);
  };

  const onClickStore = (data, isTakeAway, addressData, latLong) => {
    // let pointDelivery = LocalStorage.get('DeliveryAreaPoint');

    if (activeOrder === "orderLater") {
      if (date?.length === 0) {
        setToaster({
          toastType: true,
          toastText: "Date is required",
          isShow: true,
        });
        return;
      }
      if (time?.length === 0) {
        setToaster({
          toastType: true,
          toastText: "Time is required",
          isShow: true,
        });
        return;
      }
    }

    if (data && data?.is_open) {
      setOutletLoader(true);
      LocalStorage.set("Outlet_Details", data);
      getMenuList(
        urlValue &&
          urlValue?.length > 0 &&
          data?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
          ? urlValue
          : data?.outlets_id,
        (res) => {
          if (res && res.content && res.response.status === 200) {
            if (res.content && res?.content?.menu) {
              if (LocalStorage.get("outlet_id")) {
                const oldOutlet = LocalStorage.get("outlet_id");
                if (oldOutlet !== res.content.menu.unit_id) {
                  if (LocalStorage.get("isLoggedIn")) {
                    onMenuDataSync(res.content.menu.categories);
                  } else {
                    LocalStorage.set("CartProducts", []);
                  }
                }
              }
              LocalStorage.set("outlet_id", data?.outlets_id);
              let dataValue = {
                orderFor: active,
                orderTime: activeOrder,
                deliveryTime: data.delivery_area_delivery_time,
                address: addressData || inputData,
                date: date,
                time: time,
                lat: latLong.lat || latLng.lat,
                lng: latLong.lng || latLng.lng,
              };

              if (isTakeAway) {
                dataValue.address = data.address;
                dataValue.lat = data.latitude;
                dataValue.lng = data.longitude;
              }

              LocalStorage.set("location", dataValue);

              const db = new Dexie(res.content.menu.unit_id);
              db.version(1).stores({
                menu: "++id, categories, item_tags, taxes",
              });
              const getMenuData = async () => {
                const allData = (await db.menu.toArray()) || [];
                if (allData && allData.length > 0) {
                  await db.menu.update(0, res.content.menu);
                } else {
                  await db.menu.add(res.content.menu);
                }
                setOutletLoader(false);
                setTakeAwayModal(false);
                history.push(ROUTES.HOME);
                // if (innerWidth > 1023) {
                //
                // if (! isTakeAway) {
                window.location.reload();
                // }
                // }
              };
              getMenuData();
            }
          } else {
            setOutletLoader(false);
            setAlertPopup(
              "Sorry!! No Data Found. Please try again later sometime"
            );
          }
        }
      );
    } else {
      setAlertPopup(
        data && data.outlet_status_info.web_err_msg
          ? data.outlet_status_info.web_err_msg
          : "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
      if (isTakeAway) {
        if (
          data.outlet_status_info.web_err_msg ===
          "Sorry, the outlet is not operational now, but you can pre-order."
        ) {
          const updatedData = data;
          updatedData.is_open = true;
          LocalStorage.set("Outlet_Details", updatedData);
        }
        setTakeawayAlertModal(
          data && data.outlet_status_info.web_err_msg
            ? data.outlet_status_info.web_err_msg
            : "Sorry!! The outlet is currently closed. Please try again later sometime"
        );
      }
    }
  };

  const onMenuDataSync = (data) => {
    const outletData = LocalStorage.get("CartProducts");
    const updateitem = data.map((items) => {
      let updateItem = [];
      if (items.has_subcategory === "0") {
        updateItem = items.items;
      }
      if (items.has_subcategory === "1") {
        items.subcategories.map((item) => {
          item.items.map((value) => {
            updateItem.push(value);
          });
        });
        items.items = updateItem;
      }
      items.itemData = updateItem.map((item) => {
        item.checked = false;
        item.count = 0;
        if (LocalStorage.get("CartProducts")) {
          const productData = LocalStorage.get("CartProducts");
          if (productData && productData.length > 0) {
            productData.map((items) => {
              if (items.item_id === item.item_id) {
                item.count = items.count;
              }
            });
          }
        }
        return item;
      });

      items.vegData = updateItem.filter((item) => item.item_tags.includes("1"));
      items.nonVeg = updateItem.filter((item) => item.item_tags.includes("2"));
      items.bestSeller = updateItem.filter((item) => item.bestseller === "1");
      return items;
    });
    const updateItemData = [];
    updateitem.map((items) => {
      items.itemData.map((item) => {
        updateItemData.push(item);
      });
    });
    const datavalue = updateItemData.filter((itms) => {
      return outletData.includes(itms.item_id);
    });
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleGoBack = () => {
    setModalOpen(false);
  };
  const locationOrderFor = LocalStorage.get("location")?.orderFor;

  const handleClickFastDelivery = () => {
    setTimeout(() => {
      const newActiveMethod = locationOrderFor ? locationOrderFor : "delivery";

      setActiveMethod(newActiveMethod);
      // setActiveMethod("delivery");
      setActive("delivery");
      history.push(ROUTES.DELIVERY_LOCATION);
    }, 1000);
  };
  const handleClickTakeaway = () => {
    setTimeout(() => {
      const newActiveMethod = locationOrderFor ? locationOrderFor : "takeaway";

      setActiveMethod(newActiveMethod);
      // setActiveMethod("takeaway");
      setActive("takeaway");
      // setTakeAwayModal(true);
      onChangeMode({ id: "takeaway", name: "Takeaway" });
      openTakeAway();
    }, 1200);
  };
  const close = () => {
    setModal(false);
  };

  const closedelivery = () => {
    setDeliveryFlow(false);
  };

  const onBlogsClick = () => {
    history.push("/blogs");
  };

  const handleCartSidebar = (value) => {
    if (value === false) {
      return setShowCartSidebar(value);
    }
    setShowCartSidebar((prevState) => !prevState);
  };

  const scrollToRef = (id, isMobile) => {
    if (scrollElements.current[id]) {
      var headerOffset = isMobile ? 100 : 150;
      var elementPosition = scrollElements.current[id].offsetTop;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      const root = document.getElementById("root");
      root.scroll({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleScrollToCategory = () => {
    if (scrollToCategory.current) {
      var headerOffset = 100;
      var elementPosition = scrollToCategory.current.offsetTop;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      const root = document.getElementById("root");
      root.scroll({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleScroll2 = (event) => {
    //
    let element = document.getElementById("categoryData");
    let stickyHeaderTop = $("#scroll2")?.offset()?.top;
    if (stickyHeaderTop < 300) {
      if (element) {
        // element.classList.add("sticky");
        // setCategoryStatus(true);
        setScroll(true);
      }
    } else {
      if (element) {
        // element.classList.remove("sticky");
        // setCategoryStatus(false);
        setScroll(false);
      }
    }
  };

  const handleProductModal = (customItem) => {
    if (customItem === false) {
      setProductModal(customItem);
    } else {
      setProductModal((prevState) => !prevState);
    }
  };

  const hanldeCartProducts = () => {
    let cartLocal = LocalStorage.get("CartProducts");
    const location = LocalStorage.get("location");
    const outletId = LocalStorage.get("outlet_id");
    const isLoggedIn = LocalStorage.get("isLoggedIn");
    const outletData = LocalStorage.get("Outlet_Details");
    const distance=LocalStorage.get('userDistance')


    const cartItems = [];

    cartLocal &&
      cartLocal.map((value) => {
        let addarr = [];
        let addStr = "";
        value.addons &&
          value.addons.length > 0 &&
          value.addons.map((adds) => {
            addarr.push(parseInt(adds.item_id));
          });

        addStr = addarr.toString();
        let item = {
          item_id: value.item_id,
          item_count: value.count,
          is_veg: value.item_tags.includes("1") ? true : false,
          image_url: value.item_image_url,
          price: value.single_item_price
            ? value.single_item_price
            : value.item_unit_price,
          final_price: value.single_item_price
            ? value.single_item_price
            : value.item_final_price,
          name: value.item_name,
          discount: 0,
          item_tags: onTaxCal(value),
          addons: value.addons,
          local_id: parseInt(value.item_id + addStr),
          selected_addons: addarr,
          category_name: value?.categoryName, // 
        };
        cartItems.push(item);
      });
    const payloadData = {
      channel: location.orderFor,
      delivery_area_id: outletData?.delivery_area_id, //
      outlet_id: outletId,
      items: cartItems,
      source: outletData.outlet_status_info,
      user_distance:distance,
    };
    return payloadData;
  };

  const handleAlertModal = () => {
    setMultipleAddonAlert((prevState) => !prevState);
  };

  const handleIamInterested = () => {
    const payload = {
      latitude: latLng.lng,
      longitude: latLng.lat,
      address: inputData,
    };
    setInterestModal(true);
    handleSaveInterest(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setToaster({
          toastType: true,
          toastText:
            "Thanks for your interest we would love to serve you soon as possible.",
          isShow: true,
        });
      } else if (res && res?.response?.status === 400) {
        setToaster({
          toastType: false,
          toastText: res?.data?.response?.message,
          isShow: false,
        });
      } else {
        setToaster({
          toastType: true,
          toastText: res.response.message,
          isShow: true,
        });
      }
    });
  };

  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };

  const handleLocationAlertModal = () => {
    setAlertLocation((prevState) => !prevState);

    if (alertLocation) {
      if (innerWidth > 1023) {
        ondeliveryClick();
      } else {
        history.push(ROUTES.HOME);
      }
    }
  };

  const onClosetakeAwayAlert = () => {
    if (
      takeawayAlertModal &&
      takeawayAlertModal ===
      "Sorry, the outlet is not operational now, but you can pre-order."
    ) {
      const outletDataTakeaway = LocalStorage.get("Outlet_Details");
      onCLickStoreData(outletDataTakeaway, true);
      setTakeawayAlertModal("");
      setAlertPopup("");
    } else {
      setTakeawayAlertModal("");
    }
    closeTakeAway();
  };

  const onCLickStoreData = async (data, isTakeAway) => {
    let coordinate = latLng;
    if (latLng.lat !== "" && latLng.lng !== "") {
      coordinate = await getCurrentLocation();
    }
    onClickStore(data, isTakeAway, inputData, coordinate);
  };
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const handleClick = (value) => {
    var replacedString;
    if (/\([^)]*\)/.test(value)) {
      replacedString = value
        .replace(/\s*\([^)]*\)\s*$/, "")
        .replace(/\s+/g, "-");
    } else {
      replacedString = value.replace(/\s+/g, "-");
    }
    history.push(ROUTES.LAYOUT + "/" + replacedString.toLowerCase());
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const AddressModal = () => {
    return (
      isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <img
              src={remove}
              className="deliveryContainer_right_removeImg"
              onClick={() => closeModal()}
              alt="remove"
            />
            <h5 className="savedAddress">Previous Saved Addresses</h5>
            <SavedAddress data={savedAddress} onClick={(text, value) => {
              if (!onFindFood(text, value)) {
                localStorage.setItem("selectedAddressType", "SAVED_ADDRESS");
              }
            }} />

            {/* <button onClick={closeModal}>Close</button> */}
          </div>
        </div>
      )
    );
  };

  const selectSavedAddress = () => {
    return (
      active == "delivery" &&
      savedAddress &&
      savedAddress.length > 0 && (
        <>
          <Button
            content="Show Saved Addresses"
            className="map-search_button1"
            onClick={openModal}
            customClass="map-search_button_content"
          />
          <AddressModal />
          {/* <div className="savedAddress__divider"></div> */}
        </>
      )
    );
  };

  const onChangeProductData = (value) => {
    const newProductData =
      itemData2 &&
      itemData2.length > 0 &&
      itemData2.find((item) => item.item_id === value);
    if (newProductData) {
      setProductDataAdd(newProductData);
      setExtras(newProductData.groups);
    }
  };
  const TimeInterval = (name, bool, id) => {
    setInterval(name);
    setIntervalModal(bool);
    setTime(id);
    const locationData = JSON.parse(localStorage.getItem("location")) || {};
    locationData.time = id;
    // locationData.time=time;

    localStorage.setItem("location", JSON.stringify(locationData));
  };
  const [isDistanceCategory, setIsDistanceCategory] = useState(true);
  const [preparationTime, setPreparationTime] = useState("");
  const handlerFunction = () => { };
  const callOutletMapping = async (locationDetails) => {
    let payload = {
      latitude: locationDetails?.lat,
      longitude: locationDetails?.lng,
      is_delivery: true,
      is_order_now: true,
      order_date: "",
      order_time: "",
      channel: "online_web",
    };
    OutletMapping(payload, (res) => {
      if (res && res?.response?.status === 200) {
        let newPayload = {
          source_latitude: Number(locationDetails?.lat),
          source_longitude: Number(locationDetails?.lng),
          destination_latitude: Number(res?.content?.areas[0].latitude),
          destination_longitude: Number(res?.content?.areas[0].longitude),
        }
        getDeliveryDistance(newPayload, (response) => {
          LocalStorage.set("userDistance", Number(response?.content?.distance));
          handleSetIsDistanceCategory(Number(response?.content?.distance));
          let preparationDuration = Number((res?.content?.areas[0]?.food_preparation_time)) +
            Number(response?.content?.duration.toFixed(0));
          setPreparationTime(preparationDuration);
        });

      } else {
        return null;
      }
    })
  }
  // const callOutletMapping = async (locationDetails) => {
  //   let payload = {
  //     latitude: locationDetails?.lat,
  //     longitude: locationDetails?.lng,
  //     is_delivery: true,
  //     is_order_now: true,
  //     order_date: "",
  //     order_time: "",
  //     channel: "online_web",
  //   };

  //   OutletMapping(payload,(res) => {
  //     if (res && res?.response?.status === 200) {
  //       if (res && res?.content && res?.content?.areas) {
  //         let newPayload = {
  //           source_latitude: Number(locationDetails?.lat),
  //           source_longitude: Number(locationDetails?.lng),
  //           destination_latitude: Number(res?.content?.areas[0].latitude),
  //           destination_longitude: Number(res?.content?.areas[0].longitude),
  //         }
  //         getDeliveryDistance(newPayload,(response) => {
  //           let preparationDuration = Number((res?.content?.areas[0].takeaway_preparation_time || res?.content?.areas[0].preparation_time)) +
  //           Number(response?.content?.duration.toFixed(0));
  //           setPreparationTime(preparationDuration);
  //         });
  //       }
  //     } else {
  //       return null;
  //     }
  //   })
  // }

  useEffect(() => {
    let sourceType = getSelectedAddressType();
    if (sourceType) {
      switch (sourceType) {
        case "saved_address":
          callOutletMapping(LocalStorage.get("Previouslocation"));
          break;
        case "picked_address":
          callOutletMapping(LocalStorage.get("location"));
          break;
        case "no_address":
        case "take_away":
        default:
          break;
      }
    }
  }, []);

  // const getSelectedAddressType = () => {
  //   let sourceType = null;
  //   let data = LocalStorage.get("location");
  //   let previousLocationData = LocalStorage.get("Previouslocation");
  //   sourceType = 
  //     data?.address !== "" || (previousLocationData && previousLocationData?.address !== "")
  //       ? `${(data || previousLocationData) && (data || previousLocationData)?.orderFor && (data || previousLocationData)?.orderFor === "takeaway"
  //             ? "take_away"
  //               : previousLocationData
  //                 ? previousLocationData?.address
  //                 : `${data?.addressType !== undefined ? "picked_address" : "no_address"}`
  //         }`
  //       : "no_address"
  //   return sourceType;
  // }

  useEffect(() => {
    let sourceType = getSelectedAddressType();
    if (sourceType) {
      switch (sourceType) {
        case "saved_address":
          callOutletMapping(LocalStorage.get("Previouslocation"));
          break;
        case "picked_address":
          callOutletMapping(LocalStorage.get("location"));
          break;
        case "no_address":
        case "take_away":
          LocalStorage.set("isDistanceCategory", false);
          setIsDistanceCategory(false);
          break;
        default:
          break;
      }
    }
  }, []);

  const getSelectedAddressType = () => {
    let sourceType = null;
    let data = LocalStorage.get("location");
    let previousLocationData = LocalStorage.get("Previouslocation");
    let selectedAddressType = LocalStorage.get("selectedAddressType");
    if (innerWidth <= 1023) {
      sourceType =
        data?.address !== "" || (previousLocationData && previousLocationData?.address !== "")
          ? `${(data || previousLocationData) && (data || previousLocationData)?.orderFor && (data || previousLocationData)?.orderFor === "takeaway"
            ? "take_away"
            : previousLocationData
              ? "picked_address"
              : `${data?.addressType !== undefined ? "picked_address" : "no_address"}`
          }`
          : "no_address"

    } else {
      if ((data && data?.address !== "") || (previousLocationData && previousLocationData?.address !== "")) {
        sourceType = `${(data || previousLocationData) &&
          (data || previousLocationData)?.orderFor &&
          (data || previousLocationData)?.orderFor === "takeaway"
          ? "take_away"
          : (selectedAddressType && selectedAddressType === "SAVED_ADDRESS") ? "saved_address" :
            (data?.address !== "") ? "picked_address" : "no_address"
          }`
      } else {
        sourceType = "no_address"
      }
    }
    return sourceType;
  }

  const handleSetIsDistanceCategory = (distance) => {
    const type = getSelectedAddressType();
    switch (type) {
      case "saved_address":
      case "picked_address":
        let localMinDistance = LocalStorage.get("minDeliveryDistance");
        LocalStorage.set("isDistanceCategory", localMinDistance ? Number(distance) <= Number(localMinDistance) : false);
        setIsDistanceCategory(localMinDistance ? Number(distance) <= Number(localMinDistance) : false);
        break;
      case "take_away":
      case "no_address":
        LocalStorage.set("isDistanceCategory", false);
        setIsDistanceCategory(false);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    LocalStorage.set("localMenuList", itemData);
  }, [itemData])
  return (
    <>
      {/* {console.log("otpppppppp", active)} */}
      <Helmet>
        <title>{homePageMeta.meta_title}</title>
        {/* <meta name="description" content={homePageMeta.desciptions} />
        <meta name="keywords" content={homePageMeta.keywords} /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
      </Helmet>
      {isLoading === false ? (
        <>
          <div id="scroll" className="stick-to-top product-list" />

          {innerWidth > 1023 && (
            <Header
              walletStatus
              showButton
              LoginCLick={LoginCLick}
              SigninClick={SigninClick}
              deliveryClick={deliveryClick}
              handleScrollToCategory={handleScrollToCategory}
              profileData={footerData}
            />
          )}

          {delpop && (
            <AlertPopup
              title="!!!"
              description={"Please select location to order later!"}
              okayClick={() => setDelPop(false)}
            />
          )}

          {showScroll && (
            <div className="scrollBtnDiv">
              <div
                onClick={scrollToTop}
                className={`${cartData.count > 0 && bottomShow
                  ? "scrollBtnDiv_scrollBtn1"
                  : "scrollBtnDiv_scrollBtn"
                  }`}
              >
                <div>
                  <div>
                    <img
                      className="scrollBtnDiv_img"
                      src={downArrow}
                      alt="downArrow"
                    />
                  </div>
                  <h1 className="scrollBtnDiv_goToTop">Go to Top</h1>
                </div>
              </div>
            </div>
          )}

          {customizePopup && (
            <RepeatCustomizationPopup
              addonPopup={addonPopup}
              customizeData={customizeData}
              onUpdateContent={onUpdateContent}
              addNewProduct={addNewProduct}
              handleClose={handleClose}
              repeatCustom={repeatCustom}
              item={itemData}
            // setIsRepeat={setIsRepeat}
            // isRepeat={isRepeat}
            />
          )}

          {productModal && (
            <AddToCart
              handleVideo={handleVideo}
              setIsVideo={setIsVideo}
              isVideo={isVideo}
              Content={productDataAdd}
              item={productDataAdd}
              OnDataChecked={onDataChecked}
              Extras={extras}
              onUpdateData={onDataUpdate}
              ItemList={itemList}
              CartShow={cartShow}
              IsChecked={isChecked}
              OnItemCheck={onItemCheck}
              AddSubCount={productDataAdd.count} // 1
              CartArr={cartArr}
              SubCount={subCount}
              isAddons={isAddons}
              productModal={productModal}
              closeModal={handleProductModal}
              addNewCartBtnLoader={addNewCartBtnLoader}
              setAddNewBtnLoader={setAddNewBtnLoader}
              onChangeProductData={onChangeProductData}
              itemDataChange={itemDataChange}
            />
          )}

          {loginFlow && (
            <Modal
              modalCustomClass="modalCustomEdit1"
              onCloseHandler={closeHandle}
              hideClose={stat === "stage2" || stat === "stage3"}
              isMobileFullLayout
            >
              {stat === "stage1" && (
                <CreateAccount
                  closeHandle={closeHandle}
                  onNav={onNav}
                  errorTxt1={errorTxt1}
                  setErrorTxt1={setErrorTxt1}
                  getMobileData={getMobileData}
                  // onValidate={() => onValidate()}
                  isSignIn={isSignIn}
                  setIsSignIn={setIsSignIn}
                  isLoginBtnLoading={isLoginBtnLoading}
                />
              )}
              {stat === "stage2" && (
                <OtpVerification
                  autoReadOtpValue={otp}
                  mobile={mobileNumber}
                  displayMobile={displayMobileNumber}
                  errorTxt={errorTxt}
                  onNav={onNav}
                  getResendData={getResendData}
                  getOtpData={getOtpData}
                  successTxt={successTxt}
                />
              )}
              {stat === "stage3" && (
                <BasicDetails
                  errorMsg={errorMsg}
                  onNav={onNav}
                  getBasicDetails={getBasicDetails}
                  data={basicDetailsData}
                />
              )}
            </Modal>
          )}
          {/* {interestModal && <Locationinterested close={handleInterestModal} />} */}

          {deliveryFlow && (
            <>
              {innerWidth >= 1023 ? (
                <Modal
                  modalCustomClass={`modalCustomEdit11 product-list-modal`}
                  onCloseHandler={closedelivery}
                  hideClose={stat === "stage2" || stat === "stage3"}
                  isMobileFullLayout
                >
                  <div className="deliveryContainer">
                    <div className="deliveryContainer_right">
                      <h2 className="deliveryContainer_heading">
                        Enter Location
                      </h2>
                      <div className="deliveryContainer_switches map-top-switch-wrapper">
                        <Switch
                          data={switchData}
                          active={active}
                          onFilter={onChangeMode}
                        />
                        <div className="deliveryContainer_switches_gap" />
                        <RadioButton
                          data={radioData}
                          activeOrder={activeOrder}
                          onChange={onChangeOrder1}
                        />
                      </div>

                      <div className="">
                        {activeOrder === "orderLater" &&
                          (canShowLater ||
                            inputData !== "" ||
                            latLng.lat !== "") && (
                            <div className="deliveryContainer_orderLater">
                              <div className="deliveryContainer_orderLater_container">
                                <div className="deliveryContainer_orderLater_date">
                                  <DateTimePicker
                                    onChange={(value) => onChangeDate(value)}
                                    value={date}
                                    className="deliveryContainer_orderLater_datetime"
                                    format="yyyy-MM-dd"
                                    placeholderText="Enter date"
                                    // minDate={
                                    //   new Date(
                                    //     new Date().getTime() +
                                    //       LocalStorage.get("Outlet_Details")
                                    //         .min_preordering_day *
                                    //         24 *
                                    //         60 *
                                    //         60 *
                                    //         1000
                                    //   )
                                    // }
                                    minDate={new Date()}
                                    maxDate={
                                      new Date(
                                        new Date().getTime() +
                                        (LocalStorage.get("Outlet_Details")
                                          .max_preordering_day -
                                          1) *
                                        24 *
                                        60 *
                                        60 *
                                        1000
                                      )
                                    }
                                    disabled={latLng.lat === "" ? true : false}
                                    clearIcon={date === "" ? "" : "x "}
                                    yearPlaceholder="Enter date"
                                    monthPlaceholder=""
                                    dayPlaceholder=""
                                  />
                                </div>
                              </div>

                              <div className="deliveryContainer_orderLater_container">
                                {intervalModal && date !== "" && (
                                  <div className="deliveryContainer_orderLater_container_drop">
                                    <IntervalDrop
                                      setInterval={setInterval}
                                      interval={interval}
                                      intervalModal={intervalModal}
                                      setIntervalModal={setIntervalModal}
                                      setTime={setTime}
                                      timeInterval={intDate}
                                      TimeInterval={TimeInterval}
                                    />
                                  </div>
                                )}

                                <div className="deliveryContainer_orderLater_time">
                                  <p
                                    onClick={() => {
                                      setIntervalModal(!intervalModal);
                                    }}
                                    className="deliveryContainer_orderLater_datetime"
                                  >
                                    {interval === ""
                                      ? "Select Interval"
                                      : interval}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        <RenderMaps
                          callOutlet={callOutlet}
                          onConfirmLocation={onConfirmLocation}
                          latLng={latLng}
                          activeTheme={active}
                          selectSavedAddress={selectSavedAddress}
                          loader={outletLoader}
                          onMapClose={onMapClose}
                        />
                        {alertData && (
                          <h1 className="deliveryContainer_delivery_phrase">
                            Please Enter location !!
                          </h1>
                        )}

                        {active !== "delivery" &&
                          outlets &&
                          outlets.length > 0 && (
                            <>
                              <h4 className="deliveryContainer_error_recommend">
                                Select Outlet to order from
                              </h4>
                              <div className="deliveryContainer_store-list">
                                {outlets.map((item) => {
                                  return (
                                    <StoreCard
                                      data={item}
                                      theme={active}
                                      onClickStore={onCLickStoreData}
                                    />
                                  );
                                })}
                              </div>
                            </>
                          )}
                        {errorData && (
                          <>
                            <div className="deliveryContainer_error no-outlet-error-container">
                              <h2 className="deliveryContainer_error_text no-outlet-error-text">
                                Sorry! No outlet found near to your location!
                                Please click here if you want BBK outlet to be
                                open near to your location -
                                <span
                                  className="deliveryContainer_interest-text"
                                  onClick={handleIamInterested}
                                >
                                  &nbsp;Yes I Want
                                </span>
                              </h2>
                              <img
                                src={LocationSearch}
                                width="132"
                                // height="117"
                                height="90"
                                alt="Location"
                              />
                            </div>
                          </>
                        )}
                        {errorData1 && countData && countData.count && (
                          <>
                            <h4 className="deliveryContainer_error_recommend">
                              Recommend for take away
                            </h4>
                            <div className="deliveryContainer_error">
                              <p className="deliveryContainer_error_text1">
                                Hey ! found {countData.count} bbk restaurant{" "}
                                {countData.distance} km from your location would
                                you do take away from these restaurants
                              </p>
                              <div className="deliveryContainer_error_button">
                                <Button
                                  content="Search Now"
                                  onClick={() => onStoreSelect()}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>
              ) : (
                <>
                  {modalOpen ? (
                    <div className="modal-overlay">
                      <div className="modal-content" id="takeaway">
                        {/* <div className="wallet_header">
                          <div className="takeaway_section">
                            <img
                              src={back}
                              className="myOrders_section_backimg"
                              onClick={() => handleGoBack()}
                              alt=""
                            />
                            <h3 className="myOrders_section_heading">
                              Takeaway
                            </h3>
                          </div>
                        </div> */}
                        {errorData && (
                          <>
                            <TakeawayView
                              outlets={outlets}
                              active={active}
                              onClickStore={onCLickStoreData}
                              closeTakeAway={() => {
                                handleGoBack();
                                closeTakeAway()
                              }}
                              takeawayAlert={takeawayAlertModal}
                              onCloseAlert={onClosetakeAwayAlert}
                              handleIamInterested={handleIamInterested}
                            />
                            {/* <div className="deliveryContainer_error">
                              <h2 className="deliveryContainer_error_text">
                                Sorry! No outlet found near to your location!
                                Please click here if you want BBK outlet to be
                                open near to your location -
                                <span
                                  className="deliveryContainer_interest-text"
                                  onClick={handleIamInterested}
                                >
                                  &nbsp;Yes I Want
                                </span>
                              </h2>
                              <img
                                src={LocationSearch}
                                width="132"
                                height="117"
                                alt="Location"
                              />
                            </div> */}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
          {alertPopup && (
            <AlertPopup
              title="Sorry!!"
              description={alertPopup}
              okayClick={() => onCloseAlert()}
              alertCart={alertLocationPopup}
              close={() => onCloseAlert()}
            />
          )}
          <div className="productBody">
            {innerWidth <= 1023 && (
              <Button
                content={" " + " Browse Menu"}
                onClick={() => categoryShow()}
                // disabled={mobile === '' && true}
                image={BrowseMenu}
                autoFocus
                className={`productBody_categoryBtn ${cartData.count > 0 ? "top" : ""
                  }`}
                customClass="productBody_categoryBtn_content"
              />
            )}

            {innerWidth <= 1023 && (
              <div className="productBody_wholeData">
                <HeaderMobile
                  showButton={showButton}
                  showLocation={showLocation}
                  customClass="productBody_data_wholeWrapper_header"
                />
                <div className="productBody_method_type">
                  <div
                    className={`productBody_method_type_left ${activeMethod === "delivery" ? "active_method" : ""
                      }`}
                    onClick={handleClickFastDelivery}
                  >
                    <div className="fast_delivery">
                      <img className="fast_delivery_img" src={fastdelivery} />
                    </div>
                    <div className="fast_delivery_text">
                      <span className="fast_delivery_title">Delivery</span>
                      <span className="fast_delivery_desc">{preparationTime && `(${preparationTime} min)`}</span>
                    </div>
                  </div>
                  <div
                    className={`productBody_method_type_right ${activeMethod === "takeaway" ? "active_method" : ""
                      }`}
                    onClick={handleClickTakeaway}
                  >
                    <div className="takeaway_method">
                      <img
                        className="takeaway_method_img"
                        src={takeaway_icon}
                      />
                    </div>
                    <div className="takeaway_method_text">
                      <span className="takeaway_method_title">Takeaway</span>
                      <span className="takeaway_method_desc">Select Store</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {innerWidth <= 1023 && (
              <div className="productBody_data_wholeWrapper">
                <div className="productBody_data_tags">
                  {!(location.pathname === ROUTES.SEARCH) && (
                    <img
                      src={searchImg}
                      className="headerMobile_end_search"
                      onClick={() => onClickMenu("/search")}
                      alt=""
                    />
                  )}
                  {console.log("itemTags", itemTags, isDistanceCategory)}
                  <ToggleSwitch
                    isNotDelivery={true}
                    data={itemTags}
                    onSwitch={onSwitch}
                    noToggle
                    bestSeller={toggleBestSeller}
                    onSwitchBestSeller={onSwitchBestSeller}
                    distanceCategory={distanceCategory}
                    distanceCategoryOnClick={distanceCategoryOnClick}
                    isDistanceCategory={isDistanceCategory && isDistanceItemAvail}
                  />
                </div>
              </div>
            )}

            {innerWidth <= 1023 &&
              takeAwayModal &&
              active !== "delivery" &&
              outlets &&
              outlets.length > 0 && !errorData && (
                <TakeawayView
                  outlets={outlets}
                  active={active}
                  onClickStore={onCLickStoreData}
                  closeTakeAway={closeTakeAway}
                  takeawayAlert={takeawayAlertModal}
                  onCloseAlert={onClosetakeAwayAlert}
                  handleIamInterested={handleIamInterested}
                />
              )}

            {categoryModal && (
              <Modal
                hideClose
                bottomRightPopUp={true}
                modalCustomClass="modalCustom"
                onCloseHandler={categoryShow}
              >
                <div className="modalCustom_modal">
                  <div className="productBody_categoryList">
                    {categoryData.map((item) => {
                      return (
                        <div
                          className={`${item.itemData.length == 0
                            ? `productBody_categoryList_noItem`
                            : `productBody_categoryList_item`
                            }`}
                          onClick={() => onClickCategory(item, "mobile")}
                        >
                          <h4 className="productBody_categoryList_item_nameLabel">
                            {item.category_name}
                          </h4>
                          <h4 className="productBody_categoryList_item_subLabel">
                            {item.itemData.length}
                          </h4>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Modal>
            )}
            {/* <div className="carousel_container_whole"> */}
            {carousalMedia &&
              carousalMedia?.length &&
              carousalMedia.length > 0 ? (
              <Carousel
                swipeable={true}
                draggable={false}
                showDots={carousalMedia.length > 1}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={carousalMedia.length > 0}
                renderDotsOutside={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customDot={<CustomDot />}
              >
                {carousalMedia.map((item) => {
                  return (
                    <div
                      className="carousalContainer"
                      onClick={() => onBannerClick(item)}
                    >
                      <img
                        src={item.banner_image}
                        alt="bannerImg"
                        className="carousalContainer_imgContainer"
                      />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              ""
            )}

            {/* </div> */}
            {innerWidth <= 1023 && (
              <>
                <div className="productBody_productcategory">
                  <h3 className="productBody_productcategory_categories">
                    Order Fresh Dum Cooked Handi Biryani
                  </h3>
                  <div className="productBody_productcategory_ImgContainer">
                    {categoryData.map(
                      (item) =>
                        item.homepage_status === "1" && (
                          <>
                            <div
                              className="productBody_productcategory_item"
                              onClick={() => {
                                handleClick(item.category_name);
                              }}
                              ref={(el) =>
                                (scrollElements.current[item.category_id] = el)
                              }
                            >
                              <div className="productBody_productcategory_item_img_div">
                                <img
                                  className="productBody_productcategory_item_img"
                                  src={item.category_image_url}
                                  alt="category_image"
                                />
                              </div>
                              <p className="productBody_productcategory_item_title">
                                {item.category_name}
                              </p>
                            </div>
                          </>
                        )
                    )}
                  </div>
                </div>
              </>
            )}
            {innerWidth <= 1023 && (
              <div className="productBody_sploffer">Special Offers</div>
            )}
            <div className="productBody_data" id="categorySection" ref={referProduct}>
              {/* {delTime && delTime.deliveryTime && innerWidth >= 1023 && (
            <DurationCard data={delTime} />
          )} */}

              {offerData && offerData.length > 0 && innerWidth >= 1023 && (
                <h1 className="productBody_nameLabel">Offers</h1>
              )}
              {offerData && offerData.length > 0 && (
                <HorizontalScroll contentData={offerDataValue} />
              )}

              <div id="scroll2" />
              {onBannerData && innerWidth >= 1023 && (
                <HorizontalScroll contentData={onBannerData} />
              )}
              {LocalStorage.get("isLoggedIn") === true &&
                lastOrder &&
                lastOrder.length > 0 &&
                newDataMain.length === 0 && (
                  <>
                    <h2 className="productBody_nameLabel">Last Ordered</h2>
                    <LastOrderContain
                      data={lastOrder}
                      wholeClass={currentData}
                    />
                  </>
                )}

              {LocalStorage.get("isLoggedIn") === true &&
                newDataMain &&
                newDataMain.length > 0 && (
                  <>
                    <div className="productBody_order">
                      <p className="productBody_order_nameLabel">
                        {newDataMain && newDataMain.length > 1
                          ? "Multiple Active Order"
                          : "Active Order"}
                      </p>
                      <div
                        className="productBody_order_btnImgDiv"
                        onClick={() => history.push(ROUTES.MYORDERS)}
                      >
                        <h2 className="productBody_order_btnImgDiv_nameLabel">
                          View more
                        </h2>
                        <img
                          src={righting1}
                          className="productBody_order_btnImgDiv_btnImg"
                          alt="righting1"
                        />
                      </div>
                    </div>

                    {newDataMain &&
                      newDataMain &&
                      newDataMain.length > 0 &&
                      newDataMain.map((data, index) => {
                        if (index < 1) {
                          return (
                            <MultipleOrderCard
                              main={data}
                              newData={newDataMain}
                            />
                          );
                        }
                      })}
                  </>
                )}

              {categoryValue && categoryValue.length > 0 && (
                <>
                  <div className="productBody_category">
                    {innerWidth >= 1023 && (
                      <h1
                        ref={scrollToCategory}
                        className="productBody_category_nameLabel"
                      >
                        Categories
                      </h1>
                    )}
                    {innerWidth >= 1023 && (
                      <>
                        <ToggleSwitch
                          isNotDelivery={true}
                          data={itemTags}
                          onSwitch={onSwitch}
                          noToggle
                          bestSeller={toggleBestSeller}
                          onSwitchBestSeller={onSwitchBestSeller}
                          distanceCategory={distanceCategory}
                          distanceCategoryOnClick={distanceCategoryOnClick}
                          isDistanceCategory={isDistanceCategory && isDistanceItemAvail}
                        />
                      </>
                    )}
                  </div>

                  {innerWidth >= 1023 ? (
                    categoryStatus ? (
                      <div
                        className="productBody_categoryData"
                        id="categoryData"
                      >
                        <CategoryCard
                          sticky={true}
                          onClickCategory={onClickCategory}
                          data={categoryValue}
                          selectedData={selectedCategory}
                        />
                      </div>
                    ) : (
                      <div
                        className="productBody_categoryData"
                        id="categoryData"
                      >
                        <CategoryCard
                          sticky={false}
                          onClickCategory={onClickCategory}
                          data={categoryValue}
                          selectedData={selectedCategory}
                        />
                      </div>
                      // <div className="loaderProduct">
                      //   <Loader />
                      // </div>
                    )
                  ) : (
                    <div className="loaderProduct">
                      {innerWidth > 1023 && <Loader />}
                    </div>
                  )}

                  <div id="sticky"></div>
                </>
              )}
              {innerWidth >= 1023 && <div className="productBody_divider" />}
              <div className="productBody_product">
                {itemData &&
                  itemData.length > 0 &&
                  itemData.map((item) => {
                    return (
                      <>
                        {item?.itemData?.length > 0 && (
                          <>
                            <p
                              onClick={() => {
                                handleClick(item.category_name);
                              }}
                              ref={(el) =>
                                (scrollElements.current[item.category_id] = el)
                              }
                              className="productBody_category_nameLabel"
                            >
                              {item.category_name}
                            </p>
                            <ProductCardlisting
                              modalShow={modalShow}
                              data={item.itemData}
                              addCount={addCount}
                              subCount={subCount}
                              onUpdateContent={onUpdateContent}
                              netcoreEvent={netcoreEvent}
                              addonPopup={addonPopup}
                              setIsNegate={setIsNegate}
                              isNegate={isNegate}
                              change={true}
                              handleAlertModal={handleAlertModal}
                              addNewProduct={addNewProduct}
                              addNewProductPage={addNewProductPage}
                              setLoderById={setLoderById}
                              loaderById={loaderById}
                              homeData={categoryData}
                            />
                          </>
                        )}
                      </>
                    );
                  })}
              </div>

              {loginStatus && (
                <div className="productBody_subscription">
                  <Subsription
                    data={subscribe}
                    onChange={onSubscribe}
                    onClickSubmit={onClickSubmit}
                    isNotDelivery={true}
                  />
                </div>
              )}
            </div>

            {showPopup && showPopup.offer_name && (
              <OfferPopup data={showPopup} onCloseClick={onCloseClick} />
            )}
            {innerWidth > 1023 && (
              <Cartsidebar
                cartData={cartData}
                itemData={itemData}
                homeData={homeData}
                updatedCartData={updatedCartData}
                onUpdateContent={onUpdateContent}
                handleCartSidebar={handleCartSidebar}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
                addonPopup={addonPopup}
                isNegate={isNegate}
                setIsNegate={setIsNegate}
                change={false}
                showCartSidebar={showCartSidebar}
                addNewProduct={addNewProduct}
              />
            )}

            {multipleAddonAlert && (
              <AlertPopup
                title="Sorry!!"
                description={
                  "This item has multiple customisations added. Please remove item from cart."
                }
                okayClick={() => handleAlertModal()}
                alertCart={true}
              />
            )}
            {toaster.isShow && (
              <Toaster closeToast={closeToast} toastText={toaster.toastText} />
            )}
            {innerWidth <= 1023 && bottomShow ? (
              <Footer
                data={footerData && footerData}
                customClass="productBody_footer"
              />
            ) : (
              <>
                {console.log("footerData", footerData)}
                <Footer data={footerData && footerData} />
              </>
            )}

            {cartData.count > 0 && bottomShow && (
              <ItemDetailFooter
                LoginCLick={LoginCLick}
                data={cartData}
                deliveryClick={deliveryClick}
                onCartClick={onCartClick}
                bottomShow={bottomShow}
                setBottomShow={setBottomShow}
                handlerFunction={handlerFunction}
                bottomshow={bottomShow}
              />
            )}
          </div>
          <SelectLocationAlert
            alertLocation={alertLocation}
            handleLocationAlertModal={handleLocationAlertModal}
          />
        </>
      ) : (
        <div className="loader-gif">
          <img className="loader-gif__img" src={loaderGif} alt="loader" />
        </div>
        // ""
      )}
    </>
  );
};
export default ProductListing;
