import React, { useEffect, useState } from "react";
import "./index.scss";
import { offers } from "../../actions/offers";
import percent from "../../assets/images/percent.svg";
import productImg from "../../assets/images/productImg";
import { LocalStorage } from "../../utils/storage";
import Toaster from "../../components/toaster";
import OfferPopup from "../../containers/offerPopup";

export default function MyprofileOffers() {
  const [offersShow, setOfferShow] = useState([]);
  const [showOffInfo, setShowOffInfo] = useState(false);
  const [showOffData, setShowOffData] = useState({});
  const [payMode, setPayMode] = useState("");
  const outlet = LocalStorage.get("outlet_id");
  const location = LocalStorage.get("location");

  const payload = {
    channel: location.orderFor === "delivery" ? "delivery" : "take_away",
    online_payment: payMode === "cod" || payMode === "COD" ? false : true,
    outlet_id: outlet,
  };
  const [isMsg, setIsMsg] = useState({
    toastType: false,
    toastText: "",
    isShow: false,
  });
  const ShowParticular = (i) => {
    setShowOffInfo(true);
    setShowOffData(i);
  };
  const closeParticular = () => {
    setShowOffInfo(false);
  };

  const [isShowMessage, setisShowMessage] = useState(false);

  const offerListing = () => {
    offers(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setOfferShow([
          ...res?.content?.available_offer,
          ...res?.content?.unavailable_offer,
        ]);
        LocalStorage.set("offersShow", [
          ...res?.content?.available_offer,
          ...res?.content?.unavailable_offer,
        ]);
      }
    });
  };

  useEffect(() => {
    offerListing();
  }, []);

  const onCopy = (coupon_code, isShowMessage) => {
    navigator.clipboard.writeText(coupon_code);
    !isShowMessage &&
      setIsMsg({
        toastType: true,
        toastText: `Code ${coupon_code} copied`,
        isShow: true,
      });
  };

  const innerWidth = window.innerWidth;

  return (
    <div className="profile_offer">
      <div className="profile_offer_header_wrapper">
        <img src={percent} alt="Percent" />
        <h2 className="profile_offer_header_wrapper_data">Offers</h2>
      </div>
      <div className="profile_offer_wholewrapper">
        {offersShow.map((offer) => (
          <div key={offer._id}>
            <div className="profile_offer_wholewrapper_container">
              <div className="profile_offer_wholewrapper_design">
                <div
                  className="profile_offer_wholewrapper_design_"
                  key={offer._id}
                >
                  <img
                    src={offer.image || productImg}
                    alt="Offer"
                    className="profile_offer_wholewrapper_design_img"
                  />
                </div>
                <div className="profile_offer_wholewrapper_design_center">
                  <p className="profile_offer_wholewrapper_design_center_coupon">
                    {offer.coupon_code}
                  </p>
                  {isMsg.isShow && (
                    <Toaster
                      toastType={isMsg.toastType}
                      toastText={isMsg.toastText}
                      closeToast={() =>
                        setIsMsg({
                          isShow: false,
                        })
                      }
                    />
                  )}
                  {innerWidth === 1023 ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: offer.description,
                      }}
                      className="profile_offer_wholewrapper_design_center_phrase"
                    ></p>
                  ) : (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: offer.description,
                        }}
                        className="profile_offer_wholewrapper_design_center_phrase"
                      ></p>
                      <p className="profile_offer_wholewrapper_design_center_description">
                        <p className="profile_offer_wholewrapper_design_center_description_one">
                          {offer.short_description}
                        </p>
                        <p
                          className="profile_offer_wholewrapper_design_center_description_two"
                          onClick={() => ShowParticular(offer)}
                        >
                          T&C
                        </p>
                      </p>
                      {showOffInfo && (
                        <OfferPopup
                          data={showOffData}
                          onCloseClick={closeParticular}
                        />
                      )}
                    </>
                  )}
                  <button
                    className="profile_offer_wholewrapper_design_rightbar_button"
                    customClass="profile_offer_wholewrapper_design_rightbar_button_content"
                    onClick={() => onCopy(offer.coupon_code, isShowMessage)}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
