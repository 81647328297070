import React from "react";
import "./index.scss";
import Close from "../../assets/images/close.svg";
import Delivery from "../../assets/images/failure.svg";
import Button from "../button";
import Modal from "../modal";

const FailureCard = (props) => {
  const { onCLoseClick, onTryAgain, onExploreFood } = props;
  const { innerWidth } = window;

  return (
    <>
      {innerWidth <= 1023 ? (
        <Modal hideClose contentCustomClass="failurecard_wholeWrapper">
          <div className="failurecard">
            <div className="failurecard_head">
              <h3 className="failurecard_heading">Payment Failed</h3>
              <img
                src={Close}
                alt="close"
                height="20"
                width="20"
                onClick={onCLoseClick}
                classname="failurecard_img"
              />
            </div>
            <div className="failurecard_content">
              <div className="failurecard_mainDiv">
                <img
                  src={Delivery}
                  alt="delivery"
                  width="300"
                  height="300"
                  className="failurecard_content_img"
                />
                <h4 className="failurecard_caption">Payment Failed</h4>
                <h4 className="failurecard_text">
                  Your Payment Failed.Try again later.
                </h4>
              </div>
              <div className="failurecard_buttons">
                <Button
                  content="Try again"
                  onClick={onTryAgain}
                  className="failurecard_tryAgain"
                  customClass="failurecard_tryAgain_btn"
                />
                <Button
                  content="Explore Foods"
                  onClick={onExploreFood}
                  className="failurecard_explore"
                  customClass="failurecard_explore_btn"
                />
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal hideClose contentCustomClass="failurecard_wholeWrapper">
          <div className="failurecard">
            <div className="failurecard_head">
              <h3 className="failurecard_heading">Payment Failed</h3>
              <img
                src={Close}
                alt="close"
                height="20"
                width="20"
                onClick={onCLoseClick}
                classname="failurecard_img"
              />
            </div>
            <div className="failurecard_content">
              <img
                src={Delivery}
                alt="delivery"
                width="300"
                height="300"
                className="failurecard_content_img"
              />
              <h4 className="failurecard_caption">Payment Failed</h4>
              <h4 className="failurecard_text">
                Your Payment Failed.Try again later.
              </h4>
              <div className="failurecard_buttons">
                <Button
                  content="Try again"
                  onClick={onTryAgain}
                  className="failurecard_tryAgain"
                  customClass="failurecard_tryAgain_btn"
                />
                <Button
                  content="Explore Foods"
                  onClick={onExploreFood}
                  className="failurecard_explore"
                  customClass="failurecard_explore_btn"
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FailureCard;
