/* eslint-disable no-undef */
import React, { useEffect } from "react";
import "./index.scss";
import DetailImg from "../../assets/images/detail.png";
import close from "../../assets/images/close.svg";
import timer from "../../assets/images/timer2.svg";
import offerFood from "../../assets/images/offerfood.svg";
import servePeople from "../../assets/images/servepeople.svg";
import info from "../../assets/images/infodelivery.svg";
import VideoButton from "../../components/video-button";
import { Analytics } from "../../helpers/analytics";
import Back from "../../assets/images/back.svg";
import { LocalStorage } from "../../utils/storage";
import { viewProductNetCoreEvent } from "../../helpers/netcore";
import { handleIsShowStrikeAmt } from "../../helpers/sharedFunction";
import ImgNotAvailable from "../../assets/images/image-not-available.jpg";
import { useHistory } from "react-router-dom";

const ProductDetail = (props) => {
  const { data, onBackClick, setIsVideo, isVideo, handleVideo } = props;
  const { innerWidth } = window;
  const history = useHistory();

  useEffect(() => {
    // if (LocalStorage.get("isLoggedIn")) {
    //   viewProductNetCoreEvent(data);
    // }
    Analytics.viewCartItemEvent(data)
  }, []);
  const handleGoBack = () => history.goBack();
  return (
    <div className="productDetail">
      {innerWidth <= 1023 ? (
        <>
          <img
            src={
              data.item_image_url && data.item_image_url !== ""
                ? data.item_image_url
                : DetailImg
            }
            alt="detail image"
            className="productDetail_img"

          />
          <div className="productDetail_right" onClick={onBackClick}>
            <img
              src={close}
              alt="close"
              className="productDetail_right_close"
              onClick={() => handleGoBack()}
            />
          </div>
          <div className="productDetail_data">
            <div
              className={`productDetail_data_type ${
                data.item_tags[0] === "2" && "nonVeg"
              }`}
            />
            <div className="productDetail_data_wholeWrapper">
              <p className="productDetail_data_name">{data.item_name}</p>
              <p className="productDetail_data_price">
              {handleIsShowStrikeAmt(data?.item_strike_through_price ,data.item_final_price ) &&
               <span className='item_descriptionContainer_strike-price'>&#8377;{data?.item_strike_through_price}</span>}
                {/* {" "} */}
                &#8377;{data.item_final_price}
              </p>
            </div>
          </div>
          <p className="productDetail_description">
            {data.item_long_description}
          </p>
          {/* <div className="productDetail_wholeWrapper">
            <VideoButton link={data?.video_link} />
            <p className="productDetail_wholeWrapper_data_price">
              {" "}
              &#8377;{data.item_final_price}
            </p>
          </div> */}
          <div className="productDetail_orderData">
           {Number(data.preparation_time) !== 0 && <div className="productDetail_orderData_item">
              <img src={timer} className="productDetail_orderData_item_img" alt="timer"/>
              <div className="productDetail_orderData_wholeWrapper">
                <p className="productDetail_orderData_item_label">
                  {data.preparation_time} min preparation
                </p>
                <img
                  src={info}
                  className=".productDetail_orderData_item_infoImg"
                  alt=""
                />
              </div>
            </div>}
            {Number(data?.no_of_times_ordered) === 0 ? '' :<>
            <div className="productDetail_orderData_item">
              <img
                src={offerFood}
                className="productDetail_orderData_item_img"
                alt="offerFood"
              />
              <p className="productDetail_orderData_item_label">
                {data.no_of_times_ordered}  People order in last week
              </p>
            </div>
            </>}
            <div className="productDetail_orderData_item">
              <img
                src={servePeople}
                className="productDetail_orderData_item_img"
                alt="servePeople"
              />
              <p className="productDetail_orderData_item_label">
                Serve for {data.serve_for ? data.serve_for :""}
              </p>
            </div>
          </div>
          {data?.video_link &&
          <VideoButton handleVideo={handleVideo} link={data?.video_link} />}
        </>
      ) : (
        <>
          {/* <div className="productDetail_head"> */}
            {/* {innerWidth < 1023 && (
          <img
            src={Back}
            width='14'
            height='14'
            className='back_img'
            onClick={onBackClick}
          />
        )} */}
            {/* <p className="productDetail_headLabel">{data.item_name}</p> */}
          {/* </div> */}
          <img
            src={
              data.item_image_url && data.item_image_url !== ""
                ? data.item_image_url
                : ImgNotAvailable
            }
            onError={(e) => { e.target.onerror = null; e.target.src = ImgNotAvailable}}
            alt="detail image"
            className="productDetail_img"
          />
          <div className="productDetail_data">
            <div style={{display:'flex',alignItems:'center',width:'40rem'}}>
            <div
              className={`productDetail_data_type ${
                data.item_tags[0] === "2" && "nonVeg"
              }`}
            />
            <p className="productDetail_data_name">{data.item_name}</p>
            </div>
            <div>
            <p className="productDetail_data_price">
            {handleIsShowStrikeAmt(data?.item_strike_through_price ,data.item_final_price ) && 
            <span className='item_descriptionContainer_strike-price'>&#8377;{data?.item_strike_through_price}</span>}
              {/* {" "} */}
              &#8377;{data.item_final_price}
            </p>
            </div>
          </div>
          <p className="productDetail_description">
            {data.item_long_description}
          </p>
          <div className="productDetail_orderData">
          {Number(data.preparation_time)  !== 0 &&  <div className="productDetail_orderData_item">
              <img src={timer} className="productDetail_orderData_item_img" alt="timer" />
              <p className="productDetail_orderData_item_label">
                {data.preparation_time} min preparation
              </p>
            </div>}
            {Number(data?.no_of_times_ordered) === 0 ? '' :<>
            {Number(data.preparation_time)  !== 0 && <div className="productDetail_orderData_divider" />}
            <div className="productDetail_orderData_item">
              <img
                src={offerFood}
                className="productDetail_orderData_item_img"
                alt="offerFood"
              />
              <p className="productDetail_orderData_item_label">
                {data.no_of_times_ordered} People last order in last week
              </p>
            </div></>}
            <div className="productDetail_orderData_divider" />
            <div className="productDetail_orderData_item">
              <img
                src={servePeople}
                className="productDetail_orderData_item_img"
                alt="servePeople"
              />
              <p className="productDetail_orderData_item_label">
              Serve for {data.serve_for ? data.serve_for :""}

              </p>
            </div>
          </div>
          {data?.video_link && <VideoButton
            handleVideo={handleVideo}
            link={
              data?.video_link && data?.video_link
                ? data?.video_link
                : "https://www.youtube.com/watch?v=ybirL3EtMb8"
            }
          />}
        </>
      )}
    </div>
  );
};
export default ProductDetail;
