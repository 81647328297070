import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  VoucherCodeValidation,
  NumberValidation,
  MobileNumberValidation,
} from '../../utils/validation';

class Input extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { currentFieldFocus, id } = this.props;
    if (this.inputRef && this.inputRef.current && this.inputRef.current.focus) {
      if (currentFieldFocus === id) {
        this.inputRef.current.focus();
      }
    }
  }

  onChange = (e) => {
    const { target } = e;
    const { value } = target;
    const { id } = target;
    const { onChange, type, isNumber, isMobileNumber, validationChange } =
      this.props;
    let validation = true;

    if (isNumber && value) {
      validation = NumberValidation(value);
    }
    if (type === 'alphaNumeric' && value) {
      validation = VoucherCodeValidation(value);
    }
    if (type === 'time' && value) {
      onChange(id, value, e);
    }
    if (isMobileNumber && value) {
      validation = MobileNumberValidation(value);
    }
    if (validation && validationChange) {
      onChange(id, value, e);
      validationChange(id, value);
    }
    if (validation) {
      onChange(id, value, e);
    }
  };

  render() {
    const {
      customClass,
      placeholder,
      id,
      value,
      type,
      readOnly,
      theme,
      onFocus,
      onKeyUp,
      onpaste,
      disabled,
      onBlur,
      onKeyPress,
      autoFocus,
      newCustomClass,
      onClick
    } = this.props;
    return (
      <input
        type={type}
        className={`input ${customClass} ${theme} ${newCustomClass}`}
        placeholder={placeholder}
        onChange={this.onChange}
        onClick={onClick}
        id={id}
        value={value}
        ref={this.inputRef}
        readOnly={readOnly}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        onPaste={onpaste}
        disabled={disabled}
        onBlur={onBlur}
        autoFocus={autoFocus}
      />
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  customClass: PropTypes.string,
};

Input.defaultProps = {
  id: '',
  type: '',
  value: '',
  onChange: () => {},
  customClass: '',
  disabled: false,
};

export default Input;
