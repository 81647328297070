import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';

// export const getStores = (callback) => {
//     fetchCall(API_CONSTANTS.STORE_LOCATOR, API_METHODS.GET, '', callback);
// };
  

export const getStores = (callback, params) => {
    fetchCall(
      `${API_CONSTANTS.STORE_LOCATOR}${params}`,
      API_METHODS.GET,
      '',
      callback
    );
  };