import React, { useEffect, useState } from "react";
import { orderList } from "../../actions/orders";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "../../utils/storage";
import { ROUTES } from "../../constants/app-constants";
import HeaderCommon from "../../components/header-common-component";
import ProfileCard from "../../components/profile-card";
import MenuSideBar from "../../containers/menuSideBar";
import back from "../../assets/images/goback.svg";
import { Authentication, Logout } from "../../actions/authentication";
import AlertPopup from "../../components/alert-popup";
import { menuData } from "../../helpers/constants";
import { logOutIdentityBranch } from "../../utils/branch";

const Notification = (props) => {
  const { Dom, profileorder, routeName, customClassRoute } = props;
  const [active1, setActive1] = useState();
  const [active, setActive] = useState("all");
  const [islog, setIsLog] = useState(false);

  const { innerWidth } = window;
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  let data1 = [
    {
      category_name: "My Orders",
      key: "/my-orders",
    },
    {
      category_name: "Address",
      key: "/my-address",
    },
    {
      category_name: "Notification",
      key: "/notification",
    },
    {
      category_name: "Feedback",
      key: "/feedback",
    },
    {
      category_name: "Catering",
      key: "/catering",
    },
    {
      category_name: "Dine In",
      key: "/store-locators",
    },
    {
      category_name: "Gift Vouchers",
      key: "/gift-vouchers",
    },
    {
      category_name: "Refer & Earn",
      key: "/refer-and-earn",
    },
    {
      category_name: "Loyalty",
      key: "/loyalty-page",
    },
    {
      category_name: "Logout",
      key: "/product-list",
    },
  ];

  if (!LocalStorage.get("isLoggedIn")) {
    data1 = [
      {
        category_name: "Dine In",
        key: "/store-locators",
      },
    ];
  }
  const onClickCategory = (itm) => {
    if (itm.category_name === "Logout") {
      setIsLog(true);
      return

      // Logout((re) => {
      //   if (re.response.status === 200) {
      //     LocalStorage.set("isLoggedIn", false);
      //     LocalStorage.set("UserData", {});
      //     LocalStorage.remove("CartProducts");
      //     Authentication((res) => {
      //       if (res.response.status === 200) {
      //         if (res.content) {
      //           LocalStorage.set("AccessToken", res.content.access_token);
      //           LocalStorage.set("RefreshToken", res.content.refresh_token);
      //           history.push(ROUTES.HOME);
      //         } else {
      //           history.push(ROUTES.HOME);
      //         }
      //       }
      //     });
      //   }
      // });
    }
    // if (innerWidth <= 1023) {
      setActive1(itm.category_name);
      history.push(itm.key);
    // }
  };
  const confirmLogout = () => {
    Logout((re) => {
      if (re.response.status === 200) {
        //branch event
        logOutIdentityBranch()

        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");

        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              history.push(ROUTES.HOME);
            } else {
              history.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  }

  const handleBack = () => {
    history.goBack();
  };
  return (
    <>
   {
      islog && 
      <AlertPopup
      title='Logout?'
      description='Are you sure you want logout?'
      okayClick={() => confirmLogout()}
      logout
      close={() => setIsLog(false)}
      />
    }
      {Dom && innerWidth > 1023 && (
        <>
          <HeaderCommon />
          <div className="notificationSection_wholeSection">
            <div className="notificationSection_wholeSection_one">
              <ProfileCard />
            </div>
            <div className="notificationSection_wholeSection_two">
              <div className="notificationSection_subSectionLeft">
                <MenuSideBar
                  data={menuData}
                  active={active1}
                  onClickCategory={onClickCategory}
                  routeName={routeName}
                  customClassRoute={customClassRoute}
                />
                {/* {getMenuData()} */}
              </div>
              <div className="notificationSection_subSectionRight">
                <h3 className="notificationSection_nameLabel">Notification</h3>
                <h4 className="namehead">There is no notification currently</h4>
              </div>
            </div>
          </div>
        </>
      )}
      
      {innerWidth > 1023 && profileorder && (
        <div className="notificationSection_wholeSection1">
          <div className="notificationSection_wholeSection1_two">
            <div className="notificationSection_subSectionRight">
              <p className="notificationSection_nameLabel">Notification</p>
              <p className="namehead">There is no notification currently</p>
            </div>
          </div>
        </div>
      )}

      {innerWidth <= 1023 && (
        <div className="notificationSection_subSectionRight">
          <div className="notificationSection_wholeDiv">
            <img
              src={back}
              className="notificationSection_wholeDiv_back"
              onClick={() => handleBack()}
              alt="back"
            />
            <h2 className="notificationSection_wholeDiv_nameLabel">
              Notification
            </h2>
          </div>
          <h3 className="namehead">There is no notification currently</h3>
        </div>
      )}
    </>
  );
};

export default Notification;
