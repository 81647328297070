import React from 'react';
import './index.scss';
import Marker from '../../assets/images/marker.svg';

const Styles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: {
      visibility: 'off',
    },
  },
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
];

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapIsReady: {},
      latlng: { lat: '', lng: '' },
      mapOptions: {
        center: { lat: '', lng: '' },
        zoom: 15,
        disableDefaultUI: true,
        draggable: false,
        mapTypeId: 'roadmap',
        styles: Styles,
      },
    };
  }

  componentDidMount = () => {
    const { center } = this.props;
    const { mapOptions } = this.state;
    mapOptions.center = center;
    this.setState({ mapOptions, latlng: center });
    this.isMapLoads(center);
  };

  componentDidUpdate() {
    const { latlng, mapOptions } = this.state;
    const { center } = this.props;
    if (latlng.lat !== center.lat) {
      if (latlng.lat !== '' || latlng.lng !== '') {
        if (mapOptions.center !== latlng) {
          this.isMapLoads(latlng);
        }
      }
    }
  }

  isMapLoads = (json) => {
    const { mapOptions } = this.state;
    let image = Marker;
    mapOptions.center = json;
    const { google } = window;
    const map = new google.maps.Map(document.getElementById('map'), mapOptions);
    const marker = new google.maps.Marker({
      map,
      icon: {
        path: 'M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
        fillColor: 'black',
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 1.5,
        anchor: new google.maps.Point(20, 10),
      },
      draggable: false,
      position: map.getCenter(),
    });
    const position = marker.getPosition();
    if (typeof position !== 'undefined') {
      map.setCenter(marker.getPosition());
    }
  };

  render() {
    return (
      <>
        <div className='google-mapsData'>
          <div id='map' className='mapsData' />
        </div>
      </>
    );
  }
}

export default MapView;
