import PropTypes from 'prop-types';

import './index.scss';

const GiftCard = (props) => {
  const {
    handleGiftVoucher,
    giftVoucherCode,
    handleApplyGiftVoucher,
    giftVoucherAmt,
  } = props;

  return (
    <div>
        <div className='gift-cart'>
          <h1 className='gift-cart_header'>Gift card</h1>
          <div className='cartPopUp_section'>
            <input
              type='text'
              placeholder='Enter Voucher Code'
              className='cartPopUp_section_textfield'
              onChange={(e) => handleGiftVoucher(e)}
              value={giftVoucherCode}
            ></input>
            <button
              className='cartPopUp_section_button'
              onClick={() =>
                handleApplyGiftVoucher(
                  giftVoucherAmt && giftVoucherAmt != 0 ? true : false
                )
              }
            >
              {giftVoucherAmt && giftVoucherAmt != 0 ? 'Remove' : 'Apply'}
            </button>
          </div>
        </div>
    </div>
  );
};

GiftCard.propTypes = {
  handleGiftVoucher: PropTypes.func,
  giftVoucherCode: PropTypes.string,
  handleApplyGiftVoucher: PropTypes.func,
  giftVoucherAmt: PropTypes.string,
};

GiftCard.defaultProps = {
  handleGiftVoucher: () => {},
  giftVoucherCode: '',
  handleApplyGiftVoucher: () => {},
  giftVoucherAmt: '',
};

export default GiftCard;
