import "./index.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";

const IntervalDrop = (props) => {
  const {
    setInterval,
    interval,
    intervalModal,
    setIntervalModal,
    setTime,
    timeInterval,
    setlocationData,
    TimeInterval,
  } = props;

  return (
    <div className="interval">
      {timeInterval &&
        timeInterval.map((item) => {
          console.log("itemmm", item);
          return (
            <p
              onClick={() => TimeInterval(item?.name, false, item?.id)}
              key={item.id}
              className="interval_nameLabel"
            >
              {item.name}
            </p>
          );
        })}
    </div>
  );
};
export default IntervalDrop;
