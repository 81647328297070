import React, { Component } from "react";
import "./index.scss";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SearchIcon from "../../assets/images/search-black.svg";
import LocationIcon from "../../assets/images/location.svg";
import { ROUTES } from "../../constants/app-constants";
import closeImg from "../../assets/images/close.svg";

class SearchAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };
    this.latLng = {};
  }
  searchOnChangeHandler = (e) => {
    const { onChangeSearch } = this.props;
    this.setState({ inputValue: { location: e } });
    onChangeSearch({ location: e });
  };

  handleSelect = (value) => {
    const { onSearchChange, callOutlet } = this.props;
    this.setState({ inputValue: { location: value } });
    this.latLng = {};
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const { lat, lng } = latLng;
        const json = { lat, lng };
        this.latLng = json;
        this.getPincode(json);  
        onSearchChange(json);
        callOutlet(latLng, value);
      })
      .catch((error) => console.error("Error", error));
  };

  getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const google = window?.google;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    this.setState({
                      inputValue: {
                        location: results[0].formatted_address,
                        pincode,
                        latitude: json.lat,
                        longitude: json.lng,
                      },
                    });
                  }
                }
              },
              this
            );
            this.latLng = json;
          }
          // eslint-disable-next-line no-unused-vars
        },
        (error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  getPincode = (latLng) => {
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            this.setState({
              inputValue: {
                location: results[0].formatted_address,
                pincode,
                latLng,
              },
            });
          }
        }
      },
      this
    );
  };

  onClearClick = () => {
    this.setState({ inputValue: "" });
    const { onChangeSearch, setDate } = this.props;
    // this.setState({setDate: ''})
    onChangeSearch({ location: "" });
  };

  getRightContent = () => {
    const { inputValue } = this.state;
    const { inputData, setDate } = this.props;

    if ((inputValue && inputValue.location) || inputData) {
      return (
        <div
          className={`seachAutoComplete__clear u_cursor_pointer`}
          role="presentation"
          onClick={this.onClearClick}
        >
          {<img src={closeImg} alt='close'/>}
        </div>
      );
    }
    return "";
  };

  handleAutoCompleteClsName = (data) => {
    let clsName = "";
    if (data && data.length && data.length > 0) {
      clsName = "seachAutoComplete__search-scroll";
    }
    return clsName;
  };

  render() {
    const { inputValue } = this.state;
    const { inputData } = this.props;
    const { innerWidth } = window;

    return (
      <div className="seachAutoComplete">
        <div className="seachAutoComplete__search">
          <PlacesAutocomplete
            value={inputData}
            shouldFetchSuggestions={inputData?.length > 2}
            onChange={this.searchOnChangeHandler}
            onSelect={this.handleSelect}
            searchOptions={
              {componentRestrictions: { country: "IN" },
            }
            }
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <>
                <div className="seachAutoComplete__search-left">
                  {innerWidth >= 1024 &&
                    (inputValue && inputValue.location ? (
                      <img
                        src={SearchIcon}
                        alt="search"
                        className="seachAutoComplete__search-img"
                      />
                    ) : (
                      ""
                    ))}
                    {/* {console.log("getinputprops", getInputProps)} */}
                  <input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getInputProps({
                      placeholder:
                        innerWidth <= 1023
                          ? "Where to delivery ?"
                          : "Enter your current location",
                      className:
                        "seachAutoComplete__search-input location-search-input",
                    })}
                  />
                  {this.getRightContent()}
                  {/* <div
                    className={`seachAutoComplete__clear u_cursor_pointer`}
                    role='presentation'
                    onClick={this.onClearClick}
                  >
                    Clear
                  </div> */}
                  {/* {
                  inputValue ? <div className="onboard__clear u_cursor_pointer" role="presentation" onClick={() => this.clearSearchInput()}>Clear</div> :''
                } */}
                </div>
                {suggestions && suggestions.length ? (
                  <div className="autocomplete-dropdown-container_tri" />
                ) : (
                  ""
                )}
                {/* {suggestions ? this.setSuggestion(suggestions) : ''} */}
                <div className="autocomplete-dropdown-container">
                  {/* {loading && <div>Loading...</div>} */}
                  <div
                    className={`autocomplete-dropdown-container__sug ${this.handleAutoCompleteClsName(
                      suggestions
                    )}`}
                  >
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item suggestion-item--active"
                        : "suggestion-item";
                      // : 'suggestion-item suggestion-item--active'
                      return (
                        <div
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          <div className="suggestion-item_card">
                            <div className="suggestion-item__image">
                              <img src={LocationIcon} width="12" height="12" alt="location"/>
                            </div>
                            <div className="suggestion-item__desc">
                              <div className="suggestion-item__title">
                                {suggestion.formattedSuggestion &&
                                  suggestion.formattedSuggestion.mainText}
                              </div>
                              {suggestion.formattedSuggestion &&
                                suggestion.formattedSuggestion.secondaryText}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    );
  }
}
export default SearchAutoComplete;
