import React, { useState, useEffect } from "react";
import "./index.scss";
import StoreCard from "../store-card";
import { useHistory } from "react-router-dom";
import back from "../../assets/images/goback.svg";
import { ROUTES } from "../../constants/app-constants";
import { getAddressCoordinates } from "../../actions/maps";
import { OutletMapping } from "../../actions/home";
import { LocalStorage } from "../../utils/storage";
import AlertPopup from "../alert-popup";
import LocationSearch from "../../assets/images/homeLocation.svg"
const TakeawayView = (props) => {
  const history = useHistory();
  const {
    outlets,
    active,
    onClickStore,
    closeTakeAway,
    takeawayAlert,
    onCloseAlert,
    handleIamInterested
  } = props;
  const [searchResult, setSearchResult] = useState(null);
  const [searchOutlets, setSearchOutlets] = useState([]);
  const [filteredOutlets, setFilteredOutlets] = useState(outlets);

  const handleGoBack = () => {
    closeTakeAway();
  };

  useEffect(() => {
    new window.mappls.search(document.getElementById("auto"), (data) => {
      var dt = data[0];
      if (!dt) return false;
      var place = dt.placeName + ", " + dt.placeAddress;
      setSearchResult(place);
    });
  }, []);

  const callOutlet = (data) => {
    let payload = {
      latitude: data.latitude,
      longitude: data.longitude,
      is_delivery: false,
      is_order_now: true,
      order_date: "",
      order_time: "",
      channel: "online_web",
    };
    OutletMapping(payload, (res) => {
      if (res && res?.response?.status === 200) {
        LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
        setSearchOutlets(res?.content?.areas);
      }
    });
  };

  useEffect(() => {
    if (searchResult) {
      getAddressCoordinates(searchResult, (res) => {
        if (res && res?.response?.status === 200) {
          const data = res.content;
          callOutlet(data);
        }
      });
    } else {
      // If no search result, reset to show all outlets
      setFilteredOutlets(outlets);
    }
  }, [searchResult, outlets]);

  useEffect(() => {
    // Update filtered outlets when the outlets prop changes
    setFilteredOutlets(searchOutlets.length > 0 ? searchOutlets : outlets);
  }, [searchOutlets, outlets]);

  return (
    <div className="modal-overlay">
      <div className="modal-content" id="takeaway">
        <div className="wallet_header">
          <div className="takeaway_section">
            <img
              src={back}
              className="myOrders_section_backimg"
              onClick={() => handleGoBack()}
              alt=""
            />
            <h3 className="myOrders_section_heading">Takeaway</h3>
          </div>
        </div>
        <div className="searchtext">
          <input
            type="text"
            id="auto"
            name="auto"
            className="search-outer form-control as-input"
            placeholder="Search your store"
            required=""
            spellCheck="false"
            style={{
              position: "absolute",
              left: "2rem",
              top: "8rem",
              padding: "10px",
              borderRadius: "28px",
              width: "30rem",
              border: "1px solid rgb(222, 222, 222)",
            }}
          />
        </div>
        {
          filteredOutlets.length > 0 ? (
            <>
            <h4 className="deliveryContainer_error_recommend">
              Select Nearby Store
            </h4>
            <div className="deliveryContainer_store-list">
            {filteredOutlets.map((item) => (
              <StoreCard
              key={item.id}
              data={item}
              theme={active}
              onClickStore={onClickStore}
              />
            ))}
        </div>
            </>
            ) : (
              <div className="deliveryContainer_error">
              <h2 className="deliveryContainer_error_text">
                  Sorry! No outlet found near to your location!
                  Please click here if you want BBK outlet to be
                  open near to your location -
            <span
              className="deliveryContainer_interest-text"
              onClick={handleIamInterested}
              >
                &nbsp;Yes I Want
            </span>
            </h2>
            <img
                src={LocationSearch}
                width="132"
                height="117"
                alt="Location"
                />
        </div>
        )}
      </div>
      {takeawayAlert && (
        <AlertPopup
          title="Sorry!!"
          description={takeawayAlert}
          okayClick={() => onCloseAlert()}
          alertCart={true}
        />
      )}
    </div>
  );
};

export default TakeawayView;
