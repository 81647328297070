import { useEffect, useState } from "react";
import React from "react";
import percent from "../../assets/images/percent.svg";
import percentRed from "../../assets/images/discountred.svg";
import Cart from "../../containers/cart-PopUp";
import "./index.scss";
import { Analytics } from "../../helpers/analytics";
import { LocalStorage } from "../../utils/storage";

const CheckoutOfferCard = (props) => {
  const { logStatus, onClick, loggedIn, data, onApplyOffer, selectedOffer, onLogin, data2, ShowParticular, closeParticular } =
    props;

  const [offerPopup, setOfferPopUp] = useState(false);
  const { innerWidth } = window;
  const couponName = LocalStorage.get("CouponName");

  const handleClick = (itm, value) => {
    if (loggedIn) {
      if (value === "apply") {
        setOfferPopUp(true);
        Analytics.viewOfferEvent()
        onClick();
      } else {
        // LocalStorage.remove("offersData");
        onApplyOffer(itm, "remove");
      }
    } else {
      onLogin();
    }
  };
 

  const onApply = (itm) => {
    onApplyOffer(itm, "apply");
    setOfferPopUp(false);
  };

  const close = () => setOfferPopUp(false);


  const handleEvent = () => {
    if(innerWidth <= 1023){
      if(selectedOffer && selectedOffer.coupon_code){
        handleClick(selectedOffer, "remove")
      }else{
        handleClick({}, "apply")
      }
    } else {
      if((selectedOffer && selectedOffer.coupon_code) || 
      (couponName && couponName.length > 0 && couponName)){
        handleClick(couponName, "remove");
      } else {
        handleClick({}, "apply");
      }
    }
  }

  return (
    <div className="checkout">
      {/* {console.log("selectedoffer", selectedOffer)} */}
      <div className="checkout_offercard" onClick={handleEvent}>
        {innerWidth <= 1023 ? (
          <img src={percentRed} className="checkout_offercard_img" alt="percentRed"></img>
        ) : (
          <img src={percentRed} className="checkout_offercard_img" alt="percentRed"></img>
        )}
      </div>
      <div className="checkout_coupon" onClick={handleEvent}>
        <p className="checkout_coupon_name">
          {couponName && couponName.length > 0 
            ? couponName
            : selectedOffer && selectedOffer.coupon_code
            ? selectedOffer.coupon_code
            : "Add Offer / Refer Code"}
            {/* {selectedOffer && selectedOffer.coupon_code
            ? selectedOffer.coupon_code
            : "Add Offer"} */}
          {/* {data.offer_name ? 'Offer name' : 'Apply Coupon'} */}
        </p>
        <button
          className={`checkout_coupon_button ${
            logStatus && "checkout_coupon_disab"
          }`}
          onClick={
            // selectedOffer && selectedOffer.coupon_code
            (selectedOffer && selectedOffer.coupon_code) || (couponName && couponName.length > 0 && couponName)
              ? () => handleClick(couponName, "remove")
              : () => handleClick({}, "apply")
          }
          disabled={logStatus}
        >
          {/* {selectedOffer && selectedOffer.coupon_code */}
          {(selectedOffer && selectedOffer.coupon_code) || (couponName && couponName.length > 0 && couponName)
            ? "Remove"
            : "Apply offer"}
        </button>
      </div>
      {offerPopup && <Cart
      closeParticular={closeParticular}
      ShowParticular={ShowParticular}
      cartData2={data2} cartData={data} onApply={onApply} close={close}/>}
    </div>
  );
};

export default CheckoutOfferCard;
