import React, { useState } from "react";
import "./index.scss";
import Button from "../button";

const MyOrderFeedback = (props) => {
  const { items, data, rate, count, id, feedbackSubmitted, CartShow, outlet } =
    props;

  const { innerWidth } = window;

  return (
    <>
      {innerWidth <= 1023 ? (
        <div className="wholeWrapper_myOrderFeedback">
          <div className="myOrderFeedback">
            <p className="myOrderFeedback_phrase">Give us your feedback</p>
          </div>
          <div
            className="myOrderFeedback_rating"
            onClick={() =>
              feedbackSubmitted === false &&
              CartShow(outlet?.outlet_id, outlet?.delivery_type, outlet?._id)
            }
          >
            {items?.map((item) => {
              return (
                <div
                  className={`myOrderFeedback_rating_item ${
                    item <= count[id] && "myOrderFeedback_rating_active"
                  }`}
                >
                  &#9733;
                </div>
              );
            })}
          </div>
          {/* {feedbackSubmitted === false && (
                <Button
                onClick={() =>
                    CartShow(outlet?.outlet_id, outlet?.delivery_type, outlet?._id)
                }
                className="myOrderFeedback_btn"
                content="Rate now"
                />
            )} */}
        </div>
      ) : (
        <div className="myOrderFeedback">
          <p className="myOrderFeedback_nameLabel">Give us your feedback</p>
          <div className="myOrderFeedback_rating">
            {items?.map((item) => {
              return (
                <div
                  className={`myOrderFeedback_rating_item ${
                    item <= count[id] && "myOrderFeedback_rating_active"
                  }`}
                  // onClick={() => rate(id,item)}
                >
                  {/* <p className='myOrderFeedback_rating_item_nameLabel'></p> */}
                  &#9733;
                </div>
              );
            })}
          </div>
          {feedbackSubmitted === false && (
            <Button
              onClick={() =>
                CartShow(outlet?.outlet_id, outlet?.delivery_type, outlet?._id)
              }
              className="myOrderFeedback_btn"
              content="Rate now"
            />
          )}
        </div>
      )}
    </>
  );
};
export default MyOrderFeedback;
