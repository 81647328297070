import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import AlertPopup from "../alert-popup";
import { ROUTES } from "../../constants/app-constants";

const SelectLocationAlert = (props) => {
  const history = useHistory();
  const { alertLocation, handleLocationAlertModal } = props;


  
  return (
    <div>
      {alertLocation && (
        <AlertPopup
          title="Sorry!!"
          description={"Please select the location"}
          okayClick={() => handleLocationAlertModal()}
          // okayClick={() => history.push(ROUTES.DELIVERY_LOCATION)}
          alertCart={true}
        />
      )}
    </div>
  );
};

SelectLocationAlert.propTypes = {
  alertLocation: PropTypes.bool,
  handleLocationAlertModal: PropTypes.func,
};

SelectLocationAlert.defaultProps = {
  alertLocation: false,
  handleLocationAlertModal: () => {},
};

export default SelectLocationAlert;
