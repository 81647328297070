import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import Button from "../button";
import Close from "../../assets/images/close.svg";
import goback from "../../assets/images/goback.svg";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
import Modal from "../modal";
import Input from "../input";
import { LocalStorage } from "../../utils/storage";
import NewButton from "../newCount-Button";
import { regex } from "../../constants/regex";

const billDatae = [
  { name: "Item total", amount: "₹ 0" },
  { name: "Tax", amount: "₹ 0" },
  { name: "Delivery Charge", amount: "₹ 0" },
  { name: "Discount", amount: "₹ 0" },
  { name: "Total Amount", amount: "₹ 0" },
];

const RecieverPopup = ({
  onClose,
  data,
  onUpdateContent,
  tax,
  onRecieveData,
}) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [item, setItem] = useState(data);
  const [billData, setBillData] = useState(billDatae);

  const onRecieverClick = () => {
    const payload = {
      receiver_mobile: mobile,
      receiver_email: email,
      quantity: item.count,
      name: name,
    };
    onRecieveData(payload, message);
  };

  const SubCount = (itm, value) => {
    let cgst = 0;
    let sgst = 0;
    let totalPrice = 0;
    let offer =
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
        ).toFixed(2)
      ) -
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.offer_amount_to_be_paid).toFixed(2))
        ).toFixed(2)
      );
    const outletData = LocalStorage.get("Outlet_Details");
    if (value === "add") {
      itm.count = itm.count + 1;
    } else {
      if (itm.count > 1) {
        itm.count = itm.count - 1;
      }
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
        ).toFixed(2)
      );
    }
    setItem(itm);
    tax.map((items) => {
      if (items.tax_id === "1") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        cgst = (price * Number(items.tax_value)) / 100;
      }
      if (items.tax_id === "2") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        sgst = (price * Number(items.tax_value)) / 100;
      }
    });

    const billDatae = [
      {
        name: "Item total",
        amount: `₹ ${Number(
          parseFloat(
            itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
          ).toFixed(2)
        )}`,
      },
      { name: "Tax", amount: `₹ ${cgst + sgst}` },
      {
        name: "Delivery Charge",
        amount: `₹ ${Number(
          parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
        )}`,
      },
      {
        name: "Discount",
        amount: `₹ ${offer}`,
      },
      {
        name: "Total Amount",
        amount: `₹ ${
          Number(
            parseFloat(
              itm.count *
                Number(parseFloat(itm.offer_amount_to_be_paid).toFixed(2))
            ).toFixed(2)
          ) +
          cgst +
          sgst +
          Number(
            parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
          )
        }`,
      },
    ];

    setBillData(billDatae);
    onUpdateContent(itm);
  };

  const { innerWidth } = window;
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };

  const hadnleDisable = () => {
    let isDisbled = false;
    if (name.length === 0 || mobile.length === 0 || email.length === 0) {
      isDisbled = true;
    } else if (mobile.length > 0 && !regex.mobileRegex.test(mobile)) {
      isDisbled = true;
    } else if (email.length > 0 && !regex.emailRegex.test(email)) {
      isDisbled = true;
    } else {
      isDisbled = false;
    }
    return isDisbled;
  };

  return (
    <Modal
      modalCustomClass="recieverPopup_customClass"
      contentCustomClass="recieverPopup_contentCustomClass"
      hideClose
    >
      <div className="recieverPopup">
        <div className="recieverPopup_heading">
          {innerWidth <= 1023 ? (
            <>
              <div className="recieverPopup_heading_wholeWrapper">
                <img
                  src={goback}
                  className="recieverPopup_heading_img"
                  onClick={() => onClose(false)}
                  alt="goback"
                  />
                <h3 className="recieverPopup_name">Reciever Details</h3>
              </div>
            </>
          ) : (
            <>
              <h3 className="recieverPopup_name">Reciever Details</h3>
              <img
                src={Close}
                alt="close"
                width="20"
                height="20"
                className="recieverPopup_close"
                onClick={() => onClose(false)}
              />
            </>
          )}
        </div>
        {innerWidth <= 1023 ? (
          <>
            <div className="recieverPopup_bodySection_wholeWrapper">
              <div className="recieverPopup_bodySection_subSection">
                <Input
                  type="text"
                  onChange={(id, value) => {
                    setName(value);
                  }}
                  value={name}
                  customClass="recieverPopup_input"
                  placeholder="Enter Name"
                />
                <Input
                  type="text"
                  onChange={(id, value) => {
                    setEmail(value);
                  }}
                  value={email}
                  customClass="recieverPopup_input"
                  placeholder="Enter Email"
                />
                <Input
                  type="text"
                  onChange={(id, value) => {
                    setMobile(value);
                  }}
                  value={mobile}
                  customClass="recieverPopup_input"
                  placeholder="Enter Phone Number"
                />
                <Input
                  type="text"
                  onChange={(id, value) => {
                    setMessage(value);
                  }}
                  value={message}
                  customClass="recieverPopup_input"
                  placeholder="Message (optional)"
                />
                <div className="recieverPopup_descriptionContainer">
                  <img
                    src={BBK}
                    className="voucherModalCard_imgContainer_img"
                    alt="logo"
                    width="100%"
                    height="200"
                  />
                  <div className="recieverPopup_descriptionContainer_foodContainer">
                    <p className="itemName">{item?.name}</p>
                    <span className="itemLabel">
                      Pay &#8377;{" "}
                      {item.count *
                        Number(
                          parseFloat(item?.offer_amount_to_be_paid).toFixed(2)
                        )}
                    </span>
                    <span className="recieverPopup_originalPrice">
                      {" "}
                      &#8377;{" "}
                      {item.count *
                        Number(parseFloat(item?.total_card_amount).toFixed(2))}
                    </span>
                  </div>
                  <NewButton
                    content={item.count}
                    className="recieverPopup_addsubBtn"
                    subCount={SubCount}
                    itemid={item._id}
                    item={item}
                    customSubIconClass="recieverPopup_subIcon"
                    customClass="recieverPopup_content"
                    customAddIconClass="recieverPopup_addIcon"
                  />
                </div>
              </div>
              <div className="recieverPopup_bodySection_footerSection">
                <Button
                  content="Done"
                  className="recieverPopup_button"
                  onClick={onRecieverClick}
                  disabled={hadnleDisable()}
                  customClass="recieverPopup_button_content"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Input
              type="text"
              onChange={(id, value) => {
                setName(value);
              }}
              value={name}
              customClass="recieverPopup_input"
              placeholder="Enter Name"
            />
            <Input
              type="text"
              onChange={(id, value) => {
                setEmail(value);
              }}
              value={email}
              customClass="recieverPopup_input"
              placeholder="Enter Email"
            />
            <Input
              type="text"
              onChange={(id, value) => {
                setMobile(value);
              }}
              value={mobile}
              customClass="recieverPopup_input"
              placeholder="Enter Phone Number"
            />
            <Input
              type="text"
              onChange={(id, value) => {
                setMessage(value);
              }}
              value={message}
              customClass="recieverPopup_input"
              placeholder="Message (optional)"
            />
            <div className="recieverPopup_descriptionContainer">
              <img
                src={BBK}
                className="voucherModalCard_imgContainer_img"
                alt="logo"
                width="100%"
                height="200"
              />
              <div className="recieverPopup_descriptionContainer_foodContainer">
                <p className="itemName">{item?.name}</p>
                <span className="itemLabel">
                  Pay &#8377;{" "}
                  {item.count *
                    Number(
                      parseFloat(item?.offer_amount_to_be_paid).toFixed(2)
                    )}
                </span>
                <span className="recieverPopup_originalPrice">
                  {" "}
                  &#8377;{" "}
                  {item.count *
                    Number(parseFloat(item?.total_card_amount).toFixed(2))}
                </span>
              </div>
              <NewButton
                content={item.count}
                className="recieverPopup_addsubBtn"
                subCount={SubCount}
                itemid={item._id}
                item={item}
                customSubIconClass="recieverPopup_subIcon"
                customClass="recieverPopup_content"
                customAddIconClass="recieverPopup_addIcon"
              />
            </div>
            <Button
              content="Done"
              className="recieverPopup_button"
              onClick={onRecieverClick}
              customClass="recieverPopup_button_content"
              disabled={hadnleDisable()}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default RecieverPopup;
