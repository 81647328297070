import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ProfileCard from "../../components/profile-card";
import MenuSideBar from "../../containers/menuSideBar";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { LocalStorage } from "../../utils/storage";
import { Authentication, Logout } from "../../actions/authentication";
import OrderCard from "../../components/order-card";
import MyOrders from "../my-orders";
import { ROUTES } from "../../constants/app-constants";
import EditProfile from "../../components/edit-profile-popup";
import { updateImg, verifyProfileOtp, getProfile, v2GetProfile, updateProfile, getOTP, getEmailVerification } from "../../actions/profile";
import { EmailValidation } from "../../utils/validation";
import { sendProfileOtp } from "../../actions/profile";
import EditProfileOtp from "../../components/edit-otp-profile";
import goBack from "../../assets/images/goback.svg";
import order from "../../assets/images/myorder.svg";
import location from "../../assets/images/profilelocation.svg";
import notification from "../../assets/images/profileNotification.svg";
import catering from "../../assets/images/profileCatering.svg";
import loyalty from "../../assets/images/profileLoyalty.svg";
import moment from "moment";
import Feedback from "../feedback";
import silverBronze from '../../assets/images/handi-loyalty_org.png';
import GiftVoucher from "../../containers/giftVouchers";
import LocationAddress from "../location-address";
import ReferAndEarn from "../refer-and-earn";
import StoreLocator from "../storeLocator";
import Loyalty from "../loyalty";

//delivery
import RadioButton from "../../components/radioButton";
// import SearchAutoComplete from "../searchAutocomplete";
import SearchAutoComplete from "../../containers/searchAutocomplete";
import Detect from "../../assets/images/detectLoc.svg";
// import responsiveDetect from "../../assets/images/profileLocation.svg";
// import MapModal from "../mapModal";
import MapModal from "../../containers/mapModal";
import LocationSearch from "../../assets/images/homeLocation.svg";
import remove from "../../assets/images/close.svg";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
// import Input from "../../components/input";
import { OutletMapping, getSkipLocation } from "../../actions/home";
import StoreCard from "../../components/store-card";
// import AlertPopup from "../../components/alert-popup";
import LocationImage from "../../assets/images/Web-Location-page-web.jpg";
import Locationinterested from "../../components/location-interested-card";
import IntervalDrop from "../../components/interval-dropdown";
import Switch from "../../components/switch";
// import moment from "moment";
import { getMenuList } from "../../actions/home";
import Dexie from "dexie";
import Header from "../../containers/header";
import MyprofileOffers from "../my-profile-offers";
import Modal from "../../components/modal";
import Button from "../../components/button";
import AlertPopup from "../../components/alert-popup";
import ProfileDropdown from "../../components/profile-dropdown";
import CateringIframe from "../catering-Iframe";
import HeaderCommon from "../../components/header-common-component";
import Notification from "../notification";
import { menuData, guestMenuData } from "../../helpers/constants";
import BbkLogo from "../../assets/images/whiteLogo.png";
import { logOutIdentityBranch } from "../../utils/branch";
import { getSavedAddress } from "../../actions/home";
import next from "../../assets/images/next.png";
import Wallet from "../wallet";
import gold from "../../assets/images/gold_medal.png";
import star from "../../assets/images/star.png"
import feedback from "../../assets/images/Feeback-icon.png"
import wallet from "../../assets/images/wallet.svg"
import giftcard from "../../assets/images/gift-card.png"
import referAndEarn from "../../assets/images/referandearn.png"
import { getLoyalityData } from '../../actions/loyalty';
import dinein from "../../assets/images/dining-room.png"
import OTPVerify from "../../components/email-verification-otp";

// const data1 = [
//   {
//     category_name: "My Orders",
//     image: order,
//     key: "/my-orders",
//   },
//   {
//     category_name: "BBK Money",
//     image:wallet,
//     key: "/wallet",
//     showWalletBalance: true,
//   },
//   {
//     category_name: "Gift Card",
//     key: "/giftcard",
//   },
//   {
//     category_name: "Offers",
//     image: order,
//     key: "/offers",
//   },
//   {
//     category_name: "Location Address",
//     image: location,
//     key: "/my-address",
//   },
//   {
//     category_name: "Notification",
//     image: notification,
//     key: "/notification",
//   },
//   {
//     category_name: "Feedback",
//     key: "/feedback",
//   },
//   {
//     category_name: "Catering",
//     image: catering,
//     key: "/catering",
//   },
//   {
//     category_name: "Dine In",
//     image: order,
//     key: "/store-locators",
//   },
//   {
//     category_name: "Gift Vouchers",
//     image: order,
//     key: "/gift-vouchers",
//   },
//   {
//     category_name: "Refer & Earn",
//     key: "/refer-and-earn",
//   },
//   {
//     category_name: "Loyalty",
//     image: loyalty,
//     key: "/loyalty-page",
//   },
//   {
//     category_name: "Logout",
//     image: order,
//     key: "/product-list",
//   },
// ];

const responsiveData = [
  {
    category_name: "My Orders",
    image: order,
    key: "/my-orders",
  },
  {
    category_name: "Refer & Earn",
    image: referAndEarn,
    key: "/refer-and-earn",
  },
  {
    category_name: "BBK Money",
    key: "/wallet",
    image: wallet,
    showWalletBalance: true,
  },
  {
    category_name: "Gift Card",
    image: giftcard,
    key: "/giftcard",
  },
  {
    category_name: "Offers",
    image: order,
    key: "/offers",
  },
  {
    category_name: "Address",
    image: location,
    key: "/my-address",
  },
  // {
  //   category_name: "Notification",
  //   image: notification,
  //   key: "/notification",
  // },
  {
    category_name: "Feedback",
    image: feedback,
    key: "/feedback",
  },
  // {
  //   category_name: "Catering",
  //   image: catering,
  //   key: "/catering",
  // },
  {
    category_name: "Dine In",
    image: dinein,
    key: "/store-locators",
  },
  // {
  //   category_name: "Gift Vouchers",
  //   image: order,
  //   key: "/gift-vouchers",
  // },

  {
    category_name: "Loyalty",
    image: loyalty,
    key: "/loyalty-page",
    isShowLoyltyPoints: true,
  },

  {
    category_name: "Logout",
  },
];

function MyProfile() {
  const [modalShow, setModalShow] = useState(false);
  const [loyalty_points, setLoyalty_Points] = useState("");
  const [loyaltyexpiry, setLoyaltyexpiry] = useState("");
  const { innerWidth } = window;
  const [imgProfile, setImgProfile] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [otp, setOtp] = useState("");
  const [disableStat, setDisableStat] = useState(true);
  const history = useHistory();
  const [active1, setActive1] = useState("My Orders");
  const [navStat, setNavStat] = useState("1");
  const [displayMobileNumber, setDisplayMobileNumber] = useState("");
  const dataPast = LocalStorage.get("pastOrder");
  const [profileInfo, setProfileInfo] = useState({});

  const cartshow = (data) => {
    console.log(data,"sdwdwdywf");
    setDisableStat("");
    setModalShow(!modalShow);
    if (data && data.first_name) {
      setName(data.first_name);
    }
    else
      setName(data?.name ? data?.name : "");
    setEmail(data?.email ? data?.email : "");
    setDate(data?.dob ? data?.dob : null);
  };

  const [loginFlow, setLoginFLow] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [stat, setStat] = useState("stage1");
  const formatDate = (inputDate) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  };
  //delivery
  const [deliveryFlow, setDeliveryFlow] = useState(false);
  const switchData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Takeaway" },
  ];

  const switchResponsiveData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Pick-up" },
  ];

  const radio = [
    { id: "orderNow", name: "Order Now", checked: true },
    { id: "orderLater", name: "Order Later", checked: false },
  ];

  const responsiveRadio = [
    { id: "orderNow", name: "Now", checked: true },
    { id: "orderLater", name: "Later", checked: false },
  ];
  const [active, setActive] = useState("delivery");
  const [radioData, setRadioData] = useState(radio);
  const [responsiveRadioData, setResponsiveRadioData] =
    useState(responsiveRadio);
  const [activeOrder, setActiveOrder] = useState("orderNow");
  const [inputValue, setInputvalue] = useState({});
  const [popup, setPopup] = useState(false);
  const [inputData, setInputData] = useState("");
  // const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [latLng, setLatLng] = useState({ lat: "", lng: "" });
  const [errorData, setErrorData] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [location, setLocation] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [data, setData] = useState([]);
  const [errorData1, setErrorData1] = useState(false);
  const [countData, setCountData] = useState({});
  const [alertData, setAlertData] = useState(false);
  const [dateData, setdateData] = useState(false);
  const [timeData, settimeData] = useState(false);
  const [timeInp, setTimeInp] = useState("text");
  const [modal, setModal] = useState(false);
  const [islog, setIsLog] = useState(false);
  // const {netcore} = props;
  const [interval, setInterval] = useState("");
  const [intervalModal, setIntervalModal] = useState(false);
  const [intDate, setIntDate] = useState([]);
  const [savedAddress, setSavedAddress] = useState("");
  const [guest_label, setGuestLabel] = useState("");
  const [verifyModal, setVerifyModal] = useState(false);
  let timeInterval = [];
  let isDOBchange = LocalStorage.get("isDOB");
  const isLoggedInUser = LocalStorage.get("isLoggedIn");
  const urlParams = useLocation();
  const urlValue = urlParams.pathname.split("/")[3];
  const urlCity = urlParams.pathname.split("/")[2];
  const setAction = {
    verifyModal,
    setVerifyModal,
    otp,
    setOtp
  }
  useEffect(() => {
    //delivery
    getLoyalityData((res) => {
      if (res?.response?.status == 200) {
        setLoyalty_Points(res?.content?.current_points?.CurrentPoints)
        setLoyaltyexpiry(res?.content?.current_points?.LoyaltyExpiryDate)
        setGuestLabel(res?.content?.current_points?.guest_label)
      }
      // guest_label
    })
    getSavedAddress((res) => {
      if (res?.response?.status == 200) {
        setSavedAddress(res?.content.address);
      }
    });
    if (latLng.lat === "" && latLng.lng === "") {
      setLocation(false);
      // onCurrentLocation();
    }
    let locationDetails = LocalStorage.get("location");
    if (locationDetails) {
      setLocation(true);
      setLatLng({
        lat: locationDetails && locationDetails.lat,
        lng: locationDetails && locationDetails.lng,
      });
      // setActiveOrder(locationDetails && locationDetails.orderTime);
      setActive(locationDetails && locationDetails.orderFor);
      setInputData(locationDetails && locationDetails.address);
      // setDate(locationDetails && locationDetails.date);
      // setTime(locationDetails && locationDetails.time);
    }
    // const user = LocalStorage.get('UserData');
    // const script = document.createElement('script');
    // script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
    // script.onload = () => notification{
    //   smartech('identify', "91"+user.mobile);
    //   smartech('dispatch', 'Order Type', {
    //     orderType: LocalStorage.get('location')
    //   });
    // };
    // document.head.appendChild(script);

    // setDate(LocalStorage.get('UserData') && LocalStorage.get('UserData').dob ? LocalStorage.get('UserData').dob : '')
    if (LocalStorage.get("profileImg")) {
      setImgProfile(LocalStorage.get("profileImg"));
    }
    // innerWidth >= 1023 &&
    //   data.push({
    //     category_name: 'Logout',
    //     image: order,
    //     key: '/product-list',
    //   });
    // setDate(LocalStorage.get('UserData') && LocalStorage.get('UserData').dob ? LocalStorage.get('UserData') : '')

    v2GetProfile((res) => {
      if (res && res?.response?.status === 200) {
        setProfileInfo(res?.content);
        setName(res?.content.first_name);
        setEmail(res?.content.email);
        console.log(res,"sduwdu");
        LocalStorage.set("profileImg", res?.content?.profile_picture);
        let newData = {
          dob: res?.content?.dob,
          email: res?.content?.email,
          mobile: res?.content?.mobile,
          name: res?.content?.first_name,
          profileInfo: res?.content?.profile_picture,
          emailVerify:res?.content?.is_user_email_verified
        };

        LocalStorage.set("UserData", newData);
      }
    });
  }, []);

  const userData = LocalStorage.get("UserData");
  const onClickCategory = (itm) => {
    setActive1(itm.category_name);
    if (itm.category_name === "Logout") {
      setIsLog(true);
      // Logout((re) => {
      //   if (re.response.status === 200) {
      //     LocalStorage.set("isLoggedIn", false);
      //     LocalStorage.set("UserData", {});
      //     LocalStorage.remove("CartProducts");
      //     Authentication((res) => {
      //       if (res.response.status === 200) {
      //         if (res.content) {
      //           LocalStorage.set("AccessToken", res.content.access_token);
      //           LocalStorage.set("RefreshToken", res.content.refresh_token);
      //           history.push(ROUTES.HOME);
      //         } else {
      //           history.push(ROUTES.HOME);
      //         }
      //       }
      //     });
      //   }
      // });
    }
    if (innerWidth <= 1023) {
      history.push(itm.key);
    }
  };

  const confirmLogout = () => {
    Logout((re) => {
      if (re.response.status === 200) {
        //branch event
        logOutIdentityBranch();

        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");

        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              history.push(ROUTES.HOME);
            } else {
              history.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  };

  const getMenuData = () => {
    //
    let dom = null;
    switch (active1) {
      case "My Orders":
        dom = (
          <MyOrders
            profileData={profileInfo}
            cartShow={cartshow}
            Dom={false}
            profileorder={true}
          />
        );
        break;
      case "BBK Money":
        dom = <Wallet Dom={false} profileorder={true} />;
        break;
      case "Offers":
        dom = <MyprofileOffers Dom={false} profileorder={true} />;
        break;
      case "Feedback":
        dom = <Feedback Dom={false} profileorder={true} />;
        break;
      case "Gift Vouchers":
        dom = <GiftVoucher Dom={false} profileorder={true} />;
        break;
      case "Notification":
        dom = <Notification Dom={false} profileorder={true} />;
        break;
      // case "Catering":
      //   dom = <CateringIframe Dom={false} profileorder={true} />;
      //   break;
      case "Address":
        dom = <LocationAddress Dom={false} profileorder={true} />;
        break;
      case "Refer & Earn":
        dom = <ReferAndEarn Dom={false} profileorder={true} />;
        break;
      case "Dine In":
        dom = <StoreLocator Dom={false} profileorder={true} />;
        break;
      case "Loyalty":
        dom = <Loyalty Dom={false} profileorder={true} />;
        break;
      default:
    }
    return dom;
  };

  const imgUpload = (e) => {
    const formdata = new FormData();
    const csvdata = e.target.files[0];
    formdata.append("file", csvdata);
    updateImg(
      formdata,
      (res) => {
        if (res && res?.response?.status === 200) {
          setImgProfile(res?.content[0]);
        } else {
          setImgProfile(res?.content[0]);
        }
      },
      ""
    );
  };
  const clickVerifyEmail = () => {
    getOTP((res) => {
    })
    LocalStorage.set("otpErr", "")
    if (!verifyModal)
      setVerifyModal(true);
  }
  const submitOTP = () => {
    let payload = {
      otp: otp
    }
    getEmailVerification(payload, (res) => {
      if (res.response.status === 400) {
        LocalStorage.set("otpErr", "Wrong OTP")
        setOtp("");
      }
      else {
        v2GetProfile((res) => {
          if (res && res?.response?.status === 200) {
            setProfileInfo(res?.content);
            setName(res?.content.first_name);
            setEmail(res?.content.email);
            LocalStorage.set("profileImg", res?.content?.profile_picture);
            let newData = {
              dob: res?.content?.dob,
              email: res?.content?.email,
              mobile: res?.content?.mobile,
              name: res?.content?.first_name,
              profileInfo: res?.content?.profile_picture,
              emailVerify:res?.content?.is_user_email_verified
            };
            LocalStorage.set("UserData", newData);
          }
        });
        LocalStorage.set("otpErr", "")
        setVerifyModal(false);
        setOtp("");
      }
    })
  }
  const otpCheck = () => {
    const payload = {};
    let pay1 = {};
    let displayM = userData.mobile.split("");
    let displayMobile = [];
    let mobileDisplay = "";
    for (let i = 0; i < displayM.length; i++) {
      if (i == 0 || i == 1 || i == 2 || i == 8 || i == 9) {
        displayMobile.push(displayM[i]);
      } else {
        displayMobile.push("*");
      }
    }
    mobileDisplay = displayMobile.join("");
    setDisplayMobileNumber(mobileDisplay);
    if (
      name === "" ||
      email === "" ||
      EmailValidation(email) === false ||
      date === ""
    ) {
      setDisableStat("Enter Valid details");
    } else {
      if (
        userData.email === email &&
        (userData.name !== name ||
          userData.dob !== date ||
          userData.profileInfo !== imgProfile)
      ) {
        pay1 = {
          fname: name,
          email: email,
          dob: "0000-00-00",
          profile_picture: imgProfile,
          mobile: mobileDisplay,
        };
        updateProfile(pay1, (res) => {
          if (res.response.status === 200) {
            setModalShow(false);
            v2GetProfile((res) => {
              if (res && res?.response?.status === 200) {
                setProfileInfo(res?.content);
                setName(res?.content.first_name);
                setEmail(res?.content.email);
                LocalStorage.set("profileImg", res?.content?.profile_picture);
                let newData = {
                  dob: res?.content?.dob,
                  email: res?.content?.email,
                  mobile: res?.content?.mobile,
                  name: res?.content?.first_name,
                  profileInfo: res?.content?.profile_picture,
                  emailVerify:res?.content?.is_user_email_verified
                };
                LocalStorage.set("UserData", newData);
              }
            });
          }
          else {
            setDisableStat(res?.response?.message);
          }
        })
        // verifyProfileOtp(pay1, (res) => {
        //   if (res?.response?.status === 200) {
        //     setProfileInfo(res?.content?.data);
        //     LocalStorage.set("profileImg", res?.content?.data?.profile_picture);
        //     let newData = {
        //       profile_picture: imgProfile,
        //       dob: res?.content?.data?.dob,
        //       email: res?.content?.data?.email,
        //       mobile: res?.content?.data?.mobile,
        //       name: res?.content?.data?.first_name,
        //       profileInfo: res?.content?.data?.profile_picture,
        //     };
        //     LocalStorage.set("UserData", newData);
        //     setName(res?.content?.data?.first_name);
        //     setModalShow(false);
        //     setNavStat("1");
        //   } else {
        //     setDisableStat(res?.response?.message);
        //   }
        // });
      } else if (
        userData.email !== email ||
        (userData.email !== email &&
          (userData.name !== name ||
            userData.profileInfo !== imgProfile ||
            userData.dob !== date))
      ) {
        pay1 = {
          fname: name,
          email: email,
          profile_picture: imgProfile,
          mobile: mobileDisplay,
        };

        updateProfile(pay1, (res) => {
          if (res.response.status === 200) {
            setModalShow(false);
            setVerifyModal(true);
            getOTP((res) => {
            })
          }
          else {
            setDisableStat(res?.response?.message);
          }
        })
        setDisableStat("");

        // sendProfileOtp(pay1, (res) => {
        //   if (res.response.status === 200) {
        //     setNavStat("2");
        //   } else {
        //     setDisableStat(res.response.message);
        //   }
        // });
      } else {
        // console.log(" in hereeeee")
        // setDisableStat("");
        // sendProfileOtp(payload, (res) => {
        //   console.log("res ", res);
        //   if (res.response.status === 200) {

        //     setNavStat("2");
        //   } else {
        //     setDisableStat(res.response.message);
        //   }
        // });
      }
    }
  };
  useEffect(() => {
  }, [profileInfo])

  const verifyOtp = () => {
    let payload = {};
    if (userData.email !== email) {
      payload = {
        profile_picture: imgProfile,
        email: email,
        fname: name,
        dob: moment(date).format("YYYY-MM-DD"),
        is_registration: false, // false is mandatory,
        mobile: userData.mobile,
        otp: otp,
      };
    } else {
      payload = {
        fname: name,
        email: "",
        dob: moment(date).format("YYYY-MM-DD"),
        profile_picture: imgProfile,
        is_registration: false, // false is mandatory,
        mobile: userData.mobile,
        otp: otp,
      };
    }

    if (
      name === "" ||
      email === "" ||
      EmailValidation(email) === false ||
      date === ""
    ) {
      setDisableStat("Please go back and add all the details, again");
    } else {
      verifyProfileOtp(payload, (res) => {
        if (res?.response?.status === 200) {
          setProfileInfo(res?.content?.data);
          LocalStorage.set("profileImg", res?.content?.data?.profile_picture);
          let newData = {
            dob: res?.content?.data?.dob,
            email: res?.content?.data?.email,
            mobile: res?.content?.data?.mobile,
            name: res?.content?.data?.first_name,
            profileInfo: res?.content?.data?.profile_picture,
            emailVerify:res?.content?.is_user_email_verified
          };
          LocalStorage.set("UserData", newData);
          setName(res?.content?.data?.first_name);
          setModalShow(false);
          setNavStat("1");
        } else {
          setDisableStat(res?.response?.message);
        }
      });
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const LoginCLick = () => {
    setLoginFLow(true);
    setIsSignIn(false);
  };

  const SigninClick = () => {
    setLoginFLow(true);
    setIsSignIn(true);
  };

  const deliveryClick = () => {
    setDeliveryFlow(true);
  };

  // const deliveryClick = () => {
  //   setDeliveryFlow(true);
  // }

  //strictly for Delivery
  const onChangeOrder1 = (value) => {
    setActiveOrder(value.id);
    if (innerWidth <= 1023) {
      const updateOrder = responsiveRadioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setResponsiveRadioData(updateOrder);
    } else {
      const updateOrder = radioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setRadioData(updateOrder);
    }
  };

  const onChangeMode = (value) => {
    setActive(value.id);
  };

  const onCurrentLocations = () => {
    setAlertData(false);
    onCurrentLocation();
  };

  const onCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    setInputvalue({
                      location: results[0].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                    });
                    setLatLng({ lat: json.lat, lng: json.lng });
                    onMarkerPosition({ lat: json.lat, lng: json.lng });
                    setLocation(true);
                    setErrorData(false);
                    setInputData(results[0].formatted_address);
                    if (active === "delivery") {
                      setPopup(true);
                    } else {
                      onConfirmLocation();
                    }
                  }
                }
              },
              this
            );
          }
          // eslint-disable-next-line no-unused-vars
        },
        (_error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  const responsiveOnFindFood = () => {
    //
    if ((active === "delivery" || active === "takeaway") && inputData) {
      //
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time !== "" &&
      date !== ""
    ) {
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time === ""
    ) {
      settimeData(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      date === ""
    ) {
      setdateData(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onFindFood = () => {
    if (active === "delivery" && inputData) {
      //
      setPopup(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onChangeInput = (value) => {
    setInputData(value);
  };

  const onBackClick = () => {
    setPopup(false);
  };

  LocalStorage.remove("Previouslocation");
  const onChangeDate = (value) => {
    let gotTime = value.getDate();
    setdateData(false);
    setDate(value);
    let dateHour = new Date();
    let hours = dateHour.getHours();
    if (gotTime === new Date().getDate()) {
      for (let i = hours; i < 24; i++) {
        timeInterval.push({
          id: i + ":00",
          name:
            i < 12
              ? Math.floor(i % 12) +
              ":00 AM" +
              " - " +
              Math.floor(parseInt(i % 12) + 1) +
              ":00 AM"
              : Math.floor(i % 12) +
              ":00 PM" +
              " - " +
              Math.floor(parseInt(i % 12) + 1) +
              ":00 PM",
        });
      }
      setIntDate(timeInterval);
    } else {
      timeInterval = [
        {
          id: "10:00",
          name: "10:00 AM - 11:00 AM",
        },
        {
          id: "11:00",
          name: "11:00 AM - 12:00 PM",
        },
        {
          id: "12:00",
          name: "12:00 PM - 01:00 PM",
        },
        {
          id: "13:00",
          name: "01:00 PM - 02:00 PM",
        },
        {
          id: "14:00",
          name: "02:00 PM - 03:00 PM",
        },
        {
          id: "15:00",
          name: "03:00 PM - 04:00 PM",
        },
        {
          id: "16:00",
          name: "04:00 PM - 05:00 PM",
        },
        {
          id: "17:00",
          name: "05:00 PM - 06:00 PM",
        },
        {
          id: "18:00",
          name: "06:00 PM - 07:00 PM",
        },
        {
          id: "19:00",
          name: "07:00 PM - 08:00 PM",
        },
        {
          id: "20:00",
          name: "08:00 PM - 09:00 PM",
        },
        {
          id: "21:00",
          name: "09:00 PM - 10:00 PM",
        },
        {
          id: "22:00",
          name: "10:00 PM - 11:00 PM",
        },
      ];
      setIntDate(timeInterval);
    }
  };

  const onChangeTime = (value) => {
    settimeData(false);
    setTime(value);
  };

  const onCurrentLocationData = (value) => {
    setLatLng({ lat: value.latitude, lng: value.longitude });
    setInputData(value.location);
  };

  const onCenter = (value) => {
    if (value === false) {
      setLatLng({ lat: "", lng: "" });
    }
  };

  const onMarkerPosition = (value) => {
    LocalStorage.remove("Previouslocation");
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng: value },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            // eslint-disable-next-line no-lone-blocks
            setInputvalue({
              location: results[0].formatted_address,
              pincode,
              latitude: value.lat,
              longitude: value.lng,
            });
            setLatLng({ lat: value.lat, lng: value.lng });
            setInputData(results[0].formatted_address);
          }
        }
      },
      this
    );
  };

  const onConfirmLocation = () => {
    setPopup(false);

    let payload = {
      latitude: latLng.lat,
      longitude: latLng.lng,
      is_delivery: active === "delivery" ? true : false,
      is_order_now: activeOrder === "orderNow" ? true : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };

    OutletMapping(payload, (res) => {
      //
      if (res && res?.response?.status === 200) {
        setErrorData(false);
        setErrorData1(false);
        setDeliveryFlow(false);
        if (res?.content?.areas) {
          setOutlets(res.content.areas);
          // LocalStorage.set('DeliveryAreaPoint',res.content.areas)
          // LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
          onClickStore(res?.content?.areas[0]);
        }
        setData([]);
      } else {
        setOutlets([]);
        setErrorData(true);
        if (active === "delivery") {
          payload = {
            latitude: latLng.lat,
            longitude: latLng.lng,
            is_delivery: false,
            is_order_now: activeOrder === "orderNow" ? true : false,
            order_date:
              activeOrder !== "orderNow" && date !== ""
                ? moment(date).format("YYYY-MM-DD")
                : "",
            order_time: activeOrder !== "orderNow" ? time : "",
            channel: "online_web",
          };
          OutletMapping(payload, (res) => {
            if (res.response.status === 200) {
              if (
                res.content &&
                res.content.areas &&
                res.content.areas.length > 0
              ) {
                setErrorData1(true);
                setCountData({
                  count: res.content.areas.length,
                  distance: parseFloat(
                    res.content.max_distance_user_to_restaurant
                  ).toFixed(2),
                });
                setData(res.content.areas);
              }
            } else {
              setErrorData(true);
              setCountData({});
              setData([]);
            }
          });
        } else {
          setErrorData1(false);
          setCountData({});
          setData([]);
        }
      }
    });
  };

  const onChangeSearch = (value) => {
    setSavedAddress("");
    setAlertData(false);
    setLocation(true);
    setInputData(value.location);
    setInputvalue(value);
  };

  const onCloseAlert = () => {
    setAlertPopup("");
  };

  const onStoreSelect = () => {
    setOutlets(data);
    setErrorData1(false);
    setData([]);
    setErrorData(false);
    setActive("takeaway");
  };

  const handleRedirectHome = () => {
    history.push(ROUTES.HOME);
  };

  const onClickStore = (data) => {
    // let pointDelivery = LocalStorage.get('DeliveryAreaPoint');
    if (data && data?.is_open) {
      LocalStorage.set("Outlet_Details", data);
      getMenuList(
        urlValue &&
          urlValue?.length > 0 &&
          data?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
          ? urlValue
          : data?.outlets_id,
        (res) => {
          if (res && res.content && res.response.status === 200) {
            if (res.content) {
              if (LocalStorage.get("outlet_id")) {
                const oldOutlet = LocalStorage.get("outlet_id");
                if (oldOutlet !== res.content.menu.unit_id) {
                  if (LocalStorage.get("isLoggedIn")) {
                    onMenuDataSync(res.content.menu.categories);
                  } else {
                    LocalStorage.set("CartProducts", []);
                  }
                }
              }
              LocalStorage.set("outlet_id", res.content.menu.unit_id);
              const dataValue = {
                orderFor: active,
                orderTime: activeOrder,
                deliveryTime: data.delivery_area_delivery_time,
                address: inputData,
                date: date,
                time: time,
                lat: latLng.lat,
                lng: latLng.lng,
              };
              LocalStorage.set("location", dataValue);

              const db = new Dexie(res.content.menu.unit_id);
              db.version(1).stores({
                menu: "++id, categories, item_tags, taxes",
              });
              const getMenuData = async () => {
                const allData = (await db.menu.toArray()) || [];
                if (allData && allData.length > 0) {
                  await db.menu.update(0, res.content.menu);
                } else {
                  await db.menu.add(res.content.menu);
                }
                history.push(ROUTES.PROFILE);
              };
              getMenuData();
              // onCallMenu();
              // const getMenuData = async () => {
              //   const allData = (await db.menu.toArray()) || [];
              //   if (allData && allData.length > 0) {
              //     history.push(ROUTES.PRODUCTLIST);
              //   }
              // };
              // getMenuData();
              // history.push(ROUTES.PRODUCTLIST);
            }
          } else {
            setAlertPopup(
              "Sorry!! No Data Found. Please try again later sometime"
            );
          }
        }
      );
    } else {
      setAlertPopup(
        "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
    }
  };

  const onMenuDataSync = (data) => {
    const outletData = LocalStorage.get("CartProducts");
    const updateitem = data.map((items) => {
      let updateItem = [];
      if (items.has_subcategory === "0") {
        updateItem = items.items;
      }
      if (items.has_subcategory === "1") {
        items.subcategories.map((item) => {
          item.items.map((value) => {
            updateItem.push(value);
          });
        });
        items.items = updateItem;
      }
      items.itemData = updateItem.map((item) => {
        item.checked = false;
        item.count = 0;
        if (LocalStorage.get("CartProducts")) {
          const productData = LocalStorage.get("CartProducts");
          if (productData && productData.length > 0) {
            productData.map((items) => {
              if (items.item_id === item.item_id) {
                item.count = items.count;
              }
            });
          }
        }
        return item;
      });
      items.vegData = updateItem.filter((item) => item.item_tags.includes("1"));
      items.nonVeg = updateItem.filter((item) => item.item_tags.includes("2"));
      return items;
    });
    const updateItemData = [];
    updateitem.map((items) => {
      items.itemData.map((item) => {
        updateItemData.push(item);
      });
    });
    const datavalue = updateItemData.filter((itms) => {
      return outletData.includes(itms.item_id);
    });
  };
  // const { innerWidth } = window;
  const onOtpSubmition = (responseType) => {
    if (responseType === 'success') {
      v2GetProfile((res) => {
        if (res && res?.response?.status === 200) {
          setProfileInfo(res?.content);
          setName(res?.content.first_name);
          setEmail(res?.content.email);
          LocalStorage.set("profileImg", res?.content?.profile_picture);
          let newData = {
            dob: res?.content?.dob,
            email: res?.content?.email,
            mobile: res?.content?.mobile,
            name: res?.content?.first_name,
            profileInfo: res?.content?.profile_picture,
            emailVerify:res?.content?.is_user_email_verified
          };
          console.log(res, "propsssssssssss")
          LocalStorage.set("UserData", newData);
        }
      });
    }
  }
  const onSkipLocation = () => {
    getSkipLocation((res) => {
      if (res.response.status === 200) {
        LocalStorage.set("Outlet_Details", res.content);
        if (res.content.outlet_id) {
          getMenuList(
            urlValue &&
              urlValue?.length > 0 &&
              res?.content?.outlet__city?.toLowerCase() !==
              urlCity?.toLowerCase()
              ? urlValue
              : res.content.outlet_id,
            (res) => {
              if (res && res.content && res.response.status === 200) {
                if (res.content) {
                  if (LocalStorage.get("outlet_id")) {
                    const oldOutlet = LocalStorage.get("outlet_id");
                    if (oldOutlet !== res.content.menu.unit_id) {
                      if (LocalStorage.get("isLoggedIn")) {
                        onMenuDataSync(res.content.menu.categories);
                      } else {
                        LocalStorage.set("CartProducts", []);
                      }
                    }
                  }
                  LocalStorage.set("outlet_id", res.content.menu.unit_id);
                  const dataValue = {
                    orderFor: active,
                    orderTime: activeOrder,
                    deliveryTime: data.delivery_area_delivery_time,
                    address: inputData,
                    date: date,
                    time: time,
                    lat: latLng.lat,
                    lng: latLng.lng,
                  };
                  LocalStorage.set("location", dataValue);
                  const db = new Dexie(res.content.menu.unit_id);
                  db.version(1).stores({
                    menu: "++id, categories, item_tags, taxes",
                  });
                  const getMenuData = async () => {
                    const allData = (await db.menu.toArray()) || [];
                    if (allData && allData.length > 0) {
                      await db.menu.update(0, res.content.menu);
                    } else {
                      await db.menu.add(res.content.menu);
                    }
                    history.push(ROUTES.HOME);
                  };
                  getMenuData();
                  // onCallMenu();
                  // const getMenuData = async () => {
                  //   const allData = (await db.menu.toArray()) || [];
                  //   if (allData && allData.length > 0) {
                  //     history.push(ROUTES.PRODUCTLIST);
                  //   }
                  // };
                  // getMenuData();
                  // history.push(ROUTES.PRODUCTLIST);
                }
              } else {
                setAlertPopup(
                  "Sorry!! No Data Found. Please try again later sometime"
                );
              }
            }
          );
        }
      }
    });
  };
  // const handleGoBack = () => {
  //   //
  //   history.goBack();
  // };

  const close = () => {
    setModalShow(false);
  };

  const closedelivery = () => {
    // LocalStorage.set('loginflow', false)
    // setLoginFLow(false)
    setDeliveryFlow(false);
  };

  const onLoginClick = () => {
    history.push({
      pathname: ROUTES.LOGIN,
      state: { redirectToCheckOut: true },
    });
  };
  const viewMore = () => {
    history.push(ROUTES.LOYALTY)
  }
  const onSigninClick = () => {
    history.push({
      pathname: ROUTES.SIGNIN,
      state: { redirectToCheckOut: true },
    });
  };

  return (
    <>
      {islog && (
        <AlertPopup
          title="Logout?"
          description="Are you sure you want logout?"
          okayClick={() => confirmLogout()}
          close={() => setIsLog(false)}
          logout
        />
      )}
      {innerWidth > 1023 && <HeaderCommon />}
      <div className="myprofile">
        {modalShow && (
          <EditProfile
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            date={date}
            setDate={setDate}
            imgUpload={imgUpload}
            imgProfile={imgProfile}
            setImgProfile={setImgProfile}
            CartShow={cartshow}
            otpCheck={otpCheck}
            disableStat={disableStat}
            isDOBchange={isDOBchange}
            close={close}
            profileInfo={profileInfo}
            setDisableStat={setDisableStat}
          />
        )}
        {/* {modalShow && navStat === "2" && (
          <EditProfileOtp
            setNavStat={setNavStat}
            otp={otp}
            setOtp={setOtp}
            verifyOtp={verifyOtp}
            navStat={navStat}
            displayMobile={displayMobileNumber}
            disableStat={disableStat}
          />
        )} */}

        {deliveryFlow && (
          <Modal
            modalCustomClass="modalCustomEdit"
            onCloseHandler={closedelivery}
            hideClose={stat === "stage2" || stat === "stage3"}
            isMobileFullLayout
          >
            <div className="deliveryContainer">
              {/* <div className="deliveryContainer_left">
              <img
                src={LocationImage}
                alt="img"
                height="100%"
                width="100%"
                className="deliveryContainer_left_img"
              />
            </div> */}
              <div className="deliveryContainer_right">
                {/* <img src={Logo} width="157" height="157" /> */}
                <h2 className="deliveryContainer_heading">
                  {/* Delivering a Royal Feast to your doorsteps! */}
                  Enter Location
                </h2>
                <div className="deliveryContainer_switches">
                  <Switch
                    data={switchData}
                    active={active}
                    onFilter={onChangeMode}
                  />
                  <div className="deliveryContainer_switches_gap" />
                  <RadioButton
                    data={radioData}
                    activeOrder={activeOrder}
                    onChange={onChangeOrder1}
                  />
                </div>

                <div className="deliveryContainer_delivery">
                  <div className="deliveryContainer_delivery_complete">
                    <SearchAutoComplete
                      onSearchChange={onMarkerPosition}
                      onChangeSearch={onChangeSearch}
                      inputData={
                        savedAddress && savedAddress.length > 0
                          ? inputData.address
                          : inputData
                      }
                    />
                  </div>
                  <Button
                    content="Submit"
                    className="deliveryContainer_delivery_button"
                    onClick={() => onFindFood()}
                  />
                </div>
                {activeOrder === "orderLater" && (
                  <div className="deliveryContainer_orderLater">
                    <div className="deliveryContainer_orderLater_container">
                      {/* <span className='deliveryContainer_orderLater_label'>
                Enter Date
              </span> */}
                      <div className="deliveryContainer_orderLater_date">
                        <DateTimePicker
                          onChange={(value) => onChangeDate(value)}
                          value={date}
                          className="deliveryContainer_orderLater_datetime"
                          format="yyyy-MM-dd"
                          placeholderText="Enter date"
                          minDate={new Date()}
                          maxDate={
                            new Date(
                              new Date().getTime() + 4 * 24 * 60 * 60 * 1000
                            )
                          }
                          clearIcon={date === "" ? "" : "x "}
                          yearPlaceholder="Enter date"
                          monthPlaceholder=""
                          dayPlaceholder=""
                        />
                      </div>
                    </div>
                    <div className="deliveryContainer_orderLater_container">
                      {/* <span className='deliveryContainer_orderLater_label'>
                Enter Time
              </span> */}
                      {intervalModal && date !== "" && (
                        <div className="deliveryContainer_orderLater_container_drop">
                          <IntervalDrop
                            setInterval={setInterval}
                            interval={interval}
                            intervalModal={intervalModal}
                            setIntervalModal={setIntervalModal}
                            setTime={setTime}
                            timeInterval={intDate}
                          />
                        </div>
                      )}

                      <div className="deliveryContainer_orderLater_time">
                        {/* <DateTimePicker
                onChange={(value) => onChangeTime(value)}
                value={time}
                className='deliveryContainer_orderLater_datetime'
                format='yyyy-MM-dd'
                placeholderText='Enter time'
              /> */}
                        {/* <Input
                        type={timeInp}
                        onChange={(_id, value) => onChangeTime(value)}
                        value={time}
                        onFocus={() => setTimeInp("time")}
                        placeholder="Enter time"
                        customClass="deliveryContainer_orderLater_datetime"
                      /> */}

                        <p
                          onClick={() => {
                            setIntervalModal(!intervalModal);
                          }}
                          className="deliveryContainer_orderLater_datetime"
                        >
                          {interval === "" ? "Select Interval" : interval}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {/* {alertData && (
                  <p className="deliveryContainer_delivery_phrase">
                    Please Enter location !!
                  </p>
                )} */}
                <div
                  className="deliveryContainer_location"
                  onClick={() => onCurrentLocations()}
                >
                  <img src={Detect} width="14" height="14" alt="" />
                  <p className="deliveryContainer_text">
                    {active === "delivery" || activeOrder === "orderLater"
                      ? "Use current location"
                      : "Restaurant near you"}
                  </p>
                </div>

                {/* {outlets && outlets.length > 0 && (
                <>
                  <h4 className="deliveryContainer_error_recommend">
                    Search Result
                  </h4>
                  {outlets.map((item) => {
                    return (
                      <StoreCard
                        data={item}
                        theme={active}
                        onClickStore={onClickStore}
                      />
                    );
                  })}
                </>
              )} */}
                {/* <p className='deliveryContainer_skip' onClick={onSkipLocation}>
          Skip this for now
        </p> */}

                {errorData && (
                  <>
                    <div className="deliveryContainer_error">
                      <p className="deliveryContainer_error_text">
                        90% of people want home delivery on location x. if we
                        reach 10% More then we would open a new restaurant so
                        you can enjoy our food. are you interested? Yes I'm
                        interested
                      </p>
                      <img
                        src={LocationSearch}
                        width="132"
                        height="117"
                        alt=""
                      />
                    </div>
                  </>
                )}
                {errorData1 && countData && countData.count && (
                  <>
                    <h4 className="deliveryContainer_error_recommend">
                      Recommend  Restaurants
                    </h4>
                    <div className="deliveryContainer_error">
                      <p className="deliveryContainer_error_text1">
                        Hey ! found {countData.count} bbk restaurant{" "}
                        {countData.distance} km from your location would you do
                        take away from these restaurants
                      </p>
                      <div className="deliveryContainer_error_button">
                        <Button
                          content="Search Now"
                          onClick={() => onStoreSelect()}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {popup && (
                <MapModal
                  onCurrentDetectLocation={(value) =>
                    onCurrentLocationData(value)
                  }
                  onBackClick={() => onBackClick()}
                  activeTheme={active}
                  inputData={inputData}
                  onChangeInput={() => onChangeInput()}
                  onCenterChange={() => onCenter()}
                  latLng={latLng}
                  onMarkerPosition={onMarkerPosition}
                  onConfirmLocation={() => onConfirmLocation()}
                  onClickStore={() => onClickStore(outlets && outlets[0])}
                />
              )}
              {alertPopup && (
                <AlertPopup
                  title="Sorry!!"
                  description={alertPopup}
                  okayClick={() => onCloseAlert()}
                />
              )}
            </div>
          </Modal>
        )}
        {innerWidth <= 1023 && (
          <div className="myprofile_wholeWrapper">
            <div className="myprofile_logo-wrapper">
              <img
                src={goBack}
                onClick={() => handleGoBack()}
                className="myprofile_logo"
                alt=""
              />
              <p className="myprofile_phrase">My Profile</p>
            </div>
            {/* <div>
           
              <img
                onClick={handleRedirectHome}
                className="myprofile_logo-home"
                alt="logo"
                src={BbkLogo}
              />
            </div> */}
          </div>
        )}
        <div>
          {isLoggedInUser ? (
            <div className="myprofile_header">
              <ProfileCard data={profileInfo} CartShow={cartshow} onOtpSubmition={onOtpSubmition} />
              {innerWidth <= 1023 && (
                <>
                  <div className="myprofile_header_loyalty_popup">
                    {guest_label === 'Gold' && <span className="guest_label">{guest_label} Customer</span>}
                    <div className="loyalty">
                      <div className="loyalty_left">
                        <div className="loyalty_img">
                          <img
                            src={
                              guest_label === "Gold"
                                ? gold
                                : silverBronze
                            }
                            className={guest_label === "Gold" ? "loyalty_logo" : "loyalty_logo_other"}
                          />
                        </div>
                      </div>
                      <div className="loyalty_right">
                        <div>
                          <p className="loyalty_points">{loyalty_points}</p>
                          <p className="loyalty_points_text">Loyalty Points</p>
                        </div>
                        <div className="loyalty_view_more">
                          <p
                            className="loyalty_view_history"
                            onClick={viewMore}
                          >
                            View More
                          </p>
                          <img src={next} />
                        </div>
                      </div>
                    </div>
                    <div className="loyalty_desc">
                      <img src={star} />
                      <span>
                        The more you buy the more you earn BBK loyalty points
                        which you can redeem on your every order*
                      </span>
                    </div>
                  </div>
                  <div className="loyaltyexp">

                    {loyalty_points > 0 &&
                      loyaltyexpiry && (new Date(loyaltyexpiry) < new Date() ?
                        `Loyalty Points Expired on ${formatDate(loyaltyexpiry)}`

                        : loyaltyexpiry &&
                        `Your loyalty points will expire on ${formatDate(
                          loyaltyexpiry
                        )}`)}
                  </div>

                  {/* <div className="loyaltyexp">
                    Your loyalty points will get expire on{" "}
                    {formatDate(loyaltyexpiry)}
                  </div> */}
                </>
              )}
            </div>
          ) : (
            <div className="myprofile_head">
              <div className="myprofile_head_login">
                <Button
                  type="button"
                  className="myprofile_head_login_btn"
                  content="Login/Signup"
                  onClick={() => onLoginClick()}
                />
                {/* <Button
                type="button"
                className="myprofile_header_login_btn"
                content="Signup"
                onClick={() => onSigninClick()}
              /> */}
              </div>
            </div>
          )}
        </div>
        <div className="myprofile_content">
          {innerWidth <= 1023 ? (
            <MenuSideBar
              data={isLoggedInUser ? responsiveData : guestMenuData}
              active={active1}
              onClickCategory={onClickCategory}
              className="myprofile_sidebar"
              customClass="myprofile_text"
            />
          ) : (
            <MenuSideBar
              data={menuData}
              active={active1}
              onClickCategory={onClickCategory}
              className="myprofile_sidebar"
              customClass="myprofile_text"
            />
          )}
          {innerWidth > 1023 ? getMenuData() : ""}
        </div>
      </div>
      {
        verifyModal && <OTPVerify mail={email} setAction={setAction} onOtpSubmission={submitOTP} resend={clickVerifyEmail} />
      }
    </>
  );
}

export default MyProfile;