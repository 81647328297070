import './index.scss';
import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';
import BBK from '../../assets/images/Biryani-by-Kilo.jpg';
import Button from '../../components/button';
import DateTimePicker from 'react-datetime-picker';
import Input from '../../components/input';
import ToggleSwitch from '../../components/toggle-switch';

const Subsription = (props) => {
  const { data, onChange, onClickSubmit } = props;
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [date, setDate] = useState('');
  return (
    <div className='subscription'>
      <h3 className='subscription_headLabel'>
        For Exciting Offer and Newsletter Please Subscribe
      </h3>
      <div className='subscription_inputDivision'>
        <ToggleSwitch
          data={data}
          onSwitch={onChange}
          className='subscription_switch'
        />
        {/* <Input
            type='text'
            onChange={(id, value) => {
            setEmail(value);
            }}
            value={mobile}
            autoComplete='true'
            // onKeyPress={(e) => handleKeypress(e)}
            autoFocus
            customClass='subscription_inputDivision_inpTag'
            placeholder='Email Id'
            value={email}
            />

            <Input
            type='text'
            onChange={(id, value) => {
            setMobile(value);
            }}
            value={mobile}
            isMobileNumber
            autoComplete='true'
            // onKeyPress={(e) => handleKeypress(e)}
            value={mobile}
            autoFocus
            customClass='subscription_inputDivision_inpTag'
            placeholder='Mobile Number'
            />

            <DateTimePicker
                onChange={setDate}
                value={date}
                className='subscription_inputDivision_inpBirthdayTagDate'
                format='yyyy-MM-dd'
                placeholderText='Enter here'
                maxDate={new Date()}
            /> */}
        {/* <Button
          content='Submit'
          className='subscription_inputDivision_inpBtn'
          onClick={onClickSubmit}
        /> */}
      </div>
    </div>
  );
};
export default Subsription;
