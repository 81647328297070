import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import loader from "sass-loader";
import { orderList } from "../../actions/orders";
import OrderCard from "../../components/order-card";
import back from "../../assets/images/goback.svg";
import "./index.scss";
import ProfileCard from "../../components/profile-card";
import MenuSideBar from "../../containers/menuSideBar";
import { ROUTES } from "../../constants/app-constants";
import { Authentication, Logout } from "../../actions/authentication";
import { LocalStorage } from "../../utils/storage";
import Header from "../../containers/header";
import AlertPopup from "../../components/alert-popup";
// import { updateImg, verifyProfileOtp, getProfile } from "../../actions/profile";
import { updateImg, verifyProfileOtp, getProfile } from "../../actions/profile";
import { EmailValidation } from "../../utils/validation";
import moment from "moment";
import { sendProfileOtp } from "../../actions/profile";
import EditProfileOtp from "../../components/edit-otp-profile";
import EditProfile from "../../components/edit-profile-popup";
import Loader from "../../components/Loader";
import HeaderCommon from "../../components/header-common-component";
import Switch from "../../components/switch";
import { act } from "react-dom/test-utils";
import { menuData } from "../../helpers/constants";
import { logOutIdentityBranch } from "../../utils/branch";
import ActiveOrderHistoryCard from "../../components/activeOrderHistoryCard";
import PastOrderHistoryCard from "../../components/pastOrderHistoryCard";

function MyOrders(props) {
  const {
    profileData,
    cartShow,
    Dom,
    routeid,
    id,
    profileorder,
    customClassRoute,
    routeName,
  } = props;

  const [data, setData] = useState([]);
  const [current, setCurrent] = useState([]);
  const [active1, setActive1] = useState();
  const [route, setRoute] = useState(routeid);
  const [islog, setIsLog] = useState(false);
  const [dataList, setdataList] = useState([]);
  const [dataPage, setdataPage] = useState(1);
  const [end, setEnd] = useState(false);
  const [activeEnd, setActiveEnd] = useState(false);
  const [currentLength, setcurrentLength] = useState(false);
  const [currentList, setCurrentList] = useState([]);
  const [currentPageCount, setcurrentPageCount] = useState(1);
  //edit
  const [modalShow, setModalShow] = useState(false);
  const [imgProfile, setImgProfile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [otp, setOtp] = useState("");
  const [disableStat, setDisableStat] = useState(true);
  const [displayMobileNumber, setDisplayMobileNumber] = useState("");
  const [profileInfo, setProfileInfo] = useState({});
  const [navStat, setNavStat] = useState("1");
  const [active, setActive] = useState("active");

  let isDOBchange = LocalStorage.get("isDOB");

  const cartshow = (data) => {
    setModalShow(!modalShow);
    setName(data?.name ? data?.name : "");
    setEmail(data?.email ? data?.email : "");
    setDate(data?.dob && data?.dob);
  };
  const [loginFlow, setLoginFLow] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [stat, setStat] = useState("stage1");

  const imgUpload = (e) => {
    const formdata = new FormData();
    const csvdata = e.target.files[0];
    formdata.append("file", csvdata);
    updateImg(
      formdata,
      (res) => {
        if (res && res?.response?.status === 200) {
          setImgProfile(res?.content[0]);
        } else {
          setImgProfile(res?.content[0]);
        }
      },
      ""
    );
  };

  const otpCheck = () => {
    const payload = {};
    let pay1 = {};
    let displayM = userData.mobile.split("");
    let displayMobile = [];
    let mobileDisplay = "";
    for (let i = 0; i < displayM.length; i++) {
      if (i == 0 || i == 1 || i == 2 || i == 8 || i == 9) {
        displayMobile.push(displayM[i]);
      } else {
        displayMobile.push("*");
      }
    }
    mobileDisplay = displayMobile.join("");
    setDisplayMobileNumber(mobileDisplay);
    if (
      name === "" ||
      email === "" ||
      EmailValidation(email) === false ||
      date === ""
    ) {
      setDisableStat("Enter Valid details");
    } else {
      if (
        userData.email === email &&
        (userData.name !== name ||
          userData.dob !== date ||
          userData.profileInfo !== imgProfile)
      ) {
        pay1 = {
          fname: name,
          email: "",
          dob: moment(date).format("YYYY-MM-DD"),
          profile_picture: imgProfile,
          mobile: mobileDisplay,
        };
        verifyProfileOtp(pay1, (res) => {
          if (res?.response?.status === 200) {
            setProfileInfo(res?.content?.data);
            LocalStorage.set("profileImg", res?.content?.data?.profile_picture);
            let newData = {
              profile_picture: imgProfile,
              dob: res?.content?.data?.dob,
              email: res?.content?.data?.email,
              mobile: res?.content?.data?.mobile,
              name: res?.content?.data?.first_name,
              profileInfo: res?.content?.data?.profile_picture,
              emailVerify:res?.content?.is_user_email_verified
            };
            LocalStorage.set("UserData", newData);
            setName(res?.content?.data?.first_name);
            setModalShow(false);
            setNavStat("1");
          } else {
            setDisableStat(res?.response?.message);
          }
        });
      } else if (
        userData.email !== email ||
        (userData.email !== email &&
          (userData.name !== name ||
            userData.profileInfo !== imgProfile ||
            userData.dob !== date))
      ) {
        pay1 = {
          fname: name,
          email: email,
          profile_picture: imgProfile,
          mobile: mobileDisplay,
        };
        setDisableStat("");
        sendProfileOtp(pay1, (res) => {
          if (res.response.status === 200) {
            setNavStat("2");
          } else {
            setDisableStat(res.response.message);
          }
        });
      } else {
        setDisableStat("");
        sendProfileOtp(payload, (res) => {
          if (res.response.status === 200) {
            setNavStat("2");
          } else {
            setDisableStat(res.response.message);
          }
        });
      }
    }
  };

  const verifyOtp = () => {
    let payload = {};
    if (userData.email !== email) {
      payload = {
        profile_picture: imgProfile,
        email: email,
        fname: name,
        dob: moment(date).format("YYYY-MM-DD"),
        is_registration: false, // false is mandatory,
        mobile: userData.mobile,
        otp: otp,
      };
    } else {
      payload = {
        fname: name,
        email: "",
        dob: moment(date).format("YYYY-MM-DD"),
        profile_picture: imgProfile,
        is_registration: false, // false is mandatory,
        mobile: userData.mobile,
        otp: otp,
      };
    }

    if (
      name === "" ||
      email === "" ||
      EmailValidation(email) === false ||
      date === ""
    ) {
      setDisableStat("Please go back and add all the details, again");
    } else {
      verifyProfileOtp(payload, (res) => {
        if (res?.response?.status === 200) {
          setProfileInfo(res?.content?.data);
          LocalStorage.set("profileImg", res?.content?.data?.profile_picture);
          let newData = {
            dob: res?.content?.data?.dob,
            email: res?.content?.data?.email,
            mobile: res?.content?.data?.mobile,
            name: res?.content?.data?.first_name,
            profileInfo: res?.content?.data?.profile_picture,
            emailVerify:res?.content?.is_user_email_verified
          };
          LocalStorage.set("UserData", newData);
          setName(res?.content?.data?.first_name);
          setModalShow(false);
          setNavStat("1");
        } else {
          setDisableStat(res?.response?.message);
        }
      });
    }
  };

  const onClickCategory = (itm) => {
    if (itm.category_name === "Logout") {
      setIsLog(true);
      return
    }
    setActive1(itm.category_name);
    // if (innerWidth <= 1023) {
    history.push(itm.key);
    // }
  };
  const userData = LocalStorage.get("UserData");

  const confirmLogout = () => {
    Logout((re) => {
      if (re.response.status === 200) {

        //branch event
        logOutIdentityBranch();

        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");

        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              history.push(ROUTES.HOME);
            } else {
              history.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  };
  const getMore = (pageType, view) => {
    if (view) {
      if (pageType === 'past') {
        console.log("cureent past")
        setdataPage(dataPage => dataPage + 1);
        orderList((res) => {
          if (res && res?.content && res?.response?.status === 200) {
            if (res && res?.content) {
              setEnd(res.content.is_end);
              setData(res.content.past_orders);
              setdataList(dataList.concat(res.content.past_orders));
            }
          }
        }, dataPage + 1);
      }
      else {
        if (currentLength % 20 !== 0) {
          console.log("cureent ", Math.floor(currentLength / 20) + 1, currentPageCount + 1);
          if (currentPageCount + 1 != Math.floor(currentLength / 20) + 1)
            setCurrentList(current.slice(0, (currentPageCount + 1) * 20));
          else {
            console.log("cureent else");
            setCurrentList(current.slice(0, currentLength));
            setActiveEnd(true);
          }
        }
        else {
          setCurrentList(current.slice(0, (currentPageCount + 1) * 20));
          if (Math.floor(currentLength / 20) === currentPageCount) {
            setActiveEnd(true)
          }
        }
        setcurrentPageCount(prevState => prevState + 1);
      }
    }
  }

  useEffect(() => {
    getProfile((res) => {
      if (res && res?.response?.status === 200) {
        setProfileInfo(res?.content);
        LocalStorage.set("profileImg", res?.content?.profile_picture);
        let newData = {
          dob: res?.content?.dob,
          email: res?.content?.email,
          mobile: res?.content?.mobile,
          name: res?.content?.first_name,
          profileInfo: res?.content?.profile_picture,
          emailVerify:res?.content?.is_user_email_verified
        };
        LocalStorage.set("UserData", newData);
      }
    });

    orderList((res) => {
      setCurrent([]);
      setdataList([]);
      if (res && res?.content && res?.response?.status === 200) {
        if (res && res?.content) {
          setEnd(res.content.is_end);
          setcurrentLength(res.content.current_orders.length);
          console.log(res.content.current_orders.length, res.content.past_orders.length, "cureent")
          if (res.content.current_orders.length > 20) {
            setCurrentList(res.content.current_orders.slice(0, 20));
            setActiveEnd(false)
          }
          else {
            setCurrentList(res.content.current_orders);
            setActiveEnd(true);
          }
          setCurrent(res.content.current_orders);
          setData(res.content.past_orders);
          setdataList(dataList.concat(res.content.past_orders));
        }
      }
    }, dataPage);

  }, []);

  const getLess = () => {
    setCurrentList(currentList.slice(0, 20));
    setActiveEnd(false);
    setcurrentPageCount(1);
  }
  // useEffect(() => {
  //   console.log(currentList, "cureent")
  // }, [currentList])
  const { innerWidth } = window;
  const history = useHistory();
  const handleGoBack = () => history.goBack();
  const switchData = [
    { id: "active", name: "Active Orders" },
    { id: "past", name: "Past Orders" },
  ];

  const onChangeMode = (value) => {
    setActive(value.id);
  };


  return (
    <>
      <div className="myOrders">
        {innerWidth <= 1023 ? (
          <>
            <div className="myOrders_section">
              <img
                src={back}
                className="myOrders_section_backimg"
                onClick={() => handleGoBack()}
                alt="back"
              />
              <h3 className="myOrders_section_heading">My orders</h3>
            </div>
            <div className="myOrders_bodySection">
              {currentList.length > 0 && <span className="activeStatus">Active Orders</span>}
              {currentList &&
                currentList.length > 0 &&
                currentList.map((item) => {
                  return <ActiveOrderHistoryCard current_orders={item} />;
                })
              }
              {
                currentList.length > 0 && !activeEnd && <div className="myOrders_bodySection_viewmore" onClick={() => getMore("current", true)}><button>View More > </button></div>
              }
              {
                activeEnd && Math.floor(currentLength / 20) > 1 && <div className="myOrders_bodySection_viewmore" onClick={() => getLess()}><button>View Less </button></div>
              }
              {dataList.length > 0 && <span className="pastStatus">Past Orders</span>}
              {dataList &&
                dataList.length > 0 &&
                dataList.map((item) => {
                  return <PastOrderHistoryCard current_orders={item} />;
                })
              }
              {
                dataList.length > 0 && !end && <div className="myOrders_bodySection_viewmore" onClick={() => getMore("past", true)}><button>View More > </button></div>
              }
            </div>
          </>
        ) : (
          <>
            {
              Dom && (
                <>
                  {islog && (
                    <AlertPopup
                      title="Logout?"
                      description="Are you sure you want logout?"
                      okayClick={() => confirmLogout()}
                      logout
                      close={() => setIsLog(false)}
                    />
                  )}
                  <HeaderCommon />
                  <div className="myOrders_wholeSection">
                    <div className="myOrders_wholeSection_one">
                      <ProfileCard data={profileData} CartShow={cartshow} />
                    </div>
                    <div className="myOrders_wholeSection_two">
                      <div className="myOrders_subSectionLeft">
                        <MenuSideBar
                          data={menuData}
                          active={active1}
                          onClickCategory={onClickCategory}
                          customClassRoute={customClassRoute}
                          routeName={routeName}
                        />
                        {/* {getMenuData()} */}
                      </div>
                      {route !== id && (
                        <div className="myOrders_subSectionRight">
                          <h3 className="myOrders_heading">My Orders</h3>
                          <Switch
                            data={switchData}
                            active={active}
                            onFilter={onChangeMode}
                            customClassName="myOrders_switch"
                          />
                          {active === "active" && (
                            <>
                              {currentList &&
                                currentList.length > 0 &&
                                currentList.map((item) => {
                                  return <OrderCard ongoing data={item} />;
                                })}
                              {
                                currentList.length > 0 && !activeEnd && <div className="myOrders_subSectionRight_viewmore" onClick={() => getMore("current", true)}><button>View More > </button></div>
                              }
                              {
                                activeEnd && Math.floor(currentLength / 20) > 1 && <div className="myOrders_subSectionRight_viewmore" onClick={() => getLess()}><button>View Less </button></div>
                              }
                            </>
                          )}
                          {current &&
                            current.length === 0 &&
                            data.length === 0 ? (
                            <h3 className="myOrders_empty">No Orders</h3>
                          ) : (
                            ""
                          )}
                          {active === "past" && (
                            <>
                              {dataList &&
                                dataList.length > 0 &&
                                dataList.map((item) => {
                                  return <OrderCard data={item} />;
                                })
                              }
                              {
                                dataList.length > 0 && !end && <div className="myOrders_subSectionRight_viewmore" onClick={() => getMore("past", true)}><button>View More > </button></div>
                              }
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            {profileorder && (
              <div className="myOrders_subSectionRight">
                <h3 className="myOrders_heading">My Orders</h3>
                <Switch
                  data={switchData}
                  active={active}
                  onFilter={onChangeMode}
                  customClassName="myOrders_switch"
                />
                {active === "active" && (
                  <>
                    {currentList &&
                      currentList.length > 0 &&
                      currentList.map((item) => {
                        return <OrderCard ongoing data={item} />;
                      })}
                    {
                      currentList.length > 0 && !activeEnd && <div className="myOrders_subSectionRight_viewmore" onClick={() => getMore("current", true)}><button>View More > </button></div>
                    }
                    {
                      activeEnd && Math.floor(currentLength / 20) > 1 && <div className="myOrders_subSectionRight_viewmore" onClick={() => getLess()}><button>View Less </button></div>
                    }
                  </>
                )}
                {current && current.length === 0 && data.length === 0 ? (
                  <p className="myOrders_empty">No Orders</p>
                ) : (
                  ""
                )}
                {active === "past" && (
                  <>
                    {dataList &&
                      dataList.length > 0 &&
                      dataList.map((itm) => {
                        return <OrderCard data={itm} />;
                      })
                    }
                    {
                      dataList.length > 0 && !end && <div className="myOrders_subSectionRight_viewmore" onClick={() => getMore("past", true)}><button>View More > </button></div>
                    }
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default MyOrders;
