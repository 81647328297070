import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import './index.scss';
// import { createBrowserHistory } from 'history';
import Router from '../src/app/router';

// const history = createBrowserHistory({ basename: '/webapp' });

// branch.init('secret_test_0BmdQGB44G7UHSvTMm2YrkGL08yZAvjz', function(err, data) {
//   console.log("gokul",err, data);
// });

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Route path='/' component={Router} />
      </BrowserRouter>
    </>
  );
};

export default App;
