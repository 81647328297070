import React, { useState } from 'react';
import './index.scss';
import Button from '../button';


const Rating =(props) => {
    const {items, data,
        rate, count,id,name
    } = props;

    return(
        <div className='rating'>
            {
                items?.map((item) => {
                    return(
                    <div className={`rating_item ${item <= count[id] && 'rating_active'}`} 
                    onClick={() => rate(id,item, name)}>
                        
                        <p className='rating_item_nameLabel'>{item}</p>
                    </div>
                    )
                   
                })
            }
        </div>
    )
}
export default Rating;
