/* eslint-disable no-undef */
import "./index.scss";

import React, { Component } from "react";
import { withRouter } from "react-router";
import { Switch, BrowserRouter, Route } from "react-router-dom";
// import { createBrowserHistory } from 'history';
import Loadable from "react-loadable";
import { Helmet } from "react-helmet";

import Loader from "../components/loader-spin";
import Header from "../containers/header";
import { ROUTES } from "../constants/app-constants.js";
import SignIn from "../pages/signIn";
import ProductListing from "../pages/productListing";
import Search from "../pages/search";
import { LocalStorage } from "../utils/storage";
import Checkout from "../pages/checkout";
// import HeaderMobile from "../containers/header-mobile";
// import Option from "../components/option";
import TrackOrder from "../pages/trackOrder";
import MyProfile from "../pages/my-profile";
import MyOrders from "../pages/my-orders";
import PastOrders from "../pages/past-orders";
import OrderStatus from "../pages/orderStatus";
// import { useLocation } from "react-router-dom";
import StaticPage from "../components/static-component";
import { getStaticPage } from "../actions/staticpages";

import { Authentication } from "../actions/authentication";
import Loyalty from "../pages/loyalty";
import StoreLocator from "../pages/storeLocator";
import LocationAddress from "../pages/location-address";
import Feedback from "../pages/feedback";
import GiftVoucher from "../containers/giftVouchers";
import ReferAndEarn from "../pages/refer-and-earn";
import CurrentBlogs from "../containers/current-blogs";
import DeliveryContainer from "../containers/deliveryContainer";
import CancelPage from "../components/cancel-page";
import CateringIframe from "../pages/catering-Iframe";
import Media from "../pages/media";
import Offers from "../pages/offers";
import Notification from "../pages/notification";
import ProtectedRoute from "../utils/protectedRoutes";
// import Home from "../pages/home";
import PageNotFound from "../components/page-not-found";
import { restOfPagesMeta } from "../helpers/metaTitleDescription";
import Invoice from "../pages/invoice/index.js";

const staticData = LocalStorage.get("staticData");

const blogsData = LocalStorage.get("Blogs");
// const HomePage = Loadable({
//   loader: () => import("../pages/home"),
//   loading: Loader,
//   delay: 300,
//   timeout: 8000,
// });

const LoginPage = Loadable({
  loader: () => import("../pages/login"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});

const BlogPage = Loadable({
  loader: () => import("../pages/blogs"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});

const MenuPage = Loadable({
  loader: () => import("../pages/menu"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});
const WalletPage = Loadable({
  loader: () => import("../pages/WalletPage"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});
const OfferPage = Loadable({
  loader: () => import("../pages/offers"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});

const LayoutPage = Loadable({
  loader: () => import("../containers/Layout"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});

const AddtoCartPage = Loadable({
  loader: () => import("../pages/addtocart"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});

const GiftCardPage = Loadable({
  loader: () => import("../pages/giftcard"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});

const ReferAndEarnPage = Loadable({
  loader: () => import("../pages/refer-and-earn"),
  loading: Loader,
  delay: 300,
  timeout: 8000,
});

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisconnected: false,
      showButton: this.onInitialShowButton(),
      showStatic: false,
      locationPermission: {
        success: "",
        error: "",
      },
    };
  }

  componentDidMount() {
    window.addEventListener("orientationchange", this.setScreenOrientation);
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
    const path = window.location.pathname.substring(1);
    const orderCard = window.location.state;
    console.log()
    if (!LocalStorage.get("AccessToken")) {
      Authentication((res) => {
        if (res.response.status === 200) {
          if (res.content) {
            LocalStorage.set("AccessToken", res.content.access_token);
            LocalStorage.set("RefreshToken", res.content.refresh_token);
            // history.push(ROUTES.PRODUCTLIST);
          } else {
            // history.push(ROUTES.HOME);
          }
        }
      });
    }

    const query = "";
    let urlarr = [];
    LocalStorage.set("statCount", 0);
    // getStaticPage((res) => {
    //   if (res && res?.response?.status === 200) {
    //     res?.content.footer.map((i) => {
    //       // urlarr.push(i.url_text);
    //       if (
    //         // staticData &&
    //         // staticData.url_text &&
    //         path.includes(i.url_text)
    //       ) {
    //         const queryParams = i.url_text;
    //         getStaticPage((res) => {
    //           if (res && res?.response?.status === 200) {
    //             // setProfileData(res && res?.content);
    //             LocalStorage.set("staticData", res?.content?.result);

    //             this.setState({
    //               showStatic: true,
    //               staticData2: res?.content?.result,
    //             });

    //             // history.push(res?.content?.result?.url_text);
    //             window.history.push({
    //               pathname: res?.content?.result?.url_text,
    //               // search: '?query=abc',
    //               state: res?.content?.result,
    //             });
    //           }
    //         }, queryParams);
    //       }
    //     });
    //     if (LocalStorage.get("statCount") === 0) {
    //       //     window.location.reload();
    //     }
    //     LocalStorage.set("statCount", 1);
    //   }
    // }, query);

    // LocalStorage.set('statCount', 0);
  }

  successCallback = (position) => {
    console.log("position", position);
    if (this.state.locationPermission.success.length > 0)
      this.setState({ locationPermission: { success: position } });
  };

  errorCallback = (error) => {
    console.log("error", error);
    if (this.state.locationPermission.error.length > 0)
      this.setState({ locationPermission: { error: error } });
  };

  // intervalId = setInterval(() => {
  //   console.log("logging");
  //   navigator.geolocation.getCurrentPosition(
  //     this.successCallback,
  //     this.errorCallback
  //   );
  // }, 1000);

  onInitialShowButton = () => {
    if (LocalStorage.get("isLoggedIn")) {
      return false;
    }
    return true;
  };

  onShowButton = (value) => {
    this.setState({ showButton: value });
  };

  setScreenOrientation = () => {
    if (window.orientation == 90 || window.orientation == 270) {
      //
      // window.screen.orientation.lock("portrait");
      // alert(
      //   "We do not support landscape mode. Please go back to portrait mode for the best experience "
      // );
    }
  };

  handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") this.setState({ isDisconnected: true });
  };

  render() {

    const { showButton, showLocation, showStatic, staticData2 } = this.state;
    console.log("this.state", this.state);
    const { innerWidth } = window;
    console.log(window.location.pathname);
    console.log("loactionpermission", this.state.locationPermission);

    return (
      <>
        <Helmet>
          <title>{restOfPagesMeta.meta_title}</title>
          <meta name="description" content={restOfPagesMeta.desciptions} />
          <meta name="keywords" content={restOfPagesMeta.keywords} />
        </Helmet>
        <BrowserRouter
          // forceRefresh={true}
          basename="/"
        >
          {innerWidth > 1023 &&
            window.location.pathname.substring(1) !== "webapp/product-list" &&
            window.location.pathname.substring(1) !== "/webapp/checkout" &&
            window.location.pathname.substring(1) !== "/webapp/profile" &&
            window.location.pathname.substring(1) === "/webapp/search" &&
            window.location.pathname.substring(1) !== "/webapp/menu" &&
            window.location.pathname.substring(1) !== "/webapp/track-order" &&
            // window.location.pathname.substring(1) === "/webapp/media" &&
            window.location.pathname.substring(1) !== "webapp/" &&
            innerWidth > 1023 && (
              // (staticData &&  staticData?.url_text && window.location.pathname.substring(1) !== "webapp/" + staticData?.url_text) &&
              // (window.location.pathname.substring(1) !== "webapp/" ||
              //   innerWidth > 1023)
              // (staticData &&  staticData?.url_text && window.location.pathname.substring(1) !== "webapp/" + staticData?.url_text) &&
              // window.location.pathname.substring(1) !== "webapp/" + staticData?.url_text &&
              <Header
                walletStatus
                showButton={showButton}
                showLocation={showLocation}
                onHandlerButton={(value) => this.onShowButton(value)}
              />
            )
          }
          {innerWidth > 1023 &&
            window.location.pathname.substring(1) === "webapp/status" && (
              <Header
                walletStatus
                showButton={showButton}
                showLocation={showLocation}
                onHandlerButton={(value) => this.onShowButton(value)}
              />
            )
          }
          <Switch>
            <Route exact path={ROUTES.LOGIN} component={() => <LoginPage />} />
            <Route exact path={ROUTES.SIGNIN} component={() => <SignIn />} />
            <Route
              exact
              path={ROUTES.HOME}
              component={() => (
                <ProductListing
                  showButton={showButton}
                  showLocation={showLocation}
                />
              )}
            />
            <Route
              exact
              path={ROUTES.TAKEAWAY}
              component={() =>
                innerWidth > 1023 ? (
                  <ProductListing
                    showButton={showButton}
                    showLocation={showLocation}
                  />
                ) : (
                  <DeliveryContainer />
                )
              }
            />
            <Route
              exact
              path={ROUTES.REDIRECTLANDING}
              component={() => (
                <ProductListing
                  showButton={showButton}
                  showLocation={showLocation}
                />
              )}
            />
            {/* <Route exact path={ROUTES.BLOGS} component={() => <BlogPage />} /> */}
            <Route
              exact
              path={ROUTES.DELIVERY_LOCATION}
              component={() => <DeliveryContainer />}
            />
            <Route exact path={ROUTES.MENU} component={() => <MenuPage />} />
            <Route
              exact
              path={ROUTES.SEARCH}
              component={() => (
                <Search showButton={showButton} showLocation={showLocation} />
              )}
            />
            <ProtectedRoute
              exact
              path={ROUTES.CHECKOUT}
              component={() => <Checkout />}
            />

            <Route
              exact
              path={ROUTES.PROFILE}
              component={() => <MyProfile />}
            />
            <Route
              exact
              path={ROUTES.TRACK_ORDER}
              component={() => <TrackOrder />}
            />
            <ProtectedRoute
              exact
              path={ROUTES.MYORDERS}
              component={() => (
                <MyOrders
                  Dom={true}
                  routeid="Myorder"
                  routeName="My orders"
                  customClassRoute="myOrder_route_menuSideBar"
                />
              )}
            />
            {/* <ProtectedRoute
              exact
              path={ROUTES.CATERING}
              component={() => (
                <CateringIframe
                  Dom={true}
                  // routeid="catering"
                  routeName="Catering"
                  customClassRoute="catering_route_menuSideBar"
                />
              )}
            /> */}
            <Route
              exact
              path={ROUTES.PASTORDERS}
              component={() => <PastOrders />}
            />
            <Route
              exact
              path={ROUTES.ORDER_STATUS}
              component={() => <OrderStatus />}
            />
            <ProtectedRoute
              exact
              path={ROUTES.LOYALTY}
              component={() => (
                <Loyalty
                  Dom={true}
                  routeName="Loyalty"
                  customClassRoute="loyalty_route_menuSideBar"
                />
              )}
            />
            <Route
              exact
              path={ROUTES.CANCELPAGE}
              component={() => <CancelPage />}
            />
            <Route
              exact
              path={ROUTES.STORE_LOCATOR}
              component={() => (
                <StoreLocator
                  Dom={true}
                  routeName="Dine In"
                  customClassRoute="store_route_menuSideBar"
                />
              )}
            />
            <ProtectedRoute
              exact
              path={ROUTES.MYADDRESS}
              component={() => (
                <LocationAddress
                  Dom={true}
                  routeName="Location Address"
                  customClassRoute="location_route_menuSideBar"
                />
              )}
            />
            <ProtectedRoute
              exact
              path={ROUTES.FEEDBACK}
              component={() => (
                <Feedback
                  Dom={true}
                  routeName="Feedback"
                  customClassRoute="feedback_route_menuSideBar"
                />
              )}
            />
            <ProtectedRoute
              exact
              path={ROUTES.NOTIFICATION}
              component={() => (
                <Notification
                  Dom={true}
                  routeName="Notification"
                  customClassRoute="feedback_route_menuSideBar"
                />
              )}
            />
            <Route
              exact
              path={ROUTES.GIFT_CARDS}
              component={() => (
                <GiftVoucher
                  Dom={true}
                  routeName="Gift Vouchers"
                  customClassRoute="gift_route_menuSideBar"
                />
              )}
            />
            {/* <ProtectedRoute
              exact
              path={ROUTES.REFER_EARN}
              component={() => (
                <ReferAndEarn
                  Dom={true}
                  routeName="Refer & Earn"
                  customClassRoute="refer_route_menuSideBar"
                />
              )}
            /> */}
            <Route
              exact
              path={ROUTES.MEDIA}
              component={() => <Media Dom={true} />}
            />

            <Route
              exact
              path={"/more-info/:id"}
              component={() => (
                <StaticPage
                  showButton={showButton}
                  showLocation={showLocation}
                />
              )}
            />

            {/* <Route
              exact
              path={"/:id"}
              component={() => (
                <StaticPage
                  showButton={showButton}
                  showLocation={showLocation}
                />
              )}
            /> */}

            <Route
              exact
              path={ROUTES.CURRENT_BLOGS}
              component={() => <CurrentBlogs blogData={blogsData} />}
            />
            <Route
              exact
              path={ROUTES.LAYOUT + "/:value"}
              component={() => <LayoutPage />}
            />
            <Route
              exact
              path={ROUTES.ADDTOCART + "/:itemName"}
              component={() => <AddtoCartPage />}
            />
            <Route exact path={ROUTES.OFFER} component={() => <OfferPage />} />
            <ProtectedRoute
              exact
              path={ROUTES.WALLET}
              component={() => (
                <WalletPage
                  Dom={true}
                  routeName="wallet"
                  customClassRoute="wallet_route_menuSideBar"
                />
              )}
            />
             <ProtectedRoute
              exact
              path={ROUTES.INVOICE}
              component={() => (
                <Invoice/>
              )}
            />
            {/* <Route
              exact
              path={ROUTES.WALLET}
              component={() => <WalletPage />}
            /> */}
            <Route
              exact
              path={ROUTES.GIFTCARD}
              component={() => <GiftCardPage />}
            />
            <Route
              exact
              path={ROUTES.REFER_EARN}
              component={() => <ReferAndEarnPage />}
            />
            <Route
              exact
              path={"/:id"}
              component={() => (
                <StaticPage
                  showButton={showButton}
                  showLocation={showLocation}
                />
              )}
            />
            <Route path="*" exact={true} component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default withRouter(Router);
