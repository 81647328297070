export const  ROUTES = {
  HOME: '/',
  REDIRECTLANDING: '/menu/:id/:id',
  LOGIN: '/login',
  SIGNIN: '/signin',
  PRODUCTLIST: '/product-list',
  TAKEAWAY: '/takeaway',
  CHECKOUT: '/checkout',
  CATEGORYOPTIONS: '/category-options',
  BLOGS: '/blogs',
  DELIVERY_LOCATION:'/delivery-location',
  CURRENT_BLOGS:'/blog/:id',
  MENU: '/menu',
  TRACK_ORDER: '/track-order',
  PROFILE: '/profile',
  MYORDERS: '/my-orders',
  PASTORDERS: '/past-order',
  SEARCH: '/search',
  ORDER_STATUS: '/status',
  GIFT_CARDS: '/gift-vouchers',
  LOYALTY: '/loyalty-page',
  MYADDRESS: '/my-address',
  FEEDBACK: '/feedback',
  NOTIFICATION: '/notification',
  STORE_LOCATOR: '/store-locators',
  REFER_EARN: '/refer-and-earn',
  TERMS_CONDITIONS: '/more-info/terms-conditions',
  CANCELPAGE: '/cancel-page',
  CATERING: '/catering',
  CONTACTUS: '/useful-links/contact-us',
  MEDIA: '/media',
  LAYOUT:'/category',
  ADDTOCART: '/item',
  OFFER:'/offers',
  WALLET: '/wallet',
  GIFTCARD:'/giftcard',
  INVOICE:'/invoice',
};
