import React, { useEffect, useState } from "react";
import "./index.scss";

import HeaderCommon from "../../components/header-common-component/index";
import { offers } from "../../actions/offers";
import percent from "../../assets/images/percent.svg";
import productImg from "../../assets/images/productImg";
import { LocalStorage } from "../../utils/storage";
import Toaster from "../../components/toaster";
import OfferPopup from "../../containers/offerPopup";
import back from "../../assets/images/goback.svg";
import { useHistory } from "react-router-dom";

function Offers(props) {
  console.log("what is offer props", JSON.stringify(props));
  const [offersShow, setOffersShow] = useState([]);
  const [showOffInfo, setShowOffInfo] = useState(false);
  const [showOffData, setShowOffData] = useState({});
  const [payMode, setPayMode] = useState("");
  // const ShowParticular = props;
  const outlet = LocalStorage.get("outlet_id");
  const location = LocalStorage.get("location");
  const payload = {
    channel: location.orderFor === "delivery" ? "delivery" : "take_away",
    online_payment: payMode === "cod" || payMode === "COD" ? false : true,
    outlet_id: outlet,
  };
  const history = useHistory();
  const handleGoBack = () => history.goBack();

  console.log(payload, "payload");
  console.log(offersShow, "resultOffers");
  const ShowParticular = (i) => {
    setShowOffInfo(true);
    setShowOffData(i);
  };
  const closeParticular = () => {
    setShowOffInfo(false);
  };
  const [isMsg, setIsMsg] = useState({
    toastType: false,
    toastText: "",
    isShow: false,
  });

  const [isShowMessage, setisShowMessage] = useState(false);
  const offerListing = () => {
    offers(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setOffersShow([
          ...res?.content?.available_offer,
          ...res?.content?.unavailable_offer,
        ]);
        LocalStorage.set("offersShow", [
          ...res?.content?.available_offer,
          ...res?.content?.unavailable_offer,
        ]);
      }
    });
  };

  useEffect(() => {
    offerListing();
  }, []);

  const onApply = (coupon_code, isShowMessage) => {
    navigator.clipboard.writeText(coupon_code);
    !isShowMessage &&
      setIsMsg({
        toastType: true,
        toastText: `Code ${coupon_code} copied`,
        isShow: true,
      });
  };

  const { innerWidth } = window;

  return (
    <>
      {innerWidth >= 1023 ? (
        <>
          <HeaderCommon />
          <div className="offer_header">
            <img src={percent} alt="Percent" className="offer_image" />
            <div className="offer_header_wrapper">
              <p className="offer_header_wrapper_data">Offers</p>
            </div>
          </div>
          <div className="offer_wholewrapper">
            {offersShow.map((offer, index) => (
              <div key={offer._id}>
                <div className="offer_wholewrapper_container">
                  <div className="offer_wholewrapper_design" key={offer._id}>
                    <div className="offer_wholewrapper_design_">
                      <img
                        src={offer.image || productImg}
                        alt="Offer"
                        className="offer_wholewrapper_design_img"
                      />
                    </div>
                    <div className="offer_wholewrapper_design_center">
                      {offer.coupon_code && (
                        <p className="offer_wholewrapper_design_center_coupon">
                          {offer.coupon_code !== "" && offer.coupon_code}
                        </p>
                      )}

                      {isMsg.isShow && (
                        <Toaster
                          toastType={isMsg.toastType}
                          toastText={isMsg.toastText}
                          closeToast={() =>
                            setIsMsg({
                              isShow: false,
                            })
                          }
                        />
                      )}
                      {innerWidth === 1023 ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: offer.description,
                          }}
                          className="offer_wholewrapper_design_center_phrase"
                        ></p>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: offer.description,
                            }}
                            className="offer_wholewrapper_design_center_phrase"
                          ></p>
                          <p className="offer_wholewrapper_design_center_description">
                            <p className="offer_wholewrapper_design_center_description_one">
                              {offer.short_description}
                            </p>
                            <p
                              className="offer_wholewrapper_design_center_description_two"
                              onClick={() => ShowParticular(offer)}
                            >
                              T&C
                            </p>
                          </p>
                          {showOffInfo && (
                            <OfferPopup
                              data={showOffData}
                              onCloseClick={closeParticular}
                            />
                          )}
                        </>
                      )}

                      {offer.coupon_code && offer.coupon_code !== "" && (
                        <>
                          <button
                            className="offer_wholewrapper_design_rightbar_button"
                            customClass="offer_wholewrapper_design_rightbar_button_content"
                            onClick={() =>
                              onApply(offer.coupon_code, isShowMessage)
                            }
                          >
                            {console.log(offer._id)}
                            Copy
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>

      ) : (


        <>
          <div className="myOrders_section">
            <img
              src={back}
              className="myOrders_section_backimg"
              onClick={() => handleGoBack()}
              alt=""
            />
            <h3 className="myOrders_section_heading">My Offers</h3>
          </div>
          <div className="offer_header">
            <img src={percent} alt="Percent" className="offer_image" />
            <div className="offer_header_wrapper">
              <p className="offer_header_wrapper_data">Offers</p>
            </div>
          </div>
          <div className="offer_wholewrapper">
            {offersShow.map((offer, index) => (
              <div key={offer._id}>
                <div className="offer_wholewrapper_container">
                  <div className="offer_wholewrapper_design" key={offer._id}>
                    <div className="offer_wholewrapper_design_">
                      <img
                        src={offer.image || productImg}
                        alt="Offer"
                        className="offer_wholewrapper_design_img"
                      />
                    </div>
                    <div className="offer_wholewrapper_design_center">
                      {offer.coupon_code && (
                        <p className="offer_wholewrapper_design_center_coupon">
                          {offer.coupon_code !== "" && offer.coupon_code}
                        </p>
                      )}

                      {isMsg.isShow && (
                        <Toaster
                          toastType={isMsg.toastType}
                          toastText={isMsg.toastText}
                          closeToast={() =>
                            setIsMsg({
                              isShow: false,
                            })
                          }
                        />
                      )}
                      {innerWidth === 1023 ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: offer.description,
                          }}
                          className="offer_wholewrapper_design_center_phrase"
                        ></p>
                      ) : (
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: offer.description,
                            }}
                            className="offer_wholewrapper_design_center_phrase"
                          ></p>
                          <p className="offer_wholewrapper_design_center_description">
                            <p className="offer_wholewrapper_design_center_description_one">
                              {offer.short_description}
                            </p>
                            <p
                              className="offer_wholewrapper_design_center_description_two"
                              onClick={() => ShowParticular(offer)}
                            >
                              T&C
                            </p>
                          </p>
                          {showOffInfo && (
                            <OfferPopup
                              data={showOffData}
                              onCloseClick={closeParticular}
                            />
                          )}
                        </>
                      )}

                      {offer.coupon_code && offer.coupon_code !== "" && (
                        <>
                          <button
                            className="offer_wholewrapper_design_rightbar_button"
                            customClass="offer_wholewrapper_design_rightbar_button_content"
                            onClick={() =>
                              onApply(offer.coupon_code, isShowMessage)
                            }
                          >
                            {console.log(offer._id)}
                            Copy
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );

}

export default Offers;