import "./index.scss";
import React, { useState, Component } from "react";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
const ItemCheckBox = (props) => {
  const { data, isChecked, onClickCheck } = props;
  const { innerWidth } = window;
  
  return (
    <div className="itemCheck">
      {innerWidth <= 1023 ? (
        <>
         <div className='itemCheck_addons-wrapper'>
          <div className='itemCheck_addons-wrapper_right'>
          <input
            type="checkbox"
            onClick={onClickCheck}
            className="itemCheck_wholeWrapper_checkbox"
            checked={isChecked}
            disabled={data.disabled}
          />
          <div className="itemCheck_wholeWrapper">
            <div className="itemCheck_wholeWrapper_section">
              <img
                src={data.item_image_url || BBK}
                className="itemCheck_wholeWrapper_section__logo"
                width="55"
                height="55"
                alt="itemCheck_wholeWrapper_section"
              />
            </div>
            <div className="itemCheck_wholeWrapper_data">
              <p className="itemCheck_wholeWrapper_data_headLabel">
                {data.item_name}
              </p>
              <p className="itemCheck_wholeWrapper_data_subLabel">
                {/* {data.item_long_description} */}
              </p>
            </div>
          </div>
           </div>
           <p className="itemCheck_wholeWrapper_data_headLabel">
            {data.item_final_price && `₹${data.item_final_price}`}
          </p>
         </div>
        </>
      ) : (
        <>
          <img
            src={data.item_image_url || BBK}
            className="itemCheck_logo"
            width="55"
            height="55"
            alt="itemCheck_logo"
          />
          <div className="itemCheck_data">
            <p className="itemCheck_data_headLabel">{data.item_name}</p>
            {/* <p className="itemCheck_data_subLabel">
              {data.item_long_description}
            </p> */}
          </div>

          <p className="itemCheck_price">
            {data.item_final_price && `₹${data.item_final_price}`}
          </p>

          <input
            type="checkbox"
            onClick={onClickCheck}
            className="itemCheck_checkbox"
            checked={isChecked}
            disabled={data.disabled}
          />
        </>
      )}
    </div>
  );
};

export default ItemCheckBox;
