/* eslint-disable no-undef */
import React, { useState } from "react";
import moment from "moment";
import "./index.scss";
import BBK from "../../assets/images/biryani-by-kilo-login.jpg";
import login from "../../assets/images/Compress-Biryani-by-Kilo-login.jpg";
import remove from "../../assets/images/close.svg";
import CreateAccount from "../../containers/createAccount";
import BasicDetails from "../../containers/basicDetails";
import OtpVerification from "../../containers/otpVerification";
import { sendOtp, verifyOtp, makeProfile } from "../../actions/login";
import { LocalStorage } from "../../utils/storage";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import loginImage from "../../assets/images/Login-register-screen.jpg";
import { Analytics } from "../../helpers/analytics";
import { registrationBranch, loginBranchEvent, setIdentityBranch } from "../../utils/branch";
import { signUpNetCoreEvent } from "../../helpers/netcore";
import Toaster from "../../components/toaster";

const SignIn = () => {
  const [stat, setStat] = useState("stage1");
  const [mobileNumber, setMobileNumber] = useState("");
  const [displayMobileNumber, setDisplayMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [loginId, setLoginId] = useState("");
  const [errorTxt, setErrorTxt] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorTxt1, setErrorTxt1] = useState("");
  const [successTxt, setSuccessTxt] = useState("");
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  const [isLoginBtnLoading , setLoginBtnLoading]=useState(false);
  const [otp, setOtp] = useState("");

  const onNav = (val) => {
    setStat(val);
  };
  const history = useHistory();

  const onNavigate = () => {
    const { location } = history;
    //  
    if (
      location &&
      location.state &&
      location.state.redirectToCheckOut &&
      location.state.redirectToCheckOut == true
    ) {
      //  
      history.go(-1);
    } else if (LocalStorage.get("outlet_id")) {
      //  
      history.push(ROUTES.HOME);
    } else {
      //  
      history.push(ROUTES.HOME);
    }
  };

  const getMobileData = (number) => {
     
    setLoginBtnLoading(true)
    setMobileNumber(number);
    let displayM = number.split("");
    let displayMobile = [];
    let mobileDisplay = "";
    for (let i = 0; i < displayM.length; i++) {
      if (i == 0 || i == 1 || i == 2 || i == 8 || i == 9) {
        displayMobile.push(displayM[i]);
      } else {
        displayMobile.push("*");
      }
    }
    mobileDisplay = displayMobile.join("");
    setDisplayMobileNumber(mobileDisplay);

    const payload = {
      mobile: number,
      device_type: "web",
      is_signup: true,
      firebase_token: "qwerty",
      login_id: "",
      retry: false,
    };

    sendOtp(payload, (res) => {
       
      // setResMsg(res.response.message);
      // setToast(true);

      if (res?.response?.status === 200) {
          
        //WebOTP API

      const ac = new AbortController();
      
      //Settimeout is for aborting the WebOTP API after 1 min
      setTimeout(() => {
        ac.abort();
      }, 1 * 60 * 1000);

      if ("OTPCredential" in window) {

        try {
          if (navigator.credentials) {
            try {
              navigator.credentials
                .get({
                  otp: { transport: ["sms"] },
                  signal: ac.signal
                })
                .then((otp) => {
                  setOtp(otp.code);
                  ac.abort();
                })
                .catch((err) => {
                  ac.abort();
                  console.log(err);
                });
            } catch(err) {
              console.log(err);
            }
          }
        } catch(err) {
          console.log(err);
        }
      }
        setLoginId(res?.content?.login_id);
        setStat("stage2");
        setLoginBtnLoading(false)
      } else {
        setErrorTxt1(res?.response?.message);
        setLoginBtnLoading(false)
      }
    });
  };

  const getResendData = (number1) => {
    // setMobileNumber(number1);

    const payload = {
      mobile: mobileNumber,
      device_type: "web",
      is_signup: true,
      firebase_token: "qwerty",
      login_id: loginId,
      retry: true,
    };

    sendOtp(payload, (res) => {
      // setResMsg(res.response.message);
      // setToast(true);
      setErrorTxt("")
      if (res?.response?.status === 200) {
        //WebOTP API

      const ac = new AbortController();
      
      //Settimeout is for aborting the WebOTP API after 1 min
      setTimeout(() => {
        ac.abort();
      }, 1 * 60 * 1000);

      if ("OTPCredential" in window) {

        try {
          if (navigator.credentials) {
            try {
              navigator.credentials
                .get({
                  otp: { transport: ["sms"] },
                  signal: ac.signal
                })
                .then((otp) => {
                  setOtp(otp.code);
                  ac.abort();
                })
                .catch((err) => {
                  ac.abort();
                  console.log(err);
                });
            } catch(err) {
              console.log(err);
            }
          }
        } catch(err) {
          console.log(err);
        }
      }
        setLoginId(res?.content?.login_id);
        setSuccessTxt(res?.response?.message);
      }
    });
  };

  const getOtpData = (otpData) => {
    const payload = {
      mobile: mobileNumber,
      otp: otpData,
      login_id: loginId,
    };

    verifyOtp(payload, (res) => {
      if (res?.response?.status === 200) {
        LocalStorage.set("AccessToken", res?.content.access_token);
        LocalStorage.set("RefreshToken", res?.content.refresh_token);
        LocalStorage.set("isLoggedIn", true);
        LocalStorage.set("UserData", {
          name: res?.content.first_name,
          mobile: res?.content.mobile,
        });

        //branch events

        registrationBranch(res?.content?._id);
        setIdentityBranch(res?.content?._id);
        loginBranchEvent(res?.content?._id);

        // Analytics.logEventWithVariable(
        //   Analytics.Order.login,
        //   JSON.stringify(payload)
        // );

        setErrorTxt(res?.response?.message);
        if (res.content && res.content.is_register_on_rezol === false) {
          setStat("stage3");
        } else {
          onNavigate();
        }
      } else {
        setErrorTxt(res?.response?.message ? res?.response?.message : "Invalid OTP!");
      }
    });
  };

  const getBasicDetails = (data) => {
    //  
    setName(data.name);
    setEmail(data.email);
    setDate(data.date);

    const payload = {
      fname: data.name,
      lname: data.name,
      email: data.email,
      mobile: mobileNumber,
      dob: data.date,
    };
    makeProfile(payload, (res) => {
      //  
      if (res?.response?.status === 200) {
        localStorage.setItem("DOB", data.date);
        localStorage.setItem("Email Id", data.email);

        const createdAt = moment().format("DD-MM-YYYY");

        const enventPayload ={
          mobile: "91" + mobileNumber,
          email : data.email,
          name : data.name,
          createdAt : createdAt
        }
        setToaster({
          toastType: true,
          toastText: 'Signed up successfully',
          isShow: true,
        });
        Analytics.signUpEvent(enventPayload)
        // signUpNetCoreEvent(enventPayload)
        history.push(ROUTES.HOME);
        // onNavigate();
      } else {
        setErrorMsg(res?.response?.message + " Please Login!");
      }
    });
  };

  const { innerWidth } = window;
  const handleLoginClick = () => {
    history.goBack();
  };

  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };

  return (
    <div className='loginWrapper_resDiv'>
      <div className='loginWrapper'>
        {innerWidth >= 1024 && (
          <div className='loginWrapper_left'>
            <img src={loginImage} alt='img' height='100%' width='100%' />
          </div>
        )}
        {innerWidth <= 1023 && stat === 'stage1' && (
          <div className='loginWrapper_header'>
            <img src={login} className='loginWrapper_header_img' alt="Login"/>
            <div className='loginWrapper_header_wholeWrapper'>
              <img
                src={remove}
                className='wholeWrapper_close'
                onClick={() => handleLoginClick()}
                alt="remove"
              />
            </div>
          </div>
        )}
        <div className={`loginWrapper_right ${stat === 'stage1' && 'active'}`}>
          {stat === 'stage1' && (
            <CreateAccount
              onNav={onNav}
              errorTxt1={errorTxt1}
              getMobileData={getMobileData}
              isLoginBtnLoading={isLoginBtnLoading}
            />
          )}
          {stat === 'stage2' && (
            <OtpVerification
              autoReadOtpValue={otp}
              mobile={mobileNumber}
              displayMobile={displayMobileNumber}
              onNav={onNav}
              errorTxt={errorTxt}
              getResendData={getResendData}
              getOtpData={getOtpData}
              successTxt={successTxt}
            />
          )}
          {stat === 'stage3' && (
            <BasicDetails
              errorMsg={errorMsg}
              onNav={onNav}
              getBasicDetails={getBasicDetails}
            />
          )}

          {toaster.isShow && (
            <Toaster closeToast={closeToast} toastText={toaster.toastText} />
          )}

          {/* {
         stat === 'stage4' && 
           
        } */}
        </div>
      </div>
    </div>
  );
};
export default SignIn;
