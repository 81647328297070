import React from 'react';
import ProductListing from '../../pages/productListing';
import RecommendedCard from '../recommended-card';
import './index.scss';

const ProductCardList = (props) => {
  const {
    data,
    addCount,
    subCount,
    cartShow,
    id,
    onClickCard,
    modalShow,
    onUpdateContent,
    netcoreEvent,
    addonPopup,
    setIsNegate,
    isNegate,
    change,
    addNewProduct,
    handleAlertModal,
    setLoderById,
    loaderById,
    homeData,
    addNewProductPage
  } = props;

  // data.map((items) => {
  //   if (change) {
  //   }
  // })
  return (
    <div className='productCardListing'>
      {data.map((items) => {
        return (
          <RecommendedCard
            modalShow={modalShow}
            id={items?.item_id}
            onClickCard={onClickCard}
            cartShow={cartShow}
            content={items}
            addCount={addCount}
            subCount={subCount}
            onUpdateContent={onUpdateContent}
            netcoreEvent={netcoreEvent}
            addonPopup={addonPopup}
            setIsNegate={setIsNegate}
            isNegate={isNegate}
            addNewProduct={addNewProduct}
            handleAlertModal={handleAlertModal}
            setLoderById={setLoderById}
            loaderById={loaderById}
            homeData={homeData}
            addNewProductPage={addNewProductPage}
          />
        );
      })}
    </div>
  );
};

export default ProductCardList;
