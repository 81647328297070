import React, { useState, useEffect } from "react";
import { getBlogDetail } from "../../actions/home";
import HeaderCommon from "../../components/header-common-component";
import { getStaticPage } from "../../actions/staticpages";
import Footer from "../footer";
import Header from "../header";
import HeaderMobile from "../header-mobile";

const CurrentBlogs = (props) => {
  const { blogData } = props;
  const { innerWidth } = window;
  const [footerData, setFooterData] = useState({});
  useEffect(() => {
    const query = "";
    
    getStaticPage((res) => {
      if (res && res?.response?.status === 200) {
        const root = document.getElementById("root");
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setFooterData(res && res?.content);
      }
    }, query);
  }, []);

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <HeaderMobile />
          <div className="staticPage">
            <div
              dangerouslySetInnerHTML={{
                __html: blogData && blogData[0]?.content,
              }}
            />
          </div>
          <Footer data={footerData && footerData} />
        </>
      ) : (
        <>
          <HeaderCommon />
          <div className="staticPage">
            <div
              dangerouslySetInnerHTML={{
                __html: blogData && blogData[0]?.content,
              }}
            />
          </div>
          <Footer data={footerData && footerData} />
        </>
      )}
    </>
  );
};

export default CurrentBlogs;
