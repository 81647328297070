import React from "react";
import "./index.scss";
import logo from "../../assets/images/logo.png";
import LastOrderCard from "../../components/last-order-card";

const LastOrderContain = (props) => {
  const { data, wholeClass } = props;
  const { innerWidth } = window;
  return (
    <div className="lastOrderContain">
      <LastOrderCard wholeData={data} wholeClass={wholeClass} data={data[0]?.cart_items} />
    </div>
  );
};
export default LastOrderContain;
