import React from "react";
import "./index.scss";
import logo from "../../assets/images/logo.png";
import Accept from "../../assets/images/acceptOrder.svg";
import Timer from "../../assets/images/timer.svg";

const PastOrderItem = (props) => {
  const { data } = props;
  const { innerWidth } = window;
  return data && data.map((item) => {
    return (
      <div className="pastItemDetails">
        <div className="pastItemDetails_data">
          {innerWidth <= 1023 ? (
            <>
              <img src={item.image_url} className="pastItemDetails_data_img" alt="imageUrl"/>
            </>
          ) : (
            <>
              <img src={item.image_url} className="pastItemDetails_data_img" alt=""/>
            </>
          )}
          <div className="pastItemDetails_data_main">
            <div className="pastItemDetails_data_main_one">
              <div className="pastItemDetails_data_main_one_type" />
              <p className="pastItemDetails_data_main_one_name">{item.name}</p>
            </div>
            <div className="pastItemDetails_data_main_two">
              {/* <img src={Timer} className='pastItemDetails_data_main_two_img' /> */}
              <h1 className="pastItemDetails_data_main_two_sub">Extra Items</h1>
              {
                data?.addons && data?.addons.length > 0 &&
                data?.addons.map((item) => {
                  // return(
                    <p className="pastItemDetails_data_main_two_sub">
                      {item.item_name}
                    </p>
                  // )
                })
              }
            </div>
          </div>

          <p className="pastItemDetails_data_price">
            ₹{Number(parseFloat(item.final_price).toFixed(2))}
          </p>
        </div>
        <div className='pastItemDetails_divider' />
        {/* <p className='pastItemDetails_desc'>data.description hello</p> */}
      </div>
    );
  });
};

export default PastOrderItem;
