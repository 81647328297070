export const homePageMeta = {
    meta_title :'Order Biryani Online from Biryani By Kilo',
    desciptions : `Order Handi Biryani from Biryani by Kilo with High-quality ingredients, cooked fresh for each order. Order now to get exclusive offers.`,
    keywords :'Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore'
}

export const checkoutPageMeta = {
    meta_title :'Biryani By Kilo - Best Biryani Home Delivery',
    desciptions : `Biryani by Kilo India's most Premium Biryani & Kebab delivery chain - Fresh Biryani Dum Cooked in Handi for every individual order. Every biryani is individually prepared in the traditional dum style with distinct layers by our khansamas in natural clay handis`,
    keywords :'Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore'
}


export const safetyStandardMeta = {
    meta_title :'Biryani By Kilo - Fresh Biryani in Handi for every individual order.',
    desciptions : `With coronavirus dominating the news, Biryani by Kilo wanted to take the time to let you know what we have always done and continue to do to ensure everyone's health and safety. Biryani by Kilo India's most Premium Biryani & Kebab delivery chain - Fresh Biryani "Dum Cooked" in Handi for every individual order.`,
    keywords :'Biryani By Kilo safety, Biryani by kilo hygiene, Biryani by Kilo food, Biryani by Kilo contactless delivery, Biryani by Kilo kitchen safety'
}


export const bbkCareMeta = {
    meta_title :'#BBKCares For Daily Wage Earners',
    desciptions : `Feed the Daily Wager. Fighting coronavirus is crucial. Every little help from you can create a huge impact in supporting underprivileged people and those in need. The COVID -19 pandemic is pushing the world towards an economic slowdown. India's Daily Wage Earners and their families have been the worst hit by the lockdown. These unprecedented times require us to come together with....`,
    keywords :'Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore'
}


export const menuPageMeta = {
    meta_title :'Biryani By Kilo Menu | Explore the Best Menu Items | Hyderabadi, Lucknowi, Kolkata | Kebab, Korma, Phirni | Delhi NCR',
    desciptions : `Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore`,
    keywords :'Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore'
}

export const blogPageMeta = {
    meta_title :'Biryani By Kilo, India | Blog',
    desciptions : `Biryani by Kilo India's most Premium Biryani & Kebab delivery chain - Fresh Biryani Dum Cooked in Handi for every individual order. Every biryani is individually prepared in the traditional dum style with distinct layers by our khansamas in natural clay handis`,
    keywords :'Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore'
}

export const mediaPageMeta = {
    meta_title :'Biryani By Kilo in News & Social Media | Online Food Platform | Gurgaon, Delhi, Noida, Ghaziabad',
    desciptions : `Biryani by Kilo India's most Premium Biryani & Kebab delivery chain - Fresh Biryani Dum Cooked in Handi for every individual order. Every biryani is individually prepared in the traditional dum style with distinct layers by our khansamas in natural clay handis`,
    keywords :'Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore'
}

export const loyltyPageMeta = {
    meta_title :'Biryani By Kilo | 150 OFF | 10-20% Cashback | BBKTOHFA | Loyalty Discounts & Benefits | Delhi NCR',
    desciptions : `Now earn cashback on every order when you order online from Mobile App or Website. Download the app and avail Rs 150/- off on your first order. Biryani Offers, Home Delivery Offers, Discount on Home Delivery, Food Discount, Special Discount, Today's Offer on Food, Cashback Offers, BBK Loyalty, Loyalty Program, Biryani by Kilo Loyalty`,
    keywords :'Biryani By Kilo Loyalty, Biryani By Kilo Coins, Biryani By Kilo Loyalty Program, Biryani By Kilo Loyalty Points'
}

export const cateringPageMeta = {
    meta_title :'Food Catering Service | Birthday Party Catering - Biryani by Kilo',
    desciptions : `Biryani by Kilo offers the best food catering service near you for small parties, birthday parties & events. Fill the form to get the best quotes now!`,
    keywords :'food catering service, birthday party catering, small party catering service, food catering, catering service near me'
}

export const dineInPageMeta = {
    meta_title :'Biryani by Kilo | Book a Table',
    desciptions : `Biryani by Kilo India's most Premium Biryani & Kebab delivery chain - Fresh Biryani Dum Cooked in Handi for every individual order. Every biryani is individually prepared in the traditional dum style with distinct layers by our khansamas in natural clay handis`,
    keywords :'Biryani by Kilo Book a Table, Biryani by Kilo table reservation, Biryani by Kilo outlets'
}

export const storeLocatorPageMeta = {
    meta_title :'Biryani by Kilo | Outlets',
    desciptions : `Biryani by Kilo India's most Premium Biryani & Kebab delivery chain - Fresh Biryani Dum Cooked in Handi for every individual order. Every biryani is individually prepared in the traditional dum style with distinct layers by our khansamas in natural clay handis`,
    keywords :'Biryani by Kilo Outlets, Biryani by Kilo store locator'
}

export const contactUsPageMeta = {
    meta_title :'Biryani By Kilo | Contact Us',
    desciptions : `Order online from website biryanibykilo.com or Find Biryani By Kilo at DLF Phase 1, Sector 50 Noida, Shahpurjat Delhi, Dwarka, Sohna Road Gurgaon and Noida Sector 63, Vasant Kunj, Chandigarh, Mohali, LUDHIANA, Jaipur, Lucknow, Dehradun, Greater Noida, Jalandhar, Pune Kharadhi, Pune Wakad, Bhopal, Indore, Kanpur, Patna, Raipur, Wanorie, Baner, Bhubaneswar, Kolkatta Salt Lake.`,
    keywords :'Order online from website biryanibykilo.com or Find Biryani By Kilo at DLF Phase 1, Sector 50 Noida, Shahpurjat Delhi, Dwarka, Sohna Road Gurgaon and Noida Sector 63, Vasant Kunj, Chandigarh, Mohali, LUDHIANA, Jaipur, Lucknow, Dehradun, Greater Noida, Jalandhar, Pune Kharadhi, Pune Wakad, Bhopal, Indore, Kanpur, Patna, Raipur, Wanorie, Baner, Bhubaneswar, Kolkatta Salt Lake, Bangalore, Mysore'
}
export const joinUsPageMeta = {
    meta_title :'Join Us',
    desciptions : `JOIN the fastest growing F&B consumer company in India. Biryani by Kilo is soon launching in other countries and we pride ourselves in putting employees and customers first. With this high pace growth, we're always on the lookout for talent - for every area of the company. Join us in an organized environment where your career possibilities are endless.`,
    keywords :'Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore'
}

export const restOfPagesMeta = {
    meta_title :'Biryani By Kilo - Best Biryani Home Delivery',
    desciptions : `Biryani by Kilo India's most Premium Biryani & Kebab delivery chain - Fresh Biryani Dum Cooked in Handi for every individual order. Every biryani is individually prepared in the traditional dum style with distinct layers by our khansamas in natural clay handis`,
    keywords :'Biryani By Kilo Delhi, Biryani By Kilo NCR, Biryani By Kilo Delhi-NCR, Biryani By Kilo Jaipur, Biryani By Kilo Punjab, Biryani By Kilo Mumbai, Biryani By Kilo Pune, Biryani By Kilo Bangalore, Biryani By Kilo Jaipur, Biryani By Kilo Hyderabad, Biryani By Kilo Bhopal, Biryani By Kilo Jalandhar, Biryani By Kilo Thane, Biryani By Kilo Mysore'
}
