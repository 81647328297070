/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Input from "../../components/input";
import "./index.scss";
import Dexie from "dexie";
import Spice from "../../assets/images/spice.svg";
import { addToCart, getCartItems } from "../../actions/cart";
import Back from "../../assets/images/back.svg";
import weSearchBBK from "../../assets/images/wesearchbbk.svg";
import { LocalStorage } from "../../utils/storage";
import SearchDropdown from "../../components/search-dropdown";
import searchnotfound from "../../assets/images/searchnotfound.svg";
import AddToCart from "../../components/addToCart-popup";
import ProductCardList from "../../containers/productCardListing";
import HeaderMobile from "../../containers/header-mobile";
import SearchInput from "../../components/search-input";
import ItemDetailFooter from "../../containers/item-detail-footer";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
//delivery
import RadioButton from "../../components/radioButton";
// import SearchAutoComplete from "../searchAutocomplete";
import SearchAutoComplete from "../../containers/searchAutocomplete";
import Detect from "../../assets/images/detectLoc.svg";
// import responsiveDetect from "../../assets/images/profileLocation.svg";
// import MapModal from "../mapModal";
import MapModal from "../../containers/mapModal";
import LocationSearch from "../../assets/images/homeLocation.svg";
import remove from "../../assets/images/close.svg";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
// import Input from "../../components/input";
import { OutletMapping, getSkipLocation } from "../../actions/home";
import StoreCard from "../../components/store-card";
// import AlertPopup from "../../components/alert-popup";
import LocationImage from "../../assets/images/Web-Location-page-web.jpg";
import Locationinterested from "../../components/location-interested-card";
import IntervalDrop from "../../components/interval-dropdown";
import Switch from "../../components/switch";
import moment from "moment";
import { getMenuList } from "../../actions/home";
import Header from "../../containers/header";
import Modal from "../../components/modal";
import Button from "../../components/button";
import AlertPopup from "../../components/alert-popup";
import AddOnCart from "../../components/add-on-cart";
import RepeatCustomizationPopup from "../../components/repeat-customization-popup";
import HeaderCommon from "../../components/header-common-component";
import {
  handleGetTotalAmt,
  handleProductForGuestUser,
  handleIsLocationSelected,
} from "../../helpers/sharedFunction";
import Cartsidebar from "../../containers/cartSidebar";
import close from "../../assets/images/close.svg";
import SelectLocationAlert from "../../components/select-location-alert";
import { Analytics } from "../../helpers/analytics";
import { getSavedAddress } from "../../actions/home";

function Search(props) {
  const { showButton, showLocation } = props;

  const outlet = LocalStorage.get("outlet_id");
  const db = new Dexie(outlet);
  const cat = [];
  const [searchList, setSearchList] = useState([]);
  const [searchListMain, setSearchListMain] = useState([]);
  const [itemData1, setItemData1] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [itemList, setItemList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowOne, setModalShowOne] = useState(false);
  const [isNegate, setIsNegate] = useState(false);

  const [modalData, setModalData] = useState({});
  const [addSubCount, setAddSubCount] = useState(0);

  const [categoryModal, setCategoryModal] = useState(false);
  const [cartData, setCartData] = useState({ count: 0, price: 0, data: [] });
  const [taxCharges, setTaxCharges] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [dropShow, setDropShow] = useState(true);
  const history = useHistory();
  const [bottomShow, setBottomShow] = useState(false);

  //addons
  const [customizePopup, setCustomizePopup] = useState(false);
  const [customizeData, setCustomizeData] = useState({});
  // const [itemData1, setItemData1] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [productDataAdd, setProductDataAdd] = useState({});
  const [extras, setExtras] = useState([]);
  const [itemData2, setItemData2] = useState([]);
  const [isAddons, setIsAddons] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  //delivery
  const [stat, setStat] = useState("stage1");
  const [date, setDate] = useState("");

  const [loginFlow, setLoginFLow] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [deliveryFlow, setDeliveryFlow] = useState(false);
  const switchData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Takeaway" },
  ];

  const switchResponsiveData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Pick-up" },
  ];

  const radio = [
    { id: "orderNow", name: "Order Now", checked: true },
    { id: "orderLater", name: "Order Later", checked: false },
  ];

  const responsiveRadio = [
    { id: "orderNow", name: "Now", checked: true },
    { id: "orderLater", name: "Later", checked: false },
  ];
  const [active, setActive] = useState("delivery");
  const [radioData, setRadioData] = useState(radio);
  const [responsiveRadioData, setResponsiveRadioData] =
    useState(responsiveRadio);
  const [activeOrder, setActiveOrder] = useState("orderNow");
  const [inputValue, setInputvalue] = useState({});
  const [popup, setPopup] = useState(false);
  const [inputData, setInputData] = useState("");
  // const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [latLng, setLatLng] = useState({ lat: "", lng: "" });
  const [errorData, setErrorData] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [location, setLocation] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [data, setData] = useState([]);
  const [errorData1, setErrorData1] = useState(false);
  const [countData, setCountData] = useState({});
  const [alertData, setAlertData] = useState(false);
  const [dateData, setDateData] = useState(false);
  const [timeData, settimeData] = useState(false);
  const [timeInp, setTimeInp] = useState("text");
  const [modal, setModal] = useState(false);
  // const {netcore} = props;
  const [interval, setInterval] = useState("");
  const [intervalModal, setIntervalModal] = useState(false);
  const [intDate, setIntDate] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [addNewCartBtnLoader, setAddNewBtnLoader] = useState(false);
  const [multipleAddonAlert, setMultipleAddonAlert] = useState(false);
  const [loaderById, setLoderById] = useState({});
  const [updatedCartData, setUpdatedCartData] = useState([]);
  const [showLoader, setShowLoader] = useState({});
  const [showCartSidebar, setShowCartSidebar] = useState(false);
  const [homeData, setHomeData] = useState({});
  const [alertLocation, setAlertLocation] = useState(false);
  const [savedAddress, setSavedAddress] = useState("");
  const [addons, setAddons] = useState([]);
  const urlparams = useLocation();
  const urlValue = urlparams.pathname.split("/")[3];
  const urlCity = urlparams.pathname.split("/")[2];

  let timeInterval = [];

  db.version(1).stores({
    menu: "++id, categories, item_tags, taxes",
  });

  const onCartClick = (data) => {
    const updateData = LocalStorage.get("CartProducts");
    if (innerWidth <= 1023) {
      Analytics.beginCheckoutEvent(updateData);
      history.push(ROUTES.CHECKOUT);
    } else {
      handleCartSidebar();
      Analytics.viewCartEvent(updateData);
    }
  };

  let updateitem1;

  const getPosts = async () => {
    let updateitem;
    let allPosts = await db.menu.toArray();
    //         //new
    if (allPosts[0].categories.length > 0) {
      setCategoryData(allPosts[0].categories);
      updateitem1 = allPosts[0].categories.map((items) => {
        let updateItem1 = [];
        if (items.has_subcategory === "0") {
          updateItem1 = items.items;
        }
        if (items.has_subcategory === "1") {
          items.subcategories.map((item) => {
            item.items.map((value) => {
              updateItem1.push(value);
            });
          });
          items.items = updateItem1;
        }
        items.itemData = updateItem1.map((item) => {
          item.checked = false;
          item.count = 0;
          if (item.groups.length > 0) {
            item.groups.map((val) => {
              if (val.items.length > 0) {
                val.items.map((groupVal) => {
                  groupVal.checked = false;
                  return groupVal;
                });
              }
            });
          }
          if (LocalStorage.get("CartProducts")) {
            const productData = LocalStorage.get("CartProducts");
            if (productData && productData.length > 0) {
              productData.map((items) => {
                if (items.item_id === item.item_id) {
                  // item.count = items.count;
                  item.count = items.count + item.count;
                  if (!item.complimentary_item) {
                    item._id = items._id;
                  }
                }
              });
            }
          }
          return item;
        });
        items.vegData = updateItem1.filter((item) =>
          item.item_tags.includes("1")
        );
        items.nonVeg = updateItem1.filter((item) =>
          item.item_tags.includes("2")
        );
        return items;
      });
      let arr = [];
      updateitem1.map((item) => {
        item?.items &&
          item?.items.map((i) => {
            arr.push(i);
          });
      });
      setItemData2(arr);
      setHomeData(allPosts[0]);
      //   // for (let i=0; i <= updateitem?.length; i++) {
      //   //   // for (let j=0; updateitem[i]?.itemData?.length; j++) {
      //   //   //   for (let k=0; updateitem[i]?.itemData[j]?.length; k++) {
      //   //   //   }
      //   //   // }
      //   // }
    }
    if (allPosts[0].categories.length > 0) {
      updateitem = allPosts[0].categories.map((items) => {
        let updateItem = [];
        if (items.has_subcategory === "0") {
          updateItem = items.items;
        }
        if (items.has_subcategory === "1") {
          items.subcategories.map((item) => {
            item.items.map((value) => {
              updateItem.push(value);
            });
          });
          items.items = updateItem;
        }
        items.itemData = updateItem.map((item) => {
          item.checked = false;
          item.count = 0;
          if (item.groups.length > 0) {
            item.groups.map((val) => {
              if (val.items.length > 0) {
                val.items.map((groupVal) => {
                  groupVal.checked = false;
                  return groupVal;
                });
              }
            });
          }
          if (LocalStorage.get("CartProducts")) {
            const productData = LocalStorage.get("CartProducts");
            if (productData && productData.length > 0) {
              productData.map((items) => {
                // if (items.item_id === item.item_id) {
                //   item.count = items.count;
                // }
                if (items.item_id === item.item_id) {
                  item.count = items.count + item.count;
                  if (!items.complimentary_item) {
                    item._id = items._id;
                  }
                }
              });
            }
          }
          return item;
        });
        items.vegData = updateItem.filter((item) =>
          item.item_tags.includes("1")
        );
        items.nonVeg = updateItem.filter((item) =>
          item.item_tags.includes("2")
        );
        return items;
      });

      setItemData1(updateitem);
      setItemData(updateitem);
      // for (let i=0; i <= updateitem?.length; i++) {
      //   // for (let j=0; updateitem[i]?.itemData?.length; j++) {
      //   //   for (let k=0; updateitem[i]?.itemData[j]?.length; k++) {
      //   //   }
      //   // }
      // }
    }

    for (let i = 0; i <= updateitem?.length; i++) {
      for (let j = 0; j <= updateitem[i]?.items?.length; j++) {
        cat.push(updateitem[i]?.items[j]?.item_name);
        setSearchList((searchList) => [...searchList, updateitem[i]?.items[j]]);
        setSearchListMain((searchListMain) => [
          ...searchListMain,
          updateitem[i]?.items[j],
        ]);
      }
    }
    setTaxCharges(allPosts[0].taxes);
  };

  const closeDiv = () => {
    setModalShowOne(false);
  };

  const handleKeypress = async (e) => {
    setSearchInput(e.target.value);
    if (e.charCode === 13) {
      setModalShowOne(true);
      setDropShow(false);

      const filtered = searchList.filter((item) => {
        let itemName = item?.item_name && item?.item_name;

        if (
          itemName !== undefined &&
          itemName.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return {
            item_id: item?.item_id,
            name: item.item_name
              .toLowerCase()
              .includes(e.target.value.toLowerCase()),
          };
        }
      });
      let arr = [...new Set(filtered)];
      let stARR = [];
      stARR.push(e.target.value);
      LocalStorage.set("searchterm", [].push(e.target.value));
      const user = LocalStorage.get("UserData");
      setSearchListMain(arr);
    }
  };

  const updateInput = async (input) => {
     
    setSearchInput(input);
    let stARR = [];
    stARR.push(input);
    LocalStorage.set("searchterm", stARR);
    const user = LocalStorage.get("UserData");
    setDropShow(true);

    const filtered = searchList.filter((item) => {
       
      let itemName = item?.item_name && item?.item_name;

      if (
        itemName !== undefined &&
        (itemName.toLowerCase()).includes(input.toLowerCase())
      ) {
         
        return {
          item_id: item?.item_id,
          name: item.item_name.toLowerCase().includes(input.toLowerCase()),
        };
      }
      // item && item?.name.toLowerCase().includes(input.toLowerCase())
    });
    let uniq = [...new Set(filtered)];
    setSearchListMain(uniq);
  };

  const netcoreEvent = (data) => {
    const user = LocalStorage.get("UserData");
    if (user && user.mobile !== "") {
      //fasf
    }
  };

  const onAddToCartData = (data) => {
    //  
    const outletData = LocalStorage.get("Outlet_Details");
    const location = LocalStorage.get("location");
    const outletId = LocalStorage.get("outlet_id");
    let ontaxCal = [];
    let itemData = data.map((value) => {
      taxCharges.map((item) => {
        value.item_taxes[0].taxes.map((values) => {
          if (item.tax_id === values) {
            const tax = {
              tax_tags: values,
              amount:
                (item.tax_value *
                  (Number(parseFloat(value.item_final_price)) * value.count)) /
                100,
            };
            ontaxCal.push(tax);
          }
        });
      });
      const item = {
        item_id: value.item_id,
        item_count: value.count,
        is_veg: value.item_tags.includes("1") ? true : false,
        image_url: value.item_image_url,
        price: value.item_unit_price,
        final_price: value.item_final_price,
        name: value.item_name,
        discount: 0,
        item_tags: ontaxCal,
        addons: value.addons,
      };
      return item;
    });
    const itemDataValue = data.map((value) => {
      const item = {
        prid: value.item_id,
        prqt: parseInt(value.count),
        is_veg: value.item_tags.includes("1") ? "true" : "false",
        image: value.item_image_url,
        produrl: value.item_image_url,
        price: value.single_item_price
          ? Number(parseFloat(value.single_item_price).toFixed(2))
          : Number(parseFloat(value.item_final_price).toFixed(2)),
        name: value.item_name,
        discount: 0,
        addons: [],
      };
      return item;
    });

    const user = LocalStorage.get("UserData");
    const distance=LocalStorage.get('userDistance')

    // const script = document.createElement("script");
    // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
    // script.onload = () => {
    //   smartech("identify", "91" + user.mobile);
    //   smartech("dispatch", "Add To Cart", { items: itemDataValue });
    // };
    // document.head.appendChild(script);

    const payload = {
      channel: location.orderFor,
      outlet_id: outletId,
      items: itemData,
      source: outletData.outlet_status_info,
      user_distance:distance,
    };
    addToCart(payload, (res) => {
      if (res.response.status === 200) {
        // console.log("seacrh page rendered");

        getCartItems(outletId, (res) => {
          if (res.response.status === 200) {
            const data = onStructurize(res.content.items);
            onCartDataValue(data);
          }
        });
      }
    });
  };

  const handleCartSidebar = (value) => {
    if (value === false) {
      return setShowCartSidebar(value);
    }
    setShowCartSidebar((prevState) => !prevState);
  };

  const onCartDataValue = (productData) => {
     
    if (productData && productData.length > 0) {
      setBottomShow(true);
      let totalPrice = 0;
      let Taxes = { cgst: 0, sgst: 0 };
      let cgst = 0;
      let sgst = 0;

      totalPrice = handleGetTotalAmt(productData);

      productData.map((items) => {
        items.item_tags.map((tx) => {
          if (tx.tax_tags === "1") {
            cgst = tx.amount + cgst;
          } else {
            sgst = tx.amount + sgst;
          }
        });
      });
      const data = {
        count: productData.items.length,
        price: Math.floor(totalPrice),
        data: productData.items,
        cgst: Number(productData.billing_data.cgst).toFixed(2),
        sgst: Number(productData.billing_data.sgst).toFixed(2),
      };
      setCartData(data);
      // console.log("onCartDataValue checkoo");
    }
  };

  const onStructurize = (data) => {
    if (data && data.length > 0) {
      const dataItem = data.map((items) => {
        const item = {
          _id: items._id,
          item_name: items.name,
          item_final_price: items.single_item_price,
          item_tags: items.item_tags,
          count: Number(items.item_count),
          item_id: items.item_id,
          item_image_url: items.image_url,
          item_unit_price: items.single_item_price,
          addons: items.addons,
          complimentary_item: items.complimentary_item,
          is_veg: items.is_veg,
          categoryName: items?.category_name,
        };
        return item;
      });
      return dataItem;
    }
    return [];
  };

  const onItemCheck = () => {
    setIsChecked(!isChecked);
  };

  const addonPopup = (item) => {
    setCustomizeData(item && item);
    setCustomizePopup(true);
    LocalStorage.set("addUp", false);
  };
  const addNewProduct = (data) => {
    const isAddAvailble = handleIsLocationSelected();
    if (!isAddAvailble) {
      handleLocationAlertModal();
      return;
    }

    setIsAddons(true);
    LocalStorage.set("addUp", true);
    itemData2 &&
      itemData2.map((item) => {
        if (item.item_id === data.item_id) {
          setProductModal(!productModal);
          item.count = data.count;
          setProductDataAdd(item);
          setExtras(item.groups);
        }
      });
    // history.push(ROUTES.ADDTOCART, { data: data });

  };
  const addNewProductPage = (data) => {
    const itemName = data.item_name.replace(/\[[^\]]+\]/, '').trim().replace(/\s+/g, '-').toLowerCase();;
    history.push(ROUTES.ADDTOCART + "/" + itemName, { data: data })
    const isAddAvailble = handleIsLocationSelected();
    if (!isAddAvailble) {
      handleLocationAlertModal();
      return;
    }

    setIsAddons(true);
    setAddons(data.groups);
    LocalStorage.set("addUp", true);
    console.log("itemData2", itemData2);
    itemData2 &&
      itemData2.map((item) => {
        if (item.item_id === data.item_id) {
          console.log("setProductDataAdd", item);
          setProductModal(!productModal);
          setProductDataAdd(item);
          setExtras(item.groups);
        }
      });

    // history.push(ROUTES.ADDTOCART, { data: productModal });
  };
  const repeatCustom = (item) => {
    //  
    setIsAddons(false);
    item.addons &&
      item.addons.length > 0 &&
      item.addons.map((i) => {
        i.item_count = parseInt(i.item_count) + 1;
      });
    LocalStorage.set("addId", item._id);
    item.count = parseInt(item.count) + 1;
    item._id = item._id;
    onUpdateContent(item, [], true);
    Analytics.addToCardEvent(item, true);
    addonPopup(item);
    setCustomizePopup(false);
  };
  const handleClose = () => {
    setCustomizePopup(false);
  };

  const onDataUpdate = (item) => {
     
    // console.log("onDataUpdate checkkkkk");
    setBottomShow(true);
    onUpdateContent(item, extras, undefined, true);
    // setProductModal(!productModal);
  };

  let cartArr = [];

  const onDataChecked = (item, groupId) => {
    const updateExtra = extras.map((items) => {
      items.items.map((itm) => {
        if (groupId === items.group_id && itm.item_id === item.item_id) {
          itm.checked = !item.checked;
        }
        return itm;
      });
      return items;
    });

    const checkedItems = [];

    const findExtraIndex = extras.findIndex(
      (data) => data.group_id === groupId
    );

    updateExtra &&
      updateExtra[findExtraIndex] &&
      updateExtra[findExtraIndex].items &&
      updateExtra[findExtraIndex].items.map((item) => {
        if (item.checked) {
          checkedItems.push(item.checked);
        }
      });

    if (checkedItems.length == updateExtra[findExtraIndex].group_maximum) {
      updateExtra &&
        updateExtra[findExtraIndex] &&
        updateExtra[findExtraIndex].items &&
        updateExtra[findExtraIndex].items.forEach((item) => {
          if (!item.checked) {
            item.disabled = true;
          }
        });
    } else {
      updateExtra &&
        updateExtra[findExtraIndex] &&
        updateExtra[findExtraIndex].items &&
        updateExtra[findExtraIndex].items.forEach((item) => {
          item.disabled = false;
        });
    }
    setExtras(updateExtra);
  };

  const addCount = () => {
    setAddSubCount(addSubCount + 1);
  };
  const subCount = () => {
    setAddSubCount(addSubCount - 1);
  };

  const handleAlertModal = () => {
    setMultipleAddonAlert((prevState) => !prevState);
  };

  const onTaxCal = (value) => {
     
    let ontaxCal = [];
    taxCharges &&
      taxCharges.map((item) => {
         
        value &&
          value.item_taxes &&
          value.item_taxes[0] &&
          value.item_taxes[0].taxes &&
          value.item_taxes[0].taxes.map((values) => {
             
            if (item.tax_id === values) {
               
              const tax = {
                tax_tags: values,
                tax_value: Number(item.tax_value),
                amount: (item.tax_value * value.item_final_price) / 100,
              };
              ontaxCal.push(tax);
            }
          });
      });
    return ontaxCal;
  };

  const onAddToCart = (value, isRepeat, addNewIsRepeat) => {
     
    let isEqual = false;
    const outletData = LocalStorage.get("Outlet_Details");
    const location = LocalStorage.get("location");
    const outletId = LocalStorage.get("outlet_id");
    let ontaxCal = [];
    const user = LocalStorage.get("UserData");

    taxCharges &&
    taxCharges.length > 0 &&
    taxCharges.map((item) => {
       
      value && 
      value.length > 0 && 
      value?.item_taxes[0] &&
      value?.item_taxes[0]?.taxes &&
      value?.item_taxes[0]?.taxes.map((values) => {
         
        if (item.tax_id === values) {
           
          const tax = {
            tax_tags: values,
            tax_value: Number(item.tax_value),
            amount: (item.tax_value * value.item_final_price) / 100,
          };
          ontaxCal.push(tax);
        }
      });
    });
    let addonsCart = [];
    if (value.groups && value.groups.length > 0) {
      value.groups.map((item) => {
        if (item.items.length > 0) {
          const updateAddons = item.items.filter((val) => val.checked === true);
          if (updateAddons.length > 0) {
            updateAddons.map((vl) => {
              const itemValue = {
                item_id: vl.item_id,
                item_name: vl.item_name,
                group_id: item.group_id,
                item_count: 1,
                final_price: vl.item_final_price,
                group_name: item.group_name,
                discount: 1,
                image_url: vl.item_image_url,
                item_tags: onTaxCal(vl),
              };
              addonsCart.push(itemValue);
            });
          }
        }
      });
    }

    let cartProducts = LocalStorage.get("CartProducts");
    let itemEqualData;
    cartProducts &&
      cartProducts.map((product) => {
        if (product.item_id === value.item_id) {
          if (product.addons.length === 0 && value.addons.length === 0) {
            itemEqualData = product;
            return itemEqualData;
          }
          if (product.addons.length > 0) {
            var productsItem = product.addons.map(function (x) {
              return x.item_id;
            });
            var addItem = value.addons.map(function (x) {
              return x.item_id;
            });
            if (productsItem.sort().join(",") === addItem.sort().join(",")) {
              itemEqualData = product;
              return itemEqualData;
            }
          }
        }
      });

    let addarr = [];
    let addStr = "";
    value.addons &&
      value.addons.length > 0 &&
      value.addons.map((adds) => {
        addarr.push(parseInt(adds.item_id));
      });

    addStr = addarr.toString();
    const distance=LocalStorage.get('userDistance')


    let payload = {};

    payload = {
      channel: location.orderFor,
      outlet_id: outletId,
      delivery_area_id: outletData.delivery_area_id,
      purchase_detail_id: "",
      items: [
        {
          item_id: value.item_id,
          item_count: value.count,
          is_veg: value.item_tags.includes("1") ? true : false,
          image_url: value.item_image_url,
          price: value.single_item_price
            ? value.single_item_price
            : value.item_unit_price,
          final_price: value.single_item_price
            ? value.single_item_price
            : value.item_final_price,
          name: value.item_name,
          discount: 0,
          item_tags: ontaxCal,
          addons: value.addons,
          local_id: parseInt(value.item_id + addStr),
          selected_addons: addarr,
          category_name: value.categoryName,
        },
      ],
      source: outletData.outlet_status_info,
      user_distance:distance
    };

    if (value._id) {
      if (itemEqualData || isRepeat) {
        payload.items[0]._id = value._id;
      }
      if (addNewIsRepeat && itemEqualData) {
        payload.items[0].item_count = itemEqualData.count + value.count;

        if (
          payload.items[0].addons &&
          payload.items[0].addons.length &&
          payload.items[0].addons.length > 0
        ) {
          payload.items[0].addons.map((data) => {
            data.item_count = itemEqualData.count + data.item_count;
            return data;
          });
        }

        if (itemEqualData?._id) {
          payload.items[0]._id = itemEqualData._id;
        }
      }
    }

    addToCart(payload, (res) => {
      if (res.response.status === 200) {
        // console.log("seacrh page rendered");

        getCartItems(outletId, (res) => {
          if (res && res?.response?.status === 200) {
            if (
              res.content &&
              res.content.items &&
              res.content.items.length === 0
            ) {
              setShowCartSidebar(false);
              setBottomShow(false);
              setCartData({ count: 0 });
            } else {
              const itemDetailFooterData = {
                count: res.content.items.length,
                price: res.content.billing_data.sub_total,
                data: res.content.items,
              };
              setCartData(itemDetailFooterData);
            }
            const data = onStructurize(res.content.items);
            LocalStorage.set("CartProducts", data);
            LocalStorage.set(
              "CouponName",
              res.content.billing_data.coupon_used
            );
            setUpdatedCartData(data);
            onCartDataValue(data);
            // setItemData(data);
            if (LocalStorage.get("addUp") === true) {
              LocalStorage.set("addUp", false);
              setCustomizePopup(false);
            }
            // setShowLoader(true)
            setShowLoader((ids) => ({
              ...ids,
              [value._id]: false,
            }));

            const updateitem = categoryData.map((items) => {
              let updateItem = [];
              if (items.has_subcategory === "0") {
                updateItem = items.items;
              }
              if (items.has_subcategory === "1") {
                items.subcategories.map((item) => {
                  item.items.map((value) => {
                    updateItem.push(value);
                  });
                });
                items.items = updateItem;
              }
              items.itemData = updateItem.map((item) => {
                item.checked = false;
                item.categoryName = items.category_name;
                item.count = 0;
                if (item.groups.length > 0) {
                  item.groups.map((val) => {
                    if (val.items.length > 0) {
                      val.items.map((groupVal) => {
                        groupVal.checked = false;
                        return groupVal;
                      });
                    }
                  });
                }
                if (LocalStorage.get("CartProducts")) {
                  const productData = LocalStorage.get("CartProducts");
                  if (productData && productData.length > 0) {
                    productData.map((items) => {
                      if (items.item_id === item.item_id) {
                        item.count = items.count + item.count;
                        if (!item.complimentary_item) {
                          item._id = items._id;
                        }
                      }
                    });
                  }
                }
                return item;
              });
              return items;
            });
            setItemData(updateitem);
            onCartDataValue(updateitem);

            LocalStorage.set("addId", "");
            LocalStorage.set("addUp", false);
          }
        });
      }
      setLoderById((prevState) => ({
        ...prevState,
        [value.item_id]: false,
      }));
      setAddNewBtnLoader(false);
      handleProductModal(false);
    });
  };

  const onAddonsStructure = (value) => {
     
    let addonsCart = [];
    if (value.groups && value.groups.length > 0) {
       
      value &&
        value.groups &&
        value.groups.map((item) => {
           
          if (item.items.length > 0) {
             
            const updateAddons = item.items.filter(
              (val) => val.checked === true
            );
            if (updateAddons.length > 0) {
               
              updateAddons.map((vl) => {
                 
                const itemValue = {
                  item_id: vl.item_id,
                  item_name: vl.item_name,
                  group_id: item.group_id,
                  item_count: value.count,
                  final_price: vl.item_final_price,
                  group_name: item.group_name,
                  discount: 1,
                  image_url: vl.item_image_url,
                  item_tags: onTaxCal(vl),
                };
                addonsCart.push(itemValue);
              });
            }
          }
        });
    } else {
      if (value.addons && value.addons.length && value.addons.length > 0) {
        value &&
          value.addons &&
          value.addons.map((data) => {
            const copyData = {
              item_id: data.item_id,
              item_name: data.item_name,
              group_id: data.category_id,
              item_count: value.count,
              final_price: data.single_item_price,
              group_name: data.category_name,
              discount: 1,
              image_url: data.image_url,
              item_tags: onTaxCal(data),
            };

            addonsCart.push(copyData);
          });
      }
    }

    return addonsCart;
  };

  const onUpdateContent = (item, addons, isRepeat, addNewIsRepeat) => {
     
    if (addons && addons.length > 0) {
      item.groups = addons;
    }
    let productData = [];
    let addonData = onAddonsStructure(item);
    item.addons = addonData;
    if (LocalStorage.get("isLoggedIn")) {
       
      onAddToCart(item, isRepeat, addNewIsRepeat);
    }
    if (!LocalStorage.get("isLoggedIn")) {
      handleProductItems(item, addons, isRepeat, addNewIsRepeat);
    }
  };

  const handleProductItems = (item, addons, isRepeat, addNewIsRepeat) => {
    const updatedProduct = handleProductForGuestUser(
      item,
      isRepeat,
      categoryData
    );
    if (
      updatedProduct &&
      updatedProduct.productData &&
      updatedProduct.productData.length > 0
    ) {
      setCartData(updatedProduct.cartData);
      // console.log("handleProductItems checkoo");
      setBottomShow(true);
    } else {
      setCartData(updatedProduct.cartData);
      // console.log("checkooooo");
      setBottomShow(false);
    }

    setItemData(updatedProduct.updateitem);
    if (!LocalStorage.get("isLoggedIn")) {
      setLoderById({});
      setAddNewBtnLoader(false);
      handleProductModal(false);
      setCustomizePopup(false);
    }
  };

  const CartShow = (data) => {
    const user = LocalStorage.get("UserData");
    setModalShowOne(true);
    setModalData(data);
    setDropShow(false);
    let arruniq = [];
    arruniq.push(data);
    setSearchListMain(arruniq);
  };

  const cartShow = (data) => {
    setModalShow(!modalShow);
    handleProductModal();
  };

  useEffect(() => {
    getPosts();
    getSavedAddress((res) => {
      if (res.response?.status == 200) {
        setSavedAddress(res.content.address);
      }
    });

    //delivery
    if (latLng.lat === "" && latLng.lng === "") {
      setLocation(false);
      // onCurrentLocation();
    }
    let locationDetails = LocalStorage.get("location");
    if (locationDetails) {
      setLocation(true);
      setLatLng({
        lat: locationDetails && locationDetails.lat,
        lng: locationDetails && locationDetails.lng,
      });
      // setActiveOrder(locationDetails && locationDetails.orderTime);
      setActive(locationDetails && locationDetails.orderFor);
      setInputData(locationDetails && locationDetails.address);
      // setDate(locationDetails && locationDetails.date);
      // setTime(locationDetails && locationDetails.time);
    }

    const productData = LocalStorage.get("CartProducts");
    // if (productData && productData.length > 0) {
    //   console.log("useeffect checkooo");
    //   setBottomShow(true);
    // }
  }, [bottomShow]);

  const { innerWidth } = window;
  const LoginCLick = () => {
    setLoginFLow(true);
    setIsSignIn(false);
  };

  const SigninClick = () => {
    setLoginFLow(true);
    setIsSignIn(true);
  };

  const deliveryClick = () => {
    setDeliveryFlow(true);
  };

  //strictly for Delivery
  const onChangeOrder1 = (value) => {
    setActiveOrder(value.id);
    if (innerWidth <= 1023) {
      const updateOrder = responsiveRadioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setResponsiveRadioData(updateOrder);
    } else {
      const updateOrder = radioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setRadioData(updateOrder);
    }
  };

  const onChangeMode = (value) => {
    setActive(value.id);
  };

  const onCurrentLocations = () => {
    setAlertData(false);
    onCurrentLocation();
  };

  const onCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    setInputvalue({
                      location: results[0].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                    });
                    setLatLng({ lat: json.lat, lng: json.lng });
                    onMarkerPosition({ lat: json.lat, lng: json.lng });
                    setLocation(true);
                    setErrorData(false);
                    setInputData(results[0].formatted_address);
                    if (active === "delivery") {
                      setPopup(true);
                    } else {
                      onConfirmLocation();
                    }
                  }
                }
              },
              this
            );
          }
          // eslint-disable-next-line no-unused-vars
        },
        (_error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  const responsiveOnFindFood = () => {
    //  
    if ((active === "delivery" || active === "takeaway") && inputData) {
      //  
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time !== "" &&
      date !== ""
    ) {
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time === ""
    ) {
      settimeData(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      date === ""
    ) {
      setdateData(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onFindFood = () => {
    if (active === "delivery" && inputData) {
      //  
      setPopup(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onChangeInput = (value) => {
    setInputData(value);
  };

  const onBackClick = () => {
    setPopup(false);
  };

  const onChangeDate = (value) => {
    let gotTime = value.getDate();
    setdateData(false);
    setDate(value);
    let dateHour = new Date();
    let hours = dateHour.getHours();
    if (gotTime === new Date().getDate()) {
      for (let i = hours; i < 24; i++) {
        timeInterval.push({
          id: i + ":00",
          name:
            i < 12
              ? Math.floor(i % 12) +
              ":00 AM" +
              " - " +
              Math.floor(parseInt(i % 12) + 1) +
              ":00 AM"
              : Math.floor(i % 12) +
              ":00 PM" +
              " - " +
              Math.floor(parseInt(i % 12) + 1) +
              ":00 PM",
        });
      }
      setIntDate(timeInterval);
    } else {
      timeInterval = [
        {
          id: "10:00",
          name: "10:00 AM - 11:00 AM",
        },
        {
          id: "11:00",
          name: "11:00 AM - 12:00 PM",
        },
        {
          id: "12:00",
          name: "12:00 PM - 01:00 PM",
        },
        {
          id: "13:00",
          name: "01:00 PM - 02:00 PM",
        },
        {
          id: "14:00",
          name: "02:00 PM - 03:00 PM",
        },
        {
          id: "15:00",
          name: "03:00 PM - 04:00 PM",
        },
        {
          id: "16:00",
          name: "04:00 PM - 05:00 PM",
        },
        {
          id: "17:00",
          name: "05:00 PM - 06:00 PM",
        },
        {
          id: "18:00",
          name: "06:00 PM - 07:00 PM",
        },
        {
          id: "19:00",
          name: "07:00 PM - 08:00 PM",
        },
        {
          id: "20:00",
          name: "08:00 PM - 09:00 PM",
        },
        {
          id: "21:00",
          name: "09:00 PM - 10:00 PM",
        },
        {
          id: "22:00",
          name: "10:00 PM - 11:00 PM",
        },
      ];
      setIntDate(timeInterval);
    }
  };

  const onChangeTime = (value) => {
    settimeData(false);
    setTime(value);
  };

  const onCurrentLocationData = (value) => {
    setLatLng({ lat: value.latitude, lng: value.longitude });
    setInputData(value.location);
  };

  const onCenter = (value) => {
    if (value === false) {
      setLatLng({ lat: "", lng: "" });
    }
  };

  const onMarkerPosition = (value) => {
    LocalStorage.remove("Previouslocation");
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng: value },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            // eslint-disable-next-line no-lone-blocks
            setInputvalue({
              location: results[0].formatted_address,
              pincode,
              latitude: value.lat,
              longitude: value.lng,
            });
            setLatLng({ lat: value.lat, lng: value.lng });
            setInputData(results[0].formatted_address);
          }
        }
      },
      this
    );
  };

  const onConfirmLocation = () => {
    setPopup(false);

    let payload = {
      latitude: latLng.lat,
      longitude: latLng.lng,
      is_delivery: active === "delivery" ? true : false,
      is_order_now: activeOrder === "orderNow" ? true : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };

    OutletMapping(payload, (res) => {
      //  
      if (res && res?.response?.status === 200) {
        setErrorData(false);
        setErrorData1(false);
        setDeliveryFlow(false);
        if (res?.content?.areas) {
          setOutlets(res.content.areas);
          // LocalStorage.set('DeliveryAreaPoint',res.content.areas)
          // LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
          onClickStore(res?.content?.areas[0]);
        }
        setData([]);
      } else {
        setOutlets([]);
        setErrorData(true);
        if (active === "delivery") {
          payload = {
            latitude: latLng.lat,
            longitude: latLng.lng,
            is_delivery: false,
            is_order_now: activeOrder === "orderNow" ? true : false,
            order_date:
              activeOrder !== "orderNow" && date !== ""
                ? moment(date).format("YYYY-MM-DD")
                : "",
            order_time: activeOrder !== "orderNow" ? time : "",
            channel: "online_web",
          };
          OutletMapping(payload, (res) => {
            if (res.response.status === 200) {
              if (
                res.content &&
                res.content.areas &&
                res.content.areas.length > 0
              ) {
                setErrorData1(true);
                setCountData({
                  count: res.content.areas.length,
                  distance: parseFloat(
                    res.content.max_distance_user_to_restaurant
                  ).toFixed(2),
                });
                setData(res.content.areas);
              }
            } else {
              setErrorData(true);
              setCountData({});
              setData([]);
            }
          });
        } else {
          setErrorData1(false);
          setCountData({});
          setData([]);
        }
      }
    });
  };

  const onChangeSearch = (value) => {
    setSavedAddress("");
    setAlertData(false);
    setLocation(true);
    setInputData(value.location);
    setInputvalue(value);
  };

  const onCloseAlert1 = () => {
    setAlertPopup("");
  };

  const onStoreSelect = () => {
    setOutlets(data);
    setErrorData1(false);
    setData([]);
    setErrorData(false);
    setActive("takeaway");
  };

  const onClickStore = (data) => {
    // let pointDelivery = LocalStorage.get('DeliveryAreaPoint');
    if (data && data?.is_open) {
      LocalStorage.set("Outlet_Details", data);
      getMenuList(
        urlValue &&
          urlValue?.length > 0 &&
          data?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
          ? urlValue
          : data?.outlets_id,
        (res) => {
          if (res && res.content && res.response.status === 200) {
            if (res.content) {
              if (LocalStorage.get("outlet_id")) {
                const oldOutlet = LocalStorage.get("outlet_id");
                if (oldOutlet !== res.content.menu.unit_id) {
                  if (LocalStorage.get("isLoggedIn")) {
                    onMenuDataSync(res.content.menu.categories);
                  } else {
                    LocalStorage.set("CartProducts", []);
                  }
                }
              }
              LocalStorage.set("outlet_id", res.content.menu.unit_id);
              const dataValue = {
                orderFor: active,
                orderTime: activeOrder,
                deliveryTime: data.delivery_area_delivery_time,
                address: inputData,
                date: date,
                time: time,
                lat: latLng.lat,
                lng: latLng.lng,
              };
              LocalStorage.set("location", dataValue);

              const db = new Dexie(res.content.menu.unit_id);
              db.version(1).stores({
                menu: "++id, categories, item_tags, taxes",
              });
              const getMenuData = async () => {
                const allData = (await db.menu.toArray()) || [];
                if (allData && allData.length > 0) {
                  await db.menu.update(0, res.content.menu);
                } else {
                  await db.menu.add(res.content.menu);
                }
                history.push(ROUTES.SEARCH);
                window.location.reload();
              };
              getMenuData();
              // onCallMenu();
              // const getMenuData = async () => {
              //   const allData = (await db.menu.toArray()) || [];
              //   if (allData && allData.length > 0) {
              //     history.push(ROUTES.PRODUCTLIST);
              //   }
              // };
              // getMenuData();
              // history.push(ROUTES.PRODUCTLIST);
            }
          } else {
            setAlertPopup(
              "Sorry!! No Data Found. Please try again later sometime"
            );
          }
        }
      );
    } else {
      setAlertPopup(
        "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
    }
  };

  const onMenuDataSync = (data) => {
    const outletData = LocalStorage.get("CartProducts");
    const updateitem = data.map((items) => {
      let updateItem = [];
      if (items.has_subcategory === "0") {
        updateItem = items.items;
      }
      if (items.has_subcategory === "1") {
        items.subcategories.map((item) => {
          item.items.map((value) => {
            updateItem.push(value);
          });
        });
        items.items = updateItem;
      }
      items.itemData = updateItem.map((item) => {
        item.checked = false;
        item.count = 0;
        if (LocalStorage.get("CartProducts")) {
          const productData = LocalStorage.get("CartProducts");
          if (productData && productData.length > 0) {
            productData.map((items) => {
              if (items.item_id === item.item_id) {
                item.count = items.count;
              }
            });
          }
        }
        return item;
      });
      items.vegData = updateItem.filter((item) => item.item_tags.includes("1"));
      items.nonVeg = updateItem.filter((item) => item.item_tags.includes("2"));
      return items;
    });
    const updateItemData = [];
    updateitem.map((items) => {
      items.itemData.map((item) => {
        updateItemData.push(item);
      });
    });
    const datavalue = updateItemData.filter((itms) => {
      return outletData.includes(itms.item_id);
    });
  };
  // const { innerWidth } = window;

  const onSkipLocation = () => {
    getSkipLocation((res) => {
      if (res.response.status === 200) {
        LocalStorage.set("Outlet_Details", res.content);
        if (res.content.outlet_id) {
          getMenuList(
            urlValue &&
              urlValue?.length > 0 &&
              res?.content?.outlet__city?.toLowerCase() !==
              urlCity?.toLowerCase()
              ? urlValue
              : res.content.outlet_id,
            (res) => {
              if (res && res.content && res.response.status === 200) {
                if (res.content) {
                  if (LocalStorage.get("outlet_id")) {
                    const oldOutlet = LocalStorage.get("outlet_id");
                    if (oldOutlet !== res.content.menu.unit_id) {
                      if (LocalStorage.get("isLoggedIn")) {
                        onMenuDataSync(res.content.menu.categories);
                      } else {
                        LocalStorage.set("CartProducts", []);
                      }
                    }
                  }
                  LocalStorage.set("outlet_id", res.content.menu.unit_id);
                  const dataValue = {
                    orderFor: active,
                    orderTime: activeOrder,
                    deliveryTime: data.delivery_area_delivery_time,
                    address: inputData,
                    date: date,
                    time: time,
                    lat: latLng.lat,
                    lng: latLng.lng,
                  };
                  LocalStorage.set("location", dataValue);
                  const db = new Dexie(res.content.menu.unit_id);
                  db.version(1).stores({
                    menu: "++id, categories, item_tags, taxes",
                  });
                  const getMenuData = async () => {
                    const allData = (await db.menu.toArray()) || [];
                    if (allData && allData.length > 0) {
                      await db.menu.update(0, res.content.menu);
                    } else {
                      await db.menu.add(res.content.menu);
                    }
                    history.push(ROUTES.HOME);
                  };
                  getMenuData();
                  // onCallMenu();
                  // const getMenuData = async () => {
                  //   const allData = (await db.menu.toArray()) || [];
                  //   if (allData && allData.length > 0) {
                  //     history.push(ROUTES.PRODUCTLIST);
                  //   }
                  // };
                  // getMenuData();
                  // history.push(ROUTES.PRODUCTLIST);
                }
              } else {
                setAlertPopup(
                  "Sorry!! No Data Found. Please try again later sometime"
                );
              }
            }
          );
        }
      }
    });
  };

  // const handleGoBack = () => {
  //   //  
  //   history.goBack();
  // };

  const setSearchClose = () => {
    setSearchInput("");
    setDropShow(false);
  };

  const closedelivery = () => {
    // LocalStorage.set('loginflow', false)
    // setLoginFLow(false)
    setDeliveryFlow(false);
  };

  const handleProductModal = (customItem) => {
    if (customItem === false) {
      setProductModal(customItem);
    } else {
      setProductModal((prevState) => !prevState);
    }
  };

  const handleGoBack = () => history.goBack();

  const handleLocationAlertModal = () => {
    setAlertLocation((prevState) => !prevState);
    if (alertLocation) {
      if (innerWidth > 1023) {
        deliveryClick();
      } else {
        history.push(ROUTES.DELIVERY_LOCATION);
      }
    }
  };

  const handlerFunction = () => {
    setBottomShow(false);
  }

  return (
    <>
      {innerWidth > 1023 && (
        <HeaderCommon
          LoginCLick1={LoginCLick}
          setIsSignIn1={setIsSignIn}
          setLoginFLow1={setLoginFLow}
          loginFlow1={loginFlow}
          isSignIn1={isSignIn}
        />
      )}

      {customizePopup && (
        <RepeatCustomizationPopup
          addonPopup={addonPopup}
          customizeData={customizeData}
          onUpdateContent={onUpdateContent}
          addNewProduct={addNewProduct}
          handleClose={handleClose}
          repeatCustom={repeatCustom}
        />
      )}

      {productModal && (
        <AddToCart
          Content={productDataAdd}
          item={productDataAdd}
          OnDataChecked={onDataChecked}
          Extras={extras}
          onUpdateData={onDataUpdate}
          ItemList={itemList}
          CartShow={cartShow}
          IsChecked={isChecked}
          OnItemCheck={onItemCheck}
          AddSubCount={productDataAdd.count} // 1
          CartArr={cartArr}
          SubCount={subCount}
          isAddons={isAddons}
          productModal={productModal}
          closeModal={handleProductModal}
          addNewCartBtnLoader={addNewCartBtnLoader}
          setAddNewBtnLoader={setAddNewBtnLoader}
        />
      )}

      {deliveryFlow && (
        <Modal
          modalCustomClass="modalCustomEdit"
          onCloseHandler={closedelivery}
          hideClose={stat === "stage2" || stat === "stage3"}
          isMobileFullLayout
        >
          <div className="deliveryContainer">
            {/* <div className="deliveryContainer_left">
            <img
              src={LocationImage}
              alt="img"
              height="100%"
              width="100%"
              className="deliveryContainer_left_img"
            />
          </div> */}
            <div className="deliveryContainer_right">
              {/* <img src={Logo} width="157" height="157" /> */}
              <h2 className="deliveryContainer_heading">
                {/* Delivering a Royal Feast to your doorsteps! */}
                Enter Location
              </h2>
              <div className="deliveryContainer_switches">
                <Switch
                  data={switchData}
                  active={active}
                  onFilter={onChangeMode}
                />
                <div className="deliveryContainer_switches_gap" />
                <RadioButton
                  data={radioData}
                  activeOrder={activeOrder}
                  onChange={onChangeOrder1}
                />
              </div>

              <div className="deliveryContainer_delivery">
                <div className="deliveryContainer_delivery_complete">
                  <SearchAutoComplete
                    onSearchChange={onMarkerPosition}
                    onChangeSearch={onChangeSearch}
                    inputData={
                      savedAddress && savedAddress.length > 0
                        ? inputData.address
                        : inputData
                    }
                  />
                </div>
                <Button
                  content="Submit"
                  className="deliveryContainer_delivery_button"
                  onClick={() => onFindFood()}
                />
              </div>
              {activeOrder === "orderLater" && (
                <div className="deliveryContainer_orderLater">
                  <div className="deliveryContainer_orderLater_container">
                    {/* <span className='deliveryContainer_orderLater_label'>
              Enter Date
            </span> */}
                    <div className="deliveryContainer_orderLater_date">
                      <DateTimePicker
                        onChange={(value) => onChangeDate(value)}
                        value={date}
                        className="deliveryContainer_orderLater_datetime"
                        format="yyyy-MM-dd"
                        placeholderText="Enter date"
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().getTime() + 4 * 24 * 60 * 60 * 1000
                          )
                        }
                        clearIcon={date === "" ? "" : "x "}
                        yearPlaceholder="Enter date"
                        monthPlaceholder=""
                        dayPlaceholder=""
                      />
                    </div>
                  </div>
                  <div className="deliveryContainer_orderLater_container">
                    {/* <span className='deliveryContainer_orderLater_label'>
              Enter Time
            </span> */}
                    {intervalModal && date !== "" && (
                      <div className="deliveryContainer_orderLater_container_drop">
                        <IntervalDrop
                          setInterval={setInterval}
                          interval={interval}
                          intervalModal={intervalModal}
                          setIntervalModal={setIntervalModal}
                          setTime={setTime}
                          timeInterval={intDate}
                        />
                      </div>
                    )}

                    <div className="deliveryContainer_orderLater_time">
                      {/* <DateTimePicker
              onChange={(value) => onChangeTime(value)}
              value={time}
              className='deliveryContainer_orderLater_datetime'
              format='yyyy-MM-dd'
              placeholderText='Enter time'
            /> */}
                      {/* <Input
                      type={timeInp}
                      onChange={(_id, value) => onChangeTime(value)}
                      value={time}
                      onFocus={() => setTimeInp("time")}
                      placeholder="Enter time"
                      customClass="deliveryContainer_orderLater_datetime"
                    /> */}

                      <p
                        onClick={() => {
                          setIntervalModal(!intervalModal);
                        }}
                        className="deliveryContainer_orderLater_datetime"
                      >
                        {interval === "" ? "Select Interval" : interval}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* {alertData && (
                <p className="deliveryContainer_delivery_phrase">
                  Please Enter location !!
                </p>
              )} */}
              <div
                className="deliveryContainer_location"
                onClick={() => onCurrentLocations()}
              >
                <img src={Detect} width="14" height="14" alt="detect"/>
                <p className="deliveryContainer_text">
                  {active === "delivery" || activeOrder === "orderLater"
                    ? "Use current location"
                    : "Restaurant near you"}
                </p>
              </div>

              {/* {outlets && outlets.length > 0 && (
              <>
                <h4 className="deliveryContainer_error_recommend">
                  Search Result
                </h4>
                {outlets.map((item) => {
                  return (
                    <StoreCard
                      data={item}
                      theme={active}
                      onClickStore={onClickStore}
                    />
                  );
                })}
              </>
            )} */}
              {/* <p className='deliveryContainer_skip' onClick={onSkipLocation}>
        Skip this for now
      </p> */}

              {errorData && (
                <>
                  <div className="deliveryContainer_error">
                    <h6 className="deliveryContainer_error_text">
                      90% of people want home delivery on location x. if we
                      reach 10% More then we would open a new restaurant so you
                      can enjoy our food. are you interested? Yes I'm interested
                    </h6>
                    <img src={LocationSearch} width="132" height="117" alt="LocationSearch"/>
                  </div>
                </>
              )}
              {errorData1 && countData && countData.count && (
                <>
                  <h4 className="deliveryContainer_error_recommend">
                    Recommend  Restaurants
                  </h4>
                  <div className="deliveryContainer_error">
                    <p className="deliveryContainer_error_text1">
                      Hey ! found {countData.count} bbk restaurant{" "}
                      {countData.distance} km from your location would you do
                      take away from these restaurants
                    </p>
                    <div className="deliveryContainer_error_button">
                      <Button
                        content="Search Now"
                        onClick={() => onStoreSelect()}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {popup && (
              <MapModal
                onCurrentDetectLocation={(value) =>
                  onCurrentLocationData(value)
                }
                onBackClick={() => onBackClick()}
                activeTheme={active}
                inputData={inputData}
                onChangeInput={() => onChangeInput()}
                onCenterChange={() => onCenter()}
                latLng={latLng}
                onMarkerPosition={onMarkerPosition}
                onConfirmLocation={() => onConfirmLocation()}
                onClickStore={() => onClickStore(outlets && outlets[0])}
              />
            )}
            {alertPopup && (
              <AlertPopup
                title="Sorry!!"
                description={alertPopup}
                okayClick={() => onCloseAlert()}
              />
            )}
          </div>
        </Modal>
      )}

      {multipleAddonAlert && (
        <AlertPopup
          title="Sorry!!"
          description={
            "This item has multiple customisations added. Please remove item from cart."
          }
          okayClick={() => handleAlertModal()}
          alertCart={true}
        />
      )}
      {innerWidth <= 1023 ? (
        <>
          <HeaderMobile />
          <div className="search">
            <div className="search_inputDivision">
              <img
                src={close}
                className="searchInput_wholeWrapper_backImg"
                onClick={() => handleGoBack()}
                alt="Close"
              />
              <div className="search_inputDivision_inp">
                <SearchInput
                  setSearchInput={setSearchClose}
                  searchInput={searchInput}
                  handleKeypress={handleKeypress}
                  updateInput={updateInput}
                  closeDiv={closeDiv}
                />
                {dropShow &&
                  searchInput.length >= 3 &&
                  searchListMain.length > 0 && (
                    <SearchDropdown
                      cartShow={CartShow}
                      data={searchListMain && searchListMain}
                    />
                  )}
              </div>
            </div>

            {modalShowOne ? (
              <ProductCardList
                modalShow={modalShow}
                data={searchListMain}
                addCount={addCount}
                subCount={subCount}
                onUpdateContent={onUpdateContent}
                onShowModal={() => {
                  setModalShow(true);
                }}
                addonPopup={addonPopup}
                setIsNegate={setIsNegate}
                isNegate={isNegate}
                handleAlertModal={handleAlertModal}
                addNewProduct={addNewProduct}
                setLoderById={setLoderById}
                loaderById={loaderById}
                homeData={categoryData}
                addNewProductPage={addNewProductPage}
              />
            ) : (
              <div className="search_back">
                <img src={weSearchBBK} className="search_back_img" alt="weSearchBBK"/>
                <h3 className="search_back_nameLabel">Search for Food!</h3>
              </div>
            )}
            {modalShowOne && searchListMain.length === 0 ? (
              <p className="search_back_text-center">
                No search results found.
              </p>
            ) : (
              ""
            )}
          </div>

          {cartData.count > 0 && bottomShow && (
            <ItemDetailFooter data={cartData} onCartClick={onCartClick} bottomshow={false} />
          )}
        </>
      ) : (
        <>
          <div className="search">
            <div className="search_inputDivision">
              <img
                src={Back}
                className="search_inputDivision_back"
                alt="back"
                onClick={() => history.goBack()}
              />
              <div className="search_inputDivision_inp">
                <SearchInput
                  setSearchInput={setSearchInput}
                  searchInput={searchInput}
                  handleKeypress={handleKeypress}
                  updateInput={updateInput}
                  closeDiv={closeDiv}
                />
                {dropShow &&
                  searchInput.length >= 3 &&
                  searchListMain.length > 0 && (
                    <SearchDropdown
                      cartShow={CartShow}
                      data={searchListMain && searchListMain}
                    />
                  )}
              </div>
            </div>

            {modalShowOne ? (
              <ProductCardList
                modalShow={modalShow}
                data={searchListMain}
                addCount={addCount}
                subCount={subCount}
                onUpdateContent={onUpdateContent}
                onShowModal={() => {
                  setModalShow(true);
                }}
                addonPopup={addonPopup}
                setIsNegate={setIsNegate}
                isNegate={isNegate}
                handleAlertModal={handleAlertModal}
                addNewProduct={addNewProduct}
                setLoderById={setLoderById}
                loaderById={loaderById}
                homeData={categoryData}
              />
            ) : (
              <div className="searchBack">
                <img src={weSearchBBK} className="searchBack_img" alt="weSearchBBK"/>
                <h3 className="searchBack_nameLabel">Search for Food!</h3>
              </div>
            )}
            <div>
              {modalShowOne && searchListMain.length === 0 ? (
                <h2 className="searchBack_text-center">
                  No search results found.
                </h2>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* {console.log("search cart data", cartData)}
          {console.log("search bottomshow", bottomShow)} */}
          {cartData.count > 0 && bottomShow && (
            <ItemDetailFooter
              data={cartData}
              onCartClick={onCartClick}
              bottomShow={bottomShow}
              handlerFunction={handlerFunction}
              bottomshow={true}
              LoginCLick={LoginCLick}
              deliveryClick={deliveryClick}
            />
          )}
        </>
      )}
      <SelectLocationAlert
        alertLocation={alertLocation}
        handleLocationAlertModal={handleLocationAlertModal}
      />
      {innerWidth > 1023 && (
        <Cartsidebar
          cartData={cartData}
          itemData={itemData}
          homeData={homeData}
          updatedCartData={updatedCartData}
          onUpdateContent={onUpdateContent}
          handleCartSidebar={handleCartSidebar}
          setShowLoader={setShowLoader}
          showLoader={showLoader}
          addonPopup={addonPopup}
          isNegate={isNegate}
          setIsNegate={setIsNegate}
          change={false}
          showCartSidebar={showCartSidebar}
          addNewProduct={addNewProduct}
        />
      )}
    </>
  );
}
export default Search;
