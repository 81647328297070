import React, { useEffect, useState } from "react";
import "./index.scss";
import back from "../../assets/images/path.svg";
import Input from "../../components/input";
import DateTimePicker from "react-datetime-picker";
import Button from "../../components/button";
import Location from "../../components/checkout-location";
import LocationImg from "../../assets/images/locationIcon.svg";
import { LocalStorage } from "../../utils/storage";

const AddAddress = (props) => {
  const {
    availableAddresses,
    setAvailableAddresses,
    setSelectedAD,
    selectedAD,
    address,
    reach,
    alternateNumber,
    othersTxt,
    submit,
    setAddress,
    setReach,
    setOthersTxt,
    setAlternateNumber,
    addressChange,
    loc2,
    deliveryText,
    place,
    setPlace,
    customPopUp,
    customAddress,
    typeOfPlace,
    handleclose,
    addAddress,
    onCheckoutClick,
    logStatus,
    isHideOrderType,
    data,
    deliverycontainer,
    saveAddressError
  } = props;
  const [others, setOthers] = useState(false);
  const [home, setHome] = useState(false);
  const [work, setWork] = useState(false);

  useEffect(() => {
    if (!othersTxt || othersTxt?.length === 0) {
      setOthersTxt("Home");
      setHome(true)
    }
    else {
      if (othersTxt === 'Home') {
        setHome(true)
      }
      else if (othersTxt === 'Work') {
        setWork(true)
      }
      else {
        setOthers(true);
      }
    }
    console.log("data", data, address, othersTxt);
  }, [])

  const addOthers = () => {
    setOthersTxt("");
    setWork(false);
    setHome(false);
    setOthers(!others);
  };
  const { innerWidth } = window;
  const handleClick = () => {
    setWork(false);
    setOthers(false);
    setOthersTxt("Home");
    setHome(true);
  };
  const handleWorkClick = () => {
    setHome(false);
    setOthers(false);
    setOthersTxt("Work");
    setWork(true);
  };

  // const [address, setAddress] = useState('');
  // const [reach, setReach] = useState('');
  // const [alternateNumber, setAlternateNumber] = useState('');

  const handleGetPhnAltText = () => {
    const userData = LocalStorage.get('UserData');
    const userMobile = userData ? userData.mobile : '';
    const altPhnText = `Note: We will be calling on this number if call will not be picked on ${userMobile}`;
    return altPhnText;
  };
  return (
    <>
      {innerWidth <= 1023 ? (
        //customPopUp is used in location-address component refer it
        customPopUp ? (
          <>
            <div className="addAddress_wholeWrapper">
              <div className={`addAddress ${customAddress}`}>
                {deliverycontainer ? "" : (<div className="addAddress_location">
                  <Location
                    img={LocationImg}
                    loc2={loc2}
                    deliveryText={deliveryText}
                    addAddress={addAddress}
                    onCheckoutClick={() => onCheckoutClick()}
                    customPopUp={customPopUp}
                    close={handleclose}
                    address_headerSection={true}
                    address_confirmButton="addAddress_address_confirmButton"
                    address_bodySection="addAddress_address_bodySection"
                    logStatus={logStatus}
                  />
                </div>)}

                <div className="addAddress_bodyWrapper">
                  <h1 className="addAddress_headLabel">Enter your Complete Address</h1>
                  <Input
                    type="text"
                    onChange={(id, value) => {
                      setAddress(value);
                    }}
                    value={address}
                    autoFocus
                    customClass={`addAddress_input ${innerWidth <= 1024 && "addAddress_responsive"
                      }`}
                    placeholder="Address line 1"
                    newCustomClass="newCustomClass"
                  />
                  <Input
                    type="text"
                    onChange={(id, value) => {
                      setReach(value);
                    }}
                    value={reach}
                    customClass={`addAddress_input ${innerWidth <= 1024 && "addAddress_responsive"
                      }`}
                    placeholder="Address line 2"
                    newCustomClass="newCustomClass"
                  />
                  <Input
                    type="text"
                    onChange={(id, value) => {
                      setAlternateNumber(value);
                    }}
                    value={alternateNumber}
                    isMobileNumber
                    customClass={`addAddress_input ${innerWidth <= 1024 && "addAddress_responsive"
                      }`}
                    placeholder="Alternative number (optional)"
                    newCustomClass="newCustomClass"
                  />
                  <p className="addAddress_note">
                    {handleGetPhnAltText()}
                  </p>
                  <h1 className="addAddress_headLabel">Save as:</h1>
                  <div className={`addAddress_typeOfPlace ${typeOfPlace}`}>
                    <Button
                      onClick={() => handleClick()}
                      className={`${home
                        ? `addAddress_typeOfPlace_placeSelect`
                        : `addAddress_typeOfPlace_place`
                        }`}
                      content="Home"
                    />
                    <Button
                      onClick={() => handleWorkClick()}
                      className={`${work
                        ? `addAddress_typeOfPlace_placeSelect`
                        : `addAddress_typeOfPlace_place`
                        }`}
                      content="work"
                    />
                    <Button
                      className={`${others
                        ? "addAddress_typeOfPlace_placeSelect"
                        : "addAddress_typeOfPlace_place"
                        }`}
                      onClick={addOthers}
                      content="other"
                    />
                  </div>
                  {others && (
                    <Input
                      onChange={(id, value) => {
                        setOthersTxt(value);
                      }}
                      value={othersTxt}
                      customClass="addAddress_input"
                      placeholder="Other location"
                      newCustomClass="newCustomClass"
                    />
                  )}
                </div>
              </div>
              <div className="button_mobile">
                <Button
                  content="SAVE ADDRESS"
                  disabled={address === ""}
                  className={`addAddress_submit ${(address === "" || othersTxt === "") && "addAddress_disabled"
                    }`}
                  customClass="addressFooterCustomClass"
                  onClick={() => submit()}
                />
                {saveAddressError && <p>{saveAddressError}</p>}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="addAddress_wholeWrapper">
              <div className="addAddress">
                <div className="addAddress_location">
                  <Location
                    img={LocationImg}
                    loc2={loc2}
                    deliveryText={deliveryText}
                    addAddress={() => addressChange()}
                    address_headerSection={true}
                    address_confirmButton="cartLocation"
                    logStatus={logStatus}
                  />
                </div>
                <div className="addAddress_bodyWrapper">
                  <h1 className="addAddress_headLabel">Complete Address</h1>
                  <Input
                    type="text"
                    onChange={(id, value) => {
                      setAddress(value);
                    }}
                    value={address}
                    autoFocus
                    customClass={`addAddress_input ${innerWidth <= 1024 && "addAddress_responsive"
                      }`}
                    placeholder="Address line 1 *"
                  />
                  <Input
                    type="text"
                    onChange={(id, value) => {
                      setReach(value);
                    }}
                    value={reach}
                    customClass={`addAddress_input ${innerWidth <= 1024 && "addAddress_responsive"
                      }`}
                    placeholder="Address line 2 (optional)"
                  />
                  {/* <Input
                    type="text"
                    onChange={(id, value) => {
                      setAlternateNumber(value);
                    }}
                    value={alternateNumber}
                    isMobileNumber
                    customClass={`addAddress_input ${
                      innerWidth <= 1024 && "addAddress_responsive"
                    }`}
                    placeholder="Alternative number (optional)"
                    />
                  <p className="addAddress_note">
                    {handleGetPhnAltText()}
                  </p> */}
                  <div className="addAddress_typeOfPlace">
                    {/* {
                    availableAddresses && availableAddresses.length > 0 && 
                    availableAddresses.map((item) => { 
                      return(
                      item.is_available &&
                      <Button
                        onClick={() => setOthersTxt(item?.AddressType)}
                        className="addAddress_typeOfPlace_place"
                        content={item?.AddressType}
                      />
                      )
                    })
                  } */}
                    <Button
                      onClick={() => handleClick()}
                      className={`${home
                        ? `addAddress_typeOfPlace_placeSelect`
                        : `addAddress_typeOfPlace_place`
                        }`}
                      content="Home"
                    />
                    <Button
                      onClick={() => handleWorkClick()}
                      className={`${work
                        ? `addAddress_typeOfPlace_placeSelect`
                        : `addAddress_typeOfPlace_place`
                        }`}
                      content="work"
                    />
                    <Button
                      className={`${others
                        ? "addAddress_typeOfPlace_placeSelect"
                        : "addAddress_typeOfPlace_place"
                        }`}
                      onClick={addOthers}
                      content="other"
                    />
                  </div>
                  {others && (
                    <Input
                      onChange={(id, value) => {
                        setOthersTxt(value);
                      }}
                      value={othersTxt}
                      customClass="addAddress_input"
                      placeholder="Other location"
                    />
                  )}
                </div>
              </div>
              <Button
                content="Confirm&nbsp;address&nbsp;detail"
                disabled={address === "" || othersTxt === ""}
                className={`addAddress_submit ${(address === "" || othersTxt === "") && "addAddress_disabled"
                  }`}
                customClass="addressFooterCustomClass"
                onClick={() => submit()}
              />
            </div>
          </>
        )
      ) : (
        <div className="addAddress">
          <h2 className="addAddress_nameLabel">Enter address detail</h2>
          <div className="addAddress_location">
            <Location
              img={LocationImg}
              loc2={loc2}
              data={data}
              deliveryText={deliveryText}
              addAddress={() => addressChange()}
              logStatus={logStatus}
              isHideOrderType={isHideOrderType}
            />
          </div>

          <p className="addAddress_headLabel">Enter Full Address</p>
          <Input
            type="text"
            onChange={(id, value) => {
              setAddress(value);
            }}
            value={address}
            autoFocus
            customClass={`addAddress_input ${innerWidth <= 1024 && "addAddress_responsive"
              }`}
            placeholder="Address line 1 *"
          />
          <Input
            type="text"
            onChange={(id, value) => {
              setReach(value);
            }}
            value={reach}
            customClass={`addAddress_input ${innerWidth <= 1024 && "addAddress_responsive"
              }`}
            placeholder="Address line 2 (optional)"
          />
          {/* <Input
            type="text"
            onChange={(id, value) => {
              setAlternateNumber(value);
            }}
            isMobileNumber
            value={alternateNumber}
            customClass={`addAddress_input ${
              innerWidth <= 1024 && "addAddress_responsive"
            }`}
            placeholder="Alternative number (optional)"
          /> */}
          {/* <p className="addAddress_note">
             {handleGetPhnAltText()}
          </p> */}
          <div className="addAddress_typeOfPlace">
            <Button
              onClick={() => handleClick("Home")}
              className={`${home
                ? "addAddress_typeOfPlace_placeSelect"
                : "addAddress_typeOfPlace_place"
                }`}
              content="Home"
            />
            <Button
              onClick={() => handleWorkClick("Work")}
              className={`${work
                ? "addAddress_typeOfPlace_placeSelect"
                : "addAddress_typeOfPlace_place"
                }`}
              content="work"
            />
            <Button
              className={`${others
                ? "addAddress_typeOfPlace_placeSelect"
                : "addAddress_typeOfPlace_place"
                }`}
              onClick={addOthers}
              content="other"
            />
          </div>
          {others && (
            <Input
              onChange={(id, value) => {
                setOthersTxt(value);
              }}
              value={othersTxt}
              customClass="addAddress_input"
              placeholder="Other location"
            />
          )}
          {/* {othersTxt !== '' && <p className='addAddress_text'>{othersTxt}</p>} */}
          <div className='addAddress__btn-sticky'>
            <Button
              content="Confirm address detail"
              disabled={address === "" || othersTxt === ""}
              className={`addAddress_submit ${(address === "" || othersTxt === "") && "addAddress_disabled"
                }`}
              onClick={() => submit()}
            />
            {saveAddressError && <p>{saveAddressError}</p>}
          </div>
        </div>
      )}
    </>
  );
};
export default AddAddress;
