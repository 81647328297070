import { data } from "browserslist";
import React from "react";
import "./index.scss";

const RadioButton = (props) => {
  const {
    data,
    onChange,
    className,
    buttondata,
    label,
    checkbox,
    custom,
    customClass,
    customClassName,
    customClassChecked,
    activeOrder,
    isShowHeader,
    cartRadio,
    walletBalance,
    totalAmount,
    billingDetails,
    Wamount,
    deliveryTime
  } = props;
  console.log(Wamount,"radiodata");

  return (
    <div className={`radioButton ${className}`}>
      {data &&
        data.map((item) => {
          {console.log(item.item_final_price, "78786")}
          return (
            <>
              {cartRadio ? (
                <>
                  <div
                    className={`radioButton_data ${buttondata} ${
                      item?.isDisbled && "radioButton_disbled"
                    }`}
                  >
                    <p
                      className={`radioButton_text ${
                        item.checked
                          ? `${customClassChecked}`
                          : `unchecked ${customClass}`
                      } `}
                      onClick={
                        item?.isDisbled ? () => null : () => onChange(item)
                      }
                    >
                      {item.name || item.item_name}
                    </p>
                    <div className="radioButton_price">
                      {`₹${item.item_final_price}`} 

                      <label className={`radioButton_label ${label}`}>
                        <input
                          type="checkbox"
                          className={`radioButton_checkbox ${checkbox}`}
                          name={item.name || item.item_name}
                          id={item.name || item.item_name}
                          checked={item.checked}
                          onClick={
                            item?.isDisbled ? () => null : () => onChange(item)
                          }
                          readOnly
                        />
                        <span
                          className={`radioButton_custom ${
                            item.checked ? "checked" : "unchecked"
                          } ${custom}`}
                        />
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className={`radioButton_header ${customClassName}`}>
                    {" "}
                    {isShowHeader && item.header}
                  </p>
                  <div
                    className={`radioButton_data ${buttondata} ${
                      item?.isDisbled && "radioButton_disbled"
                    }`}
                  >
                    <label className={`radioButton_label ${label}`}>
                      <input
                        type="checkbox"
                        className={`radioButton_checkbox ${checkbox}`}
                        name={item.name || item.item_name}
                        id={item.name || item.item_name}
                        checked={item.checked}
                        onClick={
                          item?.isDisbled ? () => null : () => onChange(item)
                        }
                        readOnly
                      />
                      <span
                        className={`radioButton_custom ${
                          item.checked ? "checked" : "unchecked"
                        } ${custom}`}
                      />
                    </label>
                    <p
                      className={`radioButton_text ${
                        item.checked
                          ? `${customClassChecked}`
                          : `unchecked ${customClass}`
                      } `}
                      onClick={
                        item?.isDisbled ? () => null : () => onChange(item)
                      }
                    >
                      {item.name || item.item_name}
                      {deliveryTime && item.id === "orderNow" && 
                      <>
                        <br/><span className="cart_page_delivery_time">{`${deliveryTime}`}</span>
                      </>
                      }
                      {item.name === "BBK Money" && (
                        
                        <span className="profileDropdown_profile_data_walletBalance">
                          {"     "}{" "}
                          (
                          {" "} {" "}
                          {` Bal: ${walletBalance === 0 ? "0" : walletBalance} `} {" "} {Wamount}
                          )
                        </span>
                      )}
                    </p>
                  </div>
                </>
              )}
            </>
          );
        })}
    </div>
  );
};
export default RadioButton;
