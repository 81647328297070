import React from "react";
import moment from "moment";
import "./index.scss";
import earnedLoyImg from "../../assets/images/earnedLoy.svg";
import reducedLoyImg from "../../assets/images/reducedLoy.svg";

const PeopleCard = (props) => {
  const { data, isLoyalty } = props;
  const { innerWidth } = window;

  const handleConverToIST = (orderCreated)=>{
    var currentTimeUTC = new Date(orderCreated);
    var currentTimeIST = moment(currentTimeUTC)
      .add(5, "hours")
      .add(30, "minutes");
    let createTime = moment(currentTimeIST._d.toString()).format(
      "MMMM Do YYYY, h:mma"
    );
    return createTime
  }

  const hadnleLoyltyType = (type) => {
    let loyaltyType;
    switch (type) {
      case 'Earned':
      case 'Redeemed':
      case 'Loyalty Addition':
        return (loyaltyType = earnedLoyImg);
      default:
        return reducedLoyImg;
    }
  };

  return data && data?.length > 0
    ? data.map((item) => {
        return (
          <>
            {innerWidth <= 1023 ? (
              <div className="peopleCard">
                <div className="peopleCard_wholeWrapper">
                  <img src={hadnleLoyltyType(item.trans_type)} className="peopleCard_img" alt="trans_type" />
                  <p className="peopleCard_dataDiv_subLabel">
                  {handleConverToIST(item.trans_date_time)}
                </p>
                </div>
                <div className="peopleCard_bodySection">
                  <div className="peopleCard_dataDiv">
                    <p className="peopleCard_dataDiv_nameLabel">
                      {isLoyalty ? item?.FirstName : item?.first_name}
                    </p>
                    <p className="peopleCard_dataDiv_subLabel"></p>
                  </div>
                  <div className="peopleCard_pointsWrapper">
                    <p className="peopleCard_pointLabel">
                      {isLoyalty ? item?.loyalty_points : item?.referrer_points} points
                    </p>
                    <p className="peopleCard_pointLabel">
                  {item.trans_type}
                </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="peopleCard">
                <div>
                
                <img src={hadnleLoyltyType(item.trans_type)} className="peopleCard_img" alt="trans_type" />
                <p className="peopleCard_dataDiv_subLabel">
                 {handleConverToIST(item.trans_date_time)}
                    {/* {moment(item.trans_date_time).format("MMMM Do YYYY, h:mma")} */}
                </p>
                <div className="peopleCard_dataDiv">
                  <p className="peopleCard_dataDiv_nameLabel">
                    {isLoyalty ? item?.FirstName : item?.first_name}
                  </p>
                  <p className="peopleCard_dataDiv_subLabel"></p>
                </div>

                </div>
                <div>
                <p className="peopleCard_pointLabel">
                  {isLoyalty ? item?.loyalty_points : item?.referrer_points} points
                </p>
                <p className="peopleCard_pointLabel">
                  {item.trans_type}
                </p>
                </div>
                
              </div>
            )}
          </>
        );
      })
    : "";
};
export default PeopleCard;
