import React, { useEffect, useState } from "react";
import Button from "../button";
import ClockImg from "../../assets/images/deliverytimeIcon.svg";
import DeliveryImg from "../../assets/images/deliveryIcon.svg";
import back from "../../assets/images/goback.svg";
import edit from "../../assets/images/edit.svg";
import "./index.scss";
import Modal from "../modal";
import Option from "../option";
import AddAddress from "../../containers/add-address";
import { LocalStorage } from "../../utils/storage";
import Input from "../input";
import DateTimePicker from "react-datetime-picker";
import camera from "../../assets/images/camera1.svg";
import { updateImg } from "../../actions/profile";
import { useHistory } from "react-router-dom";
import moment from "moment";

const EditProfile = (props) => {
  const {
    CartShow,
    name,
    setName,
    email,
    setEmail,
    date,
    setDate,
    setImgProfile,
    imgProfile,
    imgUpload,
    otpCheck,
    disableStat,
    isDOBchange,
    close,
    profileInfo,
    setDisableStat
  } = props;
  // const profileImage = LocalStorage.get('profileImg');
  const { innerWidth } = window;
  const history = useHistory();
  const handleGoBack = () => history.goBack();
  const [buttonContent, setButtonContent] = useState("Update");
  const [classNameButton, setClassNameButton] = useState("modalCustomEdit_modal_division_btnTwo");
  useEffect(() => {
    if (profileInfo.first_name === name && profileInfo.email === email) {
      setClassNameButton("modalCustomEdit_modal_division_btnTwo_disable")
    }
    else if (profileInfo.first_name !== name && profileInfo.email === email) {
      setClassNameButton("modalCustomEdit_modal_division_btnTwo")
      setButtonContent("Update")
    }
    else if ((profileInfo.first_name !== name && profileInfo.email !== email) || profileInfo.email !== email) {
      setClassNameButton("modalCustomEdit_modal_division_btnTwo")
      setButtonContent("Verify and Update")
    }
    if (setDisableStat) {
      setDisableStat("");
    }
  }, [email, name])
  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <Modal
            modalCustomClass="modalCustomEdit"
            onCloseHandler={CartShow}
            isMobileFullLayout
          >
            <div className="modalCustomEdit_editProfile_wholeWrapper">
              <div className="modalCustomEdit_modal">
                <div className="modalCustomEdit_wholeWrapper">
                  <img
                    alt="goback"
                    src={back}
                    className="modalCustomEdit_img"
                    onClick={close}
                  />
                  <h3 className="modalCustomEdit_modal_nameLabel">
                    Basic detail
                  </h3>
                </div>
                {/* <div className="modalCustomEdit_divider"></div> */}
                {/* <div className="modalCustomEdit_modal_imageLayout">
                  {imgProfile !== "" && (
                    <img
                      className="modalCustomEdit_modal_imageLayout_profile"
                      src={imgProfile !== "" && imgProfile}
                      alt="imgProfile"
                    />
                  )}
                  <input
                    modalCustomEdit_modal_imageLayout_img
                    hidden
                    onChange={(e) => imgUpload(e)}
                    type="file"
                    id="img"
                    name="img"
                    accept=".png, .jpg, .jpeg"
                  />
                  <div className="modalCustomEdit_modal_imageLayout_wholeWrapper">
                    <label for="img">
                      <img
                        src={edit}
                        className="modalCustomEdit_modal_imageLayout_edit"
                        alt="edit"
                      />
                    </label>
                  </div>
                  
                </div> */}
                <h3 className="modalCustomEdit_modal_smallLabel">Name</h3>
                <Input
                  type="text"
                  onChange={(id, value) => {
                    setName(value);
                  }}
                  value={name}
                  customClass="modalCustomEdit_modal_input"
                  placeholder="E.g. Jhon, Joe"
                />
                {/* <p className="modalCustomEdit_modal_smallLabel">Date of Birth</p> */}
                {/* <DateTimePicker
                  onChange={setDate}
                  value={new Date(date)}
                  disabled={isDOBchange === false ? true : false}
                  className="modalCustomEdit_modal_input2"
                  format="yyyy-dd-MM"
                  maxDate={new Date()}
                  placeholderText="Enter here"
                  clearIcon={null}
                  onKeyDown={(e) => e.preventDefault()}
                /> */}

                {/* <input
                type="date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                value={date ? date : new Date()}
                disabled={isDOBchange === false ? true : false}
                max={moment().format("YYYY-MM-DD")} 
                className={`modalCustomEdit_modal_input2 ${!isDOBchange ? 'modalCustomEdit_modal_disable':''}`}
                onKeyDown={(e) => {
                  e.preventDefault();
               }}
               /> */}

                {/* <p className="modalCustomEdit_modal_smallLabel">
                  Note: Only onetime in a life you can change your birthday do
                  you really wanna change it ?
                </p> */}
                <h3 className="modalCustomEdit_modal_smallLabel">Email ID</h3>
                <Input
                  type="text"
                  onChange={(id, value) => {
                    setEmail(value);
                  }}
                  value={email}
                  customClass="modalCustomEdit_modal_input"
                  placeholder="E.g. Jhon, Joe"
                />
                {/* <div className="modalCustomEdit_modal_division">
                <Button
                  className="modalCustomEdit_modal_division_btnOne"
                  onClick={() => CartShow()}
                  content="Cancel"
                  customClass="customClass"
                />
                <div className="modalCustomEdit_modal_division_gap" />
                <Button
                  className="modalCustomEdit_modal_division_btnTwo"
                  onClick={() => otpCheck()}
                  content="Done"
                  customClass="customClass"
                />
              </div> */}
                <p className="modalCustomEdit_modal_error">{disableStat}</p>
              </div>
              <div className="modalCustomEdit_modal_division">
                <Button
                  className="modalCustomEdit_modal_division_btnOne"
                  onClick={() => CartShow()}
                  content="Cancel"
                  customClass="customClass"
                />
                <div className="modalCustomEdit_modal_division_gap" />
                <Button
                  className={classNameButton}
                  onClick={classNameButton === "modalCustomEdit_modal_division_btnTwo_disable" ? null : () => otpCheck()}
                  content={buttonContent}
                  customClass="customClass"
                />
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <>
          <Modal
            modalCustomClass="modalCustomEdit"
            onCloseHandler={CartShow}
            isMobileFullLayout
          >
            <div className="modalCustomEdit_modal">
              <h1 className="modalCustomEdit_modal_nameLabel">Edit Profile</h1>
              <div className="modalCustomEdit_modal_imageLayout">
                {/* {imgProfile !== "" && ( */}
                <img
                  className="modalCustomEdit_modal_imageLayout_profile"
                  src={imgProfile !== "" ? imgProfile : LocalStorage.get('profileImg')}
                  alt="Profile Image"
                />
                {/* )} */}
                <input
                  modalCustomEdit_modal_imageLayout_img
                  hidden
                  onChange={(e) => imgUpload(e)}
                  type="file"
                  id="img"
                  name="img"
                  accept=".png, .jpg, .jpeg"
                />
                <label for="img">
                  <img
                    src={camera}
                    id="output"
                    className="modalCustomEdit_modal_imageLayout_img"
                    alt="camera"
                  />
                </label>
              </div>

              <p className="modalCustomEdit_modal_subLabel">Name</p>
              <Input
                type="text"
                onChange={(id, value) => {
                  setName(value);
                }}
                value={name}
                customClass="modalCustomEdit_modal_input"
                placeholder="E.g. Jhon, Joe"
              />
              <h3 className="modalCustomEdit_modal_subLabel">Email ID</h3>
              <Input
                type="text"
                onChange={(id, value) => {
                  setEmail(value);
                }}
                value={email}
                customClass="modalCustomEdit_modal_input"
                placeholder="E.g. Jhon, Joe"
              />
              {/* <p className="modalCustomEdit_modal_subLabel">Date of Birth</p> */}
              {/* <DateTimePicker
                  onChange={setDate}
                  value={new Date(date)}
                  disabled={isDOBchange === false ? true : false}
                  className="modalCustomEdit_modal_input2"
                  format="yyyy-MM-dd"
                  clearIcon={isDOBchange === false ? true : false}
                  maxDate={new Date()}
                  placeholderText="Enter here"
                  onKeyDown={(e) => e.preventDefault()}
                /> */}

              {/* <input
                type="date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                value={date ? date : new Date()}
                disabled={isDOBchange === false ? true : false}
                max={moment().format("YYYY-MM-DD")} 
                className={`modalCustomEdit_modal_input2 ${!isDOBchange ? 'modalCustomEdit_modal_disable':''}`}
                onKeyDown={(e) => {
                  e.preventDefault();
               }}
               /> */}


              {/* <p className="modalCustomEdit_modal_smallLabel">
              You can change the Date of Birth only one time in your profile.
              </p> */}
              <br />
              <br />
              <br />
              <div className="modalCustomEdit_modal_division">
                <Button
                  className="modalCustomEdit_modal_division_btnOne"
                  onClick={() => CartShow()}
                  content="Cancel"
                />
                <div className="modalCustomEdit_modal_division_gap" />
                <Button
                  className={classNameButton}
                  onClick={classNameButton === "modalCustomEdit_modal_division_btnTwo_disable" ? null : () => otpCheck()}
                  content={buttonContent}
                  id="checkButtonIn"
                />
              </div>
              <p className="modalCustomEdit_modal_error">{disableStat}</p>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
export default EditProfile;
