import React from "react";
import remove from "../../assets/images/close.svg";
import "./index.scss";
import ShareButtons from "../social-share";
export default function SharePopup(props) {
  const { referalCode, onClose ,refersdata} = props;
  const { innerWidth } = window;
  console.log("came 1st",refersdata)
  return (
    <>
      <div className="share-overlay" onClick={innerWidth < 1023 ? onClose : null}>
      {innerWidth >= 1023 &&
          <div className="back_icon">
            <img
              src={remove}
              className="removeImg"
              onClick={onClose}
              alt=""
            />
          </div>
        }
        <div className="share-popup">
          
          <p className="share_heading">Share this referral code:</p>
          
          <div className="share-popup-div">
            <ShareButtons referalCode={referalCode} refersdata={refersdata} />
          </div>
        </div>
      </div>
    </>
  );
}
