import React from "react";
import "./index.scss";
import logo from "../../assets/images/LOGO (2).png";
import party from "../../assets/images/party-popper_1f389.png";
import ThanksHeader from "../thanks-header";
import ThanksContent from "../thanks-content";
import ThanksTac from "../thanks-tac";
import remove from "../../assets/images/close.svg";
const ThanksPage = ({ termsAndConditions, paymentData, type, tc, onClose }) => {
  console.log(tc, "terms and conditions");
  const handleClose = () => {
    onClose();
    window.location.reload(false);
  };
  return (
    <div className="modal-overlay">
      <div className="remove_thanks_icon" onClick={handleClose}>
        <img
          src={remove}
          className="deliveryContainer_right_removeImg"
          alt=""
        />
      </div>
      <div className="modal-content" id="thanksPageBuy">
        <div className="thanksPageBuy">
          <ThanksHeader paymentData={paymentData} type={type} />
          <ThanksContent type={type} />
          <ThanksTac
            termsAndConditions={termsAndConditions}
            type={type}
            tc={tc}
          />
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
