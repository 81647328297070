import React, { useEffect, useState } from "react";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Button from "../../components/button";
import { LocalStorage } from "../../utils/storage";

const ItemDetailFooter = (props) => {
  const {
    data,
    onCartClick,
    LoginCLick,
    deliveryClick,
    handlerFunction,
    bottomShow,
    bottomshow,
  } = props;
  const { innerWidth } = window;
  const history = useHistory();

  let temp = 0;
  const productData = LocalStorage.get("CartProducts");
  const previousLocationData = LocalStorage.get("Previouslocation");

  productData.map((items) => {
    temp = temp + items.count;
  });

  useEffect(() => {
    // debugger;
    if (temp === 0 || data.count === 0) {
      bottomshow && handlerFunction();
    }
  }, [data]);

  const handleLoginClick = () => {
    history.push("/login");
  };

  const handleDeliveryClick = () => {
    history.push("/delivery-location");
  };

  // console.log("footer", data);
  return (
    <div className="itemDetail">
      {innerWidth > 1023 ? (
        <div className="itemDetail_cartDetail">
          <p className="itemDetail_cartDetail_itemNumber">
            {temp && temp > 0 && temp}{" "}
            {data.count > 0 && data.count > 1 ? "items" : "item"}{" "}
          </p>
          <div className="itemDetail_cartDetail_itemData">
            <div className="itemDetail_cartDetail_itemData_priceDiv">
              <p className="itemDetail_cartDetail_itemData_priceDiv_tax">
                {temp > 0 ? "₹" + Math.floor(data.price) : ""}
                {/* ₹{data.price} + tax */}
              </p>
              <p className="itemDetail_cartDetail_itemData_priceDiv_taxComment">
                {temp > 0
                  ? "Excluding GST and Delivery Charges."
                  : "No items in the cart! Add an item to view cart."}
              </p>
            </div>
            <Button
              content={
                (
                  previousLocationData
                    ? previousLocationData.address === ""
                    : LocalStorage.get("location").address === ""
                )
                  ? "Select Location"
                  : LocalStorage.get("isLoggedIn")
                  ? "View Cart"
                  : "Login to continue"
              }
              className={`itemDetail_cartDetail_itemData_priceDiv_btn ${
                temp === 0 && "itemDetail_cartDetail_itemData_priceDiv_btndisab"
              }`}
              // "itemDetail_cartDetail_itemData_priceDiv_btn"
              onClick={() =>
                temp > 0 &&
                (previousLocationData
                  ? previousLocationData.address === ""
                  : LocalStorage.get("location").address === "")
                  ? deliveryClick()
                  : LocalStorage.get("isLoggedIn")
                  ? onCartClick()
                  : LoginCLick()
              }
            />
          </div>
        </div>
      ) : (
        <div className="itemDetail_wholeWrapper_cartDetails">
          <div className="itemDetail_wholeWrapper_cartDetail">
            <div className="itemDetail_wholeWrapper_cartDetail_item">
              <p className="itemDetail_wholeWrapper_cartDetail_itemNumber">
              {temp && temp > 0 && temp}{" "}
                {/* {temp && temp} */}
                {data.count > 0 && data.count > 1 ? " items" : " item"} | ₹
                {Math.floor(data.price)}
              </p>
              <h1 className="itemDetail_wholeWrapper_cartDetail_itemData_priceDiv_taxComment">
                Excluding GST and charges.
              </h1>
            </div>
            <Button
              content={
                (
                  previousLocationData
                    ? previousLocationData.address === ""
                    : LocalStorage.get("location").address === ""
                )
                  ? "Select Location"
                  : LocalStorage.get("isLoggedIn")
                  ? "View Cart"
                  : "Login to continue"
              }
              className="itemDetail_wholeWrapper_cartDetail_itemData_priceDiv_btn"
              customClass="itemDetail_wholeWrapper_cartDetail_itemData_priceDiv_btn_content"
              onClick={() =>
                (
                  previousLocationData
                    ? previousLocationData.address === ""
                    : LocalStorage.get("location").address === ""
                )
                  ? handleDeliveryClick()
                  : LocalStorage.get("isLoggedIn")
                  ? onCartClick()
                  : handleLoginClick()
              }
            />
            {/* <div className="itemDetail_wholeWrapper_cartDetail_itemData">
              <p className="itemDetail_wholeWrapper_cartDetail_itemData_cart">View cart</p>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};
export default ItemDetailFooter;
