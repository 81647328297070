import React, { useEffect, useState } from 'react';
import SuccessCard from '../../components/success-card';
import { ROUTES } from '../../constants/app-constants';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { SessionStorage } from '../../utils/storage';
import { productPurchaseNetCoreEvent} from '../../helpers/netcore';
import { Analytics } from '../../helpers/analytics';

function OrderStatus() {
  const [show, setShow] = useState(true);
  const history = useHistory();


  useEffect(() => {
    if (SessionStorage.get('gift')) {
      setShow(false);
    }
  }, []);

  useEffect(()=>{
      if(history?.location?.state?.isFromCheckOut){
        // productPurchaseNetCoreEvent(history?.location?.state?.payload)
        Analytics.purchaseEvent(history?.location?.state?.analyticsPayload)
        window.history.replaceState({}, '')
      }
  },[])

  const onCLoseClick = () => {
    if (SessionStorage.get('gift')) {
      SessionStorage.remove('gift');
      history.push(ROUTES.HOME);
    } else {
      history.push(ROUTES.TRACK_ORDER);
    }
  };

  const onExploreFood = () => {
    history.push(ROUTES.HOME);
    if (SessionStorage.get('gift')) {
      SessionStorage.remove('gift');
    }
  };

  return (
    <div className='status'>
      <SuccessCard
        onCLoseClick={onCLoseClick}
        onExploreFood={onExploreFood}
        showTrack={show}
      />
    </div>
  );
}

export default OrderStatus;
