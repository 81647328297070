import React, { useState, useEffect } from "react";
import Modal from "../modal";
import ProductDetail from "../../containers/productDetail";
import AddOnCheck from "../../containers/addOnCheck";
import Button from "../button";
import "./index.scss";
import NewCountButton from "../newCount-Button";
import { LocalStorage } from "../../utils/storage";
import { Analytics } from "../../helpers/analytics";
import goback from "../../assets/images/goback.svg";
import Loader from "../Loader";
import {
  handleGetTotalSingleProduct,
  handleGroupMinimum,
} from "../../helpers/sharedFunction";
import { ROUTES } from "../../constants/app-constants";

import { getAddon } from "../../actions/home";
import { addToCartNetCoreEvent } from "../../helpers/netcore";
import Toaster from "../toaster";
import RadioButton from "../../components/radioButton";
import { useHistory } from "react-router-dom";
const AddToCart = (props) => {
  const {
    CartShow,
    Content,
    ItemList,
    IsChecked,
    OnItemCheck,
    Extras,
    OnDataChecked,
    AddSubCount,
    AddCount,
    SubCount,
    Id,
    CartArr,
    customSubIconClass,
    customAddIconClass,
    item,
    netcoreEvent,
    isAddons,
    closeModal,
    productModal,
    addNewCartBtnLoader,
    setAddNewBtnLoader,
    onChangeProductData,
    pageData,
    itemDataChange,
  } = props;
  const { innerWidth } = window;
  const [popUp, setPopUp] = useState(true);
  const [isVideo, setIsVideo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  const [sized_item, setSizedItem] = useState([]);
  const [varientId, setVarientId] = useState("");
  const history = useHistory();

  const handleVideo = (data) => {
    setIsVideo(!isVideo);
  };
  const handleClose = (itm) => {
    // debugger;
    console.log("++", itm);
    const { onUpdateData } = props;

    const isHandleGrpMin = handleGroupMinimum(itm);

    if (isHandleGrpMin?.groupMinimum) {
      setToaster({
        toastText: `Please select an option from - ${isHandleGrpMin?.item?.group_name}`,
        isShow: true,
      });
      return;
    }

    setAddNewBtnLoader(true);

    itm.count = itemCount;
    if (varientId.length != 0) {
      itm.varientId = varientId;
    }
    if (itm.duplicate) {
      delete itm["sized_item"];
      delete itm["duplicate"];
    }
    // addToCartNetCoreEvent(itm);
    Analytics.addToCardEvent(itm, false, Extras);

    setPopUp(false);
    onUpdateData(itm);
    if(innerWidth <= 1023){
      // debugger;
      setTimeout(() => {
        if(window.location.pathname !== ROUTES.HOME){
          history.goBack()
        }
      }, 1000);
    }
    else{
      if(!window.location.pathname.includes("/item/") && !window.location.pathname.includes("/checkout") && !window.location.pathname.includes("/category/")){
        history.push(ROUTES.HOME);
      }
    }
  };

  const [count, setCount] = useState(item.count);
  const [itemData, setItemData] = useState(item);
  const [itemCount, setItemCount] = useState(1);
  const [isLoader, setIsLoader] = useState(false);
  const [extrasData, setExtrasData] = useState([]);
  const [extraGroup, setExtraGroup] = useState(Extras);
  const [isNewAddon, setIsNewAddon] = useState(false);
  console.log("addtocartpagedata", itemData);

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const addCountData = (itm, value) => {
    const updateItem = itm;
    itemCount !== 1 && setShowLoader(true);
    if (value === "add") {
      setCount(itm.count + 1);
      setItemCount((prevState) => prevState + 1);
    } else {
      if (itemCount > 1) {
        setCount(itm.count - 1);
        setItemCount((prevState) => prevState - 1);
      } else {
        setCount(1);
        setItemCount(1);
      }
    }
    setItemData(updateItem);
  };

  const onAdd = (item) => {
    handleClose(item);
    // debugger;
    // window.location.reload()
    
  };

  useEffect(() => {
    setShowLoader(false);
    let dataValue = [];
    let nonData = [];
    Content.sized_item &&
      Content.sized_item.length > 0 &&
      Content.sized_item.forEach((items) => {
        items.checked = false;
        if (item.item_id === items.item_id) {
          items.checked = true;
          dataValue.push(items);
        } else {
          nonData.push(items);
        }
      });
    dataValue = dataValue.concat(nonData);

    setSizedItem(dataValue);
  }, [itemCount]);

  const handleBack = () => setIsVideo(false);

  const handleTotalAmount = () => {
    const amt = isNewAddon
      ? handleGetTotalSingleProduct(extrasData, itemCount, extrasData?.groups)
      : handleGetTotalSingleProduct(item, itemCount, Extras);
    console.log("amt-->", amt);
    return amt;
  };

  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };

  const onChangeVarient = (itemData, sizedItem) => {
    const newData =
      sized_item &&
      sized_item.length > 0 &&
      sized_item.map((items) => {
        items.checked = false;
        if (items.item_id === itemData.item_id) {
          items.checked = true;
          setVarientId(items.itemid || items.item_id);
        } else {
          items.checked = false;
        }
        return items;
      });
    setSizedItem(newData);
    itemDataChange(itemData.item_id, sizedItem);
    const outletId = LocalStorage.get("outlet_id");
    const payload = {
      outlet_id: outletId,
      item_id: itemData.item_id,
    };
    getAddon(payload, (res) => {
      if (res && res?.response?.status === 200) {
        console.log("sucessss", res);
        console.log("gooooo", res.content.grouped_items.items);
        let extras = res.content.grouped_items.items;
        if (extras.groups && extras.groups.length > 0) {
          extras.groups = extras.groups.map((item) => {
            if (item.items && item.items.length > 0) {
              item.items.map((items) => {
                items.checked = false;
                return items;
              });
            }
            return item;
          });
        }
        console.log(extras, "extraaaaaaaaaaa");

        setIsNewAddon(true);
        setExtrasData(extras);
        setExtraGroup(extras.groups);
      }
    });
  };
  return (
    <>
      {productModal && (
        <Modal
        modalCustomClass={((Content.sized_item && Content.sized_item.length > 0) || (extraGroup &&
          extraGroup.length > 0)) ? "modalCustom" : "modalEmptyCustom"}
          onCloseHandler={() => {
            closeModal();
          }}
          isMobileFullLayout
          contentCustomClass={innerWidth <= 1023 && "addtoCart_popup"}
        >
          <div className="modalCustom_modal">
            <div className={((Content.sized_item && Content.sized_item.length > 0) || (extraGroup &&
          extraGroup.length > 0))? "modalCustom_modal_one" : "modalEmptyCustom_modal_one"}>
              <ProductDetail
                handleVideo={handleVideo}
                isVideo
                setIsVideo
                data={Content}
                onBackClick={CartShow}
              />
            </div>
            {isVideo && (
              <Modal
                modalCustomClass="modalCustom"
                onCloseHandler={handleVideo}
                isMobileFullLayout
                contentCustomClass={innerWidth <= 1023 && "addtoCart_popup"}
                contentDataCustomClass={
                  innerWidth <= 1023 && "addToCart_content"
                }
              >
                {Content?.video_link && Content?.video_link ? (
                  Content?.video_link
                ) : (
                  <>
                    {innerWidth <= 1023 ? (
                      <div className="addToCart">
                        <div className="addToCart_div">
                          <img
                            src={goback}
                            className="addToCart_div_backImg"
                            onClick={() => handleBack()}
                            alt="goback"
                          />
                          <h4 className="addToCart_div_phrase">Back</h4>
                        </div>
                        <iframe
                          className="addToCart_iframe"
                          width="100%"
                          height="440"
                          src="https://www.youtube.com/embed/H6nE1Nla3u0"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    ) : (
                      <iframe
                        width="100%"
                        height="440"
                        src="https://www.youtube.com/embed/H6nE1Nla3u0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    )}
                  </>
                )}
              </Modal>
            )}
           {/* {((Content.sized_item && Content.sized_item.length > 0) || (extraGroup &&
            extraGroup.length > 0)) && <div className="modalCustom_modal_divider" />} */}
           { ((Content.sized_item && Content.sized_item.length > 0) || (extraGroup &&
            extraGroup.length > 0))&&
            <div>
            <div className="modalCustom_modal_two">
              {Content.sized_item && Content.sized_item.length > 0 && (
                <div>
                  <h4 className="varianthead">
                    Choose your variant (Select any 1)
                  </h4>
                  <div key={item.item_id}>
                    <RadioButton
                      data={sized_item}
                      onChange={(item) => onChangeVarient(item, sized_item)}
                      className="radioVarient"
                      buttondata="radioVarientData"
                      label="radioLabel"
                      checkbox="radioCheckbox"
                      custom="radioCustom radioCustomVariant"
                      customClassName="radioAddCustomClass"
                      isShowHeader={true}
                      cartRadio
                    />
                  </div>
                </div>
              )}
              {console.log("extras praveena", extraGroup)}
              {extraGroup &&
                extraGroup.length > 0 &&
                extraGroup.map((item) => (
                  <AddOnCheck
                    key={item.group_id}
                    data={item.items}
                    className="modalCustom_modal_two_addItemCheck"
                    headLabel={item.group_name ? item.group_name : "Extras"}
                    onClickCheck={(id, value) =>
                      OnDataChecked(id, value, extraGroup)
                    }
                    groupId={item.group_id}
                  />
                ))}
              {/* {Extras &&
                Extras.length > 0 &&
                Extras.map((item) => {
                  
                  return (
                    <AddOnCheck
                      data={item.items}
                      className="modalCustom_modal_two_addItemCheck"
                      headLabel={item.group_name ? item.group_name : "Extras"}
                      // isChecked={isChecked}
                      onClickCheck={OnDataChecked}
                      groupId={item.group_id}
                    />
                  );
                })} */}
            </div>
            </div>
}
            {innerWidth <= 1023 && (
              <div className="modalCustom_modal_two_footerButton">
                {isLoader ? (
                  <Loader />
                ) : (
                  <div className="itemCountBtn">
                    <NewCountButton
                      content={itemCount} // 1
                      className="addsubBtn"
                      addCount={AddCount}
                      subCount={addCountData}
                      itemid={Id}
                      item={itemData}
                      customAddIconClass="itemCountBtn_add"
                      customSubIconClass="itemCountBtn_sub"
                      customClass="itemCountBtn_customClass"
                      isLoading={addNewCartBtnLoader}
                    />
                  </div>
                )}
                <div className="itemCountBtn2">
                  <Button
                    className="addButton"
                    content={"Add item ₹" + handleTotalAmount()}
                    onClick={() => handleClose(itemData)}
                    customClass="customClass"
                  />
                </div>
              </div>
            )}
          </div>
          {innerWidth >= 1024 && (
            <div className="modalCustom_modal_two_footerButton">
              {isLoader ? (
                <Loader />
              ) : (
                <div className="itemCountBtn">
                  <NewCountButton
                    content={itemCount}
                    className="addsubBtn"
                    addCount={AddCount}
                    subCount={addCountData}
                    itemid={Id}
                    item={itemData}
                    customAddIconClass={customAddIconClass}
                    customSubIconClass={customSubIconClass}
                    isLoading={showLoader}
                    isLoading={addNewCartBtnLoader}
                  />
                </div>
              )}

              <div className="">
                <Button
                  className="addButton"
                  content={
                    "Add item ₹" +
                    // itemData.count *
                    handleTotalAmount()
                  }
                  onClick={() => onAdd(itemData)}
                />
              </div>
            </div>
          )}
        </Modal>
      )}
      {pageData && (
        <>
          <div className="modalCustom_modal">
            <div className="modalCustom_modal_one">
              <ProductDetail
                handleVideo={handleVideo}
                isVideo
                setIsVideo
                data={Content}
                onBackClick={CartShow}
              />
            </div>
            {isVideo && (
              <Modal
                modalCustomClass="modalCustom"
                onCloseHandler={handleVideo}
                isMobileFullLayout
                contentCustomClass={innerWidth <= 1023 && "addtoCart_popup"}
                contentDataCustomClass={
                  innerWidth <= 1023 && "addToCart_content"
                }
              >
                {Content?.video_link && Content?.video_link ? (
                  Content?.video_link
                ) : (
                  <>
                    {innerWidth <= 1023 ? (
                      <div className="addToCart">
                        <div className="addToCart_div">
                          <img
                            src={goback}
                            className="addToCart_div_backImg"
                            onClick={() => handleBack()}
                            alt="goback"
                          />
                          <h4 className="addToCart_div_phrase">Back</h4>
                        </div>
                        <iframe
                          className="addToCart_iframe"
                          width="100%"
                          height="440"
                          src="https://www.youtube.com/embed/H6nE1Nla3u0"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    ) : (
                      <iframe
                        width="100%"
                        height="440"
                        src="https://www.youtube.com/embed/H6nE1Nla3u0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    )}
                  </>
                )}
              </Modal>
            )}
            {/* <div className="modalCustom_modal_divider" /> */}
            <div className="modalCustom_modal_two">
              {Content.sized_item && Content.sized_item.length > 0 && (
                <div>
                  <h4 className="varianthead">
                    Choose your variant (Select any 1)
                  </h4>
                  <div key={item.item_id}>
                    <RadioButton
                      data={sized_item}
                      onChange={(item) => onChangeVarient(item, sized_item)}
                      // onChange={onChangeVarient}
                      className="radioVarient"
                      buttondata="radioVarientData"
                      label="radioLabel"
                      checkbox="radioCheckbox"
                      custom="radioCustom radioCustomVariant"
                      customClassName="radioAddCustomClass"
                      isShowHeader={true}
                      cartRadio
                    />
                  </div>
                </div>
              )}
              {/* {extrasData && extrasData.length > 0
                ? extrasData.map((item) => (
                    <AddOnCheck
                      key={item.group_id}
                      data={item.items}
                      className="modalCustom_modal_two_addItemCheck"
                      headLabel={item.group_name ? item.group_name : "Extras"}
                      onClickCheck={OnDataChecked}
                      groupId={item.group_id}
                    />
                  ))
                : Extras &&
                  Extras.length > 0 &&
                  Extras.map((item) => (
                    <AddOnCheck
                      key={item.group_id}
                      data={item.items}
                      className="modalCustom_modal_two_addItemCheck"
                      headLabel={item.group_name ? item.group_name : "Extras"}
                      onClickCheck={OnDataChecked}
                      groupId={item.group_id}
                    />
                  ))} */}
              {Extras &&
                Extras.length > 0 &&
                Extras.map((item) => {
                  return (
                    <AddOnCheck
                      data={item.items}
                      className="modalCustom_modal_two_addItemCheck"
                      headLabel={item.group_name ? item.group_name : "Extras"}
                      //  isChecked={isChecked}
                      onClickCheck={OnDataChecked}
                      groupId={item.group_id}
                    />
                  );
                })}
            </div>
            {innerWidth <= 1023 && (
              <div className="modalCustom_modal_two_footerButton">
                {isLoader ? (
                  <Loader />
                ) : (
                  <div className="itemCountBtn">
                    <NewCountButton
                      content={itemCount} // 1
                      className="addsubBtn"
                      addCount={AddCount}
                      subCount={addCountData}
                      itemid={Id}
                      item={itemData}
                      customAddIconClass="itemCountBtn_add"
                      customSubIconClass="itemCountBtn_sub"
                      customClass="itemCountBtn_customClass"
                      isLoading={addNewCartBtnLoader}
                    />
                  </div>
                )}
                <div className="itemCountBtn2">
                  <Button
                    className="addButton"
                    content={"Add item ₹" + handleTotalAmount()}
                    onClick={() => handleClose(itemData)}
                    customClass="customClass"
                  />
                </div>
              </div>
            )}
          </div>
          {innerWidth >= 1024 && (
            <div className="modalCustom_modal_two_footerButton">
              {isLoader ? (
                <Loader />
              ) : (
                <div className="itemCountBtn">
                  <NewCountButton
                    content={itemCount}
                    className="addsubBtn"
                    addCount={AddCount}
                    subCount={addCountData}
                    itemid={Id}
                    item={itemData}
                    customAddIconClass={customAddIconClass}
                    customSubIconClass={customSubIconClass}
                    isLoading={showLoader}
                    isLoading={addNewCartBtnLoader}
                  />
                </div>
              )}

              <div className="">
                <Button
                  className="addButton"
                  content={
                    "Add item ₹" +
                    // itemData.count *
                    handleTotalAmount()
                  }
                  onClick={() => onAdd(itemData)}
                />
              </div>
            </div>
          )}
        </>
      )}

      {toaster.isShow && (
        <Toaster
          timeout={5000}
          closeToast={closeToast}
          toastText={toaster.toastText}
        />
      )}
    </>
  );
};

export default AddToCart;
