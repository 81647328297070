import React, { useEffect, useState } from "react";
import "./index.scss";
import Button from "../../components/button";
import back from "../../assets/images/path.svg";
import close from "../../assets/images/goback.svg";
import OtpInput from "react-otp-input";
import timer from "../../assets/images/timer.svg";
import Timer from "../../components/timer";

const OtpVerification = (props) => {
  const {
    onNav,
    getOtpData,
    getResendData,
    errorTxt,
    mobile,
    successTxt,
    displayMobile,
    autoReadOtpValue
  } = props;
  const [otp, setOtp] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [newErrorTxt, setNewErrorTxt] = useState(errorTxt);
  const { innerWidth } = window;

  const validate = () => {
    if (otp.length < 6) {
    } else {
      // onNav('');
      getOtpData(otp);
    }
  };

  const resend = () => {
    getResendData(otp);
  };
  const onShow = (value) => {
    setShowResend(value);
  };

  return (
    <div className="otp">
      <div className="otp_heading">
        {innerWidth <= 1023 ? (
          <>
            <img
              src={close}
              onClick={() => onNav("stage1")}
              alt="back"
              className="otp_heading_img"
            />
          </>
        ) : (
          <>
            <img
              src={back}
              onClick={() => onNav("stage1")}
              alt="back"
              className="otp_heading_img"
            />
          </>
        )}
        <h2 className="otp_heading_label">
          {innerWidth <= 1023 ? "Verify OTP" : "OTP Verification"}
        </h2>
      </div>

      {innerWidth <= 1023 ? (
        <div className="otp_wholeWrapper">
          <div className="otp_wholeWrapper_verification">
            <h2 className="otp_wholeWrapper_verification_label">
              Enter the 6-digit code sent to you at
            </h2>
            <p className="otp_wholeWrapper_verification_mobile">
              +91 {displayMobile}
            </p>

            <OtpInput
              value={autoReadOtpValue ? autoReadOtpValue : otp}
              // onChange={handleChange()}
              onChange={(otp) => setOtp(otp)}
              numInputs={6}
              containerStyle="otp_wholeWrapper_verification_otpContainer"
              inputStyle="otp_wholeWrapper_verification_otpInput"
              // isInputSecure={true}
              // type={'number'}
              isInputNum={true}
              shouldAutoFocus
              onKeyPress={(e) => e.key === "Enter" && validate()}
              separator={<span> </span>}
            />
            <p className="otp_wholeWrapper_verification_errorMsg"></p>
            <p className="otp_wholeWrapper_verification_errStat">{errorTxt}</p>
            <p className="otp_wholeWrapper_verification_successStat">
              {successTxt}
            </p>
            <div className="otp_wholeWrapper_verification_resend">
              <h6 className="otp_wholeWrapper_verification_resend_label1">
                I didn't receive a code?
              </h6>

              {showResend ? (
                <p
                  onClick={() => resend()}
                  className="otp_wholeWrapper_verification_resend_label2"
                >
                  Resend OTP
                </p>
              ) : (
                <span className="otp_wholeWrapper_verification_resend_label3">
                  <div className="otp_wholeDiv">
                    <img src={timer} className="otp_wholeDiv_timer" alt="timer"/>
                    <Timer
                      seconds={30}
                      onShow={onShow}
                      login={true}
                      customClass="login_timer"
                    />
                  </div>
                </span>
              )}
            </div>
          </div>
          <div className="otp_wholeWrapper_verification_button">
            <Button
              className={`${
                otp.length < 6
                  ? "otp_wholeWrapper_verification_verifyBtnDisabled"
                  : "otp_wholeWrapper_verification_verifyBtn"
              }`}
              content={"Verify and Proceed"}
              disabled={otp.length < 6 && true}
              onClick={() => validate()}
              customClass={"customclass"}
            />
          </div>
        </div>
      ) : (
        <div className="otp_verification">
          <h2 className="otp_verification_label">
            Please enter the 6-digit verification code sent to
          </h2>
          <p className="otp_verification_mobile">+91 {displayMobile}</p>

          <OtpInput
            value={autoReadOtpValue ? autoReadOtpValue :otp}
            // onChange={handleChange()}
            onChange={(otp) => setOtp(otp)}
            numInputs={6}
            containerStyle="otp_verification_otpContainer"
            inputStyle="otp_verification_otpInput"
            isInputSecure
            isInputNum={true}
            shouldAutoFocus
            onKeyPress={(e) => e.key === "Enter" && validate()}
            separator={<span> </span>}
            type="number"
          />
          <p className="otp_verification_errorMsg"></p>
          <div className="otp_verification_resend">
            <h2 className="otp_verification_resend_label1">
            Did not receive the OTP?
            </h2>

            {showResend ? (
              <p
                onClick={() => resend()}
                className="otp_verification_resend_label2"
              >
                Resend OTP
              </p>
            ) : ( 
              <>
             <span className='otp_verification_resend_label1'> Wait for</span> <span className="otp_verification_resend_label3">
               <Timer seconds={30} onShow={onShow} login={true} />
              </span>
              </>
            )}
          </div>
          <Button
            className={`${
              otp.length < 6
                ? "otp_verification_verifyBtnDisabled"
                : "otp_verification_verifyBtn"
            }`}
            content={"Verify and Proceed"}
            disabled={otp.length < 6 && true}
            onClick={() => validate()}
          />
          <p className="otp_verification_errStat">{errorTxt}</p>
          <p className="otp_verification_successStat">{successTxt}</p>
          {/*  */}
        </div>
      )}
    </div>
  );
};

export default OtpVerification;
