import React, { useEffect, useState } from "react";
import "./index.scss";
import Profile from "../../assets/images/userIcon.svg";
import Mobile from "../../assets/images/group_28.svg";
import Edit from "../../assets/images/edit.svg";
import Birthday from "../../assets/images/profileBirthday.svg";
import Mail from "../../assets/images/profileMail.svg";
import Button from "../button";
import { Authentication, Logout } from "../../actions/authentication";
import { LocalStorage } from "../../utils/storage";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import goBack from "../../assets/images/goback.svg";
import order from "../../assets/images/myorder.svg";
import location from "../../assets/images/profilelocation.svg";
import notification from "../../assets/images/profileNotification.svg";
import catering from "../../assets/images/profileCatering.svg";
import loyalty from "../../assets/images/profileLoyalty.svg";
import AlertPopup from "../alert-popup";
import { getLoyalityData } from "../../actions/loyalty";
import { handleGetLoyaltyTypeImg } from "../../helpers/sharedFunction";
import { logOutIdentityBranch } from "../../utils/branch";
import { walletgcStatusCheckApi } from "../../actions/giftcards";
import { viewWalletBalance } from "../../actions/wallet";
import dinein from "../../assets/images/dining-room.png"
const ProfileDropdown = (props) => {
  const { data, staticRender, data1 } = props;
  const { CartShow } = props;
  const userData = LocalStorage.get("UserData");
  const emailId = LocalStorage.get("Email Id");
  const dob = LocalStorage.get("DOB");
  const { innerWidth } = window;
  const [logout, setLogout] = useState(false);
  const history = useHistory();
  const profileImage = LocalStorage.get("profileImg");
  const [alertPopup, setAlertPopup] = useState(false);
  const [loyaltyData, setLoyaltyData] = useState(null);
  const [walletgcStatus, setWalletgcStatus] = useState();
  const [showWalletBalance, setShowWalletBalance] = useState(false);
  const [walletStatus, setWalletStatus] = useState(null);
  const [walletBalance, setWalletBalance] = useState();

  const dynamic = [
    {
      category_name: "My Orders",
      image: order,
      key: "/my-orders",
    },
    {
      category_name: "Offers",
      image: order,
      key: "/offers",
    },
    {
      category_name: "Address",
      image: location,
      key: "/my-address",
    },
    // {
    //   category_name: "Notification",
    //   image: notification,
    //   key: "/notification",
    // },
    {
      category_name: "Feedback",
      key: "/feedback",
    },
    // {
    //   category_name: "Catering",
    //   key: "/store-locators",
    //   category_name: "Catering",
    //   image: catering,
    //   key: "/catering",
    // },
    {
      category_name: "Dine In",
      image: dinein,
      key: "/store-locators",
    },
    walletgcStatus && walletgcStatus.is_refer_and_earn_active
    ?{
      category_name: "Refer & Earn",
      key: "/refer-and-earn",
    }:"",
    walletgcStatus && walletgcStatus.is_wallet_active
      ? {
          category_name: "BBK Money",
          image: order,
          key: "/wallet",
          showWalletBalance: true,
        }
      : "",

    walletgcStatus && walletgcStatus.is_gc_home_active
      ? {
          category_name: "Gift Card",
          image: order,
          key: "/giftcard",
          isShowGiftcard: false,
        }
      : "",
    walletgcStatus && walletgcStatus.is_gc_claim_active
    ?{
      category_name: "Gift Card Claim",
      image: order,
      key: "/giftcard",
      isShowGiftcard : false
    }:"",
   
    {
      category_name: "Loyalty",
      image: loyalty,
      key: "/loyalty-page",
      isShowLoyltyPoints: true,
    },
  ];
  const handleOnClick = () => {
    Logout((re) => {
      if (re.response.status === 200) {
        logOutIdentityBranch();
        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");
        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              // history.push(ROUTES.HOME);
              window.location.href = ROUTES.HOME;
            } else {
              window.location.href = ROUTES.HOME;
              // history.push(ROUTES.HOME);
              // window.location.reload()
            }
          }
        });
      }
    });
  };

  const handleClose = () => {
    //
    setLogout(false);
  };

  const onCloseAlert = () => {
    setLogout(false);
    setAlertPopup(false);
  };

  useEffect(() => {
    getLoyalityData((res) => {
      if (res && res?.response?.status === 200) {
        if (res && res.content && res.content.current_points) {
          setLoyaltyData(res.content.current_points);
        }
      }
    });
    walletgcStatusCheckApi((res) => {
      if (res && res?.response?.status === 200) {
        if (res && res.content) {
          console.log("Setting walletgcStatus:", res.content);
          setWalletgcStatus(res.content);
          setShowWalletBalance(res.content.is_wallet_active);
        }
      }
    });
    viewWalletBalance((res) => {
      if (res && res?.response?.status === 200) {
        setWalletStatus(res?.content?.status);
        setWalletBalance(res?.content?.balance);
      }
    });
  }, []);

  return (
    <>
      {alertPopup && (
        <AlertPopup
          title="Are you sure you want to logout"
          description={alertPopup}
          okayClick={() => onCloseAlert()}
        />
      )}

      <div className="profileDropdown">
        <div className="profileDropdown_profile">
          <img
            src={profileImage && profileImage !== "" ? profileImage : Profile}
            onClick={() => {
              history.push(ROUTES.PROFILE);
            }}
            className="profileDropdown_profile_img"
            alt=""
          />
          <div
            onClick={() => {
              history.push(ROUTES.PROFILE);
            }}
            className="profileDropdown_profile_data"
          >
            <p className="profileDropdown_profile_data_nameLabel">
              {userData.name}
            </p>
            <p className="profileDropdown_profile_data_subLabel">
              Profile Details
            </p>
          </div>
          <p
            onClick={() => {
              setLogout(true);
            }}
            className="profileDropdown_profile_logout"
          >
            Log Out
          </p>
        </div>

        <div className="profileDropdown_divider" />

        <div className="profileDropdown_list">
          <div className="profileDropdown_list_one">
            {dynamic &&
              dynamic.map((item) => {
                return (
                  <p
                    onClick={() => {
                      history.push(item.key);
                      if (item.category_name === "Logout") {
                        setLogout(true);
                        //   LocalStorage.set('isLoggedIn', false);
                        //   history.push(item.key);
                      }
                    }}
                    className="profileDropdown_list_one_nameLabel"
                  >
                    {item.category_name}
                    {loyaltyData && item.isShowLoyltyPoints && (
                      <>
                        {" "}
                        ({" "}
                        <span className="profileDropdown_profile_data_loyaltyPoints">{` ${loyaltyData.CurrentPoints} `}</span>
                        <img
                          className="profileDropdown_profile_data_loyaltyImg"
                          alt={loyaltyData.guest_label}
                          src={handleGetLoyaltyTypeImg(loyaltyData.guest_label)}
                        />{" "}
                        ){" "}
                      </>
                    )}

                     { showWalletBalance && item.showWalletBalance && (
                      <>
                        {"  "}({" "}
                        <span className="profileDropdown_profile_data_walletBalance">
                          {` ₹ ${walletBalance === 0 ? "0" : walletBalance} `}
                        </span>
                        )
                      </>
                    ) }
                  </p>
                );
              })}
          </div>

          <div className="profileDropdown_list_two">
            {data &&
              data?.result &&
              data?.result.length > 0 &&
              data?.result.map((item) => {
                return (
                  item?.filter_name === "useful_links" && (
                    <p
                      onClick={() => {
                        // history.push(item?.url_text);
                        // staticRender("/useful-links/" + item?.url_text);
                        staticRender("/" + item?.url_text);
                      }}
                      className="profileDropdown_list_two_nameLabel"
                    >
                      {item?.title}
                    </p>
                  )
                );
              })}
          </div>
        </div>

        {logout && (
          <AlertPopup
            title={`${innerWidth <= 1023 ? `Logout ?` : `Logout!!`}`}
            description={`${
              innerWidth <= 1023
                ? `Are you sure you want to logout?`
                : `Are you sure you want to logout?`
            }`}
            okayClick={() => handleOnClick()}
            close={() => handleClose()}
            logout={true}
          />
        )}
      </div>
    </>
  );
};
export default ProfileDropdown;
