import React, { useEffect, useState } from "react";
import "./index.scss";
import { getGiftCards, myVouchers } from "../../actions/giftcards";
import VoucherCard from "../../components/voucher-card";
import GiftVoucherModal from "../giftVoucherModal";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import Dexie from "dexie";
import RecieverPopup from "../../components/recieverPopup";
import FailureCard from "../../components/failure-card";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
import goback from "../../assets/images/goback.svg";
import ProfileCard from "../../components/profile-card";
import MenuSideBar from "../menuSideBar";
import { Authentication, Logout } from "../../actions/authentication";
import Header from "../header";
import HeaderCommon from "../../components/header-common-component";
import AlertPopup from "../../components/alert-popup";
import { logOutIdentityBranch } from "../../utils/branch";

const GiftVoucher = (props) => {
  const { Dom, profileorder, routeName, customClassRoute } = props;
  const history = useHistory();
  const [data, setData] = useState([]);
  const [popup, setPopup] = useState(false);
  const [items, setItems] = useState({});
  const [taxCharges, setTaxCharges] = useState([]);
  const [recieverPop, setRecieverPop] = useState(false);
  const [recieveData, setRecieveData] = useState([]);
  const [message, setMessage] = useState("");
  const [failurePay, setFailure] = useState(false);
  const [myVoucher, setMyVoucher] = useState([]);
  const [active1, setActive1] = useState();
  const [islog, setIsLog] = useState(false);

  useEffect(() => {
    const outlet = LocalStorage.get("outlet_id");
    const db = new Dexie(outlet);
    db.version(1).stores({
      menu: "++id, categories, item_tags, taxes",
    });
    // let itemId = localStorage.getItem('item_id');
    const getPosts = async () => {
      let allPosts = await db.menu.toArray();
      if (allPosts && allPosts.length > 0) {
        setTaxCharges(allPosts[0].taxes);
      }
      getPosts();
    };
    getGiftCards((res) => {
      if (res?.response?.status === 200) {
        const updateData = res.content.data || [];
        if (updateData.length > 0) {
          updateData.map((itm) => {
            itm.count = 1;
            return itm;
          });
        }
        setData(updateData);
      }
    });
    myVouchers((res) => {
      if (res?.response?.status === 200) {
        setMyVoucher(res.content.available_voucher);
      }
    });
  }, []);

  const onBuyNowData = (item) => {
    setPopup(true);
    if (item.count === 0) {
      item.count = 1;
    }
    setItems(item);
  };

  const onUpdateContent = (item) => {
    setItems(item);
    if (item.count === 0) {
      setPopup(false);
    }
  };

  const onClose = () => {
    SessionStorage.remove("sender_details");
    setPopup(false);
    setRecieverPop(false);
  };

  const onCloseReciever = () => {
    setRecieverPop(false);
    setPopup(true);
  };

  const onRecieveData = (item, msg) => {
    setRecieverPop(false);
    setRecieveData([item])
    setMessage(msg);
    setPopup(true);
  };

  const onDefaultRecieve = (item, msg) => {
    recieveData.push(item);
    setMessage(msg);
  };

  const onAddRecieve = () => {
    setRecieverPop(true);
    setPopup(false);
  };

  const onFailure = (val) => {
    setFailure(val);
  };

  const onCartClick = () => {
    setFailure(false);
  };

  const onHomeClick = () => {
    history.push(ROUTES.HOME);
  };

  const { innerWidth } = window;
  const handleGoBack = () => {
    history.goBack();
  };

  const data1 = [
    {
      category_name: "My Orders",
      key: "/my-orders",
    },
    {
      category_name: "Address",
      key: "/my-address",
    },
    {
      category_name: "Notification",
      key: "/notification",
    },
    // {
    //   category_name: "Feedback",
    //   key: "/feedback",
    // },
    {
      category_name: "Catering",
      key: "/catering",
    },
    {
      category_name: "Dine In",
      key: "/store-locators",
    },
    {
      category_name: "Gift Vouchers",
      key: "/gift-vouchers",
    },
    {
      category_name: "Refer & Earn",
      key: "/refer-and-earn",
    },
    {
      category_name: "Loyalty",
      key: "/loyalty-page",
    },
    {
      category_name: "Logout",
      key: "/product-list",
    },
  ];

  const getMenuData = () => {
    let dom = null;
    switch (active1) {
      case "My Orders":
        history.push("/my-orders");
        break;
      case "Feedback":
        history.push("/feedback");
        break;
      case "Gift Vouchers":
        history.push("/gift-vouchers");
        break;
        case "Notification":
          history.push(ROUTES.NOTIFICATION);
          break;
      case "Catering":
        history.push("/catering");
        break;
      case "Location Address":
        history.push("/my-address");
        break;
      case "Refer & Earn":
        history.push("/refer-and-earn");
        break;
      case "Dine In":
        history.push("/store-locators");
        break;
      case "Loyalty":
        history.push("/loyalty-page");
        break;
      default:
    }
    return dom;
  };

  const onClickCategory = (itm) => {
    setActive1(itm.category_name);
    if (itm.category_name === "Logout") {
      setIsLog(true);

      // Logout((re) => {
      //   if (re.response.status === 200) {
      //     LocalStorage.set("isLoggedIn", false);
      //     LocalStorage.set("UserData", {});
      //     LocalStorage.remove("CartProducts");
      //     Authentication((res) => {
      //       if (res.response.status === 200) {
      //         if (res.content) {
      //           LocalStorage.set("AccessToken", res.content.access_token);
      //           LocalStorage.set("RefreshToken", res.content.refresh_token);
      //           history.push(ROUTES.HOME);
      //         } else {
      //           history.push(ROUTES.HOME);
      //         }
      //       }
      //     });
      //   }
      // });
    }
    if (innerWidth <= 1023) {
      history.push(itm.key);
    }
  };

  const confirmLogout = () => {
    Logout((re) => {
      if (re.response.status === 200) {

        //branch event
        logOutIdentityBranch();

        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");
        
        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              history.push(ROUTES.HOME);
            } else {
              history.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  }

  return (
    <>
       {
      islog && 
      <AlertPopup
      title='Logout?'
      description='Are you sure you want logout?'
      okayClick={() => confirmLogout()}
      logout
      close={() => setIsLog(false)}
      />
    }
      {innerWidth <= 1023 ? (
        <>
          <div className="giftVoucher">
            <div className="giftVoucher_header">
              <img
                src={goback}
                className="giftVoucher_header_backImg"
                onClick={() => handleGoBack()}
                alt="goback"
              />
              <h3 className="giftVoucher_header_phrase">Gift Voucher</h3>
            </div>
            <h3 className="giftVoucher_heading">My Vouchers</h3>
            <div className="giftVoucher_data">
              {myVoucher &&
                myVoucher.length > 0 &&
                myVoucher.map((itm) => {
                  return <VoucherCard content={itm} />;
                })}
              {myVoucher && myVoucher.length === 0 && (
                <h2 className="giftVoucher_nocontent">No Vouchers Found</h2>
              )}
            </div>
            <h3 className="giftVoucher_otherVoucherHeading">Other Vouchers</h3>
            <div className="giftVoucher_data">
              {data &&
                data.length > 0 &&
                data.map((itm) => {
                  return (
                    <VoucherCard
                      content={itm}
                      list={true}
                      onBuyNowData={onBuyNowData}
                    />
                  );
                })}
              {data && data.length === 0 && (
                <p className="giftVoucher_nocontent">No Vouchers Found</p>
              )}
            </div>
            {popup && (
              <GiftVoucherModal
                data={items}
                onUpdateContent={onUpdateContent}
                tax={taxCharges}
                onAddRecieve={onAddRecieve}
                recieverData={recieveData}
                onClose={onClose}
                message={message}
                setFailurePay={onFailure}
              />
            )}
            {recieverPop && (
              <RecieverPopup
                data={items}
                onUpdateContent={onUpdateContent}
                tax={taxCharges}
                onRecieveData={onRecieveData}
                onClose={onCloseReciever}
              />
            )}
            {failurePay && (
              <FailureCard
                onCLoseClick={() => {
                  setFailure(false);
                }}
                onTryAgain={onCartClick}
                onExploreFood={onHomeClick}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {Dom && (
            <>
              <HeaderCommon />
              <div className="giftVoucher_wholeSection">
                <div className="giftVoucher_wholeSection_one">
                  <ProfileCard />
                </div>
                <div className="giftVoucher_wholeSection_two">
                  <div className="giftVoucher_subSectionLeft">
                    <MenuSideBar
                      data={data1}
                      active={active1}
                      onClickCategory={onClickCategory}
                      routeName={routeName}
                      customClassRoute={customClassRoute}
                    />
                    {getMenuData()}
                  </div>
                  <div className="giftVoucher_subSectionRight">
                    <h3 className="giftVoucher_heading">Vouchers</h3>
                    <div className="giftVoucher_data">
                      {data &&
                        data.length > 0 &&
                        data.map((itm) => {
                          return (
                            <VoucherCard
                              content={itm}
                              list={true}
                              onBuyNowData={onBuyNowData}
                            />
                          );
                        })}
                      {data && data.length === 0 && (
                        <h4 className="giftVoucher_nocontent">
                          No Vouchers Found
                        </h4>
                      )}
                    </div>
                    <h3 className="giftVoucher_heading">My Vouchers</h3>
                    <div className="giftVoucher_data">
                      {myVoucher &&
                        myVoucher.length > 0 &&
                        myVoucher.map((itm) => {
                          return <VoucherCard content={itm} />;
                        })}
                      {myVoucher && myVoucher.length === 0 && (
                        <p className="giftVoucher_nocontent">
                          No Vouchers Found
                        </p>
                      )}
                    </div>
                    {popup && (
                      <GiftVoucherModal
                        data={items}
                        onUpdateContent={onUpdateContent}
                        tax={taxCharges}
                        onAddRecieve={onAddRecieve}
                        recieverData={recieveData}
                        onClose={onClose}
                        message={message}
                        setFailurePay={onFailure}
                      />
                    )}
                    {recieverPop && (
                      <RecieverPopup
                        data={items}
                        onUpdateContent={onUpdateContent}
                        tax={taxCharges}
                        onRecieveData={onRecieveData}
                        onClose={onCloseReciever}
                      />
                    )}
                    {failurePay && (
                      <FailureCard
                        onCLoseClick={() => {
                          setFailure(false);
                        }}
                        onTryAgain={onCartClick}
                        onExploreFood={onHomeClick}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {profileorder && (
            <div className="giftVoucher">
              <h3 className="giftVoucher_heading">Vouchers</h3>
              <div className="giftVoucher_data">
                {data &&
                  data.length > 0 &&
                  data.map((itm) => {
                    return (
                      <VoucherCard
                        content={itm}
                        list={true}
                        onBuyNowData={onBuyNowData}
                      />
                    );
                  })}
                {data && data.length === 0 && (
                  <h1 className="giftVoucher_nocontent">No Vouchers Found</h1>
                )}
              </div>
              <h3 className="giftVoucher_heading">My Vouchers</h3>
              <div className="giftVoucher_data">
                {myVoucher &&
                  myVoucher.length > 0 &&
                  myVoucher.map((itm) => {
                    return <VoucherCard content={itm} />;
                  })}
                {myVoucher && myVoucher.length === 0 && (
                  <p className="giftVoucher_nocontent">No Vouchers Found</p>
                )}
              </div>
              {popup && (
                <GiftVoucherModal
                  data={items}
                  onUpdateContent={onUpdateContent}
                  tax={taxCharges}
                  onAddRecieve={onAddRecieve}
                  recieverData={recieveData}
                  onClose={onClose}
                  message={message}
                  setFailurePay={onFailure}
                />
              )}
              {recieverPop && (
                <RecieverPopup
                  data={items}
                  onUpdateContent={onUpdateContent}
                  tax={taxCharges}
                  onRecieveData={onRecieveData}
                  onClose={onCloseReciever}
                />
              )}
              {failurePay && (
                <FailureCard
                  onCLoseClick={() => {
                    setFailure(false);
                  }}
                  onTryAgain={onCartClick}
                  onExploreFood={onHomeClick}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GiftVoucher;
