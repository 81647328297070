import React from "react";
import "./index.scss";
import Location from "../../assets/images/location.svg";
import Timer from "../../assets/images/timer.svg";
import BBk from "../../assets/images/takeawayImg.png";
import Button from "../../components/button";

const StoreCard = (props) => {
  const { data, theme, onClickStore, store } = props;
  const { innerWidth } = window;
  return (
    <>
      {store ? (
        <div className="storecard">
          <div
            className="storecard_item"
            onClick={innerWidth <= 1023 && (() => onClickStore(data, true))}
          >
            <div className="storecard_item_data">
              <div className="storecard_img">
                {/* <img
                  src={BBk}
                  width="60"
                  height="62"
                  className="storecard_img_bbk"
                /> */}
              </div>
              <div className="storecard_data">
                <h2 className="storecard_heading">{data.display_name}</h2>
                <p className="storecard_desc">{data.city}</p>
                <div className="storecard_location">
                  <div className="storecard_location_detail">
                    <div className="storecard_location_data">
                      <img
                        alt="Location"
                        src={Location}
                        width="10"
                        height="10"
                        className="storecard_location_data_img"
                      />
                      <span className="storecard_location_value">
                        {parseFloat(data.user_distance_in_km).toFixed(2)} km
                      </span>
                    </div>
                    <div className="storecard_location_data storecard_location_footer">
                      <img
                        src={Timer}
                        width="10"
                        height="10"
                        className="storecard_footer_clockImg"
                        alt="Timer"
                      />
                      <span className="storecard_location_value">
                        {theme === "delivery"
                          ? Number(data.expected_delivery_time_in_mins)
                          : Number(data.takeaway_preparation_time)}
                        mins
                      </span>
                    </div>
                  </div>
                  {innerWidth >= 1023 && (
                    <div
                      className={`storecard_location_open1 ${data.is_open ? "opened1" : "closed1"
                        }`}
                    >
                      {data.is_open ? "Open Now" : "Currently Closed"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {innerWidth <= 1023 ? (
            <div className="storecard" onClick={() => onClickStore(data, true)}>
              <div
                className="storecard_item"
              // onClick={innerWidth <= 1023 && (() => onClickStore(data ,true))}
              >
                <div className="storecard_item_data">
                  <div className="storecard_img">
                    <img src={BBk} width="62" height="68" />
                  </div>
                  <div className="storecard_data">
                    <h2 className="storecard_heading">{data.display_name}</h2>
                    <p className="storecard_desc">{data.address}</p>
                    <div className="storecard_location">
                      <div className="storecard_location_detail">
                        <div className="storecard_location_data">
                          <img src={Location} width="10" height="10" alt="Location" />
                          <span className="storecard_location_value">
                            {parseFloat(data.user_distance_in_km).toFixed(2)} km away
                          </span>
                        </div>
                        {/* <div className="storecard_location_data">
                      <img
                        src={Timer}
                        width="10"
                        height="10"
                        className="storecard_location_clockimg"
                        alt="Timer"
                      />
                      <span className="storecard_location_value">
                        {theme === "delivery"
                          ? Number(data.expected_delivery_time_in_mins)
                          : Number(data.takeaway_preparation_time)}
                        mins
                      </span>
                    </div> */}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (<div className="storecard" onClick={() => onClickStore(data, true)}>
            <div
              className="storecard_item"
            // onClick={innerWidth <= 1023 && (() => onClickStore(data ,true))}
            >
              <div className="storecard_item_data">
                <div className="storecard_img">
                  {/* <img src={BBk} width="60" height="62" /> */}
                </div>
                <div className="storecard_data">
                  <h2 className="storecard_heading">{data.display_name}</h2>
                  <p className="storecard_desc">{data.city}</p>
                  <div className="storecard_location">
                    <div className="storecard_location_detail">
                      <div className="storecard_location_data">
                        <img src={Location} width="10" height="10" alt="Location" />
                        <span className="storecard_location_value">
                          {parseFloat(data.user_distance_in_km).toFixed(2)} km
                        </span>
                      </div>
                      <div className="storecard_location_data">
                        <img
                          src={Timer}
                          width="10"
                          height="10"
                          className="storecard_location_clockimg"
                          alt="Timer"
                        />
                        <span className="storecard_location_value">
                          {theme === "delivery"
                            ? Number(data.expected_delivery_time_in_mins)
                            : Number(data.takeaway_preparation_time)}
                          mins
                        </span>
                      </div>
                    </div>

                    <div
                      className={`storecard_location_open ${data.is_open ? "opened" : "closed"
                        }`}
                    >
                      {data.is_open ? "Open Now" : "Currently Closed"}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>)}
        </>
      )}
    </>
  );
};
export default StoreCard;
