import React from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import logo from "../../assets/images/logo.png";
import Button from "../button";
import { ROUTES } from "../../constants/app-constants";
import { LocalStorage } from "../../utils/storage";
const LastOrderCard = (props) => {
  const { data, wholeData, wholeClass } = props;
  const { innerWidth } = window;
  const history = useHistory();

  let finalPrice = 0;
  if (data?.length > 0) {
    //  
    for (let i = 0; i < data?.length; i++) {
      //  
      let newPrice = Number(finalPrice) + Number(data[i].final_price);
      finalPrice = newPrice;
    }
  }

  const handleClick = () => {
    history.push("/my-orders");
  };

  const dataShow = () => {
    LocalStorage.set("pastOrder", wholeClass);
    history.push(ROUTES.PASTORDERS);
  };

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="widthWrapper">
            {data &&
              data?.length > 0 &&
              data.map((item) => {
                return (
                  <>
                    <div className="lastOrderCard">
                      <div className="lastOrderCard_leftSide">
                        <img
                          src={item?.image_url}
                          className="lastOrderCard_leftSide_img"
                          alt="image_url"
                        />
                      </div>
                      <div className="lastOrderCard_rightSide">
                        <div className="lastOrderCard_rightSide_wholeWrapper">
                          <div
                            className={`lastOrderCard_data_type ${
                              item?.is_veg === false &&
                              `lastOrderCard_data_type2`
                            }`}
                          />
                          <p className="wholeWrapper_nameLabel">{item?.name}</p>
                        </div>
                        <div className="lastOrderCard_rightSide_bottom">
                          {/* <p className="bottom_addons">{item?.addons}</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="lastOrderCard_divider"></div>
                  </>
                );
              })}
          </div>
          <div className="footer_wholeDiv">
            <p className="footer_wholeDiv_price">
              {" "}
              &#8377;{Number(finalPrice)}
            </p>
            <Button
              className="footer_wholeDiv_button"
              customClass="footer_wholeDiv_content"
              content="order Now"
              onClick={() => handleClick()}
            />
          </div>
        </>
      ) : (
        <div className="widthWrapper">
          {data &&
            data?.length > 0 &&
            data.map((item) => {
              return (
                <div className="lastOrderCard">
                  <img src={logo} className="lastOrderCard_img" alt="logo"/>
                  <div className="lastOrderCard_data">
                    <div
                      className={`lastOrderCard_data_type ${
                        item?.is_veg === false && `lastOrderCard_data_type2`
                      }`}
                    />
                    <p className="lastOrderCard_data_nameLabel">{item?.name}</p>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default LastOrderCard;
