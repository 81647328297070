import React from "react";
import "./index.scss";
import percentageIcon from "../../assets/images/percentage-icon.svg";

const OffersCard = (props) => {
  const { data, onOfferClick } = props;
  const { innerWidth } = window;
  return (
    <>
      {innerWidth <= 1023 ? (
        <div className="offersCard" onClick={() => onOfferClick(data)}>
          {/* <div className=""> */}
            <img src={percentageIcon} className="offersCard_img" alt="percentageIcon"/>
            <div className="offersCard_txtDiv">
              <p className="offersCard_txtDiv_nameLabel">{data.offer_name}</p>
              <p className="offersCard_txtDiv_subLabel">
                {data.short_description}
              </p>
            </div>
          {/* </div> */}
        </div>
      ) : (
        <div className="offersCard" onClick={() => onOfferClick(data)}>
          <img src={percentageIcon} className="offersCard_img" alt="percentage"/>
          <div className="offersCard_txtDiv">
            <p className="offersCard_txtDiv_nameLabel">{data.offer_name}</p>
            <p className="offersCard_txtDiv_subLabel">
              {data.short_description}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default OffersCard;
