import React, { useState, useEffect } from "react";
import "./index.scss";
import Clock from "../../assets/images/time.svg";
import Timer from "../timer";
import Button from "../button";
import { useHistory } from "react-router-dom";
import { getOrderTrack } from "../../actions/cart";
import { LocalStorage } from "../../utils/storage";
import moment from "moment";
import AlertPopup from "../alert-popup";

const CancelOrderCard = ({ onShow, onClick }) => {
  const [cancelCardOpen, setCancelCardOpen] = useState(false);
  const [timerSecond, setTimerSecond] = useState(60);
  const [alertModal , setAlertModal] = useState(false);
  const currentDate = new Date();
  const currentMinutes = currentDate.getMinutes();
  const currentSeconds = currentDate.getSeconds();
  var secondsDiff;

  useEffect(() => {
    const queryParams = LocalStorage.get("purchaseDetailId");
    getOrderTrack((res) => {
      if (res.response && res.response.status === 200) {
        //  
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("loyalty");
        LocalStorage.remove("offerAmount");
        let orderCreated =
          res.content?.tracking_Details?.order_details?.order_created_at;
        // let sTime = new Date(orderCreated);
        var currentTimeUTC = new Date(orderCreated);
        var currentTimeIST = moment(currentTimeUTC).add(5, "hours").add(30, "minutes");
        var startTime = moment(currentTimeIST, "DD-MM-YYYY hh:mm:ss");
        var endTime = moment(new Date(), "DD-MM-YYYY hh:mm:ss");
        secondsDiff = Math.abs(endTime.diff(startTime, "seconds"));

        if (secondsDiff > 30) return;
        setTimerSecond(30 - secondsDiff);
        setCancelCardOpen(true);
      }
    }, queryParams);
  }, []);

  const { innerWidth } = window;
  const history = useHistory();
  const handleCancelClick = () => {
    onClick();
    handleAlertModal()
  };

  const handleAlertModal =()=>{
    setAlertModal((prevState)=>!prevState)
  }

  return (
    <>
      {cancelCardOpen && (
        <div className='cancelOrderCard'>
          <div className='cancelOrderCard_content'>
            <div className='cancelOrderCard_timer'>
              <img
                src={Clock}
                className='cancelOrderCard_timer_img'
                alt='clock'
                width='20'
                height='20'
              />
              {innerWidth >= 1024 && (
                <span className='cancelOrderCard_timer_nameLabel'>
                  <Timer seconds={timerSecond} onShow={onShow} />
                </span>
              )}
            </div>
            {innerWidth <= 1023 ? (
              <span className='cancelOrderCard_timer_nameLabel'>
                <Timer seconds={timerSecond} onShow={onShow} />
              </span>
            ) : (
              <h5 className='cancelOrderCard_text'>
                Note: If you choose to cancel, you can do it within 30 seconds
                after placing order. 
              </h5>
            )}
            {/* {innerWidth >=1024 && (
          <p className='cancelOrderCard_text'>
          Note: If you choose to cancel, you can do it within 60 seconds after
          placing order. Post which you will be charged 100% cancellation fee.
          </p>
        )} */}
          </div>
          <div className='cancelOrderCard_wholewrapper'>
            {innerWidth <= 1023 && (
              <div className='cancelOrderCard_wholewrapper_div'>
                <h4 className='cancelOrderCard_text'>
                  Any changes in the order?
                </h4>
                <h4 className='cancelOrderCard_text1'>
                  You can cancel the order within 30 Seconds
                </h4>
              </div>
            )}
          </div>
          {innerWidth <= 1023 ? (
            <Button
              content='Cancel'
              onClick={() => handleAlertModal()}
              className='cancelOrderCard_button'
            />
          ) : (
            <Button
              content='Cancel Order'
              onClick={()=>handleAlertModal()}
              className='cancelOrderCard_button'
            />
          )}
        </div>
      )}

      {alertModal && (
        <AlertPopup
          title={`Cancel`}
          description={`Are you sure you want to cancel?`}
          okayClick={() => handleCancelClick()}
          close={() => handleAlertModal()}
          alertCart={true}
        />
      )}
    </>
  );
};

export default CancelOrderCard;
