import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import Button from "../button";
import searchImg from "../../assets/images/searchImg.png";
import searchnotfound from "../../assets/images/searchnotfound.svg";
import close from "../../assets/images/close.svg";
import Input from "../input";
const SearchInput = (props) => {
  const { searchInput, updateInput, setSearchInput, handleKeypress, closeDiv } =
    props;
  const { innerWidth } = window;
  const history = useHistory();

  const handleGoBack = () => history.goBack();

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="searchInput">
            <img className="searchInput_img" src={searchImg} alt="search" />
            <div className="searchInput_wholeWrapper">
              <Input
                type="text"
                label="Search"
                autoFocus
                placeholder="Search"
                onChange={(id, value) => {
                  updateInput(value);
                }}
                value={searchInput}
                onKeyPress={(e) => {
                  handleKeypress(e);
                }}
                customClass="searchInput_input"
              />
              {searchInput.length >= 3 && (
                <Button
                  className="searchInput_btn"
                  customClass="searchInput_customBtn"
                  onClick={() => {
                    setSearchInput("");
                    closeDiv();
                  }}
                  content="Clear"
                />
              )}
            </div>
            {/* <img
              src={close}
              className="searchInput_wholeWrapper_backImg"
              onClick={() => handleGoBack()}
            /> */}
          </div>
        </>
      ) : (
        <>
          <div className="searchInput">
            <img className="searchInput_img" src={searchImg} alt="search" />
            <Input
              type="text"
              label="Search"
              autoFocus
              placeholder="Search"
              onChange={(id, value) => {
                updateInput(value);
              }}
              value={searchInput}
              onKeyPress={(e) => {
                handleKeypress(e);
              }}
              customClass="searchInput_input"
            />
            {searchInput.length >= 3 && (
              <Button
                className="searchInput_btn"
                onClick={() => {
                  setSearchInput("");
                  closeDiv();
                }}
                content="Clear"
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SearchInput;
