import React, { useState, useEffect } from "react";
import iIcon from '../../assets/images/iIcon.png';
import close from '../../assets/images/close.svg';
import "./index.scss";

const AmountSummary = (props) => {
  const { billingDetails, takeAway, customClass, offercode, stripData, isLoyalty, userBadge, innerWidth } = props;
  const [displayPop, setDisplayPop] = useState(false);
  const [deliveryChargeDetails, setDeliveryChargeDetails] = useState(false);

  // const { innerWidth } = window;
  let freeDelivery = false;
  if (isLoyalty && userBadge === "Gold") {
    freeDelivery = true
  }
  useEffect(()=>{
console.log("Free deliveryssss",billingDetails)
  },[billingDetails])
  const handlescreewidth = (name) => {
    if (window.innerWidth < 700) {
      if (name === 'Delivery Surcharge') {
        setDisplayPop(true)
      }
      else if (name === 'Delivery Charge') {
        setDeliveryChargeDetails(true)
      }
    }
  }
  let gold_coustomer_text = "";
  if (stripData && stripData?.delivery_strip) {
    if (stripData?.delivery_strip?.message != "" && stripData?.delivery_strip.is_show_free_delivery
    ) {
      gold_coustomer_text = ` ( ${stripData.delivery_strip.message} Customer ) `;
    }
  }
  console.log(stripData && stripData?.delivery_strip, "innnn", billingDetails);
  const handleAmout = (amount) => {
    let amt = amount && amount.substring(1).trim();
    if (amt < 0) {
      amt = "₹ 0";
    } else {
      amt = amount;
    }
    return amt;
  };
  useEffect(() => {
  }, [displayPop])
  const tooltipContent = "An additional delivery fee applies to your order. This can happen for a few reasons, such as bad weather or delivering a bulk order. We'll clearly show any applicable surcharges before checkout, so there are no surprises."
  // const tooltipChargeContent = "Delivery Charges based on Distance between the outlet and delivery address."

  return (
    <div
      className={`amountSummary ${takeAway && `amountSummary_takeAway`
        } ${customClass}`}
    >
      <div className="amountSummary_title">
        <p>Billing Details</p>
      </div>
      {console.log("props", billingDetails)}
      {billingDetails &&
        billingDetails.map((details) => {
          return (
            <>
              {details.name === "Total Amount" ? (
                <>
                  <div className="amountSummary_Hbar" />
                  <div className="amountSummary_total">
                    <div className="amountSummary_total_totalName">
                      <p>{details.name}</p>
                    </div>
                    <div className="amountSummary_total_totalAmount">
                      <p>{handleAmout(details.amount)}</p>
                    </div>
                  </div>
                  {/* {innerWidth <= 1023 && (
                    <div className="amountSummary_Hbar_total" />
                  )} */}
                </>
              ) : details.show ? (
                <div className="amountSummary_div">
                  <div
                    className={`amountSummary_name ${details?.name === "Discount" ||
                      details?.name === "Gift card"
                      ? "amountSummary_discountName"
                      : ""
                      }`}
                  >
                    <p>

                      {
                        details.name === 'Delivery Surcharge' ? <>{details.name}<img className="img-iicon" onClick={window.innerWidth > 700 ? "" : () => handlescreewidth(details.name)} src={iIcon} alt="i" /></> :
                          details.name === "Delivery Charge" ? (gold_coustomer_text ? <>{details.name}<span className="amountSummary_discountAmount">{gold_coustomer_text}</span></> : <>{details.name}{details.message && <img className="img-iicon" onClick={window.innerWidth > 700 ? "" : () => handlescreewidth(details.name)} src={iIcon} alt="i" />}</>) :
                            details.name === "Discount"
                              ? offercode
                                ? details.name.concat(" (", offercode, ")")
                                : details.name
                              : details.name
                      }

                    </p>
                    {
                      details.name === 'Delivery Surcharge' &&
                      <div className={displayPop ? "surchargetoolpop" : "surchargetool"} >
                        <div>
                          <h3>Delivery Surcharge</h3>
                          <hr></hr>
                          <p>
                            {tooltipContent}
                          </p>
                          <button className="surcharge_button" onClick={() => setDisplayPop(false)}><img src={close} alt="X" /></button>
                        </div>
                      </div>
                    }
                    {
                      (details.name === 'Delivery Charge' && details.message) && 
                      <div className={deliveryChargeDetails ? "chargetoolpop" : "chargetool"} >
                        <div>
                          <h3>Delivery Charge</h3>
                          <hr></hr>
                          <p>
                            {details.message}
                          </p>
                          <button className="surcharge_button" onClick={() => setDeliveryChargeDetails(false)}><img src={close} alt="X" /></button>
                        </div>
                      </div>
                    }
                  </div>
                  <div
                    className={`amountSummary_amount ${details?.name === "Discount" ||
                      details?.name === "Gift card"
                      ? "amountSummary_discountAmount"
                      : ""
                      }`}
                  >
                    {/* ₹ 0 */}
                    <p>
                      {details.textColor || (details.name === "Delivery charge" && freeDelivery) || details.amount === "₹ 0" ? (
                        <>
                          <span className="amountSummary_discountAmount">
                            FREE
                          </span>
                          {handleAmout(details.amount) !== "₹ 0" ? <span className="amountSummary_deliveryStrikeOut">
                            {handleAmout(details.amount)}
                          </span> : handleAmout(details.amount)}

                        </>
                      ) : (
                        handleAmout(details.amount)
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
    </div>
  );
};

export default AmountSummary;
