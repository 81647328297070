import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { getStores } from "../../actions/store-location";
import StoreCardProfile from "../../components/store-card-profile";
import "./index.scss";
import { LocalStorage } from "../../utils/storage";
import StoreLocatorPopup from "../../containers/store-locator-popup";
import goback from "../../assets/images/goback.svg";
import { ROUTES } from "../../constants/app-constants";
import { Authentication, Logout } from "../../actions/authentication";
import ProfileCard from "../../components/profile-card";
import MenuSideBar from "../../containers/menuSideBar";
import Header from "../../containers/header";
import HeaderCommon from "../../components/header-common-component";
import Switch from "../../components/switch";
import AlertPopup from "../../components/alert-popup";
import { menuData, guestMenuData } from "../../helpers/constants";
import { storeLocatorPageMeta } from "../../helpers/metaTitleDescription";
import { logOutIdentityBranch } from "../../utils/branch";

const StoreLocator = (props) => {
  const { Dom, profileorder, routeName, customClassRoute } = props;
  const [storeData, setStoreData] = useState([]);
  const [store, setStore] = useState({});
  const [modal, setModal] = useState(false);
  const [active1, setActive1] = useState();
  let locationDetails = LocalStorage.get("location");
  const [active, setActive] = useState("all");
  const [islog, setIsLog] = useState(false);

  useEffect(() => {
    const params =
      "?" +
      "latitude" +
      "=" +
      // '' +
      "&" +
      "longitude" +
      "=";
    getAllStores(params);
  }, []);

  const getAllStores = (params) => {
    getStores((res) => {
      if (res && res?.response?.status === 200) {
        setStoreData(res?.content?.stores_near_to_user);
      }
    }, params);
  };

  const switchData = [
    { id: "all", name: "All Stores" },
    { id: "near", name: "Near by Stores" },
  ];
  const onChangeMode = (value) => {
    let params;
    if (value.id === "all") {
      params =
        "?" +
        "latitude" +
        "=" +
        // '' +
        "&" +
        "longitude" +
        "=";
      getAllStores(params);
    } else {
      params =
        "?" +
        "latitude" +
        "=" +
        locationDetails.lat +
        "&" +
        "longitude" +
        "=" +
        locationDetails.lng;
      getAllStores(params);
    }
    setActive(value.id);
  };

  const modalOpen = (item) => {
    setStore(item);
    setModal(true);
  };
  const close = () => {
    setModal(false);
  };

  const { innerWidth } = window;
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  console.log(Dom, profileorder);
  const onClickCategory = (itm) => {
    if (itm.category_name === "Logout") {
      setIsLog(true);
      return
      // Logout((re) => {
      //   if (re.response.status === 200) {
      //     LocalStorage.set("isLoggedIn", false);
      //     LocalStorage.set("UserData", {});
      //     LocalStorage.remove("CartProducts");
      //     Authentication((res) => {
      //       if (res.response.status === 200) {
      //         if (res.content) {
      //           LocalStorage.set("AccessToken", res.content.access_token);
      //           LocalStorage.set("RefreshToken", res.content.refresh_token);
      //           history.push(ROUTES.HOME);
      //         } else {
      //           history.push(ROUTES.HOME);
      //         }
      //       }
      //     });
      //   }
      // });
    }
    setActive1(itm.category_name);
    // if (innerWidth <= 1023) {
    history.push(itm.key);
    // }
  };

  const confirmLogout = () => {
    Logout((re) => {
      if (re.response.status === 200) {
        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");

        //branch event
        logOutIdentityBranch()
        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              history.push(ROUTES.HOME);
            } else {
              history.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>{storeLocatorPageMeta.meta_title}</title>
        <meta name="description" content={storeLocatorPageMeta.desciptions} />
        <meta name="keywords" content={storeLocatorPageMeta.keywords} />
      </Helmet>
      {
        islog &&
        <AlertPopup
          title='Logout?'
          description='Are you sure you want logout?'
          okayClick={() => confirmLogout()}
          logout
          close={() => setIsLog(false)}
        />
      }
      {innerWidth <= 1023 ? (
        <>
          <div className="storelocator">
            {modal && (
              <StoreLocatorPopup close={close} data={store} storepopup={true} />
            )
            }
            <div className="storelocator_wholeWrapper">
              <img
                src={goback}
                className="storelocator_wholeWrapper_backImg"
                onClick={() => handleGoBack()}
                alt="goback"
              />
              <h3 className="storelocator_wholeWrapper_nameLabel">Dine In</h3>
              <div className="gaping" />
            </div>
            {/* <p className="storelocator_subLabel">All stores</p> */}
            <div className="storelocator_sub">
              <Switch
                data={switchData}
                active={active}
                onFilter={onChangeMode}
              />
            </div>
            <StoreCardProfile modalOpen={modalOpen} data={storeData} />
          </div>
        </>
      ) : (
        <>
          {Dom && (
            <>
              <HeaderCommon />
              <div className="storelocator_wholeSection">
                <div className="storelocator_wholeSection_one">
                  <ProfileCard />
                </div>
                <div className="storelocator_wholeSection_two">
                  <div className="storelocator_subSectionLeft">
                    <MenuSideBar
                      data={LocalStorage.get("isLoggedIn") ? menuData : guestMenuData}
                      active={active1}
                      onClickCategory={onClickCategory}
                      routeName={routeName}
                      customClassRoute={customClassRoute}
                    />
                    {/* {getMenuData()} */}
                  </div>
                  <div className="storelocator_subSectionRight">
                    {modal && <StoreLocatorPopup close={close} data={store} />}
                    <h3 className="storelocator_nameLabel">Dine In</h3>
                    <div className="storelocator_sub">
                      <Switch
                        data={switchData}
                        active={active}
                        onFilter={onChangeMode}
                      />
                    </div>
                    <div className="gaping" />
                    <StoreCardProfile modalOpen={modalOpen} data={storeData} />
                  </div>
                </div>
              </div>
            </>
          )}
          {profileorder && (
            <div className="storelocator">
              {modal && <StoreLocatorPopup close={close} data={store} />}
              <h3 className="storelocator_nameLabel">Dine In</h3>
              <div className="storelocator_sub">
                {/* <p className="storelocator_sub_subLabel">All stores</p> */}
                <Switch
                  data={switchData}
                  active={active}
                  onFilter={onChangeMode}
                />
              </div>
              <div className="gaping" />
              <StoreCardProfile modalOpen={modalOpen} data={storeData} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default StoreLocator;
