import React ,{ useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import congrats from "../../assets/images/congrats.svg";
import loyalty from "../../assets/images/loyaltyres.svg";
import { ROUTES } from "../../constants/app-constants";

import { LocalStorage } from "../../utils/storage";
import { getLoyalityData } from "../../actions/loyalty";
import gold from "../../assets/images/Gold.png";
import bronze from "../../assets/images/Bronze.png";
import silver from "../../assets/images/Silver.png";
const SupportDetails = (props) => {
  const { data, img, loyaltyNumber, support } = props;
  const history = useHistory();

  const routeIt = () => {
    if (data.name === "Need Support?") {
      history.push(ROUTES.CONTACTUS);
    } else if(data.name === "Congratulations"){
      history.push(ROUTES.LOYALTY)
    }
  };
  const { innerWidth } = window;
  const isLoggedInUser = LocalStorage.get("isLoggedIn");
  const [loyaltyData, setLoyaltyData] = useState();
  useEffect(() => {
    if (isLoggedInUser) {
      getLoyalityData((res) => {
        if (res && res?.response?.status === 200) {
          if (res && res.content && res.content.current_points) {
            setLoyaltyData(res.content.current_points);
            
          }
        }
      });}
    }, []);
  return (
    <>
      {innerWidth <= 1023 ? (
        <div className="supportDetails" onClick={() => routeIt()}>
          <div className="supportDetails_data">
            <div className="supportDetails_data_main">
              <div className="supportDetails_data_main_one">
                <p className={`supportDetails_data_main_one_name`}>
                  {data.name}
                </p>
                {data.name == "Congratulations" && (
                  <span
                    className="emoji"
                    role="img"
                    aria-label="birthday hat with face"
                  >
                    &#x1F973;
                  </span>
                )}
              </div>
              {data.description ? (
                <div className="supportDetails_data_main_two">
                  <p className="supportDetails_data_main_two_sub">
                    {data.description}
                  </p>
                </div>
              ) : (
                <div className="supportDetails_data_main_two">
                  <p className="supportDetails_data_main_two_sub">
                    {loyaltyNumber}
                  </p>
                </div>
              )}
            </div>
            {support ? (
              <img
                src={img}
                className="supportDetails_data_message"
                alt="img"
              />
            ) : (
              <div className="supportDetails_data_imgDiv">
              
                 <img src={loyaltyData?.guest_label === "Gold" ? gold : loyaltyData?.loyaltyData?.guest_label === "Silver" ? silver : loyaltyData?.guest_label === "Bronze" ? bronze : null}
                        className="supportDetails_data_loyaltymessage" />
               
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`supportDetails supportDetails_${data.name}`}
          onClick={() => routeIt()}
        >
          <div className="supportDetails_data">
            <div className="supportDetails_data_main">
              <div className="supportDetails_data_main_one">
                <p className={`supportDetails_data_main_one_name`}>
                  {data.name}
                </p>
                {data.name == "Congratulations" && (
                  <span
                    className="emoji"
                    role="img"
                    aria-label="birthday hat with face"
                  >
                    &#x1F973;
                  </span>
                )}
              </div>
              {data.description ? (
                <div className="supportDetails_data_main_two">
                  <p className="supportDetails_data_main_two_sub">
                    {data.description}
                  </p>
                </div>
              ) : (
                <div className="supportDetails_data_main_two">
                  <p className="supportDetails_data_main_two_sub">
                    {loyaltyNumber}
                  </p>
                </div>
              )}
            </div>
            <img src={img} className="supportDetails_data_message" alt="img" />
          </div>
        </div>
      )}
    </>
  );
};

export default SupportDetails;
