import React, { useState } from "react";
import "./index.scss";
import congrats from "../../assets/images/congrats.svg";
// import Accept from '../../assets/images/acceptOrder.svg';
import cookie from "../../assets/images/cookieImg.png";
import coffee from "../../assets/images/coffeeImg.png";
import chocolate from "../../assets/images/chocolate-barImg.png";
import Input from "../input";
import Button from "../button";
import { regex } from "../../constants/regex";

const TipRider = (props) => {
  const { tipData, addTip, tip, setTip, clicked } = props;
  const [custom, setCustom] = useState(false);

  const [customClass, setCustomClass] = useState(false);
  const [isValidAmt , setValidAmt]=useState('');

  const customOpen = () => {
    setCustom(!custom);
    setCustomClass(true);
    // addTip(tipData.val, clicked === tipData.val ? true : false);
  };

  const handleValidateInput = ()=>{
    if (tip == 0 || !regex.amountRegex.test(tip)) {
      return setValidAmt("Please enter a valid amount.");
    }

    addTip(tip, tip === "" ? true : false);
    setValidAmt('')
    customOpen();
  }

  return (
    <div className="tipRider">
      <h3 className="tipRider_nameLabel">
        Would you like to give tip to our delivery person?
      </h3>
      <div className="tipRider_tipDiv">
        {tipData.map((item) => {
          return (
            <div
              onClick={() => {
                setCustom(false);
                setCustomClass(false);
                setValidAmt('')
                addTip(item.val, clicked === item.val ? true : false);
              }}
              className={`tipRider_tipDiv_tip ${
                clicked === item.val ? "tipRider_tipDiv_active" : ""
              }`}
            >
              {item.img && (
                <img className="tipRider_tipDiv_tip_img" src={item.img} alt="img"/>
              )}
              <p className="tipRider_tipDiv_tip_text">+₹{item.name}</p>
            </div>
          );
        })}
        <div
          className={`tipRider_tipDiv_tip ${
            clicked === tipData.val ? "tipRider_tipDiv_active" : ""
          } ${customClass && `tipRider_tipDiv_customActive`}`}
          onClick={() => customOpen()}
        >
          <h2 className="tipRider_tipDiv_tip_text">Custom</h2>
        </div>
      </div>
      {custom && (
        <div
          className={`tipRider_inpDiv ${
            clicked === tipData.val ? "tipRider_tipDiv_active" : ""
          }`}
        >
          <Input
            type="text"
            onChange={(id, value) => {
              setTip(value);
            }}
            value={tip}
            isMobileNumber
            customClass="tipRider_inpDiv_inp"
            placeholder="Add custom tip"
          />
          <Button
            className="tipRider_inpDiv_btn"
            onClick={() => {
              handleValidateInput()
            }}
            content="add tip"
            customClass="tipRider_inpDiv_btn_tipContent"
          />

        </div>
      )}
      <p className='tipRider_error-text'>{isValidAmt}</p>
    </div>
  );
};
export default TipRider;
