import React from "react";
import "./index.scss";
import Marker from "../../assets/images/marker.png";
import SearchAutoComplete from "../../containers/searchAutocomplete";
import Back from "../../assets/images/back.svg";
import Detect from "../../assets/images/detectLoc.svg";
import BBk from "../../assets/images/biryani-by-kilo-login.jpg";
import Button from "../button";
import StoreCard from "../store-card";
import Input from "../input";
import close from "../../assets/images/close.svg";
import goback from "../../assets/images/goback.svg";
import { LocalStorage } from "../../utils/storage";
import RenderMaps from "../../containers/MapsCommonApi";

const Styles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: {
      visibility: "off",
    },
  },
  {
    featureType: "poi.business",
    stylers: [{ visibility: "off" }],
  },
];

const dummydata = {
  heading: "Biriyani BBK",
  description: "hgajsadgauksdg",
  img: BBk,
  distance: "2.2",
  time: "32",
};

class GoogleMapSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapIsReady: {},
      latlng: { lat: "", lng: "" },
      mapOptions: {
        center: { lat: "", lng: "" },
        zoom: 16,
        disableDefaultUI: true,
        draggable: true,
        mapTypeId: "roadmap",
        // styles: Styles,
      },
    };
  }

  componentDidMount = () => {
    const { center, inputValueData } = this.props;
    const { mapOptions } = this.state;
    if (center.lat === "") {
    }
    let image = Marker;
    mapOptions.center = center;
    this.setState({ mapOptions, latlng: center });
    this.isMapLoads(center);
  };

  componentDidUpdate() {
    const { latlng, mapOptions } = this.state;
    const { center, onCenterChange } = this.props;

    // if (center.lat !== '' || center.lng !== '') {
    //   this.isMapLoads(center);
    //   onCenterChange(false);
    // }
    if (latlng.lat !== center.lat) {
      if (latlng.lat !== "" || latlng.lng !== "") {
        if (mapOptions.center !== latlng) {
          this.isMapLoads(latlng);
        }
      }
      // if (mapOptions.center !== latlng) {
      //   mapOptions.center = latlng;
      //   this.setState({ mapOptions });
      // }
    }
  }

  isMapLoad = () => {
    const { mapOptions } = this.state;
    let image = Marker;
    if (mapOptions.center !== { lat: "", lng: "" }) {
      const { google } = window;
      const map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      const marker = new google.maps.Marker({
        map,
        icon: image,
        draggable: false,
        position: map.getCenter()
      });
      const position = marker.getPosition();
      if (typeof position !== "undefined") {
        map.setCenter(marker.getPosition());
      }

      map.addListener("center_changed", () => {
        marker.setPosition(map.getCenter());
        const NewMapCenter = map.getCenter();
        map.addListener(
          "drag",
          this.oncenter(NewMapCenter.lat(), NewMapCenter.lng())
        );
      });
    }
  };

  oncenter = (lat, lng) => {
    // console.log("ssssssssssssss",lat , lng)
    const { onMarkerPosition } = this.props;
    const { mapOptions } = this.state;
    const json = { lat, lng };
    mapOptions.center = json;
    this.setState({ mapOptions, latlng: json });
    onMarkerPosition(json);
  };

  onLoad = (value, json) => {
    if (value === true) {
      this.isMapLoads(json);
    }
  };

  onCurrentLocation = () => {
    const { onCurrentDetectLocation } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    onCurrentDetectLocation({
                      location: results[0].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                    });
                    this.setState({ latLng: { lat: json.lat, lng: json.lng } },()=>{
                      this.isMapLoads({ lat: json.lat, lng: json.lng });
                    });
                  }
                }
              },
              this
            );
          }
          // eslint-disable-next-line no-unused-vars
        },
        (error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  isMapLoads = (json) => {
    const { mapOptions } = this.state;
    let image = Marker;
    mapOptions.center = json;
    const { google } = window;
    const map = new google.maps.Map(document.getElementById("map"), mapOptions);
    let marker;
    marker = new google.maps.Marker({
      map,
      icon: image,
      draggable: true,
      position: map.getCenter()
    });
    const position = marker.getPosition();
    if (typeof position !== "undefined") {
      map.setCenter(marker.getPosition());
    }

        
    google.maps.event.addListener(map,'center_changed', function() {
        marker.setPosition(map.getCenter());
    });

    map.addListener("dragend", () => {
      marker.setPosition(map.getCenter());
      const NewMapCenter = map.getCenter();
      this.oncenter(NewMapCenter.lat(), NewMapCenter.lng())
      // map.addListener(
      //   "idle",
      //   this.oncenter(NewMapCenter.lat(), NewMapCenter.lng())
      // );

    });
  };

  render() {
    const {
      isMobileView,
      inputValue,
      onSearchChange,
      setInputValue,
      setLatLng,
      onClearClick,
      getCurrentLocation,
      noLocationFound,
      onBackClick,
      onCurrentDetectLocation,
      activeTheme,
      inputData,
      onChangeInput,
      onConfirmLocation,
      onClickStore,
    } = this.props;

    const { innerWidth } = window;

    const handleFormateAddress = (address) => {
      let splitAddress, remainderAddress;
      if (address) {
        splitAddress = address?.substring(0, address.indexOf(','));
        remainderAddress = address?.substring(
          address.indexOf(',') + 1,
          address.length
        );
      }
      return (
        <div>
          {splitAddress && <p className="map-search_bold-label">{splitAddress}</p>}
         {remainderAddress &&  <p className="map-search_address-label">{remainderAddress}</p>}
        </div>
      );
    };

    return (
      <>
        <div className="google-maps">
          {innerWidth <= 1023 ? (
            <div className="goback" onClick={onBackClick}>
              <img src={goback} width="14" height="14" className="goback_img" alt="goback"/>
            </div>
          ) : (
            <div className="back" onClick={onBackClick}>
              <img src={close} width="14" height="14" className="back_img" alt="close"/>
            </div>
          )}
          <div id="map" className="map" />
          <div className="detect" onClick={() => this.onCurrentLocation()}>
            <img src={Detect} width="14" height="14" className="back_img" alt="detect"/>
          </div>
        </div>
        <div className="map-search">
          <div className="map-search_text">
            <p className="map-search_note">
              Note: You can scroll to change the marker
            </p>
          </div>
          {innerWidth <= 1023 ? (
            <>
              <div className="map-search_bodyWholeWrapper">
                <div className="map-search_locations">
                  <h1 className="map-search_label">Approximate Delivery location</h1>
                  {activeTheme === "delivery" ? (
                    handleFormateAddress(inputData)
                  ) : (
                    <StoreCard data={dummydata} />
                  )}
                </div>
                <div className="map-search_buttonBox">
                  <Button
                    content='Confirm location'
                    className="map-search_button"
                    onClick={() => {
                      onConfirmLocation();
                    }}
                    customClass="map-search_button_content"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="map-search_locations">
                <p className="map-search_label">Approximate Delivery location</p>
                {activeTheme === "delivery" ? (
                  handleFormateAddress(inputData)
                ) : (
                  <StoreCard data={dummydata} />
                )}
              </div>
              <div className="map-search_buttonBox">
                <Button
                  content="Confirm Location"
                  className="map-search_button"
                  // onClick={onConfirmLocation}
                  onClick={() => {
                    onConfirmLocation();
                  }}
                  customClass="map-search_button_content"
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default GoogleMapSearch;
