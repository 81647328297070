import React, { useState } from "react";
import "./index.scss";
import distanceCategoryIcon from "../../assets/images/distance-category-icon.svg";
import distanceCategoryGif from "../../assets/images/30- 24 Hrs (2).gif";
const DistanceCategoryInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOnClick = (e) => {
    // if(e && e.stopPropagation) e.stopPropagation();
    setIsOpen(!isOpen);
  }
  return (
    <div className={`DistanceCategoryInfo ${isOpen ? "open" : ""}`} onClick={handleOnClick}>
      <div className="distance_category_icon_container">
        <img
          src={distanceCategoryGif}
          alt="clock-icon"
          className="distance_category_icon"
        />
      </div>
    </div>
  );
};

export default DistanceCategoryInfo;
