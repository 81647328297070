import React, { useEffect, useState } from "react";
import "./index.scss";
import Logo from "../../assets/images/footerLogo.png";
import gplay from "../../assets/images/gplay.png";
import istore from "../../assets/images/istore.png";
import facebookIcon from "../../assets/images/facebookIcon.svg";
import linkedinIcon from "../../assets/images/linkedinIcon.svg";
import instagramIcon from "../../assets/images/instagramIcon.png";
import twitterIcon from "../../assets/images/twitterIcon.svg";
import youtubeIcon from "../../assets/images/youtubeIcon.svg";
import { getStaticPage } from "../../actions/staticpages";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import { LocalStorage } from "../../utils/storage";

const Footer = (props) => {
  const history = useHistory();
  const { data, customClass } = props;
  const [footerData, setFooterData] = useState([]);
  const [blog, setBlog] = useState(false);
  const isLoggedIn = LocalStorage.get("isLoggedIn");
  const [more, setMore] = useState([]);
  useEffect(() => {
    const query = "";
    let profilearr = [];
    let usefullArr = [];
    let moreInfo = [];
    // getStaticPage((res) => {
    //   if (res && res?.response?.status === 200) {
    data?.result &&
      data?.result.length > 0 &&
      data?.result.map((item) => {
        if (item.filter_name === "profile") {
          profilearr.push(item);
        } else if (item.filter_name === "useful_links") {
          usefullArr.push(item);
        } else if (item.filter_name === "more_info") {
          moreInfo.push(item);
        }
      });
    console.log("usefullArr", usefullArr);
    setFooterData(usefullArr);
    setMore(moreInfo && moreInfo);
    //   }
    // }, query);
  }, []);

  const staticRender = (url, i) => {
    //
    const queryParams = url;
    //  history.push({
    //     pathname: res?.content?.result?.url_text,
    //     // search: '?query=abc',
    //     state: res?.content?.result,
    //   });

    history.push({
      pathname: queryParams,
      state: i,
    });
    // window.location.reload()

    // getStaticPage((res) => {
    //   if (res && res?.response?.status === 200) {
    //     // setProfileData(res && res?.content);
    //     LocalStorage.set("staticData", res?.content?.result);
    //     // history.push(res?.content?.result?.url_text);
    //     history.push({
    //       pathname: res?.content?.result?.url_text,
    //       // search: '?query=abc',
    //       state: res?.content?.result,
    //     });
    //   }
    // }, queryParams);
  };

  const usefulLinks = [
    {
      id: 0,
      name: "Our Vision",
    },
    {
      id: 1,
      name: "Catering",
    },
    {
      id: 2,
      name: "Sponsor a Biryani",
    },
    {
      id: 3,
      name: "Feedback",
    },
    {
      id: 4,
      name: "Refer & Earn",
    },
    {
      id: 5,
      name: "Biryani League",
    },
    {
      id: 6,
      name: "Safety Standards",
    },
    {
      id: 7,
      name: "Loyalty",
    },
  ];

  const moreInfo = [
    {
      id: 1,
      name: "My orders",
      key: "/my-orders",
    },
    // {
    //   id: 2,
    //   name: "Blogs",
    //   key: "/blogs",
    // },
    {
      id: 3,
      name: "Dine In",
      key: "/store-locators",
    },
    // {
    //   id: 4,
    //   name: "Gift Vouchers",
    //   key: "/gift-vouchers",
    // },
    {
      id: 5,
      name: "News & Media",
      key: "/news-media",
    },

    // {
    //   id: 7,
    //   name: "Notification",
    //   key: "/notification",
    // },
  ];
  const { innerWidth } = window;
  const handleBlogClick = () => {
    //
    history.push("/product-list");
  };

  const getCopyrightYear = () => {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  };

  const handleLogoClick = () => {
    history.push(ROUTES.HOME);
  };

  return (
    <div className={`${customClass ? customClass : `footer`}`}>
      <div className="footer_detail">
        {innerWidth <= 1023 ? (
          <>
            <Link to={ROUTES.HOME}>
              <div className="footer_detail_c1">
                <img
                  src={Logo}
                  className="footer_detail_c1_img"
                  // onClick={handleLogoClick}
                  alt="logo"
                />
              </div>
            </Link>
            <div className="footer_divider" />
            <div className="footer_detail_c2">
              <div className="footer_detail_c2_contact_headLabelDiv">
                <div className="footer_detail_c2_contact_headLabelDiv_wholeWrapper">
                  <h1 className="footer_detail_c2_contact_headLabelDiv_headLabel">
                    Explore BBK App on Mobile
                  </h1>
                  <div className="footer_detail_c2_contact_headLabelDiv_subSection">
                    <div className="footer_detail_c2_contact_headLabelDiv_store">
                      <img
                        src={gplay}
                        className="footer_detail_c2_contact_headLabelDiv_store_img"
                        alt="gplay"
                      />
                      <img
                        src={istore}
                        className="footer_detail_c2_contact_headLabelDiv_store_img"
                        alt="gplay"
                      />
                    </div>
                    <div className="footer_detail_c3_contact_social">
                      <p className="footer_detail_c3_contact_social_nameLabel">
                        Follow us:{" "}
                      </p>
                      <img
                        src={facebookIcon}
                        className="footer_detail_c3_contact_social_img"
                        onClick={() =>
                          window.open("https://www.facebook.com/biryanibykilo/")
                        }
                        alt="facebook"
                      />
                      <img
                        src={linkedinIcon}
                        className="footer_detail_c3_contact_social_img"
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/company/biryanibykilo"
                          )
                        }
                        alt=""
                      />
                      <img
                        src={instagramIcon}
                        className="footer_detail_c3_contact_social_img"
                        onClick={() =>
                          window.open("http://instagram.com/biryanibykilo")
                        }
                        alt="linkedinIcon"
                      />
                      <img
                        src={twitterIcon}
                        className="footer_detail_c3_contact_social_img"
                        onClick={() =>
                          window.open("https://twitter.com/biryanibykilo")
                        }
                        alt="twitter"
                      />
                      <img
                        src={youtubeIcon}
                        className="footer_detail_c3_contact_social_img"
                        onClick={() =>
                          window.open(
                            "https://www.youtube.com/channel/UC2VWN7yypw0m6xyqaNwM0Fw"
                          )
                        }
                        alt="youtube"
                      />
                    </div>
                  </div>
                </div>
                <div className="footer_divider" />
              </div>
            </div>
            <div className="footer_detail_c2_usefulLinks_wholeWrapper">
              {/* {usefulLinks.map((item) => {
                return (
                  <p className="footer_detail_c2_nameLabel footer_detail_c2_nameLabelVision">
                    {item.name}
                  </p>
                );
              })} */}
              {data?.length > 0
                ? data?.map((item) => {
                    return (
                      // item?.to_be_displayed_in_footer &&
                      <p
                        onClick={() => {
                          staticRender(item?.url_text, item);
                        }}
                        className={`footer_detail_c2_nameLabel ${
                          item?.title === "Terms & Conditions" &&
                          `footer_detail_c2_termsNameLabel`
                        }`}
                      >
                        {item?.title !== "Terms & Conditions" && item?.title}
                      </p>
                    );
                  })
                : footerData &&
                  footerData?.length > 0 &&
                  footerData.map((item) => {
                    return (
                      // item?.to_be_displayed_in_footer &&
                      <p
                        onClick={() => {
                          console.log("URL",item?.url_text);
                          staticRender("/" + item?.url_text, item);
                        }}
                        className="footer_detail_c2_nameLabel"
                      >
                        {item?.title !== "Terms & Conditions" && item?.title}
                      </p>
                    );
                  })}
              {moreInfo.map((item) => {
                if (item.name === "My orders") {
                  if (isLoggedIn) {
                    return (
                      <p
                        className="footer_detail_c2_nameLabel"
                        onClick={() => history.push(item.key)}
                      >
                        {item.name}
                      </p>
                    );
                  }
                } else {
                  return (
                    <p
                      className="footer_detail_c2_nameLabel"
                      onClick={() => history.push(item.key)}
                    >
                      {item.name}
                    </p>
                  );
                }
              })}
            </div>
            <div className="footer_detail_c3">
              <div className="footer_detail_c3_contact">
                <div className="footer_detail_c3_contact_numberDiv">
                  <p className="footer_detail_c3_contact_numberDiv_call">
                    {innerWidth > 1023 ? "Call:" : "Contact us:"}
                  </p>
                  <p className="footer_detail_c3_contact_numberDiv_number">
                    <a href="tel: +91 9555-212-212" className="footer_call">
                      {" "}
                      +91 9555-212-212
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="footer_divider" />
            <div className="footer_detail_c1">
              <div className="footer_detail_c1_terms">
                <p
                  className="p1"
                  onClick={() => {
                    history.push(ROUTES.TERMS_CONDITIONS);
                  }}
                >
                  Terms & Conditions
                </p>
                <h1 className="p1">Privacy Policy</h1>
                <h3 className="p1">Security</h3>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="footer_detail_c1">
              <img
                src={Logo}
                height="70"
                className="footer_detail_c1_img"
                onClick={handleLogoClick}
                alt=""
              />

              <div className="footer_detail_c1_terms">
                <h1
                  className="p1"
                  onClick={() => {
                    history.push(ROUTES.TERMS_CONDITIONS);
                  }}
                >
                  Terms & Conditions
                </h1>
                <p className="p1">Privacy Policy</p>
                {/* <p className="p1">Security</p> */}
              </div>
            </div>

            <div className="footer_detail_c2">
              <h1 className="footer_detail_c2_headLabel">Useful Links</h1>
              {data?.length > 0
                ? data?.map((item) => {
                    return (
                      // item?.to_be_displayed_in_footer
                      //  &&
                      <p
                        onClick={() => {
                          console.log("URL",item?.url_text);
                          staticRender("/" + item?.url_text, item);
                        }}
                        className="footer_detail_c2_nameLabel"
                      >
                        {item?.title !== "Terms & Conditions" && item?.title}
                      </p>
                    );
                  })
                : footerData &&
                  footerData?.length > 0 &&
                  footerData.map((item) => {
                    return (
                      // item?.to_be_displayed_in_footer &&
                      <p
                        onClick={() => {
                          staticRender("/" + item?.url_text, item);
                        }}
                        className="footer_detail_c2_nameLabel"
                      >
                        {item?.title !== "Terms & Conditions" && item?.title}
                      </p>
                    );
                  })}
            </div>
            <div className="footer_detail_c2">
              <h1 className="footer_detail_c2_headLabel">More Info</h1>
              {/* {moreInfo.map((item) => {
                return (
                  <p className="footer_detail_c2_nameLabel">{item.name}</p>
                );
              })} */}
              {more &&
                more.length > 0 &&
                more.map((i) => {
                  return (
                    // i?.filter_name === 'more_info' &&
                    <p
                      onClick={() => {
                        staticRender("/more-info/" + i?.url_text);
                      }}
                      className="footer_detail_c2_nameLabel"
                    >
                      {i?.title !== "Terms & Conditions" && i?.title}
                    </p>
                  );
                })}
            </div>
            <div className="footer_detail_c3">
              <div className="footer_detail_c3_contact">
                <h2 className="footer_detail_c3_contact_headLabel">
                  Contact Us
                </h2>
                <div className="footer_detail_c3_contact_numberDiv">
                  <h3 className="footer_detail_c3_contact_numberDiv_call">
                    Call
                  </h3>
                  <p className="footer_detail_c3_contact_numberDiv_number">
                    <a href="tel: +91 9555-212-212" className="footer_call">
                      {" "}
                      +91 9555-212-212
                    </a>
                  </p>
                </div>
              </div>
              <h1 className="footer_detail_c3_contact_headLabel">
                Explore BBK App on Mobile
              </h1>
              <div className="footer_detail_c3_contact_store">
                <img
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=in.bbk.android&hl=en"
                    )
                  }
                  src={gplay}
                  className="footer_detail_c3_contact_store_img"
                  alt="gplay"
                />
                <img
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/in/app/biryani-by-kilo-order-online/id1106886101"
                    )
                  }
                  src={istore}
                  className="footer_detail_c3_contact_store_img"
                  alt="gplay"
                />
              </div>
              <div className="footer_detail_c3_contact_social">
                <h2 className="footer_detail_c3_contact_social_nameLabel">
                  Follow us:
                </h2>
                <img
                  onClick={() =>
                    window.open("https://www.facebook.com/biryanibykilo/")
                  }
                  src={facebookIcon}
                  className="footer_detail_c3_contact_social_img"
                  alt="facebook"
                />
                <img
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/biryanibykilo"
                    )
                  }
                  src={linkedinIcon}
                  className="footer_detail_c3_contact_social_img"
                  alt="linkedin icon"
                />
                <img
                  onClick={() =>
                    window.open("https://www.instagram.com/biryanibykilo")
                  }
                  src={instagramIcon}
                  className="footer_detail_c3_contact_social_img"
                  alt=""
                />
                <img
                  onClick={() =>
                    window.open("https://twitter.com/biryanibykilo")
                  }
                  src={twitterIcon}
                  className="footer_detail_c3_contact_social_img"
                  alt="twitter"
                />
                <img
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC2VWN7yypw0m6xyqaNwM0Fw"
                    )
                  }
                  src={youtubeIcon}
                  className="footer_detail_c3_contact_social_img"
                  alt="youtube"
                />
              </div>
            </div>
          </>
        )}
        <div className="footer_detail_buildVerion">
          <p className="footer_detail_buildVerion_number">
            V {process.env.REACT_APP_VERSION}
          </p>
        </div>
      </div>
      <div className="footer_divider" />
      <p className="footer_copyright">
        Copyright © {getCopyrightYear()} Sky Gate Hospitality PVT. LTD. All
        Rights Reserved.
      </p>
    </div>
  );
};
export default Footer;
