/* eslint-disable */
// import ReactGA from "react-ga";
import { LocalStorage } from '../utils/storage';
import { handleConvertTime, handleParseAmount, isAddonPresent } from './sharedFunction';

const outletDetails = LocalStorage.get('Outlet_Details');
const outletId = outletDetails && outletDetails.outlets_id;
const isLoggedIn = LocalStorage.get('isLoggedIn');
const locationDetails = LocalStorage.get('location');

const clientIds = [];
let gClientId = '';
try {
  window?.ga?.getAll()?.forEach((tracker) => {
    const id = tracker.get('clientId');
    if (id !== undefined) {
      clientIds.push(id);
    }
  });
  if (clientIds && clientIds?.length > 0) {
    gClientId = clientIds[0];
  }
} catch (error) {
  // console.log(error);
}

const setUserId = (value) => {
  try {
    window.dataLayer.push({
      userId: value,
    });
    window.dataLayer.push({
      dimension1: value,
    });
  } catch (ex) {}
};

const PageView = (currentScreen) => {
  try {
    if (currentScreen) {
      window.dataLayer.push({
        event: currentScreen,
      });
    }
  } catch (ex) {}
};

const logEvent = (data) => {
  try {
    window.dataLayer.push({
      event: data.eventName,
    });
  } catch (ex) {}
};

const logEventWithVariable = (data, action) => {
  try {
    if (data) {
      if (data.key) {
        let info = {
          event: data.eventName,
        };
        info[data.key] = action;
        window.dataLayer.push(info);
      } else {
        window.dataLayer.push({
          event: data.eventName,
        });
      }
    }
  } catch (ex) {}
};

const Checkout = (event, payload) => {
  try {
    window.dataLayer.push({
      event: event,
      checkout: payload,
    });
  } catch (ex) {}
};

const handleCheckedAddons = (addons)=>{
  const checkedAddon = []
  if (addons && addons.length && addons.length > 0) {
    addons.map((data) => {
      data.items.map((checkedItem) => {
        if (checkedItem.checked) {
         checkedAddon.push(checkedItem)
        }
      });
    });
  }
  return checkedAddon;
}

const addToCardEvent = (value , isRepeat ,addons) => {
  const userData = LocalStorage.get('UserData');

  const data = [
    {
      item_id: value.item_id,
      item_name: value.item_name,
      coupon: '',
      currency: 'INR',
      discount: 0,
      index: 0,
      item_brand: 'BBK',
      item_category: value.categoryName,
      item_list_name: value.categoryName,
      item_variant: '',
      location_id: outletId,
      price: Number(parseFloat(value.item_final_price).toFixed(2)),
      quantity: isRepeat ? 1 : parseInt(value.count)
    }
  ];

  const addonData = value.addons ?  value.addons : handleCheckedAddons(addons);
  if(addonData && addonData.length > 0){
    addonData.forEach((item)=>(
      data.push({...item , is_addon : true})
    ))
  } 

  try {
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        items : data
      },
     
      store_name: outletDetails?.name,
      store_city: outletDetails?.outlet__city,
      order_status: locationDetails?.orderFor,
      order_type: locationDetails?.orderTime,
      timeslot:
        locationDetails?.time && locationDetails?.time?.length > 0
          ? handleConvertTime(locationDetails?.time)
          : '',
      logged_in_status: isLoggedIn ? true : false,
      user_id: userData?._id,
      gclient_id: gClientId,
    });
  } catch (ex) {
    // console.log('exexex', ex);
  }
};

const purchaseEvent = (value) => {
   
  const userData = LocalStorage.get('UserData');

  const cartItemData = value?.items?.map((item) => {
    return {
      item_id: item.item_id,
      item_name: item.item_name,
      // affiliation: "",
      coupon: '',
      currency: 'INR',
      discount: 0,
      index: 0,
      item_brand: 'BBK',
      item_category: item?.categoryName ? item.categoryName : '',
      item_list_name: item?.categoryName ? item.categoryName : '',
      item_variant: '',
      location_id: outletId,
      price: Number(parseFloat(item.item_final_price).toFixed(2)),
      quantity: parseInt(item.count),
    };
  });

  try {
     
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: value.transaction_id,
        value: handleParseAmount(value.value),
        subtotal: handleParseAmount(value.subtotal),
        tax: handleParseAmount(value.tax) + handleParseAmount(value.tax),
        shipping: handleParseAmount(value.shipping),
        currency: value.currency,
        discount: handleParseAmount(value.discount),
        total_items: value.total_items,
        items: cartItemData,
        coupon: value.coupon,
      },
      store_name: outletDetails?.name,
      store_city: outletDetails?.outlet__city,
      // order_status:"",
      order_type: locationDetails.orderFor,
      logged_in_status: isLoggedIn ? true : false,
      user_id: userData?._id,
      gclient_id: gClientId,
      order_id: value.order_id,
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
};

const Order = {
  addToCart: {
    eventName: 'add_to_cart',
    key: 'add_to_cart',
  },
  login: {
    eventName: 'user_login',
    key: 'user_login',
  },

  productView: {
    eventName: 'product',
    key: 'product',
  },
  bannerClick: {
    eventName: 'banner_click',
    key: 'banner_click',
  },
  checkout: {
    eventName: 'purchase',
    key: 'purchase',
  },
  location: {
    eventName: 'current_location',
    key: 'current_location',
  },
  media: {
    eventName: 'hit_media',
    key: 'hit_media',
  },
};

const viewCartItemEvent = (value) => {
  const userData = LocalStorage.get('UserData');

  try {
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: value.item_id,
            item_name: value.item_name,
            coupon: '',
            currency: 'INR',
            discount: 0,
            index: 0,
            item_brand: 'BBK',
            item_category: value.categoryName,
            item_list_name: value.categoryName,
            item_variant: '',
            location_id: outletId,
            price: Number(parseFloat(value.item_final_price).toFixed(2)),
            quantity: parseInt(value.count),
          },
        ],
      },
      store_name: outletDetails?.name,
      store_city: outletDetails?.outlet__city,
      order_status: locationDetails?.orderFor,
      order_type: locationDetails?.orderTime,
      timeslot:
        locationDetails?.time && locationDetails?.time?.length > 0
          ? handleConvertTime(locationDetails?.time)
          : '',
      logged_in_status: isLoggedIn ? true : false,
      user_id: userData?._id,
      gclient_id: gClientId,
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
};

const removeFromCartEvent = (value) => {
  const userData = LocalStorage.get('UserData');
  try {
    window.dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        items: [
          {
            item_id: value.item_id,
            item_name: value.item_name,
            coupon: '',
            currency: 'INR',
            discount: 0,
            index: 0,
            item_brand: 'BBK',
            item_category: value.categoryName,
            item_list_name: value.categoryName,
            item_variant: '',
            location_id: outletId,
            price: Number(parseFloat(value.item_final_price).toFixed(2)),
            quantity: parseInt(1),
          },
        ],
      },
      store_name: outletDetails?.name,
      store_city: outletDetails?.outlet__city,
      order_status: locationDetails?.orderFor,
      order_type: locationDetails?.orderTime,
      timeslot:
        locationDetails?.time && locationDetails?.time?.length > 0
          ? handleConvertTime(locationDetails?.time)
          : '',
      logged_in_status: isLoggedIn ? true : false,
      user_id: userData?._id,
      gclient_id: gClientId,
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
};

const viewCartEvent = (items) => {
  const userData = LocalStorage.get('UserData');

  const cartItemData = formateCartData(items);

  try {
    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        items: cartItemData,
      },
      store_name: outletDetails?.name,
      store_city: outletDetails?.outlet__city,
      order_status: locationDetails?.orderFor,
      order_type: locationDetails?.orderTime,
      timeslot:
        locationDetails?.time && locationDetails?.time?.length > 0
          ? handleConvertTime(locationDetails?.time)
          : '',
      logged_in_status: isLoggedIn ? true : false,
      user_id: userData?._id,
      gclient_id: gClientId,
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
};


const beginCheckoutEvent = (items) => {
  const userData = LocalStorage.get('UserData');
  const cartItemData = formateCartData(items);
  try {
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        items: cartItemData,
      },
      store_name: outletDetails?.name,
      store_city: outletDetails?.outlet__city,
      order_status: locationDetails?.orderFor,
      order_type: locationDetails?.orderTime,
      timeslot:
        locationDetails?.time && locationDetails?.time?.length > 0
          ? handleConvertTime(locationDetails?.time)
          : '',
      logged_in_status: isLoggedIn ? true : false,
      user_id: userData?._id,
      gclient_id: gClientId,
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
};

const formateCartData = (items) => {
  const cartItemData = items?.map((item) => {
    return {
      item_id: item.item_id,
      item_name: item.item_name,
      // affiliation: "",
      coupon: '',
      currency: 'INR',
      discount: 0,
      index: 0,
      item_brand: 'BBK',
      item_category: item?.categoryName ? item.categoryName : '',
      item_list_name: item?.categoryName ? item.categoryName : '',
      item_variant: '',
      location_id: outletId,
      price: Number(parseFloat(item.item_final_price).toFixed(2)),
      quantity: parseInt(item.count),
    };
  });
  return cartItemData;
};

const applyLoyltyEvent = ()=>{
  try {
    window.dataLayer.push({
      event: "apply_loyalty",
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
}

const removeLoyltyEvent = ()=>{
  try {
    window.dataLayer.push({
      event: "remove_loyalty",
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
}

const viewOfferEvent = ()=>{
  try {
    window.dataLayer.push({
      event: "view_offers",
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
}

const applyOfferEvent = (value)=>{
  try {
    window.dataLayer.push({
      event: "apply_offer",
      offer_code:value.coupon_code
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
}


const shippingInfoEvent = (items) => {
  const userData = LocalStorage.get('UserData');
  const cartItemData = formateCartData(items);
  try {
    window.dataLayer.push({
      event: "add_shipping_info",
      ecommerce: {
        items: cartItemData,
      },
      store_name: outletDetails?.name,
      store_city: outletDetails?.outlet__city,
      order_status: locationDetails?.orderFor,
      order_type: locationDetails?.orderTime,
      timeslot:
        locationDetails?.time && locationDetails?.time?.length > 0
          ? handleConvertTime(locationDetails?.time)
          : '',
      logged_in_status: isLoggedIn ? true : false,
      user_id: userData?._id,
      gclient_id: gClientId,
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
};

const paymentInfoEvent = (items , type) => {
  const userData = LocalStorage.get('UserData');
  const cartItemData = formateCartData(items);
  try {
    window.dataLayer.push({
      event: "add_payment_info",
      ecommerce: {
        payment_type: type,
        items: cartItemData,
      },
      store_name: outletDetails?.name,
      store_city: outletDetails?.outlet__city,
      order_status: locationDetails?.orderFor,
      order_type: locationDetails?.orderTime,
      timeslot:
        locationDetails?.time && locationDetails?.time?.length > 0
          ? handleConvertTime(locationDetails?.time)
          : '',
      logged_in_status: isLoggedIn ? true : false,
      user_id: userData?._id,
      gclient_id: gClientId,
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
};

const locactionSelectionEvent = ()=>{
  try {
    window.dataLayer.push({
      event: "location_selection",
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
}

const loginEvent =(payload)=>{

  try {
    window.dataLayer.push({
      event: "login",
      logintype: 'Web',
      mobile : payload.mobile,
      user_id:payload.login_id
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
}

const signUpEvent =(payload)=>{
  try {
    window.dataLayer.push({
      event: "signup",
      logintype: 'Web',
      email: payload.email,
      NAME: payload.name,
      user_id:payload.login_id
    });
  } catch (ex) {
    console.log('exexex', ex);
  }
}

export const Analytics = {
  logEvent,
  logEventWithVariable,
  PageView,
  Order,
  Checkout,
  addToCardEvent,
  purchaseEvent,
  viewCartItemEvent,
  removeFromCartEvent,
  viewCartEvent,
  beginCheckoutEvent,
  applyLoyltyEvent,
  removeLoyltyEvent,
  viewOfferEvent,
  applyOfferEvent,
  shippingInfoEvent,
  paymentInfoEvent,
  locactionSelectionEvent,
  loginEvent,
  signUpEvent
};
