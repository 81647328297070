import React, { useState, useEffect } from "react";
import Switch from "../../components/switch";
import Logo from "../../assets/images/logo.svg";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
import "./index.scss";
import moment from "moment";
import { LocalStorage } from "../../utils/storage";
import RadioButton from "../../components/radioButton";
import SearchAutoComplete from "../searchAutocomplete";
import Button from "../../components/button";
import Detect from "../../assets/images/detectLoc.svg";
import LocationSearch from "../../assets/images/homeLocation.svg";
import remove from "../../assets/images/close.svg";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { useHistory, useLocation } from "react-router-dom";
import {
  OutletMapping,
  getMenuList,
  getSkipLocation,
  handleSaveInterest,
  getSavedAddress,
} from "../../actions/home";
import {
  getAvailableAddress,
  addAddressNew,
  getAddress,
  editAddress,
  deleteAddress,
} from "../../actions/location";

import AlertPopup from "../../components/alert-popup";

import StoreCard from "../../components/store-card";
import { ROUTES } from "../../constants/app-constants";
import Dexie from "dexie";
import LocationImage from "../../assets/images/Web-Location-page-web.jpg";
import Locationinterested from "../../components/location-interested-card";
import IntervalDrop from "../../components/interval-dropdown";
import Loader from "../../components/Loader";
import LocationImg from "../../assets/images/locationIcon.svg";
import Toaster from "../../components/toaster";
import { handleParseAmount } from "../../helpers/sharedFunction";
import SavedAddress from "../../components/saved-address";
import RenderMaps from "../../containers/MapsCommonApi";
import back from "../../assets/images/goback.svg";
import Modal from "../../components/modal";
import AddAddress from "../add-address";
import AddAddressCard from "../../components/add-address-card";
import AddressCard from "../../components/address-card";

const switchData = [
  { id: "delivery", name: "Delivery" },
  { id: "takeaway", name: "Takeaway" },
];

const switchResponsiveData = [
  { id: "delivery", name: "Delivery" },
  { id: "takeaway", name: "Pick-up" },
];

const radio = [
  { id: "orderNow", name: "Order Now", checked: true },
  { id: "orderLater", name: "Order Later", checked: false },
];

const responsiveRadio = [
  { id: "orderNow", name: "Now", checked: true },
  { id: "orderLater", name: "Later", checked: false },
];

const DeliveryContainer = (props) => {
  const urlparams = useLocation();
  let takeaway = urlparams.pathname.split("/")[1];
  console.log("container", takeaway);
  const [active, setActive] = useState(
    takeaway == "takeaway" ? "takeaway" : "delivery"
  );
  const [radioData, setRadioData] = useState(radio);
  const [responsiveRadioData, setResponsiveRadioData] =
    useState(responsiveRadio);
  const [activeOrder, setActiveOrder] = useState("orderNow");
  const [inputValue, setInputvalue] = useState({});
  const [popup, setPopup] = useState(false);
  const [inputData, setInputData] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [latLng, setLatLng] = useState({ lat: "", lng: "" });
  const [errorData, setErrorData] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [location, setLocation] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [data, setData] = useState([]);
  const [errorData1, setErrorData1] = useState(false);
  const [countData, setCountData] = useState({});
  const [alertData, setAlertData] = useState(false);
  const [dateData, setdateData] = useState(false);
  const [timeData, settimeData] = useState(false);
  const [intervalModal, setIntervalModal] = useState(false);
  const [intDate, setIntDate] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [address, setAddress] = useState("");
  const [availableAddresses, setAvailableAddresses] = useState([]);
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  const [savedAddress, setSavedAddress] = useState();
  const [modal, setModal] = useState(false);
  const [interval, setInterval] = useState("");
  const [outletLoader, setOutletLoader] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [mapData, setMapData] = useState({});
  const [addressForm, setAddressForm] = useState(false);
  const [othersTxt, setOthersTxt] = useState("");
  let timeInterval = [];
  const urlParams = useLocation();
  const history = useHistory();
  const urlValue = urlParams.pathname.split("/")[3];
  const urlCity = urlParams.pathname.split("/")[2];
  const [canShowLater, setCanShowLater] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressListCpy, setAddressListCpy] = useState(null);
  const [localAddressList, setLocalAddressList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addressList, setAddressList] = useState({
    delivered: [],
    not_delivered: [],
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [editAddData, setEditAddData] = useState({});
  const [editStat, setEditStat] = useState(false);
  const [addressData, setAddressData] = useState([]);

  useEffect(() => {
    getSavedAddress((res) => {
      if (res?.response?.status == 200) {
        setSavedAddress(res.content.address);
        console.log("add first", savedAddress);
      }
    });

    if (latLng.lat === "" && latLng.lng === "") {
      setLocation(false);
      // onCurrentLocation();
    }
    let locationDetails = LocalStorage.get("location");
    if (locationDetails) {
      setLocation(true);
      setLatLng({
        lat: locationDetails && locationDetails.lat,
        lng: locationDetails && locationDetails.lng,
      });
      setActiveOrder(locationDetails && locationDetails.orderTime);

      const updateOrder = responsiveRadioData.map((item) => {
        item.checked = false;
        if (item.id === locationDetails.orderTime) {
          item.checked = true;
        }
        return item;
      });
      setResponsiveRadioData(updateOrder);

      setInputData(locationDetails && locationDetails.address);
    }
    handleGetAddress();
    // getDeliveryAddress();
  }, []);

  // console.log("savedAddress", savedAddress && savedAddress.length);
  const onChangeOrder = (value) => {
    if (latLng.lat !== "") {
      const coordinate = getCurrentLocation();
      setLatLng(coordinate);
    }
    setActiveOrder(value.id);
    if (innerWidth <= 1023) {
      const updateOrder = responsiveRadioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setResponsiveRadioData(updateOrder);
    } else {
      const updateOrder = radioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setRadioData(updateOrder);
    }
  };

  const getCurrentLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (
              position &&
              position.coords &&
              position.coords.latitude &&
              position.coords.longitude
            ) {
              const coordinates = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              resolve(coordinates);
            }
            // eslint-disable-next-line no-unused-vars
          },
          (_error) => {
            reject(_error);
            onMapClose(true);
            // alert("Unable to fetch the location. Please enter manually");
          }
        );
      } else {
        onMapClose(true);
        // alert("Unable to fetch the location. Please enter manually");
      }
    });
  };

  const onChangeMode = async (value) => {
    setActive(value.id);
    if (value.id !== "delivery") {
      let coordinates = latLng;
      const locationDetails = LocalStorage.get("location");
      if (locationDetails.lat != "" && locationDetails.lng != "") {
        coordinates = { lat: locationDetails.lat, lng: locationDetails.lng };
      } else if (coordinates.lat === "" && coordinates.lng === "") {
        coordinates = await getCurrentLocation();
      }
      onConfirmLocation(coordinates, value.id);
    } else {
      setOutlets([]);
    }
  };

  const onCurrentLocations = () => {
    setAlertData(false);
    onCurrentLocation();
  };

  const onCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    const locationDetails = {
                      location: results[0].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                    };
                    setInputvalue(locationDetails);
                    setLatLng({ lat: json.lat, lng: json.lng });
                    onMarkerPosition({ lat: json.lat, lng: json.lng });
                    setLocation(true);
                    setErrorData(false);
                    setInputData(results[0].formatted_address);
                    if (active === "delivery") {
                      setPopup(true);
                    } else {
                      onConfirmLocation(locationDetails);
                    }
                  }
                }
              },
              this
            );
          }
          // eslint-disable-next-line no-unused-vars
        },
        (_error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  const onFindFood = (text, value, address, ltln, addressLayout) => {
    setLatLng(ltln);
    setInputData(address);
    if (text == "savedaddress") {
      setInputData(value);
      const dataValue = {
        orderFor: active,
        orderTime: activeOrder,
        deliveryTime: data.delivery_area_delivery_time,
        address: value.Address1,
        addressType: value.AddressType,
        date: date,
        time: time,
        lat: latLng.lat,
        lng: latLng.lng,
      };
      LocalStorage.set("Previouslocation", dataValue);
      LocalStorage.set("outlet_id", value.UnitId);
    }
    // if (activeOrder === "orderLater") {
    //   if (date?.length === 0) {
    //     setToaster({
    //       toastType: true,
    //       toastText: "Date is required",
    //       isShow: true,
    //     });
    //     return;
    //   }
    //   if (time?.length === 0) {
    //     setToaster({
    //       toastType: true,
    //       toastText: "Time is required",
    //       isShow: true,
    //     });
    //     return;
    //   }
    // }

    if (
      (active === "delivery" || active === "takeaway") &&
      (address || inputData || (value && value.Address1))
    ) {
      onConfirmLocation(addressLayout, active, address);
      // setPopup(true);
    } else {
      setAlertData(true);
      // onConfirmLocation();
    }
  };

  const onChangeDate = (value) => {
    if (!value || value < new Date()) {
      setDate("");
      return;
    }
    // if (!value) {
    //   setDate("");
    //   return;
    // }
    let gotTime = value.getDate();
    setdateData(false);
    setDate(value);
    let dateHour = new Date();
    let hours = dateHour.getHours();
    const outeletDetailsData = LocalStorage.get("Outlet_Details");
    let opentime =
      outeletDetailsData.open_time &&
      outeletDetailsData?.open_time.substring(0, 2);
    let closetime =
      outeletDetailsData.close_time &&
      outeletDetailsData?.close_time.substring(0, 2);
    const calcTime = moment().add(1.5, "hours").format("HH:mm");
    const splitTime = calcTime.split(":");
    const totalHours =
      handleParseAmount(splitTime[0]) + handleParseAmount(splitTime[1]) / 60;
    if (gotTime === new Date().getDate()) {
      for (let i = Math.round(totalHours); i < closetime; i++) {
        timeInterval.push({
          id: i + ":00",
          name: `${moment(i, "HH").format("hh A")} - ${moment(
            i + 1,
            "HH"
          ).format("hh A")}`,
        });
      }
    } else {
      for (let i = Math.round(opentime); i < closetime; i++) {
        timeInterval.push({
          id: i + ":00",
          name: `${moment(i, "HH").format("hh A")} - ${moment(
            i + 1,
            "HH"
          ).format("hh A")}`,
        });
      }
    }
    setIntDate(timeInterval);
  };

  const handleGetAddress = () => {
    getAddress((res) => {
      if (res && res?.response?.status === 200) {
        setAddressData(res?.content?.address);
      }
    });
  };

  const onMarkerPosition = (value) => {
    LocalStorage.remove("Previouslocation");
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng: value },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            var formattedAddress = results[0].formatted_address;
            for (let i = 0; i < results.length; i++) {
              if (results[i]?.geometry?.location_type === "APPROXIMATE") {
                // console.log('resultsresults', results[i].geometry)
                formattedAddress = results[i].formatted_address;
                break;
              }
            }
            // eslint-disable-next-line no-lone-blocks
            setInputvalue({
              location: results[0].formatted_address,
              pincode,
              latitude: value.lat,
              longitude: value.lng,
            });
            setLatLng({ lat: value.lat, lng: value.lng });
            setInputData(formattedAddress);
          }
        }
      },
      this
    );
  };
  const handleAddressForm = () => {
    setAddressForm((prevState) => !prevState);
  };
  // const handleMap = () => {
  //   setShowMap((prevState) => !prevState);
  // };
  const onClickAddress = (
    locationDetails,
    type = active,
    address = inputData,
    preorderData = false,
    addressDatavalue
  ) => {
    // debugger;
    setPopup(false);
    LocalStorage.set("preorder", preorderData);
    let payload = {
      latitude: latLng.lat || locationDetails.lat,
      longitude: latLng.lng || locationDetails.lng,
      is_delivery: type === "delivery" ? true : false,
      is_order_now:
        preorderData && preorderData
          ? false
          : true || activeOrder === "orderNow"
          ? true
          : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };
    if (locationDetails) {
      payload.latitude = locationDetails.guest_lat
        ? locationDetails.guest_lat
        : locationDetails.lat;
      payload.longitude = locationDetails.guest_long
        ? locationDetails.guest_long
        : locationDetails.lng;
      setLatLng({ lat: locationDetails.lat, lng: locationDetails.lng });
      setInputData(address);
    }

    OutletMapping(payload, (res) => {
      // debugger;
      if (res && res?.response?.status === 200) {
        setErrorData(false);
        setErrorData1(false);
        if (res && res?.content && res?.content?.areas) {
          setOutlets(res.content.areas);
          // setData(res.content.areas[0]);
          // LocalStorage.set('DeliveryAreaPoint',res.content.areas)
          if (type === "delivery" && (locationDetails || latLng)) {
            // debugger;
            onClickStore(
              res?.content?.areas[0],
              type === "takeaway",
              address,
              {
                lat: locationDetails.guest_lat || locationDetails.lat || latLng.lat,
                lng: locationDetails.guest_long ||locationDetails.lng || latLng.lng,
              },
              addressDatavalue,
              {
                addressType : locationDetails.AddressType,
                addressLine : locationDetails.Address1,
              }
              
            );
          }
          setAlertData(false);
        }
        setData([]);
      } else {
        setOutlets([]);
        setErrorData(true);
        setAlertPopup(res?.response?.status ? res?.response?.message : "");
        if (type === "delivery") {
          payload = {
            latitude: latLng.lat,
            longitude: latLng.lng,
            is_delivery: false,
            is_order_now: activeOrder === "orderNow" ? true : false,
            order_date:
              activeOrder !== "orderNow" && date !== ""
                ? moment(date).format("YYYY-MM-DD")
                : "",
            order_time: activeOrder !== "orderNow" ? time : "",
            channel: "online_web",
          };
          OutletMapping(payload, (res) => {
            if (res && res.response && res.response.status === 200) {
              if (
                res.content &&
                res.content.areas &&
                res.content.areas.length > 0
              ) {
                setErrorData1(true);
                setCountData({
                  count: res.content.areas.length,
                  distance: parseFloat(
                    res.content.max_distance_user_to_restaurant
                  ).toFixed(2),
                });
                setData(res.content.areas);
              }
            } else {
              setErrorData(true);
              setCountData({});
              setData([]);
            }
          });
        } else {
          setErrorData1(false);
          setCountData({});
          setData([]);
        }
      }
    });
  };

  const onConfirmLocation = (location, active, address) => {
    // debugger;
    setMapData((prevState) => ({
      ...prevState,
      // Address1: address,
      Google_Address: location.formatted_address? location.formatted_address : address,
      guest_lat: Number(location.lat),
      guest_long: Number(location.lng),
      city: location.city,
      Pin: location.pincode,
      locality: location.locality,
    }));
    setAddress(address);
    const dataValue = {
      orderFor: active,
      orderTime: activeOrder,
      deliveryTime: data.delivery_area_delivery_time,
      address: address,
      date: date,
      time: time,
      lat: Number(location.lat),
      lng: Number(location.lng),
    };
    LocalStorage.set("location", dataValue);
    console.log(mapData, "mapdata");
    // handleAddressForm();
    handleMap();
  };
  const handleAddressChange = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      Address1: val,
    }));
    setAddress(val);
  };

  const handleReachChange = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      Address2: val,
    }));
  };

  const handleAltNumberChange = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      altNumber: val,
    }));
  };

  const handleAddressType = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      AddressType: val,
    }));
  };
  const handleLocationOthers = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      selectedOthers: val,
    }));
  };

  console.log("alert check", alertPopup);
  const onChangeSearch = (value) => {
    setSavedAddress("");
    setAlertData(false);
    setLocation(true);
    setDate("");
    setInputData(value.location);
    setInputvalue(value);
  };

  const onStoreSelect = () => {
    setOutlets(data);
    setErrorData1(false);
    setData([]);
    setErrorData(false);
    setActive("takeaway");
  };
  const [errorMsg, setErrorMsg] = useState("");
  const onClickStore = (
    data,
    takeaway,
    address,
    location = latLng,
    oldAddressData,
    addressType,
  ) => {
    // debugger;
    if (data && data?.is_open) {
      setOutletLoader(true);
      LocalStorage.set("Outlet_Details", data);
      getMenuList(
        urlValue &&
          urlValue?.length > 0 &&
          data?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
          ? urlValue
          : data?.outlets_id,
        (res) => {
          if (res && res.content && res.response.status === 200) {
            if (res.content) {
              if (LocalStorage.get("outlet_id")) {
                const oldOutlet = LocalStorage.get("outlet_id");
                if (oldOutlet !== res.content.menu.unit_id) {
                  if (LocalStorage.get("isLoggedIn")) {
                    onMenuDataSync(res.content.menu.categories);
                  } else {
                    LocalStorage.set("CartProducts", []);
                  }
                }
              }
              LocalStorage.set("outlet_id", data?.outlets_id);
              const dataValue = {
                orderFor: active,
                orderTime: activeOrder,
                deliveryTime: data.delivery_area_delivery_time,
                address: address || data.address,
                date: date,
                time: time,
                lat: location.lat,
                lng: location.lng,
                addressType : addressType.addressType,
                address1 : addressType.addressLine,
              };
              LocalStorage.set("location", dataValue);

              const db = new Dexie(res.content.menu.unit_id);
              db.version(1).stores({
                menu: "++id, categories, item_tags, taxes",
              });
              const getMenuData = async () => {
                const allData = (await db.menu.toArray()) || [];
                if (allData && allData.length > 0) {
                  await db.menu.update(0, res.content.menu);
                } else {
                  await db.menu.add(res.content.menu);
                }
                history.push(ROUTES.HOME);
                window.location.reload();
              };
              getMenuData();
            }
          } else {
            setOutletLoader(false);
            setAlertPopup(
              "Sorry!! No Data Found. Please try again later sometime"
            );
          }
        }
      );
    } else {
      // debugger;
      if (
        oldAddressData &&
        data &&
        data.outlet_status_info.web_err_msg !==
          "Sorry, the outlet is not operational now, but you can pre-order."
      ) {
        LocalStorage.set("location", oldAddressData);
      }
      setAlertPopup(
        data && data.outlet_status_info.web_err_msg
          ? data.outlet_status_info.web_err_msg
          : "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
      setErrorMsg(
        data && data.outlet_status_info.web_err_msg
          ? data.outlet_status_info.web_err_msg
          : "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
    }
  };
  const hanldeDeleteModal = () => {
    setDeleteModal((prevState) => !prevState);
  };
  const deleteCard = (item) => {
    hanldeDeleteModal(item);
    setDeleteItem(item);
  };
  const onMenuDataSync = (data) => {
    const outletData = LocalStorage.get("CartProducts");
    const updateitem = data.map((items) => {
      let updateItem = [];
      if (items.has_subcategory === "0") {
        updateItem = items.items;
      }
      if (items.has_subcategory === "1") {
        items.subcategories.map((item) => {
          item.items.map((value) => {
            updateItem.push(value);
          });
        });
        items.items = updateItem;
      }
      items.itemData = updateItem.map((item) => {
        item.checked = false;
        item.count = 0;
        if (LocalStorage.get("CartProducts")) {
          const productData = LocalStorage.get("CartProducts");
          if (productData && productData.length > 0) {
            productData.map((items) => {
              if (items.item_id === item.item_id) {
                item.count = items.count;
              }
            });
          }
        }
        return item;
      });
      items.vegData = updateItem.filter((item) => item.item_tags.includes("1"));
      items.nonVeg = updateItem.filter((item) => item.item_tags.includes("2"));
      return items;
    });
    const updateItemData = [];
    updateitem.map((items) => {
      items.itemData.map((item) => {
        updateItemData.push(item);
      });
    });
    const datavalue = updateItemData.filter((itms) => {
      return outletData.includes(itms.item_id);
    });
  };
  const { innerWidth } = window;

  const setLocationData = () => {
    let locationDetails =
      LocalStorage.get("location") || LocalStorage.get("Outlet_Details");

    if (locationDetails) {
      setMapData((prevState) => ({
        Google_Address: locationDetails.address,
        guest_lat: Number(locationDetails.lat),
        guest_long: Number(locationDetails.lng),
      }));
    }
  };
  const handleGoBack = () => {
    history.goBack();
  };
  const handleModal = () => {
    if (addressForm) {
      setLocationData();
      handleAddressForm();
      return;
    }
    // handleListAddressModal();
  };

  const addressChange = () => {
    handleAddressForm();
    handleMap();
  };

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const AddressModal = () => {
    return (
      isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <img
              src={remove}
              className="deliveryContainer_right_removeImg"
              onClick={() => closeModal()}
              alt="remove"
            />
            <h1 className="savedAddress">Previous Saved Addresses</h1>
            <SavedAddress data={savedAddress} onClick={onFindFood} />
          </div>
        </div>
      )
    );
  };
  const onMapClose = (val) => {
    setErrorData(true);
    // setAlertPopup("We are unable to fetch your location.Please allow the site to access the location in browser settings.");
  };
  const callOutlet = (ltln, val, address) => {
    //
    if (val) {
      setInputData(val);
    }
    let payload = {
      // latitude: ltln.lat,
      // longitude: ltln.lng,
      latitude: address.lat,
      longitude: address.lng,
      is_delivery: active === "delivery" ? true : false,
      is_order_now: true,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };
    //
    OutletMapping(payload, (res) => {
      if (res && res?.response?.status === 200) {
        LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
        setCanShowLater(true);
        handleAddressForm();
      } else {
        console.log("alert res call outlet");
        setErrorData(true);
        setAlertPopup(res?.response?.status ? res?.response?.message : "");
      }
      // setInputData(val);
      // onFindFood("", "", val, ltln, address);
    });
  };

  const selectSavedAddress = () => {
    return (
      active == "delivery" &&
      savedAddress &&
      savedAddress.length > 0 && (
        <>
          <Button
            content="Show Saved Addresses"
            className="map-search_button1"
            onClick={openModal}
            customClass="map-search_button_content"
          />
          <AddressModal />
          {/* <div className="savedAddress__divider"></div> */}
        </>
      )
    );
  };
  const handleEditAddress = (item) => {
    setEditStat(true);
    setMapData({
      Google_Address: !item.Google_Address
        ? item.Address1
        : item.Google_Address,
      ...item,
      guest_lat: Number(item.guest_lat),
      guest_long: Number(item.guest_long),
    });
    setEditAddData(item);
    // setEditStat(true);
    // setEditAddData(item);

    handleAddressForm();
  };
  const handleSelectedAddress = (data) => {
    let addressDatavalue = LocalStorage.get("location");
    const updatedAddress = {
      ...addressDatavalue,
      address: `${
        data?.Google_Address && data?.Google_Address !== "N/A"
          ? data.Google_Address
          : data.Address1
      }`,
      // address: `${data.Address1} ${data.Address2} ${data.reach ? data.reach :''}`,
      // lat: Number(data.guest_lat),
      // lng: Number(data.guest_long),
    };
    setSelectedAddress(data);
    setAddress(data.Google_Address ? data.Google_Address : data.Address1);
    LocalStorage.set("location", updatedAddress);
    // handleListAddressModal();

    const checkoutAddressNetCorePayload = {
      address: data.Address1,
    };
    onClickAddress(data, active, data.Locality, false, addressDatavalue);

  };

  const successCallback = (position) => {
    console.log(position);
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  const onLoginClick = () => {
    // debugger;
    history.push({
      pathname: ROUTES.LOGIN,
      // state: { redirectToCheckOut: true },
    });
    // debugger;
  };
  const handleMap = () => {
    // setOutletLoader(true);
    if (LocalStorage.get("isLoggedIn")) {
      setShowMap((prevState) => !prevState);
    } else {
      onLoginClick();
    }
  };
  const getDeliveryAddress = () => {
    setIsLoading(true);
    const outletId = LocalStorage.get("outlet_id");
    const delivery_area_id =
      LocalStorage.get("Outlet_Details").delivery_area_id;
    const queryParams = `?is_delivery=True&outlet_id=${outletId}&delivery_area_id=${delivery_area_id}`;
    getAvailableAddress((res) => {
      if (res && res.response && res.response.status === 200) {
        // if (res.content.address_available.length > 0) {
        setAddressListCpy(res.content.address_available);
        handleAddressListRes(res.content.address_available, localAddressList);
        setIsLoading(false);
        // }
      } else {
        handleAddressListRes([], localAddressList);
        setIsLoading(false);
      }
    }, queryParams);
  };

  const handleAddressListRes = (address = [], localAddress) => {
    let addressCpy = [];
    if (localAddress && localAddress.length > 0) {
      addressCpy = [...address, ...localAddress];
    } else {
      addressCpy = [...address];
    }
    let filteredAddress = {
      delivered: [],
      not_delivered: [],
    };

    addressCpy &&
      addressCpy.forEach((element) => {
        if (element.is_available) {
          filteredAddress.delivered.push(element);
        } else {
          filteredAddress.not_delivered.push(element);
        }
      });
    setAddressList({ ...filteredAddress });
  };

  const handleConfirmAddress = (isAvailble) => {
    const locationArr = mapData.Google_Address;
    let payload = {
      address_id: "0",
      city: mapData.city ? mapData.city : locationArr[locationArr.length - 3],
      pin_code: mapData.Pin ? mapData.Pin : locationArr[locationArr.length - 2],
      AddressType: mapData.AddressType,
      location_name: mapData.Google_Address
        ? mapData.Google_Address
        : locationArr[locationArr.length - 5] +
          locationArr[locationArr.length - 4],
      address_1: mapData.Address1,
      address_2: mapData.Address2,
      latitude: mapData.guest_lat,
      longitude: mapData.guest_long,
      Google_Address: locationArr,
    };

    if (mapData.AddressId || mapData.is_local_address) {
      payload.address_id = mapData.AddressId;
      if (mapData.is_local_address) {
        payload.is_local_address = mapData.is_local_address;
        payload.id = mapData.id;
      }
      handleEdit(payload);
    } else {
      const checkoutAddressNetCorePayload = {
        address: locationArr,
      };
      // checkoutAddressNetCoreEvent(checkoutAddressNetCorePayload);
      console.log(mapData, "mp");
      handleAddAddress(mapData, isAvailble);
    }
  };

  const handleAddAddress = (add, isAvailble) => {
    const queryParams = `/lat${add.latitude}&&long${add.longitude}`;

    // getIsdeliveredToAddress((res) => {
    // if (res && res.response && res.response.status === 200) {
    // add.is_available = res.response.is_available
    add.is_available = isAvailble;
    add.address_id = "0";
    add.is_local_address = true;
    add.id = localAddressList.length;

    setLocalAddressList((prevState) => [...prevState, add]);

    if (isAvailble) {
      setAddressList((prevState) => ({
        ...prevState,
        delivered: [...prevState["delivered"], add],
      }));
      console.log(addressList, "check list");
      let addressData = LocalStorage.get("location");
      const updatedAddress = {
        ...addressData,
        address: `${add.Google_Address}`,
        // address: `${add.Address1} ${add.Address2} ${add.reach ? add.reach :''}`,
        lat: Number(add.guest_lat),
        lng: Number(add.guest_long),
      };
      setSelectedAddress(add);
      setAddress(add.Google_Address);
      console.log(savedAddress, selectedAddress, "add sssssssss");
      setSavedAddress((prevState) => [...prevState, add]);
      console.log("check saved", savedAddress);
      LocalStorage.set("location", updatedAddress);
    } else {
      setAddressList((prevState) => ({
        ...prevState,
        not_delivered: [...prevState["not_delivered"], add],
      }));
    }

    handleAddressForm();
  };

  const handleRefreshList = () => {
    setTimeout(() => {
      getDeliveryAddress();
    }, 1000);
  };

  const handleEdit = (payload) => {
    if (payload.is_local_address) {
      const listAddresCpy = [...localAddressList];
      const findIndex = localAddressList.findIndex(
        (obj) => obj.id === payload.id
      );
      listAddresCpy[findIndex] = mapData;
      setLocalAddressList(listAddresCpy);
      handleAddressListRes(addressListCpy ? addressListCpy : [], listAddresCpy);
      handleAddressForm();
    } else {
      setIsLoading(true);

      editAddress(payload, (res) => {
        if (res && res?.response?.status === 200) {
          setLocationData();
          setIsLoading(false);
          setToaster({
            toastType: true,
            toastText: res.response.message,
            isShow: true,
          });
          // handleRefreshList();
          handleAddressForm();
          handleGetAddress();
        } else {
          setIsLoading(false);
          setToaster({
            toastType: false,
            toastText: res.response.message,
            isShow: true,
          });
        }
      });
    }
  };

  const submit = () => {
    let payload;
    const outletId = LocalStorage.get("Outlet_Details").outlets_id;
    LocalStorage.set("outlet_id", outletId);
    const delivery_area_id =
      LocalStorage.get("Outlet_Details").delivery_area_id;
    const queryParams = `?is_delivery=True&outlet_id=${outletId}&lat=${mapData.guest_lat}&long=${mapData.guest_long}&delivery_area_id=${delivery_area_id}`;
   console.log(mapData,"mapData")

    if (editStat === false) {
      if (address !== "") {
        payload = {
          address_id: "0",
          city: mapData.city,
          pin_code: mapData.Pin,
          AddressType: mapData.AddressType,
          location_name: address,
          address_1: mapData.Address1,
          address_2: mapData?.Address2 ? mapData.Address2 : address,
          latitude: mapData.guest_lat,
          longitude: mapData.guest_long,
          Google_Address:mapData.Google_Address,
        };
        addAddressNew(payload, (res) => {
          if (res && res?.response?.status === 200) {
            console.log(res, "add address post api");
            setTimeout(() => {
              handleGetAddress();
            }, 1000);

            handleAddressForm();
          }
        });
      }
    } //end of if
    else {
      console.log("edit data", editAddData);
      console.log(savedAddress, "addressfinal list");
      payload = {
        address_id: editAddData?.AddressId,
        city: editAddData?.City,
        pin_code: mapData.Pin || editAddData?.Pin,
        AddressType: mapData.AddressType || editAddData?.AddressType,
        location_name: editAddData?.Locality,
        address_1: mapData?.Address1 || address,
        address_2: mapData?.Address2 || editAddData?.Address2,
        latitude: editAddData?.guest_lat,
        longitude: editAddData?.guest_long,
        Google_Address: address,
      };
      handleEdit(payload);
    }
  };

  const handleDeleteItem = () => {
    setIsLoading(true);
    let payload = {};
    payload = {
      address_id: deleteItem?.AddressId,
      city: deleteItem?.City,
      pin_code: deleteItem?.Pin,
      AddressType: [deleteItem?.AddressType],
      location_name: deleteItem?.Locality,
      address_1: deleteItem?.Address1,
      address_2: deleteItem?.Address2,
      latitude: deleteItem?.guest_lat,
      longitude: deleteItem?.guest_long,
    };
    deleteAddress(payload, (res) => {
      if (res && res?.response?.status === 200) {
        //   setNavStat('2');
        setIsLoading(false);
        setDeleteModal(false);
        setToaster({
          toastType: true,
          toastText: res.response.message,
          isShow: true,
        });
        setTimeout(() => {
          handleGetAddress();
        }, 1000);
      } else {
        setIsLoading(false);
        setDeleteModal(false);
        setToaster({
          toastType: false,
          toastText: res.response.message,
          isShow: true,
        });
      }
    });
  };
  const onCloseAlert = () => {
    // debugger;
    if (
      errorMsg &&
      errorMsg ===
        "Sorry, the outlet is not operational now, but you can pre-order."
    ) {
      // debugger;
      setAlertPopup("");
      onClickAddress(LocalStorage.get("location"), active, data.Locality, true);
    } else {
      setAlertPopup("");
    }
  };

  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };
  return (
    <>
      {console.log("activeeecontainer", active)}
      {innerWidth <= 1023 ? (
        <>
          <div className="wallet_header">
            <div className="myOrders_section">
              <img
                src={back}
                className="myOrders_section_backimg"
                onClick={handleGoBack}
                alt=""
              />
              <h3 className="myOrders_section_heading">Address Selection</h3>
            </div>
          </div>
          <div className="saved_address">
            <p className="saved_address_heading ">Saved Addresses</p>
            <div className="list-address__add-address-btn-sticky">
              <Button
                className="list-address__add-address-btn"
                content={"+ Add Address"}
                onClick={handleMap}
                customClass="list-address_btncontent"
                isLoading={outletLoader}
              />
            </div>
          </div>

          <div className="list-address__list">
            {console.log(savedAddress, "addlist")}
            {console.log(editAddData, "editaddress")}

            <AddressCard
              handleSelectAddress={handleSelectedAddress}
              address={addressData}
              selectedAddress={selectedAddress}
              handleEditAddress={handleEditAddress}
              deleteCard={deleteCard}
              deliveryAddress={true}
            />
          </div>
          {addressForm && (
            <>
              <Modal
                onCloseHandler={() => handleModal()}
                hideClose={innerWidth <= 1023 ? true : false}
                modalCustomClass={`list-address-modal ${
                  addressForm ? "enter-address-details" : ""
                }`}
              >
                <AddAddress
                  loc2={mapData.Google_Address}
                  setAddress={handleAddressChange}
                  address={mapData.Address1 ? mapData.Address1 : ""}
                  setReach={handleReachChange}
                  reach={mapData.Address2 ? mapData.Address2 : ""}
                  setAlternateNumber={handleAltNumberChange}
                  alternateNumber={mapData.altNumber ? mapData.altNumber : ""}
                  setOthersTxt={handleAddressType}
                  othersTxt={mapData.AddressType ? mapData.AddressType : ""}
                  setSelectedAD={handleLocationOthers}
                  submit={submit}
                  addressChange={addressChange}
                  logStatus={
                    mapData.AddressId || mapData.is_local_address ? true : false
                  }
                  customPopUp={true}
                  isHideOrderType={true}
                  // deliveryText={mapData.location}
                  deliverycontainer={true}
                />
              </Modal>
            </>
          )}
          {showMap && (
            <div className="mapModal">
              <Modal
                onCloseHandler={() => handleModal()}
                hideClose={innerWidth <= 1023 ? true : false}
                modalCustomClass="mapModal_map"
                contentCustomClass="mapModal_map_content"
              >
                <div className="mapaddress_header">
                  <div className="myOrders_section">
                    <img
                      src={back}
                      className="myOrders_section_backimg"
                      onClick={handleGoBack}
                      alt=""
                    />
                    <h3 className="myOrders_section_heading">
                      Change Location
                    </h3>
                  </div>
                </div>
                <RenderMaps
                  callOutlet={callOutlet}
                  onConfirmLocation={onConfirmLocation}
                  latLng={latLng}
                  activeTheme={active}
                  // selectSavedAddress={selectSavedAddress}
                  onMapClose={onMapClose}
                  loader={outletLoader}
                  stylechange
                />
              </Modal>
            </div>
          )}
          {alertPopup && (
            <AlertPopup
              title="Sorry!!"
              description={alertPopup}
              okayClick={() => onCloseAlert()}
              alertCart={true}
            />
          )}
          {deleteModal && (
            <AlertPopup
              title={`Delete !`}
              description={`Are you sure you want to delete?`}
              okayClick={() => handleDeleteItem()}
              close={() => hanldeDeleteModal()}
              alertCart
              logout={true}
              isLoading={isLoading}
            />
          )}
          {toaster.isShow && (
            <Toaster closeToast={closeToast} toastText={toaster.toastText} />
          )}
        </>
      ) : (
        <>
          <div className="deliveryContainer">
            <div className="deliveryContainer_left">
              <img
                src={LocationImage}
                alt="img"
                height="100%"
                width="100%"
                className="deliveryContainer_left_img"
              />
            </div>
            <div className="deliveryContainer_right">
              <img src={Logo} width="157" height="157" alt="logo" />
              <h2 className="deliveryContainer_heading">
                Delivering a Royal Feast to your doorsteps!
              </h2>
              <div className="deliveryContainer_switches">
                <Switch
                  data={switchData}
                  active={active}
                  onFilter={onChangeMode}
                />
                <div className="deliveryContainer_switches_gap" />
                <RadioButton data={radioData} onChange={onChangeOrder} />
              </div>
              {activeOrder === "orderLater" && (
                <div className="deliveryContainer_orderLater">
                  <div className="deliveryContainer_orderLater_container">
                    <div className="deliveryContainer_orderLater_date">
                      <DateTimePicker
                        onChange={(value) => onChangeDate(value)}
                        value={new Date(date)}
                        className="deliveryContainer_orderLater_datetime"
                        format="yyyy-MM-dd"
                        placeholderText="Enter date"
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().getTime() + 4 * 24 * 60 * 60 * 1000
                          )
                        }
                        clearIcon={date === "" ? "" : "x "}
                        yearPlaceholder="Enter date"
                        monthPlaceholder=""
                        dayPlaceholder=""
                      />
                    </div>
                  </div>
                  <div className="deliveryContainer_orderLater_container">
                    {intervalModal && date !== "" && (
                      <div className="deliveryContainer_orderLater_container_drop">
                        <IntervalDrop
                          setInterval={setInterval}
                          interval={interval}
                          intervalModal={intervalModal}
                          setIntervalModal={setIntervalModal}
                        />
                      </div>
                    )}

                    <div className="deliveryContainer_orderLater_time">
                      <p
                        onClick={() => {
                          setIntervalModal(!intervalModal);
                        }}
                        className="deliveryContainer_orderLater_datetime"
                      >
                        {interval === "" ? "Select Interval" : interval}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="deliveryContainer_delivery">
                <Button
                  content="View Menu"
                  className="deliveryContainer_delivery_button"
                  onClick={() => onFindFood()}
                />
              </div>
              {alertData && (
                <h1 className="deliveryContainer_delivery_phrase">
                  Please Enter location !!
                </h1>
              )}
              <div
                className="deliveryContainer_location"
                onClick={() => onCurrentLocations()}
              >
                <img src={Detect} width="14" height="14" alt="logo" />
                <p className="deliveryContainer_text">
                  {active === "delivery" || activeOrder === "orderLater"
                    ? "Use current location"
                    : "Restaurant near you"}
                </p>
              </div>

              {active !== "delivery" && outlets && outlets.length > 0 && (
                <>
                  <h4 className="deliveryContainer_error_recommend">
                    Select Outlet to order from
                  </h4>
                  {outlets.map((item) => {
                    return (
                      <StoreCard
                        data={item}
                        theme={active}
                        onClickStore={onClickStore}
                      />
                    );
                  })}
                </>
              )}

              {errorData && (
                <>
                  <div className="deliveryContainer_error">
                    <h6 className="deliveryContainer_error_text">
                      90% of people want home delivery on location x. if we
                      reach 10% More then we would open a new restaurant so you
                      can enjoy our food. are you interested? Yes I'm interested
                    </h6>
                    <img
                      src={LocationSearch}
                      width="132"
                      height="117"
                      alt="locationSearch"
                    />
                  </div>
                </>
              )}
              {errorData1 && countData && countData.count && (
                <>
                  <h4 className="deliveryContainer_error_recommend">
                    Recommend for take away
                  </h4>
                  <div className="deliveryContainer_error">
                    <p className="deliveryContainer_error_text1">
                      Hey ! found {countData.count} bbk restaurant{" "}
                      {countData.distance} km from your location would you do
                      take away from these restaurants
                    </p>
                    <div className="deliveryContainer_error_button">
                      <Button
                        content="Search Now"
                        onClick={() => onStoreSelect()}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default DeliveryContainer;
