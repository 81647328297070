import React from 'react'
import './index.scss';
const ThanksTac = ({ termsAndConditions, type, tc }) => {
    console.log(tc, "terms and conditions")
    return (
        <>
            <div className='termsAndCondition'>
                <div
                    className="popup-tc"
                    dangerouslySetInnerHTML={{ __html: termsAndConditions }}
                />
            </div>
            {type === "wallet" && <div className='wallet-tc'>
                {tc && tc.length > 0 && (
                    <div
                        className="popup-tc"
                        dangerouslySetInnerHTML={{ __html: tc[0].html_content }}
                    />
                )}
            </div>}
        </>
    )
}

export default ThanksTac