import "./index.scss";
import React from "react";
import closeImg from "../../assets/images/close.svg";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import Button from "../button";
// import closeImg from '../../assets/images/close.svg';
// import Modal from '../../containers/modal';

const AlertPopup = (props) => {
  const { title, description, okayClick, close, alertCart, logout, isLoading } = props;
  const { innerWidth } = window;
  const history = useHistory();
  const handleOkayClick = () => {
    history.push(ROUTES.HOME);
  };
// debugger;
  return (
    // <Modal centerPopUp hideClose onCloseHandler={okayClick}>
    <>
      {innerWidth <= 1023 ? (
        <div className="alertPopup_wrapper">
          <div className="alertPopup">
            <div className="alertPopup__content">
              <div className="alertPopup_wholeDiv">
                <h3 className="alertPopup__title">{title}</h3>
                {
                  close &&
                  <img
                    src={closeImg}
                    onClick={() => close()}
                    className='close'
                    alt="closeImg"
                  />
                }
              </div>
              <p className="alertPopup__description">{description}</p>
              <div className="alertPopup__button">
                <div
                  className={`alertPopup__okay-btn ${alertCart && "alertPopup__okay"
                    }`}
                  role="presentation"
                >
                  {alertCart ? (
                    <>
                      <Button
                        content={logout ? 'Yes' : 'Okay'} 
                        className="alertPopup__okaybutton"
                        customClass="alertPopup__okaybutton_okaycontent"
                        onClick={okayClick}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        content="Cancel"
                        className="alertPopup__cancelbutton"
                        customClass="alertPopup__cancelbutton_content"
                        onClick={close}
                      />
                      {logout && (
                        <Button
                          content="Logout"
                          size="is-small"
                          className="alertPopup__logoutbutton"
                          customClass="alertPopup__logoutbutton_content"
                          onClick={okayClick}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="alertPopup_wrapper">

          <div className="alertPopup">
            {
              close &&
              <img
                src={closeImg}
                onClick={() => close()}
                className='close'
                alt="CloseImg"
              />
            }

            <div className="alertPopup__content">

              <p className="alertPopup__title">{title}</p>
              <p className="alertPopup__description">{description}</p>
              <div className="alertPopup__button">
                <div
                  className="alertPopup__okay-btn"
                  onClick={okayClick}
                  role="presentation"
                >
                  <Button isLoading={isLoading} content={logout ? 'Yes' : 'okay'} size="is-small" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    // </Modal>
  );
};

export default AlertPopup;
