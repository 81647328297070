import './index.scss';
import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';
import BBK from '../../assets/images/hyd.svg';
import HorizontalScroll from '../../components/horizontal-scroll';
import ImgNotAvailable from "../../assets/images/image-not-available.jpg";

const CategoryCard = (props) => {
  const { data, sticky, theme, onClickCategory, selectedData } = props;

  const getData = (
    <div className='categoryCard_wrapper'>
      {data && data.length > 0 && data.map((items) => {
        return (
          items && items.itemData && items.itemData.length > 0 &&
          <div
            className={`categoryCard ${selectedData && selectedData.category_name === items.category_name
                ? 'categoryCard_Active'
                : ''
              }`}
            onClick={() => onClickCategory(items)}
          >
            {selectedData &&
              selectedData.category_name === items.category_name &&
              items.itemData &&
              items.itemData.length > 0 ? (
              <div className='categoryCard_count'>{items.itemData.length}</div>
            ) : (
              ''
            )}
            <div
              className={`categoryCard_circle ${selectedData &&
                  selectedData.category_name === items.category_name
                  ? 'categoryCard_circle_Active'
                  : ''
                }`}
            >
              <img src={items?.category_image_url ? items?.category_image_url : ImgNotAvailable}
                onError={(e) => { e.target.onerror = null; e.target.src = ImgNotAvailable }}
                alt='logo' className='categoryCard_circle_img' />
            </div>
            <p className='categoryCard_label'>{items.category_name}</p>
          </div>
        );
      })}
    </div>
  );

  const getStickyData = (
    <div className='stickyDiv'>
      {data && data.length > 0 && data.map((items) => {
        return (
          items && items.itemData && items.itemData.length > 0 &&
          <p
            onClick={() => onClickCategory(items)}
            className={`stickyDiv_nameLabel ${selectedData && selectedData.category_name === items.category_name
                ? 'stickyDiv_nameLabelActive'
                : ''
              }`}
          >{items.category_name}</p>
        )
      })}
    </div>
  )


  return <HorizontalScroll contentData={sticky ? getStickyData : getData} />;
};

export default CategoryCard;
