import React, { useState, useEffect } from "react";
import "./index.scss";
import Header from "../../containers/header";
import Footer from "../../containers/footer";
import back from "../../assets/images/goback.svg";
import banner from "../../assets/images/Invite-cuate.svg";
import { useHistory } from "react-router-dom";
import { getReferEarn } from "../../actions/refer-earn";
import copy from "../../assets/images/copy-icon.png";
import share from "../../assets/images/share.png";
import Toaster from "../../components/toaster";
import SharePopup from "../../components/sharePopup";
import { getStaticPage } from "../../actions/staticpages";
import HeaderCommon from "../../components/header-common-component";
import { LocalStorage } from "../../utils/storage";
import { ROUTES } from "../../constants/app-constants";

export default function ReferAndEarn(props) {
  const { data } = props;
  const innerWidth = window.innerWidth;
  const [refers, setRefers] = useState();
  const [bannerImg, setBannerImg] = useState();
  const [isShowMessage, setisShowMessage] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [footerData, setFooterData] = useState([]);
  const history = useHistory();
  const [isMsg, setIsMsg] = useState({
    toastType: false,
    toastText: "",
    isShow: false,
  });

  //Refer-and-Earn Api
  const getRefers = () => {
    getReferEarn((res) => {
      if (res && res?.response?.status === 200) {
        const root = document.getElementById("root");
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setRefers([res?.content]);
        setBannerImg([res?.content?.banner]);
        console.log(res.content, "Refers");
      }
    });
  };
  //Footer-Data
  const query = "";
  useEffect(() => {
    getStaticPage((res) => {
      if (res && res?.response?.status === 200) {
        setFooterData(res && res?.content);
        console.log(res.content, ")I");
      }
      // else {
      //   onSkipLocation()
      // }
    }, query);
  }, []);
  const onLoginClick = () => {
    history.push({
      pathname: ROUTES.LOGIN,
      state: { redirectToReferandEarn: true },
    });
  };
  useEffect(() => {
    if (!LocalStorage.get("isLoggedIn")) {
      onLoginClick();
    }
    getRefers();
  }, []);

  // Handling-Buttons

  const handleGoBack = () => {
    history.goBack();
  };

  const onApply = (referal_code, isShowMessage) => {
    navigator.clipboard.writeText(referal_code);
    !isShowMessage &&
      setIsMsg({
        toastType: true,
        toastText: `Your Referral code  ${referal_code}  copied`,
        isShow: true,
      });
  };

  const openPopup = () => {
    setIsShare(true);
  };

  const closePopup = () => {
    setIsShare(false);
  };
  console.log(refers, "refer")
  return (
    <div>
      {innerWidth > 1023 ? (
        <>
          <HeaderCommon />
          <>
            {refers &&
              refers.map((referearn) => (
                <div className="refer-and-earn">
                  <div className="refer-and-earn_top">
                    <div
                      className="refer-and-earn_header"
                      dangerouslySetInnerHTML={{
                        __html: referearn.header,
                      }}
                    ></div>
                    <div className="refer-and-earn_banner">
                      {/* <img src={referearn.banner.banner.large.banner_image}  alt="Banner-image" className="refer-and-earn_bannerImg" /> */}
                      {/* {referearn.banner} */}
                      <img src={banner} className="refer-and-earn_bannerImg" />
                    </div>
                  </div>
                  <div className="refer-and-earn_code-div">
                    <p className="refer-and-earn_unText">
                      Your Unique referal code is
                    </p>
                    <div className="refer-and-earn_code-container">
                      <p className="refer-and-earn_code">
                        {referearn.referal_code}
                      </p>
                    </div>
                    {
                      (referearn.refer_code_status) ? <div className="refer-and-earn_copy-share-div">
                        <span
                          className="refer-and-earn_copy-div"
                          onClick={() =>
                            onApply(referearn.referal_code, isShowMessage)
                          }
                        >
                          <p>COPY CODE</p>
                          <img src={copy} className="refer-and-earn_copyImg" />
                        </span>

                        <span
                          className="refer-and-earn_share-div"
                          onClick={() => openPopup()}
                        >
                          <p>SHARE</p>
                          <img src={share} className="refer-and-earn_shareImg" />
                        </span>
                      </div> :
                        <p className="refer-and-earn-err_msg">{referearn?.message}</p>
                    }

                    <div
                      className="refer-and-earn_des-div"
                      dangerouslySetInnerHTML={{
                        __html: referearn.description,
                      }}
                    ></div>
                  </div>
                  <div className="refer-and-earn_tc-div">
                    {/* <h3>Terms and Conditions</h3> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: referearn.terms_conditions,
                      }}
                    ></p>
                  </div>
                  {isShare && <SharePopup referalCode={referearn.referal_code} onClose={closePopup} refersdata={refers} />}
                </div>
              ))}
            {isMsg.isShow && (
              <Toaster
                toastType={isMsg.toastType}
                toastText={isMsg.toastText}
                closeToast={() =>
                  setIsMsg({
                    isShow: false,
                  })
                }
              />
            )}
          </>
          {/* <Footer data={footerData && footerData} /> */}
        </>
      ) : (
        <>
          <div className="myOrders_section">
            <img
              src={back}
              className="myOrders_section_backimg"
              onClick={handleGoBack}
              alt=""
            />
            <h3 className="myOrders_section_heading">Refer and Earn</h3>
          </div>
          {refers &&
            refers.map((referearn) => (
              <>
                <div className="refer-and-earn">
                  <div className="refer-and-earn_mobile-top">
                    <img src={banner} className="refer-and-earn_bannerImg" />
                    <div
                      className="refer-and-earn_header"
                      dangerouslySetInnerHTML={{
                        __html: referearn.header,
                      }}
                    ></div>
                    <span className={referearn.refer_code_status ? "refer-and-earn_refer-btn" : "refer-and-earn_refer-btn_block"} onClick={() => referearn.refer_code_status ? openPopup() : ''}>
                      <p>Refer Now</p>
                    </span>
                    <div
                      className="refer-and-earn_des-div"
                      dangerouslySetInnerHTML={{
                        __html: referearn.description,
                      }}
                    ></div>
                    {
                      (!referearn.refer_code_status) && <p className="refer-and-earn-err_msg">{referearn.message}</p>
                    }
                  </div>
                  <div className="refer-and-earn_tc-div">
                    {/* <h3>Terms and Conditons</h3> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: referearn.terms_conditions,
                      }}
                    ></p>
                  </div>
                </div>
                {isShare && <SharePopup referalCode={referearn.referal_code} onClose={closePopup} refersdata={refers} />}

              </>

            ))}
        </>
      )}
    </div>
  );
}