import React, { useState } from "react";
import "./index.scss";
import Modal from "../modal";
import Donation from "../../assets/images/donation.png";
import Close from "../../assets/images/close.svg";
import Input from "../input";
import Button from "../button";

const data = [
  {
    name: "10",
  },
  {
    name: "20",
  },
  {
    name: "30",
  },
  {
    name: "50",
  },
];

const DonationPopup = (props) => {
  const { onClose, donation, onChangeDonation, onClickDonation, alert ,
    isDisabled
  } = props;
  const { innerWidth } = window;
  const [donate, setDonate] = useState(false);
  const [donationAmt , setDonationAmt]=useState(0);
  // const [amount, setAmount] = useState(false);

  // let donationAmount;
  // const handleAmount = () => {
  //   donationAmount = document.getElementById("donation")?.value;
  //   if (donationAmount > 0) {
  //     setAmount(true);
  //   }
  // };

  const handleDonationBtnDisbled = ()=>{
    let isDisabled = false;
      if(donationAmt === 0){
        isDisabled = true;
      }
    return isDisabled;
  }

  const handleDonationAmtClick = (amt)=>{
    setDonationAmt(amt)
  }

  const handleDonationAmtChange =(id ,value)=>{
    setDonationAmt(value)
  }

  return (
    <Modal
      hideClose
      modalCustomClass="modalCustomClass"
      contentCustomClass="donationcontentCustomClass"
    >
      <div className="donation">
        <div className="donation_image">
          <img
            src={Donation}
            alt="donation"
            width="100%"
            height="216"
            className="donation_img"
          />
          <img
            src={Close}
            alt="close"
            width="20"
            height="20"
            className="donation_close"
            onClick={() => onClose(false)}
          />
        </div>
        {innerWidth <= 1023 ? (
          <>
            <div className="donation_wholeWrapper">
              <div className="donation_data">
                <h3 className="donation_heading">Donate NGO</h3>
                {/* {handleAmount()} */}
                <h4 className="donation_text">
                  How much would you like to donate
                </h4>
                <div className="donation_money">
                  {data.map((item) => {
                    return (
                      <div
                        className={`donation_amount ${
                          donationAmt === item.name ? "donation_amountSelect" : ""
                        }`}
                        onClick={() => handleDonationAmtClick(item.name)}
                      >
                        + ₹{item.name}
                      </div>
                    );
                  })}
                </div>
                <div className="donation_money donation_centerSection">Or</div>
                <Input
                  type="number"
                  value={`${donationAmt}`}
                  onChange={handleDonationAmtChange}
                  isNumber
                  id="donation"
                  customClass="donation_input"
                />
                {alert && <p className="donation_alert">{alert}</p>}
              </div>
              <div className="donation_footerSection">
                <Button
                  content="Donate"
                  onClick={() => onClose(true , donationAmt)}
                  className={`donation_button ${handleDonationBtnDisbled() ? 'donation_btn-disble' :''}`}
                  disabled={handleDonationBtnDisbled()}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="donation_data">
            <h3 className="donation_heading">Donate NGO</h3>
            <h4 className="donation_text">How much would you linke to donate</h4>
            <div className="donation_money">
              {data.map((item) => {
                return (
                  <div
                    className={`donation_amount ${
                      donationAmt === item.name ? "donation_amountSelect" : ""
                    }`}
                    onClick={() => handleDonationAmtClick(item.name)}
                  >
                    + ₹{item.name}
                  </div>
                );
              })}
            </div>
            <div className="donation_money donation_centerSection">Or</div>
              <Input
                value={donationAmt}
                onChange={handleDonationAmtChange}
                isNumber
                onClick={() => setDonate(true)}
                customClass={`${
                  donate ? `donation_input donation_highlight` : `donation_input`
                }`}
              />
              {alert && <p className="donation_alert">{alert}</p>}
              <Button
                content="Donate"
                onClick={() => onClose(true, donationAmt)}
                className={`donation_button ${handleDonationBtnDisbled() ? 'donation_btn-disble' :''}`}
                disabled={handleDonationBtnDisbled()}
              />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DonationPopup;
