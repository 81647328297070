import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';

export const addToCart = (payload, callback) => {
  fetchCall(
    `${API_CONSTANTS.ADD_TO_CART}`,
    API_METHODS.POST,
    payload,
    callback
  );
};

export const getCartItems = (url, callback) => {
  fetchCall(
    `${API_CONSTANTS.GET_CART_ITEM + url}`,
    API_METHODS.GET,
    '',
    callback
  );
};

export const checkout = (payload, callback, params) => {
  fetchCall(
    `${API_CONSTANTS.CHECKOUT}${params}`,
    API_METHODS.POST,
    payload,
    callback
  );
};

export const getOrderTrack = (callback, params) => {
  fetchCall(
    `${API_CONSTANTS.ORDER_TRACKING}${params}`,
    API_METHODS.GET,
    '',
    callback
  );
};
export const getOrderTrackStatus = (callback, params) => {
  fetchCall(
    `${API_CONSTANTS.ORDER_TRACKING_STATUS}${params}`,
    API_METHODS.GET,
    '',
    callback
  );
};



export const cancelOrder = (params, callback) => {
  fetchCall(
    `${API_CONSTANTS.CANCEL_ORDER}${params}`,
    API_METHODS.POST,
    {},
    callback
  );
};

export const paymentSuccess = (payload, callback) => {
  fetchCall(API_CONSTANTS.PAYMENT, API_METHODS.POST, payload, callback);
};

// export const editServices = (payload, callback, params) => {
//   const url = `${API_CONSTANTS.CREATE_OUTLETS}${params}`;
//   return fetchCall(url, API_METHODS.PUT, payload, callback);
// };



export const getAllAddresses = (callback, params) => {
  fetchCall(
    `${API_CONSTANTS.ADDRESS_GET_ALL}${params}`,
    API_METHODS.GET,
    '',
    callback
  );
};

export const applyGiftVoucher = (payload, callback) => {
  fetchCall(
    `${API_CONSTANTS.GET_GIFT_VOUCHER_AMOUNT}`,
    API_METHODS.POST,
    payload,
    callback
  );
};