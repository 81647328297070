import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import BBK from '../../assets/images/Biryani-by-Kilo.jpg';

const ImageCard = (props) => {
  const { data } = props;
  return (
    <div className='imageCard'>
      <img src={data.banner_image} className='imageCard_img' alt="bannerImg"/>
    </div>
  );
};
export default ImageCard;
