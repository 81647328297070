import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';

export const orderList = (callback, page) => {
  if (page) {
    fetchCall(`${API_CONSTANTS.ORDER_LIST}?page=${page}`, API_METHODS.GET, '', callback);
  }
  else {
    fetchCall(API_CONSTANTS.ORDER_LIST, API_METHODS.GET, '', callback);
  }
};

export const getInvoice = (payload, callback) => {
  fetchCall(API_CONSTANTS.GET_INVOICE_PDF, API_METHODS.POST, payload, callback);
}
