import React from "react";
import PropTypes from "prop-types";
import Input from "../../components/input";
import "./index.scss";

const checkoutCheckbox = (props) => {
  const {
    logStatus,
    imgId,
    img,
    headerId,
    header,
    infoId,
    info,
    id,
    value,
    onChange,
    aTag,
    customClass,
    pointData,
    history1,
    loyaltyActive,
    points
  } = props;
  const changeEvent = (e) => {
    onChange(e)
  }
  console.log(customClass === "checkbox_loyalty");
  return (
    <div className={`checkbox ${customClass}`}>
      <div className="checkbox_image">
        <div
          className={`checkbox_img ${header === "Loyalty" && "checkbox_loyaltyImg"
            } ${header === "Anch box" && "checkbox_anchBoxImg"} ${header === "I want seed to raise a plant" && "checkbox_plantImg"
            } ${header === "Donate NGO" && "checkbox_donateImg"}`}
        >
          <img id={imgId} src={img} className="checkbox_img_i" alt="imgId" />
        </div>
        <div className="checkbox_data">
          <div className="checkbox_desc">
            <div className="checkbox_div">
              <div className="checkbox_header">
                {(customClass === "checkbox_loyalty" || customClass === "checkout-page_loyalty_act") && <p className="points_10">1 ₹ = {points ? points : 10} Points</p>}
                {loyaltyActive && loyaltyActive !== "" ? (
                  <div className="checkbox_header_new">
                    <p id={headerId} className="checkbox_header_new_phrase">
                      {header}
                    </p>
                    <p className="checkbox_header_new_line">
                      {loyaltyActive ? loyaltyActive : ""}
                    </p>
                  </div>
                ) : (
                  header === 'Loyalty' ?
                    `BBK ` + header : header
                )}
              </div>
            </div>
          </div>
          <div className="checkbox_info">
            <p id={infoId} className="checkbox_info_phrase">
              {aTag ? (
                <>
                  NGO -{" "}
                  <a
                    style={{ color: "#0091ff", textDecoration: "none" }}
                    href="https://robinhoodarmy.com/"
                    target="_blank"
                  >
                    {info}
                  </a>
                </>
              ) : (
                info
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="checkbox_box1">
        <label className="checkbox_label">
          <input
            type="checkbox"
            className={`checkbox_checkbox ${logStatus && "checkbox_nonActive"}`}
            // name={item.name}
            id={id}
            checked={value}
            onClick={(e) => {
              console.log("onChange-->", e);
              changeEvent(e);
            }}
            readOnly
            disabled={logStatus}
          />
          <div className={`checkbox_custom`}></div>
        </label>
      </div>
    </div>
  );
};

// checkoutCheckbox.PropTypes = {
//   id: PropTypes.string,
//   type: PropTypes.string,
//   value: PropTypes.string,
//   onChange: PropTypes.func,
//   customClass: PropTypes.string,
// };

// checkoutCheckbox.defaultProps = {
//   id: "",
//   type: "",
//   value: "",
//   onChange: () => {},
//   customClass: "",
//   disabled: false,
// };

export default checkoutCheckbox;
