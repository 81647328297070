import React, { useEffect, useRef, useState } from 'react'
import crossIcon from '../../assets/images/close.svg';
import bbk from '../../assets/images/bbkmain_logo.png';
import signature from '../../assets/images/koushikRoy.jpeg'
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getInvoice } from '../../actions/orders';
import { LocalStorage } from '../../utils/storage';



const Invoice = () => {
    const pdfRef = useRef();
    const history = useHistory()
    const [pdfContent, setPdfContent] = useState();
    const order_id = LocalStorage.get("Invoice_Order_Id")

    useEffect(() => {
        let payload = {
            order_id: order_id
        }
        getInvoice(payload, (res) => {
            if (res.response.status === 200) {
                setPdfContent(res.content)
            }
            // setPdfContent(res.content);
        })
    }, [order_id])

    useEffect(() => {
        console.log("PDF CONTENT DATA", pdfContent);

    }, [pdfContent])

    const savePDF = () => {
        pdfRef.current.querySelector('thead img').src = bbk;
        pdfRef.current.querySelector('table tbody tr table tbody tr td table tr th span img').src = signature;
        const input = pdfRef.current;
        pdfRef.current.querySelector('thead img').onload = () => {
            // html2canvas(input).then((canvas) => {
            //     const imgData = canvas.toDataURL('image/png');
            //     const pdf = new jsPDF('p', 'mm', 'a4', true);
            //     const pdfWidth = pdf.internal.pageSize.getWidth();
            //     const pdfHeight = pdf.internal.pageSize.getHeight();
            //     const imgWidth = canvas.width;
            //     const imgHeight = canvas.height;
            //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
            //     const imgY = 30;
            //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            //     pdf.save('sample.pdf');
            // })
            html2canvas(input).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4', true);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;

                // Calculate the aspect ratio of the image
                const imgRatio = imgWidth / imgHeight;

                // Adjust the width and height of the image based on the aspect ratio
                let newImgWidth, newImgHeight;
                if (imgRatio > 1) {
                    newImgWidth = pdfWidth;
                    newImgHeight = pdfWidth / imgRatio;
                } else {
                    newImgWidth = pdfHeight * imgRatio;
                    newImgHeight = pdfHeight;
                }

                // Calculate the position to center the image
                const imgX = (pdfWidth - newImgWidth) / 2;
                const imgY = (pdfHeight - newImgHeight) / 2;

                // Add the image to the PDF
                pdf.addImage(imgData, 'PNG', imgX, imgY, newImgWidth, newImgHeight);

                // Save the PDF
                pdf.save('sample.pdf');
            });
        }
    }
    return (
        <div className="pdf_view">
            {/* <img className="cross_icon_img" src={crossIcon} alt="X" onClick={() => setShowPdf(false)} /> */}
            <div className="pdf_view_content" dangerouslySetInnerHTML={{ __html: pdfContent }} ref={pdfRef}>
            </div>
            <button onClick={savePDF}>Download Invoice</button>

        </div>
    )
}

export default Invoice