import React, { useState } from "react";
import Modal from "../modal";
import Button from "../button";
import "./index.scss";
import orderCancel from "../../assets/images/orderCancel.svg";
import goback from "../../assets/images/goback.svg";
import HeaderMobile from "../../containers/header-mobile";

const OrderCancellation = (props) => {
  const { CartShow, onHomeClick } = props;

  const [popUp, setPopUp] = useState(true);

  const { innerWidth } = window;

  return (
    <>
      {popUp && (
        <Modal
          modalCustomClass="modalCustom1"
          onCloseHandler={CartShow}
          isMobileFullLayout
          contentCustomClass='order-cancellation'
        >
          {innerWidth <= 1023 ? (
            <>
              <div className="wholeWrapper">
                <img
                  alt="goback"
                  src={goback}
                  onClick={CartShow}
                  className="wholeWrapper_img"
                ></img>
                <p className="modalCustom1_modal_nameLabel">Order</p>
              </div>
              <div className="modalCustom1_wholeWrapper">
                <div className="modalCustom1_modal">
                  <img src={orderCancel} className="modalCustom1_modal_img" alt="ordercancel" />
                  <p className="modalCustom1_modal_headLabel">
                    Your order cancelled
                  </p>
                  <p className="modalCustom1_modal_subLabel">
                    Your payment will be returned to you soon.
                  </p>
                </div>
                <div className="modalCustom1_modal_btns">
                  <Button
                    className="modalCustom1_modal_btns_explore"
                    content="Explore Food"
                    onClick={onHomeClick}
                    customClass="modalCustom1_modal_btns_explore_button"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="modalCustom1_modal">
              <p className="modalCustom1_modal_nameLabel">Order cancelled</p>
              <img src={orderCancel} className="modalCustom1_modal_img" alt="ordercancel" />
              <p className="modalCustom1_modal_headLabel">
                Your Order Cancelled
              </p>
              <p className="modalCustom1_modal_subLabel">
                Your payment will be returned to you soon.
              </p>
              <div className="modalCustom1_modal_btns">
                <Button
                  className="modalCustom1_modal_btns_cancel"
                  content="Try Again"
                  onClick={onHomeClick}
                />
                <Button
                  className="modalCustom1_modal_btns_explore"
                  content="Explore Food"
                  onClick={onHomeClick}
                />
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default OrderCancellation;
