import React from "react";
import "./index.scss";
import { addWallet } from "../../actions/wallet";
import { useHistory } from "react-router-dom";
import { makeOnlinePayment } from "../../pages/wallet/makeOnlinePayment";
import ThanksPage from "../thankspage";
import { useEffect, useState } from "react";
import closeImg from "../../assets/images/close-black-symbol.svg";

const WalletAddMoney = ({
  tc,
  close,
  amount,
  selectedOfferId,
  walletOfferList,
  isOfferApplied,
  cashback,
  walletBalance,
  handleHistoryPopup,
}) => {
  const history = useHistory();
  const [isPaymentDone, setPaymentDone] = useState(false);
  const [paymentData, setPaymentData] = useState("");
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);

  useEffect(() => {
    if (isOfferApplied) {
      const appliedOffer = walletOfferList.find(
        (offer) => offer.id === selectedOfferId
      );

      if (appliedOffer && appliedOffer.offer_type === "discount") {
        const discountValue = appliedOffer.discount_value;
        const originalAmount = parseFloat(amount);
        if (appliedOffer.discount_type === "percentage") {
          const discountAmount = (discountValue / 100) * originalAmount;
          const finalAmount = originalAmount - discountAmount;
          setTotalAmount(finalAmount);
        } else {
          const finalAmount = originalAmount - discountValue;
          setTotalAmount(finalAmount);
        }
      } else {
        setTotalAmount(amount);
      }
    }
  }, [isOfferApplied, selectedOfferId, amount, walletOfferList]);

  const closePopup = () => {
    setPaymentDone(false);
  };
  const addMoneyWallet = (res) => {
    console.log(selectedOfferId, "Ooof");
    // debugger;
    const payload = {
      amount: parseFloat(amount),
      is_offer_apply: isOfferApplied,
      offer_id: selectedOfferId,
    };
    addWallet(payload, (res) => {
      if (res && res?.response?.status === 200) {
        makeOnlinePayment(res)
          .then((successResponse) => {
            setPaymentDone(true);
            setPaymentData(successResponse.content);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <>
      <div className="addMoney-overlay">
        <div className="addMoney-content">
          {/* <WalletBanner walletBalance={walletBalance} handleHistoryPopup={handleHistoryPopup}/> */}
          <div className="recharge_tnc">
            <img src={closeImg} onClick={close} className="close-img" />
            <div className="recharge_details">
              <span className="recharge_details_heading">Recharge Details</span>
              <div className="recharge_amount">
                <span className="recharge_details_amount">
                  Recharge Amount
                </span>
                <span className="recharge_details_amount">₹ {amount}</span>
              </div>
              {isOfferApplied && (
                <div className="recharge_bonus">
                  {walletOfferList.map((offer) => {
                    if (
                      offer.id === selectedOfferId &&
                      offer.offer_type === "discount"
                    ) {
                      return (
                        <>
                          {offer.discount_type === "percentage" ? (
                            <>
                              <span className="recharge_details_bonus">
                                Discount*
                              </span>
                              <span className="recharge_details_bonus">
                                {offer.discount_value}%
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="recharge_details_bonus">
                                Discount*
                              </span>
                              <span className="recharge_details_bonus">
                                ₹ {offer.discount_value}
                              </span>
                            </>
                          )}
                        </>
                      );
                    }
                  })}
                  {walletOfferList.some(
                    (offer) =>
                      offer.id === selectedOfferId &&
                      offer.offer_type === "discount"
                  ) ? null : (
                    <>
                      <span className="recharge_details_bonus">
                        BBK Cash Bonus*
                      </span>
                      <span className="recharge_details_bonus">
                        ₹ {cashback}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <span
            className="AddmoneyButton"
            id="walletAddMoney"
            onClick={() => {
              addMoneyWallet();
            }}
          >
            Pay ₹ {totalAmount || amount}
          </span>
        </div>
      </div>
      {isPaymentDone && (
        <ThanksPage
          tc={tc}
          paymentData={paymentData}
          type={"wallet"}
          onClose={closePopup}
        />
      )}
    </>
  );
};

export default WalletAddMoney;
