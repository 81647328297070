import React, { useState, useEffect } from "react";
import "./index.scss";
import { useLocation } from "react-router-dom";
import Footer from "../../containers/footer";
import { LocalStorage } from "../../utils/storage";
import Header from "../../containers/header";
//delivery
import RadioButton from "../../components/radioButton";
// import SearchAutoComplete from "../searchAutocomplete";
import SearchAutoComplete from "../../containers/searchAutocomplete";
import Detect from "../../assets/images/detectLoc.svg";
// import responsiveDetect from "../../assets/images/profileLocation.svg";
// import MapModal from "../mapModal";
import MapModal from "../../containers/mapModal";
import LocationSearch from "../../assets/images/homeLocation.svg";
import remove from "../../assets/images/close.svg";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
// import Input from "../../components/input";
import { OutletMapping, getSkipLocation } from "../../actions/home";
import StoreCard from "../../components/store-card";
// import AlertPopup from "../../components/alert-popup";
import LocationImage from "../../assets/images/Web-Location-page-web.jpg";
import Locationinterested from "../../components/location-interested-card";
import IntervalDrop from "../../components/interval-dropdown";
import Switch from "../../components/switch";
import moment from "moment";
import { getMenuList } from "../../actions/home";
import Dexie from "dexie";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
import Modal from "../modal";
import Button from "../button";
import AlertPopup from "../alert-popup";
import HeaderMobile from "../../containers/header-mobile";
import HeaderCommon from "../header-common-component";
import { getStaticPage } from "../../actions/staticpages";
import { getSavedAddress } from "../../actions/home";
import { Helmet } from "react-helmet";

const StaticPage = (props) => {
  let footerData = LocalStorage.get("footerData");
  const { staticData, showButton, showLocation } = props;
  const history = useHistory();
  const location1 = useLocation();
  const [dataLoc, setDataLoc] = useState(location1.pathname.substring(1));
  const [loginFlow, setLoginFLow] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const { innerWidth } = window;
  // history.push();
  const switchData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Takeaway" },
  ];

  const switchResponsiveData = [
    { id: "delpopupivery", name: "Delivery" },
    { id: "takeaway", name: "Pick-up" },
  ];

  const radio = [
    { id: "orderNow", name: "Order Now", checked: true },
    { id: "orderLater", name: "Order Later", checked: false },
  ];

  const responsiveRadio = [
    { id: "orderNow", name: "Now", checked: true },
    { id: "orderLater", name: "Later", checked: false },
  ];
  const [active, setActive] = useState("delivery");
  const [radioData, setRadioData] = useState(radio);
  const [responsiveRadioData, setResponsiveRadioData] =
    useState(responsiveRadio);
  const [activeOrder, setActiveOrder] = useState("orderNow");
  const [inputValue, setInputvalue] = useState({});
  const [popup, setPopup] = useState(false);
  const [inputData, setInputData] = useState("");
  // const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [latLng, setLatLng] = useState({ lat: "", lng: "" });
  const [errorData, setErrorData] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [location, setLocation] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [data, setData] = useState([]);
  const [errorData1, setErrorData1] = useState(false);
  const [countData, setCountData] = useState({});
  const [alertData, setAlertData] = useState(false);
  const [dateData, setdateData] = useState(false);
  const [timeData, settimeData] = useState(false);
  const [timeInp, setTimeInp] = useState("text");
  const [modal, setModal] = useState(false);
  // const {netcore} = props;
  const [interval, setInterval] = useState("");
  const [intervalModal, setIntervalModal] = useState(false);
  const [intDate, setIntDate] = useState([]);
  let timeInterval = [];
  const [date, setDate] = useState("");
  const [stat, setStat] = useState("stage1");
  const [savedAddress, setSavedAddress] = useState();

  //delivery
  const [deliveryFlow, setDeliveryFlow] = useState(false);
  const LoginCLick = () => {
    setLoginFLow(true);
    setIsSignIn(false);
  };

  const SigninClick = () => {
    setLoginFLow(true);
    setIsSignIn(true);
  };

  const deliveryClick = () => {
    setDeliveryFlow(true);
  };

  const [staticDataNew, setStaticDataNew] = useState();
  const urlParams = useLocation();
  const urlValue = urlParams.pathname.split("/")[3];
  const urlCity = urlParams.pathname.split("/")[2];

  useEffect(() => {
    //
    //delivery
    getSavedAddress((res) => {
      if (res?.response?.status == 200) {
        setSavedAddress(res.content.address);
      }
    });

    if (latLng.lat === "" && latLng.lng === "") {
      setLocation(false);
      // onCurrentLocation();
    }
    let locationDetails = LocalStorage.get("location");
    if (locationDetails) {
      setLocation(true);
      setLatLng({
        lat: locationDetails && locationDetails.lat,
        lng: locationDetails && locationDetails.lng,
      });
      // setActiveOrder(locationDetails && locationDetails.orderTime);
      setActive(locationDetails && locationDetails.orderFor);
      setInputData(locationDetails && locationDetails.address);
      // setDate(locationDetails && locationDetails.date);
      // setTime(locationDetails && locationDetails.time);
    }
    // const user = LocalStorage.get('UserData');
    // const script = document.createElement('script');
    // script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
    // script.onload = () => {
    //   smartech('identify', "91"+user.mobile);
    //   smartech('dispatch', 'Order Type', {
    //     orderType: LocalStorage.get('location')
    //   });
    // };
    // document.head.appendChild(script);

    let pathing = history.location.pathname.split("/").reverse();

    const queryParams = pathing[0];
    const root = document.getElementById("root");
    root.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getStaticPage((res) => {
      if (res && res?.response?.status === 200) {
        // setProfileData(res && res?.content);
        LocalStorage.set("staticData", res?.content?.result);
        setStaticDataNew(res?.content?.result);
        console.log("StaticPage", res?.content?.result);
        // this.setState({ showStatic: true, staticData2: res?.content?.result });

        // // history.push(res?.content?.result?.url_text);
        // window.history.push({
        //   pathname: res?.content?.result?.url_text,
        //   // search: '?query=abc',
        //   state: res?.content?.result,
        // });
      }
    }, queryParams);
  }, [history.location.pathname]);

  // useEffect(()=>{
  //   const pat
  // },[])

  //strictly for Delivery
  const onChangeOrder1 = (value) => {
    setActiveOrder(value.id);
    if (innerWidth <= 1023) {
      const updateOrder = responsiveRadioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setResponsiveRadioData(updateOrder);
    } else {
      const updateOrder = radioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setRadioData(updateOrder);
    }
  };

  const onChangeMode = (value) => {
    setActive(value.id);
  };

  const onCurrentLocations = () => {
    setAlertData(false);
    onCurrentLocation();
  };

  const onCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    setInputvalue({
                      location: results[0].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                    });
                    setLatLng({ lat: json.lat, lng: json.lng });
                    onMarkerPosition({ lat: json.lat, lng: json.lng });
                    setLocation(true);
                    setErrorData(false);
                    setInputData(results[0].formatted_address);
                    if (active === "delivery") {
                      setPopup(true);
                    } else {
                      onConfirmLocation();
                    }
                  }
                }
              },
              this
            );
          }
          // eslint-disable-next-line no-unused-vars
        },
        (_error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  const responsiveOnFindFood = () => {
    //
    if ((active === "delivery" || active === "takeaway") && inputData) {
      //
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time !== "" &&
      date !== ""
    ) {
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time === ""
    ) {
      settimeData(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      date === ""
    ) {
      setdateData(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onFindFood = () => {
    if (active === "delivery" && inputData) {
      //
      setPopup(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onChangeInput = (value) => {
    setInputData(value);
  };

  const onBackClick = () => {
    setPopup(false);
  };

  const onChangeDate = (value) => {
    let gotTime = value.getDate();
    setdateData(false);
    setDate(value);
    let dateHour = new Date();
    let hours = dateHour.getHours();
    if (gotTime === new Date().getDate()) {
      for (let i = hours; i < 24; i++) {
        timeInterval.push({
          id: i + ":00",
          name:
            i < 12
              ? Math.floor(i % 12) +
                ":00 AM" +
                " - " +
                Math.floor(parseInt(i % 12) + 1) +
                ":00 AM"
              : Math.floor(i % 12) +
                ":00 PM" +
                " - " +
                Math.floor(parseInt(i % 12) + 1) +
                ":00 PM",
        });
      }
      setIntDate(timeInterval);
    } else {
      timeInterval = [
        {
          id: "10:00",
          name: "10:00 AM - 11:00 AM",
        },
        {
          id: "11:00",
          name: "11:00 AM - 12:00 PM",
        },
        {
          id: "12:00",
          name: "12:00 PM - 01:00 PM",
        },
        {
          id: "13:00",
          name: "01:00 PM - 02:00 PM",
        },
        {
          id: "14:00",
          name: "02:00 PM - 03:00 PM",
        },
        {
          id: "15:00",
          name: "03:00 PM - 04:00 PM",
        },
        {
          id: "16:00",
          name: "04:00 PM - 05:00 PM",
        },
        {
          id: "17:00",
          name: "05:00 PM - 06:00 PM",
        },
        {
          id: "18:00",
          name: "06:00 PM - 07:00 PM",
        },
        {
          id: "19:00",
          name: "07:00 PM - 08:00 PM",
        },
        {
          id: "20:00",
          name: "08:00 PM - 09:00 PM",
        },
        {
          id: "21:00",
          name: "09:00 PM - 10:00 PM",
        },
        {
          id: "22:00",
          name: "10:00 PM - 11:00 PM",
        },
      ];
      setIntDate(timeInterval);
    }
  };

  const onChangeTime = (value) => {
    settimeData(false);
    setTime(value);
  };

  const onCurrentLocationData = (value) => {
    setLatLng({ lat: value.latitude, lng: value.longitude });
    setInputData(value.location);
  };

  const onCenter = (value) => {
    if (value === false) {
      setLatLng({ lat: "", lng: "" });
    }
  };

  const onMarkerPosition = (value) => {
    LocalStorage.remove("Previouslocation");
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng: value },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            // eslint-disable-next-line no-lone-blocks
            setInputvalue({
              location: results[0].formatted_address,
              pincode,
              latitude: value.lat,
              longitude: value.lng,
            });
            setLatLng({ lat: value.lat, lng: value.lng });
            setInputData(results[0].formatted_address);
          }
        }
      },
      this
    );
  };

  const onConfirmLocation = () => {
    setPopup(false);

    let payload = {
      latitude: latLng.lat,
      longitude: latLng.lng,
      is_delivery: active === "delivery" ? true : false,
      is_order_now: activeOrder === "orderNow" ? true : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };

    OutletMapping(payload, (res) => {
      //
      if (res && res?.response?.status === 200) {
        setErrorData(false);
        setErrorData1(false);
        setDeliveryFlow(false);
        if (res?.content?.areas) {
          setOutlets(res.content.areas);
          // LocalStorage.set('DeliveryAreaPoint',res.content.areas)
          // LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
          onClickStore(res?.content?.areas[0]);
        }
        setData([]);
      } else {
        setOutlets([]);
        setErrorData(true);
        if (active === "delivery") {
          payload = {
            latitude: latLng.lat,
            longitude: latLng.lng,
            is_delivery: false,
            is_order_now: activeOrder === "orderNow" ? true : false,
            order_date:
              activeOrder !== "orderNow" && date !== ""
                ? moment(date).format("YYYY-MM-DD")
                : "",
            order_time: activeOrder !== "orderNow" ? time : "",
            channel: "online_web",
          };
          OutletMapping(payload, (res) => {
            if (res.response.status === 200) {
              if (
                res.content &&
                res.content.areas &&
                res.content.areas.length > 0
              ) {
                setErrorData1(true);
                setCountData({
                  count: res.content.areas.length,
                  distance: parseFloat(
                    res.content.max_distance_user_to_restaurant
                  ).toFixed(2),
                });
                setData(res.content.areas);
              }
            } else {
              setErrorData(true);
              setCountData({});
              setData([]);
            }
          });
        } else {
          setErrorData1(false);
          setCountData({});
          setData([]);
        }
      }
    });
  };

  const onChangeSearch = (value) => {
    setSavedAddress("");
    setAlertData(false);
    setLocation(true);
    setInputData(value.location);
    setInputvalue(value);
  };

  const onCloseAlert = () => {
    setAlertPopup("");
  };

  const onStoreSelect = () => {
    setOutlets(data);
    setErrorData1(false);
    setData([]);
    setErrorData(false);
    setActive("takeaway");
  };

  const onClickStore = (data) => {
    // let pointDelivery = LocalStorage.get('DeliveryAreaPoint');
    if (data && data?.is_open) {
      LocalStorage.set("Outlet_Details", data);
      getMenuList(
        urlValue &&
          urlValue?.length > 0 &&
          data?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
          ? urlValue
          : data?.outlets_id,
        (res) => {
          if (res && res.content && res.response.status === 200) {
            if (res.content) {
              if (LocalStorage.get("outlet_id")) {
                const oldOutlet = LocalStorage.get("outlet_id");
                if (oldOutlet !== res.content.menu.unit_id) {
                  if (LocalStorage.get("isLoggedIn")) {
                    onMenuDataSync(res.content.menu.categories);
                  } else {
                    LocalStorage.set("CartProducts", []);
                  }
                }
              }
              LocalStorage.set("outlet_id", res.content.menu.unit_id);
              const dataValue = {
                orderFor: active,
                orderTime: activeOrder,
                deliveryTime: data.delivery_area_delivery_time,
                address: inputData,
                date: date,
                time: time,
                lat: latLng.lat,
                lng: latLng.lng,
              };
              LocalStorage.set("location", dataValue);

              const db = new Dexie(res.content.menu.unit_id);
              db.version(1).stores({
                menu: "++id, categories, item_tags, taxes",
              });
              const getMenuData = async () => {
                const allData = (await db.menu.toArray()) || [];
                if (allData && allData.length > 0) {
                  await db.menu.update(0, res.content.menu);
                } else {
                  await db.menu.add(res.content.menu);
                }
                history.push();
              };
              getMenuData();
              // onCallMenu();
              // const getMenuData = async () => {
              //   const allData = (await db.menu.toArray()) || [];
              //   if (allData && allData.length > 0) {
              //     history.push(ROUTES.PRODUCTLIST);
              //   }
              // };
              // getMenuData();
              // history.push(ROUTES.PRODUCTLIST);
            }
          } else {
            setAlertPopup(
              "Sorry!! No Data Found. Please try again later sometime"
            );
          }
        }
      );
    } else {
      setAlertPopup(
        "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
    }
  };

  const onMenuDataSync = (data) => {
    const outletData = LocalStorage.get("CartProducts");
    const updateitem = data.map((items) => {
      let updateItem = [];
      if (items.has_subcategory === "0") {
        updateItem = items.items;
      }
      if (items.has_subcategory === "1") {
        items.subcategories.map((item) => {
          item.items.map((value) => {
            updateItem.push(value);
          });
        });
        items.items = updateItem;
      }
      items.itemData = updateItem.map((item) => {
        item.checked = false;
        item.count = 0;
        if (LocalStorage.get("CartProducts")) {
          const productData = LocalStorage.get("CartProducts");
          if (productData && productData.length > 0) {
            productData.map((items) => {
              if (items.item_id === item.item_id) {
                item.count = items.count;
              }
            });
          }
        }
        return item;
      });
      items.vegData = updateItem.filter((item) => item.item_tags.includes("1"));
      items.nonVeg = updateItem.filter((item) => item.item_tags.includes("2"));
      return items;
    });
    const updateItemData = [];
    updateitem.map((items) => {
      items.itemData.map((item) => {
        updateItemData.push(item);
      });
    });
    const datavalue = updateItemData.filter((itms) => {
      return outletData.includes(itms.item_id);
    });
  };
  // const { innerWidth } = window;

  const onSkipLocation = () => {
    getSkipLocation((res) => {
      if (res.response.status === 200) {
        LocalStorage.set("Outlet_Details", res.content);
        if (res.content.outlet_id) {
          getMenuList(
            urlValue &&
              urlValue?.length > 0 &&
              res?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
              ? urlValue
              : res.content.outlet_id,
            (res) => {
              if (res && res.content && res.response.status === 200) {
                if (res.content) {
                  if (LocalStorage.get("outlet_id")) {
                    const oldOutlet = LocalStorage.get("outlet_id");
                    if (oldOutlet !== res.content.menu.unit_id) {
                      if (LocalStorage.get("isLoggedIn")) {
                        onMenuDataSync(res.content.menu.categories);
                      } else {
                        LocalStorage.set("CartProducts", []);
                      }
                    }
                  }
                  LocalStorage.set("outlet_id", res.content.menu.unit_id);
                  const dataValue = {
                    orderFor: active,
                    orderTime: activeOrder,
                    deliveryTime: data.delivery_area_delivery_time,
                    address: inputData,
                    date: date,
                    time: time,
                    lat: latLng.lat,
                    lng: latLng.lng,
                  };
                  LocalStorage.set("location", dataValue);
                  const db = new Dexie(res.content.menu.unit_id);
                  db.version(1).stores({
                    menu: "++id, categories, item_tags, taxes",
                  });
                  const getMenuData = async () => {
                    const allData = (await db.menu.toArray()) || [];
                    if (allData && allData.length > 0) {
                      await db.menu.update(0, res.content.menu);
                    } else {
                      await db.menu.add(res.content.menu);
                    }
                    history.push(ROUTES.HOME);
                  };
                  getMenuData();
                  // onCallMenu();
                  // const getMenuData = async () => {
                  //   const allData = (await db.menu.toArray()) || [];
                  //   if (allData && allData.length > 0) {
                  //     history.push(ROUTES.PRODUCTLIST);
                  //   }
                  // };
                  // getMenuData();
                  // history.push(ROUTES.PRODUCTLIST);
                }
              } else {
                setAlertPopup(
                  "Sorry!! No Data Found. Please try again later sometime"
                );
              }
            }
          );
        }
      }
    });
  };

  // const handleGoBack = () => {
  //   //
  //   history.goBack();
  // };

  const close = () => {
    setModal(false);
  };

  const closedelivery = () => {
    // LocalStorage.set('loginflow', false)
    // setLoginFLow(false)
    setDeliveryFlow(false);
  };

  const functionReload = () => window.location.reload();

  return (
    <>
      {
        <Helmet>
          <title>{staticDataNew?.meta_title}</title>
          <meta name="description" content={staticDataNew?.meta_description} />
          <meta name="keywords" content={staticDataNew?.meta_keywords} />
        </Helmet>
      }
      {innerWidth <= 1023 ? (
        <HeaderMobile showButton={showButton} showLocation={showLocation} />
      ) : (
        <HeaderCommon />
      )}
      {deliveryFlow && (
        <Modal
          modalCustomClass="modalCustomEdit"
          onCloseHandler={closedelivery}
          hideClose={stat === "stage2" || stat === "stage3"}
          isMobileFullLayout
        >
          <div className="deliveryContainer">
            {/* <div className="deliveryContainer_left">
            <img
              src={LocationImage}
              alt="img"
              height="100%"
              width="100%"
              className="deliveryContainer_left_img"
            />
          </div> */}
            <div className="deliveryContainer_right">
              {/* <img src={Logo} width="157" height="157" /> */}
              <h2 className="deliveryContainer_heading">
                {/* Delivering a Royal Feast to your doorsteps! */}
                Enter Location
              </h2>
              <div className="deliveryContainer_switches">
                <Switch
                  data={switchData}
                  active={active}
                  onFilter={onChangeMode}
                />
                <div className="deliveryContainer_switches_gap" />
                <RadioButton
                  data={radioData}
                  activeOrder={activeOrder}
                  onChange={onChangeOrder1}
                />
              </div>

              <div className="deliveryContainer_delivery">
                <div className="deliveryContainer_delivery_complete">
                  <SearchAutoComplete
                    onSearchChange={onMarkerPosition}
                    onChangeSearch={onChangeSearch}
                    inputData={
                      savedAddress && savedAddress.length > 0
                        ? inputData.address
                        : inputData
                    }
                  />
                </div>
                <Button
                  content="Submit"
                  className="deliveryContainer_delivery_button"
                  onClick={() => onFindFood()}
                />
              </div>
              {activeOrder === "orderLater" && (
                <div className="deliveryContainer_orderLater">
                  <div className="deliveryContainer_orderLater_container">
                    {/* <span className='deliveryContainer_orderLater_label'>
              Enter Date
            </span> */}
                    <div className="deliveryContainer_orderLater_date">
                      <DateTimePicker
                        onChange={(value) => onChangeDate(value)}
                        value={date}
                        className="deliveryContainer_orderLater_datetime"
                        format="yyyy-MM-dd"
                        placeholderText="Enter date"
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().getTime() + 4 * 24 * 60 * 60 * 1000
                          )
                        }
                        clearIcon={date === "" ? "" : "x "}
                        yearPlaceholder="Enter date"
                        monthPlaceholder=""
                        dayPlaceholder=""
                      />
                    </div>
                  </div>
                  <div className="deliveryContainer_orderLater_container">
                    {/* <span className='deliveryContainer_orderLater_label'>
              Enter Time
            </span> */}
                    {intervalModal && date !== "" && (
                      <div className="deliveryContainer_orderLater_container_drop">
                        <IntervalDrop
                          setInterval={setInterval}
                          interval={interval}
                          intervalModal={intervalModal}
                          setIntervalModal={setIntervalModal}
                          setTime={setTime}
                          timeInterval={intDate}
                        />
                      </div>
                    )}

                    <div className="deliveryContainer_orderLater_time">
                      {/* <DateTimePicker
              onChange={(value) => onChangeTime(value)}
              value={time}
              className='deliveryContainer_orderLater_datetime'
              format='yyyy-MM-dd'
              placeholderText='Enter time'
            /> */}
                      {/* <Input
                      type={timeInp}
                      onChange={(_id, value) => onChangeTime(value)}
                      value={time}
                      onFocus={() => setTimeInp("time")}
                      placeholder="Enter time"
                      customClass="deliveryContainer_orderLater_datetime"
                    /> */}

                      <p
                        onClick={() => {
                          setIntervalModal(!intervalModal);
                        }}
                        className="deliveryContainer_orderLater_datetime"
                      >
                        {interval === "" ? "Select Interval" : interval}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* {alertData && (
                <p className="deliveryContainer_delivery_phrase">
                  Please Enter location !!
                </p>
              )} */}
              <div
                className="deliveryContainer_location"
                onClick={() => onCurrentLocations()}
              >
                <img src={Detect} width="14" height="14" alt="Detect" />
                <p className="deliveryContainer_text">
                  {active === "delivery" || activeOrder === "orderLater"
                    ? "Use current location"
                    : "Restaurant near you"}
                </p>
              </div>

              {/* {outlets && outlets.length > 0 && (
              <>
                <h4 className="deliveryContainer_error_recommend">
                  Search Result
                </h4>
                {outlets.map((item) => {
                  return (
                    <StoreCard
                      data={item}
                      theme={active}
                      onClickStore={onClickStore}
                    />
                  );
                })}
              </>
            )} */}
              {/* <p className='deliveryContainer_skip' onClick={onSkipLocation}>
        Skip this for now
      </p> */}

              {errorData && (
                <>
                  <div className="deliveryContainer_error">
                    <h5 className="deliveryContainer_error_text">
                      90% of people want home delivery on location x. if we
                      reach 10% More then we would open a new restaurant so you
                      can enjoy our food. are you interested? Yes I'm interested
                    </h5>
                    <img
                      src={LocationSearch}
                      width="132"
                      height="117"
                      alt="LocationSearch"
                    />
                  </div>
                </>
              )}
              {errorData1 && countData && countData.count && (
                <>
                  <h4 className="deliveryContainer_error_recommend">
                    Recommend  Restaurants
                  </h4>
                  <div className="deliveryContainer_error">
                    <p className="deliveryContainer_error_text1">
                      Hey ! found {countData.count} bbk restaurant{" "}
                      {countData.distance} km from your location would you do
                      take away from these restaurants
                    </p>
                    <div className="deliveryContainer_error_button">
                      <Button
                        content="Search Now"
                        onClick={() => onStoreSelect()}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {popup && (
              <MapModal
                onCurrentDetectLocation={(value) =>
                  onCurrentLocationData(value)
                }
                onBackClick={() => onBackClick()}
                activeTheme={active}
                inputData={inputData}
                onChangeInput={() => onChangeInput()}
                onCenterChange={() => onCenter()}
                latLng={latLng}
                onMarkerPosition={onMarkerPosition}
                onConfirmLocation={() => onConfirmLocation()}
                onClickStore={() => onClickStore(outlets && outlets[0])}
              />
            )}
            {alertPopup && (
              <AlertPopup
                title="Sorry!!"
                description={alertPopup}
                okayClick={() => onCloseAlert()}
              />
            )}
          </div>
        </Modal>
      )}
      <div className="staticPage">
        <div
          dangerouslySetInnerHTML={{
            __html: staticDataNew?.html_content,
          }}
        />
      </div>
      <Footer data={footerData && footerData} />
    </>
  );
};
export default StaticPage;
