import React from "react";
import PropTypes from "prop-types";
import Loader from "../../components/Loader";
import "./index.scss";
import DishImg from "../../assets/images/imli-adral-lemonade@3x.png";
import ImgNotAvailable from "../../assets/images/image-not-available.jpg";

const ItemCardNew = (props) => {
  const { data } = props;
  const { innerWidth } = window;

  return (
    <>
      {innerWidth <= 1023 ? (
        <div className='itemCardNew'>
          <img
            src={data.image_url ? data.image_url : ImgNotAvailable}
            className='itemCardNew_img'
            onError={(e) => { e.target.onerror = null; e.target.src = ImgNotAvailable }}
            alt="Img not available"
          />
          <div className='itemCardNew_data'>
            <div>
              <div className='itemCardNew_data_header'>
                <div
                  className={`itemCardNew_data_type ${data.is_veg === false && 'itemCardNew_data_typeSwitch'
                    }`}
                />
                <p className='itemCardNew_data_nameLabel'>
                  {data.name + ' X' + parseInt(data.item_count)}
                </p>
              </div>

              {data.addons && data.addons.length && data.addons.length > 0 ? (
                <div className='itemCardNew_data_addons'>
                  {data.addons.slice(0, 3).map((data) => (
                    <div>{data.item_name}</div>
                  ))}
                  {data.addons.length > 2 && "..."}
                </div>
              ) : (
                ''
              )}
            </div>


          </div>
        </div>
      ) : (
        <div className='itemCardNew'>
          <img
            src={data.image_url ? data.image_url : ImgNotAvailable}
            className='itemCardNew_img'
            onError={(e) => { e.target.onerror = null; e.target.src = ImgNotAvailable }}
            alt="img not available"
          />
          <div className='itemCardNew_data'>
            <div>
              <div
                className={`itemCardNew_data_type ${data.is_veg === false && 'itemCardNew_data_typeSwitch'
                  }`}
              />
            </div>
            <p className='itemCardNew_data_nameLabel'>
              {data.name + ' X' + parseInt(data.item_count)}
            </p>
          </div>
          {data.addons && data.addons.length && data.addons.length > 0 ? (
            <div className='itemCardNew_data_addons'>
              {data.addons.slice(0, 3).map((data) => (
                <div>{data.item_name}</div>
              ))}
              {data.addons.length > 2 && "..."}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};
export default ItemCardNew;
