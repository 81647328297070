import React, { useState, useEffect } from "react";
import "./index.scss";
import Back from "../../assets/images/path.svg";
import Accept from "../../assets/images/acceptOrder.svg";
import TrackRider from "../../assets/images/trackrider.png"
import ellipse from "../../assets/images/Ellipse.png"
import biryani from "../../assets/images/biryani.png"
import CancelOrderCard from "../../components/cancel-card";
import OrderDetail from "../../components/order-detail";
import StepProcess from "../../components/step-process";
import VideoCard from "../../components/video-card";
import ItemDetails from "../../components/item-details";
import OutletDetails from "../../components/outlet-detail";
import SupportDetails from "../../components/support-card";
import congratulations from "../../assets/images/congrats.svg";
import supportImg from "../../assets/images/support.svg";
import cancelOrderImg from "../../assets/images/orderCancel.svg";
import Call from '../../assets/images/contact.png';
import percentRed from "../../assets/images/discount.png";
import deliveryicon from '../../assets/images/delivery-icon-45min.png';
import orderPickedImg from "../../assets/images/order-picked.png";
import orderPreparedImg from "../../assets/images/order-prepared.svg";

import message from "../../assets/images/message.svg";
import PreparingOrder from "../../assets/images/preparingOrder.svg";
import cancelclose from "../../assets/images/cancelclose.svg";
import back from "../../assets/images/goback.svg";
import OrderCancellation from "../../components/order-cancellation-popup";
import { cancelOrder, getOrderTrack, getOrderTrackStatus } from "../../actions/cart/index";
import { LocalStorage } from "../../utils/storage";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
import debug from "debug";
import Header from "../../containers/header";
import Modal from "../../components/modal";
import cancelImage from "../../assets/images/cancelled-event.svg";
import goback from "../../assets/images/goback.svg";
import Button from "../../components/button";
import moment from "moment";
import HeaderCommon from "../../components/header-common-component";
import refreshImg from "../../assets/images/Refresh.svg";
import {
  handleConvertToIST,
  handleParseAmount,
} from "../../helpers/sharedFunction";
import { productPurchaseNetCoreEvent } from "../../helpers/netcore";
import { Analytics } from "../../helpers/analytics";
import AmountSummary from "../../components/amount-summary";
import { getHomePage } from "../../actions/home";
import { claimGiftCardApi } from "../../actions/giftcards";

function TrackOrder() {
  const [modalShow, setModalShow] = useState(false);
  const [trackData, setTrackData] = useState({});
  const [timeData, setTimeData] = useState();
  const [steps, setSteps] = useState([]);
  const [loyaltyNumber, setLoyaltyNumber] = useState("");
  const [cancel, setCancel] = useState(false);
  const history = useHistory();
  const [status, setStatus] = useState({});
  const [statusData, setStatusData] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [deliveryPoint, setDeliveryPoint] = useState("");
  let newMinutes;
  let [deliveryTimeShow, setDeliveryTimeShow] = useState(false);
  let [deliveryMinutes, setDeliveryMinutes] = useState("");
  const [isPreorder, setIsPreOrder] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentMobileIndex, setCurrentMobileIndex] = useState(0);

  let secondsDiff;
  let locationDt = LocalStorage.get("location");
  let offerCode = LocalStorage.get("offerwholedata");
  const [isToofani, setIsToofani] = useState();
  const [surchargeAmont, setSurchargeAmont] = useState("");
  const [webSlider, setWebSlider] = useState([]);
  const [mobileSlider, setMobileSlider] = useState([]);
  const [statusSequence, setStatusSequence] = useState();

  const trackingDatas = () => {
    const queryParams = LocalStorage.get("purchaseDetailId");
    getOrderTrack((res) => {
      if (res?.response && res?.response.status === 200) {
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("loyalty");
        LocalStorage.remove("offerAmount");
        setTrackData(res.content);
        let webBanners = [];
        let appBanners = [];
        res?.content?.tracking_Details?.banner?.large.forEach(banner => {
          if (banner.source === "web") {
            webBanners.push({ heading: banner.heading, image: banner.banner_image, url: banner.cta_value });
          } else if (banner.source === "app") {
            appBanners.push({ heading: banner.heading, image: banner.banner_image, url: banner.cta_value });
          }
        });

        setWebSlider(webBanners);
        setMobileSlider(appBanners);
        (res.content.tracking_Details.delivery_surcharge) &&
          setSurchargeAmont(parseInt(res.content.tracking_Details.delivery_surcharge));
        setIsToofani(res.content.tracking_Details.is_toofani);
        let theDate = new Date(
          Date.parse(
            res?.content?.tracking_Details?.order_details?.order_created_at
          )
        );
        let orderCreated =
          res?.content?.tracking_Details?.order_details?.order_created_at;
        var currentTimeUTC = new Date(orderCreated);
        var currentTimeIST = moment(currentTimeUTC)
          .add(5, "hours")
          .add(30, "minutes");
        setTimeData(moment(currentTimeIST._d.toString()).format("h:mm a"));
        res.content.tracking_Details.order_status &&
          res.content.tracking_Details.order_status.length > 0 &&
          res.content.tracking_Details.order_status.map((item) => {
            if (item.status === "order_accepted") {
              estimate(res.content.tracking_Details, item.created_at);
            }
          });

        if (
          res &&
          res?.content &&
          res?.content?.tracking_Details?.order_details
        ) {
          //  
          setLoyaltyNumber(
            res?.content?.tracking_Details?.order_details?.earned_loyalty_points
          );
        }
        // Data for StepProcess component
        if (
          res.content &&
          res.content.tracking_Details &&
          res.content.tracking_Details.order_status_sequence &&
          res.content.tracking_Details.order_status
        ) {
          let stepData = [];
          let removeStepCount = 2;
          let stepToRemoveList = ["order_cancelled", "order_delayed"];
          setStatusSequence(res.content.tracking_Details.order_status_sequence)
          const orderStatusSequenceObj = res.content.tracking_Details.order_status_sequence;
          const orderStatusList = res.content.tracking_Details.order_status;
          let stepKeyList = Object.keys(orderStatusSequenceObj);
          console.log("stepKeyList 1", stepKeyList, stepData, removeStepCount);
          orderStatusList.map((data) => {
            if (stepKeyList.includes(data.status)) {
              stepData.push({
                checked: true,
                display_name: orderStatusSequenceObj[data.status].display_name,
                id: data.status,
                time: handleConvertToIST(data.created_at),
              })
              let index = stepKeyList.indexOf(data.status);
              stepKeyList = stepKeyList.slice(0, index).concat(stepKeyList.slice(index + 1, stepKeyList.length));
              if (stepToRemoveList.includes(data.status)) {
                removeStepCount--;
              }
            }
          })
          for (let i = 0; i < stepKeyList.length - removeStepCount; i++) {
            stepData.push({
              checked: false,
              display_name: orderStatusSequenceObj[stepKeyList[i]].display_name,
              id: stepKeyList[i],
              // time: "",
            })
          }
          setSteps(stepData);

          // Image Setting...
          let img = null;
          let caption = null;
          let currentStatusId = orderStatusList[orderStatusList.length - 1].status;
          if (currentStatusId === "order_delayed") {
            if (orderStatusList.length - 2 >= 0) {
              currentStatusId = orderStatusList[orderStatusList.length - 2].status;
            }
          }
          switch (currentStatusId) {
            case "order_placed":
              img = Accept;
              caption = "We will confirm your order soon"
              break;
            case "order_accepted":
              img = PreparingOrder;
              caption = "Preparing your order";
              break;
            case "order_preparing":
              img = orderPreparedImg;
              caption = "Your food is ready for the pickup"

              break;
            case "order_shipped":
              img = orderPickedImg;
              caption = "Order picked up";
              break;
            case "order_arriving":
              setStatusData(true);
              break;
            case "order_delivered":
              img = PreparingOrder;
              caption = "Order got delivered";
              setStatusData(true);
              break;
            case "order_cancelled":
              img = cancelOrderImg;
              caption = "Order cancelled";
              break;
            default:
              break;
          }
          setStatus({
            img: img,
            caption: caption,
          });
        }
        // if (
        //   res.content &&
        //   res.content.tracking_Details &&
        //   res.content.tracking_Details.status_key &&
        //   res.content.tracking_Details.order_status
        // ) {
        //   // let orderStatus = res.content.tracking_Details.order_status;
        //   if (res.content.tracking_Details.status_key.length > 0) {
        //     const trackDetail = res.content.tracking_Details.status_key.filter(
        //       (item) => item.default_display === 1
        //     );
        //     console.log(trackDetail,"track order detail")
        //     const stepProcess = trackDetail.map((itm) => {
        //       res.content.tracking_Details.order_status.map((item) => {
        //         item.checked = false;
        //         if (item.status === itm.id) {
        //           itm.checked = true;
        //           itm.time = handleConvertToIST(item.updated_at);
        //         }
        //       });
        //       return itm;
        //     });
        //     const updateImg = stepProcess.filter((itm) => itm.checked === true);
        //     if (updateImg.length === 1) {
        //       setStatus({
        //         img: Accept,
        //         caption: "We will confirm your order soon",
        //       });
        //     }
        //     if (updateImg.length === 2) {
        //       if (
        //         updateImg &&
        //         updateImg[1] &&
        //         updateImg[1].id === "order_cancelled"
        //       ) {
        //         setStatus({
        //           img: cancelOrderImg,
        //           caption: "Order cancelled",
        //         });
        //       } else {
        //         setStatus({
        //           img: PreparingOrder,
        //           caption: "Preparing your order",
        //         });
        //       }
        //     }
        //     if (updateImg.length === 3) {
        //       setStatus({
        //         img: orderPreparedImg,
        //         caption: "Your food is ready for the pickup",
        //       });
        //     }
        //     if (updateImg.length === 4) {
        //       setStatus({
        //         img: orderPickedImg,
        //         caption: "Order picked up",
        //       });
        //     }

        //     if (
        //       updateImg.length > 4
        //       // &&
        //       // updateImg.length >= updateImg[updateImg.length - 1]
        //     ) {
        //       setStatusData(true);
        //     }
        //     if (updateImg.length === updateImg[updateImg.length - 1]) {
        //       setStatus({
        //         img: PreparingOrder,
        //         caption: "Order got delivered",
        //       });
        //       onHomeClick();
        //     }
        //     setSteps(stepProcess);
        //   }
        // }
      }
    }, queryParams);
  }
  const onTrackingStatus = () => {
    const queryParams = LocalStorage.get("purchaseDetailId");

    getOrderTrackStatus((res) => {
      console.log("STATUS TRACKING RESPONSE", statusSequence)


      // Data for StepProcess component
      if (
        res.content &&
        res.content.order_status
      ) {
        let stepData = [];
        let removeStepCount = 2;
        let stepToRemoveList = ["order_cancelled", "order_delayed"];
        const orderStatusList = res.content.order_status;
        let stepKeyList = Object.keys(statusSequence);
        console.log("stepKeyList 1", stepKeyList, stepData, removeStepCount);
        orderStatusList.map((data) => {
          if (stepKeyList.includes(data.status)) {
            stepData.push({
              checked: true,
              display_name: statusSequence[data.status].display_name,
              id: data.status,
              time: handleConvertToIST(data.created_at),
            })
            let index = stepKeyList.indexOf(data.status);
            stepKeyList = stepKeyList.slice(0, index).concat(stepKeyList.slice(index + 1, stepKeyList.length));
            if (stepToRemoveList.includes(data.status)) {
              removeStepCount--;
            }
          }
        })
        for (let i = 0; i < stepKeyList.length - removeStepCount; i++) {
          stepData.push({
            checked: false,
            display_name: statusSequence[stepKeyList[i]].display_name,
            id: stepKeyList[i],
            // time: "",
          })
        }
        setSteps(stepData);

        // Image Setting...
        let img = null;
        let caption = null;
        let currentStatusId = orderStatusList[orderStatusList.length - 1].status;
        if (currentStatusId === "order_delayed") {
          if (orderStatusList.length - 2 >= 0) {
            currentStatusId = orderStatusList[orderStatusList.length - 2].status;
          }
        }
        switch (currentStatusId) {
          case "order_placed":
            img = Accept;
            caption = "We will confirm your order soon"
            break;
          case "order_accepted":
            img = PreparingOrder;
            caption = "Preparing your order";
            break;
          case "order_preparing":
            img = orderPreparedImg;
            caption = "Your food is ready for the pickup"

            break;
          case "order_shipped":
            img = orderPickedImg;
            caption = "Order picked up";
            break;
          case "order_arriving":
            setStatusData(true);
            break;
          case "order_delivered":
            img = PreparingOrder;
            caption = "Order got delivered";
            setStatusData(true);
            break;
          case "order_cancelled":
            img = cancelOrderImg;
            caption = "Order cancelled";
            break;
          default:
            break;
        }
        setStatus({
          img: img,
          caption: caption,
        });
      }

    }, queryParams);
  }
  useEffect(() => {
    trackingDatas();


    // onTrack();




  }, []);

  useEffect(() => {
    // Call trackingDatas every 1 minute
    const interval = setInterval(() => {
      onTrackingStatus();
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [statusSequence])



  useEffect(() => {
    if (deliveryMinutes > 0 && deliveryTimeShow) {
      const timeout = setTimeout(() => {
        setDeliveryMinutes(prevState => prevState - 1);
      }, (10000 * 6));
      return () => clearTimeout(timeout);
    }
    else {
      setDeliveryTimeShow(false);
    }
  }, [deliveryMinutes, deliveryTimeShow]);

  useEffect(() => {

    if (history?.location?.state?.isFromCheckOut) {

      console.log("history?.location?.state?.analyticsPayload", history?.location?.state?.analyticsPayload);
      // productPurchaseNetCoreEvent(history?.location?.state?.payload)
      Analytics.purchaseEvent(history?.location?.state?.analyticsPayload);
      window.history.replaceState({}, "");
    }
  }, []);

  const estimate = (data, createTime) => {
    // debugger;
    setDeliveryPoint(data?.order_details?.delivery_type);
    let typedel =
      data?.order_details?.delivery_type === "take_away" ? 1800 : 3600; {/*data?.order_details.delivery_time * 60 */ }
    let orderCreated = createTime;
    let sTime = new Date(orderCreated);
    var currentTimeUTC = new Date(orderCreated);
    var currentTimeIST = moment(currentTimeUTC)
      .add(5, "hours")
      .add(30, "minutes");
    let hour = new Date().getHours();
    let min = new Date().getMinutes();
    let seconds = new Date().getSeconds();
    let date = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();

    console.log(year, "createtime ist")
    console.log(currentTimeIST._d.getDate(), "createtime ist")
    let order_hour = currentTimeIST._d.getHours();
    let order_min = currentTimeIST._d.getMinutes();
    let order_sec = currentTimeIST._d.getSeconds();
    let time_taken;
    order_hour += Math.floor((order_min + data.delivery_time) / 60);
    order_min = (order_min + data.delivery_time) % 60;
    console.log(hour, min, seconds, order_hour, order_min, order_sec, "createtime ist")
    let hourDiff = (order_hour > hour) ? Math.abs(order_hour - hour) - 1 : Math.abs(order_hour - hour);
    let minDiff = (order_hour > hour) ? (60 - min) + order_min : order_min - min;

    // console.log(hourDiff, " hour ", minDiff, " min ", " createtime ist");
    time_taken = hourDiff * 60 + minDiff;
    // console.log(time_taken, " createtime ist");

    setDeliveryMinutes(time_taken);

    if (order_hour < hour || date > currentTimeIST._d.getDate() || month > currentTimeIST._d.getMonth() || year > currentTimeIST._d.getFullYear())
      setDeliveryMinutes(0)

    // var startTime = moment(currentTimeIST, "DD-MM-YYYY hh:mm:ss");
    // var endTime = moment(new Date(), "DD-MM-YYYY hh:mm:ss");
    // secondsDiff = Math.abs(endTime.diff(startTime, "seconds"));
    // if (secondsDiff > typedel) return;
    // setDeliveryMinutes(Math.floor((typedel - secondsDiff) / 60));
    const isOrderDelivered = data.order_status.some(
      (data) => data.status === "order_delivered"
    );

    if (
      !isOrderDelivered &&
      !data.order_details.pre_order_date &&
      !data.order_details.pre_order_time
    ) {
      setDeliveryTimeShow(true);
    } else {
      setIsPreOrder(true);
    }

    if (Math.floor((typedel - secondsDiff) / 60) === 0) {
      setDeliveryTimeShow(false);
    }
  };

  const onTrack = () => {
    const queryParams = LocalStorage.get("purchaseDetailId");
    getOrderTrack((res) => {
      if (res?.response && res?.response.status === 200) {
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("loyalty");
        LocalStorage.remove("offerAmount");
        setTrackData(res.content);
        let orderCreated =
          res?.content?.tracking_Details?.order_details?.order_created_at;
        // let sTime = new Date(orderCreated);
        var currentTimeUTC = new Date(orderCreated);
        var currentTimeIST = moment(currentTimeUTC)
          .add(5, "hours")
          .add(30, "minutes");
        setTimeData(moment(currentTimeIST._d.toString()).format("h:mm a"));
        // console.log(res.content.tracking_Details.order_status[res.content.tracking_Details.order_status.length-1].status, " status herree")
        // Data for StepProcess component
        if (
          res.content &&
          res.content.tracking_Details &&
          res.content.tracking_Details.order_status_sequence &&
          res.content.tracking_Details.order_status
        ) {
          let stepData = [];
          let removeStepCount = 2;
          let stepToRemoveList = ["order_cancelled", "order_delayed"];
          const orderStatusSequenceObj = res.content.tracking_Details.order_status_sequence;
          const orderStatusList = res.content.tracking_Details.order_status;
          let stepKeyList = Object.keys(orderStatusSequenceObj);
          console.log("stepKeyList 1", stepKeyList, stepData, removeStepCount);
          orderStatusList.map((data) => {
            if (stepKeyList.includes(data.status)) {
              stepData.push({
                checked: true,
                display_name: orderStatusSequenceObj[data.status].display_name,
                id: data.status,
                time: handleConvertToIST(data.created_at),
              })
              let index = stepKeyList.indexOf(data.status);
              stepKeyList = stepKeyList.slice(0, index).concat(stepKeyList.slice(index + 1, stepKeyList.length));
              if (stepToRemoveList.includes(data.status)) {
                removeStepCount--;
              }
            }
          })
          for (let i = 0; i < stepKeyList.length - removeStepCount; i++) {
            stepData.push({
              checked: false,
              display_name: orderStatusSequenceObj[stepKeyList[i]].display_name,
              id: stepKeyList[i],
              // time: "",
            })
          }
          setSteps(stepData);

          // Image Setting...
          let img = null;
          let caption = null;
          let currentStatusId = orderStatusList[orderStatusList.length - 1].status;
          if (currentStatusId === "order_delayed") {
            if (orderStatusList.length - 2 >= 0) {
              currentStatusId = orderStatusList[orderStatusList.length - 2].status;
            }
          }
          switch (currentStatusId) {
            case "order_placed":
              img = Accept;
              caption = "We will confirm your order soon"
              break;
            case "order_accepted":
              img = PreparingOrder;
              caption = "Preparing your order";
              break;
            case "order_preparing":
              img = orderPreparedImg;
              caption = "Your food is ready for the pickup"
              break;
            case "order_shipped":
              img = orderPickedImg;
              caption = "Order picked up";
              break;
            case "order_arriving":
              setStatusData(true);
              break;
            case "order_delivered":
              img = PreparingOrder;
              caption = "Order got delivered";
              setStatusData(true);
              break;
            default:
              break;
          }
          setStatus({
            img: img,
            caption: caption,
          });
        }
        // if (
        //   res.content &&
        //   res.content.tracking_Details &&
        //   res.content.tracking_Details.status_key &&
        //   res.content.tracking_Details.order_status
        // ) {
        //   if (res.content.tracking_Details.status_key.length > 0) {
        //     res.content.tracking_Details.order_status &&
        //       res.content.tracking_Details.order_status.length > 0 &&
        //       res.content.tracking_Details.order_status.map((item) => {
        //         if (item.status === "order_accepted") {
        //           estimate(res.content.tracking_Details, item.created_at);
        //         }
        //       });

        //     const trackDetail = res.content.tracking_Details.status_key.filter(
        //       (item) => item.default_display === 1
        //     );
        //     const stepProcess = trackDetail.map((itm) => {
        //       res.content.tracking_Details.order_status.map((item) => {
        //         item.checked = false;
        //         if (item.status === itm.id) {
        //           itm.checked = true;
        //         }
        //       });
        //       return itm;
        //     });
        //     const updateImg = stepProcess.filter((itm) => itm.checked === true);
        //     if (updateImg.length === 1) {
        //       setStatus({
        //         img: Accept,
        //         caption: "Waiting Hotel to Confirm your order",
        //       });
        //     }
        //     if (updateImg.length === 2) {
        //       setStatus({
        //         img: PreparingOrder,
        //         caption: "Preparing your order",
        //       });
        //     }
        //     if (
        //       updateImg.length > 2 &&
        //       updateImg.length > updateImg[updateImg.length - 1]
        //     ) {
        //       setStatusData(true);
        //     }
        //     if (updateImg.length === updateImg[updateImg.length - 1]) {
        //       setStatus({
        //         img: PreparingOrder,
        //         caption: "Order got delivered",
        //       });
        //       onHomeClick();
        //     }
        //     setSteps(stepProcess);
        //   }
        // }
        setTimeout(() => onTrack(), 10000);
      }
    }, queryParams);
  };

  const onShowCancel = (value) => {
    setCancel(value);
  };

  const congrats = {
    name: "Congratulations",
    // description: "You just earned " + loyaltyNumber + "points",
  };
  const support = {
    name: "Customer Care",
    description:
      "1800-212-2-212",
  };

  const outletData = {
    img: Accept,
    outletName: "BBK",
    description: "Lorem Ipsum!",
  };
  const itemData = {
    img: Accept,
    itemName: "Guntur Chicken Biryani",
    duration: "30",
    description: "Extra Spicy!",
    price: "500",
  };
  const instruction = {
    thumbnailImg: "",
    videoURL: "",
    videoName: "Opening the Box",
  };
  const orderDetail = {
    id: "12345",
    date: "07/12/2021",
    deliveryTo: "Koramangala, Bangalore",
    card: "1234****",
  };
  const cartshow = () => {
    //  
    if (innerWidth <= 1023) {
      const queryParams = LocalStorage.get("purchaseDetailId");
      cancelOrder(queryParams, (res) => {
        if (res.response.status === 200) {
          //  
          setModalShow(!modalShow);
          // LocalStorage.remove("purchaseDetailId");
        }
        // history.push("/cancel-page");
      });
    } else {
      // setCancelModal(true);
      const queryParams = LocalStorage.get("purchaseDetailId");
      cancelOrder(queryParams, (res) => {
        //  
        if (res.response.status === 200) {
          //  
          setModalShow(!modalShow);
          // LocalStorage.remove("purchaseDetailId");
        }
      });
    }
  };

  const handleCloseModal = () => {
    // if (innerWidth <= 1023) {
    //   history.push("/cancel-page");
    // }else{
    setModalShow(false);
    // }
  };

  const onHomeClick = () => {
    if (history.location.state === true) history.goBack();
    else history.push(ROUTES.HOME);
  };

  const onNewHomeClick = () => {
    LocalStorage.remove("EndKey");
    history.push(ROUTES.HOME);
  };
  const { innerWidth } = window;
  const handleCancelPage = () => setCancelModal(false);
  const handleHome = () => history.push("/");

  const handleAddonCount = (item) => {
    const itemPrice =
      handleParseAmount(item.final_price) / handleParseAmount(item.item_count);
    const itemCount = handleParseAmount(item.item_count);
    return (
      <div className="card-sidebar__products__addons-wrapper">
        <p className="card-sidebar__products__extras">
          {item.item_name} {` [Rs ${itemPrice}]`}
        </p>
        <p className="card-sidebar__products__extras">[{itemCount}]</p>
      </div>
    );
  };
  useEffect(() => {
    if (trackData && trackData.tracking_Details && trackData.tracking_Details?.order_status) {
      const orderStatus = trackData.tracking_Details?.order_status;
      const status = orderStatus.length != 0 ? orderStatus[orderStatus.length - 1].status : ""
      if (status === 'order_cancelled' || status === "order_delayed" || status === "order_delivered" || status === "order_placed" || status === "order_arriving" || status === "order_shipped" || deliveryMinutes <= 0) {
        setDeliveryTimeShow(false)
      }
      else {
        setDeliveryTimeShow(true);
      }
      console.log(orderStatus[orderStatus.length - 1], " status")
    }
  })

  const trackOrderdata = trackData?.tracking_Details?.order_details;

  const billingDetails = [
    {
      name: "Sub Total",
      amount: `₹ ${Math.floor(
        Number(
          trackOrderdata?.final_price
        )
      )}`,
      show: true,
    },
    {
      name: "Discount",
      amount: ` - ₹ ${Math.floor(
        Number(
          trackOrderdata?.offer_discount
        )
      )}`,
      show:
        Math.floor(
          Number(
            trackOrderdata?.offer_discount
          )
        ) === 0
          ? false
          : true,
    },
    {
      name: "Loyalty Discount",
      amount: ` - ₹ ${Number(trackOrderdata?.loyalty_discount).toFixed(2)}`,
      show: trackOrderdata?.loyalty_discount > 0 ? true : false,
    },
    {
      name: "Donation",
      amount: `+ ₹ ${Math.floor(Number(trackOrderdata?.donation))}`,
      show: Math.floor(
        Number(trackOrderdata?.donation)
      ) === 0.0 ? false : true,
    },
    {
      name: "Delivery charge",
      amount: `${`₹ ${Math.floor(
        Number(
          trackOrderdata?.delivery_charges
        )
      )}`
        }`,
      show: trackOrderdata?.delivery_type === "takeaway" ? false : true,
      // textColor:
      //   initialBillingData?.strip_data?.delivery_strip
      //     ?.is_show_free_delivery === true
      //     ? true
      //     : false,
    },
    surchargeAmont && surchargeAmont > 0 &&
    {
      name: "Delivery Surcharge",
      amount: `₹ ${surchargeAmont}`,
      show: surchargeAmont && surchargeAmont > 0
    },
    {
      name: "Packing charge",
      amount: `${`₹ ${Math.floor(
        Number(
          trackOrderdata?.package_charges
        )
      )}`
        }`,
      show: true,
      // textColor:
      //   initialBillingData?.strip_data?.packing_charge_strip
      //     ?.is_show_free_packing_charge === true
      //     ? true
      //     : false,
    },
    {
      name: "Tip",
      amount: `${`+ ₹ ${Math.floor(Number(trackOrderdata?.delivery_tips))}`}`,
      show: Math.floor(Number(
        trackOrderdata?.delivery_tips)) === 0 ? false : true,
    },
    {
      name:
        `CGST`,
      amount: `₹ ${Number(
        parseFloat(
          trackOrderdata?.cgst
        ).toFixed(2)
      )}`,
      show: true,
    },
    {
      name:
        `SGST`,
      amount: `₹ ${Number(
        parseFloat(
          trackOrderdata?.sgst
        ).toFixed(2)
      )}`,
      show: true,
    },
    // {
    //   name: "Gift card",
    //   amount: ` - ₹ ${giftVoucherAmtData}`,
    //   show: giftVoucherAmtData ? true : false,
    // },
    {
      name: "Total Amount",
      amount: `₹ ${Math.floor(
        Number(
          trackOrderdata?.paid_amount
        )
      )}`,
      show: true,
    },

    {
      name: "Payment Mode",
      amount:
        trackOrderdata?.mode_of_payment,
      show: true,
    },
  ];
  const handleCallClick = () => {
    const phoneNumber = '18002122212';
    window.location.href = `tel:${phoneNumber}`;
  };
  const ToofaniTag = () => {
    return (<div className="isToofani_track_order">
      <img src={deliveryicon} alt="45" />
      <p>All items are within 45 min delivery</p>
    </div>)
  }
  // Function to advance to the next slide
  const nextWebSlide = () => {
    if (currentIndex < webSlider.length - 1) {
      const newIndex = currentIndex + 1;
      console.log("NEW SLIDE INDEX", newIndex);
      setCurrentIndex(newIndex);
    }
    else {
      setCurrentIndex(0);
    }
  };
  const nextMobileSlide = () => {
    if (currentMobileIndex < mobileSlider.length - 1) {
      const newIndex = currentMobileIndex + 1;
      console.log("NEW SLIDE INDEX", newIndex);
      setCurrentMobileIndex(newIndex);
    }
    else {
      setCurrentMobileIndex(0);
    }
  };

  // Automatically advance to the next slide every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(nextWebSlide, 5000);
    return () => clearInterval(intervalId);
  }, [currentIndex, webSlider]);
  useEffect(() => {
    const intervalId = setInterval(nextMobileSlide, 5000);
    return () => clearInterval(intervalId);
  }, [currentMobileIndex, mobileSlider]);
  const dots = webSlider.map((slide, index) => (
    <div
      key={slide}
      className={index === currentIndex ? 'activeDotStyle' : 'dotStyle'}
      onClick={() => {
        console.log('Image Changed');
        setCurrentIndex(index);
      }}
    />
  ));
  const Mobiledots = mobileSlider.map((slide, index) => (
    <div
      key={slide}
      className={index === currentMobileIndex ? 'activeDotStyle' : 'dotStyle'}
      onClick={() => {
        console.log('Image Changed');
        setCurrentMobileIndex(index);
      }}
    />
  ));
  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="trackOrder">
            {modalShow && (
              <OrderCancellation
                CartShow={onNewHomeClick}
                onHomeClick={onNewHomeClick}
              />
            )}
            <div className="trackOrder_content">
              <div className="trackOrder_content_image">
                {(trackData?.tracking_Details?.tracking_link &&
                  statusData &&
                  trackData?.tracking_Details?.tracking_link !== "") ||
                  (trackData?.tracking_Details?.tracking_link === null &&
                    statusData) ? (
                  // <iframe
                  //   src={trackData?.tracking_Details?.tracking_link}
                  //   className="orderShipsy"
                  // />
                  <></>
                ) : !statusData ? (
                  <>
                    <img
                      src={status.img || Accept}
                      alt="acpt"
                      width={innerWidth <= 1023 ? "250" : "368"}
                      height={innerWidth <= 1023 ? "250" : "361"}
                    />

                    {!isPreorder && status && status.caption && (
                      <figcaption className="trackOrder_content_caption">
                        <span className="trackOrder_content_caption_imgdiv">
                          <img src={ellipse} className="trackOrder_content_caption_ellipse" />
                          <img src={biryani} className="trackOrder_content_caption_biryani" />
                        </span>
                        Fresh Biryani on Every order. We don’t reheat!
                      </figcaption>
                    )}
                  </>
                ) : (
                  <iframe
                    src="https://r.shipsy.in/r/5tuE9SA"
                    width="100%"
                    height="100%"
                  />
                )}
                <div
                  className="trackOrder_content_image_wholewrapper"
                  onClick={() => onHomeClick()}
                >
                  <img src={back} alt="Back" className="wholewrapper_backimg" />
                </div>
              </div>
              {cancel ? (
                ""
              ) : (
                <div className="trackOrder_cancelOrder">
                  <CancelOrderCard
                    onClick={() => cartshow("cancelcard")}
                    onShow={onShowCancel}
                  />
                </div>
              )}
              {/* <div
                onClick={() => window.location.reload()}
                className="refreshClass"
              >
                <img src={refreshImg} className="refreshClass_img" alt="" />
                <p className="refreshClass_nameLabel">
                  Refresh to update the order status
                </p>
              </div> */}
              <div className="OrderID">
                Order ID: {trackOrderdata?.order_id}
              </div>
              {deliveryTimeShow && (
                <div className="trackOrder_estimate">
                  <p className="trackOrder_estimate_subLabel">
                    {deliveryMinutes} Min
                  </p>
                  <p className="trackOrder_estimate_nameLabel">
                    {deliveryPoint === "take_away"
                      ? "Pickup your order in"
                      : "Estimated time to Delivery"}
                  </p>
                </div>
              )}
              <div className="trackOrder_rightData_divider" />
              <div className="trackOrder_content_stepDetail">
                <StepProcess data={steps} time={timeData} />
              </div>
              {(trackData?.tracking_Details?.tracking_link &&
                statusData &&
                trackData?.tracking_Details?.tracking_link !== "") ||
                (trackData?.tracking_Details?.tracking_link === null &&
                  statusData) &&
                <div className='trackOrder_content_image_trackRider'>
                  <button className='trackOrder_content_image_trackRider_btn' onClick={() => window.open(trackData?.tracking_Details?.tracking_link, '_blank')}><div className="trackOrder_content_image_trackRider_btn_data">Track Rider <img src={TrackRider} className="trackOrder_content_image_trackRider_btn_logo" /></div></button>
                </div>
              }
              <div className="trackOrder_rightData">
                {/* {trackData?.tracking_Details?.order_details &&
                  trackData?.tracking_Details?.order_details
                    .cooking_instruction !== null && (
                    <>
                      <p className="trackOrder_rightData_nameLabel">
                        Cooking Instruction
                      </p>
                      <p className="trackOrder_rightData_description">
                        {
                          trackData?.tracking_Details?.order_details
                            .cooking_instruction
                        }
                      </p>
                    </>
                  )} */}
                <div className='trackOrder_rightData_bannerData'>
                  <span className='trackOrder_rightData_bannerData_bannerhead'>
                    {mobileSlider[currentMobileIndex]?.heading}
                  </span>
                  <img
                    src={`${mobileSlider[currentMobileIndex]?.image}`}
                    alt='carousel-slide'
                    onClick={() => window.open(mobileSlider[currentMobileIndex].url, "_blank")}
                    className='trackOrder_rightData_bannerData_images'
                    style={{ height: '100%', width: '100%' }}
                  />
                  <div className='trackOrder_rightData_bannerData_slideStyle'></div>

                  <div className='trackOrder_rightData_bannerData_dotContainerStyle'>
                    {Mobiledots}
                  </div>
                </div>

                <VideoCard
                  data={instruction}
                  instruction_video={
                    trackData?.tracking_Details?.instruction_video
                  }
                />
                <div className="trackOrder_rightData_detailsDiv">
                  {/* <div className="trackOrder_rightData_divider" />
                  <div className="trackOrder_rightData_divider" /> */}
                </div>
                <SupportDetails
                  data={congrats}
                  img={congratulations}
                  loyaltyNumber={
                    "You just earned " +
                    Number(parseFloat(loyaltyNumber).toFixed(2)) +
                    " points on this order"
                  }
                />
                {/* <div className="trackOrder_rightData_divider" /> */}

                <p className="trackOrder_rightData_nameLabel_order">
                  ORDER DETAILS
                </p>
                {isToofani && <ToofaniTag />}
                {
                  trackData &&
                  trackData.tracking_Details &&
                  trackData.tracking_Details.cart_items &&
                  trackData.tracking_Details.cart_items.map((item) => {
                    return (
                      <div className="itemDetails_container">
                        <ItemDetails
                          data={item}
                          trackingDetails={
                            trackData?.tracking_Details?.order_details &&
                            trackData?.tracking_Details?.order_details
                          }
                        />
                        {item.addons &&
                          item.addons.map((data) => (
                            <p className="trackOrder_rightData_addonLabel">
                              {/* {data.item_name} */}
                              {handleAddonCount(data)}
                            </p>
                          ))}
                      </div>
                    );
                  })}
                {parseFloat(trackData?.tracking_Details?.order_details?.discount) > 0 && (
                  <div className="checkout-page_side2_totalsavings">

                    <div className="checkout-page_side2_totalsavings__left">
                      <img
                        src={percentRed}
                        className="checkout-page_side2_totalsavingsimg"
                        alt=""
                      />
                      <div className="checkout-page_side2_totalsavingslabel">
                        Your total saving
                      </div>
                    </div>


                    {parseFloat(trackData?.tracking_Details?.order_details?.discount) > 0 && (
                      <div className="checkout-page_side2_totalsavingsvalue">
                        ₹{" "}
                        {parseFloat(trackData?.tracking_Details?.order_details?.discount).toFixed(
                          2
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className="trackOrder_content_orderDetail">
                  <AmountSummary
                    billingDetails={billingDetails}
                    takeAway={trackOrderdata?.delivery_type}
                    offercode={offerCode && offerCode.coupon_code}
                  />
                  {/* <p className="trackOrder_content_disclaimer">
                    GST 18% applicable on ice-cream items
                  </p> */}
                  {console.log(trackData, "trackdata")}

                  <OrderDetail
                    data={
                      trackData?.tracking_Details?.order_details &&
                      trackData?.tracking_Details?.order_details
                    }
                    created={timeData}

                    surcharge={surchargeAmont}
                  />
                </div>
                {/* <div className="trackOrder_rightData_divider" /> */}
                <p className="trackOrder_rightData_nameLabel_outlet">
                  Outlet Details:
                </p>

                <OutletDetails
                  data={trackData?.tracking_Details?.outlet?.outlet_details}
                />
                <div className="trackOrder_rightData_divider" />
                {/* <div className="trackOrder_rightData_space" /> */}
                <div className="customercare">
                  <div className="customer_care">
                    <span className="customer_care_name">Customer Care</span>
                    <span className="customer_care_number">1800-212-2-212</span>
                  </div>
                  <img src={Call} alt="call" onClick={() => handleCallClick()} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <HeaderCommon />
          <div className="trackOrder">
            <div className="trackOrder_img" onClick={onHomeClick}>
              <img src={Back} width="10px" height="18px" alt="checkoutImg" />
            </div>

            {modalShow && (
              <OrderCancellation
                CartShow={onNewHomeClick}
                onHomeClick={onNewHomeClick}
              />
            )}

            <div className="trackOrder_content">
              <div className="trackOrder_content_image">
                {(trackData?.tracking_Details?.tracking_link &&
                  statusData &&
                  trackData?.tracking_Details?.tracking_link !== "") ||
                  (trackData?.tracking_Details?.tracking_link !== null &&
                    statusData) ? (
                  // <iframe
                  //   src={trackData?.tracking_Details?.tracking_link}
                  //   className="orderShipsy"
                  // />
                  <div className='trackOrder_content_image_trackRider'>
                    <button className='trackOrder_content_image_trackRider_btn' onClick={() => window.open(trackData?.tracking_Details?.tracking_link, '_blank')}><div className="trackOrder_content_image_trackRider_btn_data">Track Rider <img src={TrackRider} className="trackOrder_content_image_trackRider_btn_logo" /></div></button>
                  </div>
                ) : !statusData ? (
                  <div className="trackOrder_content_image">
                    <img
                      src={status.img || Accept}
                      alt="acpt"
                      width="368"
                      height="361"
                    />

                    {!isPreorder && status && status.caption && (
                      <figcaption className="trackOrder_content_caption">
                        {status.caption}
                      </figcaption>
                    )}
                  </div>
                ) : (
                  <iframe
                    src="https://r.shipsy.in/r/5tuE9SA"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
              {cancel ? (
                ""
              ) : (
                <div className="trackOrder_cancelOrder">
                  <CancelOrderCard
                    onClick={() => cartshow()}
                    onShow={onShowCancel}
                    localMinutes={newMinutes}
                  />
                </div>
              )}
              {/* <Button
            content="Update to see new"
            onClick={() => window.location.reload()}
            className="refreshClass"
            /> */}
              {/* <div
                onClick={() => window.location.reload()}
                className="refreshClass"
              >
                <img src={refreshImg} className="refreshClass_img" alt="" />
                <p className="refreshClass_nameLabel">
                  Refresh to update the order status
                </p>
              </div> */}
              {deliveryTimeShow && (
                <div className="trackOrder_estimate">
                  <p className="trackOrder_estimate_subLabel">
                    {deliveryMinutes} Min
                  </p>
                  <p className="trackOrder_estimate_nameLabel">
                    {deliveryPoint === "take_away"
                      ? "Pickup your order in"
                      : "Estimated time to Delivery"}
                  </p>
                </div>
              )}

              <div className="trackOrder_content_divider" />
              <div className="trackOrder_content_stepDetail">
                <StepProcess data={steps} />
              </div>
              <div className="trackOrder_content_divider" />
              <div className="trackOrder_content_orderDetail">               
                <OrderDetail
                  data={
                    trackData?.tracking_Details?.order_details &&
                    trackData.tracking_Details.order_details
                  }
                  created={timeData}
                  surcharge={surchargeAmont}
                />
              </div>
            </div>
            <div className="trackOrder_rightData">
              <div className='trackOrder_rightData_bannerData'>
                <span className='trackOrder_rightData_bannerData_bannerhead'>
                  {webSlider[currentIndex]?.heading}
                </span>
                <img
                  src={`${webSlider[currentIndex]?.image}`}
                  alt='carousel-slide'
                  className='trackOrder_rightData_bannerData_images'
                  onClick={() => window.open(webSlider[currentIndex].url, "_blank")}
                  style={{ height: '100%', width: '100%' }}
                />
                <div className='trackOrder_rightData_bannerData_slideStyle'></div>

                <div className='trackOrder_rightData_bannerData_dotContainerStyle'>
                  {dots}
                </div>
              </div>
              <p className="trackOrder_rightData_nameLabel">Instructions</p>

              <VideoCard
                data={instruction}
                instruction_video={
                  trackData?.tracking_Details?.instruction_video
                }
              />
              <div className="trackOrder_rightData_divider" />
              {
                isToofani && <ToofaniTag />
              }
              <p className="trackOrder_rightData_nameLabel">Item Details</p>

              {trackData &&
                trackData.tracking_Details &&
                trackData.tracking_Details.cart_items &&
                trackData.tracking_Details.cart_items.map((item) => {
                  return (
                    <>
                      <ItemDetails data={item} />
                      {item.addons &&
                        item.addons.map((data) => (
                          <p className="trackOrder_rightData_addonLabel">
                            {/* {data.item_name} */}
                            {handleAddonCount(data)}
                          </p>
                        ))}
                    </>
                  );
                })}
              <div className="trackOrder_rightData_divider" />
              <p className="trackOrder_rightData_nameLabel">Outlet Details</p>
              <OutletDetails
                data={trackData?.tracking_Details?.outlet?.outlet_details}
              />
              <div className="trackOrder_rightData_divider" />
              <SupportDetails
                data={congrats}
                loyaltyNumber={
                  "You just earned " +
                  Number(parseFloat(loyaltyNumber).toFixed(2)) +
                  " points"
                }
                img={congratulations}
              />
              <div className="trackOrder_rightData_space" />
              <SupportDetails
                data={support}
                loyaltyNumber=""
                img={supportImg}
              />
            </div>
          </div>
          {cancelModal && (
            <Modal
              hideClose
              contentCustomClass="cancelPage1_contentCustomClass"
            >
              <div className="cancelPage1">
                <div className="cancelPage1_header">
                  <p className="cancelPage1_header_phrase">Order cancelled</p>
                  <img
                    src={cancelclose}
                    className="cancelPage1_header_img"
                    onClick={() => handleCancelPage()}
                    alt=""
                  />
                </div>
                <div className="cancelPage1_wholeWrapper">
                  <div className="cancelPage1_body">
                    <img src={cancelImage} className="cancelPage1_img" alt="" />
                    <div className="cancelPage1_wholeWrapper_wholeDiv1">
                      <p className="wholeDiv1_phrase1">Your Order Cancelled</p>
                      <p className="wholeDiv1_phrase2">
                        Your payment will be return to you soon.
                      </p>
                    </div>
                  </div>
                  <div className="cancelPage1_subWrapper">
                    {/* <Button
                      content="Cancel"
                      className="cancelPage1_subWrapper_button"
                      customClass="cancelPage1_subWrapper_custom"
                      onClick={() => handleCancelPage()}
                    /> */}
                    <Button
                      content="Explore food"
                      className="cancelPage1_subWrapper_button1"
                      customClass="cancelPage1_subWrapper_custom1"
                      onClick={() => handleHome()}
                    />
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
}
export default TrackOrder;