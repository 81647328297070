import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import './index.scss';

const Button = (props) => {
  const {
    className,
    content,
    type,
    outlined,
    size,
    theme,
    onClick,
    image,
    disabled,
    customClass,
    isLoading,
    id
  } = props;
  return (
    <button
      id={id ? id : ""}
      type='button'
      disabled={disabled}
      className={`button ${theme} ${type} ${outlined && 'outlined'
        } ${size} ${className} ${disabled ? 'button_disble' : ""}`}
      onClick={onClick ? (e) => onClick(e) : () => null}
    >
      {isLoading ? <Loader customClass={'button__loader'} /> :
        <>
          <img src={image} />
          <span className={`button__content ${customClass}`}>{content}</span>
        </>
      }
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  outlined: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  isLoading: PropTypes.bool
};

Button.defaultProps = {
  className: '',
  content: null,
  outlined: false,
  size: '',
  type: '',
  theme: '',
  isLoading: false
};
export default React.memo(Button);
