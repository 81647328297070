import React, { useState } from 'react';
import './index.scss';
import Input from '../input';

const SenderDetails = ({
  name,
  isChecked,
  email,
  mobile,
  onChangeName,
  onChangeMobile,
  onChangeEmail,
  onChangeChecked,
  nameAlert,
  emailAlert,
  mobileAlert,
}) => {
  const onClickCheck = (e) => {
    onChangeChecked(e.target.checked);
  };

  return (
    <div className='senderDetails'>
      <Input
        type='text'
        onChange={(id, value) => {
          onChangeName(value);
        }}
        value={name}
        customClass='senderDetails_input'
        placeholder='Enter Name'
      />
      <p className='senderDetails_alert'>{nameAlert}</p>
      <Input
        type='text'
        onChange={(id, value) => {
          onChangeEmail(value);
        }}
        value={email}
        customClass='senderDetails_input'
        placeholder='Enter Email'
      />
      <p className='senderDetails_alert'>{emailAlert}</p>
      <Input
        type='text'
        onChange={(id, value) => {
          onChangeMobile(value);
        }}
        value={mobile}
        customClass='senderDetails_input'
        placeholder='Enter Phone Number'
      />
      <p className='senderDetails_alert'>{mobileAlert}</p>
      <div className="senderDetails_checkboxDiv">
        <input
          type='checkbox'
          onClick={onClickCheck}
          className='senderDetails_checkbox'
          checked={isChecked}
        />
        <span className='senderDetails_label'>
          Make your Phone Number & Email Id as Reciever
        </span>
      </div>
    </div>
  );
};

export default SenderDetails;
