import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../containers/header";
import Modal from "../modal";
import $ from "jquery";
import CreateAccount from "../../containers/createAccount";
import OtpVerification from "../../containers/otpVerification";
import BasicDetails from "../../containers/basicDetails";
import { LocalStorage } from "../../utils/storage";
import { sendOtp, verifyOtp, makeProfile } from "../../actions/login";
import Dexie from "dexie";
import {
  OutletMapping,
  getSkipLocation,
  getMenuList,
  getHomePage,
  handleSaveInterest,
} from "../../actions/home";
import { Analytics } from "../../helpers/analytics";
import { ROUTES } from "../../constants/app-constants";
import moment from "moment";
import Switch from "../switch";
import DateTimePicker from "react-datetime-picker";
import Detect from "../../assets/images/detectLoc.svg";
import LocationSearch from "../../assets/images/homeLocation.svg";
import IntervalDrop from "../interval-dropdown";
import { getProfile, updateProfile } from "../../actions/profile";
import RadioButton from "../radioButton";
import Button from "../button";
import MapModal from "../../containers/mapModal";
import AlertPopup from "../alert-popup";
import Subsription from "../../containers/subscription";
import SearchAutoComplete from "../../containers/searchAutocomplete";
import StoreCard from "../store-card";
import { getCartItems } from "../../actions/cart";
import { loginNetCoreEvent, signUpNetCoreEvent } from "../../helpers/netcore";
import LocationImg from "../../assets/images/locationIcon.svg";
import { handleParseAmount } from "../../helpers/sharedFunction";
import Toaster from "../toaster";
import { getStaticPage } from "../../actions/staticpages";
import SavedAddress from "../saved-address";
import { getSavedAddress } from "../../actions/home";
import {
  loginBranchEvent,
  registrationBranch,
  setIdentityBranch,
} from "../../utils/branch";
import RenderMaps from "../../containers/MapsCommonApi";
import remove from "../../assets/images/close.svg";

const HeaderCommon = (props) => {
  const { LoginCLick1, setIsSignIn1, setLoginFLow1, loginFlow1, isSignIn1, headerDeliveryTime } =
    props;
  const radio = [
    { id: "orderNow", name: "Order Now", checked: true },
    { id: "orderLater", name: "Order Later", checked: false },
  ];

  const responsiveRadio = [
    { id: "orderNow", name: "Now", checked: true },
    { id: "orderLater", name: "Later", checked: false },
  ];

  const [loginFlow, setLoginFLow] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [stat, setStat] = useState("stage1");
  const [errorTxt1, setErrorTxt1] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [displayMobileNumber, setDisplayMobileNumber] = useState("");
  const [errorTxt, setErrorTxt] = useState("");
  const [loginId, setLoginId] = useState("");
  const [successTxt, setSuccessTxt] = useState("");
  const [basicDetailsData, setBasicDetailsData] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [deliveryFlow, setDeliveryFlow] = useState(false);
  const [location, setLocation] = useState(false);
  const [alertData, setAlertData] = useState(false);
  const [latLng, setLatLng] = useState({ lat: "", lng: "" });
  const [active, setActive] = useState("delivery");
  const [inputData, setInputData] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [errorData1, setErrorData1] = useState(false);
  const [countData, setCountData] = useState({});
  const [radioData, setRadioData] = useState(radio);
  const [data, setData] = useState([]);
  const [errorData, setErrorData] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [activeOrder, setActiveOrder] = useState("orderNow");
  const [intervalModal, setIntervalModal] = useState(false);
  const [loginStatus, setloginStatus] = useState(
    LocalStorage.get("isLoggedIn") || false
  );
  const [subscribe, setSubscribe] = useState([
    { name: "Subscribe", id: "subscribe", checked: false },
  ]);
  const [responsiveRadioData, setResponsiveRadioData] =
    useState(responsiveRadio);
  const [inputValue, setInputvalue] = useState({});
  const [imgProfile, setImgProfile] = useState("");
  const [profileInfo, setProfileInfo] = useState({});
  const [activeSubscribe, setActiveSubscribe] = useState("unsubscribe");
  const [popup, setPopup] = useState(false);
  const [intDate, setIntDate] = useState([]);
  const [dateData, setdateData] = useState(false);
  const [interval, setInterval] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState("");
  const history = useHistory();
  const [canShowLater, setCanShowLater] = useState(false);
  const [outletLoader, setOutletLoader] = useState(false);
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  const [isLoginBtnLoading, setLoginBtnLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [savedAddress, setSavedAddress] = useState();
  const [otp, setOtp] = useState("");

  const urlParams = useLocation();
  // console.log("urlparams", urlParams);
  const urlValue = urlParams.pathname.split("/")[3];
  const urlCity = urlParams.pathname.split("/")[2];

  useEffect(() => {
    getSavedAddress((res) => {
      if (res?.response?.status == 200) {
        setSavedAddress(res.content.address);
      }
    });

    if (LocalStorage.get("profileImg")) {
      setImgProfile(LocalStorage.get("profileImg"));
    }

    if (LocalStorage.get("UserData")) {
      getProfile((res) => {
        if (res && res?.response?.status === 200) {
          setProfileInfo(res?.content);
          if (
            res.content &&
            (res.content.is_register_on_rezol === false ||
              res.content.is_new_user === true)
          ) {
            setLoginFLow(true);
            setStat("stage3");
          }

          if (res.content.is_subscribed_to_newsletter) {
            setActiveSubscribe(res.content.is_subscribed_to_newsletter);
            subscribe.map((itm) => {
              itm.name = "Unsubscribe";
              itm.id = "unsubscribe";
              itm.checked = res.content.is_subscribed_to_newsletter;
              return itm;
            });
          }
          LocalStorage.set("profileImg", res?.content?.profile_picture);
          let newData = {
            dob: res?.content?.dob,
            email: res?.content?.email,
            mobile: res?.content?.mobile,
            name: res?.content?.first_name,
            profileInfo: res?.content?.profile_picture,
            emailVerify:res?.content?.is_user_email_verified,
            _id: res?.content?._id,
          };
          LocalStorage.set("UserData", newData);
        }
      });
      if (latLng.lat === "" && latLng.lng === "") {
        setLocation(false);
      }

      let locationDetails = LocalStorage.get("location");
      let user1 = LocalStorage.get("UserData");
      let OutletData = LocalStorage.get("Outlet_Details");
      if (user1) {
        // const script = document.createElement("script");
        // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
        // script.onload = () => {
        //   smartech("identify", user1?.mobile ? "91" + user1?.mobile : "91");
        //   smartech("dispatch", "Order Type", {
        //     orderType: locationDetails.orderTime,
        //     orderFor: locationDetails.orderFor,
        //     outletname: OutletData.city,
        //     pageurl: "/",
        //   });
        // };
        // document.head.appendChild(script);
      }

      if (locationDetails) {
        setLocation(true);
        setLatLng({
          lat: locationDetails && locationDetails.lat,
          lng: locationDetails && locationDetails.lng,
        });
        // setActiveOrder(locationDetails && locationDetails.orderTime);
        setActive(locationDetails && locationDetails.orderFor);
        setInputData(locationDetails && locationDetails.address);
        // setDate(locationDetails && locationDetails.date);
        // setTime(locationDetails && locationDetails.time);
      }
    }
  }, []);

  useEffect(() => {
    const query = "";

    getStaticPage((res) => {
      if (res && res?.response?.status === 200) {
        const root = document.getElementById("root");
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setProfileData(res && res?.content);
      }
    }, query);
  }, []);

  let timeInterval = [];

  const switchData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Takeaway" },
  ];

  const LoginCLick = () => {
    setLoginFLow(true);
    setIsSignIn(false);
    // setLoginFLow1(true);
    // setIsSignIn1(false);
  };

  const loginCheck = () => {
    if (LoginCLick) {
      LoginCLick();
    } else {
      setLoginFLow1(true);
      setIsSignIn1(false);
    }
  };

  const SigninClick = () => {
    setLoginFLow(true);
    setIsSignIn(true);
  };

  const deliveryClick = () => {
    setDeliveryFlow(true);
    console.log(active, "activer")
    if (active !== "delivery") {
      let coordinates = latLng;
      onConfirmLocation(coordinates, active);
    } else {
      setOutlets([]);
    }
    Analytics.locactionSelectionEvent();
  };

  const onSubscribe = (e, item) => {
    setActiveSubscribe(item.id);
    const updateSubscribe = subscribe.map((itm) => {
      itm.checked = !item.checked;
      itm.name = !item.checked === false ? "Unsubscribe" : "Subscribe";
      itm.id = !item.checked === false ? "unsubscribe" : "subscribe";
      return itm;
    });
    setSubscribe(updateSubscribe);
  };

  const closeHandle = () => {
    LocalStorage.set("loginflow", false);
    setLoginFLow(false);
    setLoginFLow1 && setLoginFLow1(false);
  };

  const onClickSubmit = () => {
    const user = LocalStorage.get("UserData");
    // const script = document.createElement("script");
    // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
    // script.onload = () => {
    //   smartech(
    //     "contact",
    //     "2",
    //     {
    //       "pk^mobile": "91" + user.mobile,
    //     },
    //     () => {
    //       smartech("identify", "91" + user.mobile);
    //       smartech("dispatch", "Newsletter Subscription", {
    //         usertype: activeSubscribe === "subscribe" ? "true" : "false",
    //       });
    //     }
    //   );
    // };
    // document.head.appendChild(script);
    const payload = {
      is_subscribed_to_newsletter:
        activeSubscribe === "subscribe" ? true : false,
    };
    updateProfile(payload, (res) => {
      if (res.response.status === 200) {
        if (activeSubscribe !== "subscribe") {
          setActiveSubscribe("unsubscribe");
        } else {
          setActiveSubscribe("subscribe");
        }
      }
    });
  };

  const callOutlet = (ltln, val) => {
    if (val) {
      setInputData(val);
    }
    // setLatLng({ lat: ltln.lat, lng: ltln.lng });
    let payload = {
      latitude: ltln.lat,
      longitude: ltln.lng,
      is_delivery: active === "delivery" ? true : false,
      is_order_now: true,
      // activeOrder === "orderNow" ? true : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };

    OutletMapping(payload, (res) => {
      if (res && res?.response?.status === 200) {
        LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
        setCanShowLater(true);
      } else {
        setErrorData(true);
        setAlertPopup(res?.response?.status ? res?.response?.message : "");
      }
    });
  };

  const onSkipLocation = () => {
    setIsLoading(true);
    getSkipLocation((res) => {
      if (res && res?.response && res?.response?.status === 200) {
        LocalStorage.set("Outlet_Details", res.content);
        if (res.content.outlet_id) {
          getMenuList(
            urlValue &&
              urlValue?.length > 0 &&
              res?.content?.outlet__city?.toLowerCase() !==
              urlCity?.toLowerCase()
              ? urlValue
              : res.content.outlet_id,
            (res) => {
              if (res && res.content && res.response.status === 200) {
                if (res.content) {
                  if (LocalStorage.get("outlet_id")) {
                    const oldOutlet = LocalStorage.get("outlet_id");
                    if (oldOutlet !== res.content.menu.unit_id) {
                      if (LocalStorage.get("isLoggedIn")) {
                        onMenuDataSync(res.content.menu.categories);
                      } else {
                        LocalStorage.set("CartProducts", []);
                      }
                    }
                  }
                  LocalStorage.set("outlet_id", res.content.menu.unit_id);
                  const dataValue = {
                    orderFor: active,
                    orderTime: activeOrder,
                    deliveryTime: data.delivery_area_delivery_time,
                    address: inputData,
                    date: date,
                    time: time,
                    lat: latLng.lat,
                    lng: latLng.lng,
                  };
                  LocalStorage.set("location", dataValue);
                  const db = new Dexie(res.content.menu.unit_id);
                  db.version(1).stores({
                    menu: "++id, categories, item_tags, taxes",
                  });
                  const getMenuData = async () => {
                    const allData = (await db.menu.toArray()) || [];
                    if (allData && allData.length > 0) {
                      await db.menu.update(0, res.content.menu);
                    } else {
                      await db.menu.add(res.content.menu);
                    }
                    history.push(ROUTES.HOME);
                  };
                  getMenuData();
                  setIsLoading(false);
                }
              } else {
                setAlertPopup(
                  "Sorry!! No Data Found. Please try again later sometime"
                );
              }
            }
          );
        }
      }
    });
  };

  const onMenuDataSync = (data) => {
    const outletData = LocalStorage.get("CartProducts");
    const updateitem = data.map((items) => {
      let updateItem = [];
      if (items.has_subcategory === "0") {
        updateItem = items.items;
      }
      if (items.has_subcategory === "1") {
        items.subcategories.map((item) => {
          item.items.map((value) => {
            updateItem.push(value);
          });
        });
        items.items = updateItem;
      }
      items.itemData = updateItem.map((item) => {
        item.checked = false;
        item.count = 0;
        if (LocalStorage.get("CartProducts")) {
          const productData = LocalStorage.get("CartProducts");
          if (productData && productData.length > 0) {
            productData.map((items) => {
              if (items.item_id === item.item_id) {
                item.count = items.count;
              }
            });
          }
        }
        return item;
      });
      items.vegData = updateItem.filter((item) => item.item_tags.includes("1"));
      items.nonVeg = updateItem.filter((item) => item.item_tags.includes("2"));
      return items;
    });
    const updateItemData = [];
    updateitem.map((items) => {
      items.itemData.map((item) => {
        updateItemData.push(item);
      });
    });
    const datavalue = updateItemData.filter((itms) => {
      return outletData.includes(itms.item_id);
    });
  };

  const onClickStore = (data, isTakeAway, address, location = latLng) => {
    // let pointDelivery = LocalStorage.get('DeliveryAreaPoint');
    if (activeOrder === "orderLater") {
      if (date?.length === 0) {
        setToaster({
          toastType: true,
          toastText: "Date is required",
          isShow: true,
        });
        return;
      }
      if (time?.length === 0) {
        setToaster({
          toastType: true,
          toastText: "Time is required",
          isShow: true,
        });
        return;
      }
    }

    if (data && data?.is_open) {
      setOutletLoader(true);
      LocalStorage.set("Outlet_Details", data);
      getMenuList(
        urlValue &&
          urlValue?.length > 0 &&
          data?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
          ? urlValue
          : data?.outlets_id,
        (res) => {
          if (res && res.content && res.response.status === 200) {
            if (res.content) {
              if (LocalStorage.get("outlet_id")) {
                const oldOutlet = LocalStorage.get("outlet_id");
                if (oldOutlet !== res.content.menu.unit_id) {
                  if (LocalStorage.get("isLoggedIn")) {
                    onMenuDataSync(res.content.menu.categories);
                  } else {
                    LocalStorage.set("CartProducts", []);
                  }
                }
              }
              LocalStorage.set("outlet_id", data?.outlets_id);
              const dataValue = {
                orderFor: active,
                orderTime: activeOrder,
                deliveryTime: data.delivery_area_delivery_time,
                address: address,
                date: date,
                time: time,
                lat: latLng.lat,
                lng: latLng.lng,
              };
              LocalStorage.set("location", dataValue);

              if (isTakeAway) {
                dataValue.address = data.address;
                dataValue.lat = data.latitude;
                dataValue.lng = data.longitude;
              }

              const db = new Dexie(res.content.menu.unit_id);
              db.version(1).stores({
                menu: "++id, categories, item_tags, taxes",
              });
              const getMenuData = async () => {
                const allData = (await db.menu.toArray()) || [];
                if (allData && allData.length > 0) {
                  await db.menu.update(0, res.content.menu);
                } else {
                  await db.menu.add(res.content.menu);
                }
                setOutletLoader(false);
                history.push();
                window.location.reload();
              };
              getMenuData();

              getCartItems(data?.outlets_id, (res) => {
                if (res && res?.response?.status === 200) {
                  const data = onStructurize(res.content.items);
                  LocalStorage.set("CartProducts", data);
                  LocalStorage.set(
                    "CouponName",
                    res.content.billing_data.coupon_used
                  );
                }
              });

              // onCallMenu();
              // const getMenuData = async () => {
              //   const allData = (await db.menu.toArray()) || [];
              //   if (allData && allData.length > 0) {
              //     history.push(ROUTES.PRODUCTLIST);
              //   }
              // };
              // getMenuData();
              // history.push(ROUTES.PRODUCTLIST);
            }
          } else {
            setAlertPopup(
              "Sorry!! No Data Found. Please try again later sometime"
            );
          }
        }
      );
    } else {
      setAlertPopup(
        data && data.outlet_status_info.web_err_msg
          ? data.outlet_status_info.web_err_msg
          : "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
    }
  };

  const onStructurize = (data) => {
    if (data && data.length > 0) {
      const dataItem = data.map((items) => {
        const item = {
          _id: items._id,
          item_name: items.name,
          item_final_price: items.single_item_price,
          item_tags: items.item_tags,
          count: Number(items.item_count),
          item_id: items.item_id,
          item_image_url: items.image_url,
          item_unit_price: items.single_item_price,
          addons: items.addons,
          complimentary_item: items.complimentary_item,
          is_veg: items.is_veg,
          _id: items._id,
        };
        return item;
      });
      return dataItem;
    }
    return [];
  };

  const onConfirmLocation = (
    locationDetails,
    type = active,
    address = inputData
  ) => {
    setPopup(false);

    let payload = {
      latitude: latLng.lat || locationDetails.lat,
      longitude: latLng.lng || locationDetails.lng,
      is_delivery: type === "delivery" ? true : false,
      is_order_now: activeOrder === "orderNow" ? true : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };
    if (locationDetails) {
      payload.latitude = locationDetails.guest_lat
        ? locationDetails.guest_lat
        : locationDetails.lat;
      payload.longitude = locationDetails.guest_long
        ? locationDetails.guest_long
        : locationDetails.lng;
      setLatLng({ lat: locationDetails.lat, lng: locationDetails.lng });
    }
    // if (type === "delivery" && address) {
    //   setInputData(address);
    // }
    //
    OutletMapping(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setErrorData(false);
        setErrorData1(false);
        //
        if (res && res?.content && res?.content?.areas) {
          setOutlets(res.content.areas);
          // LocalStorage.set('DeliveryAreaPoint',res.content.areas[0].address)
          if (type === "delivery" && locationDetails) {
            onClickStore(res?.content?.areas[0], type === "takeaway", address, {
              lat: locationDetails.lat || latLng.lat,
              lng: locationDetails.lng || latLng.lng,
            });
          }
          setAlertData(false);
        }
        setData([]);
      } else {
        setOutlets([]);
        setErrorData(true);
        setAlertPopup(res?.response?.status ? res?.response?.message : "");
        if (type === "delivery") {
          payload = {
            latitude: latLng.lat,
            longitude: latLng.lng,
            is_delivery: false,
            is_order_now: activeOrder === "orderNow" ? true : false,
            order_date:
              activeOrder !== "orderNow" && date !== ""
                ? moment(date).format("YYYY-MM-DD")
                : "",
            order_time: activeOrder !== "orderNow" ? time : "",
            channel: "online_web",
          };
          OutletMapping(payload, (res) => {
            if (res && res.response && res.response.status === 200) {
              if (
                res.content &&
                res.content.areas &&
                res.content.areas.length > 0
              ) {
                setErrorData1(true);
                setCountData({
                  count: res.content.areas.length,
                  distance: parseFloat(
                    res.content.max_distance_user_to_restaurant
                  ).toFixed(2),
                });
                setData(res.content.areas);
              }
            } else {
              setErrorData(true);
              setCountData({});
              setData([]);
            }
          });
        } else {
          setErrorData1(false);
          setCountData({});
          setData([]);
        }
      }
    });
  };

  const { innerWidth } = window;

  const onChangeOrder1 = (value) => {
    setActiveOrder(value.id);
    const user = LocalStorage.get("UserData");
    // const script = document.createElement("script");
    const location = LocalStorage.get("location");
    const OutletData = LocalStorage.get("Outlet_Details");
    // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
    // script.onload = () => {
    //   smartech("identify", user?.mobile ? "91" + user?.mobile : "91");
    //   smartech("dispatch", "Order Type", {
    //     orderType: value.name,
    //     orderFor: location.orderFor,
    //     outletname: OutletData.city,
    //     pageurl: "/",
    //   });
    // };
    // document.head.appendChild(script);

    if (innerWidth <= 1023) {
      const updateOrder = responsiveRadioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setResponsiveRadioData(updateOrder);
    } else {
      const updateOrder = radioData.map((item) => {
        item.checked = false;
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      setRadioData(updateOrder);
    }
  };

  const onNav = (val) => {
    setStat(val);
  };
  const onChangeMode = async (value) => {
    setActive(value.id);
    if (value.id !== "delivery") {
      let coordinates = latLng;
      onConfirmLocation(coordinates, value.id);
    } else {
      setOutlets([]);
    }
  };

  const onStoreSelect = () => {
    setOutlets(data);
    setErrorData1(false);
    setData([]);
    setErrorData(false);
    setActive("takeaway");
  };

  const onNavigate = () => {
    const { location } = history;
    setTimeout(() => {
      history.push(ROUTES.HOME);
    }, 1000);
  };

  const getMobileData = (number) => {
    setLoginBtnLoading(true);
    setMobileNumber(number);
    let displayM = number.split("");
    let displayMobile = [];
    let mobileDisplay = "";
    for (let i = 0; i < displayM.length; i++) {
      if (i == 0 || i == 1 || i == 2 || i == 8 || i == 9) {
        displayMobile.push(displayM[i]);
      } else {
        displayMobile.push("*");
      }
    }
    mobileDisplay = displayMobile.join("");
    setDisplayMobileNumber(mobileDisplay);

    // setDisplayMobile(number)
    let payload = {};
    if (isSignIn === true) {
      payload = {
        mobile: number,
        device_type: "web",
        is_signup: true,
        firebase_token: "qwerty",
        login_id: "",
        retry: false,
      };
    } else {
      payload = {
        mobile: number,
        device_type: "web",
        is_signup: false,
        firebase_token: "qwerty",
        login_id: "",
        retry: false,
      };
    }

    sendOtp(payload, (res) => {
      if (res?.response?.status === 200) {
        //WebOTP API

        const ac = new AbortController();

        //Settimeout is for aborting the WebOTP API after 1 min
        setTimeout(() => {
          ac.abort();
        }, 1 * 60 * 1000);

        if ("OTPCredential" in window) {
          try {
            if (navigator.credentials) {
              try {
                navigator.credentials
                  .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                  })
                  .then((otp) => {
                    setOtp(otp.code);
                    ac.abort();
                  })
                  .catch((err) => {
                    ac.abort();
                    console.log(err);
                  });
              } catch (err) {
                console.log(err);
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
        setLoginId(res?.content?.login_id);
        setStat("stage2");
      } else {
        // setErrorTxt(true);
        setErrorTxt1(res?.response?.message);
      }
      setLoginBtnLoading(false);
    });
  };

  const getResendData = (number1) => {
    // setMobileNumber(number1);

    const payload = {
      mobile: mobileNumber,
      device_type: "web",
      is_signup: false,
      firebase_token: "qwerty",
      login_id: loginId,
      retry: true,
    };

    sendOtp(payload, (res) => {
      // setResMsg(res.response.message);
      // setToast(true);
      if (res?.response?.status === 200) {
        //WebOTP API

        const ac = new AbortController();

        //Settimeout is for aborting the WebOTP API after 1 min
        setTimeout(() => {
          ac.abort();
        }, 1 * 60 * 1000);

        if ("OTPCredential" in window) {
          try {
            if (navigator.credentials) {
              try {
                navigator.credentials
                  .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                  })
                  .then((otp) => {
                    setOtp(otp.code);
                    ac.abort();
                  })
                  .catch((err) => {
                    ac.abort();
                    console.log(err);
                  });
              } catch (err) {
                console.log(err);
              }
            }
          } catch (err) {
            console.log(err);
          }
        }
        setLoginId(res?.content?.login_id);
        setSuccessTxt(res?.response?.message);
      }
    });
  };

  const onLoginClick = () => {
    const { location } = history;
    setTimeout(() => {
      history.push(ROUTES.HOME);
    }, 1000);

    // if (
    //   location &&
    //   location.state &&
    //   location.state.redirectToCheckOut &&
    //   location.state.redirectToCheckOut == true
    // ) {
    //   history.go(-1);
    // } else if (LocalStorage.get("outlet_id")) {
    //   history.push(ROUTES.HOME);
    // } else {
    //   history.push(ROUTES.HOME);
    // }
  };

  const getOtpData = (otpData) => {
    const payload = {
      mobile: mobileNumber,
      otp: otpData,
      login_id: loginId,
    };
    const { location } = history;

    if (isSignIn === true) {
      verifyOtp(payload, (res) => {
        if (res?.response?.status === 200) {
          LocalStorage.set("AccessToken", res?.content.access_token);
          LocalStorage.set("RefreshToken", res?.content.refresh_token);
          LocalStorage.set("isLoggedIn", true);
          LocalStorage.set("UserData", {
            name: res?.content.first_name,
            mobile: res?.content.mobile,
          });
          LocalStorage.set("isDOB", res?.content?.is_dob_eitable);

          // Analytics.logEventWithVariable(
          //   Analytics.Order.login,
          //   JSON.stringify(payload)
          // );
          Analytics.loginEvent(payload);

          //branch events

          registrationBranch(res?.content?._id);
          setIdentityBranch(res?.content?._id);
          loginBranchEvent(res?.content?._id);

          setErrorTxt(res?.response?.message);
          if (
            res.content &&
            (res.content.is_register_on_rezol === false ||
              res.content.is_new_user === true)
          ) {
            setLoginFLow(true);
            setStat("stage3");
          } else {
            onNavigate();
          }
        } else {
          setErrorTxt(
            res?.response?.message ? res?.response?.message : "Invalid OTP!"
          );
        }
      });
    } else {
      //

      verifyOtp(payload, (res) => {
        if (res?.response?.status === 200) {
          // setStat('stage3')

          console.log("web login");
          const netCoreLoginPayload = {
            date: moment(res.content.updated_at).format("DD-MM-YYYY"),
            mobile: `91${res.content.mobile}`,
            logintype: "Web",
          };

          // loginNetCoreEvent(netCoreLoginPayload)
          Analytics.loginEvent(payload);

          //branch events

          setIdentityBranch(res?.content?._id);
          loginBranchEvent(res?.content?._id);

          if (isSignIn === false) {
            setLoginFLow(false);
          }

          LocalStorage.set("AccessToken", res?.content.access_token);
          LocalStorage.set("RefreshToken", res?.content.refresh_token);
          LocalStorage.set("isLoggedIn", true);
          LocalStorage.set("UserData", {
            name: res?.content.first_name,
            mobile: res?.content.mobile,
            email: res?.content.email,
            dob: res?.content.dob,
            emailVerify:res?.content?.is_user_email_verified
          });
          LocalStorage.set("isDOB", res?.content?.is_dob_eitable);
          onLoginClick();

          const Date = moment(res.content.updated_at, "DD-MM-YYYY");

          if (
            res.content &&
            (res.content.is_register_on_rezol === false ||
              res.content.is_new_user === true)
          ) {
            setBasicDetailsData(res.content);
            setStat("stage3");
          } else {
            onLoginClick();
          }
          setErrorTxt(res?.response?.message);
        } else {
          setErrorTxt(
            `${res?.response?.message ? res?.response?.message : `Invalid OTP!`
            }`
          );
        }
      });
    }
  };

  const getBasicDetails = (data) => {
    setName(data.name);
    setEmail(data.email);
    setDate(data.date);

    const payload = {
      fname: data.name,
      lname: data.name,
      email: data.email,
      mobile: mobileNumber,
      dob: data.date,
    };
    //
    makeProfile(payload, (res) => {
      if (res?.response?.status === 200) {
        localStorage.setItem("DOB", res?.content?.data?.dob);
        localStorage.setItem("Email Id", res?.content?.data?.email);

        const createdAt = moment(res.content.created_at).format("DD-MM-YYYY");

        const enventPayload = {
          mobile: "91" + mobileNumber,
          email: data.email,
          name: data.name,
          createdAt: createdAt,
        };
        // signUpNetCoreEvent(enventPayload)
        Analytics.signUpEvent(enventPayload);
        onNavigate();
      } else {
        setErrorMsg(res?.response?.message + " Please Login!");
      }
    });
  };

  const closedelivery = () => {
    // LocalStorage.set('loginflow', false)
    // setLoginFLow(false)
    setDeliveryFlow(false);
  };

  const onChangeSearch = (value) => {
    setSavedAddress("");
    setAlertData(false);
    setLocation(true);
    setInputData(value.location);
    setInputvalue(value);
    setDate("");
    setInterval("");
  };

  const onFindFood = (text, value) => {
    if (text == "savedaddress") {
      setInputData(value);
      const dataValue = {
        orderFor: active,
        orderTime: activeOrder,
        deliveryTime: data.delivery_area_delivery_time,
        address: value.Address1,
        addressType: value.AddressType,
        date: date,
        time: time,
        lat: latLng.lat,
        lng: latLng.lng,
      };
      LocalStorage.set("Previouslocation", dataValue);
      LocalStorage.set("outlet_id", value.UnitId);
    }

    if (activeOrder === "orderLater") {
      if (date?.length === 0) {
        setToaster({
          toastType: true,
          toastText: "Date is required",
          isShow: true,
        });
        return;
      }
      if (time?.length === 0) {
        setToaster({
          toastType: true,
          toastText: "Time is required",
          isShow: true,
        });
        return;
      }
    }

    if (
      (active === "delivery" || active === "takeaway") &&
      (inputData || value.Address1)
    ) {
      //
      // setPopup(true);
      onConfirmLocation();
    } else {
      setAlertData(true);
      // onConfirmLocation();
    }
  };
  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };

  const onMarkerPosition = (value) => {
    LocalStorage.remove("Previouslocation");
    const google = window?.google;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng: value },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            var formattedAddress = results[0].formatted_address;
            for (let i = 0; i < results.length; i++) {
              if (results[i]?.geometry?.location_type === "APPROXIMATE") {
                formattedAddress = results[i].formatted_address;
                break;
              }
            }
            // eslint-disable-next-line no-lone-blocks
            setInputvalue({
              location: results[0].formatted_address,
              pincode,
              latitude: value.lat,
              longitude: value.lng,
            });
            setLatLng({ lat: value.lat, lng: value.lng });
            setInputData(formattedAddress);
          }
        }
      },
      this
    );
  };

  const onChangeDate = (value) => {
    if (!value) {
      setDate("");
      return;
    }
    let gotTime = value.getDate();
    setdateData(false);
    setDate(value);
    let dateHour = new Date();
    let hours = dateHour.getHours();
    let opentime = LocalStorage.get("Outlet_Details")?.open_time.substring(
      0,
      2
    );
    let closetime = LocalStorage.get("Outlet_Details")?.close_time.substring(
      0,
      2
    );

    const calcTime = moment().add(1.5, "hours").format("HH:mm");
    const splitTime = calcTime.split(":");
    const totalHours =
      handleParseAmount(splitTime[0]) + handleParseAmount(splitTime[1]) / 60;
    if (opentime) {
      if (gotTime === new Date().getDate()) {
        for (let i = Math.round(totalHours); i < closetime; i++) {
          timeInterval.push({
            id: i + ":00",
            name: `${moment(i, "HH").format("hh A")} - ${moment(
              i + 1,
              "HH"
            ).format("hh A")}`,
          });
        }
      } else {
        for (let i = Math.round(opentime); i < closetime; i++) {
          timeInterval.push({
            id: i + ":00",
            name: `${moment(i, "HH").format("hh A")} - ${moment(
              i + 1,
              "HH"
            ).format("hh A")}`,
          });
        }
      }
      setIntDate(timeInterval);
    }
  };

  const onChangeInput = (value) => {
    setInputData(value);
  };

  const onCenter = (value) => {
    if (value === false) {
      setLatLng({ lat: "", lng: "" });
    }
  };

  const onCloseAlert = () => {
    setAlertPopup("");
  };

  const onCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    const locationDetails = {
                      location: results[0].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                    };
                    setInputvalue(locationDetails);
                    setLatLng({ lat: json.lat, lng: json.lng });
                    onMarkerPosition({ lat: json.lat, lng: json.lng });
                    setLocation(true);
                    setErrorData(false);
                    setInputData(results[0].formatted_address);
                    if (active === "delivery") {
                      setPopup(true);
                    } else {
                      onConfirmLocation(locationDetails);
                    }
                  }
                }
              },
              this
            );
          }
          // eslint-disable-next-line no-unused-vars
        },
        (_error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  const onCurrentLocations = () => {
    setAlertData(false);
    onCurrentLocation();
  };

  const onCurrentLocationData = (value) => {
    setLatLng({ lat: value.latitude, lng: value.longitude });
    setInputData(value.location);
  };

  const onBackClick = () => {
    setPopup(false);
  };

  const handleIamInterested = () => {
    const payload = {
      latitude: latLng.lng,
      longitude: latLng.lat,
      address: inputData,
    };
    handleSaveInterest(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setToaster({
          toastType: true,
          toastText:
            "Thanks for your interest we would love to serve you soon as possible.",
          isShow: true,
        });
      } else {
        setToaster({
          toastType: true,
          toastText: res.response.message,
          isShow: true,
        });
      }
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const AddressModal = () => {
    return (
      isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <img
              src={remove}
              className="deliveryContainer_right_removeImg"
              onClick={() => closeModal()}
              alt="remove"
            />
            <p className="savedAddress">Previous Saved Addresses</p>
            <SavedAddress data={savedAddress} onClick={onFindFood} />
          </div>
        </div>
      )
    );
  };

  const selectSavedAddress = () => {
    return (
      active == "delivery" &&
      savedAddress &&
      savedAddress.length > 0 && (
        <>
          <Button
            content="Show Saved Addresses"
            className="map-search_button1"
            onClick={openModal}
            customClass="map-search_button_content"
          />
          <AddressModal />
        </>
      )
    );
  };
  return (
    <>
      <Header
        walletStatus
        showButton
        LoginCLick={LoginCLick}
        SigninClick={SigninClick}
        profileData={profileData}
        deliveryClick={deliveryClick}
        headerDeliveryTime={headerDeliveryTime}
      />

      {(loginFlow || loginFlow1) && (
        <Modal
          modalCustomClass="modalCustomEdit1"
          onCloseHandler={closeHandle}
          hideClose={stat === "stage2" || stat === "stage3"}
          isMobileFullLayout
        >
          {stat === "stage1" && (
            <CreateAccount
              closeHandle={closeHandle}
              onNav={onNav}
              errorTxt1={errorTxt1}
              getMobileData={getMobileData}
              setErrorTxt1={setErrorTxt1}
              //   onValidate={() => onValidate()}
              isSignIn={isSignIn}
              setIsSignIn={setIsSignIn}
              isLoginBtnLoading={isLoginBtnLoading}
            />
          )}
          {stat === "stage2" && (
            <OtpVerification
              autoReadOtpValue={otp}
              mobile={mobileNumber}
              displayMobile={displayMobileNumber}
              errorTxt={errorTxt}
              onNav={onNav}
              getResendData={getResendData}
              getOtpData={getOtpData}
              successTxt={successTxt}
            />
          )}
          {stat === "stage3" && (
            <BasicDetails
              errorMsg={errorMsg}
              onNav={onNav}
              getBasicDetails={getBasicDetails}
              data={basicDetailsData}
            />
          )}
        </Modal>
      )}

      {deliveryFlow && (
        <Modal
          modalCustomClass={`modalCustomEdit11 product-list-modal`}
          onCloseHandler={closedelivery}
          hideClose={stat === "stage2" || stat === "stage3"}
          isMobileFullLayout
        >
          <div className="deliveryContainer">
            <div className="deliveryContainer_right">
              <h1 className="deliveryContainer_heading">Enter Location</h1>
              <div className="deliveryContainer_switches">
                <Switch
                  data={switchData}
                  active={active}
                  onFilter={onChangeMode}
                />
                <div className="deliveryContainer_switches_gap" />
                <RadioButton
                  data={radioData}
                  activeOrder={activeOrder}
                  onChange={onChangeOrder1}
                />
              </div>

              {activeOrder === "orderLater" && (
                <div className="deliveryContainer_orderLater">
                  <div className="deliveryContainer_orderLater_container">
                    <div className="deliveryContainer_orderLater_date">
                      <DateTimePicker
                        onChange={(value) => onChangeDate(value)}
                        value={date}
                        className="deliveryContainer_orderLater_datetime"
                        format="yyyy-MM-dd"
                        placeholderText="Enter date"
                        minDate={
                          // LocalStorage.get('Outlet_Details').max_preordering_day
                          new Date(
                            new Date().getTime() +
                            LocalStorage.get("Outlet_Details")
                              .min_preordering_day *
                            24 *
                            60 *
                            60 *
                            1000
                          )
                        }
                        maxDate={
                          // LocalStorage.get('Outlet_Details').max_preordering_day
                          new Date(
                            new Date().getTime() +
                            (LocalStorage.get("Outlet_Details")
                              .max_preordering_day -
                              1) *
                            24 *
                            60 *
                            60 *
                            1000
                          )
                        }
                        disabled={inputData === "" ? true : false}
                        clearIcon={date === "" ? "" : "x "}
                        yearPlaceholder="Enter date"
                        monthPlaceholder=""
                        dayPlaceholder=""
                      />
                    </div>
                  </div>
                  <div className="deliveryContainer_orderLater_container">
                    {intervalModal && date !== "" && (
                      <div className="deliveryContainer_orderLater_container_drop">
                        <IntervalDrop
                          setInterval={setInterval}
                          interval={interval}
                          intervalModal={intervalModal}
                          setIntervalModal={setIntervalModal}
                          setTime={setTime}
                          timeInterval={intDate}
                        />
                      </div>
                    )}

                    <div className="deliveryContainer_orderLater_time">
                      <p
                        onClick={() => {
                          setIntervalModal(!intervalModal);
                        }}
                        className="deliveryContainer_orderLater_datetime"
                      >
                        {interval === "" ? "Select Interval" : interval}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <RenderMaps
                callOutlet={callOutlet}
                onConfirmLocation={onConfirmLocation}
                latLng={latLng}
                activeTheme={active}
                selectSavedAddress={selectSavedAddress}
              />
              {alertData && (
                <h2 className="deliveryContainer_delivery_phrase">
                  Please Enter location !!
                </h2>
              )}

              {active !== "delivery" && outlets && outlets.length > 0 && (
                <>
                  <h4 className="deliveryContainer_error_recommend">
                    Select Outlet to order from
                  </h4>
                  <div className="deliveryContainer_store-list">
                    {outlets.map((item) => {
                      return (
                        <StoreCard
                          data={item}
                          theme={active}
                          onClickStore={onClickStore}
                        />
                      );
                    })}
                  </div>
                </>
              )}
              {errorData && (
                <>
                  <div className="deliveryContainer_error">
                    <h3 className="deliveryContainer_error_text">
                      Sorry! No outlet found near to your location! Please click
                      here if you want BBK outlet to be open near to your
                      location -
                      <span
                        className="deliveryContainer_interest-text"
                        onClick={handleIamInterested}
                      >
                        &nbsp;Yes I Want
                      </span>
                    </h3>
                    <img
                      src={LocationSearch}
                      width="132"
                      height="117"
                      alt="locationSearch"
                    />
                  </div>
                </>
              )}
              {errorData1 && countData && countData.count && (
                <>
                  <h3 className="deliveryContainer_error_recommend">
                    Recommend for take away
                  </h3>
                  <div className="deliveryContainer_error">
                    <p className="deliveryContainer_error_text1">
                      Hey ! found {countData.count} bbk restaurant{" "}
                      {countData.distance} km from your location would you do
                      take away from these restaurants
                    </p>
                    <div className="deliveryContainer_error_button">
                      <Button
                        content="Search Now"
                        onClick={() => onStoreSelect()}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default HeaderCommon;
