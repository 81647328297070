import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const CountButton = (props) => {
  const {
    className,
    content,
    type,
    outlined,
    size,
    theme,
    onClick,
    image,
    disabled,
    addCount,
    subCount,
    head,
    cartShow,
    id,
    customSubIconClass,
    customAddIconClass,
  } = props;

  return (
    <button
      type='button'
      disabled={disabled}
      id={id}
      className={`button ${theme} ${type} ${
        outlined && 'outlined'
      } ${size} ${className} `}
      onClick={head !== 'nocontent' && content === 0 ? addCount : cartShow}
    >
      <img src={image} alt="image" />
      {head === 'nocontent' || content > 0 ? (
        <>
          <p
            onClick={content !== 0 ? subCount : null}
            className={`button_subIcon ${customSubIconClass}`}
          >
            -
          </p>
          <span className='button__content'> {content}</span>
          <p
            onClick={addCount}
            className={`button_addIcon ${customAddIconClass}`}
          >
            +
          </p>
        </>
      ) : (
        <span className='button__content'>
          {head !== 'nocontent' ? 'Add +' : '+'}
        </span>
      )}
    </button>
  );
};

CountButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  outlined: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
};

CountButton.defaultProps = {
  className: '',
  content: null,
  outlined: false,
  size: '',
  type: '',
  theme: '',
};
export default CountButton;
