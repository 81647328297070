import axios from 'axios';
import { API_METHODS, API_CONSTANTS } from '../constants/api-constants';
import { Authentication, Logout } from '../actions/authentication';
import { LocalStorage } from './storage';
import { ROUTES } from "../constants/app-constants";

const ajaxRequest = null;

export const fetchCall = (url, method, payload, callback, ...args) =>
  new Promise((resolve, reject) => {
    const hasWindow = typeof window !== 'undefined';
    const accessToken = hasWindow && localStorage?.getItem('AccessToken');
    // const token = hasWindow && localStorage?.getItem('token');
    const clientId = 'bbk';

    // const restArgs = args.length && args[0];
    let options = {};

    const headers = {
      'Content-Type': 'application/json',
    };

    if (!url.includes(API_CONSTANTS.TOKEN)) {
      headers['x-access-token'] = accessToken;
      headers['client-id'] = clientId;
    }

    if (method === API_METHODS.GET) {
      options = {
        method,
        headers,
        url,
      };
    } else {
      options = {
        method,
        data: payload,
        headers,
        url,
      };
    }

    axios(options)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 400) {
          callback(error.response.data);
          return;
        }
        callback(error);
      });
  });

let failedRequests = [];
let isRefreshing = false;

function processQueue(error, token = null) {
  failedRequests.forEach(({ promise, config }) => {
    if (error !== null) {
      promise.reject(error);
    } else {
      // config.headers.auth_token = token || '';
      const clientId = 'bbk';
      config.headers['x-access-token'] = token || '';
      config.headers['client-id'] = clientId;

      axios
        .request(config)
        .then((response) => {
          promise.resolve(response);
        })
        .catch((error) => {
          // promise.reject(error);
        });
    }
  });

  // failedRequests = false;
  isRefreshing = false;
  failedRequests = [];
}

export async function makeHttpRequestForRefreshToken() {
  const url = API_CONSTANTS.REFRESH_TOKEN;
  const isLoggedIn = LocalStorage.get('isLoggedIn');
  const refresh_token = LocalStorage.get('RefreshToken');

  return new Promise((resolve, reject) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': refresh_token,
        },
        url,
        data: {},
      };
      axios(options)
        .then((response) => {
          if (response && response.content && response.content.auth_token) {
            localStorage.setItem('AccessToken', response.content.auth_token);
            resolve(response.content.auth_token);
          } else {
            if (response.status === 401) {
              if (!isLoggedIn) {
                Authentication((res) => {
                  if (res.response.status === 200) {
                    if (res.content) {
                      LocalStorage.set('AccessToken', res.content.access_token);
                      LocalStorage.set(
                        'RefreshToken',
                        res.content.refresh_token
                      );
                    }
                  }
                });
              } else {
                LocalStorage.set('isLoggedIn', false);
                LocalStorage.remove('UserData');
                Authentication((res) => {
                  if (res.response.status === 200) {
                    if (res.content) {
                      LocalStorage.set('AccessToken', res.content.access_token);
                      LocalStorage.set(
                        'RefreshToken',
                        res.content.refresh_token
                      );
                    }
                  }
                });
              }
            }
            resolve(null);
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            if (!isLoggedIn) {
              Authentication((res) => {
                if (res.response.status === 200) {
                  if (res.content) {
                    LocalStorage.set('AccessToken', res.content.access_token);
                    LocalStorage.set('RefreshToken', res.content.refresh_token);
                  }
                }
              });
            } else {
              LocalStorage.set('isLoggedIn', false);
              LocalStorage.remove('UserData');
              Authentication((res) => {
                if (res.response.status === 200) {
                  if (res.content) {
                    LocalStorage.set('AccessToken', res.content.access_token);
                    LocalStorage.set('RefreshToken', res.content.refresh_token);
                  }
                }
              });
            }
          }
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

axios.interceptors.response.use(
  (response) => response?.data, // Return a successful response back to the calling service
  (error) => {
    const { response, config } = error;
    let errorResponse = null;
    if (!config || !response) {
      if (error.message === 'Network Error') {
        errorResponse = {
          error: {
            code: 503,
            message: error.message,
          },
        };
      }
      return errorResponse;
    }

    const REFRESH_TOKEN = API_CONSTANTS.REFRESH_TOKEN;

    if (config.url === REFRESH_TOKEN) {
      Logout((re) => {
        if (re.response.status === 200) {
          LocalStorage.set("isLoggedIn", false);
          LocalStorage.set("UserData", {});
          LocalStorage.remove("CartProducts");
          Authentication((res) => {
            if (res.response.status === 200) {
              if (res.content) {
                LocalStorage.set("AccessToken", res.content.access_token);
                LocalStorage.set("RefreshToken", res.content.refresh_token);
                window.location.href = ROUTES.HOME;
              } else {
                window.location.href = ROUTES.HOME;
              }
            }
          });
        }
      });

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (
      response &&
      response.status === 401 &&
      config &&
      !config.__isRetryRequest
    ) {
      if (isRefreshing) {
        try {
          return new Promise((resolve, reject) => {
            const options = {
              promise: {
                resolve,
                reject,
              },
              config,
            };
            failedRequests.push(options);
          });
        } catch (e) {
          return e;
        }
      }
      isRefreshing = true;

      config.__isRetryRequest = true;
      // Try request again with new token
      return makeHttpRequestForRefreshToken()
        .then((response) => {
          // New request with new token
          const { config } = error;

          processQueue(null, response);
          const token = response;
          // config.headers.auth_token = token || '';
          const clientId = 'bbk';
          config.headers['x-access-token'] = token;
          config.headers['client-id'] = clientId;

          // setTimeout(() => {
          //   isRefreshing = false;
          // }, );

          // config.__isRetryRequest = false;
          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error) => {
          // reject(error);
        });
    }
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);
