import React from "react";
import "./index.scss";
import moment from "moment";
const PastOrderBillDetails = (props) => {
  const { data } = props;
  console.log("pastorderdetails", data);
  const bill = [
    {
      name: "Item total",
      val: data.price,
    },
    {
      name: "Delivery charge",
      val: data.delivery_charges,
    },
    {
      name: "Packaging charge",
      val: data.package_charges,
    },
    {
      name: "Discount",
      val: data.discount,
    },
    {
      name: "CGST",
      val: data.cgst,
    },
    {
      name: "SGST",
      val: data.sgst,
    },
    {
      name: "Donation",
      val: data.donation,
    },
    {
      name: "Delivery tips",
      val: data.delivery_tips,
    },
  ];

  const { innerWidth } = window;

  const handleShowItem=(item)=>{
     let isHideItem = false
     const amount =  Number(parseFloat(item?.val).toFixed(2))
     if(amount === 0){
      isHideItem = true
     }
     return isHideItem;
  }

  return (
    <div className="pastBill">
      <p className="pastBill_nameLabel">Bill detail</p>
      {innerWidth >= 1024 && <div className="pastBill_divider" />}
      <div className="pastBill_items">
        {bill.map((item) => {
           return (
            !handleShowItem(item) &&
            <div className="pastBill_items_item">
              <p
                className={`pastBill_items_item_one ${
                  item?.name === "Discount" && "pastBill_items_item_orderOne"
                }`}
              >
                {item.name}
              </p>
              <p
                className={`pastBill_items_item_two ${
                  item?.name === "Discount" && "pastBill_items_item_orderTwo"
                }`}
              >
                ₹{Number(parseFloat(item.val).toFixed(2))}
              </p>
            </div>
          );
        })}
      </div>
      <div
        className={`${
          innerWidth <= 1023 ? `pastBill_dividerr` : `pastBill_divider`
        }`}
      />
      <div className="pastBill_items">
        <div className="pastBill_items_item1">
          <p className="pastBill_items_item1_one">Total Amount</p>
          <p className="pastBill_items_item1_two">
            ₹{Number(parseFloat(data.initial_paid_amount).toFixed(2))}
          </p>
        </div>
      </div>
    </div>
  );
};
export default PastOrderBillDetails;
