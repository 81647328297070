import React from "react";
import "./index.scss";
import logo from "../../assets/images/logo.png";
import Accept from "../../assets/images/acceptOrder.svg";
import Timer from "../../assets/images/timer.svg";
import {
  handleGetTotalAmt,
  handleParseAmount,
} from "../../helpers/sharedFunction";

const ItemDetails = (props) => {
  const { data, trackingDetails } = props;
  const { innerWidth } = window;
console.log(data,"track order item details")
  const handleProductType = (type = null) => {
    let clsName = "";
    if (type !== null) {
      if (type) {
        return (clsName = "itemDetails_data_main_one__product-veg");
      }
      return (clsName = "itemDetails_data_main_one__product-non-veg");
    }
  };

  const handleTotalAmount = (finalPrice, addons) => {
    let totalAddonsPrice = 0;

    addons &&
      addons?.map((data) => {
        totalAddonsPrice =
          handleParseAmount(data.final_price) *
            handleParseAmount(data.item_count) +
          totalAddonsPrice;
      });

    const amt = totalAddonsPrice + handleParseAmount(finalPrice);

    return amt;
  };

  return (
    <div className="itemDetails">
      {innerWidth <= 1023 ? (
        <>
         <div className="itemDetails_data">
            <div className="itemDetails_data_main">
              <div className="itemDetails_data_main_one">
                <div>
                  <div className={handleProductType(data.is_veg)} />
                </div>
                <p className="itemDetails_data_main_one_name">{data.name}</p>
                
              </div>
              
            </div>
            <div className="itemDetails_data_main_two">
                <p className="itemDetails_data_main_two_sub">Qty{Number(data.item_count)} 
                </p>
              </div>
           
          </div>
          <p className="itemDetails_data_price">
              ₹{handleTotalAmount(data.final_price, data.addons)}/-
            </p>
        </>
      ) : (
        <>
          <div className="itemDetails_data">
            {/* <img src={data.image_url && data.image_url !=='' ? data.image_url : logo} className='itemDetails_data_img' /> */}
            <div className="itemDetails_data_main">
              <div className="itemDetails_data_main_one">
                <div>
                  <div className={handleProductType(data.is_veg)} />
                </div>
                <p className="itemDetails_data_main_one_name">{data.name}</p>
              </div>
              <div className="itemDetails_data_main_two">
                {/* <img src={Timer} className="itemDetails_data_main_two_img" /> */}
                <p className="itemDetails_data_main_two_sub">
                  {Number(data.item_count)} items
                </p>
              </div>
            </div>

            <p className="itemDetails_data_price">
              ₹{handleTotalAmount(data.final_price, data.addons)}/-
            </p>
          </div>
          <p className="itemDetails_desc">{data.description}</p>
        </>
      )}
    </div>
  );
};

export default ItemDetails;
