import { useEffect, useState } from "react";

import AddAddress from "../add-address";
import Toaster from "../../components/toaster";
import MapModal from "../mapModal";
import {
  getAvailableAddress,
  addAddressNew,
  editAddress,
  getIsdeliveredToAddress,
} from "../../actions/location";
import { LocalStorage } from "../../utils/storage";
import AddressCard from "../../components/address-card";
import Button from "../../components/button";
import Modal from "../../components/modal";
import back from "../../assets/images/close.svg";
import { checkoutAddressNetCoreEvent } from "../../helpers/netcore";
import Loader from "../../components/Loader";

import "./index.scss";
import { Analytics } from "../../helpers/analytics";
import RenderMaps from "../MapsCommonApi";
import AlertPopup from "../../components/alert-popup";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import { OutletMapping, getMenuList } from "../../actions/home";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Dexie from "dexie";

const ListAddress = (props) => {
  const [addressList, setAddressList] = useState({
    delivered: [],
    not_delivered: [],
  });
  const [addressListCpy, setAddressListCpy] = useState(null);
  const [mapData, setMapData] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [addressForm, setAddressForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  const [editData, setEditData] = useState({});
  const history = useHistory();
  const { innerWidth } = window;
  const [alertPopup, setAlertPopup] = useState("");
  const [addressChanged,setAddressChanged]=useState("");
  const {
    setAddress,
    handleListAddressModal,
    setSelectedAddress,
    selectedAddress,
    localAddressList,
    setLocalAddressList,
    click,
    openAddressForm,
    isHideOrderType,
    
  } = props;
  const urlParams = useLocation();
  const urlValue = urlParams.pathname.split("/")[3];
  const urlCity = urlParams.pathname.split("/")[2];
  const [outlets, setOutlets] = useState([]);
  useEffect(() => {
    let locationDetails = LocalStorage.get("location");
    getDeliveryAddress();
    if (locationDetails) {
      setMapData((prevState) => ({
        ...prevState,
        Google_Address: locationDetails.address,
        guest_lat: Number(locationDetails.lat),
        guest_long: Number(locationDetails.lng),
      }));
      // openAddressForm && handleEditAddress(selectedAddress);
    }
  }, []);

  const getDeliveryAddress = () => {
    setIsLoading(true);
    const outletId = LocalStorage.get("outlet_id");
    const delivery_area_id =
      LocalStorage.get("Outlet_Details").delivery_area_id;
    const queryParams = `?is_delivery=True&outlet_id=${outletId}&delivery_area_id=${delivery_area_id}`;
    getAvailableAddress((res) => {
      if (res && res.response && res.response.status === 200) {
        // if (res.content.address_available.length > 0) {
        setAddressListCpy(res.content.address_available);
        handleAddressListRes(res.content.address_available, localAddressList);
        setIsLoading(false);
        // }
      } else {
        handleAddressListRes([], localAddressList);
        setIsLoading(false);
      }
    }, queryParams);
  };

  const handleAddressListRes = (address = [], localAddress) => {
    let addressCpy = [];
    if (localAddress && localAddress.length > 0) {
      addressCpy = [...address, ...localAddress];
    } else {
      addressCpy = [...address];
    }
    let filteredAddress = {
      delivered: [],
      not_delivered: [],
    };

    addressCpy &&
      addressCpy.forEach((element) => {
        if (element.is_available) {
          filteredAddress.delivered.push(element);
        } else {
          filteredAddress.not_delivered.push(element);
        }
      });
    setAddressList({ ...filteredAddress });
  };

  const handleSelectedAddress = (data) => {
    // debugger;
    let addressData = LocalStorage.get("location");
    const updatedAddress = {
      ...addressData,
      address: `${
        data?.Google_Address && data?.Google_Address !== "N/A"
          ? data.Google_Address
          : data.Address1
      }`,
      // address: `${data.Address1} ${data.Address2} ${data.reach ? data.reach :''}`,
      lat: Number(data.guest_lat),
      lng: Number(data.guest_long),
    };
    setSelectedAddress(data);
    setAddress(data.Google_Address ? data.Google_Address : data.Address1);
    LocalStorage.set("location", updatedAddress);

    handleListAddressModal();
    
    const checkoutAddressNetCorePayload = {
      address: data.Address1,
    };
    // checkoutAddressNetCoreEvent(checkoutAddressNetCorePayload);
  };

  const onMenuDataSync = (data) => {
    const outletData = LocalStorage.get("CartProducts");
    const updateitem = data.map((items) => {
      let updateItem = [];
      if (items.has_subcategory === "0") {
        updateItem = items.items;
      }
      if (items.has_subcategory === "1") {
        items.subcategories.map((item) => {
          item.items.map((value) => {
            updateItem.push(value);
          });
        });
        items.items = updateItem;
      }
      items.itemData = updateItem.map((item) => {
        item.checked = false;
        item.count = 0;
        if (LocalStorage.get("CartProducts")) {
          const productData = LocalStorage.get("CartProducts");
          if (productData && productData.length > 0) {
            productData.map((items) => {
              if (items.item_id === item.item_id) {
                item.count = items.count;
              }
            });
          }
        }
        return item;
      });
      items.vegData = updateItem.filter((item) => item.item_tags.includes("1"));
      items.nonVeg = updateItem.filter((item) => item.item_tags.includes("2"));
      return items;
    });
    const updateItemData = [];
    updateitem.map((items) => {
      items.itemData.map((item) => {
        updateItemData.push(item);
      });
    });
    const datavalue = updateItemData.filter((itms) => {
      return outletData.includes(itms.item_id);
    });
  };

  const onClickStore = (
    data,
    type ,
    address,
    latlng,
    addressType,
  ) => {
    // debugger;
    if (data && data?.is_open) {
      // setOutletLoader(true);
      LocalStorage.set("Outlet_Details", data);
      getMenuList(
        urlValue &&
          urlValue?.length > 0 &&
          data?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
          ? urlValue
          : data?.outlets_id,
        (res) => {
          if (res && res.content && res.response.status === 200) {
            if (res.content) {
              if (LocalStorage.get("outlet_id")) {
                const oldOutlet = LocalStorage.get("outlet_id");
                if (oldOutlet !== res.content.menu.unit_id) {
                  if (LocalStorage.get("isLoggedIn")) {
                    onMenuDataSync(res.content.menu.categories);
                  } else {
                    LocalStorage.set("CartProducts", []);
                  }
                }
              }
              LocalStorage.set("outlet_id", data?.outlets_id);
              const dataValue = {
                orderFor: "delivery",
                orderTime: "orderNow",
                deliveryTime: data?.delivery_area_delivery_time,
                address: data.address ? data.address : address,
                date: "",
                time: "",
                lat: latlng.lat,
                lng: latlng.lng,
                addressType : addressType.addressType,
                address1 : addressType.addressLine,
              };
              LocalStorage.set("location", dataValue);

              const db = new Dexie(res.content.menu.unit_id);
              db.version(1).stores({
                menu: "++id, categories, item_tags, taxes",
              });
              const getMenuData = async () => {
                const allData = (await db.menu.toArray()) || [];
                if (allData && allData.length > 0) {
                  await db.menu.update(0, res.content.menu);
                } else {
                  await db.menu.add(res.content.menu);
                }
                history.push(ROUTES.HOME);
                window.location.reload();
              };
              getMenuData();
            }
          } else {
            // setOutletLoader(false);
            setAlertPopup(
              "Sorry!! No Data Found. Please try again later sometime"
            );
          }
        }
      );
    } else {
      // debugger;
      // if (
      //   oldAddressData &&
      //   data &&
      //   data.outlet_status_info.web_err_msg !==
      //     "Sorry, the outlet is not operational now, but you can pre-order."
      // ) {
      //   LocalStorage.set("location", oldAddressData);
      // }
      setAlertPopup(
        data && data.outlet_status_info.web_err_msg
          ? data.outlet_status_info.web_err_msg
          : "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
      // setErrorMsg(
      //   data && data.outlet_status_info.web_err_msg
      //     ? data.outlet_status_info.web_err_msg
      //     : "Sorry!! The outlet is currently closed. Please try again later sometime"
      // );
    }
  };
  const onClickAddress = (
    locationDetails,
    type = "delivery"
  ) => {
    let payload = {
      latitude: locationDetails.guest_lat,
      longitude: locationDetails.guest_long,
      is_delivery: true ,
      is_order_now:true,
      order_date:"",
      order_time: "",
      channel: "online_web",
    };
    

    OutletMapping(payload, (res) => {
      // debugger;
      if (res && res?.response?.status === 200) {
        if (res && res?.content && res?.content?.areas) {
          setOutlets(res.content.areas);
          console.log(res.content,"address outlet mapping")
          if (type === "delivery") {
            // debugger;
            onClickStore(
              res?.content?.areas[0],
              type === "takeaway",
              locationDetails.Locality,
              {
                lat: locationDetails.guest_lat ,
                lng: locationDetails.guest_long ,
              },
          
              {
                addressType : locationDetails.AddressType,
                addressLine : locationDetails.Address1,
              }
              
            );
          }
          // setAlertData(false);
        }
        // setData([]);
      } 
    });
  };
  const handleMap = () => {
    // debugger;
    setShowMap((prevState) => !prevState);
  };


  const handleCheckAvailableForDelivery = () => {
     
    const outletId = LocalStorage.get("outlet_id");
    const delivery_area_id =
      LocalStorage.get("Outlet_Details").delivery_area_id;
    const queryParams = `?is_delivery=True&outlet_id=${outletId}&lat=${mapData.guest_lat}&long=${mapData.guest_long}&delivery_area_id=${delivery_area_id}`;

    getAvailableAddress((res) => {
       
      if (res && res.response && res.response.status === 200) {
         
        if (
          res.content &&
          res.content.address_available &&
          res.content.address_available[0]
        ) {
           
          handleConfirmAddress(res.content.address_available[0].is_available);
        }
      } else {
        setToaster({
          toastType: false,
          toastText: res?.response?.message
            ? res?.response?.message
            : "Something went wrong",
          isShow: true,
        });
      }
    }, queryParams);
  };

  const handleConfirmAddress = (isAvailble) => {
    //  debugger;
    const locationArr = mapData.Google_Address;
    let payload = {
      address_id: "0",
      city: locationArr[locationArr.length - 3],
      pin_code: mapData.Pin ? mapData.Pin : locationArr[locationArr.length - 2],
      AddressType: mapData.AddressType,
      location_name:
        locationArr[locationArr.length - 5] +
        locationArr[locationArr.length - 4],
      address_1: mapData.Address1,
      address_2: mapData.Address2,
      latitude: mapData.guest_lat,
      longitude: mapData.guest_long,
      Google_Address: locationArr,
    };

    if (mapData.AddressId || mapData.is_local_address) {
      payload.address_id = mapData.AddressId;
      if (mapData.is_local_address) {
        payload.is_local_address = mapData.is_local_address;
        payload.id = mapData.id;
      }
      handleEdit(payload);
    } else {
      const checkoutAddressNetCorePayload = {
        address: locationArr,
      };
      // checkoutAddressNetCoreEvent(checkoutAddressNetCorePayload);
      handleAddAddress(mapData, isAvailble);
    }
  };

  const setLocationData = () => {
    let locationDetails = LocalStorage.get("location");
    if (locationDetails) {
      setMapData((prevState) => ({
        Google_Address: locationDetails.address,
        guest_lat: Number(locationDetails.lat),
        guest_long: Number(locationDetails.lng),
      }));
    }
  };

  // const handleGetAddress = () => {
  //   getAddress((res) => {
  //     if (res && res?.response?.status === 200) {
  //       setAddressData(res?.content?.address);
  //     }
  //   });
  // };
  const handleAddAddress = (add, isAvailble) => {
    const queryParams = `/lat${add.latitude}&&long${add.longitude}`;
// debugger;
    // getIsdeliveredToAddress((res) => {
    // if (res && res.response && res.response.status === 200) {
    // add.is_available = res.response.is_available
    add.is_available = isAvailble;
    add.address_id = "0";
    add.is_local_address = true;
    add.id = localAddressList.length;

    setLocalAddressList((prevState) => [...prevState, add]);

    if (isAvailble) {
      setAddressList((prevState) => ({
        ...prevState,
        delivered: [...prevState["delivered"], add],
      }));
      let addressData = LocalStorage.get("location");
      const updatedAddress = {
        ...addressData,
        address: `${add.Google_Address}`,
        // address: `${add.Address1} ${add.Address2} ${add.reach ? add.reach :''}`,
        lat: Number(add.guest_lat),
        lng: Number(add.guest_long),
      };
      setSelectedAddress(add);
      setAddress(add.Google_Address);
      LocalStorage.set("location", updatedAddress);
      handleListAddressModal();
    } else {
      setAddressList((prevState) => ({
        ...prevState,
        not_delivered: [...prevState["not_delivered"], add],
      }));
    }
    let payload;
    payload = {
      address_id: "0",
      city: add.city,
      pin_code: add.Pin,
      AddressType: add.AddressType,
      location_name: add.Google_Address,
      address_1: add.Address1,
      address_2: add?.Address2 ? add.Address2 : "",
      latitude: add.guest_lat,
      longitude: add.guest_long,
      Google_Address:add.Google_Address,
    };
    addAddressNew(payload, (res) => {
      if (res && res?.response?.status === 200) {
        console.log(res, "add address post api");
        // setTimeout(() => {
        //   handleGetAddress();
        // }, 1000);

        handleAddressForm();
      }
    });
    
   
  };

  const handleRefreshList = () => {
    setTimeout(() => {
      getDeliveryAddress();
    }, 1000);
  };

  const handleEdit = (payload) => {
    if (payload.is_local_address) {
      const listAddresCpy = [...localAddressList];
      const findIndex = localAddressList.findIndex(
        (obj) => obj.id === payload.id
      );
      listAddresCpy[findIndex] = mapData;
      setLocalAddressList(listAddresCpy);
      handleAddressListRes(addressListCpy ? addressListCpy : [], listAddresCpy);
      handleAddressForm();
    } else {
      setIsLoading(true);

      editAddress(payload, (res) => {
        if (res && res?.response?.status === 200) {
          setLocationData();
          setIsLoading(false);
          setToaster({
            toastType: true,
            toastText: res.response.message,
            isShow: true,
          });
          handleRefreshList();
          handleAddressForm();
        } else {
          setIsLoading(false);
          setToaster({
            toastType: false,
            toastText: res.response.message,
            isShow: true,
          });
        }
      });
    }
  };

 

  const handleAddressForm = () => {
    setAddressForm((prevState) => !prevState);
  };


  const onConfirmLocation = (location, active, address) => {
    // debugger;
    setMapData((prevState) => ({
      ...prevState,
      // Address1: address,
      Google_Address: location.formatted_address? location.formatted_address : address,
      guest_lat: Number(location.lat),
      guest_long: Number(location.lng),
      city: location.city,
      Pin: location.pincode,
      locality: location.locality,
    }));
    setAddress(address);
    // const dataValue = {
    //   orderFor: active,
    //   orderTime: activeOrder,
    //   deliveryTime: data.delivery_area_delivery_time,
    //   address: address,
    //   date: date,
    //   time: time,
    //   lat: Number(location.lat),
    //   lng: Number(location.lng),
    // };
    // LocalStorage.set("location", dataValue);
    console.log(mapData, "mapdata");
    handleAddressForm();
    handleMap();
  };

  const handleAddressChange = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      Address1: val,
    }));
  };

  const handleReachChange = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      Address2: val,
    }));
  };

  const handleAltNumberChange = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      altNumber: val,
    }));
  };

  const handleAddressType = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      AddressType: val,
    }));
  };
  const handleLocationOthers = (val) => {
    setMapData((prevState) => ({
      ...prevState,
      selectedOthers: val,
    }));
  };

  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };

  const handleEditAddress = (item) => {
    setEditData(item);
    setMapData({
      Google_Address: !item.Google_Address
        ? item.Address1
        : item.Google_Address,
      ...item,
      guest_lat: Number(item.guest_lat),
      guest_long: Number(item.guest_long),
    });
    handleAddressForm();
  };

  const addressChange = () => {
    handleAddressForm();
    handleMap();
  };

  const handleModal = () => {
    if (addressForm) {
      setLocationData();
      handleAddressForm();
      return;
    }
    handleListAddressModal();
  };



  
  const handleNotdeliverableAddress =(item)=>{
    // debugger;
    console.log(item,"address item")
    setAddressChanged(item)
    setAlertPopup(
      "You are selecting the address which is mapped to a different outlet, if you proceed then your cart will get clear."
    );
  }
  const onCloseAlert = () => {
    // debugger;
    onClickAddress(addressChanged)
    // LocalStorage.set("CartProducts", []);
    setAlertPopup("");
    
    // history.push(ROUTES.HOME);
  };

  return (
    <div>
      <Modal
        onCloseHandler={() => handleModal()}
        // hideClose={()=>handleModal()}
        hideClose={innerWidth <= 1023 ? true : false}
        modalCustomClass={`list-address-modal ${
          addressForm ? "enter-address-details" : ""
        }`}
      >
        {isLoading ? (
          <div className="list-address-modal_loader">
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <div>
            {" "}
            {!addressForm && (
              <>
                {innerWidth <= 1023 ? (
                  <div className="list-address">
                    <div className="list-address_wholeDiv">
                      <h1 className="list-address__select-header">
                        Select Delivery Address
                      </h1>
                      <img
                        src={back}
                        className="list-address_img"
                        onClick={click}
                        alt="back"
                      />
                    </div>
                    <div className="list-address_bodyDiv">
                      {addressList && addressList.delivered.length > 0 && (
                        <h2 className="list-address__delivered-to">
                          Delivers to
                        </h2>
                      )}
                      {addressList &&
                        addressList.delivered.length === 0 &&
                        addressList.not_delivered.length === 0 && (
                          <h3 className="list-address__no-data">
                            No delivery address found
                          </h3>
                        )}

                      <div className="list-address__list">
                        <AddressCard
                          handleSelectAddress={handleSelectedAddress}
                          address={addressList && addressList.delivered}
                          selectedAddress={selectedAddress}
                          handleEditAddress={handleEditAddress}
                        />
                      </div>

                      <div>
                        {addressList &&
                          addressList.not_delivered.length > 0 && (
                            <h2 className="list-address__not-delivered-to">
                             Does not deliver to (address mapped to different outlet)
                            </h2>
                          )}
                        <AddressCard
                          address={addressList && addressList.not_delivered}
                          handleNotdeliverableAddress ={handleNotdeliverableAddress}
                        />
                      </div>
                      <div className="list-address__add-address-btn-sticky">
                        <Button
                          className="list-address__add-address-btn"
                          content={"+ Add New Address"}
                          onClick={handleMap}
                          customClass="list-address_btncontent"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="list-address">
                    <h1 className="list-address__select-header">
                      Select Delivery Address
                    </h1>
                    {addressList && addressList.delivered.length > 0 && (
                      <h2 className="list-address__delivered-to">
                        Delivers to
                      </h2>
                    )}
                    {addressList &&
                      addressList.delivered.length === 0 &&
                      addressList.not_delivered.length === 0 && (
                        <p className="list-address__no-data">
                          {/* No delivery address found */}
                          Add a delivery address <br />You seem to be in the new location
                        </p>
                      )}

                    <div className="list-address__list">
                      <AddressCard
                        handleSelectAddress={handleSelectedAddress}
                        address={addressList && addressList.delivered}
                        selectedAddress={selectedAddress}
                        handleEditAddress={handleEditAddress}
                      />
                    </div>

                    <div>
                      {addressList && addressList.not_delivered.length > 0 && (
                        <h2 className="list-address__not-delivered-to">
                           Does not deliver to (address mapped to different outlet)
                        </h2>
                      )}
                      <AddressCard
                        address={addressList && addressList.not_delivered}
                        handleNotdeliverableAddress ={handleNotdeliverableAddress}
                      />
                    </div>
                    <div className="list-address__add-address-btn-sticky">
                      <Button
                        className="list-address__add-address-btn"
                        content={"+ Add New Address"}
                        onClick={handleMap}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {addressForm && (
          <AddAddress
            loc2={mapData.Google_Address}
            setAddress={handleAddressChange}
            address={mapData.Address1 ? mapData.Address1 : ""}
            setReach={handleReachChange}
            reach={mapData.Address2 ? mapData.Address2 : ""}
            setAlternateNumber={handleAltNumberChange}
            alternateNumber={mapData.altNumber ? mapData.altNumber : ""}
            setOthersTxt={handleAddressType}
            othersTxt={mapData.AddressType ? mapData.AddressType : ""}
            setSelectedAD={handleLocationOthers}
            submit={handleCheckAvailableForDelivery}
            addressChange={addressChange}
            logStatus={
              mapData.AddressId || mapData.is_local_address ? true : false
            }
            isHideOrderType={isHideOrderType}
            // deliveryText={mapData.location}
          />
        )}
      </Modal>
      {showMap && (
       
          <div className='mapModal'>   
              <Modal
              onCloseHandler={() => handleModal()}
              hideClose={innerWidth <= 1023 ? true : false}
              modalCustomClass='mapModal_map'
              contentCustomClass='mapModal_map_content'
            >
          <RenderMaps
            activeTheme={"delivery"}
            latLng={{ lat: mapData.guest_lat, lng: mapData.guest_long } }
            // onConfirmLocation={() => onConfirmLocation()}
            onConfirmLocation={onConfirmLocation}
            callOutlet={() => {}}
            stylechange
          />
        </Modal>
        </div>
       
      )}
      {toaster.isShow && (
        <Toaster closeToast={closeToast} toastText={toaster.toastText} />
      )}
      {alertPopup && (
        <AlertPopup
          // title="Sorry!!"
          description={alertPopup}
          okayClick={() => onCloseAlert()}
          alertCart={true}
          close={() => setAlertPopup(false)}
        />
      )}
    </div>
  );
};

export default ListAddress;
