import React, { useEffect, useState } from "react";
import "./index.scss";
import { LocalStorage } from "../../utils/storage";
import moment from "moment";
import { handleParseAmount } from "../../helpers/sharedFunction";

const OrderDetail = (props) => {
  const { data, created, surcharge } = props;
  const { innerWidth } = window;
  let offerCode = LocalStorage.get("offerwholedata");
  let locationDetails = LocalStorage.get("location");
  let outlet = LocalStorage.get("Outlet_Details");
  let orderCreated = data?.order_created_at;
  let currentTimeUTC = new Date(orderCreated);
  let currentTimeIST = moment(currentTimeUTC)
    .add(5, "hours")
    .add(30, "minutes");
  let startTime = moment(currentTimeIST).format("MMM Do YY, hh:mm:ss");
  // let startTime = moment(currentTimeIST).format("MMM Do YY");
  const handleConvertTime = (time) => {
    const splitTime = `${Number(parseFloat(time)) + 1}`;
    const formatedTime = moment(time, "HH:mm").format("hh A");
    const formatedTimeEnd = moment(splitTime, "HH").format("hh A");
    return `${formatedTime} - ${formatedTimeEnd}`;
  };


  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="orderDetail_data_item">
            <p className="orderDetail_data_item_head">Order Placed Date:</p>
            <p className="orderDetail_data_item_head">
              {moment(data?.order_created_at).format("MMM Do YYYY")}
            </p>
          </div>
          <div className="orderDetail_data_item">
            <p className="orderDetail_data_item_head">Order Placed Time:</p>
            <p className="orderDetail_data_item_head">{created}</p>
          </div>
          {data &&
            locationDetails &&
            data.pre_order_date &&
            data.pre_order_time && (
              <>
                <div className="orderDetail_data_item">
                  <p className="orderDetail_data_item_head">Delivery Date:</p>
                  <p className="orderDetail_data_item_head">
                    {moment(data.pre_order_date).format("MMM Do YYYY")}
                  </p>
                </div>
                <div className="orderDetail_data_item">
                  <p className="orderDetail_data_item_head">Delivery Time:</p>
                  <p className="orderDetail_data_item_head">
                    {handleConvertTime(data.pre_order_time)}
                  </p>
                </div>
              </>
            )}
          <div className="orderDetail_data_item">
            <p className="orderDetail_data_item_head">
              {data?.delivery_type === "delivery"
                ? "Delivery Address :"
                : "Picked Address:"}{" "}
            </p>
            {locationDetails && locationDetails.orderFor === "takeaway" ? (
              <p className="orderDetail_data_item_head1">
                {outlet && outlet.display_name}
              </p>
            ) : (
              <div className="orderDetail_data_item_head1">
                <p className="orderDetail_data_item_head1_type">
                  {data?.delivery_address?.AddressType
                    ? data?.delivery_address?.AddressType + ","
                    : ""}
                </p>
                <p>{data?.delivery_address.address1}</p>
                <p>
                  {data?.delivery_address.address2
                    ? data?.delivery_address.address2
                    : ""}
                </p>

              </div>
            )}
          </div>
          <div className="orderDetail_data_item">
            <p className="orderDetail_data_item_head">Cooking Instruction:</p>
            <p className="orderDetail_data_item_head">{data?.cooking_instruction}</p>
          </div>
        </>
      ) : (
        <>
          <div className="orderDetail">
            <h1 className="orderDetail_headLabel">Order Details</h1>
            <div className="orderDetail_data">
              <div className="orderDetail_data_item">
                <p className="orderDetail_data_item_head">
                  {innerWidth <= 1023 ? "Order number:" : "Order Id:"}
                </p>
                <p className="orderDetail_data_item_sub">{data?.order_id}</p>
              </div>
              <div className="orderDetail_data_item">
                <p className="orderDetail_data_item_head">Date:</p>
                <p className="orderDetail_data_item_head">
                  {moment(data?.order_created_at).format("MMM Do YYYY")}
                </p>
              </div>
              <div className="orderDetail_data_item">
                <p className="orderDetail_data_item_head">Time:</p>
                <p className="orderDetail_data_item_head">{created}</p>
              </div>
              {innerWidth > 1023 &&
                data?.cooking_instruction &&
                data?.cooking_instruction !== "" && (
                  <div className="orderDetail_data_item">
                    <h3 className="orderDetail_data_item_head">
                      Cooking Instruction:
                    </h3>
                    <p className="orderDetail_data_item_head1">
                      {data?.cooking_instruction}
                    </p>
                  </div>
                )}

              <div className="orderDetail_data_item">
                <p className="orderDetail_data_item_head">
                  {data?.delivery_type === "delivery"
                    ? "Delivery to :"
                    : "Picked to:"}{" "}
                </p>
                {locationDetails && locationDetails.orderFor === "takeaway" ? (
                  <p className="orderDetail_data_item_head1">
                    {outlet && outlet.display_name}
                  </p>
                ) : (
                  <div className="orderDetail_data_item_head1">
                    <p className="orderDetail_data_item_head1_type">
                      {data?.delivery_address?.AddressType
                        ? data?.delivery_address?.AddressType + ","
                        : ""}
                    </p>
                    <p>{data?.delivery_address.address1}</p>
                    <p>
                      {data?.delivery_address.address2
                        ? data?.delivery_address.address2
                        : ""}
                    </p>
                    {/* {`${ 
                data?.delivery_address?.AddressType ?
                data?.delivery_address?.AddressType + ',':''}
                ${data?.delivery_address.address1} 
               `} 
                {<br />}
                {
                  data?.delivery_address.address2  ? data?.delivery_address.address2  :""
                } */}
                  </div>
                )}
              </div>
              {data &&
                locationDetails &&
                data.pre_order_date &&
                data.pre_order_time && (
                  <div className="orderDetail_data_item">
                    <p className="orderDetail_data_item_head">
                      Delivery date and time:
                    </p>
                    <p className="orderDetail_data_item_head1">
                      {moment(data.pre_order_date).format("MMM Do YY")},{" "}
                      {handleConvertTime(data.pre_order_time)}
                    </p>
                  </div>
                )}
              <div className="orderDetail_data_item">
                <p className="orderDetail_data_item_head">Order type:</p>
                <p className="orderDetail_data_item_head">
                  {data?.delivery_type === "delivery" ? "Delivery" : "Takeaway"}
                </p>
              </div>

              {Number(parseFloat(data?.donation).toFixed(2)) !== 0 && (
                <div className="orderDetail_data_item">
                  <p className="orderDetail_data_item_head">Donation: </p>
                  <p className="orderDetail_data_item_head">
                    {"₹"}
                    {Number(parseFloat(data?.donation).toFixed(2))}/-
                  </p>
                </div>
              )}
              {/* {Number(parseFloat(data?.voucher_discount_amount).toFixed(2)) !==
                0 && (
                <div className="orderDetail_data_item">
                  <p className="orderDetail_data_item_head">
                    Voucher Discount:{" "}
                  </p>
                  <p className="orderDetail_data_item_head">
                    {"₹"}
                    {Number(
                      parseFloat(data?.voucher_discount_amount).toFixed(2)
                    )}
                    /-
                  </p>
                </div>
              )} */}

              {Number(parseFloat(data?.delivery_tips).toFixed(2)) !== 0 && (
                <div className="orderDetail_data_item">
                  <p className="orderDetail_data_item_head">Delivery Tip: </p>
                  <p className="orderDetail_data_item_head">
                    {"₹"}
                    {Number(parseFloat(data?.delivery_tips).toFixed(2))}/-
                  </p>
                </div>
              )}

              {innerWidth > 1023 && (
                <>
                  {Number(parseFloat(data?.discount).toFixed(2)) !== 0 && (
                    <div className="orderDetail_data_item">
                      <p className="orderDetail_data_item_head">
                        Discount:{offerCode && offerCode.coupon_code}{" "}
                      </p>
                      <p className="orderDetail_data_item_head">
                        {"₹"}
                        {Number(parseFloat(data?.discount).toFixed(2))}/-
                      </p>
                    </div>
                  )}

                  {Number(parseFloat(data?.delivery_charges).toFixed(2)) !==
                    0 && (
                      <div className="orderDetail_data_item">
                        <p className="orderDetail_data_item_head">
                          Delivery Charges:{" "}
                        </p>
                        <p className="orderDetail_data_item_head">
                          {"₹"}
                          {Number(parseFloat(data?.delivery_charges).toFixed(2))}
                          /-
                        </p>
                      </div>
                    )}
                  {surcharge && surcharge > 0 ?
                    <div className="orderDetail_data_item">
                      <p className="orderDetail_data_item_head">
                        Delivery Surcharge:{" "}
                      </p>
                      
                      <p className="orderDetail_data_item_head">
                        {"₹"}
                        { surcharge}
                        /-
                      </p>
                    </div> :""
                  }
                  {Number(parseFloat(data?.package_charges).toFixed(2)) !==
                    0 && (
                      <div className="orderDetail_data_item">
                        <p className="orderDetail_data_item_head">
                          Packing Charges:{" "}
                        </p>
                        <p className="orderDetail_data_item_head">
                          {"₹"}
                          {Number(parseFloat(data?.package_charges).toFixed(2))}/-
                        </p>
                      </div>
                    )}
                  <div className="orderDetail_data_item">
                    <p className="orderDetail_data_item_head">CGST:</p>
                    <p className="orderDetail_data_item_head">
                      {"₹"}
                      {Number(parseFloat(data?.cgst).toFixed(2))}/-
                    </p>
                  </div>
                  <div className="orderDetail_data_item">
                    <p className="orderDetail_data_item_head">SGST:</p>
                    <p className="orderDetail_data_item_head">
                      {"₹"}
                      {Number(parseFloat(data?.sgst).toFixed(2))}/-
                    </p>
                  </div>
                  <div className="orderDetail_data_item">
                    <p className="orderDetail_data_item_sub2">Total Amount:</p>
                    <p className="orderDetail_data_item_sub2">
                      {"₹"}
                      {Number(parseFloat(data?.paid_amount).toFixed(2))}/-
                    </p>
                  </div>
                  <div className="orderDetail_data_item">
                    <p className="orderDetail_data_item_sub2">Payment Mode:</p>
                    <p className="orderDetail_data_item_sub2">
                      {data?.mode_of_payment}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default OrderDetail;
