import React from "react";
import "./index.scss";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
import CountButton from "../count-button";
import durationOrder from "../../assets/images/durationorder.svg";
import Option from "../option";
import NewButton from "../../components/newCount-Button";
import { LocalStorage } from "../../utils/storage";
import OptionCustomize from "../option-customize";
import blueTickImg from "../../assets/images/ep--success-filled.svg";

import {
  handleParseAmount,
  isAddonsAvailble,
  handleGetTotalAmt,
} from "../../helpers/sharedFunction";
import { Analytics } from "../../helpers/analytics";

const ProductSummary = (props) => {
  const {
    data,
    onUpdateContent,
    addonPopup,
    isCustomize,
    isloading,
    setLoderById,
    homeData,
    isStrikeRequired
  } = props;
  console.log("prodductttt summmmmary", data);
  const opts = {
    first: "Customize",
    second: "option 2",
  };
  let cartLocal = LocalStorage.get("CartProducts");
  let isLoggedIn = LocalStorage.get("isLoggedIn");
  const subCount = (item, value) => {
    if (value === "add" || value === "nocontent") {
      const addonsAvailble = homeData && isAddonsAvailble(homeData, item);
      if (!addonsAvailble) {
        item.count = item.count + 1;
        setLoderById((prevState) => ({
          ...prevState,
          [item._id]: true,
        }));
        onUpdateContent(item, [], true);
        Analytics.addToCardEvent(item, true);
        return;
      }
      value === "add" && addonPopup(item);
      LocalStorage.set("addId", item._id);
    } else {
      if (item.count > 0) {
        item.count = item.count - 1;
        LocalStorage.set("addId", item._id);
        LocalStorage.set("addUp", false);
        setLoderById((prevState) => ({
          ...prevState,
          [item._id]: true,
        }));
        onUpdateContent(item, undefined, true);
        Analytics.removeFromCartEvent(item);
      } else {
        item.count = 0;
        onUpdateContent(item);
      }
    }
  };

  const locationDetails = LocalStorage.get("location");
  const { innerWidth } = window;
  const handleAddonCount = (item) => {
    const itemPrice = isLoggedIn
      ? handleParseAmount(item.single_item_price ? item.single_item_price : item.final_price)
      : handleParseAmount(item.final_price);
    const itemCount = handleParseAmount(item.item_count);
    return (
      <div className="productSummary__addons-wrapper">
        <p className="productSummary_extras">
          {item.item_name} {` [Rs ${itemPrice}]`}
        </p>
        <p className="productSummary_extras">[{itemCount}]</p>
      </div>
    );
  };
  const handleTotalAmount = (product) => {
    const amt = handleGetTotalAmt([product]);
    return amt;
  };

  return (
    <>
      {innerWidth <= 1023 ? (
        <div className="productSummary">
          <div className="productSummary_product product-header-wrapper">
            <div className="productSummary_product_card">
              <div className="productSummary_product_data">
                <div className="productSummary_product_data_headd">
                  {!isStrikeRequired ?
                  <div className="productSummary_product_wholeWrapper">
                    <p
                      className={
                        data?.is_veg
                          ? "productSummary_product_veg"
                          : "productSummary_product_nonveg"
                      }
                    ></p>
                    <p className="productSummary_product_data_title">
                      {data.item_name}
                    </p>
                  </div>
                  :
                  <div className="productSummary_product_wholeWrapper offer-text-item-title-wrapper title-wrapper-container">
                      <p
                        className={
                          data?.is_veg
                            ? "productSummary_product_veg"
                            : "productSummary_product_nonveg"
                        }
                      ></p>
                      <div className="title-wrapper">
                        <p className="productSummary_product_data_title">
                          {data.item_name}
                        </p>
                        <p className="offer-item-text">
                        {data.complimentary_item ? "" : 
                          <>
                            {data.discount !== 0 && <img src={blueTickImg} alt="blueTick" className="blue-tick-img-mob"/>}
                            {(Math.floor(data.item_final_price) - data.discount) === 0 ? "Free item" 
                            : data.discount !== 0 ? 
                            `Contains ${data.discount / data.item_unit_price} FREE item${data.discount / data.item_unit_price > 1 
                            ? "s" : ""}` : ""}
                          </>}
                      </p>
                    </div>
                  </div>
                  }

                  <div className="productSummary_opt">
                    {data.complimentary_item === false &&
                      isCustomize === true && (
                        <>
                          <NewButton
                            item={data}
                            content={data.count}
                            className="productSummary_product_data_button"
                            subCount={subCount}
                            customSubIconClass="productSubButton"
                            customAddIconClass="productAddButton"
                            isLoading={isloading}
                          />
                        </>
                      )}
                  </div>
                </div>

                <div className="productSummary_product_data_head">
                  <div className="productSummary_product_data_duration"></div>
                </div>
              </div>
            </div>
          </div>
          {!isStrikeRequired ? 
          <p
          className={
            data.complimentary_item === false || !data.complimentary_item
            ? "productSummary_product_priceSection_price_paid"
            : "productSummary_product_priceSection_price_free"
            }
          >
            {data.complimentary_item === false || !data.complimentary_item
              ? `₹${data && Math.floor(data.item_final_price)}/-`
              : "Free"}
          </p>
          :
          <p className={
              data.complimentary_item === false || !data.complimentary_item
                ? "productSummary_product_priceSection_price_paid"
                : "productSummary_product_priceSection_price_free"
            }>
                      {data.complimentary_item === false ||
                      !data.complimentary_item
                      ? 
                          (data.discount === 0 ? `₹${data && Math.floor(data.item_final_price)}/-` : 
                          <p className="price-wrapper-mob">
                            <span className={`${(Math.floor(data.item_final_price) - data.discount) === 0 && "free-offer-item-text-mob"}`}>
                              { (Math.floor(data.item_final_price) - data.discount) === 0 ? "FREE":`₹${Math.floor(data.item_final_price) - data.discount}/-`}
                            </span>
                            <span className="strike-out-price">
                              {`₹${data && Math.floor(data.item_final_price)}/-`}
                            </span>
                          </p>)
                        : "Free"}
          </p>
          }
          {data?.addons &&
            data?.addons.length > 0 &&
            data?.addons.map((item) => {
              return <div key={item.id}>{handleAddonCount(item)}</div>;
            })}
        </div>
      ) : (
        <>
          <div className="productSummary">
            <div className="productSummary_product">
              <div className="productSummary_product_card">
                <div className="productSummary_product_data">
                  <div className="productSummary_product_data_head">
                    <div className="productSummary_product_wholeWrapper">
                      <p
                        className={
                          data?.is_veg
                            ? "productSummary_product_veg"
                            : "productSummary_product_nonveg"
                        }
                      ></p>
                      <p className="productSummary_product_data_title">
                        {data.item_name}
                      </p>
                    </div>
                    {!isStrikeRequired ?
                    <p className="productSummary_product_priceSection_price">
                      {data.complimentary_item === false ||
                      !data.complimentary_item
                        ? `₹${data && Math.floor(data.item_final_price)}/-`
                        : "Free"}
                    </p> 
                    :
                    <p className="productSummary_product_priceSection_price">
                      {data.complimentary_item === false ||
                      !data.complimentary_item
                      ? 
                          (data.discount === 0 ? `₹${data && Math.floor(data.item_final_price)}/-` : 
                          <p className="price-wrapper">
                            <span className={`${(Math.floor(data.item_final_price) - data.discount) === 0 && "free-offer-item-text"}`}>
                              { (Math.floor(data.item_final_price) - data.discount) === 0 ? "FREE":`₹${Math.floor(data.item_final_price) - data.discount}/-`}
                            </span>
                            <span className="strike-out-price">
                              {`₹${data && Math.floor(data.item_final_price)}/-`}
                            </span>
                          </p>)
                        : "Free"}
                    </p>
                    }
                  </div>
                  <p className="productSummary_product_data_desc">
                    {data.item_short_description}
                  </p>
                  <div className={`productSummary_product_data_head ${isStrikeRequired && "flex-wrapper"}`}>
                    { !isStrikeRequired &&
                      <div className="productSummary_product_data_duration"></div>
                    }
                    { isStrikeRequired &&
                      <p className="offer-item-text">
                        {data.complimentary_item ? "" : 
                          <>
                            {data.discount !== 0 && <img src={blueTickImg} alt="blueTick" className="blue-tick-img"/>}
                            {(Math.floor(data.item_final_price) - data.discount) === 0 ? "Free item" 
                            : data.discount !== 0 ? 
                            `Contains ${data.discount / data.item_unit_price} FREE item${data.discount / data.item_unit_price > 1 
                            ? "s" : ""}` : ""}
                          </>}
                      </p>
                    }
                    {data.complimentary_item === false &&
                      isCustomize === true && (
                        <NewButton
                          item={data}
                          content={data.count}
                          className="productSummary_product_data_button"
                          subCount={subCount}
                          isLoading={isloading}
                        />
                      )}
                  </div>
                </div>
              </div>
              <div className="productSummary_product_priceSection"></div>
            </div>
            {data?.addons &&
              data?.addons.length > 0 &&
              data?.addons.map((item) => {
                return <div key={item.id}>{handleAddonCount(item)}</div>;
              })}
          </div>
        </>
      )}
    </>
  );
};

export default ProductSummary;