import React, { useState, useEffect } from "react";
import "./index.scss";
import locationImg from "../../assets/images/locationIcon2.svg";
import bikeImg from "../../assets/images/bike.svg";
import userImg from "../../assets/images/userIcon.svg";
import walletImg from "../../assets/images/wallet.png";
import timeImg from "../../assets/images/time.svg";
import locationIcon from "../../assets/images/locationIcon2.svg";
import notification from "../../assets/images/profileNotification.svg"
import wallet from "../../assets/images/wallet.png"
import takeAwayIcon from "../../assets/images/work.svg";
import DeliveryIcon from "../../assets/images/deliveryIcon.svg";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import Button from "../../components/button";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import Logo from "../../assets/images/logo.svg";
import Option from "../../components/option";
import moment from "moment";
import AlertLocationChange from "../../components/alert-location-change";
import {
  getDistanceFromLatLong,
  handleParseAmount,
} from "../../helpers/sharedFunction";
import { Analytics } from "../../helpers/analytics";
import { viewWalletBalance } from "../../actions/wallet";
import { walletgcStatusCheckApi } from "../../actions/giftcards";

const HeaderMobile = (props) => {
  const [changeLocationAlert, setChangeLocationAlert] = useState(false);

  const { customClass } = props;
  const history = useHistory();
  const location = useLocation();
  const { showButton } = props;
  const { innerWidth } = window;
  const staticData = LocalStorage.get("staticData");
  const profileImage = LocalStorage.get("profileImg");
  const [walletgcStatus, setWalletgcStatus] = useState();
  const [showWalletBalance, setShowWalletBalance] = useState(false);
  const [data, setData] = useState({});
  const [previousLocationData, setPreviousLocationData] = useState();
  const [walletStatus, setWalletStatus] = useState(null);
  const [walletBalance, setWalletBalance] = useState();

  useEffect(() => {
    if (LocalStorage.get("location")) {
      // debugger;
      const value = LocalStorage.get("location");
      console.log(value, "location");
      setData(value);
    }

    if (LocalStorage.get("Previouslocation")) {
      const previousValue = LocalStorage.get("Previouslocation");
      setPreviousLocationData(previousValue);
    }
  }, []);

  useEffect(() => {
    viewWalletBalance((res) => {
      if (res && res?.response?.status === 200) {
        setWalletStatus(res?.content?.status);
        setWalletBalance(res?.content?.balance);
      }
    });
    walletgcStatusCheckApi((res) => {
      if (res && res?.response?.status === 200) {
        if (res && res.content) {
          console.log("Setting walletgcStatus:", res.content);
          setWalletgcStatus(res.content);
          setShowWalletBalance(res.content.is_wallet_active);
        }
      }
    });
  }, []);

  useEffect(() => {
    let locationDetails = LocalStorage.get("location");
    const isShowLocationAlert = SessionStorage.get("locationAlert");
    if (navigator.geolocation && !isShowLocationAlert) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            if (
              locationDetails &&
              locationDetails.lat &&
              locationDetails.lng &&
              json
            ) {
              const disInKm = getDistanceFromLatLong(
                locationDetails.lat,
                locationDetails.lng,
                json.lat,
                json.lng
              );
              if (disInKm > 5) {
                setChangeLocationAlert(true);
                // handleAlertChangeLocation()
              }
            }
          }
        },
        (_error) => { }
      );
    }
  }, []);

  const onLoginClick = () => {
    history.push({
      pathname: ROUTES.LOGIN,
      state: { redirectToCheckOut: true },
    });
  };
  const onSigninClick = () => {
    history.push({
      pathname: ROUTES.SIGNIN,
      state: { redirectToCheckOut: true },
    });
  };

  const onHomeClick = () => {
    history.push(ROUTES.HOME);
  };

  // const onClickMenu = (value) => {
  //   history.push(value);
  // };

  const onProfileClick = () => {
    history.push(ROUTES.PROFILE);
  };
  const onNotificationClick = () => {
    history.push(ROUTES.NOTIFICATION);
  };


  const onChangeInput = (e) => {
    const { target } = e;
    const { value } = target;
    let locationDetails = LocalStorage.get("location");
    if (value !== locationDetails && locationDetails?.orderTime) {
      history.push(ROUTES.HOME);
    }
  };

  let locationDetails = LocalStorage.get("location");
  let previousLocationDetails = LocalStorage.get("Previouslocation");
  let outletDt = LocalStorage.get("Outlet_Details");

  const locationPage = () => {
    Analytics.locactionSelectionEvent();
    history.push(ROUTES.DELIVERY_LOCATION);
  };

  const opts = {
    first: "Now",
    second: "Later",
  };

  const getLocationImgType = () => {
    let imgType = locationIcon;
    if (locationDetails && locationDetails.orderFor === "takeaway") {
      imgType = takeAwayIcon;
    }
    return <img src={imgType} alt="locationIcon" width="16" height="20" />;
  };

  const getLocationType = () => {
    let locationType = "";
    if (locationDetails && locationDetails.orderFor === "takeaway") {
      locationType = "Takeaway. ";
    }
    return locationType;
  };

  const handleChangeLocation = () => {
    locationPage();
    handleAlertChangeLocation();
    SessionStorage.set("locationAlert", true);
  };

  const handleAlertChangeLocation = () => {
    setChangeLocationAlert((prevState) => !prevState);
    SessionStorage.set("locationAlert", true);
  };

  const handleConvertTime = (time) => {
    const splitTime = `${Number(parseFloat(time)) + 1}`;
    const formatedTime = moment(time, "HH:mm").format("hh A");
    const formatedTimeEnd = moment(splitTime, "HH").format("hh A");
    return `${formatedTime} - ${formatedTimeEnd}`;
  };

  const onWalletClick = () => {
    if (LocalStorage.get("isLoggedIn") === true) {
      history.push(ROUTES.WALLET);
    } else {
      onLoginClick();
    }
  };
  // console.log(data,"data address1")
  return (
    <div className={`headerMobile ${customClass}`}>
      <AlertLocationChange
        changeLocationAlert={changeLocationAlert}
        handleChangeLocation={handleChangeLocation}
        handleAlertChangeLocation={handleAlertChangeLocation}
      />
      <div className="headerMobile_wholeWrapper">
        {location.pathname === ROUTES.HOME ||
          location.pathname === ROUTES.MENU ||
          location.pathname === ROUTES.WALLET ||
          location.pathname === ROUTES.SEARCH ||
          location.pathname.startsWith('/category/') ||
          location.pathname.includes("menu") ||
          location.pathname === ROUTES.CURRENT_BLOGS ||
          location.pathname === ROUTES.CONTACTUS ||
          location.pathname === "/" + staticData?.url_text ||
          location.pathname === ROUTES.BLOGS ? (
          <div className="headerMobile_content" onClick={() => locationPage()}>
            <div className="category-header_category-location_icon">
              {getLocationImgType()}
            </div>
            <div className="category-header_wholeWrapper">
              <div className="category-header_category-location_location">
                <div className="category-header_category-location_location2">
                  <p className="one">
                    {previousLocationData &&
                      previousLocationData.address !== "" &&
                      `${previousLocationData?.addressType + `,`}`}
                  </p>
                  <p className="two">
                    {data.address !== "" ||
                      (previousLocationData &&
                        previousLocationData.address !== "")
                      ? `${getLocationType()}${(locationDetails || previousLocationDetails) &&
                        (locationDetails || previousLocationDetails)
                          ?.orderFor &&
                        (locationDetails || previousLocationDetails)
                          .orderFor === "takeaway"
                        ? outletDt.display_name
                        : previousLocationData
                          ? previousLocationData.address
                          : `${data.addressType !== undefined ? data.addressType + "," : ""} 
                          ${data.addressType !== undefined ? data.address1 : "Select your Location"}`
                      }`
                      : "Select your Location"}
                  </p>
                </div>
              </div>
              {/* <div className="headerMobile_content_deliveryDetails">
                <div className="headerMobile_content_deliveryDetails_wholeWrapper">
                  <img src={timeImg} className="option_i" alt="timeImg"/>
                  <Option
                    selectedOption={
                      (locationDetails || previousLocationDetails) &&
                      (locationDetails || previousLocationDetails).orderTime
                    }
                    onChangeInput={onChangeInput}
                  />
                </div>
                <div className="headerMobile_content_deliveryDetails_limit">
                  {(locationDetails || previousLocationDetails) &&
                    (locationDetails || previousLocationDetails).orderFor ===
                    "delivery" &&
                    (locationDetails || previousLocationDetails).orderTime !==
                    "orderLater" &&
                    (data.address ||
                      (previousLocationData &&
                        previousLocationData.address)) !== "" && (
                      <div className="category-header_category-location_deliveryTime">
                        <div className="category-header_category-location_deliveryTime_deliveryImg">
                          <img
                            src={DeliveryIcon}
                            className="category-header_category-location_deliveryTime_img"
                            alt="deliveryIcon"
                          />
                        </div>
                        <h2 className="category-header_category-location_timeDelivery">
                          50-60 min delivery
                        </h2>
                      </div>
                    )}{" "}
                  <div className="category-header_category-location_deliveryTime">
                    {(locationDetails || previousLocationDetails)?.orderTime &&
                      (locationDetails || previousLocationDetails)
                        ?.orderTime !== "orderNow" && (
                        <p className="category-header_category-location_timeDelivery2">
                          {moment(
                            (
                              locationDetails ||
                              (previousLocationData && previousLocationDetails)
                            ).date
                          ).format("MMMM Do, ") +
                            handleConvertTime(
                              (
                                locationDetails ||
                                (previousLocationData &&
                                  previousLocationDetails)
                              ).time
                            )}
                        </p>
                      )}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {LocalStorage.get("isLoggedIn") === true ? (
          <>
            <div
              className={`category-header_wallet-div ${
                location.pathname === ROUTES.WALLET ? "active" : ""
              }`}
              onClick={() => onWalletClick()}
            >
              {showWalletBalance  && (
                <>
                  <div className="category-header_walletBalance-mobile">
                    ₹ {walletBalance === 0 ? "0" : walletBalance}
                  </div>
                  <img
                    src={walletImg}
                    alt="walletIcon"
                    className="category-header_walletImg"
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <div
            className={`category-header_wallet-div ${
              location.pathname === ROUTES.WALLET ? "active" : ""
            }`}
            onClick={() => onWalletClick("wallet")}
          >
            {showWalletBalance && (
              <>
                <img
                  src={walletImg}
                  alt="walletIcon"
                  className="category-header_walletImg"
                />
              </>
            )}
          </div>
        )}

        {location.pathname === ROUTES.LOGIN ||
          location.pathname === ROUTES.SIGNIN ? (
          ""
        ) : LocalStorage.get("isLoggedIn") === true ? (
          <div className="headerMobile_end">
            {/* {!(location.pathname === ROUTES.SEARCH) && (
              <img
                src={searchImg}
                className="headerMobile_end_search"
                onClick={() => onClickMenu("/search")}
              />
            )} */}
            <img
              src={profileImage && profileImage !== "" ? profileImage : userImg}
              className="headerMobile_end_userIcon"
              onClick={() => onProfileClick()}
              alt=""
            />
          </div>
        ) : (
            <>
              {/* <Button
                type="button"
                className="headerMobile_section_login"
                content="Login"
                onClick={() => onLoginClick()}
              />
              <Button
                type="button"
                className="headerMobile_section_signup"
                content="Signup"
                onClick={() => onSigninClick()}
              /> */}
              {/* <img
                src={searchImg}
                className="headerMobile_end_search"
                onClick={() => onClickMenu("/search")}
              /> */}
              <img
                src={userImg}
                className="headerMobile_end_guestIcon"
                onClick={() => onProfileClick()}
                alt="userImg"
              />
            </>
        )}
      </div>
    </div>
  );
};
export default HeaderMobile;
