import React, { useRef, useState } from "react";
import "./index.scss";
import BBK from "../../assets/images/detail.png";
import Button from "../button";
import moment from "moment";
import Preparing from "../../assets/images/delivery.svg";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import { LocalStorage } from "../../utils/storage";
import { handleGetDeliveryTypeImg, handleGetDeliveryTypeText } from "../../helpers/sharedFunction";
import { getInvoice } from "../../actions/orders";
import { event } from "jquery";
import jsPDF from 'jspdf';
import bbk from '../../assets/images/bbkmain_logo.png';
import signature from '../../assets/images/koushikRoy.jpeg'
import html2canvas from "html2canvas";

const OrderCard = (props) => {
  const { ongoing, data } = props;
  const location = useLocation();
  const history = useHistory();
  const { innerWidth } = window;
  let orderCard;
  const [showpdf, setShowPdf] = useState(false);
  const [pdfContent, setPdfContent] = useState('');
  const pdfRef = useRef();

  let orderCreated = data?.order_created_at;
  // let sTime = new Date(orderCreated);
  var currentTimeUTC = new Date(orderCreated);
  var currentTimeIST = moment(currentTimeUTC)
    .add(5, "hours")
    .add(30, "minutes");
  let createTime = moment(currentTimeIST._d.toString()).format(
    "D, MMMM YY, hh:mm a"
  );

  const dataShow = () => {
    LocalStorage.set("pastOrder", data);
    history.push(ROUTES.PASTORDERS);

  };

  const routeToTrack = () => {
    //  
    localStorage.setItem("purchaseDetailId", data?._id);
    history.push({
      pathname: ROUTES.TRACK_ORDER,
      state: true
    })
  };

 

  return (
    <>
      {ongoing ? (
        <div className="order-card">
          <div className="order-card_ongoingimg">
            <img
              src={handleGetDeliveryTypeImg(data.status)}
              width="100"
              height="100"
              alt="status"
              className="order-card_ongoingimg-data"
            />
          </div>
          <div className="order-card_ongoingproduct">
            <div className="order-card_product_detail">
              <p className="order-card_orderId">{`Order ID: ${data.divum_order_id}`}
                <span className='order-card_orderId_OrderType'>{data.is_preorder ? `${" | "} Pre Order` : ""}</span>
              </p>
              <p className="order-card_price">
                ₹{Number(parseFloat(data.paid_amount).toFixed(2))}
              </p>
            </div>
            <div className="order-card_orderData">
              <div className="order-card_product_details">
                <h3 className="order-card_product_name">
                  {data &&
                    data.cart_items &&
                    data.cart_items?.length > 0 &&
                    data.cart_items?.length <= 2 ? (
                    data.cart_items.map((item) => {
                      return (
                        <span className="order-card_product_name_nameLabel">
                          {item.name + ", "}
                        </span>
                      );
                    })
                  ) : (
                    <span className="order-card_product_name_nameLabel">
                      {data &&
                        data?.cart_items?.length > 0 &&
                        data?.cart_items[0].name +
                        ", " +
                        data?.cart_items[1]?.name +
                        ", " +
                        data?.cart_items[2]?.name +
                        "..."}
                    </span>
                  )}
                </h3>
                {innerWidth <= 1023 ? (
                  <div className="order-card_product_detailss">
                    <p className="order-card_orderStatus">{handleGetDeliveryTypeText(data.status)}</p>
                    <p className="order-card_orderIdd">{createTime}</p>
                  </div>
                ) : (
                  <div className="order-card_product_details">
                    <p className="order-card_orderStatus">{handleGetDeliveryTypeText(data.status)}</p>
                    <p className="order-card_orderId">
                      {/* {moment(data?.order_created_at).format(
                        "D, MMMM YY HH:mm:ss"
                      )} */}
                      {createTime}
                    </p>
                  </div>
                )}
              </div>
              <div className="order-card_ongoingbuttons">
                <Button
                  content="View Order"
                  onClick={() => routeToTrack()}
                  className="order-card_ongoingbuttons_btn"
                  customClass="customClass"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {innerWidth <= 1023 ? (
            <div
              className="wholeWrapper_order"
              onClick={() => dataShow()}
            >
              <div className="order-card card2">
                <div className="order-card_img">
                  {/* <img
                    src={BBK}
                    width="100"
                    height="100"
                    className="order-card_img-data"
                  /> */}
                  <p
                    className={`order-card_img_status ${data.status === "order_cancelled" ? "cancel" : ""
                      }`}
                  >
                    {data.status === "order_cancelled"
                      ? "Cancelled"
                      : "Delivered"}
                  </p>
                </div>
                <div className="order-card_product">
                  <div className="order-card_product_detail card2_product_detail">
                    <p className="order-card_orderId card2-card_orderId">{`Order ID: ${data.divum_order_id}`}
                      <span className='order-card_orderId_OrderType'>{data.is_preorder ? `${" | "} Pre Order` : ""}</span>
                    </p>
                  </div>
                  <div className="order-card_orderData card2-card_orderData">
                    <div className="order-card_product_details card2_product_details">
                      <h3 className="order-card_product_name card2_product_name">
                        {data &&
                          data.cart_items?.length > 0 &&
                          data.cart_items?.map((item) => {
                            return (
                              <span className="feedbackCard_division_nameLabel card2_nameLabel">
                                {item.name}
                              </span>
                            );
                          })}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wholeWrapper_order_subSection">
                <p className="order-card_orderId card2-card_orderId">
                  {/* {moment(data?.order_created_at).format("D, MMMM YY HH:mm:ss")} */}
                  {createTime}
                </p>
                <p className="order-card_price card2-card_price">
                  ₹{Number(parseFloat(data.paid_amount).toFixed(2))}
                </p>
              </div>
              {data?.status !== "order_cancelled" ? (
                <div className="order-card_buttons">
                  <Button
                    content="Rate Now"
                    className="order-card_buttonRate"
                    customClass="customClass"
                  />
                  <a
                    className="order-card_buttonInvoice"
                    customClass="customClass"
                    onClick={(e)=>{e.stopPropagation();LocalStorage.set("Invoice_Order_Id",data.divum_order_id)}}
                    href={ROUTES.INVOICE}
                    target="_blank"
                  >View Invoice</a>
                  <Button
                    content="Re-order"
                    className="order-card_buttonReorder"
                    customClass="customClass"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="order-card card2" onClick={() => dataShow()} id="cardItemInvoice">
              <div className="order-card_img">
                {/* <img
                  src={BBK}
                  width="100"
                  height="100"
                  className="order-card_img-data"
                /> */}
                <p
                  className={`order-card_img_status ${data.status === "order_cancelled" ? "cancel" : ""
                    }`}
                >
                  {data.status === "order_cancelled"
                    ? "Cancelled"
                    : "Delivered"}
                </p>
              </div>
              <div className="order-card_product">
                <div className="order-card_product_detail card2_product_detail">
                  <p className="order-card_orderId card2-card_orderId">{`Order ID: ${data.divum_order_id}`}
                    <span className='order-card_orderId_OrderType'>{data.is_preorder ? `${" | "} Pre Order` : ""}</span>
                  </p>
                  <p className="order-card_price card2-card_price">
                    ₹{Number(parseFloat(data.paid_amount).toFixed(2))}
                  </p>
                </div>
                <div className="order-card_orderData card2-card_orderData">
                  <div className="order-card_product_details card2_product_details">
                    <h3 className="order-card_product_name card2_product_name">
                      {data &&
                        data.cart_items &&
                        data.cart_items?.length > 0 &&
                        data.cart_items.map((item, index) => {
                          return (
                            <span className="feedbackCard_division_nameLabel card2_nameLabel">
                              {item.name} {data.cart_items?.length >= 2 && data.cart_items?.length > index + 1 && `, `}

                            </span>
                          );
                        })}
                    </h3>
                    <p className="order-card_orderId card2-card_orderId">
                      {/* {moment(data?.order_created_at).format(
                        "D, MMMM YY HH:mm:ss"
                      )} */}
                      {createTime}
                    </p>
                  </div>
                  {data?.status !== "order_cancelled" ? (
                    <div className="order-card_buttons">
                      <Button
                        content="Rate Now"
                        className="order-card_buttonRate"
                      />
                      <a
                        className="order-card_buttonInvoice"
                        onClick={(e)=>{e.stopPropagation();LocalStorage.set("Invoice_Order_Id",data.divum_order_id)}}
                        id="invoiceButton1"
                        href={ROUTES.INVOICE}
                        target="_blank"
                      >View Invoice</a>
                      <Button
                        content="Re-order"
                        className="order-card_buttonReorder"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
          {/* {
            showpdf && <div className="pdf_view">
              <img className="cross_icon_img" src={crossIcon} alt="X" onClick={() => setShowPdf(false)} />
              <div className="pdf_view_content" dangerouslySetInnerHTML={{ __html: pdfContent }} ref={pdfRef}>
              </div>
              <button onClick={savePDF}>Download Invoice</button>

            </div>
          } */}
        </>
        // <div className="order-card card2" onClick={() => dataShow()}>
        //   <div className="order-card_img">
        //     <img
        //       src={BBK}
        //       width="100"
        //       height="100"
        //       className="order-card_img-data"
        //     />
        //     <p
        //       className={`order-card_img_status ${
        //         data.status === "order_cancelled" ? "cancel" : ""
        //       }`}
        //     >
        //       {data.status === "order_cancelled" ? "Cancelled" : "Delivered"}
        //     </p>
        //   </div>
        //   <div className="order-card_product">
        //     {/* need to chenge */}
        //     <div className="order-card_product_detail card2_product_detail">
        //       <p className="order-card_orderId card2-card_orderId">{`Order ID: ${data.divum_order_id}`}</p>
        //       <p className="order-card_price card2-card_price">
        //         ₹{Number(parseFloat(data.paid_amount).toFixed(2))}
        //       </p>
        //     </div>
        //     {/* dchidc */}
        //     <div className="order-card_orderData card2-card_orderData">
        //       <div className="order-card_product_details card2_product_details">
        //         <h3 className="order-card_product_name card2_product_name">
        //           {data?.cart_items.map((item) => {
        //             return (
        //               <span className="feedbackCard_division_nameLabel card2_nameLabel">
        //                 {item.name}
        //               </span>
        //             );
        //           })}
        //         </h3>
        //         <p className="order-card_orderId card2-card_orderId">
        //           {/* hkuhkukhukhu */}
        //           {moment(data?.order_created_at).format("D, MMMM YY HH:mm:ss")}
        //         </p>
        //       </div>
        //       {data?.status !== "order_cancelled" ? (
        //         <div className="order-card_buttons">
        //           <Button
        //             content="Rate Now"
        //             className="order-card_buttonRate"
        //           />
        //           <Button
        //             content="Re-order"
        //             className="order-card_buttonReorder"
        //           />
        //         </div>
        //       ) : (
        //         ""
        //       )}
        //     </div>
        //   </div>
        // </div>
      )}
    </>
  );
};

export default OrderCard;
