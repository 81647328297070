import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Toaster = (props) => {
  const { toastType, toastText, closeToast, className, timeout = 3000 } = props;
  setTimeout(() => {
    closeToast();
  }, timeout);

  return (
    <>
      <span
        className={`${"toaster"}
      ${toastType && "toaster__success"}
      ${!toastType && "toaster__error"} ${className}`}
      >
        {toastText}
      </span>
    </>
  );
};

Toaster.propTypes = {
  toastType: PropTypes.string,
  toastText: PropTypes.string.isRequired,
  closeToast: PropTypes.string,
  className: PropTypes.string,
};

Toaster.defaultProps = {
  toastType: "",
  closeToast: "",
  className: "",
};
export default Toaster;
