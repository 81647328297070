import "./index.scss";
import React, { useState, Component } from "react";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "../../utils/storage";
import { getBlogDetail } from '../../actions/home';
import PropTypes from "prop-types";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
import ORDERRATE from "../../assets/images/order-rate.svg";
import blogs from "../../assets/images/blogs_newIcon.png";
import Button from "../../components/button";
import moment from "moment";

const BlogCard = (props) => {
  const { data, id } = props;
  const [blogData, setBlogData] = useState();
  const history = useHistory();
  const handleBlogClick = () => {
    let queryParams = `?display_name=${data?.display_name}`;
    getBlogDetail((res) => {
      if (res && res?.response?.status === 200) {
        if (res?.content) {
          setBlogData(res?.content?.blog_detail);
          LocalStorage.set("Blogs", res?.content?.blog_detail);
        }
        history.push(`/blog/${data?.display_name}`);
      }
    }, queryParams);
  };

  return (
    <div className="blogItem" key={data?._id} onClick={() => handleBlogClick()}>
      <div className="blogItem_imgContainer">
        <img
          src={data?.thumbnail_image ? data?.thumbnail_image : blogs}
          className="blogItem_imgContainer_img"
          alt="logo"
        />
      </div>
      <div className="blogItem_descriptionContainer">
        <p className="blogItem_descriptionContainer_date">
          Date:{" "}
          {moment(data?.from_date).format("D, MMMM 'YY") +
            " - " +
            moment(data?.to_date).format("D, MMMM 'YY")}
        </p>
        <p className="blogItem_data">{data?.title}</p>
      </div>
    </div>
  );
};

export default BlogCard;
