import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants/app-constants';

import Button from '../button';
import './index.scss';

const PageNotFound = () => {
  const history = useHistory();

  const btnClick = () => {
    history.push(ROUTES.HOME);
  
  };

  return (
    <div className='pnf'>
      <div className='pnf__content'>
        <h1 className='pnf__content--title'>Page Not Found</h1>
        <h2 className='pnf__content--desc'>
          The requested URL not found.
        </h2>
        <Button onClick={btnClick} content={"Home"} size="is-small" />

      </div>
    </div>
  );
};

export default PageNotFound;
