import React, { useEffect, useState } from "react";
import Button from "../button";
import RadioButton from "../radioButton";
import remove from "../../assets/images/close.svg";
// import { displayRazorpay } from '../../utils/payment';
import "./index.scss";
import { LocalStorage } from "../../utils/storage";
import offerIcon from "../../assets/images/bxs--offer.svg"
// const Payment = [
//   {
//     name: "Pay On Delivery",
//     checked: false,
//     id: "cod",
//   },
//   {
//     name: "Pay On Online",
//     checked: false,
//     id: "online_payment",
//   },
// ];

// if (LocalStorage.get('Outlet_Details').delivery_area_order_threshold_amount )

const PaymentMethod = ({
  onPaymentClick,
  noCheckout,
  customClassName,
  handleGoBack,
  subtotal,
  voucher,
  customClass,
  paymentMethods,
  walletBalance,
  Wamount,
  campaignDetail
}) => {
  // if (LocalStorage.get('Outlet_Details').delivery_area_order_threshold_amount > subtotal) {
  //   Payment[0].remove()
  // }

  // const [data, setData] = useState(Payment);
  const [responsiveData, setResponsiveData] = useState([]);

  console.log(walletBalance, "0okm");
  useEffect(() => {
    setResponsiveData(paymentMethods);
  }, [paymentMethods]);

  useEffect(() => {
    if (noCheckout) {
      const data = [
        {
          name: "Pay Online",
          checked: true,
          id: "online_payment",
          header: "Pay On Online",
        },
      ];
      // setData(data);

      const responsiveData = [
        {
          name: "Cash",
          checked: false,
          id: "cod",
          header: "Pay On Delivery",
        },
      ];
      setResponsiveData(data);
    }
  }, []);

  // const onChangeItem = (itm) => {
  //   const updateData = data.map((item) => {
  //     //
  //     item.checked = false;
  //     if (item.name === itm.name) {
  //       item.checked = true;
  //     }
  //     return item;
  //   });
  //   setData(updateData);
  //   onPaymentClick(itm.id);
  // };

  const responsiveOnChangeItem = (itm) => {
    const updateData = responsiveData.map((item) => {
      //
      item.checked = false;
      if (item.name === itm.name) {
        item.checked = true;
        console.log(item.item_final_price, "item.name")
      }
      return item;
    });
    setResponsiveData(updateData);
    onPaymentClick(itm.id);
  };

  const { innerWidth } = window;

  return (
    <>
      {innerWidth <= 1023 ? (
        <div
          className={`${
            noCheckout ? "paymentMethod_paymode" : "paymentMethod"
          } ${customClassName}`}
        >
          <div>
            {voucher && 
            <>
              <div className={`paymentMethod_Div ${
                (campaignDetail && campaignDetail?.is_campaign_active) 
                ?
                 "payment-method-title-mob" 
                : ""}`}>
                <p className="paymentMethod_Div_phrase">Payment Methods</p>
                <img
                  src={remove}
                  className="paymentMethod_Div_backImg"
                  onClick={handleGoBack}
                  alt=""
                  />
              </div>
                {(campaignDetail && campaignDetail?.is_campaign_active) && 
                <div className="wallet-offer-wrapper">
                  <img src={offerIcon} alt="offerIcon" className="offer-icon"/>
                  <p className="wallet-campaign-offer-text">
                      {`${campaignDetail?.campaign_details?.offer_description}`}
                  </p>
                </div>
                }
            </>
            }
            <RadioButton
              data={responsiveData}
              isShowHeader={true}
              onChange={responsiveOnChangeItem}
              className={`paymentMethod_button ${customClass}`}
              customClass="paymentMethod_unchecked"
              customClassName="paymentMethod_customClass"
              buttondata="paymentMethod_buttonData"
              walletBalance={walletBalance}
              Wamount={Wamount()}
            />
            <>
            </>
          </div>
        </div>
      ) : (
        <div
          className={`${
            noCheckout ? "paymentMethod_paymode" : "paymentMethod"
          } ${customClassName}`}
        >
          {noCheckout ? (
            ""
          ) : (
            <p className={`paymentMethod_nameLabel ${
              (campaignDetail && campaignDetail?.is_campaign_active) 
              ?
               "payment-method-title" 
              : ""}`}>
              {noCheckout ? "Payment Method" : "Select Payment Method"}
            </p>
          )}
          {(campaignDetail && campaignDetail?.is_campaign_active) && 
            <div className="wallet-offer-wrapper">
              <img src={offerIcon} alt="offerIcon" className="offer-icon"/>
              <p className="wallet-campaign-offer-text">
                  {`${campaignDetail?.campaign_details?.offer_description}`}
              </p>
            </div>
          }
          <RadioButton
            data={responsiveData}
            onChange={responsiveOnChangeItem}
            isShowHeader={true}
            className="paymentMethod_button"
            customClass="paymentMethod_unchecked"
            customClassChecked="paymentMethod_checked"
            buttondata="paymentMethod_buttonData"
            walletBalance={walletBalance} 
            Wamount={Wamount()}
          />
        </div>
      )}
      {/* <div className='paymentMethod_detail'>
        <p
          className='paymentMethod_detail_headLabel'
          onClick={() => onPaymentClick('cod')}
        >
          COD
        </p>
      </div>
      <div className='paymentMethod_detail'>
        <p
          className='paymentMethod_detail_headLabel'
          onClick={() => onPaymentClick('online_payment')}
        >
          Online Payment
        </p>

  </div> */}
    </>
  );
};

export default PaymentMethod;
