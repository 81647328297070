import React from "react";
import PropTypes from "prop-types";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import { Link } from "react-router-dom";
import "./index.scss";
import { LocalStorage } from "../../utils/storage";

const NewButton = (props) => {
  const {
    className,
    type,
    outlined,
    size,
    theme,
    disabled,
    addCount,
    subCount,
    cartShow,
    id,
    head,
    item,
    customSubIconClass,
    customAddIconClass,
    customClass,
    isLoading,
    change,
    addNewProduct,
    
  } = props;
  let { content } = props;

  const { innerWidth } = window;
  const history = useHistory();

  // const handleButtonClick = () => {
  //   if (content === 0) {
  //     // Navigate to "/addtocart" and pass data as state
  //     history.push(ROUTES.ADDTOCART, { data: item });
  //   }
  // };
  return (
    <button
      type="button"
      disabled={disabled}
      id={id}
      className={`button newButton ${theme} ${type} ${
        outlined && "outlined"
      } ${size} ${className} `}
      onClick={content === 0 ? () => addNewProduct(item) : () => null}
      // onClick={content === 0 ? (isAddtoCart ? handleButtonClick : () => addNewProduct(item)) : null}
      >
        {head != "nocontent" || content > 0 ? (
          <>

            {isLoading ? (
              <Loader />
            ) : (
              <>
                <p
                  onClick={() => subCount(item, "remove")}
                  className={`button_subIcon ${customSubIconClass}`}
                >
                  -
                </p>
                <span className={`button__content ${customClass}`}>
                  {content}
                  {/* {item.count} */}
                </span>
                <p
                  onClick={() => subCount(item, "add")}
                  className={`button_addIcon ${customAddIconClass}`}
                >
                  +
                </p>
              </>
            )}
          </>
        ) : (
          <p
            className={`button__content ${customClass}`}
            onClick={() => subCount(item, "nocontent")}
          >
            {head === "nocontent" && "Add +"}
          </p>
        )}
      </button>
  );
};

NewButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  outlined: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
};

NewButton.defaultProps = {
  className: "",
  content: null,
  outlined: false,
  size: "",
  type: "",
  theme: "",
};
export default NewButton;
