import { fetchCall } from "../../utils/ajax";
import { API_CONSTANTS, API_METHODS } from "../../constants/api-constants";

export const getGiftCards = (callback) => {
  fetchCall(API_CONSTANTS.GIFT_VOUCHERS, API_METHODS.GET, "", callback);
};

// export const applyGiftCard = (payload, callback) => {
//   fetchCall(API_CONSTANTS.GIFT_VOUCHERS, API_METHODS.POST, payload, callback);
// };

export const myVouchers = (callback) => {
  fetchCall(API_CONSTANTS.MY_VOUCHERS, API_METHODS.GET, "", callback);
};

export const getTermsandConditions = (url, callback) => {
  fetchCall(
    `${API_CONSTANTS.GET_GIFTCARD_TC + url}`,
    API_METHODS.GET,
    "",
    callback
  );
};

export const getGiftOffers = (url, callback) => {
  fetchCall(
    `${API_CONSTANTS.GET_GIFTCARD_OFFERS + url}`,
    API_METHODS.GET,
    "",
    callback
  );
};

export const applyGiftCard = (payload, callback) => {
  fetchCall(API_CONSTANTS.APPLY_GIFTCARD, API_METHODS.POST, payload, callback);
};

export const issuesGiftCardApi = (payload, callback) => {
  fetchCall(API_CONSTANTS.ISSUE_GIFTCARD, API_METHODS.POST, payload, callback);
};

export const claimGiftCardApi = (payload, callback) => {
  fetchCall(API_CONSTANTS.CLAIM_GIFTCARD, API_METHODS.POST, payload, callback);
};

export const walletgcStatusCheckApi = (callback) => {
  fetchCall(API_CONSTANTS.GET_WALLETGC_STATUS, API_METHODS.GET, "", callback);
};
