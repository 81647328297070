import React, { useState, useEffect } from "react";
import "./index.scss";
import Profile from "../../assets/images/userIcon.svg";
import Mobile from "../../assets/images/group_28.svg";
import Edit from "../../assets/images/edit.svg";
import Birthday from "../../assets/images/profileBirthday.svg";
import Mail from "../../assets/images/profileMail.svg";
import {
  updateImg,
  verifyProfileOtp,
  getProfile,
  sendProfileOtp,
  getEmailVerification,
  getOTP
} from "../../actions/profile";
import { EmailValidation } from "../../utils/validation";
import Button from "../button";
import { LocalStorage } from "../../utils/storage";
import EditProfile from "../edit-profile-popup";
import EditProfileOtp from "../edit-otp-profile";
import AlertPopup from "../../components/alert-popup";
import { regex } from "../../constants/regex";
import verifiedIcon from '../../assets/images/verified 3.png';

import moment from "moment";
import OTPVerify from "../email-verification-otp";

const ProfileCard = (props) => {
  const { CartShow, data, onOtpSubmission } = props;
  const userData = LocalStorage.get("UserData");
  const emailId = LocalStorage.get("Email Id");
  // let isDOBchange = LocalStorage.get("isDOB");
  const [modalShow, setModalShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [imgProfile, setImgProfile] = useState("");
  const [displayMobileNumber, setDisplayMobileNumber] = useState("");
  const [disableStat, setDisableStat] = useState(true);
  const [navStat, setNavStat] = useState("1");
  const [otp, setOtp] = useState("");
  const [profileInfo, setProfileInfo] = useState({});
  const [isDobEditable, setIsDobEditable] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [verifyModal, setVerifyModal] = useState(false);
  const { innerWidth } = window;

  console.log(data, userData, "sduwdu");

  // const cartshow = (data) => {
  //   console.log("in heree")
  //   setModalShow(!modalShow);
  //   setName(data?.name ? data?.name : "");
  //   setEmail(data?.email ? data?.email : "");
  //   // setDate(data?.dob && data?.dob);
  // };
  const setAction = {
    otp, setOtp, verifyModal, setVerifyModal
  }

  useEffect(() => {
    setIsDobEditable(props && props.data && props.data.is_dob_eitable)

    if (verifyModal) {
      document.getElementById('root').style.overflow = "hidden"
    }
    else {
      document.getElementById('root').style.overflow = "auto"
    }
  }, [props, verifyModal])
  const imgUpload = (e) => {
    const formdata = new FormData();

    const allowdImgFormates = ['image/png', 'image/jpg', 'image/jpeg'];
    const csvdata = e.target.files[0];

    if (!allowdImgFormates.includes(csvdata.type)) {
      setAlertPopup('Please select png, jpg, jpeg formats')
      return
    }

    formdata.append("file", csvdata);
    updateImg(
      formdata,
      (res) => {
        if (res && res?.response?.status === 200) {
          setImgProfile(res?.content[0]);
        } else {
          setImgProfile(res?.content[0]);
        }
      },
      ""
    );
  };
  const clickVerifyEmail = () => {
    getOTP((res) => {
    })
    LocalStorage.set("otpErr", "")
    if (!verifyModal)
      setVerifyModal(true);
  }
  const submitOTP = () => {
    let payload = {
      otp: otp
    }
    getEmailVerification(payload, (res) => {
      if (res.response.status === 400) {
        LocalStorage.set("otpErr", "Wrong OTP")
        setOtp("");
      }
      else {
        LocalStorage.set("otpErr", "")
        setVerifyModal(false);
        setOtp("");
      }
    })
  }

  const otpCheck = () => {
    const payload = {};
    let pay1 = {};
    let displayM = userData.mobile.split("");
    let displayMobile = [];
    let mobileDisplay = "";

    const nameValidation = regex.nameRegex.test(name.trim());

    if (!nameValidation) {
      setDisableStat("Only alphabets are allowed of maximum length 50!");
      return
    }


    for (let i = 0; i < displayM.length; i++) {
      if (i == 0 || i == 1 || i == 2 || i == 8 || i == 9) {
        displayMobile.push(displayM[i]);
      } else {
        displayMobile.push("*");
      }
    }
    mobileDisplay = displayMobile.join("");
    setDisplayMobileNumber(mobileDisplay);
    if (
      name === "" ||
      email === "" ||
      EmailValidation(email) === false
      //  ||
      // date === ""
    ) {
      setDisableStat("Enter Valid details");
    } else {
      if (
        userData.email === email &&
        (userData.name !== name ||
          // userData.dob !== date ||
          userData.profileInfo !== imgProfile)
      ) {
        pay1 = {
          fname: name,
          email: "",
          dob: "",
          profile_picture: imgProfile,
          mobile: mobileDisplay,
        };
        verifyProfileOtp(pay1, (res) => {
          if (res?.response?.status === 200) {
            //  
            setProfileInfo(res?.content?.data);
            LocalStorage.set("profileImg", res?.content?.data?.profile_picture);
            let newData = {
              profile_picture: imgProfile,
              dob: res?.content?.data?.dob,
              email: res?.content?.data?.email,
              mobile: res?.content?.data?.mobile,
              name: res?.content?.data?.first_name,
              profileInfo: res?.content?.data?.profile_picture,
              emailVerify: res?.content?.is_user_email_verified
            };
            setIsDobEditable(res?.content?.data?.is_dob_eitable)
            LocalStorage.set("UserData", newData);
            setName(res?.content?.data?.first_name);
            setModalShow(false);
            setNavStat("1");
          } else {
            setDisableStat(res?.response?.message);
          }
        });
      } else if (
        userData.email !== email ||
        (userData.email !== email &&
          (userData.name !== name ||
            userData.profileInfo !== imgProfile ||
            userData.dob !== date))
      ) {
        pay1 = {
          fname: name,
          email: email,
          profile_picture: imgProfile,
          mobile: mobileDisplay,
        };
        setDisableStat("");
        sendProfileOtp(pay1, (res) => {
          if (res.response.status === 200) {
            setNavStat("2");
          } else {
            setDisableStat(res.response.message);
          }
        });
      } else {
        setDisableStat("");
        sendProfileOtp(payload, (res) => {
          if (res.response.status === 200) {
            setNavStat("2");
          } else {
            setDisableStat(res.response.message);
          }
        });
      }
    }
  };

  const verifyOtp = () => {
    let payload = {};
    if (userData.email !== email) {
      payload = {
        profile_picture: imgProfile,
        email: email,
        fname: name,
        dob: "",
        is_registration: false, // false is mandatory,
        mobile: userData.mobile,
        otp: otp,
      };
    } else {
      payload = {
        fname: name,
        email: "",
        dob: "",
        profile_picture: imgProfile,
        is_registration: false, // false is mandatory,
        mobile: userData.mobile,
        otp: otp,
      };
    }

    if (
      name === "" ||
      email === "" ||
      EmailValidation(email) === false
      //  ||
      // date === ""
    ) {
      setDisableStat("Please go back and add all the details, again");
    } else {
      verifyProfileOtp(payload, (res) => {
        if (res?.response?.status === 200) {
          setProfileInfo(res?.content?.data);
          LocalStorage.set("profileImg", res?.content?.data?.profile_picture);
          let newData = {
            dob: res?.content?.data?.dob,
            email: res?.content?.data?.email,
            mobile: res?.content?.data?.mobile,
            name: res?.content?.data?.first_name,
            profileInfo: res?.content?.data?.profile_picture,
            emailVerify: res?.content?.is_user_email_verified
          };
          LocalStorage.set("UserData", newData);
          setName(res?.content?.data?.first_name);
          setModalShow(false);
          setNavStat("1");
        } else {
          setDisableStat(res?.response?.message);
        }
      });
    }
  };

  const isLoggedIn = LocalStorage.get("isLoggedIn");
  const onCloseAlert = () => {
    setAlertPopup("");
  };
  useEffect(() => {
    console.log("userrrrrxvcg", data, userData);
  }, [])
  return (
    <>
      <div className="profileCard">
        {innerWidth <= 1023 ? (
          <>
            <div className="profileCard_left">
              {/* <div className="profileCard_subSection">
              <img
                src={data?.profile_picture && (data?.profile_picture !== null || data?.profileInfo !== '') ? data?.profile_picture : Profile}
                alt="profile"
                width="50"
                height="50"
                className="profileCard_img"
              />
            </div> */}
              <div className="profileCard_right">
                <h4 className="profileCard_name">
                  {data?.first_name !== "" ? data?.first_name : userData.name}
                </h4>
                <div className="profileCard_content">
                  <div className="profileCard_wholeWrapper">
                    <h4 className="profileCard_desc">
                      +91 {data?.mobile === "" ? data?.mobile : userData.mobile}
                    </h4>
                    {(data && Object.keys(data).length !== 0) ?
                      data?.is_user_email_verified && <>
                        <img src={verifiedIcon} alt="verified" />
                        <span>Verified</span>
                      </> :
                      (userData?.emailVerify && <>
                        <img src={verifiedIcon} alt="verified" />
                        <span>Verified</span>
                      </>)}
                  </div>
                  {/* <div>
                    <p>|</p>
                  </div> */}
                  <div className="profileCard_data">
                    <h4 className="profileCard_emailName">
                      {data?.email === "" ? data?.email : userData.email}
                    </h4>
                    {
                      (data && Object.keys(data).length !== 0) ? (
                        !data?.is_user_email_verified && (
                          <span className="profileCard_data_verify" onClick={clickVerifyEmail}>
                            verify Email &gt;
                          </span>
                        )
                      ) : (
                        !userData?.emailVerify && (
                          <span className="profileCard_data_verify" onClick={clickVerifyEmail}>
                            verify Email &gt;
                          </span>
                        )
                      )
                    }
                    {
                      (data && Object.keys(data).length !== 0) ? (data?.is_user_email_verified && <>
                        <img src={verifiedIcon} alt="verified" />
                        <span>Verified</span>
                      </>) : (userData.emailVerify && <>
                        <img src={verifiedIcon} alt="verified" />
                        <span>Verified</span>
                      </>)
                    }
                  </div>
                </div>
              </div>
            </div>
            <Button
              content="Edit"
              onClick={() => CartShow((data && Object.keys(data).length !== 0) ? data : userData)}
              className="profileCard_button"
              customClass="customClass"
            />
          </>
        ) : (
          <>
            {/* {modalShow && (
              <EditProfile
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                // date={date}
                // setDate={setDate}
                imgUpload={imgUpload}
                imgProfile={imgProfile}
                setImgProfile={setImgProfile}
                CartShow={CartShow}
                otpCheck={otpCheck}
                disableStat={disableStat}
                profileInfo={userData}
              // isDOBchange={isDobEditable}
              />
            )} */}
            {/* {modalShow && navStat === "2" && (
              <EditProfileOtp
                setNavStat={setNavStat}
                otp={otp}
                setOtp={setOtp}
                verifyOtp={verifyOtp}
                navStat={navStat}
                displayMobile={displayMobileNumber}
                disableStat={disableStat}
              />
            )} */}
            <div className="profileCard_left">
              {<img
                src={
                  data && data?.profile_picture && (data?.profile_picture !== null || data?.profileInfo !== '')
                    ? data?.profile_picture
                    : (userData && userData.profileInfo && userData.profileInfo !== '' ? userData.profileInfo : Profile)
                }
                alt="profile"
                width="50"
                height="50"
                className="profileCard_img"
              />}
            </div>
            {!isLoggedIn && <div className="profileCard_right">
              <h4 className="profileCard_name">
                Guest user
              </h4>
            </div>}
            {isLoggedIn && <div className="profileCard_right">
              <div className="profileCard_content">
                <h4 className="profileCard_name">
                  {data?.first_name !== "" ? data?.first_name : userData.name}
                </h4>
                <div className="profileCard_data">
                  <img
                    src={Mobile}
                    alt="Mobile"
                    className="profileCard_data_Img"
                  />
                  <p className="profileCard_data_desc">
                    +91 {data?.mobile === "" ? data?.mobile : userData.mobile}
                  </p>
                  <img src={Mail} alt="Email" className="profileCard_data_Img" />
                  <p className="profileCard_data_desc">
                    {data?.email === "" ? data?.email : userData.email}
                    {(data && Object.keys(data).length !== 0) ? !data?.is_user_email_verified && <span className="profileCard_data_verify" onClick={clickVerifyEmail}>verify Email 	&#62;</span> : (!userData?.emailVerify && <span className="profileCard_data_verify" onClick={clickVerifyEmail}>verify Email 	&#62;</span>)}
                  </p>
                  {/* <img
                  src={Birthday}
                  alt="Dob"
                  className="profileCard_data_Img"
                />
                <p className="profileCard_data_desc">
                  {data?.dob === "" ? 
                  moment(data?.dob).format('MMMM Do')
                   : 
                   moment(userData.dob).format('MMMM Do')
                   }
                </p> */}
                </div>
              </div>
              <Button
                content="Edit"
                onClick={() =>
                  CartShow((data && Object.keys(data).length != 0) ? data : userData)}
                className="profileCard_button"
              />
            </div>}
          </>
        )}

        {alertPopup && (
          <AlertPopup
            title="Sorry!!"
            description={alertPopup}
            okayClick={() => onCloseAlert()}
          />
        )}

        {/* <div className="profileCard_left">
        <img
          src={Profile}
          alt="profile"
          width="50"
          height="50"
          className="profileCard_img"
        />
        {innerWidth <= 1023 && (
          <div className="profileCard_body">
            <img src={Edit} alt="Edit" className="profileCard_edit" />
          </div>
        )}
      </div>
      <div className="profileCard_right">
        {innerWidth <= 1023 && (
          <h4 className="profileCard_name">{userData.name}</h4>
        )}
        <div className="profileCard_content">
          {innerWidth >= 1024 && (
            <h4 className="profileCard_name">{userData.name}</h4>
          )}
          {innerWidth <= 1023 && (
            <>
              <div className="">
                <h4 className="profileCard_name">{userData.emailId}</h4>
                <h4 className="profileCard_name">{userData.dob}</h4>
              </div>
              <div className="">
                <img src={Mobile} alt="Mobile" className="" />
                <p className="profileCard_desc">{userData.mobile}</p>
              </div>
            </>
          )}
          {/* <p className="profileCard_desc">{userData.mobile}</p> */}
        {/* </div> */}
        {/* {innerWidth >= 1024 && ( */}
        {/* <Button
            content="Edit"
            onClick={() => CartShow()}
            className="profileCard_button"
          /> */}
        {/* )} */}
        {/* </div> */}
      </div>
      {
        verifyModal && (
          <OTPVerify
            mail={data?.email === "" ? data?.email : userData.email}
            setAction={setAction}
            onOtpSubmission={submitOTP}
            resend={clickVerifyEmail}
          />
        )
      }
    </>
  );
};

export default ProfileCard;
