import React from "react";
import "./index.scss";
import Button from "../button";
import productImg from "../../assets/images/productImg";

const CartCard = (props) => {
  const { couponData, onApply, ShowParticular, closeParticular } = props;
  const { innerWidth } = window;

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          {couponData.map((itemData) => {
            return (
              <div className="cards_wholewrapper">
                <div className="cards_wholewrapper_design">
                  <img
                    src={itemData.image || productImg}
                    className="cards_wholewrapper_design_img"
                    alt="ProductImg"
                  ></img>
                  <div className="cards_wholewrapper_design_center">
                    {itemData.coupon_code && (
                      <p className="cards_wholewrapper_design_center_coupon">
                        {itemData.coupon_code !== "" && itemData.coupon_code}
                      </p>
                    )}
                    <p 
                     dangerouslySetInnerHTML={{
                      __html: itemData.description,
                    }}
                    className="cards_wholewrapper_design_center_phrase">
                      {/* {itemData.description} */}
                    </p>
                    {/* <p className="cards_wholewrapper_design_center_description">
                      {itemData.short_description}
                    </p> */}
                    {/* <button
                      className="cards_wholewrapper_design_rightbar_button"
                      onClick={() => onApply(itemData)}
                    >
                      Apply
                    /> */}
                    {/* <Button
                      content="Apply"
                      className="cards_wholewrapper_design_rightbar_button"
                      customClass="cards_wholewrapper_design_rightbar_button_content"
                      onClick={() => onApply(itemData)}
                    /> */}
                    {itemData.coupon_code && itemData.coupon_code !== "" && (
                      <button
                        className="cards_wholewrapper_design_rightbar_button"
                        customClass="cards_wholewrapper_design_rightbar_button_content"
                        onClick={() => onApply(itemData)}
                      >
                        Apply
                      </button>
                    )}
                  </div>
                  {/* <div className="cards_wholewrapper_design_rightbar">
                  </div> */}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {couponData.map((itemData) => {
            return (
              <div className="cards_wholewrapper">
                <div className="cards_wholewrapper_design">
                  <img
                    src={itemData.image || productImg}
                    className="cards_wholewrapper_design_img"
                    alt="ProductImg"
                  ></img>
                  <div className="cards_wholewrapper_design_center">
                    {itemData.coupon_code && (
                      <p className="cards_wholewrapper_design_center_coupon">
                        {itemData.coupon_code !== "" && itemData.coupon_code}
                      </p>
                    )}
                    <p 
                      dangerouslySetInnerHTML={{
                        __html: itemData.offer_name,
                      }}
                    className="cards_wholewrapper_design_center_phrase">
                      {/* {itemData.description} */}
                    </p>
                    <p className="cards_wholewrapper_design_center_description">
                      <p className="cards_wholewrapper_design_center_description_one">
                      {itemData.short_description}
                      </p>
                      <p 
                      className="cards_wholewrapper_design_center_description_two"
                      onClick={() => ShowParticular(itemData)}
                      >
                       T&C
                      </p>
                    </p>
                  </div>
                  <div className="cards_wholewrapper_design_rightbar">
                    {itemData.coupon_code && itemData.coupon_code !== "" && (
                      <button
                        className="cards_wholewrapper_design_rightbar_button"
                        onClick={() => onApply(itemData)}
                      >
                        Apply
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default CartCard;
