import React, { useState, useEffect } from "react";
import "./index.scss";

const Timer = ({ seconds, onShow, login, customClass }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    if (!timeLeft) {
      onShowData(true);
      return;
    }

    if (login) {
      const intervalId = setInterval(() => {
        let time = timeLeft - 1;
        setTimeLeft(time);
        onShowData(false);
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      const intervalId = setInterval(() => {
        let time = timeLeft - 1;
        setTimeLeft(time);
        onShowData(false);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  const onShowData = (data) => {
    onShow(data);
  };

  const { innerWidth } = window;

  return (
    <div>
      {innerWidth <= 1023 ? (
        <>
          {timeLeft >= 10 ? (
            <p className={`${customClass} timer`}>{timeLeft} sec</p>
          ) : (
            <p className={`${customClass} timer`}>{timeLeft} sec</p>
          )}
        </>
      ) : (
        <>
          {timeLeft >= 10 ? (
            <p className="timer">{timeLeft} sec</p>
          ) : (
            <p className="timer">{timeLeft} sec</p>
          )}
        </>
      )}
    </div>
  );
};
export default Timer;
