import React, { useState, useEffect } from "react";
import "./index.scss";
import { LocalStorage } from "../../utils/storage";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
import NewButton from "../newCount-Button";
import AmountSummary from "../amount-summary";
import SenderDetails from "../../components/senderDetails";
import Button from "../../components/button";
import Modal from "../modal";
const billDatae = [
  { name: "Item total", amount: "₹ 0", show: true },
  { name: "Tax", amount: "₹ 0", show: true },
  { name: "Discount", amount: "₹ 0", show: true },
  { name: "Total Amount", amount: "₹ 0", show: true },
];

const VoucherModalCard = (props) => {
  const {
    data,
    onUpdateContent,
    tax,
    name,
    mobile,
    email,
    isChecked,
    onChangeName,
    onChangeMobile,
    onChangeEmail,
    onChangeChecked,
    nameAlert,
    mobileAlert,
    emailAlert,
    recieverData,
    onAddRecieveData,
    imgContainerCustomClass,
    descriptionContainerCustomClass,
    quantityCardCustomClass,
  } = props;
  const [item, setItem] = useState(data);
  const [billData, setBillData] = useState(billDatae);
  const [terms, setTerms] = useState(false);
  const [termData, setTermData] = useState('');
  const termsSetting = (item) => {
    setTerms(true);
    setTermData(item);
  }
  const closeThing = (item) => {
    setTerms(false);
  }
  // const [name, setName] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [email, setEmail] = useState("");
  // const [nameAlert, setNameAlert] = useState("");
  // const [mobileAlert, setMobileAlert] = useState("");
  // const [emailAlert, setEmailAlert] = useState("");
  // const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    let cgst = 0;
    let sgst = 0;
    let totalPrice = 0;
    let offer =
      Number(
        parseFloat(
          item.count * Number(parseFloat(item.total_card_amount).toFixed(2))
        ).toFixed(2)
      ) -
      Number(
        parseFloat(
          item.count *
            Number(parseFloat(item.offer_amount_to_be_paid).toFixed(2))
        ).toFixed(2)
      );
    const outletData = LocalStorage.get("Outlet_Details");
    tax.map((items) => {
      if (items.tax_id === "1") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        cgst = (price * Number(items.tax_value)) / 100;
      }
      if (items.tax_id === "2") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        sgst = (price * Number(items.tax_value)) / 100;
      }
    });

    const billDatae = [
      {
        name: "Item total",
        amount: `₹ ${Number(
          parseFloat(
            item.count * Number(parseFloat(item.total_card_amount).toFixed(2))
          ).toFixed(2)
        )}`,
        show: true,
      },
      { name: "Tax", amount: `₹ ${cgst + sgst}`, show: true },
      {
        name: "Discount",
        amount: `₹ ${offer}`,
        show: true,
      },
      {
        name: "Total Amount",
        amount: `₹ ${
          Number(
            parseFloat(
              item.count *
                Number(parseFloat(item.offer_amount_to_be_paid).toFixed(2))
            ).toFixed(2)
          ) +
          cgst +
          sgst
        }`,
        show: true,
      },
    ];
    setBillData(billDatae);
  }, []);

  const SubCount = (itm, value) => {
    let cgst = 0;
    let sgst = 0;
    let totalPrice = 0;
    let offer =
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
        ).toFixed(2)
      ) -
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.offer_amount_to_be_paid).toFixed(2))
        ).toFixed(2)
      );
    const outletData = LocalStorage.get("Outlet_Details");
    if (value === "add") {
      itm.count = itm.count + 1;
    } else {
      if (itm.count > 0) {
        itm.count = itm.count - 1;
      }
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
        ).toFixed(2)
      );
    }
    setItem(itm);
    tax.map((items) => {
      if (items.tax_id === "1") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        cgst = (price * Number(items.tax_value)) / 100;
      }
      if (items.tax_id === "2") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        sgst = (price * Number(items.tax_value)) / 100;
      }
    });

    const billDatae = [
      {
        name: "Item total",
        amount: `₹ ${Number(
          parseFloat(
            itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
          ).toFixed(2)
        )}`,
        show: true,
      },
      { name: "Tax", amount: `₹ ${cgst + sgst}`, show: true },
      {
        name: "Discount",
        amount: `₹ ${offer}`,
        show: true,
      },
      {
        name: "Total Amount",
        amount: `₹ ${
          Number(
            parseFloat(
              itm.count *
                Number(parseFloat(itm.offer_amount_to_be_paid).toFixed(2))
            ).toFixed(2)
          ) +
          cgst +
          sgst
        }`,
        show: true,
      },
    ];

    setBillData(billDatae);
    onUpdateContent(itm);
  };

  const { innerWidth } = window;

  return (
    <>
      {
      terms &&
      <Modal
      modalCustomClass={`modalCustomEdit11`}
      onCloseHandler={closeThing}
      // hideClose={stat === "stage2" || stat === "stage3"}
      isMobileFullLayout
      >
        <div className="termsVoucher">
          <h2 className="termsVoucher_heading">
            Terms and Conditions
          </h2>
        <p 
        className="termsVoucher_nameLabel"
        dangerouslySetInnerHTML={{
          __html: termData,
          }}
        >
        </p>
        </div>
      
      </Modal>
    }
      {innerWidth <= 1023 ? (
        <div className="voucherModalCard">
          <div classsName="voucherModalCard_item">
            <div className="voucherModalCard_item">
              <div className="voucherModalCard_itemDiv_wrapper">
                <div
                  className={`voucherModalCard_imgContainer ${imgContainerCustomClass}`}
                >
                  <img
                    src={BBK}
                    className="voucherModalCard_imgContainer_img"
                    alt="logo"
                    width="100%"
                    height="200"
                  />
                </div>
                <div className="voucherModalCard_descriptionContainer_Div">
                  <div
                    className={`voucherModalCard_descriptionContainer ${descriptionContainerCustomClass}`}
                  >
                    <div className="voucherModalCard_descriptionContainer_foodContainer">
                      <p className="itemName">{item?.name}</p>
                      <span className="itemLabel">
                        Pay &#8377;{" "}
                        {Number(
                          parseFloat(item?.offer_amount_to_be_paid).toFixed(2)
                        )}
                      </span>
                      <span className="voucherModalCard_originalPrice">
                        {" "}
                        &#8377;{" "}
                        {Number(parseFloat(item?.total_card_amount).toFixed(2))}
                      </span>
                      <h3 className="voucherModalCard_terms">
                        Terms & Conditions
                      </h3>
                    </div>
                  </div>
                  <NewButton
                    content={item?.count} // 1
                    className="voucherModalCard_addsubBtn"
                    customSubIconClass="voucherModalCard_customSubIconClass"
                    customClass="voucherModalCard_customClass"
                    customAddIconClass="voucherModalCard_customAddIconClass"
                    subCount={SubCount}
                    itemid={item?._id}
                    item={item}
                  />
                </div>
              </div>
              <div className="giftVoucherModal_sender">
                {recieverData &&
                  recieverData.length > 0 &&
                  recieverData.map((item) => {
                    return (
                      <div className="giftVoucherModal_descriptionContainer_foodContainer">
                        <p className="itemName">
                          {item?.name} ,{item?.receiver_mobile}
                        </p>
                        <span className="itemLabel">
                          Quantity: {item?.quantity}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="voucherModalCard">
          <div classsName="voucherModalCard_item">
            <div className="voucherModalCard_item">
              <div
                className={`voucherModalCard_imgContainer ${imgContainerCustomClass}`}
              >
                <img
                  src={BBK}
                  className="voucherModalCard_imgContainer_img"
                  alt="logo"
                  width="100%"
                  height="200"
                />
              </div>
              <div
                className={`voucherModalCard_descriptionContainer ${descriptionContainerCustomClass}`}
              >
                <div className="voucherModalCard_descriptionContainer_foodContainer">
                  <p className="itemName">{item?.name}</p>
                  <span className="itemLabel">
                    Pay &#8377;{" "}
                    {Number(
                      parseFloat(item?.offer_amount_to_be_paid).toFixed(2)
                    )}
                  </span>
                  <span className="voucherModalCard_originalPrice">
                    {" "}
                    &#8377;{" "}
                    {Number(parseFloat(item?.total_card_amount).toFixed(2))}
                  </span>
                </div>
                <p 
                onClick={() => termsSetting(item?.terms_and_conditions)}
                className="voucherModalCard_terms">Terms & Conditions</p>
              </div>
              <div
                className={`voucherModalCard_quantityCard ${quantityCardCustomClass}`}
              >
                <h1 className="voucherModalCard_quantityCard_text">Quantity</h1>
                <NewButton
                  content={item?.count} // 1
                  className="voucherModalCard_addsubBtn"
                  subCount={SubCount}
                  itemid={item?._id}
                  item={item}
                />
              </div>
              {/* <SenderDetails
            name={name}
            mobile={mobile}
            email={email}
            isChecked={isChecked}
            onChangeName={onChangeName}
            onChangeMobile={onChangeMobile}
            onChangeEmail={onChangeEmail}
            onChangeChecked={onChangeChecked}
            nameAlert={nameAlert}
            mobileAlert={mobileAlert}
            emailAlert={emailAlert}
          /> */}
              {/* <p className="giftVoucherModal_senderDetails">Receiver Details</p> */}
              <div className="giftVoucherModal_sender">
                {recieverData &&
                  recieverData.length > 0 &&
                  recieverData.map((item) => {
                    return (
                      <div className="giftVoucherModal_descriptionContainer_foodContainer">
                        <p className="itemName">
                          {item?.name} ,{item?.receiver_mobile}
                        </p>
                        <span className="itemLabel">
                          Quantity: {item?.quantity}
                        </span>
                      </div>
                    );
                  })}
                {/* <Button
              content="+ Add Receiver"
              className="giftVoucherModal_reciever"
              onClick={() => onAddRecieveData}
            /> */}
              </div>
              <AmountSummary billingDetails={billData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VoucherModalCard;
