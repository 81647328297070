import { Route, Redirect } from 'react-router-dom';
import { LocalStorage } from "../utils/storage";


const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const isLoggedIn = LocalStorage.get("isLoggedIn");
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
      }
    />
  );
};

export default ProtectedRoute;
