import React from "react";
import "./index.scss";
import videoPlay from "../../assets/images/videoPlay.svg";

const VideoButton = (props) => {
  const { link, handleVideo } = props;
  const { innerWidth } = window;


  return (
    <div className="videoButton">
      <img src={videoPlay} className="videoButton_img" alt="videoPlay" />
      <a onClick={() => handleVideo(link)}>
        {innerWidth <= 1023 ? (
          <h3 className="videoButton_nameLabel">Watch Video</h3>
        ) : (
          <h4 className="videoButton_nameLabel">The Tasty Biryani</h4>
        )}
      </a>
    </div>
  );
};
export default VideoButton;
