import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import back from "../../assets/images/back.svg";
import close from "../../assets/images/group.svg";
import "./index.scss";
import Modal from "../../components/modal";
import storeImg from "../../assets/images/store.png";
import Timer from "../../assets/images/timer.svg";
import locationShort from "../../assets/images/locationShort.svg";
import Button from "../../components/button";
import MapView from "../../components/mapView";
import remove from "../../assets/images/close.svg";
import RenderMaps from "../MapsCommonApi";

const StoreLocatorPopup = (props) => {
  const { data, close, storepopup } = props;
  const latLng = { lat: data.latitude, lng: data.longitude }
  let center = { lat: "", lng: "" };
  if (data && data.latitude && data.longitude) {
    center = {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
    };
  }
  console.log(latLng,"ltttt")
  const onOpenMap = (data) => {
    window.open(
      `http://maps.google.com/maps?q=${data.latitude},${data.longitude}`,
      "_blank"
    );
  };

  const { innerWidth } = window;
  const [storePopup, setStorePopup] = useState(storepopup);

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          {storePopup && (
            <Modal
              modalCustomClass="modalCustom"
              onCloseHandler={() => close()}
              contentCustomClass="contentCustomClass"
              hideClose
            >
              <div className="storePopup">
                <div className="storePopup_one">
                  <div className="storePopup_one_wholeWrapper">
                    <h4 className="storePopup_one_titleNameLabel">
                      Store detail
                    </h4>
                    <img
                      src={remove}
                      className="storePopup_one_backimg"
                      onClick={() => close()}
                      alt="remove"
                    />
                  </div>
                  <div className="storePopup_one_body">
                    <div className="storePopup_oneWholeDiv">
                      <img src={storeImg} className="storePopup_one_img" alt="storeImg" />
                      <p className="storePopup_one_nameLabel">
                        {data?.display_name}
                      </p>
                      <div className="storePopup_one_third">
                        <p className="storePopup_one_third_address2">
                          {data?.address}
                        </p>
                      </div>
                      <div className="storePopup_one_second">
                        <img
                          src={locationShort}
                          className="storePopup_one_second_img"
                          alt="location"
                        />
                        <p className="storePopup_one_second_km">
                          {(data?.user_distance_in_km / 0.1).toFixed(2)} km
                        </p>
                        <div className="storePopup_one_second_timeWrapper">
                          <img
                            className="storePopup_one_second_clockImg"
                            src={Timer}
                            alt="timer"
                          />
                          <p className="storePopup_one_second_nameLabel">
                            {data?.outletdeliverytiming__start_time.split(
                              ":",
                              1
                            )}
                            AM -{" "}
                            {data?.outletdeliverytiming__end_time.split(":", 1)}
                            PM
                          </p>
                        </div>
                        <p className="storePopup_one_second_status">
                          {data?.is_outlet_open == 1 ? "Open" : "Closed"}
                        </p>
                      </div>
                    </div>
                    <div className="storePopup_one_wholeDiv">
                      <Button
                        className="storePopup_one_button"
                        content="Navigate me"
                        onClick={() => onOpenMap(data)}
                        customClass="storePopup_one_button_content"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </>
      ) : (
        <>
          <Modal
            modalCustomClass="modalCustomStoreLocator"
            onCloseHandler={() => close()}
          >
            <div className="storePopup">
              <div className="storePopup_one">
                <h3 className="storePopup_one_nameLabel">Store Detail</h3>
                <img src={storeImg} className="storePopup_one_img" />
                <p className="storePopup_one_nameLabel">{data?.display_name}</p>
                <div className="storePopup_one_first">
                  <img className="storePopup_one_first_img" src={Timer} alt="timer" />
                  <p className="storePopup_one_first_nameLabel">
                    {data?.outletdeliverytiming__start_time} -{" "}
                    {data?.outletdeliverytiming__end_time}
                  </p>
                </div>
                <div className="storePopup_one_second">
                  <img
                    src={locationShort}
                    className="storePopup_one_second_img" alt="location"
                  />
                  <p className="storePopup_one_second_km">
                    {(data?.user_distance_in_km / 0.1).toFixed(2)} km
                  </p>
                  <p className="storePopup_one_second_status">
                    {data?.is_outlet_open == 1 ? "Open" : "Closed"}
                  </p>
                </div>
                <div className="storePopup_one_third">
                  <h1 className="storePopup_one_third_address">Address - </h1>
                  <p className="storePopup_one_third_address2">
                    {" "}
                    {data?.address}
                  </p>
                </div>
                <div className="storePopup_one_flx">
                  <Button
                    className={`storePopup_one_flx_button ${data?.is_active === false && 'storePopup_one_flx_buttonFirst'}`}
                    content="Book a Table"
                    onClick={() => window.open('https://form.jotform.com/Biryani_by_kilo/bbk-table-reservation')}
                  />
                  <Button
                    className="storePopup_one_flx_button"
                    content="Get Direction"
                    onClick={() => onOpenMap(data)}
                  />
                </div>
              </div>
              <div className="storePopup_two">
                {/* <MapView center={center} /> */}
                <RenderMaps
                  // callOutlet={callOutlet}
                  // onConfirmLocation={onConfirmLocation}
                  latLng={latLng}
                  activeTheme="delivery"
                  noconfirm={true}
                // activeTheme={active}
                // selectSavedAddress={selectSavedAddress}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default StoreLocatorPopup;
