import "./index.scss";
import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
// import ORDERRATE from '../../assets/images/order-rate.svg';
import Button from "../../components/button";
import Modal from "../modal";
import { handleParseAmount } from "../../helpers/sharedFunction";
// import CountButton from '../../components/count-button';
// import Modal from '../../components/modal';
// import ProductDetail from '../productDetail';
// import AddOnCheck from '../addOnCheck';
// import Spice from '../../assets/images/spice.svg';
// import VideoButton from '../../components/video-button';
// import AddToCart from '../../components/addToCart-popup';
// import NewButton from '../../components/newCount-Button';
// import { LocalStorage } from '../../utils/storage';
// import Cart from '../cart-PopUp';

const VoucherCard = ({ content, list, onBuyNowData }) => {
  const { innerWidth } = window;
  const [terms, setTerms] = useState(false);
  const [termData, setTermData] = useState('');
  const termsSetting = (item) => {
    setTerms(true);
    setTermData(item);
  }
  const closeThing = (item) => {
    setTerms(false);
  }
  return (
    <>
    {
      terms &&
      <Modal
      modalCustomClass={`modalCustomEdit11`}
      onCloseHandler={closeThing}
      // hideClose={stat === "stage2" || stat === "stage3"}
      isMobileFullLayout
      >
        <div className="termsVoucher">
          <h2 className="termsVoucher_heading">
            Terms and Conditions
          </h2>
        <p 
        className="termsVoucher_nameLabel"
        dangerouslySetInnerHTML={{
          __html: termData,
          }}
        >
        </p>
        </div>
      
      </Modal>
    }
    <div className="voucherCard">
      <div className="voucherCard_imgContainer">
        <img
          src={BBK}
          className="voucherCard_imgContainer_img"
          alt="logo"
          width="100%"
          height="200"
        />
        {/* <p className='voucherCard_imgContainer_preparation'>{preparation}</p> */}
      </div>
      <div className="voucherCard_descriptionContainer">
        <div className="voucherCard_descriptionContainer_foodContainer">
          {innerWidth <= 1023 ? (
            <p className="itemName">{content?.name && (content?.name).toUpperCase()}</p>
          ) : (
            <p className="itemName">{content?.name}</p>
          )}
          {innerWidth <= 1023 ? (
            <div className="voucherCard_descriptionContainer_wholeWrapper">
             {list ? <span className="itemLabel">
                Pay &#8377;{" "}
                {Number(
                  parseFloat(content?.offer_amount_to_be_paid).toFixed(2)
                )}
              </span>
              : <span className="itemLabel">
              &#8377;{" "} {handleParseAmount(content.gift_card_amount)}
              </span>}
              {list && <span className="voucherCard_originalPrice">
                {" "}
                &#8377;{" "}
                {Number(parseFloat(content?.total_card_amount).toFixed(2))}
              </span>}
            </div>
          ) : (
            <>
               {list ? <span className="itemLabel">
                Pay &#8377;{" "}
                {Number(
                  parseFloat(content?.offer_amount_to_be_paid).toFixed(2)
                )}
              </span> : 
              <span className="itemLabel">
               &#8377;{" "} {handleParseAmount(content.gift_card_amount)}
               </span>
              }
              {list && <span className="voucherCard_originalPrice">
                {" "}
                &#8377;{" "}
                {Number(parseFloat(content?.total_card_amount).toFixed(2))}
              </span>}
            </>
          )}
        </div>
        {innerWidth <= 1023 ? (
          <>
            {list ? (
              <Button
                content="Buy now"
                className="voucherCard_buyNow"
                customClass="voucherCard_buyNow_content"
                onClick={() => onBuyNowData(content)}
              />
            ) : (
              <span className="voucherCard_coupon-code">
                {content.coupon_code}
              </span>
            )}
          </>
        ) : (
          <>
            {list ? (
              <Button
                content="Buy now"
                className="voucherCard_buyNow"
                onClick={() => onBuyNowData(content)}
              />
            ) : (
              <span className="voucherCard_coupon-code">
              {content.coupon_code}
            </span>
            )}
          </>
        )}
      </div>
      <p 
      onClick={() => termsSetting(content?.terms_and_conditions)}
      className="voucherCard_terms">* Terms & Conditions</p>
    </div>
    </>
  );
};

export default VoucherCard;
