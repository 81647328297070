import React, { useState } from "react";
import "./index.scss";
import plusImg from "../../assets/images/plusAdd.svg";
import home from "../../assets/images/homeAddress.svg";
import homedark from "../../assets/images/homedark.svg";
import workdark from "../../assets/images/work.svg";
import edit from "../../assets/images/editdark.svg";
import close from "../../assets/images/close.svg";
import Button from "../button";
import deleteicon from "../../assets/images/delete.svg"

const AddAddressCard = (props) => {
  const { onClick, add, data, onEdit, deleteCard } = props;
  const { innerWidth } = window;
  const [editAddress, setEditAddress] = useState(false);

  const handleEdit = (data) => {
    setEditAddress(true);
    onEdit(data);
  };
  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="addAddressCard_wholeDiv">
            {data &&
              data?.length > 0 &&
              data.map((item) => {
                return (
                  <div className="addAddressCard2">
                    <div className="addAddressCard2_wholeWrapper">
                      {item?.AddressType === "Work" ? (
                        <img src={workdark} className="addAddressCard2_img"  alt="workdark"/>
                      ) : (
                        <img src={homedark} className="addAddressCard2_img" alt="homedark"/>
                      )}
                    </div>
                    <div className="addAddressCard2_bodySection">
                      <div className="addAddressCard2_dataDiv">
                        <p className="addAddressCard2_dataDiv_nameLabel">
                          {item?.AddressType}
                        </p>
                        <p className="addAddressCard2_dataDiv_subLabel">
                          {item?.Address1} {/* {item?.Address2}  */}
                          {!item?.Google_Address
                            ? item.Address2
                            : item.Google_Address}
                        </p>
                        <p className="addAddressCard2_dataDiv_subLabel">
                          {item?.City}
                        </p>
                      </div>
                      <img
                        src={edit}
                        className="addAddressCard2_bodySection_editImg"
                        onClick={() => handleEdit(item)}
                        alt="edit"
                      />
                      <img
                        src={deleteicon}
                        className="addAddressCard2_bodySection_editImg"
                        onClick={() => deleteCard(item)}
                        alt="edit"
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <>
          {add ? (
            <div className="addAddressCard" onClick={() => onClick()}>
              <img src={plusImg} className="addAddressCard_img" alt="plusImg"/>
              <h3 className="addAddressCard_nameLabel">Add Address</h3>
            </div>
          ) : (
            data &&
            data?.length > 0 &&
            data.map((item) => {
              return (
                <div className="addAddressCard2">
                  <img src={home} className="addAddressCard2_img" alt="home"/>
                  <div className="addAddressCard2_dataDiv">
                    <p className="addAddressCard2_dataDiv_nameLabel">
                      {item?.AddressType}
                    </p>
                    <p className="addAddressCard2_dataDiv_subLabel">
                      {item?.Address1} <br />
                      {/* {item?.Address2}  */}
                      {!item?.Google_Address
                        ? item.Address2
                        : item.Google_Address}
                    </p>
                    <p className="addAddressCard2_dataDiv_subLabel">
                      {item?.City}
                    </p>
                    <div className="addAddressCard2_dataDiv_subDiv">
                      <p
                        onClick={() => onEdit(item)}
                        className="addAddressCard2_dataDiv_subDiv_nameLabel"
                      >
                        Edit
                      </p>
                      <p
                        onClick={() => deleteCard(item)}
                        className="addAddressCard2_dataDiv_subDiv_delLabel"
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </>
      )}
      {!data || data?.length === 0 ? (
        <h2 className="addAddressCard__not-data-found">No address saved!</h2>
      ) : (
        ""
      )}
    </>
  );
};
export default AddAddressCard;
