import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import { LocalStorage } from "../../utils/storage";
import { Authentication, Logout } from "../../actions/authentication";
import "./index.scss";
import AlertPopup from "../../components/alert-popup";
import { handleGetLoyaltyTypeImg } from "../../helpers/sharedFunction";
import { getLoyalityData } from "../../actions/loyalty";
import { getStaticPage } from "../../actions/staticpages";
import { logOutIdentityBranch } from "../../utils/branch";
import { viewWalletBalance } from "../../actions/wallet";
import menu_icon from "../../assets/images/staticimage.png"
import next from "../../assets/images/next.png";


const MenuSideBar = (props) => {
  const {
    data,
    active,
    onClickCategory,
    className,
    customClass,
    key,
    customClassRoute,
    routeName,
  } = props;
  console.log(data, "profileData");
  const { innerWidth } = window;
  const [logout, setLogout] = useState(false);
  const [menuClick, setMenuClick] = useState(false);
  const [loyaltyData, setLoyaltyData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [walletStatus, setWalletStatus] = useState(null);
  const [walletBalance, setWalletBalance] = useState();

  const history = useHistory();
  const isLoggedInUser = LocalStorage.get("isLoggedIn");

  useEffect(() => {
    if (isLoggedInUser) {
      getLoyalityData((res) => {
        if (res && res?.response?.status === 200) {
          if (res && res.content && res.content.current_points) {
            setLoyaltyData(res.content.current_points);
            console.log(res.content, "UUUY")
          }
        }
      });
    }
    const queryParams = "";

    if (innerWidth <= 1023) {
      getStaticPage((res) => {
        if (res && res?.response?.status === 200) {
          const root = document.getElementById("root");
          root.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          setProfileData(res && res?.content);
        }
      }, queryParams);
    }
    viewWalletBalance((res) => {
      if (res && res?.response?.status === 200) {
        setWalletStatus(res?.content?.status);
        setWalletBalance(res?.content?.balance);
      }
    });
  }, []);

  const handleOnClick = () => {
    Logout((re) => {
      if (re.response.status === 200) {
        //branch event
        logOutIdentityBranch();

        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");

        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              history.push(ROUTES.HOME);
            } else {
              history.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  };

  const handleClose = () => {
    setLogout(false);
  };

  const handleMenuClick = (newItems) => {
    setMenuClick(true);
    onClickCategory(newItems);
  };

  return (
    <div className={`menuSideBar ${className}`}>
      {data &&
        data.length > 0 &&
        data.map((items) => {
          return (
            <>
              {innerWidth <= 1023 ? (
                <>
                  {items.category_name !== "Logout" && (
                    <div className="wholeWrapper">
                      <div className="wholeWrapper_icon">
                        <img src={items.image} />
                      </div>
                      <p
                        className={`menuSideBar_text ${customClass} ${active === items.category_name ? "activate" : ""}`}
                        onClick={() => onClickCategory(items)}
                      >
                        {items.category_name}
                        {loyaltyData && items.isShowLoyltyPoints && (
                          <>
                            (
                            <span className="profileDropdown_profile_data_loyaltyPoints">{` ${loyaltyData.CurrentPoints} `}</span>
                            <img
                              className="profileDropdown_profile_data_loyaltyImg"
                              alt="profile"
                              src={handleGetLoyaltyTypeImg(loyaltyData.guest_label)}
                            />{" "}
                            ){" "}
                          </>
                        )}
                        {walletBalance && items.showWalletBalance && walletStatus ? (
                          <>
                            <span className="profileDropdown_profile_data_walletBalance">
                              {` ₹ ${walletBalance === 0 ? "0" : walletBalance} `}
                            </span>
                          </>
                        ) : null}
                      </p>
                    </div>
                  )}
                  <div
                    className={`wholeWrapper_divider ${items.category_name === "Logout" &&
                      "wholeWrapper_logoutDivider"
                      }`}
                  />
                </>
              ) : (
                <p
                  className={`menuSideBar_text ${routeName === items.category_name ? customClassRoute : ""
                    } ${customClass} ${active === items.category_name ? "activate" : ""
                    } ${menuClick && `menuSideBar_highlight`}`}
                  onClick={() => handleMenuClick(items)}
                >
                  {items.category_name}
                </p>
              )}
            </>
          );
        })}
      {innerWidth <= 1023 && (
        <div>
          {console.log(profileData,"pd")}
          {profileData &&
            profileData?.result &&
            profileData?.result.length > 0 &&
            profileData?.result.map((item) => {
              return (
                item?.filter_name === "useful_links" && (
                  <>
                    <div className="wholeWrapper">
                      <img className = "static_icon"src={menu_icon} />
                      <p
                        onClick={() => {
                          // history.push(`/useful-links/${item?.url_text}`);
                          history.push(`/${item?.url_text}`);
                          // staticRender('/useful-links/'+item?.url_text);
                        }}
                        className="menuSideBar_text myprofile_text"
                      >
                        {item?.title}
                      </p>
                    </div>
                    <div className={`wholeWrapper_divider`} />
                  </>
                )
              );
            })}

          {isLoggedInUser && (
            <>
              <div className="menuSidebar_footer_div">
                <p
                  className="menuSideBar_footer"
                  onClick={() => {
                    setLogout(true);
                  }}
                >
                  Logout
                </p>
                <img src={next} /></div>
              <div className="menuSidebar_footer_div">
                <p
                  className="menuSideBar_footer"
                  onClick={() => {
                    setLogout(true);
                  }}
                >
                  Logout from all devices
                </p>
                <img src={next} /></div>
            </>

          )}
        </div>
      )}
      {logout && (
        <AlertPopup
          title={`${innerWidth <= 1023 ? `Logout ?` : `Logout!!`}`}
          description={`${innerWidth <= 1023
            ? `Are you sure you want to logout?`
            : `Are you sure to logout?`
            }`}
          okayClick={() => handleOnClick()}
          close={() => handleClose()}
          logout={true}
        />
      )}
    </div>
  );
};

export default MenuSideBar;
