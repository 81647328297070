import React, { useState, useEffect } from "react";
import "./index.scss";

const SavedAddress = (props) => {
  const { data, onClick } = props;
  // console.log(data);
  return (
    <div className="savedAddressWholeDiv">
      {data &&
        data.map((value) => {
          return (
            <div
              className="savedAddress"
              onClick={() => onClick("savedaddress", value)}
            >
              <div className="savedAddress__container">
                {/* {console.log(value)} */}
                <p className="savedAddress__container-addressType">
                  {value.AddressType}
                </p>
                <p className="savedAddress__container-address1">
                  {value.Address1 + value.Address2}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SavedAddress;
