import React from 'react';
import GoogleMapSearch from '../../components/map-search';
import Modal from '../../components/modal';
import SearchAutoComplete from '../searchAutocomplete';
import './index.scss';

const MapModal = (props) => {
  const {
    onCurrentDetectLocation,
    onBackClick,
    activeTheme,
    inputData,
    onChangeInput,
    latLng,
    onCenterChange,
    onMarkerPosition,
    onConfirmLocation,
    inputValue,
    onClickStore,
  } = props;
  return (
    <div className='mapModal'>
      <Modal
        hideClose
        modalCustomClass='mapModal_map'
        contentCustomClass='mapModal_map_content'
        //   contentDataCustomClass='mapModal_map_'
      >
        <GoogleMapSearch
          onBackClick={onBackClick}
          onCurrentDetectLocation={onCurrentDetectLocation}
          activeTheme={activeTheme}
          inputData={inputData}
          onChangeInput={onChangeInput}
          center={latLng}
          inputValueData={inputData}
          onCenterChange={onCenterChange}
          onMarkerPosition={onMarkerPosition}
          onConfirmLocation={onConfirmLocation}
          onClickStore={onClickStore}
        />
      </Modal>
    </div>
  );
};
export default MapModal;
