import React from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import Close from "../../assets/images/close.svg";
import goback from "../../assets/images/goback.svg";
import Delivery from "../../assets/images/payment-success.svg";
import Button from "../button";

const SuccessCard = (props) => {
  const { onCLoseClick, onExploreFood, showTrack } = props;
  const { innerWidth } = window;
  const history = useHistory();
  const handleGoBack = () => history.goBack();

  return (
    <>
      {innerWidth <= 1023 ? (
        <div className="successcard">
          <div className="successcard_header">
            <img
              src={goback}
              className="successcard_header_img"
              onClick={() => handleGoBack()}
              alt="goback"
            />
            <h1 className="successcard_header_phrase">Payment Detail</h1>
          </div>
          <div className="successcard_wholeWrapper">
            <div className="successcard_wholeDiv">
              <div className="successcard_head">
                <h3 className="successcard_heading">
                  Your Payment was successfull. Enjoy your food.
                </h3>
              </div>
              <div className="successcard_content">
                <img src={Delivery} alt="delivery" width="300" height="300"/>
                <h4 className="successcard_caption">
                  Yohoo!! your order was successfully placed...
                </h4>
                {/* <p className="successcard_text">
                  Your Online Payment was successfull. Enjoy your food.
                </p> */}
              </div>
            </div>
            <div className="successcard_buttons">
              {showTrack && (
                <Button
                  content="Track Order"
                  onClick={onCLoseClick}
                  className="successcard_tryAgain"
                  customClass="successcard_content"
                />
              )}
              <Button
                content="Explore Foods"
                className="successcard_explore"
                customClass="successcard_content"
                onClick={onExploreFood}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="successcard">
          <div className="successcard_head">
            <h3 className="successcard_heading">
              Your Payment was successfull. Enjoy your food.
            </h3>
            <img
              src={Close}
              alt="close"
              height="20"
              width="20"
              onClick={onCLoseClick}
            />
          </div>
          <div className="successcard_content">
            <img src={Delivery} alt="delivery" width="300" height="300" />
            <h4 className="successcard_caption">
              Yohoo!! your order was successfully placed...
            </h4>
            {/* <p className="successcard_text">
              Your Online Payment was successfull. Enjoy your food.
            </p> */}
            <div className="successcard_buttons">
              {showTrack && (
                <Button
                  content="Track Order"
                  onClick={onCLoseClick}
                  className="successcard_tryAgain"
                />
              )}
              <Button content="Explore Foods" onClick={onExploreFood} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessCard;
