import React, { useState } from "react";
import "./index.scss";
import back from "../../assets/images/path.svg";
import Input from "../../components/input";
import DateTimePicker from "react-datetime-picker";
import Button from "../../components/button";
import { EmailValidation } from "../../utils/validation";
import moment from "moment";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
const BasicDetails = (props) => {
  const { onNav, getBasicDetails, errorMsg, data } = props;

  const [name, setName] = useState(
    data && data.first_name ? data.first_name : ""
  );
  const [email, setEmail] = useState(data && data.email ? data.email : "");
  const [date, setDate] = useState("");
  const history = useHistory();

  const onLoginClick = () => {
    history.push(ROUTES.LOGIN);
  };

  const validation = () => {
     
    if (
      name === "" ||
      email === "" ||
      EmailValidation(email) === false 
      // date === ""
    ) {
    } else {
      // onNav('stage3');
      getBasicDetails({
        name: name,
        email: email,
        date: "",
      });
    }
  };

  const { innerWidth } = window;

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="basicDetails">
            <div className="basicDetails_heading">
              <h2 className="basicDetails_heading_label">Basic detail</h2>
            </div>

            <div className="basicDetails_form">
              <div className="">
              <h3 className="basicDetails_form_labelName">Name</h3>
                <Input
                  type="text"
                  onChange={(id, value) => {
                    setName(value);
                  }}
                  value={name}
                  customClass="basicDetails_form_inpTag"
                  placeholder={"Enter your name"}
                />
                 {/* <p className="basicDetails_form_labelName">
                 Date of Birth
              </p> */}

                {/* <DateTimePicker
                  onChange={setDate}
                  value={date}
                  maxDate={new Date()}
                  className="basicDetails_form_inpBirthdayTagDate"
                  format="yyyy-MM-dd"
                  placeholderText="Enter here"
                /> */}

               {/* <input
                type="date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                value={date}
                max={moment().format("YYYY-MM-DD")} 
                className="basicDetails_form_inpBirthdayTagDate"
                onKeyDown={(e) => {
                  e.preventDefault();
               }}

                customClass="basicDetails_form_inpBirthdayTag"
                placeholder={"Enter Email ID"}
              /> */}

                {/* <p className="basicDetails_form_greetTxt">
                  Note: We are asking it to make your birthday special{" "}
                </p> */}
                {innerWidth <= 1023 && (
                  <>
                  <h3 className="basicDetails_form_labelName">Email ID</h3>
                  <Input
                    type="email"
                    onChange={(id, value) => {
                      setEmail(value);
                    }}
                    value={email}
                    customClass="basicDetails_form_inpBirthdayTag"
                    placeholder={`${
                      innerWidth <= 1023
                        ? `Enter your email ID`
                        : `Enter Email ID`
                    }`}
                  />
                  </>
                )}

                <p className="basicDetails_form_errTxt">{errorMsg}</p>
                {errorMsg !== "" && (
                  <h2
                    onClick={() => onLoginClick()}
                    className="basicDetails_form_errhandle"
                  >
                    Login
                  </h2>
                )}

                <div className="basicDetails_form_termsCondition">
                  <h2 className="basicDetails_form_termsCondition_label1">
                    I accept & agree
                  </h2>
                  <h2 className="basicDetails_form_termsCondition_label2">
                    Terms & conditions
                  </h2>
                </div>
              </div>
              <Button
                content="continue"
                onClick={() => validation()}
                disabled={
                  name === "" ||
                  email === "" ||
                  EmailValidation(email) === false 
                  // ||((date === "" || date === undefined) && true)
                }
                className={`${
                  name === "" ||
                  email === "" ||
                  EmailValidation(email) === false 
                  // ||
                  // date === "" ||
                  // date === undefined
                    ? "basicDetails_form_continueBtnDisabled"
                    : "basicDetails_form_continueBtn"
                }`}
                customClass={"customclass"}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="basicDetails">
            <div className="basicDetails_heading">
              {/* <img
                src={back}
                onClick={() => onNav("stage2")}
                alt="back"
                className="basicDetails_heading_img"
              /> */}
              <h2 className="basicDetails_heading_label">Basic Details</h2>
            </div>

            <div className="basicDetails_form">
              <h2 className="basicDetails_form_labelName">Name</h2>
              <Input
                type="text"
                onChange={(id, value) => {
                  setName(value);
                }}
                value={name}
                customClass="basicDetails_form_inpTag"
                placeholder={"E.g, John, Joe"}
              />
              <h2 className="basicDetails_form_labelName">Email ID</h2>
              <Input
                type="email"
                onChange={(id, value) => {
                  setEmail(value);
                }}
                value={email}
                customClass="basicDetails_form_inpBirthdayTag"
                placeholder={"Enter Email ID"}
              />
              <h3 className="basicDetails_form_greetTxt">
                We will be sending bills to this email
              </h3>

              {/* <p className="basicDetails_form_labelName">
              Date of Birth
              </p> */}
              {/* <DateTimePicker
                onChange={setDate}
                value={date}
                maxDate={new Date()}
                className="basicDetails_form_inpBirthdayTagDate"
                format="yyyy-MM-dd"
                placeholderText="Enter here"
              /> */}


                {/* <input
                type="date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                value={date}
                max={moment().format("YYYY-MM-DD")} 
                className="basicDetails_form_inpBirthdayTagDate"
                onKeyDown={(e) => {
                  e.preventDefault();
               }}

                customClass="basicDetails_form_inpBirthdayTag"
                placeholder={"Enter Email ID"}
              /> */}

              {/* <p className="basicDetails_form_greetTxt">
                Note: We are asking it to make your birthday special :){" "}
              </p> */}
              <p className="basicDetails_form_errTxt">{errorMsg}</p>
              {/* {errorMsg !== "" && (
                <p
                  onClick={() => onLoginClick()}
                  className="basicDetails_form_errhandle"
                >
                  Login
                </p>
              )} */}
              <Button
                content="continue"
                onClick={() => validation()}
                
                disabled={
                  name === "" ||
                  email === "" ||
                  EmailValidation(email) === false
                  //  ||
                  // ((date === "" || date === undefined) && true)
                }
                className={`${
                  name === "" ||
                  email === "" ||
                  EmailValidation(email) === false
                  //  ||
                  // date === "" ||
                  // date === undefined
                    ? "basicDetails_form_continueBtnDisabled"
                    : "basicDetails_form_continueBtn"
                }`}
              />
              <div className="basicDetails_form_termsCondition">
                <h4 className="basicDetails_form_termsCondition_label1">
                  By clicking on Continue you are agreeing our{" "}
                </h4>
                <h3 className="basicDetails_form_termsCondition_label2">
                  Terms & Condition
                </h3>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BasicDetails;
