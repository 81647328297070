import React, { useEffect, useState } from "react";
import PeopleCard from "../../components/people-card";
import ReferLoyaltyCard from "../../components/refer-loyalty-card";
import "./index.scss";
import { getLoyalityData } from "../../actions/loyalty";
import LoyaltyCard from "../../components/loyalty-card";
import { useHistory } from "react-router-dom";
import goback from "../../assets/images/goback.svg";
import ProfileCard from "../../components/profile-card";
import MenuSideBar from "../../containers/menuSideBar";
import { ROUTES } from "../../constants/app-constants";
import { Authentication, Logout } from "../../actions/authentication";
import { LocalStorage } from "../../utils/storage";
import Header from "../../containers/header";
import HeaderCommon from "../../components/header-common-component";
import AlertPopup from "../../components/alert-popup";
import { handleGetLoyaltyTypeImg } from "../../helpers/sharedFunction";
import { menuData } from "../../helpers/constants";
import { logOutIdentityBranch } from "../../utils/branch";

const Loyalty = (props) => {
  const { Dom, profileorder, routeName, customClassRoute } = props;
  const [pointData, setPointData] = useState({});
  const [history, setHistory] = useState([]);
  const [active1, setActive1] = useState();
  const [islog, setIsLog] = useState(false);

  useEffect(() => {
    getLoyalityData((res) => {
      if (res && res?.response?.status === 200) {
        setPointData(res?.content?.current_points);
        setHistory(res?.content?.history);
      }
    });
  }, []);

  const { innerWidth } = window;
  const History = useHistory();
  const handleGoBack = () => {
    History.goBack();
  };

  const onClickCategory = (itm) => {
    if (itm.category_name === "Logout") {

      setIsLog(true);
      return

      // Logout((re) => {
      //   if (re.response.status === 200) {
      //     LocalStorage.set("isLoggedIn", false);
      //     LocalStorage.set("UserData", {});
      //     LocalStorage.remove("CartProducts");
      //     Authentication((res) => {
      //       if (res.response.status === 200) {
      //         if (res.content) {
      //           LocalStorage.set("AccessToken", res.content.access_token);
      //           LocalStorage.set("RefreshToken", res.content.refresh_token);
      //           History.push(ROUTES.HOME);
      //         } else {
      //           History.push(ROUTES.HOME);
      //         }
      //       }
      //     });
      //   }
      // });
    }
    setActive1(itm.category_name);

    // if (innerWidth <= 1023) {
    History.push(itm.key);
    // }
  };
  const confirmLogout = () => {
    Logout((re) => {
      if (re.response.status === 200) {

        //branch event
        logOutIdentityBranch();

        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");

        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              History.push(ROUTES.HOME);
            } else {
              History.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  }

  return (
    <>
      {
        islog &&
        <AlertPopup
          title='Logout?'
          description='Are you sure you want logout?'
          okayClick={() => confirmLogout()}
          logout
          close={() => setIsLog(false)}
        />
      }
      {innerWidth <= 1023 ? (
        <>
          <div className="loyalty">
            <div className="loyalty_wholeWrapper">
              <img
                src={goback}
                className="loyalty_wholeWrapper_img"
                onClick={() => handleGoBack()}
                alt="back"
              />
              <h1 className="loyalty_nameLabel">Loyalty
                {pointData.guest_label === "Gold" &&
                  <> ( {pointData.guest_label} - <img src={handleGetLoyaltyTypeImg(pointData.guest_label)} /> )</>
                }
              </h1>
            </div>
            <LoyaltyCard data={pointData} />
            <p className="loyalty_subLabel">Loyalty History</p>
            {history !== 0 && <PeopleCard isLoyalty={true} data={history} />}
          </div>
        </>
      ) : (
        <>
          {Dom && (
            <>
              <HeaderCommon />
              <div className="loyalty_wholeSection">
                <div className="loyalty_wholeSection_one">
                  <ProfileCard />
                </div>
                <div className="loyalty_wholeSection_two">
                  <div className="loyalty_subSectionLeft">
                    <MenuSideBar
                      data={menuData}
                      active={active1}
                      onClickCategory={onClickCategory}
                      routeName={routeName}
                      customClassRoute={customClassRoute}
                    />
                    {/* {getMenuData()} */}
                  </div>
                  <div className="loyalty_subSectionRight">
                    <div className="loyalty">
                      <p className="loyalty_nameLabel">Loyalty {pointData.guest_label === "Gold" &&
                        <> ( {pointData.guest_label} - <img src={handleGetLoyaltyTypeImg(pointData.guest_label)} /> )</>
                      }</p>
                      <LoyaltyCard data={pointData} />
                      <p className="referEarn_subLabel">History</p>
                      {history !== 0 && (
                        <PeopleCard isLoyalty={true} data={history} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {profileorder && (
            <div className="loyalty">
              <h2 className="loyalty_nameLabel">Loyalty</h2>
              <LoyaltyCard data={pointData} />
              <h3 className="referEarn_subLabel">History</h3>
              {history !== 0 && <PeopleCard isLoyalty={true} data={history} />}
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Loyalty;
