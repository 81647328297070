import { fetchCall } from "../../utils/ajax";
import { API_CONSTANTS, API_METHODS } from "../../constants/api-constants";

export const OutletMapping = (payload, callback) => {
  fetchCall(API_CONSTANTS.OUTLET_MAPPING, API_METHODS.POST, payload, callback);
};

export const getMenuList = (url, callback) => {
  fetchCall(API_CONSTANTS.MENU_LIST + url, API_METHODS.GET, "", callback);
};

export const getSkipLocation = (callback) => {
  fetchCall(API_CONSTANTS.SKIP_LOCATION, API_METHODS.GET, "", callback);
};

export const getHomePage = (callback, params) => {
  fetchCall(
    `${API_CONSTANTS.HOME_PAGE_API}${params}`,
    API_METHODS.GET,
    "",
    callback
  );
};

export const getBlogDetail = (callback, params) => {
  fetchCall(
    `${API_CONSTANTS.BLOG_DETAIL}${params}`,
    API_METHODS.GET,
    "",
    callback
  );
};

export const handleSaveInterest = (payload, callback) => {
  fetchCall(API_CONSTANTS.ADD_STORE_INTEREST, API_METHODS.POST, payload, callback);
};

export const getSavedAddress = (callback) => {
  fetchCall(
    `${API_CONSTANTS.GET_SAVED_ADDRESS}`,
    API_METHODS.GET,
    "",
    callback
  );
};

export const getAddon = (payload, callback) => {
  fetchCall(API_CONSTANTS.GET_ADD_ON, API_METHODS.POST, payload, callback);
};

