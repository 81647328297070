import React, { useState, useEffect } from "react";
import "./index.scss";
import Logo from "../../assets/images/LOGO (4).png";
import coins from "../../assets/images/coin.png";
import arrow from "../../assets/images/right.svg";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import back from "../../assets/images/goback.svg";
import close from "../../assets/images/close-black-symbol.svg";
import WalletAddMoney from "../../components/walletAddMoney";
import {
  viewWalletBalance,
  showHistory,
  addWallet,
  walletApplyOffer,
  getWalletOffer,
  termsAndConditionsWallet,
} from "../../actions/wallet";
import { LocalStorage } from "../../utils/storage";
import WalletBanner from "../../components/walletBanner";
import Header from "../../containers/header";

const Termspopup = ({ tc, onclose }) => {
  return (
    <div className="Tc-modal-overlay">
      <div className="Tc-modal">
        <img src={close} className="wallet-history-close" onClick={onclose} />
        <div className="Tc-modal_heading">
          <h3>Terms and Conditions</h3>
        </div>
        <div
          className="TC-modal_content"
          dangerouslySetInnerHTML={{
            __html: tc,
          }}
        />
      </div>
    </div>
  );
};
const Wallet = () => {
  const [walletOfferList, setWalletOfferList] = useState();
  const [walletBalance, setWalletBalance] = useState();
  const [walletHistory, setWalletHistory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOfferModal, setIsOfferModal] = useState(false);
  const [isOfferApplied, setIsOfferApplied] = useState(false);
  const [isAddmoney, setAddmoney] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [tc, setTc] = useState([]);
  const [cashback, setCashback] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  const [activeSection, setActiveSection] = useState(null);
  const [activeOffer, setActiveOffer] = useState(null);
  const [selectedPredefinedAmount, setSelectedPredefinedAmount] = useState("");
  const [alertData, setAlertData] = useState("");
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [selectedtc, setSelectedtc] = useState("");
  const [isCustomAmountEntered, setIsCustomAmountEntered] = useState(false);
  const [activeContent, setActiveContent] = useState("wallet");
  const innerWidth = window.innerWidth;
  const history = useHistory();
  const giftcard = () => {
    history.push(ROUTES.GIFTCARD);
  };

  //Wallet-predefined-amount
  const addPredefinedAmount = (amount) => {
    setSelectedAmount(parseFloat(amount));
    setSelectedPredefinedAmount(amount);
  };

  //Wallet-Offer-Listing
  const walletOfferListing = () => {
    console.log(getWalletOffer, "walletOffer");
    getWalletOffer(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setWalletOfferList(res?.content?.offer_details);
        console.log(res.content, "walletList");
      }
    });
  };

  //Wallet-balance
  const showWalletBalance = () => {
    viewWalletBalance((res) => {
      if (res && res?.response?.status === 200) {
        setWalletBalance(res?.content?.balance);
      }
    });
  };

  //Wallet-History
  const showTransactionHistory = () => {
    showHistory((res) => {
      if (res && res?.response?.status === 200) {
        setWalletHistory(res.content.wallet_transactions);
      }
    });
  };
  // Wallet-Terms-and-Conditions
  const showTC = () => {
    termsAndConditionsWallet((res) => {
      if (res && res?.response?.status === 200) {
        const filteredTC = res?.content.terms_and_conditions.filter(
          (item) => item.filter_type === activeContent
        );
        setTc(filteredTC);
      }
    });
  };

  //Wallet-Add-Money
  const payload = {
    amount: parseFloat(selectedAmount),
    is_offer_apply: isOfferApplied,
    offer_minprice_id: activeOffer,
  };

  //Wallet-Offer-Apply
  const handleOfferButtonClick = (offerId) => {
    const isActiveOffer = activeOffer === offerId;
    const isOfferAlreadyApplied = isActiveOffer && isOfferApplied;

    if (!isOfferAlreadyApplied) {
      // Apply the offer
      const offerPayload = {
        amount: parseInt(selectedAmount),
        offer_id: offerId,
        is_offer_apply: true,
      };

      walletApplyOffer(offerPayload, (res) => {
        if (res && res.response.status === 200) {
          setIsOfferApplied(true);
          setActiveOffer(offerId);
          toggleOffer();
          setCashback(res?.content?.offer_details.cashback);
        } else {
          handleErrorMsg();
        }
      });
    } else {
      // Remove the offer
      const offerPayload = {
        amount: parseInt(selectedAmount),
        offer_id: offerId,
        is_offer_apply: false,
      };

      walletApplyOffer(offerPayload, (res) => {
        if (res && res.response.status === 200) {
          setIsOfferApplied(false);
          setActiveOffer(null);
        } else {
          handleErrorMsg();
        }
      });
    }
  };

  useEffect(() => {
    walletOfferListing();
    showWalletBalance();
    showTransactionHistory();
    showTC();
  }, []);
  useEffect(() => {
    // Watch for changes in selectedAmount and isOfferApplied
    if (
      isOfferApplied &&
      selectedAmount !== parseFloat(selectedPredefinedAmount)
    ) {
      // Remove the offer if amount is changed
      const offerPayload = {
        amount: parseInt(selectedAmount),
        offer_id: activeOffer,
        is_offer_apply: false,
      };

      walletApplyOffer(offerPayload, (res) => {
        if (res && res.response.status === 200) {
          setIsOfferApplied(false);
          setActiveOffer(null);
        } else {
          handleErrorMsg();
        }
      });
    }
  }, [selectedAmount, isOfferApplied, selectedPredefinedAmount, activeOffer]);
  //Handling-Buttons
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleOffer = () => {
    setIsOfferModal(!isOfferModal);
  };

  const handleErrorMsg = () => {
    setIsError(!iserror);
  };
  const handleHowItWorksClick = () => {
    console.log("How it Works clicked");
    setActiveContent("wallet_how_it_works");
    showTC();
  };

  const handleAllFAQsClick = () => {
    console.log("All FAQ's clicked");
    setActiveContent("wallet_faqs");
    showTC();
  };
  const handleAddMoneyClick = () => {
    if (selectedAmount == null) {
      setAlertData("Please enter the recharge amount");
    } else if (selectedAmount < 200) {
      setAlertData("Please Enter Minimum Amount ₹200");
    } else if (selectedAmount >= 20000) {
      setAlertData("Amount should be less than ₹20,000");
    } else {
      setAlertData(null);
      if (selectedAmount) {
        setAddmoney(!isAddmoney);
      }
    }
    // addMoneyWallet(selectedAmount);
    // addMoneyWallet(selectedAmount);
  };
  const handleGoBack = () => history.goBack();

  function formatDateWithMonthName(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    const day = date.getDate();
    let daySuffix = "th";

    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    }

    const monthNumber = date.getMonth() + 1;
    const monthName = getMonthName(monthNumber);

    return `${day}${daySuffix} ${monthName}, ${date.getFullYear()}`;
  }

  function getMonthName(monthNumber) {
    switch (monthNumber) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return "";
    }
  }

  const handleHistoryPopup = () => {
    setIsTransactionModalOpen(true);
  };
  const handleAmountChange = (e) => {
    const newAmount = parseFloat(e.target.value);
    setSelectedAmount(newAmount);
    setSelectedPredefinedAmount("");

    if (isOfferApplied) {
      const offerPayload = {
        amount: parseInt(selectedAmount),
        offer_id: activeOffer,
        is_offer_apply: false,
      };
      walletApplyOffer(offerPayload, (res) => {
        if (res && res.response.status === 200) {
          setIsOfferApplied(false);
          setActiveOffer(null);
          toggleOffer();
          setIsCustomAmountEntered(false);
        }
      });
    }
    setAlertData(null);
  };
  const handlePredefinedAmountClick = (amount) => {
    const newSelectedAmount = parseFloat(amount);
    setSelectedAmount(newSelectedAmount);
    setSelectedPredefinedAmount(amount);
    setAlertData(null);
    if (isOfferApplied) {
      setIsOfferApplied(false);
      setActiveOffer(null);
    }
  };
  return (
    <>
      <div className="wallet">
        <h2 className="wallet_head"> BBK Money</h2>
        <div className="wallet_container">
          <div className="wallet_div1">
            <div className="wallet_container_float">
              <div className="wallet_container_float_wallet-logo">
                <div className="wallet_container_float_logo">
                  <img
                    src={Logo}
                    alt="BBK Logo"
                    className="wallet_container_float_img"
                  />
                  <h4 className="wallet_container_float_text">BBK Money</h4>
                </div>
                <div className="wallet_container_float_coin-div">
                  <img
                    src={coins}
                    alt="coins"
                    className="wallet_container_float_coinImg"
                  />
                </div>
              </div>
              <div className="wallet_container_float_div-2">
                <div className="wallet_container_float_balance">
                  <div className="wallet_container_float_balance_text">
                    <h3>Your balance</h3>
                  </div>
                  <div className="wallet_container_float_balance_amt">
                    ₹ {walletBalance == 0 ? "0" : walletBalance}
                  </div>
                </div>

                <div
                  className="wallet_container_float_history"
                  onClick={handleHistoryPopup}
                >
                  <p>Transaction History</p>
                  <img
                    src={arrow}
                    alt="right-arrow"
                    className="wallet_container_float_history_img"
                  />
                </div>
              </div>
              {/* <div className="wallet_container_fixed"></div> */}
            </div>
            <div className="wallet_container_add-money">
              <h4 className="wallet_container_add-money_text">Add Money</h4>
              <div className="wallet_container_add-money_wrapper1">
                <input
                  placeholder="Enter Amount"
                  className="wallet_container_add-money_input"
                  type="number"
                  value={selectedAmount}
                  onChange={handleAmountChange}
                  min={200}
                  max={20000}
                />
                <button
                  className="wallet_container_add-money_button"
                  onClick={() => handleAddMoneyClick()}
                >
                  Add Money
                </button>
                {alertData && <p className="alerterrormsg">{alertData}</p>}
                <div className="wallet_container_add-money_amountadd-div">
                  <div
                    className={`wallet_container_add-money_amountadd ${
                      selectedPredefinedAmount === "500" ? "selected" : ""
                    }`}
                    onClick={() => handlePredefinedAmountClick("500")}
                  >
                    {"₹ 500"}
                  </div>
                  <div
                    className={`wallet_container_add-money_amountadd ${
                      selectedPredefinedAmount === "1000" ? "selected" : ""
                    }`}
                    onClick={() => handlePredefinedAmountClick("1000")}
                  >
                    {"₹ 1000"}
                  </div>
                  <div
                    className={`wallet_container_add-money_amountadd ${
                      selectedPredefinedAmount === "1500" ? "selected" : ""
                    }`}
                    onClick={() => handlePredefinedAmountClick("1500")}
                  >
                    {"₹ 1500"}
                  </div>
                  <div
                    className={`wallet_container_add-money_amountadd ${
                      selectedPredefinedAmount === "2000" ? "selected" : ""
                    }`}
                    onClick={() => handlePredefinedAmountClick("2000")}
                  >
                    {"₹ 2000"}
                  </div>
                </div>
              </div>
            </div>
            {walletOfferList && walletOfferList.length > 0 ? (
              <>
                <div className="wallet_container_offer-div">
                  <p className="wallet_container_offer_text">Offers</p>
                  {walletOfferList &&
                    walletOfferList.map((getWalletOffer) => (
                      <div
                        className={`wallet_container_offer_card ${
                          isOfferApplied && activeOffer === getWalletOffer.id
                            ? "applied-offer"
                            : ""
                        }`}
                      >
                        <div className="wallet_container_offer_des-wrapper">
                          <div className="wallet_container_offer_title">
                            {getWalletOffer?.offer_name}
                          </div>
                          <div className="wallet_container_offer_des">
                            {getWalletOffer?.offer_description}
                          </div>
                          {getWalletOffer && (
                            <p
                              className="wallet_container_offer_tc"
                              onClick={() => {
                                setSelectedtc(getWalletOffer.offer_tc);

                                setIsModalOpen(true);
                              }}
                            >
                              Terms and conditions Apply
                            </p>
                          )}
                        </div>
                        {getWalletOffer && (
                          <div className="wallet_container_offer_button-wrapper">
                            <p
                              className={`wallet_container_offer_button ${
                                isOfferApplied &&
                                activeOffer === getWalletOffer.id
                                  ? "remove"
                                  : "apply"
                              }`}
                              onClick={() =>
                                handleOfferButtonClick(getWalletOffer.id)
                              }
                            >
                              {isOfferApplied &&
                              activeOffer === getWalletOffer.id
                                ? "REMOVE"
                                : "APPLY"}
                            </p>
                            {isOfferModal && (
                              <div className="offerModal-overlay">
                                <div
                                  className="offerModal-content"
                                  onClick={toggleOffer}
                                >
                                  <div className="offerModal">
                                    <div className="offerModal_top">
                                      <p className="offerModal_heading">
                                        OFFER APPLIED
                                      </p>
                                      <p className="offerModal_text">
                                        Applicable on recharge of Rs{" "}
                                        {selectedAmount}
                                      </p>
                                    </div>
                                    <span className="offerModal_line"></span>
                                    <div className="offerModal_bottom">
                                      <span className="offerModal_button">
                                        Woohoo! Thanks
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {iserror && (
                              <div className="offerModal-overlay">
                                <div
                                  className="offerModal"
                                  onClick={handleErrorMsg}
                                >
                                  <p>
                                    {" "}
                                    Offer not applicable on selected amount{" "}
                                  </p>
                                  <br />
                                  <p> Please check Terms and Conditions </p>
                                  <span className="offerModal_OKBTN">Ok</span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </>
            ) : null}
          </div>
          <div className="wallet_container_div2">
            <div className="wallet_container_div2_link-wrapper">
              <div className="wallet_container_div2_link">
                <p
                  className="wallet_container_div2_ltext1"
                  onClick={() => giftcard()}
                >
                  {"  Add Gift Card"}
                </p>
                <p
                  className={`wallet_container_div2_ltext2 ${
                    activeSection === "how_it_works" ? "act" : ""
                  }`}
                  onClick={handleHowItWorksClick}
                >
                  {" How it works"}
                </p>
                <p
                  className={`wallet_container_div2_ltext3 ${
                    activeSection === "all_faqs" ? "act" : ""
                  }`}
                  onClick={handleAllFAQsClick}
                >
                  {"All FAQ's"}
                </p>
              </div>
            </div>
            <div>
              {tc && tc.length > 0 && (
                <div
                  className="popup-tc"
                  dangerouslySetInnerHTML={{
                    __html: tc[0].html_content,
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {isModalOpen && (
          <Termspopup tc={selectedtc} onclose={() => toggleModal()} />
        )}

        {isAddmoney && (
          <WalletAddMoney
            cashback={cashback}
            close={handleAddMoneyClick}
            tc={tc}
            isOfferApplied={isOfferApplied}
            selectedOfferId={activeOffer}
            walletOfferList={walletOfferList}
            amount={selectedAmount}
            walletBalance={walletBalance}
            handleHistoryPopup={handleHistoryPopup}
          />
        )}
        {isTransactionModalOpen && (
          <div className="wallet-history-modal-overlay">
            <div className="modal-wallet-history">
              <div className="modal-wallet-history_head">
                <img
                  src={close}
                  className="wallet-history-close"
                  onClick={() => setIsTransactionModalOpen(false)}
                />
                <h3 className="wallet-history_head">Transaction History</h3>
              </div>
              <span className="wallet-histroy-balance">
                <p>Your Balance</p>
                <p className="wallet-history-balance-amt">
                  ₹ {walletBalance == 0 ? "0" : walletBalance}
                </p>
              </span>

              {walletHistory.map((transaction) => (
                <div key={transaction.txn_id} className="wallet-history">
                  <div className="wallet-history_div-1">
                    <span className="wallet-history_type">
                      {transaction.pay_type}
                    </span>
                    <span
                      className={`wallet-history_amount ${
                        transaction.credit > 0 ? "green-text" : "red-text"
                      }`}
                    >
                      {transaction.credit > 0
                        ? `+${transaction.credit}`
                        : `-${transaction.debit}`}
                    </span>
                  </div>
                  <div className="wallet-history_div-2">
                    <span className="wallet-history_id">
                      ID: {transaction.txn_id}
                    </span>
                    <span className="wallet-history_create">
                      {formatDateWithMonthName(transaction.created_at)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Wallet;
