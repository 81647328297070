import React from 'react';
import ComponentSlider from '@kapost/react-component-slider';
import './index.scss';
import Left from '../../assets/images/left.svg';
import Right from '../../assets/images/right.svg';

const HorizontalScroll = (props) => {
  const { contentData, arrowPosition, customClass } = props;

  // const renderLeftArrow = () => (
  //   <div
  //     className={`htl-scroll__arrow htl-scroll-left ${
  //       arrowPosition && `htl-scroll__arrow_${arrowPosition}`
  //     }`}
  //   >
  //     <img src={Left} alt='arrow' width='12' height='12' />
  //   </div>
  // );
  // const renderRightArrow = () => (
  //   <div
  //     className={`htl-scroll__arrow htl-scroll-left ${
  //       arrowPosition && `htl-scroll__arrow_${arrowPosition}`
  //     }`}
  //   >
  //     <img src={Right} alt='arrow' width='12' height='12'   />
  //   </div>
  // );
  // const renderRightArrow: () => <span>&rarr;</span>

  const renderLeftArrow = () =>
  {
    return (
      <img src={Left} alt='arrow' width='12' height='12' className= 'arrowLeft' />
    )
  }
  const renderRightArrow = () =>
  {
    return (
      <img src={Right} alt='arrow' width='12' height='12' className='arrowRight'  />
    )
  }

  return (
    <>
      <div
        className={`htl_scroll ${
          arrowPosition && `htl_scroll_${arrowPosition}`
        } ${customClass && customClass}`}
      >
        <ComponentSlider
          renderLeftArrow={renderLeftArrow}
          renderRightArrow={renderRightArrow}
        >
          {contentData}
        </ComponentSlider>
      </div>
    </>
  );
};

export default HorizontalScroll;
