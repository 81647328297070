import React, { useState } from "react";
import "./index.scss";
import Button from "../button";
import Modal from "../modal";
import ProductSummary from "../product-summary";
import goBack from "../../assets/images/close.svg";
import { LocalStorage } from "../../utils/storage";
const RepeatCustomizationPopup = (props) => {
  const { addonPopup, customizeData, onUpdateContent, addNewProduct, handleClose, repeatCustom, } = props;

  // console.log("repeatcustomizationpopup", customizeData);
  // const repeatCustom = (item) => {
  //   //  
  //   item.addons &&
  //     item.addons.length > 0 &&
  //     item.addons.map((i) => {
  //       i.item_count = parseInt(i.item_count) + 1;
  //     });
  //   LocalStorage.set("addId", item._id);
  //   item.count = parseInt(item.count) + 1;
  //   item._id = item._id;
  //   onUpdateContent(item);
  //   addonPopup(item);
  // };
  const { innerWidth } = window;

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <Modal
            modalCustomClass="modalAddon"
            onCloseHandler={() => handleClose()}
            hideClose
            contentCustomClass="modalAddon_contentCustomClass"
          >
            <div className="repeatcustomization">
              <div className="repeatcustomization_wholeWrapper">
                <h3 className="repeatcustomization_nameLabel">
                  Repeat last used customization?
                </h3>
                <img
                  src={goBack}
                  className="repeatcustomization_closeImg"
                  onClick={() => handleClose()}
                  alt="goBack"
                />
              </div>
              <ProductSummary data={customizeData} isCustomize={false} />
              <div className="repeatcustomization_btnDiv">
                <Button
                  content="Repeat Last"
                  className="repeatcustomization_btnDiv_button"
                  onClick={() => repeatCustom(customizeData)}
                  customClass="repeatcustomization_btnDiv_content"
                />
                <Button
                  content="Add New"
                  className="repeatcustomization_btnDiv_button1"
                  customClass="repeatcustomization_btnDiv_content"
                  onClick={() => addNewProduct(customizeData)}
                />
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <>
          <Modal
            modalCustomClass="modalAddon"
            onCloseHandler={() => handleClose()}
            isMobileFullLayout
          >
            <div className="repeatcustomization">
              <h2 className="repeatcustomization_nameLabel">
                Repeat last used customization?
              </h2>
              <ProductSummary data={customizeData} isCustomize={false} />
              <div className="repeatcustomization_btnDiv">
                <Button
                  content="Repeat Last"
                  className="repeatcustomization_btnDiv_button"
                  onClick={() => repeatCustom(customizeData)}
                />
                <Button
                  content="Add New"
                  onClick={() => addNewProduct(customizeData)}
                  className="repeatcustomization_btnDiv_button1"
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default RepeatCustomizationPopup;
