import moment from 'moment';
import { LocalStorage } from '../utils/storage';
import { handleParseAmount } from './sharedFunction';

const locationURL = window.location.href;
const userData = LocalStorage.get('UserData');
const userMobile = userData && userData.mobile ? `91${userData.mobile}` : '';
const userName = userData && userData.name;
const userMail = userData && userData.email;
const outletDetails = LocalStorage.get('Outlet_Details');
const outletId = outletDetails && outletDetails.outlets_id;
const locationDetails = LocalStorage.get("location");

export const loginNetCoreEvent = async (payload) => {
  try {
    await window.smartech(
      'contact',
      '5',
      {
        'pk^mobile': payload.mobile,
      },
      () => {
        window.smartech('identify', payload.mobile);
        window.smartech('dispatch', 'Exiting User Login', {
          logindate: payload.date,
          logintype: 'Web',
        });
      }
    );

  } catch (error) {
    
  }
};

export function signUpNetCoreEvent(payload) {

  try {
    window.smartech(
      'contact',
      '4',
      {
        'pk^mobile': payload.mobile
      },
      () => {
        window.smartech('identify', payload.mobile);
        window.smartech('dispatch', 'New User Registration', {
          Source: 'Web',
          email: payload.email,
          NAME: payload.name,
          SIGNUPDATE: payload.createdAt
        });
      }
    );
  } catch (error) {
    
  }
 
}

export function categoryClickNetCoreEvent(payload) {
  try {
  window.smartech('identify', payload.mobile);
  window.smartech('dispatch', 'Category Viewed', {
    category_name: payload.category_name,
    category_type: 'Food',
    // category_id: payload.category_id,
    pageurl: locationURL,
  });
    
  } catch (error) {
    
  }
  
}

const isAddonPresent =(item)=>{
  let isAddonAvailable  = false;
  item?.groups &&
  item?.groups.map((grp) => {
    if (grp.items && grp.items.length > 0) {
      return isAddonAvailable = true;
    }
  });
  return isAddonAvailable;
}

export function viewProductNetCoreEvent(data) {
  try {
    window.smartech('identify', userMobile);
  window.smartech('dispatch', 'Product View', {
    outlet_id: outletId,
    currency: 'INR',
    prid: data.item_id,
    name: data.item_name,
    price: handleParseAmount(data.item_final_price),

    prqt: parseInt(data.count),
    is_extra_spicy :'false',
    is_addon : isAddonPresent(data) ? "true" :'false',
    cart_added_for : locationDetails.orderFor,
    is_adding : 'true',
    is_combo :'true',
    // entity_id :'',
    is_customise : isAddonPresent(data) ? "true" :'false',


    img_url: data.item_image_url,
    produrl: data.item_image_url,
    category_name: data.categoryName,
    outletname: outletDetails.name,
    outletcity :outletDetails?.outlet__city,
    // item_tags: data.item_tags[0] === '1' ? 1 : 2,
    // item_short_description: data.item_short_description,
    // item_long_description: data.item_long_description,
    // item_strike_through_price: parseInt(data.item_strike_through_price),
    // item_is_active: parseInt(data.item_is_active),
    // item_is_default: parseInt(data.item_is_default),
    // serve_for: parseInt(data.serve_for),
    // preparation_time: parseInt(data.preparation_time),
    // no_of_times_ordered: parseInt(data.no_of_times_ordered),
    // item_is_recommended: parseInt(data.item_is_recommended),
    // video_link: data.video_link,
    // count: data.count,
  });
  } catch (error) {
    
  }
}

export function addToCartNetCoreEvent(value) {
  try {
    window.smartech('identify', userMobile);
    window.smartech('dispatch', 'Add To Cart', {
      outlet_id: outletId,
      img_url: value.item_image_url,
      prid: value.item_id,
      produrl: value.item_image_url,
      curreny: 'INR',
      name: value.item_name,
      price: Number(parseFloat(value.item_final_price).toFixed(2)),
      prqt: parseInt(value.count),
      // is_in_stock: 'YES',
  
      is_extra_spicy :'false',
      is_addon : isAddonPresent(value) ? "true" :'false',
      cart_added_for : locationDetails.orderFor,
      is_adding : 'true',
      is_combo :'true',
      // entity_id :'',
      is_customise : isAddonPresent(value) ? "true" :'false',
      outletname: outletDetails.name,
      outletcity :outletDetails?.outlet__city,
      category_name: value.categoryName,
    });
    
  } catch (error) {
    // console.log('error',error)
  }
}

export async function checkoutNetCoreEvent(payload) {
  try {
    await window.smartech('identify', userMobile);
    await window.smartech('dispatch', 'Checkout', {
      totalamount: handleParseAmount(payload.totalamount),
      subtotal : handleParseAmount(payload.subtotal),
      tax : payload.tax,
      coupon_code: payload.coupon_code,
      currency: payload.currency,
      discount: handleParseAmount(payload.discount),
      usertype: payload.usertype,
      total_items: payload.total_items,
      address1: payload.address1,
      address2: payload.address2,
      paymentmode: payload.paymentmode,
      is_loyalty_discount: payload.is_loyalty_discount,
      cooking_instruction: payload.cooking_instruction,
      donation: handleParseAmount(payload.donation),
      outletname: outletDetails.name,
      outletcity :outletDetails?.outlet__city,
    });
    
  } catch (error) {
    
  }
 
}

export async function payNowNetCoreEvent(payload) {
  try {
    await window.smartech(
      'contact',
      'list id',
      {
        'pk^mobile': userMobile,
        NAME: userName,
        email: userMail,
        mobile: userMobile,
      },
      () => {
        window.smartech('identify', userMobile);
        window.smartech('dispatch', 'Pay Now', {
          totalamount: Number(payload.totalamount),
          subtotal: Number(payload.subtotal),
          discount: Number(payload.discount),
          currency: 'INR',
          paymentmode: payload.paymentmode,
          pageurl: locationURL,
        });
      }
    );
    
  } catch (error) {
    
  }
 
}

export function couponAppliedNetCoreEvent(payload) {
  try {

    window.smartech('identify', userMobile);
    window.smartech('dispatch', 'Coupon Applied', {
      couponcode: payload.couponcode,
      coupon_discount: payload.coupon_discount,
      coupon_status: payload.coupon_status,
      coupon_error: payload.coupon_error,
      pageurl: locationURL,
    });
    
  } catch (error) {
    
  }
 
}

export function productPurchaseNetCoreEvent(payload) {
 
  const cartDataValue = payload.items.map((value) => {
    const item = {
      category_name: value.categoryName,
      prid: value.item_id,
      prqt: value.count,
      is_veg: value.is_veg === "true" ? "true" : "false",
      image: value.item_image_url,
      produrl: value.item_image_url,
      price: value.single_item_price
      ? Number(parseFloat(value.single_item_price).toFixed(2)) 
      :Number(parseFloat(value.item_final_price).toFixed(2)),
      name: value.item_name,
      discount: 0,
      addons: LocalStorage.get("Addons") && LocalStorage.get("Addons"),
    };
    return item;
  });

  try {
    window.smartech('identify', userMobile);
    window.smartech('dispatch', 'Product Purchase', {
      totalamount: Number(payload.totalamount),
      subtotal: Number(payload.subtotal),
      tax: Number(payload.tax) + Number(payload.tax),
      oultet_id: outletId,
      currency: 'INR',
      discount: handleParseAmount(payload.discount),
      usertype: 'New',
      total_items: handleParseAmount(payload.total_items),
      items: cartDataValue,
      coupon_code: payload.coupon_code,
      outletname: outletDetails.name,
      outletcity :outletDetails?.outlet__city,
    });
    
  } catch (error) {
    
  }
}

export function checkoutAddressNetCoreEvent(payload) {
  try {

    window.smartech('identify', userMobile);
  window.smartech('dispatch', 'Checkout Address', {
    address: payload.address,
    pageurl: locationURL,
  });
    
  } catch (error) {
    
  }
  
}

export function paymentFailureNetCoreEvent(payload) {
  try {
    window.smartech('identify', userMobile);
  window.smartech('dispatch', 'Payment Failed', {
    payment_status: 'failed',
    payment_type: payload.payment_type,
    payment_for: payload.payment_for,
    pageurl: locationURL,
  });
    
  } catch (error) {
    
  }
  
}

export function netCoreOrderTypeSelection(){
  const leterDate =  locationDetails.date ?
   moment(locationDetails.date).format('DD-MM-YYYY') :"";
   const laterTime = locationDetails.time ? handleParseAmount(locationDetails.time)
   : '';

   try {
     
    window.smartech("identify", userMobile);
  window.smartech("dispatch", "Order Type", {
    orderType: locationDetails.orderFor,
    deliverylocation :locationDetails.address,
    outletname: outletDetails.name,
    order_delivery_type :locationDetails.orderTime,
    order_later_date : leterDate,
    order_later_time : laterTime,
    pageurl: locationURL
  });
   } catch (error) {
     
   }
  
}

export function newsLetterSubsribeNetCoreEvent(payload) {
  const dob = userData.dob ? userData.dob:''

  try {
    window.smartech(
      'contact',
      '2',
      {
        'pk^mobile': userMobile,
      },
      () => {
        window.smartech('identify', userMobile);
        window.smartech('dispatch', 'Newsletter Subscription', {
          usertype: payload.usertype === 'subscribe' ? 'true' : 'false',
          email: userMail,
          DOB : dob
        });
      }
    );
    
  } catch (error) {
    
  }
  
}

