import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';

export const offers = (payload, callback) => {
  fetchCall(API_CONSTANTS.OFFERS, API_METHODS.POST, payload, callback);
};

export const applyOffers = (payload, callback) => {
  fetchCall(API_CONSTANTS.APPLY_OFFER, API_METHODS.POST, payload, callback);
};

export const applyLoyalty = (callback) => {
  fetchCall(API_CONSTANTS.APPLY_LOYALTY, API_METHODS.GET, '', callback);
};

export const applyNewLoyalty = (payload, callback) => {
  fetchCall(API_CONSTANTS.NEW_LOYALTY, API_METHODS.POST, payload, callback);
};
