import React from "react";
import "./index.scss";
import moment from "moment";
import { handleConvertToIST } from "../../helpers/sharedFunction";
const PastOrderDetails = (props) => {
  const { data } = props;
  const bill = [
    {
      name: "Order Number",
      val: data.divum_order_id,
    },
    {
      name: "Date",
      val: moment(handleConvertToIST(data.created_at)).format("MMMM Do YYYY, h:mma"),
    },
    {
      name: "Delivery Address",
      val: <>
      {`${ 
        data?.delivery_address?.AddressType ?
        data?.delivery_address?.AddressType + ',':''}
        ${data?.delivery_address.address1} 
       `} 
        {<br />}
        {data?.delivery_address.address2}
        </>

    },
  ];

  const { innerWidth } = window;

  return (
    <div className="orderdata">
      {innerWidth <= 1023 ? (
        <h1 className="orderdata_nameLabel">Order Detail</h1>
      ) : (
        <h1 className="orderdata_nameLabel">Order Details</h1>
      )}

      <div className="orderdata_items">
        {bill.map((item) => {
          return (
            <>
              {innerWidth <= 1023 ? (
                <div className="orderdata_items_item">
                  <p className="orderdata_items_item_one">{item.name} : </p>
                  <p className="orderdata_items_item_two">{item.val}</p>
                </div>
              ) : (
                <div className="orderdata_items_item">
                  <p className="orderdata_items_item_one">{item.name}</p>
                  <p className="orderdata_items_item_two">{item.val}</p>
                </div>
              )}
            </>
          );
        })}
      </div>
      {innerWidth >= 1024 && <div className="orderdata_divider" />}
      <div
        className={`${
          innerWidth <= 1023 ? `orderdata_itemss` : `orderdata_items`
        }`}
      >
        <div
          className={`${
            innerWidth <= 1023
              ? `orderdata_itemss_item1`
              : `orderdata_items_item1`
          }`}
        >
          <p
            className={`${
              innerWidth <= 1023
                ? `orderdata_itemss_item1_one`
                : `orderdata_items_item1_one`
            }`}
          >
            Paid via :
          </p>
          <p
            className={`${
              innerWidth <= 1023
                ? `orderdata_itemss_item1_two`
                : `orderdata_items_item1_two`
            }`}
          >
            Cash
          </p>
        </div>
      </div>
    </div>
  );
};
export default PastOrderDetails;
