import React from "react";
import "./index.scss";
import referUser from "../../assets/images/referUser.svg";
import Button from "../button";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
const LoyaltyCard = (props) => {
  const { data } = props;
  const history = useHistory();
  const { innerWidth } = window;
  const formatDate = (inputDate) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  };
  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="loyalty">
            <div className="loyalty_one">
              <h2 className="loyalty_one_nameLabel">Current loyalty point</h2>
              <p className="loyalty_one_subLabel">
                {data && data?.CurrentPoints} points
              </p>
            </div>
          </div>
          <div className="loyaltyexp">
            {data &&
              data?.CurrentPoints > 0 &&
              data?.LoyaltyExpiryDate &&
              (new Date(data?.LoyaltyExpiryDate) < new Date()
                ? `Loyalty Points Expired on ${formatDate(
                    data?.LoyaltyExpiryDate
                  )}`
                : data?.LoyaltyExpiryDate &&
                  `Your loyalty points will expire on ${formatDate(
                    data?.LoyaltyExpiryDate
                  )}`)}
          </div>
          {/* <div className="loyaltyexp">Your loyalty points will get expire on {formatDate(data?.LoyaltyExpiryDate)}</div> */}
          <div className="loyalty_two">
            <div className="loyalty_two_first">
              <h1 className="loyalty_two_first_nameLabel">
                Do you want to know more about the loyalty program?
              </h1>
            </div>
            <Button
              onClick={() => history.push("/more-info/loyalty")}
              className="loyalty_two_second"
              content="Explore"
              customClass="loyalty_two_second_content"
            />
          </div>
        </>
      ) : (
        <>
          <div className="referLoyalty">
            <div className="referLoyalty_one">
              <h1 className="referLoyalty_one_nameLabel">
                Current Loyalty Points
              </h1>
              <p className="referLoyalty_one_subLabel">
                {data && data?.CurrentPoints} points
              </p>
            </div>
            <div className="referLoyalty_divider" />
            <div className="referLoyalty_two">
              <div className="referLoyalty_two_first">
                <h2 className="referLoyalty_two_first_nameLabel">
                  Do you want to know more about the loyalty program?
                </h2>
                {/* <div className='referLoyalty_two_first_imgDiv'>
            <img
              src={referUser}
              className='referLoyalty_two_first_imgDiv_img'
            />
            <p className='referLoyalty_two_first_imgDiv_label'>
              {data && data?.total_number_of_user_referred}
            </p>
          </div> */}
              </div>
              <Button
                onClick={() => history.push("/more-info/loyalty")}
                className="referLoyalty_two_second"
                content="Explore now"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LoyaltyCard;
