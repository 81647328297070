import React, { useState } from "react";
import back from "../../assets/images/back.svg";
import goback from "../../assets/images/goback.svg";
import closeImg from "../../assets/images/group.svg";
import "./index.scss";
import Modal from "../../components/modal";
import CartCard from "../../components/cart-cards";
import { Analytics } from "../../helpers/analytics";

const Cart = (props) => {
  const { cartData, cartData2, onApply, close, ShowParticular, closeParticular } = props;
  const [cartOfferPopup, setCartOfferPopUp] = useState(true);
  const [name, setName] = useState("");
  const [alert, setAlert] = useState("");

  const handleOnClick = () => {
    setCartOfferPopUp(false);
  };

  const onApplyOffer = (item) => {
    Analytics.applyOfferEvent(item)
    onApply(item);
    setCartOfferPopUp(false);
  };


  const onCouponChange = (e) => {
    setName(e.target.value);
  };

  const onApplyData = () => {
    if (name !== "") {
      const itm = { coupon_code: name };
      Analytics.applyOfferEvent(itm)
      onApplyOffer(itm);
      setAlert("");
    } else {
      setAlert("Please enter a valid coupon code");
    }
  };

  const { innerWidth } = window;

  return (
    <>
      {cartOfferPopup && (
        <>
          {innerWidth <= 1023 ? (
            <Modal
              modalCustomClass="cartPopUp_modal"
              contentCustomClass="cartPopUp_contentCustomClass"
              hideClose
            >
              <div className="cartPopUp">
                <div className="cartPopUp_header">
                  <img
                    src={goback}
                    className="cartPopUp_header_image"
                    onClick={close}
                    alt="goback"
                  ></img>
                  <div className="cartPopUp_header_wrapper">
                    <h1 className="cartPopUp_header_wrapper_data">Offer</h1>
                  </div>
                </div>
                <div className="cartPopUp_section">
                  <input
                    type="text"
                    placeholder="Enter Coupon Code"
                    className="cartPopUp_section_textfield"
                    onChange={(e) => onCouponChange(e)}
                    value={name}
                  ></input>
                  <button
                    className="cartPopUp_section_button"
                    onClick={onApplyData}
                  >
                    Apply
                  </button>
                </div>
                {alert && <p className="cartPopUp_alert">{alert}</p>}
                <div className="cartPopUp_body">
                  <h2 className="cartPopUp_body_heading">Available Coupons</h2>
                </div>
                {cartData.length > 0 ? (
                  <div>
                    <CartCard couponData={cartData}
                    closeParticular={closeParticular}
                    ShowParticular={ShowParticular}
                    onApply={onApplyOffer} />
                  </div>
                ) : (
                  <div className="cartPopUp_coupons">
                    <p className="cartPopUp_coupons_phrase">
                      Sorry, No Available Coupons Available !
                    </p>
                  </div>
                )}
              </div>
            </Modal>
          ) : (
            <Modal hideClose>
              <div className="cartPopUp">
                <div className="cartPopUp_header">
                  <img
                    src={back}
                    className="cartPopUp_header_image"
                    onClick={() => close()}
                    alt="goback"
                    ></img>
                  <div className="cartPopUp_header_wrapper">
                    <h1 className="cartPopUp_header_wrapper_data">Offers</h1>
                    <img
                      src={closeImg}
                      className="cartPopUp_header_wrapper_close"
                      onClick={() => close()}
                      alt="closeImg"
                    ></img>
                  </div>
                </div>
                <div className="cartPopUp_section">
                  <input
                    type="text"
                    placeholder="Enter Coupon Code"
                    className="cartPopUp_section_textfield"
                    onChange={(e) => onCouponChange(e)}
                    value={name}
                  ></input>
                  <button
                    className="cartPopUp_section_button"
                    onClick={onApplyData}
                  >
                    Apply
                  </button>
                </div>
                {alert && <p className="cartPopUp_alert">{alert}</p>}
                <div className="cartPopUp_body">
                  {/* <p className="cartPopUp_body_heading">Available Coupons</p> */}
                </div>
                {cartData.length > 0 ? (
                  <div>
                    <CartCard
                    closeParticular={closeParticular}
                    ShowParticular={ShowParticular}
                    couponData={cartData} onApply={onApplyOffer} />
                  </div>
                ) : (
                  <div className="cartPopUp_coupons">
                    <p className="cartPopUp_coupons_phrase">
                      {/* Sorry, No Available Coupons Available ! */}
                    </p>
                  </div>
                )}
                <div className="cartPopUp_body">
                  {/* <p className="cartPopUp_body_heading">Un Available Coupons</p> */}
                </div>
                {cartData2?.length > 0 && (
                  <div>
                    <CartCard
                    closeParticular={closeParticular}
                    ShowParticular={ShowParticular}
                    couponData={cartData2} onApply={onApplyOffer} />
                  </div>
                ) 
                // : 
                // (
                //   <div className="cartPopUp_coupons">
                //     <p className="cartPopUp_coupons_phrase">
                //       No Unavailable Coupons Available !
                //     </p>
                //   </div>
                // )
                }
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default Cart;
