import React, { createRef, useState } from 'react';
import './index.scss';
import star from "../../assets/images/star.png"
import { handleConvertToIST, handleGetDeliveryTypeText, handleParseAmount } from "../../helpers/sharedFunction";
import { ROUTES } from '../../constants/app-constants';
import { useHistory } from "react-router-dom";
import { LocalStorage } from '../../utils/storage';
import moment from "moment";
import { addToCart, getCartItems } from '../../actions/cart';
import { getInvoice } from '../../actions/orders';
import crossIcon from '../../assets/images/close.svg';
import bbk from '../../assets/images/bbkmain_logo.png';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import Toaster from '../toaster';

const PastOrderHistoyCard = ({ current_orders }) => {
  console.log("current_orders past: ", current_orders)
  const orderCreated = current_orders?.order_created_at;
  const order_id = current_orders?.divum_order_id;
  const Payment = current_orders?.mode_of_payment;
  const [showpdf, setShowPdf] = useState(false);
  const [pdfContent, setPdfContent] = useState();
  const imgRef = createRef();
  const history = useHistory();


  const [toaster, setToaster] = useState({
    isShow: false
  })


  const dataShow = () => {
    LocalStorage.set("pastOrder", current_orders);
    LocalStorage.set("pastPurchaseDetailId", current_orders?._id);
    history.push(ROUTES.PASTORDERS);
  };
  const getInvoicePdf = () => {
    let payload = {
      order_id: order_id
    }
    getInvoice(payload, (res) => {
      if (res.response.status === 200) {
        setShowPdf(true)
        setPdfContent(res.content);

      }
    })
  }

  const savePDF = () => {
    imgRef.current.querySelector('thead img').src = bbk;
    const input = imgRef.current;
    imgRef.current.querySelector('thead img').onload = () => {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('sample.pdf');
      })
    }
  }

  const handleItemPrice = (item) => {
    let amount = handleParseAmount(item.final_price) /
      handleParseAmount(item.initial_item_count)
    return amount
  }
  const onAddToCart = () => {
    const outletData = LocalStorage.get("Outlet_Details");
    const location = LocalStorage.get("location");
    const outletId = LocalStorage.get("outlet_id");
    const user = LocalStorage.get("UserData");
    // debugger;

    let payload = {};
    let cartItem;
    let cartItems = [];
    let addonsData;

    let value = current_orders?.cart_items[0];
    console.log(value, "current_orders past value");
    let addonItems = [];
    value.addons &&
      value.addons.length > 0 &&
      value.addons.map((i) => {
        addonsData = {
          item_id: i.item_id,
          item_name: i.item_name,
          group_id: i.category_id,
          item_count: parseInt(i.item_count),
          final_price: i.price,
          group_name: i.category_name,
          discount: 1,
          image_url: i.image_url,
          item_tags: i.item_tags,
        };
        addonItems.push(addonsData);
      });

    cartItem = {
      // _id: LocalStorage.get('addId'),
      item_id: value.item_id,
      item_count: value.item_count,
      is_veg: value.is_veg,
      image_url: value.image_url,
      price: handleItemPrice(value),
      final_price: handleItemPrice(value),
      name: value.name,
      discount: value.discount,
      item_tags: value.item_tags,
      addons: addonItems,
      local_id: parseInt(value.local_id),
      selected_addons: value.selected_addons,
    };
    cartItems.push(cartItem);
    console.log(cartItem, cartItems, "cartItem");
    const distance=LocalStorage.get('userDistance')
    payload = {
      channel: location.orderFor,
      outlet_id: outletId,
      delivery_area_id: outletData.delivery_area_id,
      items: cartItems,
      source: outletData.outlet_status_info,
      user_distance:distance,
    };
    console.log("here is payload", payload);
    addToCart(payload, (res) => {
      if (res && res?.response?.status === 200) {
        getCartItems(outletId, (res) => {
          if (res && res?.response?.status === 200) {
            history.push(ROUTES.CHECKOUT);
          }
        });
      }
      else {
        setToaster({
          isShow: true,
          toastText: res.response.message
        })
      }
    });
  };
  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };
  return (
    <>
      <div className='pastOrderHistory'>
        <div className='pastOrderHistoryTop'>
          <div className='pastOrderHistoryTopDetails'>
            <div className='orderDetails'>
              <span className='orderID'>Order ID: <span className='bold-text'>{order_id}</span></span>
              <span className=''>Order On :{moment(handleConvertToIST(orderCreated)).format(
                "MMMM Do YYYY, h:mma"
              )} </span>
              <span className='paymentMode'>Payment Mode : {Payment}</span>
            </div>
            <div className={`orderStatus ${current_orders.status === "order_cancelled" ? "red-text" : ""}`}>{handleGetDeliveryTypeText(current_orders.status)}</div>
          </div>
          {current_orders && !current_orders.is_cancelled && (
            <div className='pastOrderHistoryTopButtons'>
              <div className='rateOrder'>
                <img src={star} />
                <span onClick={() => dataShow()}>RATE ORDER</span>
              </div>
              <div className='OrderInvoice'>
                <a className='OrderInvoice_Btn' onClick={(e)=>{e.stopPropagation();LocalStorage.set("Invoice_Order_Id",order_id)}} href={ROUTES.INVOICE} target='_blank'>VIEW INVOICE</a>
              </div>
              <div className='repeatOrder'>
                <span onClick={() => onAddToCart()}>REPEAT ORDER</span>
              </div>
            </div>
          )}
        </div>
        <div className='pastOrderHistoryViewMore'>
          <span onClick={() => dataShow()}>VIEW ORDER DETAILS</span>
          <img src='' />
        </div>
        <span className='dividerLine'></span>
      </div>
      {

        showpdf && <div className="pdf_view">
          <img className="cross_icon_img" src={crossIcon} alt="X" onClick={() => setShowPdf(false)} />
          <div className="pdf_view_content" dangerouslySetInnerHTML={{ __html: pdfContent }} ref={imgRef}>
          </div>
          <button onClick={savePDF}>Download</button>

        </div>

      }
      {toaster.isShow && (
        <Toaster
          timeout={3000}
          closeToast={closeToast}
          toastText={toaster.toastText}
        />
      )}
    </>
  )
}

export default PastOrderHistoyCard