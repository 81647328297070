import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';


export const feedback = (payload, callback) => {
    const url = `${API_CONSTANTS.FEEDBACK}`;
    return fetchCall(url, API_METHODS.POST, payload, callback);
  };

  

  export const exportFeedback = (payload, callback, params) => {
    const url = `${API_CONSTANTS.SEND_FEEDBACK}${params}`;
    return fetchCall(url, API_METHODS.POST, payload, callback);
  };