import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';

export const addAddressNew = (payload, callback) => {
    const url = `${API_CONSTANTS.ADD_ADDRESS}`;
    return fetchCall(url, API_METHODS.POST, payload, callback);
};

export const editAddress = (payload, callback) => {
    const url = `${API_CONSTANTS.ADD_ADDRESS}`;
    return fetchCall(url, API_METHODS.PUT, payload, callback);
};

export const deleteAddress = (payload, callback) => {
    const url = `${API_CONSTANTS.ADD_ADDRESS}`;
    return fetchCall(url, API_METHODS.DELETE, payload, callback);
};


export const getAddress = (callback) => {
    fetchCall(API_CONSTANTS.ADD_ADDRESS, API_METHODS.GET, '', callback);
};

export const getAvailableAddress = (callback , params) => {
    fetchCall(`${API_CONSTANTS.ADDRESS_GET_ALL}${params}`, API_METHODS.GET, '', callback);
};

export const getLocationAvailable = (callback , params) => {
    fetchCall(`${API_CONSTANTS.GET_ADDRESS_LOCATION}${params}`, API_METHODS.GET, '', callback);
   
};
export const getDeliveryDistance = (payload,callback) => {
    fetchCall(`${API_CONSTANTS.DISTANCE_MATRIX}`, API_METHODS.POST, payload, callback);
};

// export const getIsdeliveredToAddress=(callback , params)=>{
//     const url =  `${API_CONSTANTS.IS_DELIVERABLE_ADDRESS}${params}`
//     fetchCall(url, API_METHODS.GET, '', callback);
// }