/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Discount from "../../assets/images/discount_img.png";
import Back from "../../assets/images/path.svg";
import LocationImg from "../../assets/images/locationIcon.svg";
import DishImg from "../../assets/images/imli-adral-lemonade@3x.png";
import Details from "../../components/checkout-details";
import Location from "../../components/checkout-location";
import Checkbox from "../../components/checkout-checkbox";
import AmountSummary from "../../components/amount-summary";
import AddOnCart from "../../components/add-on-cart";
import donationPic from "../../assets/images/donate.svg";
import growPlants from "../../assets/images/grow-plants.svg";
import serveBiryani from "../../assets/images/serve-biryani.png";
import closeImg from "../../assets/images/close.svg";
import aunchBox from "../../assets/images/anchbox.svg";
import plantsRes from "../../assets/images/plant.svg";
import donateRes from "../../assets/images/donateres.svg";
import HorizontalScroll from "../../components/horizontal-scroll";

import {
  addToCart,
  getCartItems,
  checkout,
  paymentSuccess,
  applyGiftVoucher,
} from "../../actions/cart";
import Loyalty from "../../assets/images/loyalty.svg";
import { displayRazorpay } from "../../utils/payment";
import FailureCard from "../../components/failure-card";
// import productImg from '../../assets/images/productImg.png';
import cookie from "../../assets/images/cookieImg.png";
import coffee from "../../assets/images/coffeeImg.png";
import chocolate from "../../assets/images/chocolate-barImg.png";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
import timeImg from "../../assets/images/deliverytimeIcon.svg";
import bikeImg from "../../assets/images/deliveryIcon.svg";
import locationCart from "../../assets/images/addresslocation.svg";
import { API_CONSTANTS } from "../../constants/api-constants";
import AddAddress from "../../containers/add-address";
import { addAddressNew, getDeliveryDistance } from "../../actions/location";
import Spice from "../../assets/images/spice.svg";
import { getLoyalityData } from "../../actions/loyalty";
import goldloyalty from "../../assets/images/goldloyalty.png";
import silverloyalty from "../../assets/images/silverloyalty.png";
import bronzeloyalty from "../../assets/images/bronzeloyalty.png";
import heartImg from '../../assets/images/heart.png';
import "./index.scss";
import ProductDetail from "../../containers/productDetail";
import ProductSummary from "../../components/product-summary";
import Input from "../../components/input";
import Button from "../../components/button";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import Modal from "../../components/modal";
import { Analytics } from "../../helpers/analytics";

import { LocalStorage } from "../../utils/storage";
import Dexie from "dexie";
import CheckoutOfferCard from "../../components/checkout-offercard";
import {
  offers,
  applyLoyalty,
  applyOffers,
  applyNewLoyalty,
} from "../../actions/offers";
import DonationPopup from "../../components/donationPopup";
import PaymentMethod from "../../components/payment-method";
import AlertPopup from "../../components/alert-popup";
import TipRider from "../../components/tip-rider";
import Option from "../../components/option";
import { getAllAddresses } from "../../actions/cart";

//login
import CreateAccount from "../../containers/createAccount";
import { sendOtp, verifyOtp, makeProfile } from "../../actions/login";
import CreateAccountModal from "../../containers/createAccountModal";
import BasicDetails from "../../containers/basicDetails";
import OtpVerification from "../../containers/otpVerification";

//delivery
import RadioButton from "../../components/radioButton";
// import SearchAutoComplete from "../searchAutocomplete";
import SearchAutoComplete from "../../containers/searchAutocomplete";
import Detect from "../../assets/images/detectLoc.svg";
// import responsiveDetect from "../../assets/images/profileLocation.svg";
import customize from "../../assets/images/customize.svg";
// import MapModal from "../mapModal";
import MapModal from "../../containers/mapModal";
import LocationSearch from "../../assets/images/homeLocation.svg";
import remove from "../../assets/images/close.svg";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
// import Input from "../../components/input";
import { OutletMapping, getSkipLocation } from "../../actions/home";
import StoreCard from "../../components/store-card";
// import AlertPopup from "../../components/alert-popup";
import LocationImage from "../../assets/images/Web-Location-page-web.jpg";
import Locationinterested from "../../components/location-interested-card";
import IntervalDrop from "../../components/interval-dropdown";
import Switch from "../../components/switch";
import moment from "moment";
import { getMenuList } from "../../actions/home";
import RepeatCustomizationPopup from "../../components/repeat-customization-popup";
import AddToCart from "../../components/addToCart-popup";
import ListAddress from "../../containers/list-address";
import Toaster from "../../components/toaster";
import HeaderCommon from "../../components/header-common-component";
import OfferPopup from "../../containers/offerPopup";
import {
  handleGetTotalAmt,
  handleParseAmount,
  isAddonsAvailble,
} from "../../helpers/sharedFunction";
import { regex } from "../../constants/regex";
import {
  checkoutNetCoreEvent,
  couponAppliedNetCoreEvent,
  paymentFailureNetCoreEvent,
  payNowNetCoreEvent,
  productPurchaseNetCoreEvent,
} from "../../helpers/netcore";
import GiftCard from "../../components/gift-card";
import { checkoutPageMeta } from "../../helpers/metaTitleDescription";
import NewButton from "../../components/newCount-Button";
import { useLocation } from "react-router-dom";
import { getSavedAddress } from "../../actions/home";
import {
  addAddressInfoBranch,
  addPaymentInfoBranch,
  purchaseBranch,
  viewCartBranch,
  setIdentityBranch,
  loginBranchEvent,
  registrationBranch,
} from "../../utils/branch";

// praveena 8th june
import percentRed from "../../assets/images/discount.png";
import { claimGiftCardApi } from "../../actions/giftcards";
import { viewWalletBalance, walletApplyOffer } from "../../actions/wallet";
import distanceCategoryIcon from "../../assets/images/distance-category-icon_green.svg";
import deliveryIcon45Min from "../../assets/images/delivery-icon-45min.png";

const paymentModesData = [
  {
    name: "Cash",
    checked: false,
    id: "cod",
    header: "Pay On Delivery",
  },
  {
    name: "UPI, Paytm Wallet, Debit/Credit Cards, Netbanking, Wallet and more…",
    checked: false,
    id: "online_payment",
    header: "Pay Online",
  },
  {
    name: "BBK Money",
    checked: false,
    id: "online_payment",
    header: "Pay from BBK Money",
    showWalletBalance: true,
  },
];

const Checkout = () => {
  const [instruction, setInstruction] = useState("");
  const [address, setAddress] = useState("");
  const [reach, setReach] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [place, setPlace] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [delTime, setDeliverytime] = useState({});
  const [addOns, setAddOns] = useState();
  const [taxCharges, setTaxCharges] = useState([]);
  const [user, setUser] = useState({});
  const [outletData, setOutletData] = useState([]);
  const history = useHistory();
  const [offerData, setOfferData] = useState([]);
  const [unAvailableOffers, setUnavailableOffers] = useState([]);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [loyalty, setLoyalty] = useState({});
  const [isLoyalty, setIsLoyalty] = useState(false);
  const [isDonate, setIsDonate] = useState(false);
  const [isPot, setIsPot] = useState(false);
  const [isHotBiryani, setIsHotBiryani] = useState(true);
  const [donation, setDonation] = useState(0);
  const [showOffInfo, setShowOffInfo] = useState(false);
  const [showOffData, setShowOffData] = useState({});
  const [openDonation, setOpenDonation] = useState(false);
  const [donationClose, setDonationClose] = useState("");
  const [alert, setAlert] = useState("");
  const [othersTxt, setOthersTxt] = useState("");
  const [loginStatus, setLoginStatus] = useState(
    LocalStorage.get("isLoggedIn")
  );
  const [payMode, setPayMode] = useState("");
  const [paymentMode, setPaymentMode] = useState(false);
  const [failurePay, setFailurePay] = useState(false);
  const [tip, setTip] = useState(0);
  const [clicked, setClicked] = useState("");
  const { innerWidth } = window;
  const [errorCart, setErrorCart] = useState("");
  const [availableAddresses, setAvailableAddresses] = useState([]);
  const [selectedAD, setSelectedAD] = useState({});
  const [customizePopup, setCustomizePopup] = useState(false);
  const [customizeData, setCustomizeData] = useState({});
  const [itemData1, setItemData1] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [productDataAdd, setProductDataAdd] = useState({});
  const [extras, setExtras] = useState([]);
  const [addSubCount, setAddSubCount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [cartviewcount, setcartviewcount] = useState(0);
  const [isAddons, setIsAddons] = useState(false);
  const [isMsg, setIsMsg] = useState({
    toastType: false,
    toastText: "",
    isShow: false,
  });
  const [isLoader, setIsLoader] = useState(false);

  const [pointData, setPointData] = useState({});
  const [history1, setHistory] = useState([]);

  //login
  const [stat, setStat] = useState("stage1");
  const [mobileNumber, setMobileNumber] = useState("");
  const [displayMobileNumber, setDisplayMobileNumber] = useState("");
  const [loginId, setLoginId] = useState("");
  const [errorTxt, setErrorTxt] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorTxt1, setErrorTxt1] = useState("");
  const [successTxt, setSuccessTxt] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [basicDetailsData, setBasicDetailsData] = useState();

  const [loginFlow, setLoginFLow] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [addressListModal, setAddressListModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [localAddressList, setLocalAddressList] = useState([]);
  const [payment, setPayment] = useState(false);
  const [addNewCartBtnLoader, setAddNewBtnLoader] = useState(false);
  const [multipleAddonAlert, setMultipleAddonAlert] = useState(false);
  const [loaderById, setLoderById] = useState({});
  const [homeData, setHomeData] = useState({});
  const [takeAway, setTakeAway] = useState(false);
  const [openAddressForm, setOpenAddressForm] = useState(false);

  const [btnDisabled, setBtnDisaled] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [updatedCartData, setUpdatedCartData] = useState([]);
  const [categoryNameData, setCategoryNameData] = useState([]);
  const [billingDetailsData, setBillingDetailsData] = useState({});
  const [commonData, setCommonData] = useState({ tip: 0, donation: 0 });
  const [stripData, setStripData] = useState({}); //praveena 9th june
  const [isStrikeRequired, setIsStrikeRequired] = useState();
  const [isInDistanceCategory, setIsInDistanceCategory] = useState(false);
  // const [isDistanceCategory,setIsDistanceCategory] = useState(false);

  //delivery
  const [deliveryFlow, setDeliveryFlow] = useState(false);
  const switchData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Takeaway" },
  ];

  const switchResponsiveData = [
    { id: "delivery", name: "Delivery" },
    { id: "takeaway", name: "Pick-up" },
  ];

  const radio = [
    { id: "orderNow", name: "Order Now", checked: true },
    { id: "orderLater", name: "Order Later", checked: false },
  ];

  // const responsiveRadio = [
  //   { id: "orderNow", name: "Now", checked: true },
  //   { id: "orderLater", name: "Later", checked: false },
  // ];
  let isDelivery =
    LocalStorage.get("location").orderFor &&
    (LocalStorage.get("location").orderFor === "delivery" ? true : false);
  let isOrderLater =
    LocalStorage.get("location")?.orderTime === "orderLater" ? true : false;
  let preorder_only = LocalStorage.get("preorder");
  const responsiveRadio = [
    {
      id: "orderNow",
      name: isDelivery ? "Delivery Now" : "Takeaway Now",
      checked: !preorder_only && !isOrderLater,
      isDisbled: preorder_only,
    },
    {
      id: "orderLater",
      name: isDelivery ? "Delivery Later" : "Takeaway Later",
      checked: !preorder_only && isOrderLater,
      isDisbled: false,
    },
  ];
  const [active, setActive] = useState("delivery");
  const [radioData, setRadioData] = useState(radio);
  const [responsiveRadioData, setResponsiveRadioData] =
    useState(responsiveRadio);
  const [activeOrder, setActiveOrder] = useState(
    LocalStorage.get("location").orderTime
      ? LocalStorage.get("location").orderTime
      : "orderNow"
  );
  const [inputValue, setInputvalue] = useState({});
  const [popup, setPopup] = useState(false);
  const [inputData, setInputData] = useState("");
  // const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [latLng, setLatLng] = useState({ lat: "", lng: "" });
  const [errorData, setErrorData] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [location, setLocation] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [data, setData] = useState([]);
  const [errorData1, setErrorData1] = useState(false);
  const [countData, setCountData] = useState({});
  const [alertData, setAlertData] = useState(false);
  const [dateData, setdateData] = useState(false);
  const [timeData, settimeData] = useState(false);
  const [timeInp, setTimeInp] = useState("text");
  const [modal, setModal] = useState(false);
  // const {netcore} = props;
  const [interval, setInterval] = useState("");
  const [intervalModal, setIntervalModal] = useState(false);
  const [intDate, setIntDate] = useState([]);
  const [loyaltyAmount, setLoyaltyAmount] = useState(null);
  const [giftVoucherCode, setGiftVoucherCode] = useState("");
  const [giftVoucherAmt, setGiftVoucherAmt] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(paymentModesData);
  const [alertBasicForm, setAlertBasicForm] = useState(false);
  const [savedAddress, setSavedAddress] = useState("");
  const [taxDatas, setTaxDatas] = useState([]);

  const urlParams = useLocation();
  const urlValue = urlParams.pathname.split("/")[3];
  const urlCity = urlParams.pathname.split("/")[2];
  const outletDataDetails = LocalStorage.get("Outlet_Details");

  let timeInterval = [];
  let offerCode = LocalStorage.get("offerwholedata");
  let localOfferData = LocalStorage.get("offersData");
  let localloyaltyDiscountAmt = LocalStorage.get("localloyaltyDiscountAmt");
  const previousLocationData = LocalStorage.get("Previouslocation");
  const outletID = LocalStorage.get("outlet_id");
  let localStrgInstructions = localStorage.getItem("Instructions");
  let localStrgCartProducts = localStorage.getItem("CartProducts");

  const addCart = [
    {
      dishName: "lemonade",
      desc: "lorem ipsum is fun",
      amount: "30/-",
    },
    {
      dishName: "Peri peri",
      desc: "lorem ipsum is fun",
      amount: "10/-",
    },
    {
      dishName: "lemonade",
      desc: "lorem ipsum is fun",
      amount: "50/-",
    },
  ];
  let tipData = [
    {
      img: cookie,
      name: "10",
      val: "10",
    },
    {
      img: coffee,
      name: "20",
      val: "20",
    },
    {
      img: chocolate,
      name: "30",
      val: "30",
    },
  ];

  const TimeInterval = (name, bool, id) => {
    // debugger;
    setInterval(name);
    setIntervalModal(bool);
    setTime(id);
    const locationData = JSON.parse(localStorage.getItem("location")) || {};
    locationData.time = id;
    // locationData.time=time;
    console.log(locationData, "datee time");

    localStorage.setItem("location", JSON.stringify(locationData));
  };

  const getAddons = (category, itemId) => {
    let itemData = {};
    category &&
      category.map((item) => {
        if (item && item.has_subcategory === "0") {
          item.items.map((data) => {
            if (data && data.item_id === itemId) {
              itemData = data;
            }
          });
        }
        if (item && item.has_subcategory === "1") {
          item.subcategories.map((itemValue) => {
            if (itemValue && itemValue.item_id === itemId) {
              itemData = itemValue;
            }
          });
        }
      });
    return itemData;
  };

  const splitItems = (value) => {
    value = value && value[0];
    let splitValues = value.goes_well_with.split(",");
    return splitValues;
  };

  const itemList = [
    {
      id: 0,
      item_name: "Extra",
      item_long_description: "",
      item_final_price: "",
      item_image_url: Spice,
    },
  ];

  const onItemCheck = () => {
    setIsChecked(!isChecked);
  };

  const subCount = (item, value) => {
    // console.log("subcount", item);
    if (value === "add") {
      const addonsAvailble = isAddonsAvailble(homeData.categories, item);
      if (item.count === 0 && item._id) {
        delete item._id;
      }
      if (!addonsAvailble) {
        setLoderById((prevState) => ({
          ...prevState,
          [item._id]: true,
        }));
        item.count = item.count + 1;
        onUpdateContent(item, [], true);
        Analytics.addToCardEvent(item, true);
        return;
      }
      value === "add" && addonPopup(item);
    } else {
      if (item.count > 0) {
        setLoderById((prevState) => ({
          ...prevState,
          [item._id]: true,
        }));
        item.count = item.count - 1;
        onUpdateContent(item, [], true, false);
        Analytics.removeFromCartEvent(item);
      } else {
        item.count = 0;
        onUpdateContent(item, [], true, false);
      }
    }
  };

  let cartArr = [];

  const addCount = () => {
    setAddSubCount(addSubCount + 1);
    cartArr.push({ Quantity: addSubCount, "Food Id": id });
    LocalStorage.set("Food Details", cartArr);
  };

  const onDataUpdate = (item) => {
    onUpdateContent(item, extras, undefined, true);
    // setProductModal(!productModal);
  };

  const onDataChecked = (item, groupId) => {
    const updateExtra = extras.map((items) => {
      items.items.map((itm) => {
        if (groupId === items.group_id && itm.item_id === item.item_id) {
          itm.checked = !item.checked;
        }
        return itm;
      });
      return items;
    });

    const checkedItems = [];

    const findExtraIndex = extras.findIndex(
      (data) => data.group_id === groupId
    );

    updateExtra &&
      updateExtra[findExtraIndex] &&
      updateExtra[findExtraIndex].items &&
      updateExtra[findExtraIndex].items.map((item) => {
        if (item.checked) {
          checkedItems.push(item.checked);
        }
      });

    if (checkedItems.length == updateExtra[findExtraIndex].group_maximum) {
      updateExtra &&
        updateExtra[findExtraIndex] &&
        updateExtra[findExtraIndex].items &&
        updateExtra[findExtraIndex].items.forEach((item) => {
          if (!item.checked) {
            item.disabled = true;
          }
        });
    } else {
      updateExtra &&
        updateExtra[findExtraIndex] &&
        updateExtra[findExtraIndex].items &&
        updateExtra[findExtraIndex].items.forEach((item) => {
          item.disabled = false;
        });
    }
    setExtras(updateExtra);
  };

  const cartShow = () => {
    setProductModal(!productModal);

    //
    Analytics.logEventWithVariable(
      Analytics.Order.productView,
      JSON.stringify(productDataAdd)
    );
    setExtras(productDataAdd.groups);
    productDataAdd.count = productDataAdd.count + 1;
  };

  // let updateItem1;
  let updateitem1;
  let locationDetails;
  let loyaltyDiscountAmt;

  useEffect(() => {
    let taxdatas = [];
    JSON.parse(localStrgCartProducts) &&
      JSON.parse(localStrgCartProducts).forEach((data) => {
        data?.item_tags.forEach((taxData) => {
          taxdatas.push(taxData.tax_value);
        });
      });
    setTaxDatas(JSON.stringify(taxdatas).replaceAll(",", "%+"));
    const root = document.getElementById("root");
    root.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getSavedAddress((res) => {
      if (res?.response?.status == 200) {
        setSavedAddress(res.content.address);
      }
    });

    if (locationBottom.orderFor === "takeaway") setTakeAway(true);

    getLoyalityData((res) => {
      if (res && res?.response?.status === 200) {
        setPointData(res?.content?.current_points);
        setHistory(res?.content?.history);
        console.log(res, "loyalty data")
      }
    });

    // LocalStorage.set("offerAmount", null);
    LocalStorage.set("cartcountview", 1);

    let isDelivery =
      LocalStorage.get("location").orderFor &&
      (LocalStorage.get("location").orderFor === "delivery" ? true : false);
    let outletID = LocalStorage.get("outlet_id")
      ? LocalStorage.get("outlet_id")
      : "";
    let DeliveryAreaId =
      LocalStorage.get("Outlet_Details") &&
      LocalStorage.get("Outlet_Details").delivery_area_id;
    const queryParams =
      "?is_delivery=" +
      isDelivery +
      "&outlet_id=" +
      outletID +
      "&delivery_area_id=" +
      DeliveryAreaId;
    getAllAddresses((res) => {
      if (res && res?.response?.status === 200) {
        setAvailableAddresses(res?.content?.address_available);
      }
    }, queryParams);

    const dataDeliveryTime = LocalStorage.get("location") || {};
    setDeliverytime(dataDeliveryTime);

    if (LocalStorage.get("outlet_id")) {
      const outlet = LocalStorage.get("outlet_id");
      const db = new Dexie(outlet);
      db.version(1).stores({
        menu: "++id, categories, item_tags, taxes",
      });
      // let itemId = localStorage.getItem('item_id');
      const getPosts = async () => {
        let allPosts = await db.menu.toArray();

        //new
        if (allPosts && allPosts[0] && allPosts[0].categories.length > 0) {
          updateitem1 = allPosts[0].categories.map((items) => {
            let updateItem1 = [];
            if (items.has_subcategory === "0") {
              updateItem1 = items.items;
            }
            if (items.has_subcategory === "1") {
              items.subcategories.map((item) => {
                item.items.map((value) => {
                  updateItem1.push(value);
                });
              });
              items.items = updateItem1;
            }
            items.itemData = updateItem1.map((item) => {
              item.checked = false;
              item.categoryName = items.category_name;
              item.count = 0;
              if (item.groups.length > 0) {
                item.groups.map((val) => {
                  if (val.items.length > 0) {
                    val.items.map((groupVal) => {
                      groupVal.checked = false;
                      return groupVal;
                    });
                  }
                });
              }
            });
            items.vegData = updateItem1.filter((item) =>
              item.item_tags.includes("1")
            );
            items.nonVeg = updateItem1.filter((item) =>
              item.item_tags.includes("2")
            );
            return items;
          });
          let arr = [];
          updateitem1.map((item) => {
            item?.items &&
              item?.items.map((i) => {
                arr.push(i);
              });
          });
          setItemData1(arr);
          setHomeData(allPosts[0]);
          setItemData(updateitem1);
        }

        if (allPosts && allPosts.length > 0) {
          setTaxCharges(allPosts[0].taxes);
          LocalStorage.set("taxes", allPosts[0].taxes);
          if (LocalStorage.get("CartProducts")) {
            const data = LocalStorage.get("CartProducts");
            setCartData(data);
            // if (data && data.length > 0) {
            //   let updateItem = [];
            //   data.map((item) => {
            //     if (
            //       item &&
            //       item.item_goes_well_with &&
            //       item.item_goes_well_with.length > 0
            //     ) {
            //       let goesWellArray = splitItems(item.item_goes_well_with);
            //       goesWellArray.map((innerItem) => {
            //         let response = getAddons(allPosts[0].categories, innerItem);
            //         if (response) {
            //           response.count = response.count || 0;
            //           updateItem.push(response);
            //         }
            //       });
            //     }
            //   });
            //   //
            //   setCategoryData(updateItem);
            // }
          }
        }
      };

      // console.log("first useeffect rendered");
      getPosts();
      if (LocalStorage.get("isLoggedIn")) {
        const login = LocalStorage.get("isLoggedIn");
        setLoggedIn(login);
        // offerPayload();
        getLoyality();
        console.log("deliverryy", DeliveryAreaId);
        getCartItems(`${outlet}&delivery_area_id=${DeliveryAreaId}`, (res) => {
          console.log("trrrrqueeee tyrrrs", JSON.stringify(res));
          if (res && res?.response?.status === 200) {
            console.log("get cart complete details", res);
            handleBranchFunc(res.content.billing_data);
            const data = onStructurize(res.content.items);
            setIsStrikeRequired(res.content.is_strike_required); // key to maintain striking the old price

            if (data.length === 0) {
              setAlert("Your cart is empty now!!");
              LocalStorage.set("CartProducts", []);
              LocalStorage.set("CouponName", []);
            } else {
              LocalStorage.set("CartProducts", data);
              LocalStorage.set(
                "CouponName",
                res.content.billing_data.coupon_used
              );
            }
            console.log("setBillingDetailsData--1->", res.content);
            setBillingDetailsData(res.content.billing_data);
            console.log("setBillingDetailsData--1->", billingDetailsData);
            setLoyaltyAmount(res.content.billing_data.loyalty_discount);
            setCartData(data);
            console.log("useffect 1st", res.content.strip_data);
            setStripData(res.content.strip_data);
            onCartDataValue(data, "", "", res.content, taxdatas);
            // praveena 9th june
            console.log(" dataaaaaa ", data);
          }
        });
      } else {
        if (LocalStorage.get("CartProducts")) {
          const data = LocalStorage.get("CartProducts");
          setCartData(data);
          onCartDataValue(data);
          if (data.length === 0) {
            setAlert("Your cart is empty now!!");
            LocalStorage.set("CartProducts", []);

            // alert('Your cart is empty');
            // history.push(ROUTES.PRODUCTLIST);
          }
        }
      }
    }

    //delivery
    if (latLng.lat === "" && latLng.lng === "") {
      setLocation(false);
      // onCurrentLocation();
    }
    locationDetails = LocalStorage.get("location");
    if (locationDetails) {
      setLocation(true);
      setLatLng({
        lat: locationDetails && locationDetails.lat,
        lng: locationDetails && locationDetails.lng,
      });
      // setActiveOrder(locationDetails && locationDetails.orderTime);
      setActive(locationDetails && locationDetails.orderFor);
      setInputData(locationDetails && locationDetails.address);
      // setDate(locationDetails && locationDetails.date);
      // setTime(locationDetails && locationDetails.time);
    }

    const localLoyalty = LocalStorage.get("loyalty");
    if (localLoyalty && localLoyalty == true) {
      console.log("insideloyalty");
      setIsLoyalty(localLoyalty);
    }
  }, [isLoyalty]);
  //wallet-balance
  const [walletBalance, setWalletBalance] = useState("");
  useEffect(() => {
    viewWalletBalance((res) => {
      if (res && res?.response?.status === 200) {
        setWalletBalance(res?.content?.balance);
        console.log(res.content.balance, "9090");
      }
    });
  }, []);
  const Wamount = () => {
    if (walletBalance != 0 && walletBalance < billingDetailsData?.paid_amount) {
      const onlinePaymentAmount =
        billingDetailsData?.paid_amount - walletBalance;
      return `+ Rs ${onlinePaymentAmount} Online Payment`;
    } else {
      return null;
    }
  };
  console.log(Wamount(), "ertrer");

  useEffect(() => {
    let cartProductsData = LocalStorage.get("CartProducts");
    const categoryNamesData = [];
    //Appending the people also order to the cart product
    cartProductsData &&
      cartProductsData.map((prd) => {
        itemData.map((item) => {
          item.items &&
            item.items.map((itemValue) => {
              if (
                itemValue.item_id === prd.item_id &&
                itemValue?.item_goes_well_with?.length > 0
              ) {
                prd.item_goes_well_with = itemValue.item_goes_well_with;
              }
              if (itemValue.item_id === prd.item_id) {
                prd.categoryName = item.category_name;
                categoryNamesData.push(prd);
              }
            });
        });
        return prd;
      });

    //Getting the people also order product
    if (cartProductsData && cartProductsData.length > 0) {
      let updateItem = [];
      cartProductsData.map((item) => {
        if (
          item &&
          item.item_goes_well_with &&
          item.item_goes_well_with.length > 0
        ) {
          let goesWellArray = splitItems(item.item_goes_well_with);
          goesWellArray.map((innerItem) => {
            let response = getAddons(homeData.categories, innerItem);
            if (response.item_id) {
              response.count = 0;
              updateItem.push(response);
            }
          });
        }
      });

      updateItem.length > 0 &&
        updateItem.map((product) => {
          cartProductsData.map((item) => {
            if (product.item_id === item.item_id) {
              product._id = item._id;
              product.count = item.count;
              product.complimentary_item = false;
            }
            return product;
          });
        });

      let uniqueItems = updateItem.filter(
        (ele, ind) =>
          ind === updateItem.findIndex((elem) => elem.item_id === ele.item_id)
      );
      setCategoryData(uniqueItems);
      setCategoryNameData(categoryNamesData);
    }
    // console.log("useeffect rendered");
  }, [itemData, updatedCartData]);

  useEffect(() => {
    console.log("outlet", outletData);
  }, [outletData]);

  useEffect(() => {
    if (giftVoucherAmt) {
      onCartDataValue(cartData, LocalStorage.get("offerAmount"), loyaltyAmount);
    }
    // getLoyalityDetails();
  }, [giftVoucherAmt]);

  const handleBranchFunc = (value) => {
    viewCartBranch({
      cart: LocalStorage.get("CartProducts"),
      totalamount: Math.floor(value.paid_amount),
      deliverCharge: Math.floor(value.delivery_charges),
      tax: Math.floor(value.cgst),
      storeName: LocalStorage.get("Outlet_Details").display_name,
      storeCity: LocalStorage.get("Outlet_Details").city,
      orderType: LocalStorage.get("location").orderTime,
    });
  };

  const getLoyality = () => {

    applyLoyalty((res) => {
      if (res && res.response && res.response.status === 200) {
        setLoyalty(res.content);
      }
    });
  };

  const addTip = (t, value) => {
    if (value === true) {
      setClicked("");
      setTip(0);
      onSetTip(0, cartData, "");
    } else {
      setClicked(t);
      setTip(t);
      onSetTip(t, cartData, "");
    }
  };

  const onCloseAlert = () => {
    setAlert("");
    if (alert === "Your cart is empty now!!") {
      history.push(ROUTES.HOME);
    }
    if (alertBasicForm) {
      setLoginFLow(true);
      setStat("stage3");
    }
  };
  const getNumericValue = (string) => {
    return parseInt(string.match(/\d+/)[0], 10);
  };

  const onCartCall = () => {
    // debugger;
    setBtnDisaled(true);
    const outlet = LocalStorage.get("outlet_id");
    const location = LocalStorage.get("location");

    const delivery_area_id = LocalStorage.get("Outlet_Details");
    const queryParams = delivery_area_id.delivery_area_id;
    const locationArr = location.address.split(",");
    const pin = locationArr[locationArr.length - 2]
      ? locationArr[locationArr.length - 2].split(" ")
      : "";
    const user = LocalStorage.get("UserData");
    const cartValue = [];
    cartData &&
      cartData.map((value) => {
        const item = {
          prid: value.item_id,
          prqt: value.count,
          is_veg: value.item_tags.includes("1") ? "true" : "false",
          image: value.item_image_url,
          produrl: value.item_image_url,
          price: value.single_item_price
            ? value.single_item_price
            : value.item_final_price,
          name: value.item_name,
          discount: 0,
          addons: [],
        };
        cartValue.push(item);
      });
    const cartDataValue = cartValue.map((value) => {
      const item = {
        prid: value.prid,
        prqt: value.prqt,
        is_veg: value.is_veg === "true" ? "true" : "false",
        image: value.item_image_url,
        produrl: value.produrl,
        price: Number(parseFloat(value.price).toFixed(2)),
        name: value.name,
        discount: 0,
        addons: LocalStorage.get("Addons") && LocalStorage.get("Addons"),
      };
      return item;
    });
    LocalStorage.set("cartDataValue", cartDataValue);

    const payNowNetCorePayload = {
      totalamount:
        outletData[9].amount && outletData[9].amount.substring(1).trim(),
      subtotal:
        outletData[0].amount && outletData[0].amount.substring(1).trim(),
      discount: Number(parseFloat(discountAmount).toFixed(2)),
      paymentmode: payMode,
    };

    // payNowNetCoreEvent(payNowNetCorePayload)

    const checkoutNetCorePayload = {
      totalamount:
        outletData[9].amount && outletData[9].amount.substring(1).trim(),
      subtotal:
        outletData[0].amount && outletData[0].amount.substring(1).trim(),
      tax:
        handleParseAmount(
          outletData[7].amount && outletData[7].amount.substring(1).trim()
        ) +
        handleParseAmount(
          outletData[6].amount && outletData[6].amount.substring(1).trim()
        ),
      currency: "INR",
      coupon_code: "INR",
      discount: Number(parseFloat(discountAmount).toFixed(2)),
      usertype: "New",
      total_items: cartValue.length,
      address1:
        selectedAddress && selectedAddress.Address1
          ? `${selectedAddress.Address1} ${selectedAddress.reach ? selectedAddress.reach : ""
          }`
          : "",
      address2:
        selectedAddress && selectedAddress.Address2
          ? selectedAddress.Address2
          : address,
      paymentmode: payMode,
      is_loyalty_discount: isLoyalty ? "true" : "false",
      cooking_instruction: instruction,
      donation: donation,
    };
    // checkoutNetCoreEvent(checkoutNetCorePayload)

    //Analytics

    const payload = {
      mode_of_payment: payMode,
      address_id: 12,
      outlet_id: outlet,
      source: "web", //"app"
      delivery_address: {
        addressid:
          selectedAddress && selectedAddress.AddressId
            ? selectedAddress.AddressId
            : "0",
        city:
          selectedAddress && selectedAddress.City
            ? selectedAddress.City
            : locationArr[locationArr.length - 3],
        pin:
          selectedAddress && selectedAddress.Pin
            ? selectedAddress.Pin
            : pin[pin.length - 1],
        location_name:
          selectedAddress && selectedAddress.Locality
            ? selectedAddress.Locality
            : locationArr[locationArr.length - 5] +
            locationArr[locationArr.length - 4],
        landmark: reach,
        address1:
          previousLocationData && previousLocationData.address
            ? previousLocationData.address
            : selectedAddress && selectedAddress.Address1
              ? `${selectedAddress.Address1}`
              : "",
        address2:
          selectedAddress && selectedAddress.Address2
            ? selectedAddress.Address2
            : "",
        Google_Address: selectedAddress?.Google_Address
          ? selectedAddress.Google_Address
          : "",
        latitude:
          selectedAddress && selectedAddress.guest_lat
            ? selectedAddress.guest_lat
            : location.lat,
        longitude:
          selectedAddress && selectedAddress.guest_long
            ? selectedAddress.guest_long
            : location.lng,
        alternate_number: alternateNumber,
        AddressType:
          previousLocationData && previousLocationData.addressType
            ? previousLocationData.addressType
            : selectedAddress && selectedAddress.AddressType
              ? selectedAddress.AddressType
              : othersTxt,
      },
      // coupon_used: selectedOffer && selectedOffer.coupon_code,
      coupon_used: billingDetailsData?.coupon_used,
      is_loyalty_discount: isLoyalty,
      // voucher_code: "",
      cooking_instruction: instruction,
      is_seed: isPot,
      is_pot: isHotBiryani,
      delivery_type:
        location.orderFor === "takeaway" ? "take_away" : "delivery",
      is_preorder: location.orderTime === "orderNow" ? false : true,
      pre_order_time: location.orderTime === "orderNow" ? null : location.time,
      delivery_tips: Number(tip),
      donation: Number(donation),
      pre_order_date:
        location.orderTime === "orderNow"
          ? null
          : moment(location.date).format("YYYY-MM-DD"),
      taxes_tags: [
        {
          tax_type: "percentage",
          tax_value: 2.5,
          tax_label: "SGST",
          tax_tags: "1",
        },
        {
          tax_type: "percentage",
          tax_value: 2.5,
          tax_label: "CGST",
          tax_tags: "2",
        },
      ],
    };
    if (giftVoucherAmt && giftVoucherAmt != 0) {
      payload.voucher_code = giftVoucherCode;
    }
    //

    const prdPurchaseNetCorePayload = {
      totalamount: getNumericValue(outletData[9].amount),
      subtotal: getNumericValue(outletData[0].amount),
      tax: getNumericValue(outletData[7].amount),
      discount: discountAmount,
      total_items: cartValue.length,
      items: categoryNameData,
      coupon_code:
        selectedOffer && selectedOffer.coupon_code
          ? selectedOffer.coupon_code
          : "",
    };

    console.log("prdPurchaseNetCorePayload", prdPurchaseNetCorePayload);

    checkout(
      payload,
      (res) => {
        // console.log("razorpay", res);
        //branch events
        purchaseBranch({
          cart: LocalStorage.get("CartProducts"),
          transactionId: res?.content?.order_id,
          revenue: Math.floor(billingDetailsData.paid_amount),
          shipping: Math.floor(billingDetailsData.delivery_charges),
          tax: Math.floor(billingDetailsData.cgst),
          storeName: LocalStorage.get("Outlet_Details").display_name,
          storeCity: LocalStorage.get("Outlet_Details").city,
          orderType: LocalStorage.get("location").orderTime,
        });
        let prdPurchaseGAPayload = {
          transaction_id: res?.content?.order_id,
          order_id: res?.content?.order_id,
          value: billingDetailsData.paid_amount,
          subtotal: outletData[0].amount.substring(1),
          tax: outletData[7].amount.substring(1),
          shipping: outletData[4].amount.substring(1),
          currency: "INR",
          discount: discountAmount,
          total_items: cartValue.length,
          items: categoryNameData,
          coupon:
            selectedOffer && selectedOffer.coupon_code
              ? selectedOffer.coupon_code
              : "",
        };
        console.log("prdPurchaseGAPayload", prdPurchaseGAPayload);

        setBtnDisaled(false);
        if (res && res?.response?.status === 200) {
          localStorage.setItem(
            "purchaseDetailId",
            res.content.purchase_detail_id
          );

          // LocalStorage.remove("CartProducts");
          if (payMode === "online_payment") {
            const option = {
              key: res.content.payment_info.key,
              amount: res.content.payment_info.amount,
              currency: "INR",
              name: res.content.payment_info.name,
              description: res.content.payment_info.description,
              image: { BBK },
              order_id: res.content.payment_info.order_id,

              handler: (response) => {
                const payload = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                };
                paymentSuccess(payload, (res) => {
                  if (res.response.status === 200) {
                    const user = LocalStorage.get("UserData");
                    const script = document.createElement("script");
                    const outletInfo = LocalStorage.get("Outlet_Details");

                    // productPurchaseNetCoreEvent(prdPurchaseNetCorePayload)
                    // Analytics.purchaseEvent(prdPurchaseGAPayload)

                    LocalStorage.remove("CartProducts");

                    history.push({
                      pathname: ROUTES.ORDER_STATUS,
                      state: {
                        isFromCheckOut: true,
                        payload: prdPurchaseNetCorePayload,
                        analyticsPayload: prdPurchaseGAPayload,
                      },
                    });

                    // history.push(ROUTES.ORDER_STATUS);
                  }
                });
              },
              prefill: res.content.payment_info.prefill,
              notes: res.content.payment_info.notes,
              retry: { enabled: false },
              features: {
                wallet_on_checkout: true,
              },
            };
            displayRazorpay(option, (res) => {
              if (res.error && res.error.reason === "payment_failed") {
                setFailurePay(true);
                const paloadEvent = {
                  payment_for:
                    res?.content?.purchase_detail_id &&
                    res?.content?.purchase_detail_id,
                  payment_type: "online",
                };
                // paymentFailureNetCoreEvent(paloadEvent)
              }
            });
          } else {
            // productPurchaseNetCoreEvent(prdPurchaseNetCorePayload)
            // Analytics.purchaseEvent(prdPurchaseGAPayload)

            // history.push(ROUTES.TRACK_ORDER);
            history.push({
              pathname: ROUTES.TRACK_ORDER,
              state: {
                isFromCheckOut: true,
                payload: prdPurchaseNetCorePayload,
                analyticsPayload: prdPurchaseGAPayload,
              },
            });
          }
        } else if (innerWidth <= 1023) {
          if (date === "") {
            setIsMsg({
              toastType: true,
              toastText: "Date is required",
              isShow: true,
            });
          } else if (time === "") {
            setIsMsg({
              toastType: true,
              toastText: "Time is required",
              isShow: true,
            });
          } else {
            setIsMsg({
              toastType: true,
              toastText: res?.response?.message,
              isShow: true,
            });
            if (res?.content?.show_form) {
              setAlertBasicForm(true);
              setAlert(res?.response?.message);
            }
            const paloadEvent = {
              payment_for: res?.response?.message,
              payment_type: "cod",
            };
          }
          // paymentFailureNetCoreEvent(paloadEvent)
        } else {
          const paloadEvent = {
            payment_for: res?.response?.message,
            payment_type: "cod",
          };
          // paymentFailureNetCoreEvent(paloadEvent)
          setErrorCart(res?.response?.message);
          if (res?.content?.show_form) {
            setAlertBasicForm(true);
            setAlert(res?.response?.message);
          }
        }
      },
      queryParams
    );
  };
  const [claimCode, setClaimCode] = useState("");
  const [claimResponse, setClaimResponse] = useState(null);
  const [showClaimPopup, setShowClaimPopup] = useState(false);
  const [successClaimResponse, setSuccessClaimResponse] = useState(null);

  const handleClaim = () => {
    const payload = {
      giftcard_number: claimCode.toString(),
    };
    claimGiftCardApi(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setSuccessClaimResponse(
          "Gift card amount has been added to your wallet. Start ordering to use the BBK Money."
        );
      } else if (res && res?.response?.status === 400) {
        setClaimResponse(res.response.message);
      }
      setShowClaimPopup(true);
    });
  };

  const onCheckoutClick = (value) => {
    // debugger;
    if (value === true) {
      onCartCall();
    } else {
      onLoginClick();
    }
  };

  const selectAgain = () => {
    setAlert("Select Payment Method to continue!");

    //branch events

    addPaymentInfoBranch({
      revenue: Math.floor(billingDetailsData.paid_amount),
      shipping: Math.floor(billingDetailsData.delivery_charges),
      tax: Math.floor(billingDetailsData.cgst),
      storeName: LocalStorage.get("Outlet_Details").display_name,
      storeCity: LocalStorage.get("Outlet_Details").city,
      orderType: LocalStorage.get("location").orderTime,
    });
  };

  const offerPayload = () => {
    const outlet = LocalStorage.get("outlet_id");
    const location = LocalStorage.get("location");
    const delivery_area_id = LocalStorage.get("Outlet_Details");

    let payload = {
      channel: location.orderFor === "delivery" ? "delivery" : "take_away",
      online_payment: payMode === "cod" || payMode === "COD" ? false : true,
      outlet_id: outlet,
    };
    offers(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setOfferData([
          ...res?.content?.available_offer,
          ...res?.content?.unavailable_offer,
        ]);
        LocalStorage.set("offersData", [
          ...res?.content?.available_offer,
          ...res?.content?.unavailable_offer,
        ]);
        // setUnavailableOffers(res?.content?.unavailable_offer);
      }
    });
  };

  const onAddToCart = (value, isRepeat, addNewIsRepeat) => {
    let isEqual = false;
    const outletData = LocalStorage.get("Outlet_Details");
    const location = LocalStorage.get("location");
    const outletId = LocalStorage.get("outlet_id");
    const DeliveryAreaId = LocalStorage.get("delivery_area_id");
    const user = LocalStorage.get("UserData");
    let ontaxCal = [];
    taxCharges.map((item) => {
      if (isRepeat == true) {
        value.item_tags.map((values) => {
          if (item.tax_id == values.tax_tags) {
            const tax = {
              tax_tags: values.tax_tags,
              tax_value: Number(values.tax_value),
              amount: Number(values.amount),
            };
            ontaxCal.push(tax);
          }
        });
      } else if (addNewIsRepeat == true) {
        if (value.item_taxes && value.item_taxes.length > 0) {
          value.item_taxes[0].taxes.map((values) => {
            if (item.tax_id === values) {
              const tax = {
                tax_tags: values,
                tax_value: Number(item.tax_value),
                amount:
                  (item.tax_value *
                    (Number(parseFloat(value.item_final_price)) *
                      value.count)) /
                  100,
              };
              ontaxCal.push(tax);
            }
          });
        }
      }
    });

    let cartProducts = LocalStorage.get("CartProducts");
    let itemEqualData;
    cartProducts &&
      cartProducts.map((product) => {
        if (product.item_id === value.item_id) {
          if (product.addons.length === 0 && value.addons.length === 0) {
            itemEqualData = product;
            return itemEqualData;
          }
          if (product.addons.length > 0) {
            var productsItem = product.addons.map(function (x) {
              return x.item_id;
            });
            var addItem = value.addons.map(function (x) {
              return x.item_id;
            });
            if (productsItem.sort().join(",") === addItem.sort().join(",")) {
              itemEqualData = product;
              return itemEqualData;
            }
          }
        }
      });

    let addarr = [];
    let addStr = "";
    value.addons &&
      value.addons.length > 0 &&
      value.addons.map((adds) => {
        addarr.push(parseInt(adds.item_id));
      });

    addStr = addarr.toString();

    let payload = {};

    payload = {
      channel: location.orderFor,
      outlet_id: outletId,
      delivery_area_id: outletData.delivery_area_id,
      purchase_detail_id: "",
      items: [
        {
          item_id: value.item_id,
          item_count: value.count,
          is_veg: value?.is_veg
            ? value.is_veg
            : value.item_tags.includes("1")
              ? true
              : false,
          image_url: value.item_image_url,
          price: value.single_item_price
            ? value.single_item_price
            : value.item_unit_price,
          final_price: value.single_item_price
            ? value.single_item_price
            : value.item_final_price,
          name: value.item_name,
          discount: 0,
          item_tags: ontaxCal,
          addons: value.addons,
          local_id: parseInt(value.item_id + addStr),
          selected_addons: addarr,
          category_name: value.categoryName,
        },
      ],
      source: outletData.outlet_status_info,
    };

    // if (value._id) {
    if (isRepeat) {
      payload.items[0]._id = value._id;
    }
    if (addNewIsRepeat && itemEqualData) {
      payload.items[0].item_count = itemEqualData.count + value.count;

      if (
        payload.items[0].addons &&
        payload.items[0].addons.length &&
        payload.items[0].addons.length > 0
      ) {
        const updatedAddons = payload.items[0].addons.map((data) => {
          data.item_count = itemEqualData.count + data.item_count;
          return data;
        });
      }

      if (itemEqualData?._id) {
        payload.items[0]._id = itemEqualData._id;
      }
    }

    addToCart(payload, (res) => {
      if (res && res?.response?.status === 200) {
        // console.log("checkout page rendered");
        getCartItems(
          `${outletId}&delivery_area_id=${LocalStorage.get("Outlet_Details").delivery_area_id}`,
          (res) => {
            console.log("get cart complete details", JSON.stringify(res));

            if (res && res?.response?.status === 200) {
              const data = onStructurize(res.content && res.content.items);
              setIsStrikeRequired(res.content.is_strike_required); // key to maintain striking the old price

              // LocalStorage.set(
              //   "CouponName",
              //   res.content.billing_data.coupon_used
              // );
              // setSelectedOffer(LocalStorage.get("offerwholedata"))
              if (res.content.hasOwnProperty("billing_data") == false) {
                setAlert("Your cart is empty now!!");
                LocalStorage.set("CartProducts", []);
                LocalStorage.set("CouponName", []);

                // alert('Your cart is empty');
                // history.push(ROUTES.PRODUCTLIST);
              } else {
                handleBranchFunc(res.content && res.content.billing_data);
                const totalAmount = getTotalAmount(data);
                if (isLoyalty) {
                  const loyDiscounts = getLoyalityBurningDisc(totalAmount);

                  setLoyaltyAmount(loyDiscounts.burningDiscount);
                  onCartDataValue(
                    data,
                    null,
                    loyDiscounts.burningDiscount,
                    res.content
                  );
                } else {
                  onCartDataValue(data, null, "", res.content);
                }
                if (selectedOffer && selectedOffer.coupon_code) {
                  onApplyOffer(selectedOffer, "apply", true);
                }
                if (LocalStorage.get("addUp") === true) {
                  LocalStorage.set("addUp", false);
                  setCustomizePopup(false);
                }
                LocalStorage.set("CartProducts", data);
                LocalStorage.set(
                  "CouponName",
                  res.content.billing_data.coupon_used
                );
                setUpdatedCartData(data);
              }
              setBillingDetailsData(res.content.billing_data);
              console.log("onAddToCart func", res.content.strip_data);
              setStripData(res.content.strip_data);
              setCartData(data);
              LocalStorage.set("addId", "");
              LocalStorage.set("addUp", false);
            }
            LocalStorage.set("addId", "");
            LocalStorage.set("addUp", false);
          }
        );
      } else if (res && res?.response?.status === 400) {
        setAlert(res?.response?.message);
        getCartItems(
          `${outletId}&delivery_area_id=${DeliveryAreaId}`,
          (res) => {
            console.log("get cart complete details", JSON.stringify(res));

            if (res && res?.response?.status === 200) {
              const data = onStructurize(res.content && res.content.items);
              setIsStrikeRequired(res.content.is_strike_required); // key to maintain striking the old price

              // LocalStorage.set(
              //   "CouponName",
              //   res.content.billing_data.coupon_used
              // );
              // setSelectedOffer(LocalStorage.get("offerwholedata"))
              if (res.content.hasOwnProperty("billing_data") == false) {
                setAlert("Your cart is empty now!!");
                LocalStorage.set("CartProducts", []);
                LocalStorage.set("CouponName", []);

                // alert('Your cart is empty');
                // history.push(ROUTES.PRODUCTLIST);
              } else {
                handleBranchFunc(res.content && res.content.billing_data);
                const totalAmount = getTotalAmount(data);
                if (isLoyalty) {
                  const loyDiscounts = getLoyalityBurningDisc(totalAmount);

                  setLoyaltyAmount(loyDiscounts.burningDiscount);
                  onCartDataValue(
                    data,
                    null,
                    loyDiscounts.burningDiscount,
                    res.content
                  );
                } else {
                  onCartDataValue(data, null, "", res.content);
                }
                if (selectedOffer && selectedOffer.coupon_code) {
                  onApplyOffer(selectedOffer, "apply", true);
                }
                if (LocalStorage.get("addUp") === true) {
                  LocalStorage.set("addUp", false);
                  setCustomizePopup(false);
                }
                LocalStorage.set("CartProducts", data);
                LocalStorage.set(
                  "CouponName",
                  res.content.billing_data.coupon_used
                );
                setUpdatedCartData(data);
              }
              setBillingDetailsData(res.content.billing_data);
              console.log("onAddToCart func", res.content.strip_data);
              setStripData(res.content.strip_data);
              setCartData(data);
              LocalStorage.set("addId", "");
              LocalStorage.set("addUp", false);
            }
            LocalStorage.set("addId", "");
            LocalStorage.set("addUp", false);
          }
        );
      }
      setLoderById((prevState) => ({
        ...prevState,
        [value._id]: false,
      }));
      setAddNewBtnLoader(false);
      handleProductModal(false);
    });
  };


  const onTaxCal = (value) => {
    let ontaxCal = [];
    taxCharges.map((item) => {
      value &&
        value.item_taxes &&
        value.item_taxes[0].taxes.map((values) => {
          if (item.tax_id === values) {
            const tax = {
              tax_tags: values,
              tax_value: Number(item.tax_value),
              amount: (item.tax_value * value.item_final_price) / 100,
            };
            ontaxCal.push(tax);
          }
        });
    });
    return ontaxCal;
  };

  const onAddonsStructure = (value) => {
    //
    let addonsCart = [];
    if (value.groups && value.groups.length > 0) {
      value.groups.map((item) => {
        if (item && item?.items?.length > 0) {
          const updateAddons = item.items.filter((val) => val.checked === true);
          if (updateAddons.length > 0) {
            updateAddons.map((vl) => {
              const itemValue = {
                item_id: vl.item_id,
                item_name: vl.item_name,
                group_id: item.group_id,
                item_count: value.count,
                final_price: vl.item_final_price,
                group_name: item.group_name,
                discount: 1,
                image_url: vl.item_image_url,
                item_tags: onTaxCal(vl),
              };
              addonsCart.push(itemValue);
              LocalStorage.set("Addons", [].push(addonsCart));
            });
          }
        }
      });
    } else {
      if (value.addons && value.addons.length && value.addons.length > 0) {
        value.addons.map((data) => {
          const copyData = {
            item_id: data.item_id,
            item_name: data.item_name,
            group_id: data.category_id,
            item_count: value.count,
            final_price: data.single_item_price,
            group_name: data.category_name,
            discount: 1,
            image_url: data.image_url,
            item_tags: onTaxCal(data),
          };

          addonsCart.push(copyData);
        });
      }
    }
    return addonsCart;
  };

  const onUpdateContent = (item, addons, isRepeat, addNewIsRepeat) => {
    if (addons && addons.length > 0) {
      item.groups = addons;
    }
    let productData = [];
    let addonData = onAddonsStructure(item);
    // LocalStorage.set('Addons',addonData?.addons);
    item.addons = addonData;

    if (LocalStorage.get("isLoggedIn")) {
      onAddToCart(item, isRepeat, addNewIsRepeat);
    } else {
      let cartFilter = cartData.filter(
        (items) => items.item_id === item.item_id
      );
      let updateCart = [];
      if (cartFilter.length > 0) {
        updateCart = cartData.map((items) => {
          if (items.item_id === item.item_id) {
            items.count = item.count;
            items.addons = item.addons;
          }
          return items;
        });
      } else {
        updateCart = [...cartData, item];
      }
      updateCart = updateCart.filter((value) => value.count > 0);
      setCartData(updateCart);
      if (updateCart.length === 0) {
        setAlert("Your cart is empty now!!");
        LocalStorage.set("CartProducts", []);

        // alert('Your cart is empty');
        // history.push(ROUTES.PRODUCTLIST);
      }
      onCartDataValue(updateCart, null, loyaltyAmount);
      LocalStorage.set("CartProducts", updateCart);
    }
  };

  const addonPopup = (item) => {
    // console.log("addonpopup", item);
    setCustomizeData(item && item);
    setCustomizePopup(!customizePopup);
    LocalStorage.set("addUp", false);
  };

  const repeatCustom = (item) => {
    // console.log("repeatcustom", item);
    setIsAddons(false);

    item.addons &&
      item.addons.length > 0 &&
      item.addons.map((i) => {
        i.item_count = parseInt(i.item_count) + 1;
      });
    LocalStorage.set("addId", item._id);
    item.count = parseInt(item.count) + 1;
    item._id = item._id;
    onUpdateContent(item, [], true);
    Analytics.addToCardEvent(item);
    addonPopup(item);
  };

  const addNewProduct = (data) => {
    setIsAddons(true);
    LocalStorage.set("addUp", true);
    itemData1 &&
      itemData1.map((item) => {
        if (item.item_id === data.item_id) {
          setProductModal(!productModal);
          item.count = data.count;
          setProductDataAdd(item);
          setExtras(item.groups);
        }
      });
    // history.push(ROUTES.ADDTOCART, { data: data });
  };
  const handleDeliveryCharge = (totalPrice) => {
    const outletInfo = LocalStorage.get("Outlet_Details");
    const deliveryAreaThresholdAmt =
      outletInfo.delivery_area_order_threshold_amount
        ? Number(
          parseFloat(outletInfo.delivery_area_order_threshold_amount).toFixed(
            2
          )
        )
        : 0;

    let deliveryCharge = 0;
    if (totalPrice <= deliveryAreaThresholdAmt) {
      deliveryCharge = outletInfo.delivery_area_delivery_charge
        ? Number(
          parseFloat(outletInfo.delivery_area_delivery_charge).toFixed(2)
        )
        : 0;
    }

    if (deliveryAreaThresholdAmt === 0) {
      deliveryCharge = outletInfo.delivery_area_delivery_charge
        ? Number(
          parseFloat(outletInfo.delivery_area_delivery_charge).toFixed(2)
        )
        : 0;
    }

    return deliveryCharge;
  };

  console.log("billingdetailsdata", JSON.stringify(billingDetailsData));

  const onCartDataValue = (
    productData,
    discount,
    loyaltyDiscount,
    initialBillingData,
    taxesvalue
  ) => {
    let productDatataxdatas = [];
    productData &&
      productData.forEach((data) => {
        data?.item_tags.forEach((taxData) => {
          productDatataxdatas.push(taxData.tax_value);
        });
      });
    setTaxDatas(JSON.stringify(productDatataxdatas).replaceAll(",", "%+"));
    let taxDisplay = [
      ...new Set(taxesvalue ? taxesvalue : productDatataxdatas),
    ];

    let cgst = 0;
    let sgst = 0;
    let discountValue = discountAmount;
    if (discount !== undefined) {
      discountValue = discount;
    }
    const location = LocalStorage.get("location");
    const isLoggedIn = LocalStorage.get("isLoggedIn");
    const giftVoucherAmtData = handleParseAmount(
      giftVoucherAmt && giftVoucherAmt != 0 ? giftVoucherAmt : 0
    );
    const discountLocal =
      LocalStorage.get("offerAmount") !== null
        ? LocalStorage.get("offerAmount")
        : 0;
    loyaltyDiscountAmt = initialBillingData?.billing_data
      ? initialBillingData?.billing_data.loyalty_discount
      : billingDetailsData.loyalty_discount;
    //  loyaltyDiscount
    //   ? Number(parseFloat(loyaltyDiscount))
    //   : (initialBillingData ? initialBillingData.loyalty_discount : billingDetailsData.loyalty_discount)
    //   ? Number(parseFloat(initialBillingData ? initialBillingData.loyalty_discount : billingDetailsData.loyalty_discount))
    //   : 0;
    if (productData && productData.length > 0) {
      let totalPrice = 0;
      // totalPrice = handleGetTotalAmt(productData);
      totalPrice = initialBillingData?.billing_data
        ? initialBillingData?.billing_data.sub_total
        : billingDetailsData.sub_total;
      let taxation = LocalStorage.get("taxes");
      taxation?.map((items) => {
        if (items.tax_id === "1") {
          const price =
            totalPrice -
            discountLocal -
            loyaltyDiscountAmt +
            Math.round(
              initialBillingData?.billing_data
                ? initialBillingData?.billing_data.packing_charge
                : billingDetailsData.packing_charge
            ) +
            handleDeliveryCharge(totalPrice);
          cgst = (price * Number(items.tax_value)) / 100;
        }
        if (items.tax_id === "2") {
          const price =
            totalPrice -
            discountLocal -
            loyaltyDiscountAmt +
            Math.round(
              initialBillingData?.billing_data
                ? initialBillingData?.billing_data.packing_charge
                : billingDetailsData.packing_charge
            ) +
            handleDeliveryCharge(totalPrice);
          sgst = (price * Number(items.tax_value)) / 100;
        }
      });
      const totalAmount = (
        totalPrice +
        Number(parseFloat(sgst).toFixed(2)) +
        Number(parseFloat(sgst).toFixed(2)) -
        loyaltyDiscountAmt -
        discountLocal -
        giftVoucherAmtData +
        Number(tip) +
        handleDeliveryCharge(totalPrice) +
        // Number(
        //   parseFloat(deliveryCharge).toFixed(2)
        // )
        Number(donation) +
        Math.round(
          initialBillingData?.billing_data
            ? initialBillingData?.billing_data.packing_charge
            : billingDetailsData.packing_charge
        )
      ).toFixed(2);
      console.log("loyalll", Number(loyaltyDiscountAmt));
      console.log()
      console.log("INITAIL BILLING DATA", initialBillingData)
      const billingDetails = [
        {
          name: "Sub Total",
          amount: `₹ ${Math.floor(
            Number(
              initialBillingData?.billing_data
                ? initialBillingData?.billing_data.sub_total
                : billingDetailsData.sub_total
            )
          )}`,
          show: true,
        },
        {
          name: "Discount",
          amount: ` - ₹ ${Math.floor(
            Number(
              initialBillingData?.billing_data
                ? initialBillingData?.billing_data.offer_discount
                : billingDetailsData.offer_discount
            )
          )}`,
          show:
            Math.floor(
              Number(
                initialBillingData?.billing_data
                  ? initialBillingData?.billing_data.offer_discount
                  : billingDetailsData.offer_discount
              )
            ) === 0
              ? false
              : true,
        },
        {
          name: "Loyalty Discount",
          amount: ` - ₹ ${Number(loyaltyDiscountAmt).toFixed(2)}`,
          show: loyaltyDiscountAmt > 0 ? true : false,
        },
        {
          name: "Donation",
          amount: `+ ₹ ${donation}`,
          show: donation === 0.0 ? false : true,
        },
        {
          name: "Delivery Charge",
          amount: `${initialBillingData?.strip_data?.delivery_strip
            ?.is_show_free_delivery === true
            ? `₹ ${Number(
              initialBillingData?.strip_data.delivery_strip.amount
            )}`
            : `₹ ${Math.floor(
              Number(
                initialBillingData?.billing_data
                  ? initialBillingData?.billing_data.delivery_charges
                  : billingDetailsData.delivery_charges
              )
            )}`
            }`,
          show: location.orderFor === "takeaway" ? false : true,
          textColor:
            initialBillingData?.strip_data?.delivery_strip
              ?.is_show_free_delivery === true
              ? true
              : false,
          message: initialBillingData?.billing_data?.delivery_message
        }, {
          name: "Delivery Surcharge",
          amount: `₹ ${Math.floor(
            Number(
              initialBillingData?.billing_data
                ? initialBillingData?.billing_data.delivery_surcharge
                : billingDetailsData.delivery_surcharge
            )
          )}`,
          show: (initialBillingData?.billing_data && initialBillingData?.billing_data.delivery_surcharge && initialBillingData?.billing_data.delivery_surcharge > 0),
        },
        {
          name: "Packing Charge",
          amount: `${initialBillingData?.strip_data?.packing_charge_strip
            ?.is_show_free_packing_charge === true
            ? `₹ ${Number(
              initialBillingData?.strip_data.packing_charge_strip.amount
            )}`
            : `₹ ${Math.floor(
              Number(
                initialBillingData?.billing_data
                  ? initialBillingData?.billing_data.packing_charge
                  : billingDetailsData.packing_charge
              )
            )}`
            }`,
          show: true,
          textColor:
            initialBillingData?.strip_data?.packing_charge_strip
              ?.is_show_free_packing_charge === true
              ? true
              : false,
        },
        {
          name: "Tip",
          amount: `+ ₹ ${tip}`,
          show: tip === 0 ? false : true,
        },
        {
          name:
            taxDisplay.length > 0
              ? `CGST${" "}(${JSON.stringify(taxDisplay)
                .replaceAll(",", "% + ")
                .replaceAll("[", "")
                .replaceAll("]", "")}${"%"})`
              : `CGST`,
          amount: `₹ ${Number(
            parseFloat(
              initialBillingData?.billing_data
                ? initialBillingData?.billing_data.cgst
                : billingDetailsData.cgst
            ).toFixed(2)
          )}`,
          show: true,
        },
        {
          name:
            taxDisplay.length > 0
              ? `SGST${" "}(${JSON.stringify(taxDisplay)
                .replaceAll(",", "% + ")
                .replaceAll("[", "")
                .replaceAll("]", "")}${"%"})`
              : `SGST`,
          amount: `₹ ${Number(
            parseFloat(
              initialBillingData?.billing_data
                ? initialBillingData?.billing_data.sgst
                : billingDetailsData.sgst
            ).toFixed(2)
          )}`,
          show: true,
        },
        {
          name: "Gift card",
          amount: ` - ₹ ${giftVoucherAmtData}`,
          show: giftVoucherAmtData ? true : false,
        },
        {
          name: "Total Amount",
          amount: `₹ ${Math.floor(
            Number(
              initialBillingData?.billing_data
                ? initialBillingData?.billing_data.paid_amount
                : billingDetailsData.paid_amount
            )
          )}`,
          show: true,
        },
      ];

      LocalStorage.set("subtotal", totalPrice);
      setTaxDatas(
        `${JSON.stringify(taxDisplay)
          .replaceAll(",", "% + ")
          .replaceAll("[", "")
          .replaceAll("]", "")}${"%"}`
      );
      setOutletData(billingDetails);

      handleOrderLessZeroAmt(totalAmount);

      if (LocalStorage.get("isLoggedIn")) {
        if (LocalStorage.get("cartcountview") === 1) {
          LocalStorage.set("cartcountview", 0);
          const user = LocalStorage.get("UserData");
        }
      }
    } else {
      const billingDetails = [
        {
          name: "Sub Total",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "Discount",
          amount: `- ₹ ${discountAmount}`,
          show: false,
        },
        {
          name: "Donation",
          amount: `+ ₹ 0`,
          show: false,
        },
        {
          name: "Delivery Charge",
          amount: `₹ 0`,
          show: true,
          textColor: false,
        },
        {
          name: "Tip",
          amount: `+ ₹ 0`,
          show: false,
        },
        {
          name: "Packing Charge",
          amount: `₹ 0`,
          show: true,
          textColor: false,
        },
        {
          name: "CGST",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "SGST",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "Total Amount",
          amount: `₹ 0`,
          show: true,
        },
      ];
      setOutletData(billingDetails);
    }
  };
  useEffect(() => {
    console.log("outletDataaaaa ", outletData, " strip", stripData)
  }, [outletData, stripData]);

  const onStructurize = (data) => {
    if (data && data.length > 0) {
      const dataItem = data.map((items) => {
        const item = {
          _id: items._id,
          item_name: items.name,
          // item_final_price: items.single_item_price,
          item_final_price: items.final_price,
          item_tags: items.item_tags,
          count: Number(items.item_count),
          item_id: items.item_id,
          item_image_url: items.image_url,
          item_unit_price: items.single_item_price,
          addons: items.addons,
          complimentary_item: items.complimentary_item,
          _id: items._id,
          is_veg: items.is_veg,
          categoryName: items?.category_name,
          discount: parseFloat(items?.discount)
        };
        return item;
      });
      return dataItem;
    }
    return [];
  };

  const addCartData = (
    <div className="checkout-page_imgCards">
      {categoryData.map((item) => (
        <div className="checkout-page__suggestion-card">
          <div className="checkout-page__suggestion-card-wrapper">
            <img
              className="checkout-page__suggestion-card-img"
              src={item.item_image_url}
              alt=""
            />
            <div>
              <p className="checkout-page__card-header">{item.item_name}</p>
              {/* <p className='checkout-page__card-description'>
                    {item.item_short_description}
                  </p> */}
            </div>
          </div>
          <div className="checkout-page__suggestion-card-product">
            <p className="checkout-page__card-amount">
              ₹ {item.item_final_price}
            </p>
            {item.count === 0 ? (
              <Button
                content="Add"
                onClick={() => subCount(item, "add")}
                className="checkout-page__add-btn"
              />
            ) : (
              <NewButton
                item={item}
                content={item.count}
                className="productSummary_product_data_button checkout-page__add-new-btn"
                subCount={subCount}
                isLoading={loaderById[item._id]}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const addAddress1 = () => {
    // setModalOpen(!modalOpen);
    handleListAddressModal();
    // setOpenAddressForm(true);
  };
  const submit = () => {
    if (address !== "") {
      const location = LocalStorage.get("location").address;
      const locationArr = location.split(",");
      let payload = {};
      if (availableAddresses === []) {
        // history.push(ROUTES.PROFILE)
        payload = {
          address_id: "0",
          city: locationArr[locationArr.length - 3],
          pin_code: locationArr[locationArr.length - 2],
          AddressType: othersTxt,
          location_name:
            locationArr[locationArr.length - 5] +
            locationArr[locationArr.length - 4],
          address_1: location,
          address_2: address,
          latitude: LocalStorage.get("location").lat,
          longitude: LocalStorage.get("location").lng,
          Google_Address: address,
        };
        addAddressNew(payload, (res) => {
          if (res && res?.response?.status === 200) {
            //   setNavStat('2');
            getAddress((res) => {
              if (res && res?.response?.status === 200) {
                // setAddressData(res?.content?.address);
              }
            });
            // addAddress1();
          }
        });
      }

      addAddress1();

      const user = LocalStorage.get("UserData");
      // const script = document.createElement("script");
      // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
      // script.onload = () => {
      //   smartech("identify", "91" + user.mobile);
      //   smartech("dispatch", "Checkout Address", {
      //     address: address + ", " + LocalStorage.get("location").address,
      //     pageurl: "https://web-frontend.bbkbiryani.com/webapp/checkout",
      //   });
      // };
      // document.head.appendChild(script);
    }
  };

  const addressChange = () => {
    if (innerWidth <= 1023) {
      history.push(ROUTES.DELIVERY_LOCATION);
    } else {
      setModalOpen(false);
      setDeliveryFlow(true);
    }
  };

  const onLoginClick = () => {
    if (LocalStorage.get("isLoggedIn") === true) {
      history.push(ROUTES.HOME);
    } else {
      history.push({
        pathname: ROUTES.LOGIN,
        state: { redirectToCheckOut: true },
      });
    }
  };

  const onSignUp = () => {
    history.push({
      pathname: ROUTES.SIGNIN,
      state: { redirectToCheckOut: true },
    });
  };

  const onBack = () => {
    history.go("-1");
  };

  const onCloseDonation = (value, amount) => {
    if (value === true && regex.amountRegex.test(amount)) {
      setDonation(amount);
      setIsDonate(true);
      setOpenDonation(false);
      setDonationClose("");
      onSetDonation(amount, cartData, "");
    } else if (value === true) {
      setOpenDonation(true);
      setDonationClose("Please enter a valid amount to donate");
    } else {
      setIsDonate(value);
      setOpenDonation(value);
      setDonationClose("");
    }
  };

  const onClickDonation = (value) => {
    setDonation(value);
  };

  const onChangeDonation = (id, value) => {
    setDonation(value);
  };

  const ShowParticular = (i) => {
    setShowOffInfo(true);
    setShowOffData(i);
  };

  const closeParticular = () => {
    setShowOffInfo(false);
  };

  const onApplyOffer = (item, value, isShowMessage) => {
    const distance=LocalStorage.get('userDistance')
    if (
      (localOfferData && localOfferData.length > 0) ||
      offerData.length > 0 ||
      (unAvailableOffers && unAvailableOffers.length > 0)
    ) {
      const outlet = LocalStorage.get("outlet_id");
      const outeltData = LocalStorage.get("Outlet_Details");
      const location = LocalStorage.get("location");
      const outletID = LocalStorage.get("outlet_id");
      const DeliveryAreaId = LocalStorage.get("delivery_area_id");
      const payload = {
        delivery_area_id: outeltData.delivery_area_id, //pass delivery area id
        coupon_code: item?.coupon_code ? item?.coupon_code : item,
        online_payment: payMode === "cod" || payMode === "COD" ? false : true,
        outlet_id: outlet,
        channel: location.orderFor === "delivery" ? "delivery" : "take_away",
        source_id: outeltData.outlet_status_info.SourceId,
        is_apply_coupon: value === "apply" ? true : false,
        user_distance:distance,
      };
      applyOffers(payload, (res) => {
        if (res && res?.response?.status === 200) {
          if (res?.content?.offer_amount && value === "apply") {
            LocalStorage.set(
              "offerAmount",
              Number(parseFloat(res.content.offer_amount).toFixed(2))
            );
            !isShowMessage &&
              setIsMsg({
                toastType: true,
                toastText: "Code successfully applied",
                isShow: true,
              });
            onCartDataValue(
              cartData,
              LocalStorage.get("offerAmount"),
              loyaltyAmount
              // Number(parseFloat(res.content.offer_amount).toFixed(2))
            );
            setDiscountAmount(
              LocalStorage.get("offerAmount")
              // Number(parseFloat(res.content.offer_amount).toFixed(2))
            );

            getCartItems(
              `${outletID}&delivery_area_id=${DeliveryAreaId}`,
              (res) => {
                //console.log("get cart complete details", JSON.stringify(res));
                if (res && res?.response?.status === 200) {
                  handleBranchFunc(res.content.billing_data);
                  const data = onStructurize(res.content.items);
                  setIsStrikeRequired(res.content.is_strike_required); // key to maintain striking the old price

                  LocalStorage.set(
                    "CouponName",
                    res.content.billing_data.coupon_used
                  );
                  setSelectedOffer(LocalStorage.get("offerwholedata"));
                  if (data.length === 0) {
                    setAlert("Your cart is empty now!!");
                    LocalStorage.set("CartProducts", []);
                    LocalStorage.set("CouponName", []);

                    // alert('Your cart is empty');
                    // history.push(ROUTES.PRODUCTLIST);
                  } else {
                    onCartDataValue(
                      data,
                      LocalStorage.get("offerAmount"),
                      loyaltyAmount,
                      res.content
                    );
                    LocalStorage.set("CartProducts", data);
                    LocalStorage.set(
                      "CouponName",
                      res.content.billing_data.coupon_used
                    );
                  }
                  setBillingDetailsData(res.content.billing_data);
                  setCartData(data);
                  setStripData(res.content.strip_data);
                }
              }
            );
            const user = LocalStorage.get("UserData");

            const payloadData = {
              couponcode: item.coupon_code,
              coupon_discount: Number(
                parseFloat(res.content.offer_amount).toFixed(2)
              ),
              coupon_status: "Valid",
              coupon_error: "NA",
            };
            // couponAppliedNetCoreEvent(payloadData)
            LocalStorage.set("offerwholedata", item);
            setSelectedOffer(LocalStorage.get("offerwholedata"));
          } else {
            setDiscountAmount(0);
            onCartDataValue(cartData, 0, loyaltyAmount);
            setSelectedOffer({});

            LocalStorage.set("offerAmount", 0);
            LocalStorage.remove("offerwholedata");
            onCartDataValue(
              cartData,
              LocalStorage.get("offerAmount")
              // Number(parseFloat(res.content.offer_amount).toFixed(2))
            );
            setDiscountAmount(
              LocalStorage.get("offerAmount")
              // Number(parseFloat(res.content.offer_amount).toFixed(2))
            );

            getCartItems(
              `${outletID}&delivery_area_id=${DeliveryAreaId}`,
              (res) => {
                console.log("get cart complete details", JSON.stringify(res));
                if (res && res?.response?.status === 200) {
                  // setBillingDetailsData(res.content.billing_data);

                  handleBranchFunc(res.content.billing_data);
                  const data = onStructurize(res.content.items);
                  setIsStrikeRequired(res.content.is_strike_required); // key to maintain striking the old price

                  // LocalStorage.set(
                  //   "CouponName",
                  //   res.content.billing_data.coupon_used
                  // );
                  // setSelectedOffer(LocalStorage.get("offerwholedata"))
                  if (data.length === 0) {
                    setAlert("Your cart is empty now!!");
                    LocalStorage.set("CartProducts", []);
                    LocalStorage.set("CouponName", []);

                    // alert('Your cart is empty');
                    // history.push(ROUTES.PRODUCTLIST);
                  } else {
                    onCartDataValue(
                      data,
                      LocalStorage.get("offerAmount"),
                      loyaltyAmount,
                      res.content
                    );
                    LocalStorage.set("CartProducts", data);
                    LocalStorage.set(
                      "CouponName",
                      res.content.billing_data.coupon_used
                    );
                  }
                  setBillingDetailsData(res.content.billing_data);
                  setCartData(data);
                  setStripData(res.content.strip_data);
                }
              }
            );
          }
        } else {
          setDiscountAmount(0);
          LocalStorage.remove("offerAmount");
          onCartDataValue(cartData, 0, loyaltyAmount);
          setSelectedOffer({});
          setAlert(res && res?.response?.message);
        }
      });
    } else {
      setAlert("No offers found");
    }
  };

  const onSetDonation = (val, productData, taxesvalue) => {
    let initialBillingData;
    setCommonData((prevState) => {
      return {
        ...prevState,
        donation: val,
      };
    });

    let taxDisplay = [...new Set(taxesvalue ? taxesvalue : taxDatas)];

    let cgst = 0;
    let sgst = 0;
    let discountValue = discountAmount;
    const outletData = LocalStorage.get("Outlet_Details");
    const isLoggedIn = LocalStorage.get("isLoggedIn");
    const discountLocal =
      LocalStorage.get("offerAmount") !== null
        ? LocalStorage.get("offerAmount")
        : 0;
    const loyaltyDiscountAmt = loyaltyAmount
      ? Number(parseFloat(loyaltyAmount))
      : 0;
    const giftVoucherAmtData = handleParseAmount(
      giftVoucherAmt && giftVoucherAmt != 0 ? giftVoucherAmt : 0
    );

    if (productData && productData.length > 0) {
      let totalPrice = 0;
      totalPrice = handleGetTotalAmt(productData);
      taxCharges.map((items) => {
        if (items.tax_id === "1") {
          const price =
            totalPrice -
            discountLocal -
            loyaltyDiscountAmt +
            handleDeliveryCharge(totalPrice);
          cgst = (price * Number(items.tax_value)) / 100;
        }
        if (items.tax_id === "2") {
          const price =
            totalPrice -
            discountLocal -
            loyaltyDiscountAmt +
            handleDeliveryCharge(totalPrice);
          sgst = (price * Number(items.tax_value)) / 100;
        }
      });

      const deliveryCharge = outletData.delivery_area_delivery_charge
        ? outletData.delivery_area_delivery_charge
        : 0;

      const totalAmount =
        Math.floor(Number(billingDetailsData.paid_amount)) +
        Number(val) +
        Number(commonData.tip);

      const billingDetails = [
        {
          name: "Sub Total",
          amount: `₹ ${Math.floor(Number(billingDetailsData.sub_total))}`,
          show: true,
        },
        {
          name: "Discount",
          amount: ` - ₹ ${discountValue}`,
          show: discountValue === 0 ? false : true,
        },
        {
          name: "Donation",
          amount: `+ ₹ ${val}`,
          show: val === 0 ? false : true,
        },
        {
          name: "Loyalty Discount",
          amount: ` - ₹ ${Number(loyaltyDiscountAmt).toFixed(2)}`,
          show: loyaltyDiscountAmt > 0 ? true : false,
        },
        {
          name: "Delivery Charge",
          amount: `${initialBillingData?.strip_data?.delivery_strip
            ?.is_show_free_delivery === true
            ? `₹ ${initialBillingData?.strip_data.delivery_strip.amount}`
            : `₹ ${Math.floor(Number(billingDetailsData.delivery_charges))}`
            }`,
          show: true,
          textColor:
            initialBillingData?.strip_data?.delivery_strip
              ?.is_show_free_delivery === true
              ? true
              : false,
          message: billingDetailsData?.delivery_message,

        },
        {
          name: "Packing Charge",
          amount: `${initialBillingData?.strip_data?.packing_charge_strip
            ?.is_show_free_packing_charge === true
            ? `₹ ${initialBillingData?.strip_data.packing_charge_strip.amount}`
            : `₹ ${Math.floor(Number(billingDetailsData.packing_charge))}`
            }`,
          show: true,
          textColor:
            initialBillingData?.strip_data?.packing_charge_strip
              ?.is_show_free_packing_charge === true
              ? true
              : false,

        },
        {
          name: "Tip",
          amount: `+ ₹ ${tip}`,
          show: tip === 0 ? false : true,
        },
        {
          name: taxDisplay.length > 0 ? `CGST (${taxDatas})` : `CGST`,
          amount: `₹ ${Number(parseFloat(billingDetailsData.cgst).toFixed(2))}`,
          show: true,
        },
        {
          name: taxDisplay.length > 0 ? `SGST (${taxDatas})` : `SGST`,
          amount: `₹ ${Number(parseFloat(billingDetailsData.sgst).toFixed(2))}`,
          show: true,
        },
        {
          name: "Gift card",
          amount: ` - ₹ ${giftVoucherAmtData}`,
          show: giftVoucherAmtData ? true : false,
        },
        {
          name: "Total Amount",
          amount: `₹ ${Math.floor(totalAmount)}`,
          show: true,
        },
      ];
      setOutletData(billingDetails);
    } else {
      
      const billingDetails = [
        {
          name: "Sub Total",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "Discount",
          amount: `- ₹ ${discountAmount}`,
          show: false,
        },
        {
          name: "Donation",
          amount: `+ ₹ ${val}`,
          show: false,
        },
        {
          name: "Delivery Charge",
          amount: `₹ 0`,
          show: true,
          textColor: false,
        },
        {
          name: "Packing Charge",
          amount: `${initialBillingData?.strip_data?.packing_charge_strip
            ?.is_show_free_packing_charge === true
            ? `₹ ${initialBillingData?.strip_data.packing_charge_strip.amount}`
            : `₹ ${Math.floor(Number(billingDetailsData.packing_charge))}`
            }`,
          show: true,
          textColor: false,
        },
        {
          name: "Tip",
          amount: `+ ₹ 0`,
          show: false,
        },
        {
          name: "CGST",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "SGST",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "Total Amount",
          amount: `₹ 0`,
          show: true,
        },
      ];
      setOutletData(billingDetails);
    }
  };

  const onSetTip = (val, productData, taxesvalue) => {
    console.log("TIP BILLING",billingDetailsData)
    let initialBillingData;
    // console.log("insideonsettipfunc");
    setCommonData((prevState) => {
      return {
        ...prevState,
        tip: val,
      };
    });
    let taxDisplay = [...new Set(taxesvalue ? taxesvalue : taxDatas)];

    let cgst = 0;
    let sgst = 0;
    let discountValue = discountAmount;
    const outletData = LocalStorage.get("Outlet_Details");
    const isLoggedIn = LocalStorage.get("isLoggedIn");
    const discountLocal =
      LocalStorage.get("offerAmount") !== null
        ? LocalStorage.get("offerAmount")
        : 0;
    const loyaltyDiscountAmt = loyaltyAmount
      ? Number(parseFloat(loyaltyAmount))
      : 0;
    const giftVoucherAmtData = handleParseAmount(
      giftVoucherAmt && giftVoucherAmt != 0 ? giftVoucherAmt : 0
    );

    if (productData && productData.length > 0) {
      let totalPrice = LocalStorage.get("subtotal");
      let taxation = LocalStorage.get("taxes");

      taxation.map((items) => {
        if (items.tax_id === "1") {
          const price =
            totalPrice -
            discountLocal -
            loyaltyDiscountAmt +
            handleDeliveryCharge(totalPrice);
          cgst = (price * Number(items.tax_value)) / 100;
        }
        if (items.tax_id === "2") {
          const price =
            totalPrice -
            discountLocal -
            loyaltyDiscountAmt +
            handleDeliveryCharge(totalPrice);
          sgst = (price * Number(items.tax_value)) / 100;
        }
      });

      const deliveryCharge = outletData.delivery_area_delivery_charge
        ? outletData.delivery_area_delivery_charge
        : 0;

      const totalAmount =
        Math.floor(Number(billingDetailsData.paid_amount)) +
        Number(val) +
        Number(commonData.donation);


      const billingDetails = [
        {
          name: "Sub Total",
          amount: `₹ ${Math.floor(Number(billingDetailsData.sub_total))}`,
          show: true,
        },
        {
          name: "Discount",
          amount: ` - ₹ ${discountLocal}`,
          show: discountLocal === 0 ? false : true,
        },
        {
          name: "Loyalty Discount",
          amount: ` - ₹ ${Number(loyaltyDiscountAmt).toFixed(2)}`,
          show: loyaltyDiscountAmt > 0 ? true : false,
        },
        {
          name: "Donation",
          amount: `+ ₹ ${donation}`,
          show: donation === 0 ? false : true,
        },
        {
          name: "Delivery Charge",
          amount: `${initialBillingData?.strip_data?.delivery_strip
            ?.is_show_free_delivery === true
            ? `₹ ${initialBillingData?.strip_data.delivery_strip.amount}`
            : `₹ ${Math.floor(Number(billingDetailsData.delivery_charges))}`
            }`,
          show: true,
          textColor:
            initialBillingData?.strip_data?.delivery_strip
              ?.is_show_free_delivery === true
              ? true
              : false,
          message: billingDetailsData.delivery_message
        },
        {
          name: "Packing Charge",
          amount: `${initialBillingData?.strip_data?.packing_charge_strip
            ?.is_show_free_packing_charge === true
            ? `₹ ${initialBillingData?.strip_data.packing_charge_strip.amount}`
            : `₹ ${Math.floor(Number(billingDetailsData.packing_charge))}`
            }`,
          show: true,
          textColor:
            initialBillingData?.strip_data?.packing_charge_strip
              ?.is_show_free_packing_charge === true
              ? true
              : false,
        },
        {
          name: "Tip",
          amount: `+ ₹ ${val}`,
          show: val === 0 ? false : true,
        },
        {
          name: taxDisplay.length > 0 ? `CGST (${taxDatas})` : `CGST`,
          amount: `₹ ${Number(parseFloat(billingDetailsData.cgst).toFixed(2))}`,
          show: true,
        },
        {
          name: taxDisplay.length > 0 ? `SGST (${taxDatas})` : `SGST`,
          amount: `₹ ${Number(parseFloat(billingDetailsData.sgst).toFixed(2))}`,
          show: true,
        },
        {
          name: "Gift card",
          amount: ` - ₹ ${giftVoucherAmtData}`,
          show: giftVoucherAmtData ? true : false,
        },
        {
          name: "Total Amount",
          amount: `₹ ${Math.floor(totalAmount)}`,
          show: true,
        },
      ];
      setOutletData(billingDetails);
    } else {
      const billingDetails = [
        {
          name: "Sub Total",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "Discount",
          amount: `- ₹ ${discountAmount}`,
          show: false,
        },
        {
          name: "Donation",
          amount: `+ ₹ ${val}`,
          show: false,
        },
        {
          name: "Delivery Charge",
          amount: `₹ 0`,
          show: true,
          textColor: false,
        },
        {
          name: "Packing Charge",
          amount: `₹ 0}`,
          show: true,
          textColor: false,
        },
        {
          name: "Tip",
          amount: `+ ₹ 0`,
          show: false,
        },
        {
          name: "CGST",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "SGST",
          amount: `₹ 0`,
          show: true,
        },
        {
          name: "Total Amount",
          amount: `₹ 0`,
          show: true,
        },
      ];
      setOutletData(billingDetails);
    }
  };

  const getLoyalityBurningDisc = (totalPrice) => {
    const onLoyaltyPenny = Number(parseFloat(loyalty?.is_loyalty_offer_combined[0].loyalty_points_for_one_penny).toFixed(2));
    let LoyaltyPoints = Number(loyalty?.loyalty_percentage[0].loyalty_consume_percentage);
    let burningPercentage = (totalPrice * Number(parseFloat(LoyaltyPoints))) / 100;
    let burningDiscount = Number(burningPercentage * onLoyaltyPenny).toFixed(2);
    const one_point = Number(loyalty.current_points);
    if (one_point < Number(burningPercentage)) {
      burningDiscount = Number(one_point * onLoyaltyPenny).toFixed(2);
      LoyaltyPoints = one_point;
    }
    return { burningPercentage, burningDiscount };
  };

  const getTotalAmount = (cartItems) => {
    let totalPrice = 0;
    totalPrice = handleGetTotalAmt(cartItems);
    // cartItems && cartItems.map((items) => {
    //   totalPrice = Number(
    //     parseFloat(
    //       Number(parseFloat(items.item_final_price)) * items.count +
    //         totalPrice
    //     ).toFixed(2)
    //   );
    // });
    return totalPrice;
  };

  const getLoyalityDetails = () => {
    const one_point = Number(loyalty.current_points);
    let points = 0;
    let discout = 0;
    const totalPrice = billingDetailsData && billingDetailsData.amount_to_find_loyalty;
    const loyDiscounts = getLoyalityBurningDisc(totalPrice);
    if (one_point < Number(loyDiscounts.burningPercentage)) {
      const onLoyaltyPenny = Number(parseFloat(loyalty?.is_loyalty_offer_combined[0].loyalty_points_for_one_penny).toFixed(2));
      discout = Number(one_point * onLoyaltyPenny).toFixed(2); points = one_point;
    }
    else {
      points = Number(loyDiscounts.burningPercentage).toFixed(2);
      discout = Number(loyDiscounts.burningDiscount).toFixed(2);
    }
    return {
      points,
      discout,
    };
  };

  // console.log("loyaltyamt", loyaltyAmount);
  const onChangeLoyality = (isCheckedVal) => {
    
    const distance=LocalStorage.get('userDistance')

    // console.log("isCheckedVal", isCheckedVal);
    let payload = {};
    console.log(" clicked ");
    if (isCheckedVal) {
      payload = {
        is_use_loyalty: true,
        outlet_id: outletDataDetails.outlets_id,
        channel: LocalStorage.get("location").orderFor,
        delivery_area_id: outletDataDetails.delivery_area_id,
        user_distance:distance,
      };

      let totalPrice = 0;
      const one_point = Number(loyalty.current_points);
      if (loyalty && loyalty.loyalty_percentage.length > 0) {
        const onLoyaltyCombine =
          loyalty.is_loyalty_offer_combined[0].loyalty_combined;
        if (one_point > 0) {
          totalPrice = handleGetTotalAmt(cartData);

          const loyDiscounts = getLoyalityBurningDisc(totalPrice);

          if (onLoyaltyCombine) {
            onCartDataValue(cartData, null, loyDiscounts.burningDiscount);
            setLoyaltyAmount(loyDiscounts.burningDiscount);
          } else {
            onCartDataValue(cartData, null, loyDiscounts.burningDiscount);
            setLoyaltyAmount(loyDiscounts.burningDiscount);
          }
          Analytics.applyLoyltyEvent();
        } else {
          setAlert("You dont have enough points to avail loyalty offer");
          setIsLoyalty(false);
        }
      }
    } else {
      payload = {
        is_use_loyalty: false,
        outlet_id: outletDataDetails.outlets_id,
        channel: LocalStorage.get("location").orderFor,
        delivery_area_id: outletDataDetails.delivery_area_id,
        user_distance:distance,
      };
      Analytics.removeLoyltyEvent();
      onCartDataValue(cartData);
      setLoyaltyAmount(null);
    }

    applyNewLoyalty(payload, (res) => {
      if (res && res.response && res.response.status === 200) {
        getCartItems(
          `${LocalStorage.get("outlet_id")}&delivery_area_id=${LocalStorage.get("Outlet_Details").delivery_area_id}`,
          (response) => {
            if (isCheckedVal == true) {

              setBillingDetailsData(response.content.billing_data);
              console.log(
                "onChange=Loyalty inside aplynewloaylty api",
                response.content.strip_data
              );
              // setStripData(response.content.strip_data);
              setIsLoyalty(true);
              LocalStorage.set("loyalty", true);
              LocalStorage.set("localloyaltyDiscountAmt", loyaltyDiscountAmt);
            } else if (isCheckedVal == false) {
              setBillingDetailsData(response.content.billing_data);
              console.log("isCheckedVal == false", response.content.strip_data);
              setStripData(response.content.strip_data);
              setIsLoyalty(false);
              LocalStorage.remove("loyalty");
              LocalStorage.remove("localloyaltyDiscountAmt");
            }
            onCartDataValue(cartData, "", "", response.content);
          })
      }
    });
  };

  const onChangeDonationValue = (e) => {
    setIsDonate(e.target.checked);
    if (e.target.checked === false) {
      setDonation(0);
      onSetDonation(0, cartData, "");
    }
    setOpenDonation(e.target.checked);
  };

  const onPaymentClick = (value) => {
    // debugger;
    let cartProductsData = LocalStorage.get("CartProducts");
    if (value && value?.length && value?.length > 0) {
      Analytics.paymentInfoEvent(cartProductsData, value);
    }
    setPayMode(value);
    setPaymentMode(true);
    setPayment(false);
  };

  const onCartClick = () => {
    history.go(0);
  };

  const onHomeClick = () => {
    history.push(ROUTES.HOME);
  };

  const handleGoBack = () => history.goBack();

  const closeAddress = () => {
    //
    setModalOpen(false);
  };

  let header = LocalStorage.get("UserData");
  const locationBottom = LocalStorage.get("location");
  header = header && header.name + "," + header.mobile;

  const onNav = (val) => {
    setStat(val);
  };

  const onNavigate = () => {
    const { location } = history;
    if (
      location &&
      location.state &&
      location.state.redirectToCheckOut &&
      location.state.redirectToCheckOut == true
    ) {
      history.go(-1);
    } else if (LocalStorage.get("outlet_id")) {
      history.push(ROUTES.HOME);
    } else {
      history.push(ROUTES.HOME);
    }
  };



  const getMobileData = (number) => {
    setMobileNumber(number);
    let displayM = number.split("");
    let displayMobile = [];
    let mobileDisplay = "";
    for (let i = 0; i < displayM.length; i++) {
      if (i == 0 || i == 1 || i == 2 || i == 8 || i == 9) {
        displayMobile.push(displayM[i]);
      } else {
        displayMobile.push("*");
      }
    }
    mobileDisplay = displayMobile.join("");
    setDisplayMobileNumber(mobileDisplay);

    // setDisplayMobile(number)
    let payload = {};
    if (isSignIn === true) {
      payload = {
        mobile: number,
        device_type: "web",
        is_signup: true,
        firebase_token: "qwerty",
        login_id: "",
        retry: false,
      };
    } else {
      payload = {
        mobile: number,
        device_type: "web",
        is_signup: false,
        firebase_token: "qwerty",
        login_id: "",
        retry: false,
      };
    }

    sendOtp(payload, (res) => {
      // setResMsg(res.response.message);
      // setToast(true);
      if (res?.response?.status === 200) {
        setLoginId(res?.content?.login_id);
        setStat("stage2");
      } else {
        // setErrorTxt(true);
        setErrorTxt1(res?.response?.message);
      }
    });
  };
  useEffect(() => {
    console.log(" outlet here ", outletData)
  }, [outletData])
  const getResendData = (number1) => {
    // setMobileNumber(number1);
    const payload = {
      mobile: mobileNumber,
      device_type: "web",
      is_signup: false,
      firebase_token: "qwerty",
      login_id: loginId,
      retry: true,
    };

    sendOtp(payload, (res) => {
      // setResMsg(res.response.message);
      // setToast(true);
      if (res?.response?.status === 200) {
        setLoginId(res?.content?.login_id);
        setSuccessTxt(res?.response?.message);
      }
    });
  };

  const getOtpData = (otpData) => {
    const payload = {
      mobile: mobileNumber,
      otp: otpData,
      login_id: loginId,
    };
    if (isSignIn === true) {
      verifyOtp(payload, (res) => {
        if (res?.response?.status === 200) {
          LocalStorage.set("AccessToken", res?.content.access_token);
          LocalStorage.set("RefreshToken", res?.content.refresh_token);
          LocalStorage.set("isLoggedIn", true);
          LocalStorage.set("UserData", {
            name: res?.content.first_name,
            mobile: res?.content.mobile,
          });

          Analytics.logEventWithVariable(
            Analytics.Order.login,
            JSON.stringify(payload)
          );

          //branch events

          registrationBranch(res?.content?._id);
          setIdentityBranch(res?.content?._id);
          loginBranchEvent(res?.content?._id);

          setErrorTxt(res?.response?.message);
          if (res.content && res.content.is_register_on_rezol === false) {
            setStat("stage3");
          } else {
            onNavigate();
          }
        } else {
          setErrorTxt("Invalid OTP!");
        }
      });
    } else {
      verifyOtp(payload, (res) => {
        if (res?.response?.status === 200) {
          // setStat('stage3')
          if (isSignIn === false) {
            setLoginFLow(false);
          }

          LocalStorage.set("AccessToken", res?.content.access_token);
          LocalStorage.set("RefreshToken", res?.content.refresh_token);
          LocalStorage.set("isLoggedIn", true);
          LocalStorage.set("UserData", {
            name: res?.content.first_name,
            mobile: res?.content.mobile,
            email: res?.content.email,
            dob: res?.content.dob,
            emailVerify: res?.content?.is_user_email_verified
          });
          const Date = moment(res.content.updated_at, "DD-MM-YYYY");
          // const script = document.createElement("script");
          // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
          // script.onload = () => {
          //   smartech(
          //     "contact",
          //     "5",
          //     {
          //       "pk^mobile": "91" + res.content.mobile,
          //     },
          //     () => {
          //       smartech("identify", "91" + res.content.mobile);
          //       smartech("dispatch", "Exiting User Login", {
          //         logindate: Date,
          //         logintype: "Web",
          //       });
          //     }
          //   );
          // };
          // document.head.appendChild(script);

          Analytics.logEventWithVariable(
            Analytics.Order.login,
            JSON.stringify(payload)
          );

          // branch events

          setIdentityBranch(res?.content?._id);
          loginBranchEvent(res?.content?._id);

          if (
            res.content &&
            (res.content.is_register_on_rezol === false ||
              res.content.is_new_user === true)
          ) {
            // basicDetailsData = res.content;
            setBasicDetailsData(res.content);
            setStat("stage3");
            // setBasicDetailsData(res?.content);
          } else {
            // onLoginClick();
          }
          setErrorTxt(res?.response?.message);
        } else {
          setErrorTxt(
            `${innerWidth <= 1023
              ? `*You have entered wrong OTP`
              : `Invalid OTP!`
            }`
          );
        }
      });
    }
  };

  const getBasicDetails = (data) => {
    setName(data.name);
    setEmail(data.email);
    setDate(data.date);

    const payload = {
      fname: data.name,
      lname: data.name,
      email: data.email,
      mobile: mobileNumber,
      dob: data.date,
    };
    makeProfile(payload, (res) => {
      if (res?.response?.status === 200) {
        localStorage.setItem("DOB", res?.content?.data?.dob);
        localStorage.setItem("Email Id", res?.content?.data?.email);
        // const script = document.createElement("script");
        // script.src = "//cdnt.netcoresmartech.com/smartechclient.js";
        // const Date = moment(res.content.created_at, "DD-MM-YYYY");
        // script.onload = () => {
        //   smartech(
        //     "contact",
        //     "4",
        //     {
        //       "pk^mobile": "91" + res?.content?.data?.mobile,
        //       mobile: "91" + data.mobile,
        //       FIRSTNAME: data.name,
        //       LASTNAME: data.name,
        //       SIGNUPDATE: Date,
        //     },
        //     () => {
        //       smartech("identify", "91" + data.mobile);
        //       smartech("dispatch", "New User Registration", {
        //         Source: "Web",
        //       });
        //     }
        //   );
        // };
        // document.head.appendChild(script);
        onNavigate();
      } else {
        setErrorMsg(res?.response?.message + " Please Login!");
      }
    });
  };

  const closeHandle = () => {
    LocalStorage.set("loginflow", false);
    setLoginFLow(false);
  };

  const LoginCLick = () => {
    setLoginFLow(true);
    setIsSignIn(false);
  };

  const SigninClick = () => {
    setLoginFLow(true);
    setIsSignIn(true);
  };

  const deliveryClick = () => {
    setDeliveryFlow(true);
  };

  //strictly for Delivery
  const onChangeOrder1 = (value) => {
    // debugger;

    setActiveOrder(value.id);
    console.log("localstorage location", LocalStorage.get("location"));
    console.log(activeOrder, "activeorder");

    if (innerWidth <= 1023) {
      // debugger;
      const updateOrder = responsiveRadioData.map((item) => {
        item.checked = false;
        console.log(item.id, "type");
        if (item.id === value.id) {
          item.checked = true;
        }
        return item;
      });
      // LocalStorage.set("", LocalStorage.get("location").orderTime);
      const changeOrderStatus = LocalStorage.get("location");
      changeOrderStatus.orderTime = value.id;
      LocalStorage.set("location", changeOrderStatus);
      console.log("updatedOrder", updateOrder);
      setResponsiveRadioData(updateOrder);
    } else {
      const updateOrder = radioData.map((item) => {
        // debugger;
        item.checked = false;
        if (item.id === value.id) {
          // debugger;
          item.checked = true;
        }
        return item;
      });
      setRadioData(updateOrder);
    }
  };

  const onChangeMode = (value) => {
    setActive(value.id);
  };

  const onCurrentLocations = () => {
    setAlertData(false);
    onCurrentLocation();
  };

  const onCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[0] &&
                    results[0].formatted_address
                  ) {
                    let pincode = "";
                    results[0].address_components.forEach((data) => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          (item) => item === "postal_code"
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    setInputvalue({
                      location: results[0].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                    });
                    setLatLng({ lat: json.lat, lng: json.lng });
                    onMarkerPosition({ lat: json.lat, lng: json.lng });
                    setLocation(true);
                    setErrorData(false);
                    setInputData(results[0].formatted_address);
                    if (active === "delivery") {
                      setPopup(true);
                    } else {
                      onConfirmLocation();
                    }
                  }
                }
              },
              this
            );
          }
          // eslint-disable-next-line no-unused-vars
        },
        (_error) => {
          alert("Unable to fetch the location. Please enter manually");
        }
      );
    } else {
      alert("Unable to fetch the location. Please enter manually");
    }
  };

  const responsiveOnFindFood = () => {
    //
    if ((active === "delivery" || active === "takeaway") && inputData) {
      //
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time !== "" &&
      date !== ""
    ) {
      setPopup(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      time === ""
    ) {
      settimeData(true);
    } else if (
      (active === "takeaway" || active === "delivery") &&
      responsiveRadioData === "orderLater" &&
      inputData &&
      date === ""
    ) {
      setdateData(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onFindFood = () => {
    if (active === "delivery" && inputData) {
      //
      setPopup(true);
    } else {
      setAlertData(true);
      onConfirmLocation();
    }
  };

  const onChangeInput = (value) => {
    setInputData(value);
  };

  const onBackClick = () => {
    setPopup(false);
  };

  const onChangeDate = (value) => {
    // debugger;
    if (!value) {
      setDate("");
      return;
    }
    let gotTime = value.getDate();
    setdateData(false);
    setDate(value);
    let dateHour = new Date();
    let hours = dateHour.getHours();
    const outeletDetailsData = LocalStorage.get("Outlet_Details");
    let opentime =
      outeletDetailsData.open_time &&
      outeletDetailsData.open_time.substring(0, 2);
    let closetime =
      outeletDetailsData.close_time &&
      outeletDetailsData.close_time.substring(0, 2);
    const calcTime = moment().add(1.5, "hours").format("HH:mm");
    const splitTime = calcTime.split(":");
    const totalHours =
      handleParseAmount(splitTime[0]) + handleParseAmount(splitTime[1]) / 60;
    console.log("date total hours", totalHours);
    if (gotTime === new Date().getDate()) {
      for (let i = Math.round(totalHours); i < closetime; i++) {
        timeInterval.push({
          id: i + ":00",
          name: `${moment(i, "HH").format("hh A")} - ${moment(
            i + 1,
            "HH"
          ).format("hh A")}`,
        });
      }
    } else {
      for (let i = Math.round(opentime); i < closetime; i++) {
        timeInterval.push({
          id: i + ":00",
          name: `${moment(i, "HH").format("hh A")} - ${moment(
            i + 1,
            "HH"
          ).format("hh A")}`,
        });
      }
    }

    setIntDate(timeInterval);
    console.log("date", interval);
    // setTimeout(() => {
    const locationData = JSON.parse(localStorage.getItem("location")) || {};
    locationData.orderTime = activeOrder;
    // locationData.time=time;
    console.log(locationData, "datee");
    locationData.date = value;
    localStorage.setItem("location", JSON.stringify(locationData));
    // }, 1000);
  };

  const onChangeTime = (value) => {
    settimeData(false);
    setTime(value);
  };

  const onCurrentLocationData = (value) => {
    setLatLng({ lat: value.latitude, lng: value.longitude });
    setInputData(value.location);
  };

  const onCenter = (value) => {
    if (value === false) {
      setLatLng({ lat: "", lng: "" });
    }
  };

  const onMarkerPosition = (value) => {
    LocalStorage.remove("PreviousLocation");
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng: value },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            var formattedAddress = results[0].formatted_address;
            for (let i = 0; i < results.length; i++) {
              if (results[i]?.geometry?.location_type === "APPROXIMATE") {
                // console.log('resultsresults', results[i].geometry)
                formattedAddress = results[i].formatted_address;
                break;
              }
            }
            // eslint-disable-next-line no-lone-blocks
            setInputvalue({
              location: results[0].formatted_address,
              pincode,
              latitude: value.lat,
              longitude: value.lng,
            });
            setLatLng({ lat: value.lat, lng: value.lng });
            setInputData(formattedAddress);
          }
        }
      },
      this
    );
  };

  const onConfirmLocation = () => {
    setPopup(false);

    let payload = {
      latitude: latLng.lat,
      longitude: latLng.lng,
      is_delivery: active === "delivery" ? true : false,
      is_order_now: activeOrder === "orderNow" ? true : false,
      order_date:
        activeOrder !== "orderNow" && date !== ""
          ? moment(date).format("YYYY-MM-DD")
          : "",
      order_time: activeOrder !== "orderNow" ? time : "",
      channel: "online_web",
    };

    OutletMapping(payload, (res) => {
      setIsLoader(true);
      if (res && res?.response?.status === 200) {
        setIsLoader(false);
        setErrorData(false);
        setErrorData1(false);
        setDeliveryFlow(false);
        if (res?.content?.areas) {
          setOutlets(res.content.areas);
          // LocalStorage.set('DeliveryAreaPoint',res.content.areas)
          // LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
          onClickStore(res?.content?.areas[0]);
        }
        setData([]);
      } else {
        setOutlets([]);
        setErrorData(true);
        if (active === "delivery") {
          payload = {
            latitude: latLng.lat,
            longitude: latLng.lng,
            is_delivery: false,
            is_order_now: activeOrder === "orderNow" ? true : false,
            order_date:
              activeOrder !== "orderNow" && date !== ""
                ? moment(date).format("YYYY-MM-DD")
                : "",
            order_time: activeOrder !== "orderNow" ? time : "",
            channel: "online_web",
          };
          OutletMapping(payload, (res) => {
            setIsLoader(true);
            if (res.response.status === 200) {
              setIsLoader(false);
              if (
                res.content &&
                res.content.areas &&
                res.content.areas.length > 0
              ) {
                setErrorData1(true);
                setCountData({
                  count: res.content.areas.length,
                  distance: parseFloat(
                    res.content.max_distance_user_to_restaurant
                  ).toFixed(2),
                });
                setData(res.content.areas);
              }
            } else {
              setErrorData(true);
              setCountData({});
              setData([]);
            }
          });
        } else {
          setErrorData1(false);
          setCountData({});
          setData([]);
        }
      }
    });
  };

  const onChangeSearch = (value) => {
    setSavedAddress("");
    setAlertData(false);
    setLocation(true);
    setInputData(value.location);
    setInputvalue(value);
  };

  const onCloseAlert1 = () => {
    setAlertPopup("");
  };

  const onStoreSelect = () => {
    setOutlets(data);
    setErrorData1(false);
    setData([]);
    setErrorData(false);
    setActive("takeaway");
  };

  const onClickStore = (data) => {
    // let pointDelivery = LocalStorage.get('DeliveryAreaPoint');
    if (data && data?.is_open) {
      LocalStorage.set("Outlet_Details", data);
      getMenuList(
        urlValue &&
          urlValue?.length > 0 &&
          data?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
          ? urlValue
          : data?.outlets_id,
        (res) => {
          if (res && res.content && res.response.status === 200) {
            if (res.content) {
              if (LocalStorage.get("outlet_id")) {
                const oldOutlet = LocalStorage.get("outlet_id");
                if (oldOutlet !== res.content.menu.unit_id) {
                  if (LocalStorage.get("isLoggedIn")) {
                    onMenuDataSync(res.content.menu.categories);
                  } else {
                    LocalStorage.set("CartProducts", []);
                  }
                }
              }
              LocalStorage.set("outlet_id", data?.outlets_id);
              const dataValue = {
                orderFor: active,
                orderTime: activeOrder,
                deliveryTime: data.delivery_area_delivery_time,
                address: inputData,
                date: date,
                time: time,
                lat: latLng.lat,
                lng: latLng.lng,
              };
              LocalStorage.set("location", dataValue);

              const db = new Dexie(data?.outlets_id);
              db.version(1).stores({
                menu: "++id, categories, item_tags, taxes",
              });
              const getMenuData = async () => {
                const allData = (await db.menu.toArray()) || [];
                if (allData && allData.length > 0) {
                  await db.menu.update(0, res.content.menu);
                } else {
                  await db.menu.add(res.content.menu);
                }
                history.push(ROUTES.CHECKOUT);
              };
              getMenuData();
              // onCallMenu();
              // const getMenuData = async () => {
              //   const allData = (await db.menu.toArray()) || [];
              //   if (allData && allData.length > 0) {
              //     history.push(ROUTES.PRODUCTLIST);
              //   }
              // };
              // getMenuData();
              // history.push(ROUTES.PRODUCTLIST);
            }
          } else {
            setAlertPopup(
              "Sorry!! No Data Found. Please try again later sometime"
            );
          }
        }
      );
    } else {
      setAlertPopup(
        "Sorry!! The outlet is currently closed. Please try again later sometime"
      );
    }
  };

  const onMenuDataSync = (data) => {
    const outletData = LocalStorage.get("CartProducts");
    const updateitem = data.map((items) => {
      let updateItem = [];
      if (items.has_subcategory === "0") {
        updateItem = items.items;
      }
      if (items.has_subcategory === "1") {
        items.subcategories.map((item) => {
          item.items.map((value) => {
            updateItem.push(value);
          });
        });
        items.items = updateItem;
      }
      items.itemData = updateItem.map((item) => {
        item.checked = false;
        item.count = 0;
        if (LocalStorage.get("CartProducts")) {
          const productData = LocalStorage.get("CartProducts");
          if (productData && productData.length > 0) {
            productData.map((items) => {
              if (items.item_id === item.item_id) {
                item.count = items.count;
              }
            });
          }
        }
        return item;
      });
      items.vegData = updateItem.filter((item) => item.item_tags.includes("1"));
      items.nonVeg = updateItem.filter((item) => item.item_tags.includes("2"));
      return items;
    });
    const updateItemData = [];
    updateitem.map((items) => {
      items.itemData.map((item) => {
        updateItemData.push(item);
      });
    });
    const datavalue = updateItemData.filter((itms) => {
      return outletData.includes(itms.item_id);
    });
  };
  // const { innerWidth } = window;

  const onSkipLocation = () => {
    getSkipLocation((res) => {
      if (res.response.status === 200) {
        LocalStorage.set("Outlet_Details", res.content);
        if (res.content.outlet_id) {
          getMenuList(
            urlValue &&
              urlValue?.length > 0 &&
              res?.content?.outlet__city?.toLowerCase() !==
              urlCity?.toLowerCase()
              ? urlValue
              : res.content.outlet_id,
            (res) => {
              if (res && res.content && res.response.status === 200) {
                if (res.content) {
                  if (LocalStorage.get("outlet_id")) {
                    const oldOutlet = LocalStorage.get("outlet_id");
                    if (oldOutlet !== res.content.menu.unit_id) {
                      if (LocalStorage.get("isLoggedIn")) {
                        onMenuDataSync(res.content.menu.categories);
                      } else {
                        LocalStorage.set("CartProducts", []);
                      }
                    }
                  }
                  LocalStorage.set("outlet_id", res.content.menu.unit_id);
                  const dataValue = {
                    orderFor: active,
                    orderTime: activeOrder,
                    deliveryTime: data.delivery_area_delivery_time,
                    address: inputData,
                    date: date,
                    time: time,
                    lat: latLng.lat,
                    lng: latLng.lng,
                  };
                  LocalStorage.set("location", dataValue);
                  const db = new Dexie(res.content.menu.unit_id);
                  db.version(1).stores({
                    menu: "++id, categories, item_tags, taxes",
                  });
                  const getMenuData = async () => {
                    const allData = (await db.menu.toArray()) || [];
                    if (allData && allData.length > 0) {
                      await db.menu.update(0, res.content.menu);
                    } else {
                      await db.menu.add(res.content.menu);
                    }
                    history.push(ROUTES.HOME);
                  };
                  getMenuData();
                  // onCallMenu();
                  // const getMenuData = async () => {
                  //   const allData = (await db.menu.toArray()) || [];
                  //   if (allData && allData.length > 0) {
                  //     history.push(ROUTES.PRODUCTLIST);
                  //   }
                  // };
                  // getMenuData();
                  // history.push(ROUTES.PRODUCTLIST);
                }
              } else {
                setAlertPopup(
                  "Sorry!! No Data Found. Please try again later sometime"
                );
              }
            }
          );
        }
      }
    });
  };

  // const handleGoBack = () => {
  //   //
  //   history.goBack();
  // };

  const close = () => {
    setModal(false);
  };

  const closedelivery = () => {
    setDeliveryFlow(false);
  };

  const handleClose = () => {
    setCustomizePopup(false);
  };

  const handleProductModal = (customItem) => {
    if (customItem === false) {
      setProductModal(customItem);
    } else {
      setProductModal((prevState) => !prevState);
    }
  };

  const isTakeAwayDelivery = () => {
    let locationDetails = LocalStorage.get("location");
    let takeAway = false;
    if (locationDetails && locationDetails.orderFor === "takeaway") {
      takeAway = true;
    }
    return takeAway;
  };

  const handleListAddressModal = () => {
    // console.log("handleListAddressModal", billingDetailsData);
    let cartProductsData = LocalStorage.get("CartProducts");
    if (!addressListModal) {
      Analytics.shippingInfoEvent(cartProductsData);
    }
    setAddressListModal((prevState) => !prevState);
    setOpenAddressForm(false);

    //branch events

    addAddressInfoBranch({
      revenue: Math.floor(billingDetailsData.paid_amount),
      shipping: Math.floor(billingDetailsData.delivery_charges),
      tax: Math.floor(billingDetailsData.cgst),
      storeName: LocalStorage.get("Outlet_Details").display_name,
      storeCity: LocalStorage.get("Outlet_Details").city,
      orderType: LocalStorage.get("location").orderTime,
    });

    // console.log(addAddressInfoBranch);
  };

  const handleClick = () => {
    setAddressListModal(false);
  };

  const handleChangeClick = () => {
    history.push("/delivery-location");
  };

  const handlePayment = () => {
    setPayment(true);
  };

  const handlePaymentBack = () => setPayment(false);

  const handleGiftVoucher = (e) => {
    setGiftVoucherCode(e.target.value);
  };

  const handleApplyGiftVoucher = (isRomove) => {
    if (isRomove) {
      setGiftVoucherAmt("0");
      return;
    }
    const payload = {
      voucher_code: giftVoucherCode,
    };
    applyGiftVoucher(payload, (res) => {
      if (res && res.response && res.response.status === 200) {
        setGiftVoucherAmt(res.content.voucher_amount);
        setIsMsg({
          toastType: true,
          toastText: res?.response?.message,
          isShow: true,
        });
      } else {
        setAlert(res.response.message);
      }
    });
  };

  const handleOrderLessZeroAmt = (totalAmount) => {
    if (totalAmount <= 0) {
      const updatedPaymentMethod = paymentMethods.map((data) => {
        return { ...data, checked: false, isDisbled: true };
      });
      setPayMode("gift_voucher");
      setPaymentMethods(updatedPaymentMethod);
      // setPaymentMode(false)
    } else {
      // if(!payMode && payMode.length !==0){
      const isCheckedElement = paymentMethods.some((data) => data.checked);
      if (!isCheckedElement) {
        setPayMode("");
      }
      // setPaymentMode(true)
      // }
      const updatedPaymentMethod = paymentMethods.map((data) => {
        return { ...data, isDisbled: false };
      });
      setPaymentMethods(updatedPaymentMethod);
    }
  };

  const handleCheckAmtGiftCart = () => {
    let amt = outletData[9].amount && outletData[9].amount.substring(1).trim();
    if (amt < 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleConvertTime = (time) => {
    console.log("time", time);
    const formatedTime = moment(time, "HH:mm").format("hh A");
    return formatedTime;
  };

  const handleAddress = () => {
    let formateAddress = "";
    let addressType = "";
    if (selectedAddress) {
      if (selectedAddress.AddressType) {
        addressType += `${selectedAddress.AddressType}, `;
      }
      if (selectedAddress.Address1) {
        formateAddress += `${selectedAddress.Address1}`;
      }
    }
    return (
      <div>
        <p className="subWrapper_address_type">{addressType}</p>
        <p className="subWrapper_address_address">{formateAddress}</p>
      </div>
    );
  };
  console.log("stripData?.amount_to_get_free_delivery", stripData);
  const handleInstructions = (value) => {
    localStorage.setItem("Instructions", value);
    localStrgInstructions = localStorage.getItem("Instructions");
    setInstruction(value);
  };

  useEffect(() => {
    let menuList = LocalStorage.get("localMenuList");
    let distanceCategoryItemIdList = [];
    menuList.map(category => {
      category.itemData.map(item => {
        if (item.item_tags.includes('3')) {
          distanceCategoryItemIdList.push(item.item_id);
        }
      })
    })
    let flag = true;
    let tempCartData = [...cartData];
    for (let i = 0; i < tempCartData.length; i++) {
      if (!distanceCategoryItemIdList.includes(tempCartData[i].item_id)) {
        flag = false;
        break;
      }
    }
    let localValue = LocalStorage.get("isDistanceCategory");
    if (localValue !== undefined) {
      setIsInDistanceCategory((localValue && flag && tempCartData.length > 0) ? true : false);
    } else {
      setIsInDistanceCategory(false);
    }
  }, [cartData]);

  const getIsDistanceCategory = () => {
    let menuList = LocalStorage.get("localMenuList");
    let distanceCategoryItemIdList = [];
    menuList.map(category => {
      category.itemData.map(item => {
        if (item.item_tags.includes('3')) {
          distanceCategoryItemIdList.push(item.item_id);
        }
      })
    })
    let flag = true;
    let tempCartData = [...cartData];
    for (let i = 0; i < tempCartData.length; i++) {
      if (!distanceCategoryItemIdList.includes(tempCartData[i].item_id)) {
        flag = false;
        break;
      }
    }
    let localValue = LocalStorage.get("isDistanceCategory");
    if (localValue !== undefined) {
      return (localValue && flag && tempCartData.length > 0) ? true : false;
    } else {
      return false;
    }
  }

  const [deliveryTime, setDeliveryTime] = useState("");
  const [preprationTime, setPreprationTime] = useState("");

  // Distance Matrix 
  const callOutletMapping = async (locationDetails) => {
    let payload = {
      latitude: locationDetails?.lat,
      longitude: locationDetails?.lng,
      is_delivery: true,
      is_order_now: true,
      order_date: "",
      order_time: "",
      channel: "online_web",
    };
    OutletMapping(payload, (res) => {

      if (res && res?.response?.status === 200) {
        if (res && res?.content && res?.content?.areas) {
          let newPayload = {
            source_latitude: Number(locationDetails.lat),
            source_longitude: Number(locationDetails.lng),
            destination_latitude: Number(res?.content?.areas[0].latitude),
            destination_longitude: Number(res?.content?.areas[0].longitude),
          }
          getDeliveryDistance(newPayload, (response) => {
            console.log(response, " map time")
            let preparationDuration =
              Number((res?.content?.areas[0]?.takeaway_preparation_time || res?.content?.areas[0]?.preparation_time)) +
              Number(response?.content?.duration.toFixed(0))
            let temp = [...responsiveRadio];
            // temp[0].name = isDelivery ? `Delivery Now ${preparationDuration ? `(${preparationDuration} min)` : ""}` : "Takeaway Now";
            // setResponsiveRadioData(temp);
            // console.log("getIsDistanceCategory",getIsDistanceCategory());
            setPreprationTime(preparationDuration)
            // setDeliveryTime(getIsDistanceCategory() ? `(45min)` : (preparationDuration ? `(${preparationDuration} min)` : ""));
          });
        }
      } else {
        return null;
      }
    })
  }

  const [headerDeliveryTime, setHeaderDeliveryTime] = useState("");

  useEffect(() => {
    if (getIsDistanceCategory()) {
      setDeliveryTime("(45min)");
      setHeaderDeliveryTime(45);
    } else {
      setHeaderDeliveryTime(preprationTime ? preprationTime : "...");
      setDeliveryTime(preprationTime ? `(${preprationTime} min)` : "");
    }
  }, [cartData, preprationTime]);

  useEffect(() => {
    let sourceType = getSelectedAddressType();
    switch (sourceType) {
      case "saved_address":
        callOutletMapping(LocalStorage.get("Previouslocation"));
        break;
      case "picked_address":
        callOutletMapping(LocalStorage.get("location"));
        break;
      case "no_address":
      case "take_away":
      default:
        break;
    }
  }, []);

  const getSelectedAddressType = () => {
    let sourceType = null;
    let data = LocalStorage.get("location");
    let previousLocationData = LocalStorage.get("Previouslocation");
    sourceType =
      data.address !== "" || (previousLocationData && previousLocationData.address !== "")
        ? `${(data || previousLocationData) && (data || previousLocationData)?.orderFor && (data || previousLocationData)?.orderFor === "takeaway"
          ? "take_away"
          : previousLocationData
            ? previousLocationData.address
            : `${data.addressType !== undefined ? "picked_address" : "no_address"}`
        }`
        : "no_address"
    return sourceType;
  }

  return (
    <>
      <Helmet>
        <title>{checkoutPageMeta.meta_title}</title>
        <meta name="description" content={checkoutPageMeta.desciptions} />
        <meta name="keywords" content={checkoutPageMeta.keywords} />
      </Helmet>
      {innerWidth > 1023 && <HeaderCommon headerDeliveryTime={headerDeliveryTime} />}
      {/* {handleDeliveryCharge()} */}
      {/* {customizePopup && (
        <RepeatCustomizationPopup
          addonPopup={addonPopup}
          customizeData={customizeData}
          onUpdateContent={onUpdateContent}
          handleClose={handleClose}
        />
      )} */}

      {/* setShowOffInfo(true)
    setShowOffData(i) */}

      {customizePopup && (
        <RepeatCustomizationPopup
          addonPopup={addonPopup}
          customizeData={customizeData}
          onUpdateContent={onUpdateContent}
          addNewProduct={addNewProduct}
          handleClose={handleClose}
          repeatCustom={repeatCustom}
        />
      )}
      {isMsg.isShow && (
        <Toaster
          toastType={isMsg.toastType}
          toastText={isMsg.toastText}
          closeToast={() =>
            setIsMsg({
              isShow: false,
            })
          }
        />
      )}

      {productModal && (
        <AddToCart
          Content={productDataAdd}
          item={productDataAdd}
          OnDataChecked={onDataChecked}
          Extras={extras}
          onUpdateData={onDataUpdate}
          ItemList={itemList}
          CartShow={cartShow}
          IsChecked={isChecked}
          OnItemCheck={onItemCheck}
          AddSubCount={productDataAdd.count} // 1
          CartArr={cartArr}
          SubCount={subCount}
          isAddons={isAddons}
          closeModal={handleProductModal}
          productModal={productModal}
          addNewCartBtnLoader={addNewCartBtnLoader}
          setAddNewBtnLoader={setAddNewBtnLoader}
        />
      )}
      {loginFlow && (
        <Modal
          modalCustomClass="modalCustomEdit"
          onCloseHandler={closeHandle}
          hideClose={stat === "stage2" || stat === "stage3"}
          isMobileFullLayout
        >
          {stat === "stage1" && (
            <CreateAccount
              closeHandle={closeHandle}
              onNav={onNav}
              errorTxt1={errorTxt1}
              getMobileData={getMobileData}
              onValidate={() => onValidate()}
              isSignIn={isSignIn}
              setIsSignIn={setIsSignIn}
            />
          )}
          {stat === "stage2" && (
            <OtpVerification
              autoReadOtpValue={otp}
              mobile={mobileNumber}
              displayMobile={displayMobileNumber}
              errorTxt={errorTxt}
              onNav={onNav}
              getResendData={getResendData}
              getOtpData={getOtpData}
              successTxt={successTxt}
            />
          )}
          {stat === "stage3" && (
            <BasicDetails
              errorMsg={errorMsg}
              onNav={onNav}
              getBasicDetails={getBasicDetails}
              data={basicDetailsData}
            />
          )}
        </Modal>
      )}

      {deliveryFlow && (
        <Modal
          modalCustomClass="modalCustomEdit"
          onCloseHandler={closedelivery}
          hideClose={stat === "stage2" || stat === "stage3"}
          isMobileFullLayout
        >
          <div className="deliveryContainer">
            {/* <div className="deliveryContainer_left">
              <img
                src={LocationImage}
                alt="img"
                height="100%"
                width="100%"
                className="deliveryContainer_left_img"
              />
            </div> */}
            <div className="deliveryContainer_right">
              {/* <img src={Logo} width="157" height="157" /> */}
              <h2 className="deliveryContainer_heading">
                {/* Delivering a Royal Feast to your doorsteps! */}
                Enter Location
              </h2>
              <div className="deliveryContainer_switches">
                <Switch
                  data={switchData}
                  active={active}
                  onFilter={onChangeMode}
                />
                <div className="deliveryContainer_switches_gap" />
                <RadioButton
                  data={radioData}
                  activeOrder={activeOrder}
                  onChange={onChangeOrder1}
                  totalAmount={totalAmount}
                  billingDetails={billingDetails}
                  Wamount={Wamount()}
                />
                {console.log(totalAmount, "ojgrli")}
              </div>

              {/* <div className="deliveryContainer_delivery">
                <div className="deliveryContainer_delivery_complete">
                  <SearchAutoComplete
                    onSearchChange={onMarkerPosition}
                    onChangeSearch={onChangeSearch}
                    inputData={
                      savedAddress && savedAddress.length > 0
                        ? inputData.address
                        : inputData
                    }
                  />
                </div>
                <Button
                  content="Submit"
                  className="deliveryContainer_delivery_button"
                  onClick={() => onFindFood()}
                />
              </div> */}
              {activeOrder === "orderLater" && (
                <div className="deliveryContainer_orderLater">
                  <div className="deliveryContainer_orderLater_container">
                    {/* <span className='deliveryContainer_orderLater_label'>
                Enter Date
              </span> */}
                    <div className="deliveryContainer_orderLater_date">
                      <DateTimePicker
                        onChange={(value) => onChangeDate(value)}
                        value={date}
                        className="deliveryContainer_orderLater_datetime"
                        format="yyyy-MM-dd"
                        placeholderText="Enter date"
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().getTime() + 4 * 24 * 60 * 60 * 1000
                          )
                        }
                        clearIcon={date === "" ? "" : "x "}
                        yearPlaceholder="Enter date"
                        monthPlaceholder=""
                        dayPlaceholder=""
                      />
                    </div>
                  </div>
                  <div className="deliveryContainer_orderLater_container">
                    {/* <span className='deliveryContainer_orderLater_label'>
                Enter Time
              </span> */}
                    {intervalModal && date !== "" && (
                      <div className="deliveryContainer_orderLater_container_drop">
                        <IntervalDrop
                          setInterval={setInterval}
                          interval={interval}
                          intervalModal={intervalModal}
                          setIntervalModal={setIntervalModal}
                          setTime={setTime}
                          timeInterval={intDate}
                        />
                      </div>
                    )}

                    <div className="deliveryContainer_orderLater_time">
                      {/* <DateTimePicker
                onChange={(value) => onChangeTime(value)}
                value={time}
                className='deliveryContainer_orderLater_datetime'
                format='yyyy-MM-dd'
                placeholderText='Enter time'
              /> */}
                      {/* <Input
                        type={timeInp}
                        onChange={(_id, value) => onChangeTime(value)}
                        value={time}
                        onFocus={() => setTimeInp("time")}
                        placeholder="Enter time"
                        customClass="deliveryContainer_orderLater_datetime"
                      /> */}

                      <p
                        onClick={() => {
                          setIntervalModal(!intervalModal);
                        }}
                        className="deliveryContainer_orderLater_datetime"
                      >
                        {interval === "" ? "Select Interval" : interval}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* {alertData && (
                <p className="deliveryContainer_delivery_phrase">
                  Please Enter location !!
                </p>
              )} */}
              <div
                className="deliveryContainer_location"
                onClick={() => onCurrentLocations()}
              >
                <img src={Detect} width="14" height="14" alt="" />
                <p className="deliveryContainer_text">
                  {active === "delivery" || activeOrder === "orderLater"
                    ? "Use current location"
                    : "Restaurant near you"}
                </p>
              </div>

              {errorData && (
                <>
                  <div className="deliveryContainer_error">
                    <p className="deliveryContainer_error_text">
                      90% of people want home delivery on location x. if we
                      reach 10% More then we would open a new restaurant so you
                      can enjoy our food. are you interested? Yes I'm interested
                    </p>
                    <img src={LocationSearch} width="132" height="117" alt="" />
                  </div>
                </>
              )}
              {errorData1 && countData && countData.count && (
                <>
                  <h4 className="deliveryContainer_error_recommend">
                    Recommend Restaurants
                  </h4>
                  <div className="deliveryContainer_error">
                    <p className="deliveryContainer_error_text1">
                      Hey ! found {countData.count} bbk restaurant{" "}
                      {countData.distance} km from your location would you do
                      take away from these restaurants
                    </p>
                    <div className="deliveryContainer_error_button">
                      <Button
                        content="Search Now"
                        onClick={() => onStoreSelect()}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {popup && (
              <></>
              // <MapModal
              //   onCurrentDetectLocation={(value) =>
              //     onCurrentLocationData(value)
              //   }
              //   onBackClick={() => onBackClick()}
              //   activeTheme={active}
              //   inputData={inputData}
              //   onChangeInput={() => onChangeInput()}
              //   onCenterChange={() => onCenter()}
              //   latLng={latLng}
              //   onMarkerPosition={onMarkerPosition}
              //   onConfirmLocation={() => onConfirmLocation()}
              //   onClickStore={() => onClickStore(outlets && outlets[0])}
              // />
            )}
            {alertPopup && (
              <AlertPopup
                title="Sorry!!"
                description={alertPopup}
                okayClick={() => onCloseAlert()}
              />
            )}
          </div>
        </Modal>
      )}
      <div className="checkout-page">
        {modalOpen && (
          <Modal
            onCloseHandler={addAddress1}
            modalCustomClass="modalCustomClassCheckout"
            contentCustomClass="contentCustomClassCheckout"
          >
            <AddAddress
              availableAddresses={availableAddresses}
              setAvailableAddresses={setAvailableAddresses}
              address={address}
              reach={reach}
              setSelectedAD={setSelectedAD}
              selectedAD={selectedAD}
              alternateNumber={alternateNumber}
              submit={submit}
              setPlace={setPlace}
              place={place}
              othersTxt={othersTxt}
              setOthersTxt={setOthersTxt}
              setAddress={setAddress}
              setReach={setReach}
              setAlternateNumber={setAlternateNumber}
              addressChange={addressChange}
              loc2={
                LocalStorage.get("location") &&
                LocalStorage.get("location").address
              }
              deliveryText={` ${""
                // LocalStorage.get("location") &&
                // LocalStorage.get("location").deliveryTime
                }50-60 min delivery`}
              handleclose={closeAddress}
              addAddress={addAddress1}
              onCheckoutClick={onCheckoutClick}
            />
          </Modal>
        )}
        {innerWidth <= 1023 ? (
          <>
            <div className="checkout-page_title">
              {/* <div className="checkout-page_title_img" onClick={onBack}>
            </div> */}
              <div className="checkout-page_title_header">
                <p>Cart</p>
              </div>
              <div className="checkout-page_title_imgdiv">
                <img
                  src={closeImg}
                  className="checkout-page_title_closeImg"
                  onClick={() => handleGoBack()}
                  alt=""
                />
              </div>
            </div>
          </>
        ) : (
          <div className="checkout-page_title">
            <div className="checkout-page_title_img" onClick={onBack}>
              <img src={Back} width="10px" height="18px" alt="checkoutImg" />
            </div>
            <div className="checkout-page_title_header">
              <p>Checkout</p>
            </div>
          </div>
        )}
      </div>
      {innerWidth <= 1023 && (
        <div className="checkout-page_title_divider"></div>
      )}
      <div className="checkout-page_div">
        {window.innerWidth <= 1023 && (
          <>
            <div className="checkout-page_side1">
              {console.log("selectedAddress for loc2", address)}
              {address !== "" && (
                <Location
                  logStatus={loginStatus ? false : true}
                  address={`${address}`}
                  addAddress1={addAddress1}
                  modalOpen={modalOpen}
                  reach={reach}
                  othersTxt={othersTxt}
                  setOthersTxt={setOthersTxt}
                  alternateNumber={alternateNumber}
                  submit={submit}
                  setAddress={setAddress}
                  setReach={setReach}
                  setAlternateNumber={setAlternateNumber}
                  img={LocationImg}
                  selectedAddress={selectedAddress}
                  locHeader="Delivery Address"
                  // loc2={
                  //   LocalStorage.get("location") &&
                  //   LocalStorage.get("location").address
                  // }
                  deliveryText={` ${""
                    // LocalStorage.get("location") &&
                    // LocalStorage.get("location").deliveryTime
                    }50-60 min delivery`}
                  addressChange={addressChange}
                  address_headerSection={true}
                  address_confirmButton="address_confirmButton"
                  address_bodySection="address_bodySection"
                  customClassName="address_customClassName"
                  loggedIn={loggedIn}
                  onCheckoutClick={onCheckoutClick}
                />
              )}
            </div>

            <div class="radio-group">
              <RadioButton
                className="OrderType"
                buttondata="OrderTypeData"
                label="OrderLabel"
                data={responsiveRadioData}
                onChange={onChangeOrder1}
                activeOrder={activeOrder}
                custom="radioCustomOrderType"
                customClass="OrderText"
                customClassChecked="OrderText"
                deliveryTime={deliveryTime}
              />
            </div>

            {activeOrder === "orderLater" && (
              <div className="deliveryContainer_orderLater">
                <div className="deliveryContainer_orderLater_container">
                  {/* <span className='deliveryContainer_orderLater_label'>
                Enter Date
              </span> */}
                  <div className="deliveryContainer_orderLater_date">
                    <DateTimePicker
                      onChange={(value) => onChangeDate(value)}
                      value={date}
                      className="deliveryContainer_orderLater_datetime"
                      format="yyyy-MM-dd"
                      placeholderText="Enter date"
                      minDate={new Date()}
                      maxDate={
                        new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000)
                      }
                      clearIcon={date === "" ? "" : "x "}
                      yearPlaceholder="Enter date"
                      monthPlaceholder=""
                      dayPlaceholder=""
                    />
                  </div>
                </div>
                <div className="deliveryContainer_orderLater_container">
                  {/* <span className='deliveryContainer_orderLater_label'>
                Enter Time
              </span> */}
                  {intervalModal && date !== "" && (
                    <div className="deliveryContainer_orderLater_container_drop">
                      <IntervalDrop
                        setInterval={setInterval}
                        interval={interval}
                        intervalModal={intervalModal}
                        setIntervalModal={setIntervalModal}
                        setTime={setTime}
                        timeInterval={intDate}
                        TimeInterval={TimeInterval}
                      />
                    </div>
                  )}

                  <div className="deliveryContainer_orderLater_time">
                    {/* <DateTimePicker
                onChange={(value) => onChangeTime(value)}
                value={time}
                className='deliveryContainer_orderLater_datetime'
                format='yyyy-MM-dd'
                placeholderText='Enter time'
              /> */}
                    {/* <Input
                        type={timeInp}
                        onChange={(_id, value) => onChangeTime(value)}
                        value={time}
                        onFocus={() => setTimeInp("time")}
                        placeholder="Enter time"
                        customClass="deliveryContainer_orderLater_datetime"
                      /> */}

                    <p
                      onClick={() => {
                        setIntervalModal(!intervalModal);
                      }}
                      className="deliveryContainer_orderLater_datetime"
                    >
                      {interval === "" ? "Select Interval" : interval}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="checkout-page_side2">
              {
                isInDistanceCategory &&
                <div className="distance_category_container">
                  <div className="distance_category_wrapper">
                    {/* <img src={distanceCategoryIcon} alt="clock-icon" className="distance_category_icon"/> */}
                    <img src={deliveryIcon45Min} alt="clock-icon" className="distance_category_icon" />
                    <p className="distance_category_text">All items are within 45min delivery</p>
                  </div>
                </div>
              }
              <div className="checkout-page_side2_topDivWrapper">
                <p className="checkout-page_side2_nameLabel">Your Items</p>
                {cartData &&
                  cartData.length > 0 &&
                  cartData.map((items) => {
                    return (
                      <>
                        <ProductSummary
                          data={items}
                          onUpdateContent={onUpdateContent}
                          isCustomize={true}
                          addonPopup={addonPopup}
                          isloading={loaderById[items._id]}
                          setLoderById={setLoderById}
                          homeData={homeData.categories}
                          isStrikeRequired={isStrikeRequired}
                        />
                        {/* <div className="checkout-page_side2_divider" /> */}
                      </>
                    );
                  })}
                {console.log(billingDetailsData, "trc")}
                {parseFloat(billingDetailsData?.total_discount) > 0 && (
                  <div className="checkout-page_side2_totalsavings">
                    <div className="checkout-page_side2_totalsavings__left flex-align-wrapper">
                      <img
                        src={percentRed}
                        className="checkout-page_side2_totalsavingsimg"
                        alt=""
                      />
                      <div className="checkout-page_side2_totalsavingslabel">
                        Your total saving
                      </div>
                    </div>

                    {console.log(billingDetailsData, "billingDetailsData")}
                    {parseFloat(billingDetailsData?.total_discount) > 0 && (
                      <div className="checkout-page_side2_totalsavingsvalue">
                        ₹{" "}
                        {parseFloat(billingDetailsData?.total_discount).toFixed(
                          2
                        )}
                      </div>
                    )}
                  </div>
                )}
                <p className="checkout-page_side2_nameLabel2">
                  Add Instructions
                </p>
                <Input
                  type="text"
                  onChange={(id, value) => {
                    handleInstructions(value);
                  }}
                  value={
                    localStrgInstructions && localStrgInstructions.length > 0
                      ? localStrgInstructions
                      : instruction
                  }
                  customClass="checkout-page_side2_input"
                  placeholder="Write instructions here"
                  logStatus={loginStatus ? false : true}
                />
                <div className="checkout-page_side2_divider" />
                {categoryData && categoryData.length > 0 && (
                  <div className="checkout-page_addons">
                    <p className="checkout-page_addons_nameLabel">
                      You may also like
                    </p>
                    <HorizontalScroll contentData={addCartData} />
                    <div className="checkout-page_Hbar"></div>
                  </div>
                )}
                {loyalty &&
                  loyalty.is_loyalty_offer_combined &&
                  loyalty.is_loyalty_offer_combined[0].loyalty_combined ===
                  false &&
                  isLoyalty ? (
                  ""
                ) : (
                  <>
                    <div className="checkout-page_offers">
                      <CheckoutOfferCard
                        logStatus={loginStatus ? false : true}
                        onClick={offerPayload}
                        data={offerData}
                        selectedOffer={selectedOffer}
                        onApplyOffer={onApplyOffer}
                        onLogin={onLoginClick}
                        loggedIn={loggedIn}
                        ShowParticular={ShowParticular}
                      />
                    </div>
                    <div className="gift-card-modal-content">
                      <div className="giftclaimcheckout">
                        <Input
                          type="text"
                          onChange={(id, value) => {
                            setClaimCode(value);
                          }}
                          value={claimCode}
                          customClass="giftclaimcheckout_input"
                          placeholder="Enter gift card code "
                        />

                        <Button
                          className={"claimcheckoutButton"}
                          content={"Claim"}
                          onClick={() => handleClaim()}
                          customClass="customClass"
                        />
                      </div>
                    </div>
                    {showClaimPopup && (
                      <div
                        className="claim-response-popup"
                        onClick={() => setShowClaimPopup(false)}
                      >
                        <div className="modal-overlay">
                          <div className="modal-content" id="checkoutclaim">
                            {successClaimResponse ? (
                              <>
                                <p className="congratsmsg">Congratulations !</p>
                                <p className="claimresponse">
                                  {successClaimResponse}
                                </p>
                                <span className="dividerLine"></span>
                                <p className="thanksmsg">Woohoo! Thanks</p>
                              </>
                            ) : (
                              <p className="errorclaimresponse">
                                {claimResponse}
                              </p>
                            )}
                          </div>                          if (e.target.checked)

                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="checkout-page_side2_divider" />
                {loyalty &&
                  loyalty.is_loyalty_offer_combined &&
                  loyalty.is_loyalty_offer_combined[0].loyalty_combined ===
                  false &&
                  selectedOffer &&
                  selectedOffer.coupon_code ? (
                  ""
                ) : loyalty &&
                  loyalty.loyalty_percentage &&
                  loyalty.loyalty_percentage.length > 0 ? (
                  <div className="checkout-page_loyalty">
                    {pointData.CurrentPoints !== "0" ? (
                      <Checkbox
                        img={heartImg}
                        header="Loyalty"
                        info={`Use Points (${getLoyalityDetails().points
                          }) to get ₹${getLoyalityDetails().discout} discount`}
                        id="isLoyalty"
                        value={isLoyalty}
                        onChange={(e) => {
                          console.log("newenter")
                          console.log("e-->", e.target.checked, isLoyalty);
                          onChangeLoyality(e.target.checked);
                          // setIsLoyalty(e.target.checked);
                        }}
                        logStatus={loginStatus ? false : true}
                        customClass="checkbox_loyalty"
                        points={parseInt(loyalty.is_loyalty_offer_combined[0].loyalty_points_for_one_penny)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <Checkbox
                  img={aunchBox}
                  header="Want Anch Box?"
                  // info="I would love to have this"
                  info="Our mini Angeethis provides extra 8-10 minutes of dum to your Biryani right before serving."
                  value={isHotBiryani}
                  onChange={(e) => {
                    setIsHotBiryani(e.target.checked);
                  }}
                  logStatus={loginStatus ? false : true}
                  customClass="checkbox_anchBox"
                />
                {/* <p className="checkout-page_phrase">
                  How would you like to use our pot
                </p> */}
                {/*<Checkbox
                  img={donateRes}
                  header="Donate NGO"
                  info={
                    donation && donation !== "0"
                      ? `₹${donation} added in donation`
                      : `Feedon.in, Robinhood Army.`
                  }
                  aTag={true}
                  id="isDonate"
                  value={isDonate}
                  onChange={onChangeDonationValue}
                  logStatus={loginStatus ? false : true}
                  customClass="checkbox_donateNgo"
                /> */}
                {!isTakeAwayDelivery() && (
                  <TipRider
                    tip={tip}
                    setTip={setTip}
                    tipData={tipData}
                    addTip={addTip}
                    setClicked={setClicked}
                    clicked={clicked}
                  />
                )}
              </div>

              <AmountSummary
                billingDetails={outletData}
                takeAway={takeAway}
                offercode={offerCode && offerCode.coupon_code}
                isLoyalty={isLoyalty}
                userBadge={pointData && pointData?.guest_label}
                stripData={stripData}
                innerWidth={innerWidth}
              />
              <p className={`checkout-page_side2-disclaimer ${(locationBottom.orderFor !== "takeaway" && address !== "") // margin for address selected popup
                ?
                "bottom-margin-16" :
                (stripData?.amount_to_get_free_delivery > 0) // margin for free delivery popup
                  ?
                  "bottom-margin-10" : ""
                }`}>
                {/* Disclaimer{" "}:{""} */}
                GST 18% applicable on ice-cream items
              </p>
              <div class="checkout-wrapper">
                {stripData?.amount_to_get_free_delivery > 0 && (
                  <div className="checkout-page_side2_free-delivery-check">
                    <img
                      src={Discount}
                      className="checkout-page_side2_totalsavingsimg"
                      alt=""
                    />
                    <span>
                      Add items worth ₹
                      {parseFloat(
                        stripData?.amount_to_get_free_delivery
                      ).toFixed(2)}{" "}
                      more to get Free delivery
                    </span>
                  </div>
                )}
                <div className="checkout-page_side2_bottomDivWrapper">
                  <div className="checkout-page_Hbar_amount"></div>
                  {payment && (
                    <Modal
                      hideClose
                      modalCustomClass="payment_custom"
                      contentCustomClass="payment_custom_content"
                      contentDataCustomClass="payment_custom_contentData"
                    >
                      <PaymentMethod
                        onPaymentClick={onPaymentClick}
                        customClassName="checkoutcustomClassName"
                        handleGoBack={handlePaymentBack}
                        voucher={true}
                        handleGiftVoucher={handleGiftVoucher}
                        giftVoucherCode={giftVoucherCode}
                        handleApplyGiftVoucher={handleApplyGiftVoucher}
                        giftVoucherAmt={giftVoucherAmt}
                        paymentMethods={paymentMethods}
                        walletBalance={walletBalance}
                        Wamount={Wamount}
                        campaignDetail={loyalty}
                      />
                    </Modal>
                  )}
                  {/* {console.log("locationBottom",locationBottom)} */}
                  <div className="checkout-page_side2_cartLocation">
                    {locationBottom.orderFor !== "takeaway" &&
                      address !== "" && (
                        <div className="cartLocation_wholeWrapper">
                          <img
                            src={locationCart}
                            className="cartLocation_wholeWrapper_locImg"
                            alt=""
                          />
                          <div className="cartLocation_wholeWrapper_subWrapper">
                            <div className="subWrapper_div">
                              <p className="subWrapper_address">
                                {/* {locationBottom.address} */}
                                {handleAddress()}
                              </p>
                              <div className="subWrapper_firstLayer">
                                <div className="subWrapper_firstLayer_wholeDiv">
                                  <img
                                    src={timeImg}
                                    className="wholeDiv_timeImg"
                                    alt=""
                                  />
                                  <Option
                                    selectedOption={
                                      locationBottom && locationBottom.orderTime
                                    }
                                    customClassName="wholeDiv_order"
                                  />
                                </div>
                                {locationBottom &&
                                  locationBottom.orderTime === "orderNow" ? (
                                  <div className="subWrapper_firstLayer_rightSide">
                                    <div className="rightSide_wrapper">
                                      <img
                                        src={bikeImg}
                                        className="rightSide_wrapper_bikeImg"
                                        alt=""
                                      />
                                    </div>
                                    <p className="rightSide_phrase">
                                      {/* {locationBottom.deliveryTime} min delivery */}
                                      50-60 min delivery
                                    </p>
                                  </div>
                                ) : (
                                  <div className="subWrapper_firstLayer_rightSide">
                                    <p className="rightSide_phrase">
                                      {console.log(
                                        "locationbottomm",
                                        locationBottom
                                      )}
                                      {moment(locationBottom.date).format(
                                        "MMMM Do, "
                                      ) + interval}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="subWrapper_footer">
                              <Button
                                content="Change"
                                className="subWrapper_footer_btn"
                                customClass="subWrapper_footer_btn_content"
                                onClick={() => addAddress1()}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    {paymentMode ? (
                      <div className="payment_buttonDiv">
                        <Button
                          content={
                            payMode === "online_payment"
                              ? "Online Payment"
                              : "Cash"
                          }
                          className="payment_buttonDiv_btn"
                          customClass="payment_buttonDiv_content"
                          onClick={() => handlePayment()}
                        />
                        <img
                          src={customize}
                          className="payment_customize"
                          onClick={() => handlePayment()}
                          alt=""
                        />
                        <Button
                          disabled={btnDisabled}
                          content={
                            loggedIn
                              ? (previousLocationData
                                ? previousLocationData.address !== ""
                                : address !== "") || isTakeAwayDelivery()
                                ? payMode === ""
                                  ? "Select Payment Method"
                                  : payMode === "cod"
                                    ? "Place Order"
                                    : "Make Payment"
                                : "Confirm address"
                              : "Login to proceed checkout"
                          }
                          className="checkout-page_side2_paymentSubmit"
                          customClass="checkout-page_side2_paymentSubmit_content"
                          onClick={
                            loggedIn
                              ? (previousLocationData
                                ? previousLocationData.address !== ""
                                : address !== "") || isTakeAwayDelivery()
                                ? payMode === ""
                                  ? () => handlePayment()
                                  : () => {
                                    onCheckoutClick(loggedIn);
                                  }
                                : () => handleListAddressModal()
                              : () => onCheckoutClick(loggedIn)
                          }
                        ></Button>
                      </div>
                    ) : (
                      <Button
                        disabled={btnDisabled}
                        content={
                          loggedIn
                            ? (previousLocationData
                              ? previousLocationData.address !== ""
                              : address !== "") || isTakeAwayDelivery()
                              ? payMode === ""
                                ? "Select Payment Method"
                                : payMode === "cod"
                                  ? "Place Order"
                                  : "Make Payment"
                              : "Confirm address"
                            : "Login to proceed checkout"
                        }
                        className="checkout-page_side2_cartSubmit"
                        customClass="checkout-page_side2_cartSubmit_content"
                        onClick={
                          loggedIn
                            ? (previousLocationData
                              ? previousLocationData.address !== ""
                              : address !== "") || isTakeAwayDelivery()
                              ? payMode === ""
                                ? () => handlePayment()
                                : () => {
                                  onCheckoutClick(loggedIn);
                                }
                              : () => handleListAddressModal()
                            : () => onCheckoutClick(loggedIn)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {window.innerWidth >= 1023 && (
          <>
            <div className="checkout-page_side1">
              <Details
                header={header}
                info="Your Details"
                show={loggedIn}
                onLoginClick={onLoginClick}
                onSignUp={onSignUp}
              />
              {/* {address !== "" && (
                <Location
                  logStatus={loginStatus ? false : true}
                  address={address}
                  addAddress={addAddress1}
                  modalOpen={modalOpen}
                  reach={reach}
                  alternateNumber={alternateNumber}
                  submit={submit}
                  setAddress={setAddress}
                  setReach={setReach}
                  setAlternateNumber={setAlternateNumber}
                  img={LocationImg}
                  locHeader="Delivery Address"
                  selectedAddress={selectedAddress}
                  // loc2={
                  //   LocalStorage.get("location") &&
                  //   LocalStorage.get("location").address
                  // }
                  deliveryText={` ${""
                    // LocalStorage.get("location") &&
                    // LocalStorage.get("location").deliveryTime
                    }50-60 min delivery`}
                  addressChange={addressChange}
                  dontShowAddress={true}
                />
              )} */}

              {/* <Checkbox
                logStatus={loginStatus ? false : true}
                img={donationPic}
                header="Make a Donation"
                info={
                  donation && donation !== "0"
                    ? `₹${donation} added in donation`
                    : `Feedon.in, Robinhood Army.`
                }
                aTag={true}
                id="isDonate"
                value={isDonate}
                onChange={onChangeDonationValue}
              /> */}
              {/* <Checkbox
                logStatus={loginStatus ? false : true}
                img={growPlants}
                header="How would you use our pot?"
                info="I would like to have some seeds to grow plants "
                id="isPot"
                value={isPot}
                onChange={(e) => {
                  setIsPot(e.target.checked);
                }}
              /> */}
              <Checkbox
                logStatus={loginStatus ? false : true}
                customClass={"checkout-page_hot-biryani"}
                img={aunchBox}
                header="Want Anch Box?"
                info="Our mini Angeethis provides extra 8-10 minutes of dum to your Biryani right before serving."
                id="isHotBiryani"
                value={isHotBiryani}
                onChange={(e) => {
                  setIsHotBiryani(e.target.checked);
                }}
              />
              <div className="checkout-page_side1_gap" />
              <PaymentMethod
                subtotal={outletData[0]?.amount.substring(1)}
                onPaymentClick={onPaymentClick}
                handleGiftVoucher={handleGiftVoucher}
                giftVoucherCode={giftVoucherCode}
                handleApplyGiftVoucher={handleApplyGiftVoucher}
                giftVoucherAmt={giftVoucherAmt}
                paymentMethods={paymentMethods}
                walletBalance={walletBalance}
                Wamount={Wamount}
                campaignDetail={loyalty}
              />
            </div>

            {/* <div className="checkout-page_Vslide"></div> */}
            <div className="checkout-page_side2">
              {
                isInDistanceCategory &&
                <div className="distance_category_container">
                  <div className="distance_category_wrapper">
                    {/* <img src={distanceCategoryIcon} alt="clock-icon" className="distance_category_icon"/> */}
                    <img src={deliveryIcon45Min} alt="clock-icon" className="distance_category_icon" />
                    <p className="distance_category_text">All items are within 45min delivery</p>
                  </div>
                </div>
              }
              <p className="checkout-page_side2_nameLabel">Your Cart</p>

              {cartData &&
                cartData.length > 0 &&
                cartData.map((items) => {
                  return (
                    <>
                      <ProductSummary
                        data={items}
                        addonPopup={addonPopup}
                        isCustomize={true}
                        onUpdateContent={onUpdateContent}
                        isloading={loaderById[items._id]}
                        setLoderById={setLoderById}
                        homeData={homeData.categories}
                        isStrikeRequired={isStrikeRequired}
                      />
                      <div className="checkout-page_side2_divider" />
                    </>
                  );
                })}
              {/* <div className='checkout-page_side2_divider' /> */}

              {/*  praveena 8th june */}
              {console.log(billingDetailsData, "trc")}
              {parseFloat(billingDetailsData?.total_discount) > 0 && (
                <div className="checkout-page_side2_totalsavings">
                  <div className="checkout-page_side2_totalsavings__left flex-align-wrapper">
                    <img
                      src={percentRed}
                      className="checkout-page_side2_totalsavingsimg"
                      alt=""
                    />
                    <div className="checkout-page_side2_totalsavingslabel">
                      Your total saving
                    </div>
                  </div>

                  {parseFloat(billingDetailsData?.total_discount) > 0 && (
                    <div className="checkout-page_side2_totalsavingsvalue">
                      ₹{" "}
                      {parseFloat(billingDetailsData?.total_discount).toFixed(
                        2
                      )}
                    </div>
                  )}
                </div>
              )}
              <p className="checkout-page_side2_nameLabel2">
                Add Kitchen instruction
              </p>

              <Input
                type="text"
                onChange={(id, value) => {
                  handleInstructions(value);
                }}
                value={
                  localStrgInstructions && localStrgInstructions.length > 0
                    ? localStrgInstructions
                    : instruction
                }
                customClass="checkout-page_side2_input"
                placeholder="Write instructions here"
                logStatus={loginStatus ? false : true}
              />
              <div className="checkout-page_side2_divider" />

              {loyalty &&
                loyalty.is_loyalty_offer_combined &&
                loyalty.is_loyalty_offer_combined[0].loyalty_combined === false &&
                selectedOffer &&
                selectedOffer.coupon_code ? (
                ""
              ) : loyalty &&
                loyalty.loyalty_percentage &&
                loyalty.loyalty_percentage.length > 0 ? (
                <div className="checkout-page_loyalty">
                  {pointData.CurrentPoints !== "0" ? (
                    <Checkbox
                      logStatus={loginStatus ? false : true}
                      img={
                        heartImg
                        // (pointData?.guest_label === "Silver" &&
                        //   silverloyalty) ||
                        // (pointData?.guest_label === "Gold" && goldloyalty) ||
                        // (pointData?.guest_label === "Bronze" && bronzeloyalty)
                      }
                      header={`BBK Loyalty`}
                      pointData={pointData}
                      history1={history1}
                      loyaltyActive={`${pointData.CurrentPoints &&
                        "(Your Points - " + pointData.CurrentPoints + ")"
                        }`}
                      customClass={"checkout-page_loyalty_act"}
                      info={`Use Points (${getLoyalityDetails().points
                        }) to get ₹${getLoyalityDetails().discout} discount`}
                      // Number(parseFloat(itemData.item_final_price).toFixed(2))

                      id="isLoyalty"
                      value={isLoyalty}
                      onChange={(e) => {
                        console.log("eee->", e);
                        setIsLoyalty(e.target.checked);
                        // if (e.target.checked) {
                        onChangeLoyality(e.target.checked);
                        // }
                      }}
                      points={parseInt(loyalty.is_loyalty_offer_combined[0].loyalty_points_for_one_penny)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {loyalty &&
                loyalty.is_loyalty_offer_combined &&
                loyalty.is_loyalty_offer_combined[0].loyalty_combined === false &&
                isLoyalty ? (
                ""
              ) : (
                <>
                  <div className="checkout-page_offers">
                    <CheckoutOfferCard
                      logStatus={loginStatus ? false : true}
                      onClick={offerPayload}
                      data={offerData}
                      data2={unAvailableOffers}
                      selectedOffer={selectedOffer}
                      onApplyOffer={onApplyOffer}
                      loggedIn={loggedIn}
                      onLogin={onLoginClick}
                      ShowParticular={ShowParticular}
                      closeParticular={closeParticular}
                    />
                  </div>
                  <p className="gcheading">Have a gift card ?</p>
                  <div className="gift-card-modal-content">
                    <div className="giftclaimcheckout">
                      <Input
                        type="text"
                        onChange={(id, value) => {
                          setClaimCode(value);
                        }}
                        value={claimCode}
                        customClass="giftclaimcheckout_input"
                        placeholder="Enter gift card code "
                      />

                      <Button
                        className={"claimcheckoutButton"}
                        content={"Claim"}
                        onClick={() => handleClaim()}
                        customClass="customClass"
                      />
                    </div>
                  </div>
                  {showClaimPopup && (
                    <div
                      className="claim-response-popup"
                      onClick={() => setShowClaimPopup(false)}
                    >
                      <div className="modal-overlay">
                        <div className="modal-content" id="apply-claim">
                          {successClaimResponse ? (
                            <>
                              <p className="congratsmsg">Congratulations !</p>
                              <p className="claimresponse">
                                {successClaimResponse}
                              </p>
                              <span className="dividerLine"></span>
                              <p className="thanksmsg">Woohoo! Thanks</p>
                            </>
                          ) : (
                            <p className="errorclaimresponse">
                              {claimResponse}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {loginStatus === false && (
                <p className="checkout-page_warning">
                  Please Login to proceed further
                </p>
              )}

              {!isTakeAwayDelivery() && (
                <TipRider
                  tip={tip}
                  setTip={setTip}
                  tipData={tipData}
                  addTip={addTip}
                  setClicked={setClicked}
                  clicked={clicked}
                />
              )}
              <div className="checkout-page_Hbar"></div>

              <AmountSummary
                billingDetails={outletData}
                offercode={offerCode && offerCode.coupon_code}
                isLoyalty={isLoyalty}
                userBadge={pointData && pointData?.guest_label}
                stripData={stripData}
                innerWidth={innerWidth}
              />
              <p className="checkout-page_side2-disclaimer">
                {/* Disclaimer{" "}:{""} */}
                GST 18% applicable on ice-cream items
                {/* <span className="disclaimer_span">GST 18% applicable on ice-cream items</span> */}
              </p>
              <p className="checkout-page_side2_errorTxt">{errorCart}</p>
              {stripData?.amount_to_get_free_delivery > 0 && (
                <div className="checkout-page_side2_free-delivery-check">
                  <img
                    src={Discount}
                    className="checkout-page_side2_totalsavingsimg"
                    alt=""
                  />
                  <span>
                    Add items worth ₹
                    {parseFloat(stripData?.amount_to_get_free_delivery).toFixed(
                      2
                    )}{" "}
                    more to get Free delivery
                  </span>
                </div>
              )}
              <Button
                disabled={btnDisabled}
                content={
                  loggedIn
                    ? (previousLocationData
                      ? previousLocationData.address !== ""
                      : address !== "") || isTakeAwayDelivery()
                      ? payMode === ""
                        ? // && handleCheckAmtGiftCart()
                        "Select payment method"
                        : payMode === "cod"
                          ? "Place Order"
                          : "Make Payment"
                      : "Confirm address"
                    : "Login to proceed checkout"
                }
                className="checkout-page_side2_submit"
                onClick={
                  loggedIn
                    ? (previousLocationData
                      ? previousLocationData.address !== ""
                      : address !== "") || isTakeAwayDelivery()
                      ? payMode === ""
                        ? // && handleCheckAmtGiftCart()
                        () => selectAgain()
                        : () => {
                          onCheckoutClick(loggedIn);
                        }
                      : () => handleListAddressModal()
                    : () => onCheckoutClick(loggedIn)
                }
              />
            </div>
          </>
        )}
      </div>

      {addressListModal && (
        <ListAddress
          handleListAddressModal={handleListAddressModal}
          setAddress={setAddress}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          setLocalAddressList={setLocalAddressList}
          localAddressList={localAddressList}
          openAddressForm={openAddressForm}
          submit={submit}
          click={() => handleClick()}
          isHideOrderType={true}

        />
      )}

      {showOffInfo && (
        <OfferPopup data={showOffData} onCloseClick={closeParticular} />
      )}

      {/* <div className="checkout-page_Vslide"></div> */}

      {openDonation && (
        <DonationPopup
          donation={donation}
          onChangeDonation={onChangeDonation}
          onClose={onCloseDonation}
          onClickDonation={onClickDonation}
          alert={donationClose}
        />
      )}
      {failurePay && (
        <FailureCard
          onCLoseClick={() => {
            setFailurePay(false);
          }}
          onTryAgain={onCartClick}
          onExploreFood={onHomeClick}
        />
      )}
      {alert && (
        <AlertPopup
          title="Sorry!!"
          description={alert}
          okayClick={() => onCloseAlert()}
          alertCart={true}
        />
      )}
    </>
  );
};

export default Checkout;
