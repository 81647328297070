import React from 'react'
import logo from '../../assets/images/LOGO (2).png'
import party from '../../assets/images/party-popper_1f389.png'
const ThanksHeader = ({ paymentData, type, subtype }) => {
    const dateString = paymentData?.payment_time;

    const date = new Date(dateString);

    function formatDate(date) {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        return date.toLocaleDateString('en-US', options);
    }

    const formattedDate = formatDate(date);

    return (
        <div className='thanksPageBuy_header'>
            <div className='logoImgDiv'>
                <img src={logo} className='logoImg' />
            </div>
            <div className='partyImgDiv'>
                <img src={party} className='partyImg' />
            </div>
            {type === "wallet" ? (<div className='textContent'>
                <span className='congrats'>Recharge Successful!</span>
                <span className='desc'>{paymentData?.amount} has been added to your BBK Money</span>
                <span className='transationId'>Trasaction ID : {paymentData?.payment_id}</span>
                <span className='dateTime'>{formattedDate}</span>
            </div>) : (<div className='textContent'>
                <span className='congrats'>Congratulation!</span>
                <span className='desc'>"Gift card has been purchased successfully</span>
                <span className='transationId'>Trasaction ID : {paymentData?.payment_id}</span>
                <span className='dateTime'>{formattedDate}</span>
            </div>)}

        </div>
    )
}

export default ThanksHeader