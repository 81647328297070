import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import HeaderCommon from "../../components/header-common-component";
import "./index.scss";
import { getHomePage } from "../../actions/home";
import MediaCard from "../../components/media-card";
import { LocalStorage } from "../../utils/storage";
import back from "../../assets/images/back.svg";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
import { getStaticPage } from "../../actions/staticpages";
import Footer from "../../containers/footer";
import { mediaPageMeta } from "../../helpers/metaTitleDescription";

const Media = (props) => {
  const history = useHistory();
  const urlParams = useLocation();
  const urlValue = urlParams.pathname.split("/")[3];
  const urlCity = urlParams.pathname.split("/")[2];

  const [media, setMedia] = useState([]);
  const [footerData, setFooterData] = useState({});

  let oultetID = LocalStorage.get("outlet_id");
  let outletDetails = LocalStorage.get("Outlet_Details");

  useEffect(() => {
    getHomePage(
      (res) => {
        if (res && res?.response?.status === 200) {
          setMedia(res?.content?.media_data);
        }
      },
      urlValue &&
        urlValue?.length > 0 &&
        outletDetails?.outlet__city?.toLowerCase() !== urlCity?.toLowerCase()
        ? urlValue
        : oultetID
    );
    const query = "";
    
    getStaticPage((res) => {
      if (res && res?.response?.status === 200) {
        const root = document.getElementById("root");
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setFooterData(res && res?.content);
      }
    }, query);
  }, []);

  return (
    <>
      <Helmet>
        <title>{mediaPageMeta.meta_title}</title>
        <meta name="description" content={mediaPageMeta.desciptions} />
        <meta name="keywords" content={mediaPageMeta.keywords} />
      </Helmet>
      <HeaderCommon />
      <div className="media">
        {/* <div className="media_div">
                <img src={back}
                onClick={() => history.push(ROUTES.HOME)}
                className='media_div_img' />
            <p className="media_div_nameLabel">Media</p>

            </div> */}
        <h3 className="media_nameLabel">Media</h3>
        <h3 className="media_nameLabel1">Trending</h3>

        <div className="media_mediaGrid">
          {media &&
            media.length > 0 &&
            media.map((item) => {
              return <MediaCard data={item} />;
            })}
        </div>
      </div>

      <Footer data={footerData && footerData} />
    </>
  );
};

export default Media;
