import React from "react";
import "./index.scss";
import Logo from "../../assets/images/LOGO (4).png";
import coins from "../../assets/images/coin.png";
import arrow from "../../assets/images/right.svg";

const WalletBanner = ({ walletBalance, handleHistoryPopup }) => {
  console.log(handleHistoryPopup, "sss");
  return (
    <div className="wallet-addmoney_top">
      <div className="wallet-addmoney_top_container">
        <div className="container_top">
          <div className="bbk_logo">
            <div className="bbk_logo_div">
              <img src={Logo} className="bbk_logo_img" />
            </div>
            <span className="bbk_logo_text">BBK Money</span>
          </div>
          <div className="wallet_icon">
            <img src={coins} className="wallet_icon_img" />
          </div>
        </div>
        <div className="container_bottom">
          <div className="content_left">
            <span className="balance_lable">Your Balance</span>
            <span className="balance_amount">₹ {walletBalance}</span>
          </div>
          <div className="content_right">
            <span className="transaction" onClick={handleHistoryPopup}>
              Transaction History{" "}
              <img src={arrow} className="arrow" />
            </span>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletBanner;
