import React, { useEffect, useState } from "react";
import { orderList } from "../../actions/orders";
import "./index.scss";
import { LocalStorage } from "../../utils/storage";
import Back from "../../assets/images/back.svg";
import PastOrderItem from "../../components/pastorder-item";
import PastOrderBillDetails from "../../components/past-order-bill-details";
import PastOrderDetails from "../../components/past-order-details";
import moment from "moment";
import SupportDetails from "../../components/support-card";
import supportImg from "../../assets/images/supportquestion.svg";
import Support from "../../components/support";
import Button from "../../components/button";
import { useLocation, useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import MyOrderFeedback from "../../components/myorder-feedback";
import FeedbackPopup from "../../components/feedback-popup";
import { feedback } from "../../actions/feedback";
import back from "../../assets/images/close.svg";
import { addToCart, getCartItems, getOrderTrack } from "../../actions/cart";
import Header from "../../containers/header";
import HeaderCommon from "../../components/header-common-component";
import { handleConvertToIST, handleParseAmount } from "../../helpers/sharedFunction";
import Toaster from "../../components/toaster";
import congratulations from "../../assets/images/congrats.svg";
import ItemDetails from "../../components/item-details";
import percentRed from "../../assets/images/discount.png";
import AmountSummary from "../../components/amount-summary";
import Call from '../../assets/images/contact.png';
import StepProcess from "../../components/step-process";
import OutletDetails from "../../components/outlet-detail";

const PastOrders = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({});
  const pastorders = LocalStorage.get("pastOrder");
  console.log(pastorders, " past data track order past orders")
  const navBack = () => {
    history.push(ROUTES.PROFILE);
  };
  const [loyaltyNumber, setLoyaltyNumber] = useState("");
  const [status, setStatus] = useState({});
  const [steps, setSteps] = useState([]);
  const [count, setCount] = useState({ 1: data?.feedback_rating });
  const [feedbackDta, setFeedback] = useState([]);
  const [comment, setComment] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [feedbackData, setFeedbackData] = useState([]);
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  const [statusData, setStatusData] = useState(false);
  const congrats = {
    name: "Congratulations",
  };
  const rate = (id, item) => {
    setCount((count) => ({ ...count, [id]: item }));
  };
  useEffect(() => {
    const queryParams = LocalStorage.get("pastPurchaseDetailId");
    getOrderTrack((res) => {
      console.log("track past order", res)
      if (res?.response && res?.response.status === 200) {
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("loyalty");
        LocalStorage.remove("offerAmount");

        setData(res.content);


        if (
          res &&
          res?.content &&
          res?.content?.tracking_Details?.order_details
        ) {
          //  
          setLoyaltyNumber(
            res?.content?.tracking_Details?.order_details?.earned_loyalty_points
          );
        }

        // Data for StepProcess component
        if (
          res.content &&
          res.content.tracking_Details &&
          res.content.tracking_Details.order_status_sequence &&
          res.content.tracking_Details.order_status
        ) {
          let stepData = [];
          let removeStepCount = 2;
          let stepToRemoveList = ["order_cancelled", "order_delayed"];
          const orderStatusSequenceObj = res.content.tracking_Details.order_status_sequence;
          const orderStatusList = res.content.tracking_Details.order_status;
          let stepKeyList = Object.keys(orderStatusSequenceObj);
          console.log("stepKeyList 1", stepKeyList, stepData, removeStepCount);
          orderStatusList.map((data) => {
            if (stepKeyList.includes(data.status)) {
              stepData.push({
                checked: true,
                display_name: orderStatusSequenceObj[data.status].display_name,
                id: data.status,
                time: handleConvertToIST(data.created_at),
              })
              let index = stepKeyList.indexOf(data.status);
              stepKeyList = stepKeyList.slice(0, index).concat(stepKeyList.slice(index + 1, stepKeyList.length));
              if (stepToRemoveList.includes(data.status)) {
                removeStepCount--;
              }
            }
          })
          for (let i = 0; i < stepKeyList.length - removeStepCount; i++) {
            stepData.push({
              checked: false,
              display_name: orderStatusSequenceObj[stepKeyList[i]].display_name,
              id: stepKeyList[i],
              // time: "",
            })
          }
          setSteps(stepData);
        }

        // if (
        //   res.content &&
        //   res.content.tracking_Details &&
        //   res.content.tracking_Details.status_key &&
        //   res.content.tracking_Details.order_status
        // ) {
        //   // let orderStatus = res.content.tracking_Details.order_status;
        //   if (res.content.tracking_Details.status_key.length > 0) {
        //     const trackDetail = res.content.tracking_Details.status_key.filter(
        //       (item) => item.default_display === 1
        //     );
        //     console.log(trackDetail,"track order detail")
        //     // const stepProcess = trackDetail.map((itm) => {
        //     //   res.content.tracking_Details.order_status.map((item) => {
        //     //     console.log("track past order item",item)
        //     //     item.checked = false;
        //     //     if (item.status === itm.id) {
        //     //       itm.checked = true;
        //     //       itm.time = handleConvertToIST(item.updated_at);
        //     //     }
        //     //   });
        //     //   return itm;
        //     // });
        //     const stepProcess = trackDetail.map((itm, index) => {
        //       let orderCancelledEncountered = false;

        //       res.content.tracking_Details.order_status.map((item) => {
        //         console.log("track past order item", item);
        //         item.checked = false;

        //         if (item.status === itm.id) {
        //           itm.checked = true;
        //           itm.time = handleConvertToIST(item.updated_at);

        //           if (item.status === 'order_cancelled') {
        //             orderCancelledEncountered = true;
        //             debugger;

        //           }
        //         }

        //         if (orderCancelledEncountered) {
        //           trackDetail.slice(index + 1).forEach(item => {
        //             item.textGreyedOut = true;
        //           });
        //         }
        //       });

        //       return itm;
        //     });



        //     setSteps(stepProcess);
        //   }
        // }
      }
    }, queryParams);
  }, []);
  console.log(data, "past data")
  const cartshow = (a, b, c) => {
    // debugger;
    setModalShow(!modalShow);
    LocalStorage.set("feedbackId", c);
    const payload = {
      outlet_id: a,
      order_type: b,
    };
    feedback(payload, (res) => {
      if (res && res.content && res.response.status === 200) {
        if (res.content) {
          // setData(res.content.past_orders);
          setFeedbackData(res?.content.data);
        }
      }
    });
  };

  const { innerWidth } = window;
  const handleGoBack = () => history.goBack();
  let offerCode = LocalStorage.get("offerwholedata");
  let pastOrder = LocalStorage.get("pastOrder")?.cart_items;
  // const [taxCharges, setTaxCharges] = useState([]);
  let taxCharges;
  const onTaxCal = (value) => {
    let ontaxCal = [];
    taxCharges.map((item) => {
      value.item_taxes[0].taxes.map((values) => {
        if (item.tax_id === values) {
          const tax = {
            tax_tags: values,
            amount: (item.tax_value * value.item_final_price) / 100,
          };
          ontaxCal.push(tax);
        }
      });
    });
    return ontaxCal;
  };

  const onAddToCart = () => {
    const outletData = LocalStorage.get("Outlet_Details");
    const location = LocalStorage.get("location");
    const outletId = LocalStorage.get("outlet_id");
    const user = LocalStorage.get("UserData");
    const distance=LocalStorage.get('userDistance')


    let payload = {};
    let cartItem;
    let cartItems = [];
    let addonsData;

    pastOrder &&
      pastOrder.length > 0 &&
      pastOrder.map((value) => {
        let addonItems = [];
        value.addons &&
          value.addons.length > 0 &&
          value.addons.map((i) => {
            addonsData = {
              item_id: i.item_id,
              item_name: i.item_name,
              group_id: i.category_id,
              item_count: parseInt(i.item_count),
              final_price: i.price,
              group_name: i.category_name,
              discount: 1,
              image_url: i.image_url,
              item_tags: i.item_tags,
            };
            addonItems.push(addonsData);
          });


        cartItem = {
          // _id: LocalStorage.get('addId'),
          item_id: value.item_id,
          item_count: value.item_count,
          is_veg: value.is_veg,
          image_url: value.image_url,
          price: handleItemPrice(value),
          final_price: handleItemPrice(value),
          name: value.name,
          discount: value.discount,
          item_tags: value.item_tags,
          addons: addonItems,
          local_id: parseInt(value.local_id),
          selected_addons: value.selected_addons,
        };
        cartItems.push(cartItem);
      });

    payload = {
      channel: location.orderFor,
      outlet_id: outletId,
      items: cartItems,
      source: outletData.outlet_status_info,
      user_distance:distance
    };
    addToCart(payload, (res) => {
      if (res && res?.response?.status === 200) {
        // console.log("past order page rendered");

        getCartItems(outletId, (res) => {
          if (res && res?.response?.status === 200) {

            //  
            history.push(ROUTES.CHECKOUT);
          }
        });
      }
    });
  };

  const handleItemPrice = (item) => {
    let amount = handleParseAmount(item.final_price) /
      handleParseAmount(item.initial_item_count)
    return amount
  }
  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };
  const handleAddonCount = (item) => {
    const itemPrice =
      handleParseAmount(item.final_price) / handleParseAmount(item.item_count);
    const itemCount = handleParseAmount(item.item_count);
    return (
      <div className="card-sidebar__products__addons-wrapper">
        <p className="card-sidebar__products__extras">
          {item.item_name} {` [Rs ${itemPrice}]`}
        </p>
        <p className="card-sidebar__products__extras">[{itemCount}]</p>
      </div>
    );
  };
  const pastOrderdata = data?.tracking_Details?.order_details;
  const billingDetails = [
    {
      name: "Sub Total",
      amount: `₹ ${Math.floor(
        Number(
          pastOrderdata?.final_price
        )
      )}`,
      show: true,
    },
    {
      name: "Discount",
      amount: ` - ₹ ${Math.floor(
        Number(
          pastOrderdata?.offer_discount
        )
      )}`,
      show:
        Math.floor(
          Number(
            pastOrderdata?.offer_discount
          )
        ) === 0
          ? false
          : true,
    },
    {
      name: "Loyalty Discount",
      amount: ` - ₹ ${Number(pastOrderdata?.loyalty_discount).toFixed(2)}`,
      show: pastOrderdata?.loyalty_discount > 0 ? true : false,
    },
    {
      name: "Donation",
      amount: `+ ₹ ${Math.floor(Number(pastOrderdata?.donation))}`,
      show: Math.floor(
        Number(pastOrderdata?.donation)
      ) === 0.0 ? false : true,
    },
    {
      name: "Delivery charge",
      amount: `${`₹ ${Math.floor(
        Number(
          pastOrderdata?.delivery_charges
        )
      )}`
        }`,
      show: pastOrderdata?.delivery_type === "takeaway" ? false : true,
      // textColor:
      //   initialBillingData?.strip_data?.delivery_strip
      //     ?.is_show_free_delivery === true
      //     ? true
      //     : false,
    },
    {
      name: "Packing charge",
      amount: `${`₹ ${Math.floor(
        Number(
          pastOrderdata?.package_charges
        )
      )}`
        }`,
      show: true,
      // textColor:
      //   initialBillingData?.strip_data?.packing_charge_strip
      //     ?.is_show_free_packing_charge === true
      //     ? true
      //     : false,
    },
    {
      name: "Tip",
      amount: `${`+ ₹ ${Math.floor(Number(pastOrderdata?.delivery_tips))}`}`,
      show: Math.floor(Number(
        pastOrderdata?.delivery_tips)) === 0 ? false : true,
    },
    {
      name:
        `CGST`,
      amount: `₹ ${Number(
        parseFloat(
          pastOrderdata?.cgst
        ).toFixed(2)
      )}`,
      show: true,
    },
    {
      name:
        `SGST`,
      amount: `₹ ${Number(
        parseFloat(
          pastOrderdata?.sgst
        ).toFixed(2)
      )}`,
      show: true,
    },
    // {
    //   name: "Gift card",
    //   amount: ` - ₹ ${giftVoucherAmtData}`,
    //   show: giftVoucherAmtData ? true : false,
    // },
    {
      name: "Total Amount",
      amount: `₹ ${Math.floor(
        Number(
          pastOrderdata?.paid_amount
        )
      )}`,
      show: true,
    },
  ];

  const handleCallClick = () => {
    const phoneNumber = '18002122212';
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="pastOrder pastOrder_mobile">
            {modalShow && (
              <FeedbackPopup
                data={feedbackData}
                CartShow={cartshow}
                setToaster={setToaster}
              />
            )}
            <div className="pastOrder_sendBack">
              <>
                <div className="wholeWrapper_pastOrder">
                  <div className="pastOrderID">
                    Order ID: {pastOrderdata?.order_id}
                  </div>
                  <img
                    src={back}
                    className="pastOrder_sendBack_img"
                    onClick={() => handleGoBack()}
                    alt="back"
                  />
                </div>
                {/* <div className="pastBill_divider" /> */}
              </>
            </div>
            <div className="pastOrder_rightData">
              <div className="pastOrder_content_stepDetail">
                <StepProcess data={steps} />
              </div>
              <SupportDetails
                data={congrats}
                img={congratulations}
                loyaltyNumber={
                  "You just earned " +
                  Number(parseFloat(loyaltyNumber).toFixed(2)) +
                  " points on this order"
                }
              />
              {pastorders && !pastorders.is_cancelled && (
                <MyOrderFeedback
                  items={["1", "2", "3", "4", "5"]}
                  id={1}
                  data={pastorders?.feedback_rating}
                  count={count}
                  rate={rate}
                  CartShow={cartshow}
                  outlet={pastorders}
                  feedbackSubmitted={pastorders?.is_feedback_submitted}
                />
              )}
              <p className="pastOrder_rightData_nameLabel_order">
                ORDER DETAILS
              </p>
              {data &&
                data.tracking_Details?.cart_items &&
                data.tracking_Details?.cart_items.map((item) => {
                  return (
                    <div className="itemDetails_container">
                      <ItemDetails data={item} />
                      {item.addons &&
                        item.addons.map((data) => (
                          <p className="pastOrder_rightData_addonLabel">
                            {handleAddonCount(data)}
                          </p>
                        ))}
                    </div>
                  );
                })}

              <div className="trackOrder_content_orderDetail">
                <AmountSummary
                  billingDetails={billingDetails}
                  takeAway={data?.delivery_type}
                  offercode={offerCode && offerCode.coupon_code}
                />
              </div>
              {parseFloat(data?.discount) > 0 && (
                <div className="checkout-page_side2_totalsavings">
                  <div className="checkout-page_side2_totalsavings__left">
                    <img
                      src={percentRed}
                      className="checkout-page_side2_totalsavingsimg"
                      alt=""
                    />
                    <div className="checkout-page_side2_totalsavingslabel">
                      Your total saving
                    </div>
                  </div>

                  {parseFloat(data?.discount) > 0 && (
                    <div className="checkout-page_side2_totalsavingsvalue">
                      ₹ {parseFloat(data?.discount).toFixed(2)}
                    </div>
                  )}
                </div>
              )}
              <p className="trackOrder_rightData_nameLabel_outlet">
                Outlet Details:
              </p>

              <OutletDetails
                data={data?.tracking_Details?.outlet?.outlet_details}
              />
              <div className="trackOrder_rightData_divider" />
              <div className="customercare">
                <div className="customer_care">
                  <span className="customer_care_name">Customer Care</span>
                  <span className="customer_care_number">1800-212-2-212</span>
                </div>
                <img src={Call} alt="call" onClick={() => handleCallClick()} />
              </div>
            </div>
            {/* <div className="pastOrder_data">
              <div className="pastOrder_data_one">
                <h1 className="pastOrder_data_heading">Product</h1>
                <PastOrderItem data={data.cart_items} />
                <div className="pastOrder_data_one_placed">
                  <p className="pastOrder_data_one_placed_label1">
                    {"Placed On: " +
                      moment(handleConvertToIST(data.created_at)).format(
                        "MMMM Do YYYY, h:mma"
                      )}
                  </p>
                  {data.order_delivered_date && (
                    <p
                      className={`pastOrder_data_one_placed_label2
                  ${
                    data.is_cancelled
                      ? "pastOrder_data_one_placed_label2_cancelled"
                      : ""
                  }
                  `}
                    >
                      {`${
                        data.is_cancelled ? "Cancelled" : "Delivered"
                      }  On: ` +
                        moment(
                          handleConvertToIST(data.order_delivered_date)
                        ).format("MMMM Do YYYY, h:mma")}
                    </p>
                  )}
                </div>
                {data && !data.is_cancelled && (
                  <MyOrderFeedback
                    items={["1", "2", "3", "4", "5"]}
                    id={1}
                    data={data?.feedback_rating}
                    count={count}
                    rate={rate}
                    CartShow={cartshow}
                    outlet={data}
                    feedbackSubmitted={data?.is_feedback_submitted}
                  />
                )}
              </div>
              <div className="pastOrder_data_two">
                <PastOrderDetails data={data} />
                <PastOrderBillDetails data={data} />
                <Support data={{ name: "Need Support?" }} img={supportImg} />
                <Button
                  onClick={() => onAddToCart()}
                  content="Repeat Order"
                  className="pastOrder_data_two_btn"
                  customClass="customClass"
                />
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <>
          <HeaderCommon />
          <div className="pastOrder pastOrder_mobile">
            {modalShow && (
              <FeedbackPopup
                data={feedbackData}
                CartShow={cartshow}
                setToaster={setToaster}
              />
            )}
            <div className="pastOrder_sendBack">
              <img
                src={Back}
                onClick={() => navBack()}
                className="pastOrder_sendBack_img"
                alt="back"
              />
              <p className="pastOrder_sendBack_nameLabel">Past Order</p>
            </div>
            <div className="pastOrder_data">
              <div className="pastOrder_data_one">
                <PastOrderItem data={pastorders.cart_items} />
                <div className="pastOrder_data_one_placed">
                  <p className="pastOrder_data_one_placed_label1">
                    {"Placed On: " +
                      moment(handleConvertToIST(pastorders.created_at)).format(
                        "MMMM Do YYYY, h:mma"
                      )}
                  </p>
                  {pastorders.order_delivered_date && (
                    <p
                      className={`pastOrder_data_one_placed_label2
                  ${pastorders.is_cancelled
                          ? "pastOrder_data_one_placed_label2_cancelled"
                          : ""
                        }
                  `}
                    >
                      {`${pastorders.is_cancelled ? "Cancelled" : "Delivered"
                        }  On: ` +
                        moment(
                          handleConvertToIST(pastorders.order_delivered_date)
                        ).format("MMMM Do YYYY, h:mma")}
                    </p>
                  )}
                </div>
                <div className="pastOrder_data_one_divider" />
                {pastorders && !pastorders.is_cancelled && (
                  <MyOrderFeedback
                    items={["1", "2", "3", "4", "5"]}
                    id={1}
                    data={pastorders?.feedback_rating}
                    count={count}
                    rate={rate}
                    CartShow={cartshow}
                    outlet={pastorders}
                    feedbackSubmitted={pastorders?.is_feedback_submitted}
                  />
                )}
                <PastOrderBillDetails data={pastorders} />
              </div>
              <div className="pastOrder_data_two">
                <PastOrderDetails data={pastorders} />
                <div className="pastOrder_data_two_space" />
                <Support data={{ name: "Need Support?" }} img={supportImg} />
                <div className="pastOrder_data_two_space" />
                <Button
                  onClick={() => onAddToCart()}
                  content="Repeat Order"
                  className="pastOrder_data_two_btn"
                  customClass="customClass"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {toaster.isShow && (
        <Toaster closeToast={closeToast} toastText={toaster.toastText} />
      )}
    </>
  );
};
export default PastOrders;
