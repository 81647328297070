const BaseUrl = {
  // BASE_URL: 'https://backend-demo.bbkbiryani.com/backend-app/', //DEV URL
   BASE_URL: 'https://web-backend.biryanibykilo.com/backend-app/' // prod
  // BASE_URL: 'https://web-backend.bbkbiryani.com/backend-app/' // staging
};

export const API_CONSTANTS = {
  TOKEN: `${BaseUrl.BASE_URL}authentication/api/v1/guest_token/web`,
  REFRESH_TOKEN: `${BaseUrl.BASE_URL}refresh_token`,
  SIGN_UP: `${BaseUrl.BASE_URL}authentication/api/v2/send_otp`,
  VERIFY_OTP: `${BaseUrl.BASE_URL}authentication/api/v2/verify_otp_login`,
  PROFILE: `${BaseUrl.BASE_URL}authentication/api/v2/profile`,
  OUTLET_MAPPING: `${BaseUrl.BASE_URL}app/api/v1/outlet_mapping`,
  MENU_LIST: `${BaseUrl.BASE_URL}app/api/v1/get_menu_list/`,
  HOME_PAGE_API: `${BaseUrl.BASE_URL}app/api/v1/home_page/`,
  OFFERS: `${BaseUrl.BASE_URL}app/api/v1/list_offers`,
  ADD_TO_CART: `${BaseUrl.BASE_URL}app/api/v3/add_to_cart`,
  GET_CART_ITEM: `${BaseUrl.BASE_URL}app/api/v2/add_to_cart?outlet_id=`,
  APPLY_OFFER: `${BaseUrl.BASE_URL}app/api/v2/coupon_validation`,
  APPLY_LOYALTY: `${BaseUrl.BASE_URL}app/api/v1/discount_list`,
  NEW_LOYALTY: `${BaseUrl.BASE_URL}app/api/v2/apply_loyalty`,
  CHECKOUT: `${BaseUrl.BASE_URL}app/api/v2/checkout/`,
  ORDER_TRACKING: `${BaseUrl.BASE_URL}app/api/v4/order_tracking/`,
  ORDER_TRACKING_STATUS: `${BaseUrl.BASE_URL}app/api/v1/fetch_status/`,
  CANCEL_ORDER: `${BaseUrl.BASE_URL}app/api/v1/order_cancel/`,
  ORDER_LIST: `${BaseUrl.BASE_URL}app/api/v1/order_list`,
  PROFILE_IMAGE: `${BaseUrl.BASE_URL}common/v1/upload_to_s3?storage_folder=bbk&image_in=user_profile`,
  SEND_PROFILE_OTP: `${BaseUrl.BASE_URL}authentication/api/v1/profile_send_otp?retry=0`,
  VERIFY_PROFILE_OTP: `${BaseUrl.BASE_URL}authentication/api/v2/profile`,
  FEEDBACK: `${BaseUrl.BASE_URL}app/api/v1/get_feedback`,
  SEND_FEEDBACK: `${BaseUrl.BASE_URL}app/api/v1/submit_feedback/`,
  PAYMENT: `${BaseUrl.BASE_URL}app/api/v2/razor_pay_verify_signature`,
  STATIC_PAGE: `${BaseUrl.BASE_URL}app/api/v1/static_page/`,
  GIFT_VOUCHERS: `${BaseUrl.BASE_URL}app/api/v1/gift_card`,
  MY_VOUCHERS: `${BaseUrl.BASE_URL}app/api/v1/my_voucher`,
  LOGOUT: `${BaseUrl.BASE_URL}authentication/api/v1/logout`,
  ADD_ADDRESS: `${BaseUrl.BASE_URL}app/api/v1/address`,
  REFER_EARN: `${BaseUrl.BASE_URL}app/api/v1/bbk_referral`,
  SKIP_LOCATION: `${BaseUrl.BASE_URL}app/api/v1/skip_location`,
  STORE_LOCATOR: `${BaseUrl.BASE_URL}app/api/v1/store_locator`,
  LOYALTY: `${BaseUrl.BASE_URL}app/api/v1/loyalty_points_and_history`,
  ADDRESS_GET_ALL: `${BaseUrl.BASE_URL}app/api/v1/available_address_to_deliver`,
  BLOG_DETAIL: `${BaseUrl.BASE_URL}app/api/v1/blog_detail`,
  IS_DELIVERABLE_ADDRESS: `${BaseUrl.BASE_URL}app/api/v1/getavailble`,
  GET_GIFT_VOUCHER_AMOUNT: `${BaseUrl.BASE_URL}app/api/v2/coupon_validation`,
  ADD_STORE_INTEREST: `${BaseUrl.BASE_URL}app/api/v1/unavailable_location`,
  GET_ADDRESS_LOCATION: `https://maps.googleapis.com/maps/api/geocode/json?`,
  GET_SAVED_ADDRESS: `${BaseUrl.BASE_URL}app/api/v1/address`,
  GET_LOCATION_TOKEN: "https://outpost.mappls.com/api/security/oauth/token",
  GET_ADD_ON: `${BaseUrl.BASE_URL}app/api/v1/get_addon`,
  GET_GIFTCARD_TC: `${BaseUrl.BASE_URL}app/api/v2/banner_and_tc/?page_type=`,
  GET_GIFTCARD_OFFERS: `${BaseUrl.BASE_URL}app/api/v2/list_wallet_offers/?offer_for=`,
  APPLY_GIFTCARD: `${BaseUrl.BASE_URL}app/api/v2/applywalletgcoffer`,
  ISSUE_GIFTCARD: `${BaseUrl.BASE_URL}app/api/v2/issuegiftcard`,
  CLAIM_GIFTCARD: `${BaseUrl.BASE_URL}app/api/v2/claimgiftcard`,
  VIEW_WALLET_BALANCE: `${BaseUrl.BASE_URL}app/api/v2/getwalletbalance`,
  GET_WALLET_OFFER: `${BaseUrl.BASE_URL}app/api/v2/list_wallet_offers/?offer_for=wallet`,
  ADD_WALLET_MONEY: `${BaseUrl.BASE_URL}app/api/v2/addwallet`,
  WALLET_APPLY_OFFER: `${BaseUrl.BASE_URL}app/api/v2/applywalletgcoffer`,
  TERMS_AND_CONDITIONS_WALLET: `${BaseUrl.BASE_URL}app/api/v2/banner_and_tc/?page_type=wallet`,
  SHOW_WALLET_HISTORY: `${BaseUrl.BASE_URL}app/api/v2/getwallet`,
  GET_WALLETGC_STATUS: `${BaseUrl.BASE_URL}app/api/v2/get_cms_wallet_status`,
  GET_MAP_SEARCH_ADDRESS: `${BaseUrl.BASE_URL}map_my_india/api/v1/get_lat_and_long?address=`,
  DISTANCE_MATRIX: `${BaseUrl.BASE_URL}map_my_india/api/v1/distance_matrix`,
  VERIFY_OTP_V1:`${BaseUrl.BASE_URL}authentication/api/v1/email_send_otp`,
  GET_INVOICE_PDF:`${BaseUrl.BASE_URL}app/api/v1/pdf_details`
};


export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  PUT: "PUT",
};
