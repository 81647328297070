export const menuData = [
  {
    category_name: 'My Orders',
    key: '/my-orders',
  },
  {
    category_name: 'BBK Money',
    key: '/wallet',
  },
  {
    category_name: 'Offers',
    key: '/offers', 
  },
  {
    category_name: 'Address',
    key: '/my-address',
  },
  {
    category_name: 'Notification',
    key: '/notification',
  },
  {
    category_name: 'Feedback',
    key: '/feedback',
  },
  // {
  //   category_name: 'Catering',
  //   key: '/catering',
  // },
  {
    category_name: 'Dine In',
    key: '/store-locators',
  },
  // {
  //   category_name: 'Gift Vouchers',
  //   key: '/gift-vouchers',
  // },
  // {
  //   category_name: "Refer & Earn",
  //   key: "/refer-and-earn",
  // },
  {
    category_name: "Loyalty",
    key: "/loyalty-page",
  },
  {
    category_name: 'Logout',
    key: '/product-list',
  },
];

export const guestMenuData = [
  {
    category_name: 'Dine In',
    key: '/store-locators',
  },
];
