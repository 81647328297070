import { useEffect, useState } from "react";
import Button from "../../components/button";
import { getMapToken } from "../../actions/maps";
import { LocalStorage } from "../../utils/storage";
import mapPin from "../../assets/images/map-pin.png";
import LocationImg from "../../assets/images/locationIcon.svg";

const RenderMaps = ({
  callOutlet,
  onConfirmLocation,
  latLng,
  activeTheme,
  selectSavedAddress,
  loader,
  onMapClose,
  stylechange,
  confirmAddress,
  addNew,
  noconfirm
}) => {
  const [addressDetails, setAddressDetails] = useState({});
  const [currentLatLng, setcurrentLatLng] = useState(latLng);
  const [mapLoad, setMapLoad] = useState(true);

  useEffect(async () => {
    const accessToken = await getMapToken();
    const script = document.createElement("script");
    script.src = `https://apis.mappls.com/advancedmaps/api/${accessToken}/map_sdk_plugins?v=3.0`;
    script.defer = true;
    script.async = true;
    document.head.appendChild(script);
    const locationDetail = LocalStorage.get("location");
    if (latLng && noconfirm !== undefined) {
      initMap1(latLng, true);
    }
    else if (activeTheme === "delivery" && locationDetail) {
      let checkCoordinates = await onCurrentLocation();
      let coordinates = { lat: locationDetail.lat, lng: locationDetail.lng };
      if (
        (coordinates.lat === "" && coordinates.lng === "") ||
        (!coordinates.lat && !coordinates.lng)
      ) {
        coordinates = checkCoordinates;
      }
      console.log(addNew, "add new")
      if (latLng && confirmAddress && !addNew) {
        coordinates = latLng;
      }
      initMap1(coordinates, true);
    }

  }, [activeTheme]);

  const initMap1 = (coordinates, mark) => {
    console.log("innnnnnnnnn", coordinates);
    var map, marker, picker;
    console.log(coordinates, "lattlngg")
    map = new window.mappls.Map("map", {
      center: [coordinates.lat, coordinates.lng],
      geolocation: true,
      zoom: 16,
      clickableIcons: false,
      fullscreenControl: false,
      minZoom: 5,
      maxZoom: 18,
      zoomControl: true,
      rotateControl: true,
      // minZoom:5
    });

    if (map && map.addListener) {
      map.addListener("load", function ({ data }) {
        const { offsetWidth, offsetHeight } = map.getCanvas();
        window.mappls.addControl({
          map: map,
          html: `<div id="Picker" className="default_div" style="background:transparent;pointer-events:none;width:${offsetWidth}px;height:
        ${offsetHeight}px; left:0px;top:0px;position:absolute;">
          <img src="https://apis.mappls.com/map_v3/pin.png" alt="maps" style="position:absolute;height:39px;min-height:28px;top:0;bottom:39px;left:0;right:0;margin:auto;">
        </div>`,
          position: "top-left",
        });

        const { lat, lng } = map.getCenter();
        window.mappls.placePicker(
          {
            location: { lat, lng },
            geolocation: true,
          },
          function ({ data }) {
            // result for reverse geocode from center on dragend
            console.log("noved", data);
            let address = {};
            let place;
            if (data) {
              console.log(data, "address");
              address = data;
              place = `Current Location, ${address.formatted_address}`;
            }
            { console.log(address.formatted_address, "add") }
            setAddressDetails(address);
            setcurrentLatLng(coordinates);
          }
        );

        console.log("what is data", data);
        var optional_config = {
          region: "IND",
          height: 300,
          width: 300,
        };

        new window.mappls.search(
          document.getElementById("auto"),
          optional_config,
          (data) => {
            var dt = data[0];
            if (!dt) return false;

            var eloc = dt.eLoc;
            var place = dt.placeName + ", " + dt.placeAddress;

            // Add your custom marker using the pinMarker function
            new window.mappls.pinMarker(
              {
                map: map,
                pin: eloc,
                popupHtml: place,
                popupOptions: {
                  openPopup: false,
                },
                draggable: false,
                icon: {
                  url: mapPin,
                  width: 30,
                  height: 45,
                },
                cType: 1, // Make sure this value is appropriate for your custom marker
              },
              function (data) {
                console.log("searchdata", data);
                // marker.remove();
                marker = data;
                marker.addListener("dragend", (newData) => {
                  initMap1(newData.latlng);
                });
                // marker.fitbounds({ maxzoom: 10 });
              }
            );

            window.mappls.getPinDetails({ map: map, pin: dt.eLoc }, (data) => {
              let address = data.data;
              let coordinates = {
                lat: address.latitude,
                lng: address.longitude,
              };
              dt.lat = address.latitude;
              dt.lng = address.longitude;
              marker = data.data;
              // setcurrentLatLng(coordinates);
              initMap1(coordinates);
            });

            // setAddressDetails(dt);
          }
        );
      });

      /*Place Picker plugin initialization*/
      map.on("moveend", function () {
        const { lat, lng } = map.getCenter();
        window.mappls.placePicker(
          {
            location: { lat, lng },
            geolocation: true,
          },
          function ({ data }) {
            // result for reverse geocode from center on dragend
            console.log("noved", data);
            let address = {};
            let place;
            if (data) {
              console.log(data, "address");
              address = data;
              place = `Current Location, ${address.formatted_address}`;
            }
            // else{
            //   place = `Current Location, ${address.formatted_address}`;
            // }
            setAddressDetails(address);

            setcurrentLatLng(coordinates);
          }
        );
      });
    }
  };
  const onCurrentLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (
              position &&
              position.coords &&
              position.coords.latitude &&
              position.coords.longitude
            ) {
              const coordinates = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              resolve(coordinates);
            }
            // eslint-disable-next-line no-unused-vars
          },
          (_error) => {
            let coordinates = { lat: 19.18, lng: 72.66 };
            resolve(coordinates);
            // reject(_error);
            // setMapLoad(false);
            // onMapClose(true);
            // alert("Unable to fetch the location. Please enter manually");
          }
        );
      } else {
        let coordinates = { lat: 19.18, lng: 72.66 };
        resolve(coordinates);
        // setMapLoad(false);
        // onMapClose(true);
        // alert("Unable to fetch the location. Please enter manually");
      }
    });
  };

  const concatenateWithComma = (placeAddress, ...strings) => {
    var result = strings.filter(Boolean).join(", ");
    console.log(result, "address resylut");
    return result;
  };
  const getCompleteAddress = (address = addressDetails) => {
    const {
      houseName,
      houseNumber,
      subLocality,
      subSubLocality,
      locality,
      city,
      state,
      pincode,
      placeName,
    } = address;

    if (address?.placeName != undefined) {
      return address?.placeName + " " + address.placeAddress;
    } else {
      return concatenateWithComma(
        "",
        houseName,
        houseNumber,
        subSubLocality,
        subLocality,
        locality,
        city,
      );
      // state,
      // pincode
    }
  };

  const styleMap = {
    width: "99%",
    height: "300px",
    display: "inline-block",
    position: "relative",
    top: "1rem",
  };
  if (window.innerWidth < 1023) {
    if (stylechange) {
      styleMap.height = "480px";
    } else {
      styleMap.height = "210px";
    }
  }
  const searchstyle = {
    position: "absolute",
    left: "2rem",
    top: "2rem",
    padding: "12px",
    borderRadius: "2rem",
    width: "65%",
  };

  if (window.innerWidth < 1023) {
    searchstyle.width = "75%"
  }



  return (
    <>
      {/* {mapLoad ? ( */}
      <>
        {activeTheme === "delivery" ? (
          <>
            <div id="map" style={styleMap}>
              {!noconfirm && <input
                type="text"
                id="auto"
                name="auto"
                class="search-outer form-control as-input"
                placeholder="Enter Your Area / Locality"
                required=""
                spellcheck="false"
                style={searchstyle}
              />}
            </div>

            {!noconfirm && <p className="disclaimer">
              Note: Move the map marker to change the delivery location.
            </p>}
            {console.log(addressDetails.data, "++++")}
            {(addressDetails !== {} || addressDetails.data) && (!noconfirm) && (
              <div className="addressDescription">
                <img
                  src={LocationImg}
                  className="addressDescription_locIcon"
                  alt="locationIcon"
                />
                {console.log("")}
                <p className="savedAddress">
                  {getCompleteAddress(addressDetails.data || addressDetails)}
                </p>
              </div>
            )}
            <div className="map-search_buttonBox select-loc-btn-wrapper">
              {selectSavedAddress && selectSavedAddress()}
              {
                !noconfirm &&
                <Button
                  content="Confirm Location"
                  className="map-search_button"
                  isLoading={loader}
                  // onClick={onConfirmLocation}
                  onClick={() => {
                    if (!selectSavedAddress) {
                      LocalStorage.remove("Previouslocation");
                    }
                    let ltln = {
                      lat: currentLatLng.lat,
                      lng: currentLatLng.lng,
                    };
                    const address = getCompleteAddress();
                    if (confirmAddress) {
                      confirmAddress(addressDetails);
                    }
                    else {
                      if (!onConfirmLocation(
                        addressDetails.data || addressDetails,
                        activeTheme,
                        address
                      )) {
                        localStorage.setItem("selectedAddressType", "PICKED_ADDRESS")
                      }
                      callOutlet(
                        ltln,
                        address,
                        addressDetails.data || addressDetails
                      );
                    }
                  }}
                  customClass="map-search_button_content"
                />
              }
            </div>
          </>
        ) : (
          ""
        )}
      </>
      {/* // ) : (
      //   <>
      //   <input type="text" id="search" style={searchstyle} ></input>
      //   <Button></Button>
      //   </>
        
      // )} */}
    </>
  );
};

export default RenderMaps;
