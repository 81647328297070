import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { feedback } from "../../actions/feedback";
import { orderList } from "../../actions/orders";
import FeedbackCard from "../../components/feedback-card";
import FeedbackPopup from "../../components/feedback-popup";
import Toaster from "../../components/toaster";
import "./index.scss";
import { LocalStorage } from "../../utils/storage";
import { ROUTES } from "../../constants/app-constants";
import { Authentication, Logout } from "../../actions/authentication";
import goback from "../../assets/images/goback.svg";
import ProfileCard from "../../components/profile-card";
import MenuSideBar from "../../containers/menuSideBar";
import Header from "../../containers/header";
import HeaderCommon from "../../components/header-common-component";
import AlertPopup from "../../components/alert-popup";
import { menuData } from "../../helpers/constants";
import { logOutIdentityBranch } from "../../utils/branch";

const Feedback = (props) => {
  const {
    Dom,
    profileorder,
    profileData,
    cartShow,
    routeName,
    customClassRoute,
  } = props;

  const [data, setData] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [outletData, setOutletData] = useState({});
  const [active1, setActive1] = useState();
  // const [goBackProfile, setGoBackProfile] = useState(setGoBackProfileProps);
  const [islog, setIsLog] = useState(false);
  const [itemName, setItemName] = useState(""); 
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  // const [isMsg, setIsMsg] = useState({
  //   toastType: false,
  //   toastText: "",
  //   isShow: false,
  // });

  useEffect(() => {
    hadleGetFeedback()
  }, []);

  const hadleGetFeedback=()=>{
    orderList((res) => {
      if (res && res.content && res.response.status === 200) {
        if (res.content) {
          setData(res.content.past_orders);
        }
      }
    });
  }

  const cartshow = (a, b, c, d) => {
    //  
    setItemName(d);
    setModalShow(!modalShow);
    LocalStorage.set("feedbackId", c);

    const payload = {
      outlet_id: a,
      order_type: b,
    };
    feedback(payload, (res) => {
      //  
      if (res && res.content && res.response.status === 200) {
        //  
        if (res.content) {
          //  
          setFeedbackData(res?.content.data);
        }
      }
    });
  };

  const onClickCategory = (itm) => {
    if (itm.category_name === "Logout") {
      setIsLog(true);
      return
    }
    // if (innerWidth <= 1023) {
      setActive1(itm.category_name);
      history.push(itm.key);
    // }
  };

  const confirmLogout = () => {
    Logout((re) => {
      if (re.response.status === 200) {

        //branch event
        logOutIdentityBranch();

        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");

        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              history.push(ROUTES.HOME);
            } else {
              history.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  };

  const { innerWidth } = window;
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };

  const handleBack = () => {
    setModalShow(false);
  };

  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };

  return (
    <>
      {islog && (
        <AlertPopup
          title="Logout?"
          description="Are you sure you want logout?"
          okayClick={() => confirmLogout()}
          logout
          close={() => setIsLog(false)}
        />
      )}
      <div className="feedback">
        {Dom && innerWidth > 1023 && <HeaderCommon />}
        {modalShow && (
          <FeedbackPopup
            data={feedbackData}
            itemData={itemName}
            CartShow={cartshow}
            handleBack={() => handleBack()}
            hadleGetFeedback={hadleGetFeedback}
            setToaster={setToaster}
          />
        )}
        {innerWidth <= 1023 ? (
          <>
            <div className="feedback_wholeWrapper">
              <img
                src={goback}
                className="feedback_img"
                onClick={() => handleGoBack()}
                alt="goback"
              />
              <h1 className="feedback_nameLabel">Feedback</h1>
            </div>
            {data &&
              data.length > 0 &&
              data.map((item) => {
                return <FeedbackCard CartShow={cartshow} data={item} />;
              })}
            {/* {isMsg.isShow && (
              <Toaster
                toastType={isMsg.toastType}
                toastText={isMsg.toastText}
                closeToast={() =>
                  setIsMsg({
                    isShow: false,
                  })
                }
              />
            )} */}
          </>
        ) : (
          <>
            {Dom && (
              <>
                <div className="feedback_wholeSection">
                  <div className="feedback_wholeSection_one">
                    <ProfileCard data={profileData} CartShow={cartShow} />
                  </div>
                  <div className="feedback_wholeSection_two">
                    <div className="feedback_subSectionLeft">
                      <MenuSideBar
                        data={menuData}
                        active={active1}
                        onClickCategory={onClickCategory}
                        routeName={routeName}
                        customClassRoute={customClassRoute}
                      />
                      {/* {getMenuData()} */}
                    </div>
                    <div className="feedback_subSectionRight">
                      <h1 className="feedback_nameLabel">Feedback</h1>
                      {data && data.length > 0 ? (
                        data.map((item) => {
                          return (
                            <FeedbackCard CartShow={cartshow} data={item} />
                          );
                        })
                      ) : (
                        <h3 className="errorData">
                          No orders found for feedback!
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {profileorder && (
              <>
                <h1 className="feedback_nameLabel">Feedback</h1>
                {data &&
                  data.length > 0 &&
                  data.map((item) => {
                    return <FeedbackCard CartShow={cartshow} data={item} />;
                  })}
              </>
            )}
          </>
        )}
          {toaster.isShow && (
        <Toaster closeToast={closeToast} toastText={toaster.toastText} />
      )}
      </div>
    </>
  );
};
export default Feedback;
