import React from "react";
import { useHistory } from "react-router-dom";
import cancelImage from "../../assets/images/cancelled-event.svg";
import goback from "../../assets/images/goback.svg";
import Button from "../button";
import "./index.scss";

const CancelPage = () => {
  const history = useHistory();
  const handleHome = () => history.push("/");

  return (
    <div className="cancelPage">
      <div className="cancelPage_header">
        <img
          src={goback}
          className="cancelPage_header_backImg"
          onClick={() => handleHome()}
          alt="goback"
        />
        <h1 className="cancelPage_header_phrase">Order</h1>
      </div>
      <div className="cancelPage_wholeWrapper">
        <div className="cancelPage_body">
          <img src={cancelImage} className="cancelPage_img" alt="cancelImg"/>
          <div className="cancelPage_wholeWrapper_wholeDiv1">
            <h2 className="wholeDiv1_phrase1">Your order cancelled</h2>
            <h3 className="wholeDiv1_phrase2">
              Still 100+ varieties are there, Try it out !!
            </h3>
          </div>
        </div>
        <div className="cancelPage_subWrapper">
          <Button
            content="Explore food"
            className="cancelPage_subWrapper_button"
            customClass="cancelPage_subWrapper_custom"
            onClick={() => handleHome()}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelPage;
