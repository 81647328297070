import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import closeImg from "../../assets/images/group.svg";
import NewButton from "../../components/newCount-Button";
import { Analytics } from "../../helpers/analytics";
import { LocalStorage } from "../../utils/storage";
import Button from "../../components/button";
import { ROUTES } from "../../constants/app-constants";
import AlertPopup from "../../components/alert-popup";
import {
  isAddonsAvailble,
  handleParseAmount,
  handleGetTotalAmt,
} from "../../helpers/sharedFunction";

import "./index.scss";

const Cartsidebar = (props) => {
  const {
    handleCartSidebar,
    onUpdateContent,
    cartData,
    homeData,
    itemData,
    updatedCartData,
    showLoader,
    setShowLoader,
    addonPopup,
    showCartSidebar,
    addNewProduct,
  } = props;

  const [categoryData, setCategoryData] = useState([]);
  const [alertModal, setAlertModal] = useState({
    isShow: false,
    description: "",
  });
  // const [showLoader, setShowLoader] = useState(true);
  const history = useHistory();
  const sidebarRef = useRef(null);

  let data = LocalStorage.get("CartProducts");
  const outletData = LocalStorage.get("Outlet_Details");

  useEffect(() => {
    //Appending the people also order to the cart product
    data &&
      data.map((prd) => {
        itemData.map((item) => {
          item.items &&
            item.items.map((itemValue) => {
              if (
                itemValue.item_id === prd.item_id &&
                itemValue?.item_goes_well_with?.length > 0
              ) {
                prd.item_goes_well_with = itemValue.item_goes_well_with;
              }
            });
        });
        return prd;
      });

    //Getting the people also order product
    if (data && data.length > 0) {
      let updateItem = [];
      data.map((item) => {
        if (
          item &&
          item.item_goes_well_with &&
          item.item_goes_well_with.length > 0
        ) {
          let goesWellArray = splitItems(item.item_goes_well_with);
          goesWellArray.map((innerItem) => {
            let response = getAddons(homeData.categories, innerItem);
            if (response.item_id) {
              response.count = response.count || 0;
              updateItem.push(response);
            }
          });
        }
      });

      updateItem.length > 0 &&
        updateItem.map((product) => {
          data.map((item) => {
            if (product.item_id === item.item_id) {
              product._id = item._id;
              product.count = item.count;
              product.complimentary_item = false;
            }
            return product;
          });
        });

      let uniqueItems = updateItem.filter(
        (ele, ind) =>
          ind === updateItem.findIndex((elem) => elem.item_id === ele.item_id)
      );
      setCategoryData(uniqueItems);
    }
  }, [updatedCartData]);

  const splitItems = (value) => {
    value = value && value[0];
    let splitValues = value.goes_well_with.split(",");
    return splitValues;
  };

  // console.log("cartsidebar", cartData);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      handleCartSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      handleClickOutside(e);
    });
    return () => {
      document.removeEventListener("mousedown", (e) => {
        handleClickOutside(e);
      });
    };
  }, [sidebarRef]);

  const getAddons = (category, itemId) => {
    let itemData = {};
    category &&
      category.map((item) => {
        if (item && item.has_subcategory === "0") {
          item.items.map((data) => {
            if (data && data.item_id === itemId) {
              itemData = data;
            }
          });
        }
        if (item && item.has_subcategory === "1") {
          item.subcategories.map((itemValue) => {
            if (itemValue && itemValue.item_id === itemId) {
              itemData = itemValue;
            }
          });
        }
      });
    return itemData;
  };

  const handleItem = () => {
    let count = 0;
    const productData = LocalStorage.get("CartProducts");
    productData &&
      productData.map((items) => {
        count = count + items.count;
      });

    if (cartData.count > 1) {
      return (count += " items");
    }
    return (count += " item");
  };

  const handleCheckout = () => {
    const minOrderAmt = handleParseAmount(
      outletData.delivery_area_min_order_amount
    );
    // console.log("minOrderAmt", minOrderAmt);
    // console.log("cartData.price", cartData.price);
    if (cartData.price < minOrderAmt) {
      setAlertModal({
        isShow: true,
        description: `Minimum sub-total amount of ₹${minOrderAmt} not reached. Please add more items.`,
      });
      return;
    }
    Analytics.beginCheckoutEvent(data);
    history.push(ROUTES.CHECKOUT);
  };

  const handleProductType = (type = null) => {
    let clsName = "";
    if (type !== null) {
      if (type) {
        return (clsName = "card-sidebar__product-veg");
      }
      return (clsName = "card-sidebar__product-non-veg");
    }
  };
  let cartLocal = LocalStorage.get("CartProducts");

  const deleteCart = () => {
    cartLocal &&
      cartLocal.length > 0 &&
      cartLocal.map((item) => {
        item.count = 0;
        onUpdateContent(item, [], true);
      });
  };

  const isFindDuplicateElement = (data) => {
    let isDuplicate = false;
    const idArr = [];
    cartLocal &&
      cartLocal.map((item) => {
        if (data && data.item_id === item.item_id) {
          idArr.push(item.item_id);
        }
      });
    if (idArr.length >= 2) {
      isDuplicate = true;
    }
    return isDuplicate;
  };

  const subCount = (item, value, isYouMayLike) => {
    if (value === "add") {
      const addonsAvailble = isAddonsAvailble(homeData.categories, item);
      if (item.count === 0 && item._id) {
        delete item._id;
      }
      if (!addonsAvailble) {
        item.count = item.count + 1;
        onUpdateContent(item, [], true);
        Analytics.addToCardEvent(item, true);
        return;
      }

      addonPopup(item, false);
      handleCartSidebar();
      // item.count = item.count + 1;
    } else {
      const isDublicateElement = isFindDuplicateElement(item);
      if (isYouMayLike && isDublicateElement) {
        setAlertModal({
          isShow: true,
          description: `This item has multiple customisations added. Please remove item from cart.`,
        });
        return;
      }

      if (item.count > 0) {
        setShowLoader((ids) => ({
          ...ids,
          [item._id]: true,
        }));
        item.count = item.count - 1;
        LocalStorage.set("addId", item._id);
        LocalStorage.set("addUp", false);
        onUpdateContent(item, [], true, false);
        Analytics.removeFromCartEvent(item);
      } else {
        item.count = 0;
        onUpdateContent(item, [], true, false);
      }
    }
  };

  const handleAddonCount = (item) => {
    const itemPrice = handleParseAmount(item.single_item_price);
    const itemCount = handleParseAmount(item.item_count);
    return (
      <div className="card-sidebar__products__addons-wrapper">
        <p className="card-sidebar__products__extras">
          {item.item_name} {` [Rs ${itemPrice}]`}
        </p>
        <p className="card-sidebar__products__extras">[{itemCount}]</p>
      </div>
    );
  };

  const handleTotalAmount = (product) => {
    const amt = handleGetTotalAmt([product]);
    return amt;
  };

  const handleAlertModal = () => {
    setAlertModal((prevState) => ({
      ...prevState,
      isShow: !prevState.isShow,
    }));
  };

  const hanldeAddProduct = (item) => {
    addNewProduct(item);
    // addonPopup(item, false);
    handleCartSidebar();
  };

  return (
    <>
      <div className={`${showCartSidebar && "card-sidebar__left"}`}></div>
      <div
        className={"card-sidebar__overlay"}
        style={showCartSidebar ? { width: "39.6rem" } : { width: "0" }}
      >
        <div ref={sidebarRef} className="card-sidebar">
          <div className="card-sidebar__header-wrapper">
            <h6 className="card-sidebar__header">Your Cart</h6>
            <div className="card-sidebar__header__item-count">
              <div className="card-sidebar__header__item">
                <p className="card-sidebar__header__count">{handleItem()}</p>
              </div>
              {/* <img
            src={deleteImg}
            onClick={deleteCart}
            className='card-sidebar__header_del'
            /> */}
              <img
                onClick={handleCartSidebar}
                className="card-sidebar__header__close"
                src={closeImg}
                alt="closeImg"
              />
            </div>
          </div>
          {data &&
            data.map((product) => (
              <div className="card-sidebar__product-wrapper">
                <div className="card-sidebar__products">
                  <div className="card-sidebar__products__items">
                    <div>
                      <div className={handleProductType(product.is_veg)}></div>
                    </div>
                    <h2>{product.item_name}</h2>
                  </div>
                  <p className="card-sidebar__products__amount">
                    {/* {console.log("cartdataaa", product)} */}
                    {product.complimentary_item === false ? (
                      <>
                        &#8377;{" "}
                        {product &&
                          Math.floor(Number(product.item_final_price))}
                        {/* {handleTotalAmount(product , product.count , product.addons)} */}
                        {/* {Number(parseFloat(product.item_final_price).toFixed(2)) *
                      Number(product.count)} */}
                      </>
                    ) : (
                      "Free"
                    )}
                  </p>
                </div>
                <div className="card-sidebar__products__addons">
                  <div className="card-sidebar__products__addons__container">
                    {product.addons &&
                      product.addons.length > 0 &&
                      product.addons.map((item) =>
                        handleAddonCount(item)
                        // <div>
                        // <p className='card-sidebar__products__extras'>
                        //   {item.item_name}
                        // </p>
                        //   {item.final_price}  {item.item_count}
                        // </div>
                      )}
                    {/* {!product.complimentary_item && <OptionCustomize />} */}
                  </div>

                  <div>
                    {/* {showLoader[data._id] ?
                <Loader />
                : */}
                
                    {product.complimentary_item === false && (
                      <NewButton
                        item={product}
                        content={product.count}
                        className="productSummary_product_data_button"
                        subCount={subCount}
                        isLoading={showLoader[product._id]}
                      />
                    )}

                    {/* } */}
                  </div>
                </div>
              </div>
            ))}
          <div className="card-sidebar__products__calculation">
            <div>
              <h1 className="card-sidebar__products__subtotal">Subtotal</h1>
              <h2 className="card-sidebar__products__gst">
                Excluding GST and Delivery Charges.
              </h2>
            </div>
            <p className="card-sidebar__products__total">
              ₹{Math.floor(cartData.price)}
            </p>
          </div>
          {data && data.length > 0 && (
            <Button
              content="Checkout"
              onClick={handleCheckout}
              className="card-sidebar__checkout-btn"
            />
          )}

          <div className="card-sidebar__suggestion">
            {categoryData.length > 0 && (
              <h2 className="card-sidebar__order-header">You May Also Like</h2>
            )}

            {categoryData.map((item) => (
              <div className="card-sidebar__suggestion-card">
                <div className="card-sidebar__suggestion-card-wrapper">
                  <img
                    className="card-sidebar__suggestion-card-img"
                    src={item.item_image_url}
                    alt="Item Image"
                  />
                  <div>
                    <p className="card-sidebar__card-header">
                      {item.item_name}
                    </p>
                    {/* <p className='card-sidebar__card-description'>
                    {item.item_short_description}
                  </p> */}
                  </div>
                </div>
                <div className="card-sidebar__suggestion-card-product">
                  <p className="card-sidebar__card-amount">
                    ₹ {item.item_final_price}
                  </p>
                  {item.count === 0 ? (
                    <Button
                      content="Add"
                      onClick={() => hanldeAddProduct(item, "add")}
                      className="card-sidebar__add-btn"
                    />
                  ) : (
                    <NewButton
                      item={item}
                      content={item.count}
                      className="productSummary_product_data_button"
                      subCount={(item, itemType) =>
                        subCount(item, itemType, true)
                      }
                      isLoading={showLoader[item._id]}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          {alertModal.isShow && (
            <AlertPopup
              title="Sorry!!"
              description={alertModal.description}
              okayClick={() => handleAlertModal()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Cartsidebar;
