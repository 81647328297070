import { LocalStorage, SessionStorage } from "../utils/storage";
import moment from "moment";

import goldloyalty from "../assets/images/goldloyalty.png";
import silverloyalty from "../assets/images/silverloyalty.png";
import bronzeloyalty from "../assets/images/bronzeloyalty.png";

import orderPickedImg from "../assets/images/order-picked.png";
import orderPreparedImg from "../assets/images/order-prepared.svg";
import preparingOrder from "../assets/images/preparingOrder.svg";
import orderAcceptImg from "../assets/images/acceptOrder.svg";
import orderArriving from "../assets/images/delivery.svg";

const locationDetails = LocalStorage.get("location");

export const isAddonsAvailble = (products, itemData) => {
  let isAddonAvailable = false;
  products &&
    products.map((data) => {
      data &&
        data.items.map((item) => {
          if (item.item_id === itemData.item_id) {
            item.groups &&
              item.groups.map((grp) => {
                if (grp.items && grp.items.length > 0) {
                  return (isAddonAvailable = true);
                }
              });
          }
        });
    });
  return isAddonAvailable;
};

export const handleProductForGuestUser = (item, isRepeat, categoryData) => {
  const cartProducts = LocalStorage.get("CartProducts");
  let productData = cartProducts ? cartProducts : [];
  let cartData;
  let itemEqualData;
  cartProducts &&
    cartProducts.map((product) => {
      if (product.item_id === item.item_id) {
        if (product.addons.length === 0 && item.addons.length === 0) {
          itemEqualData = product;
          return itemEqualData;
        }
        if (product.addons.length > 0) {
          var productsItem = product.addons.map(function (x) {
            return x.item_id;
          });
          var addItem = item.addons.map(function (x) {
            return x.item_id;
          });
          if (productsItem.sort().join(",") === addItem.sort().join(",")) {
            itemEqualData = product;
            return itemEqualData;
          }
        }
      }
    });
  if (!itemEqualData) {
    if (isRepeat) {
      const findIndex = productData.findIndex(
        (obj) => obj.localId === item.localId
      );
      if (item.count === 0) {
        productData.splice(findIndex, 1);
      } else {
        productData[findIndex].count = item.count;
        let addonsData = productData[findIndex].addons;
        if (addonsData && addonsData.length && addonsData.length > 0) {
          addonsData.map((data) => {
            data.item_count = item.count;
          });
          productData[findIndex].addons = addonsData;
        }
      }
    } else {
      const localId = Date.now();
      item.localId = localId;
      productData.push(item);
    }
  }
  if (itemEqualData) {
    if (isRepeat) {
      const findIndex = productData.findIndex(
        (obj) => obj.localId === item.localId
      );
      if (item.count === 0) {
        productData.splice(findIndex, 1);
      } else {
        productData[findIndex].count = item.count;
        let addonsData = productData[findIndex].addons;
        //
        if (addonsData && addonsData.length && addonsData.length > 0) {
          addonsData.map((data) => {
            data.item_count = item.count;
          });
          productData[findIndex].addons = addonsData;
        }
      }
    } else {
      const findIndex = productData.findIndex(
        (obj) => obj.localId === itemEqualData.localId
      );
      productData[findIndex].count = itemEqualData.count + item.count;
      let addonsData = productData[findIndex].addons;
      if (addonsData && addonsData.length && addonsData.length > 0) {
        addonsData.map((data) => {
          data.item_count = data.item_count + item.count;
        });
        productData[findIndex].addons = addonsData;
      }
    }
  }
  LocalStorage.set("CartProducts", productData);
  if (productData && productData.length > 0) {
    let totalPrice = 0;
    totalPrice = handleGetTotalAmt(productData);
    cartData = {
      count: productData.length,
      price: totalPrice,
      data: productData,
    };
  } else {
    cartData = {
      count: 0,
      price: 0,
      data: [],
    };
  }

  const updateitem = categoryData.map((items) => {
    let updateItem = [];
    if (items.has_subcategory === "0") {
      updateItem = items.items;
    }
    if (items.has_subcategory === "1") {
      items.subcategories.map((item) => {
        item.items.map((value) => {
          updateItem.push(value);
        });
      });
      items.items = updateItem;
    }
    items.itemData = updateItem.map((item) => {
      item.checked = false;
      item.count = 0;
      if (item.groups.length > 0) {
        item.groups.map((val) => {
          if (val.items.length > 0) {
            val.items.map((groupVal) => {
              groupVal.checked = false;
              return groupVal;
            });
          }
        });
      }
      if (LocalStorage.get("CartProducts")) {
        const productData = LocalStorage.get("CartProducts");
        if (productData && productData.length > 0) {
          productData.map((items) => {
            if (items.item_id === item.item_id) {
              item.count = items.count + item.count;
              // item._id = items._id;
            }
          });
        }
      }
      return item;
    });
    return items;
  });

  return {
    updateitem,
    cartData,
    productData,
  };
};

export const handleParseAmount = (amount) => {
  const parsedAmt = Number(parseFloat(amount).toFixed(2));
  return parsedAmt;
};

export const handleGetTotalAmt = (products) => {
  let totalPrice = 0;
  let totalAddonsPrice = 0;
  const isLoggedIn = LocalStorage.get("isLoggedIn");
  products.map((items) => {
    totalPrice =
      handleParseAmount(items.item_final_price) * items.count + totalPrice;
    if (items.addons && items.addons.length && items.addons.length > 0) {
      if (isLoggedIn) {
        items.addons.map((data) => {
          totalAddonsPrice =
            handleParseAmount(data.final_price) + totalAddonsPrice;
        });
      } else {
        items.addons.map((data) => {
          totalAddonsPrice =
            handleParseAmount(data.final_price) *
              handleParseAmount(data.item_count) +
            totalAddonsPrice;
        });
      }
    }
  });
  totalPrice = totalPrice + totalAddonsPrice;
  return handleParseAmount(totalPrice);
};

export const handleGetTotalSingleProduct = (product, itemCount, addons) => {
  let totalPrice = 0;
  let totalAddonsPrice = 0;
  totalPrice =
    handleParseAmount(product.item_final_price) * itemCount + totalPrice;
  if (addons && addons.length && addons.length > 0) {
    addons.map((data) => {
      data.items.map((checkedItem) => {
        if (checkedItem.checked) {
          totalAddonsPrice =
            handleParseAmount(checkedItem.item_final_price) * itemCount +
            totalAddonsPrice;
        }
      });
    });
  }
  totalPrice = totalPrice + totalAddonsPrice;
  return totalPrice;
};

export const handleConvertToIST = (date) => {
  var currentTimeUTC = new Date(date);
  var currentTimeIST = moment(currentTimeUTC)
    .add(5, "hours")
    .add(30, "minutes");
  return currentTimeIST;
};

export const handleGetLoyaltyTypeImg = (type) => {
  let imgType;
  switch (type) {
    case "Silver":
      imgType = silverloyalty;
      break;
    case "Gold":
      imgType = goldloyalty;
      break;
    case "Bronze":
      imgType = bronzeloyalty;
      break;
    default:
      break;
  }
  return imgType;
};

export const handleGetDeliveryTypeImg = (type) => {
  let imgType;
  switch (type) {
    case "order_shipped":
      imgType = orderPickedImg;
      break;
    case "order_accepted":
      imgType = preparingOrder;
      break;
    case "order_preparing":
      imgType = orderPreparedImg;
      break;
    case "order_placed":
      imgType = orderAcceptImg;
      break;
    case "order_arriving":
      imgType = orderArriving;
      break;
    default:
      break;
  }
  return imgType;
};

export const handleGetDeliveryTypeText = (type) => {
  let deliveryText = "";
  switch (type) {
    case "order_shipped":
      deliveryText = "Order Shipped";
      break;
    case "order_accepted":
      deliveryText = "Order Accepted";
      break;
    case "order_preparing":
      deliveryText = "Food Ready";
      break;
    case "order_placed":
      deliveryText = "Order Placed";
      break;
    case "order_arriving":
      deliveryText = "Order Arriving";
      break;
    case "order_cancelled":
      deliveryText = "Order Canceled";
      break;
    default:
      break;
  }
  return deliveryText;
};

export const handleIsShowStrikeAmt = (strikeAmt, amt) => {
  let isShowStrikeAmt = false;
  if (handleParseAmount(strikeAmt) > handleParseAmount(amt)) {
    isShowStrikeAmt = true;
  }
  return isShowStrikeAmt;
};

export const getDistanceFromLatLong = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = getDegree(lat2 - lat1); // getDegree below
  var dLon = getDegree(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(getDegree(lat1)) *
      Math.cos(getDegree(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

const getDegree = (deg) => {
  return deg * (Math.PI / 180);
};

export const handleIsLocationSelected = () => {
  // debugger;
  let isAddressAvailable = true;

  if (locationDetails) {
    const { lng, lat, address } = locationDetails;
    if (!lng && !lat && !address) {
      isAddressAvailable = false;
    }
  }
  if (!locationDetails) {
    isAddressAvailable = false;
  }

  return isAddressAvailable;
};

export const handleConvertTime = (time) => {
  const splitTime = `${Number(parseFloat(time)) + 1}`;
  const formatedTime = moment(time, "HH:mm").format("hh A");
  const formatedTimeEnd = moment(splitTime, "HH").format("hh A");
  return `${formatedTime} - ${formatedTimeEnd}`;
};

export const handleGroupMinimum = (itm) => {
  const isGroupMinimum =
    itm &&
    itm.groups.filter((item) => item.group_minimum && item.group_minimum >= 1);

  for (let index = 0; index < isGroupMinimum.length; index++) {
    const checkedItems = [];
    isGroupMinimum[index].items.forEach((item1) => {
      if (item1.checked) {
        checkedItems.push(item1);
      }
    });
    if (!(checkedItems.length >= isGroupMinimum[index].group_minimum)) {
      return {
        item: isGroupMinimum[index],
        groupMinimum: true,
      };
    }
  }
};

export const isAddonPresent = (item) => {
  let isAddonAvailable = false;
  item?.groups &&
    item?.groups.map((grp) => {
      if (grp.items && grp.items.length > 0) {
        return (isAddonAvailable = true);
      }
    });
  return isAddonAvailable;
};
