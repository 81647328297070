import React from "react";
import "./index.scss";
import close from "../../assets/images/close-black-symbol.svg";
import closeImg from "../../assets/images/close.svg";
import Modal from "../../components/modal";
import offerImg from "../../assets/images/percent.svg";
const OfferPopup = (props) => {
  const { data, onCloseClick } = props;
  const { innerWidth } = window;
  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <Modal contentCustomClass="offerPopup_contentCustomClass" hideClose>
            <div className="offerPopup">
              <div className="offerPopup_heading">
                <h2 className="offerPopup_product_heading1"> Offer detail</h2>
                <img
                  src={closeImg}
                  width="14"
                  height="14"
                  alt="close"
                  className="offerPopup_heading_closeImg"
                  onClick={() => onCloseClick()}
                />
              </div>
              <div className="offerPopup_content">
                <div className="offerPopup_product">
                  <img
                    src={data.image !== null ? data.image : offerImg}
                    width="108"
                    height="92"
                    alt="offerimg"
                    className="offerPopup_product_img"
                  />
                  <div className="offerPopup_product_content">
                    <p className="offerPopup_product_heading">
                      {data.offer_name}
                    </p>
                    {/* <p className="offerPopup_product_shortdesc">
                      {data.short_description}
                    </p> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.terms_and_conditions,
                      }}
                    />
                  </div>
                </div>
                <div className="offerPopup_product_desc"
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                >
                  {/* {data.description} */}
                </div>
                {/* <div>
            <p className='offerPopup_product_head'>Terms&Conditions</p>
            <ol  className='offerPopup_product_terms'>
              <li className='offerPopup_product_desc'>minimum order amount</li>
            </ol>
          </div> */}
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <>
          <Modal hideClose>
            <div className="offerPopup">
              <div className="offerPopup_heading">
                <h1 className="offerPopup_product_heading1"> Offer Details</h1>
                <img
                  src={close}
                  width="14"
                  height="14"
                  alt="close"
                  onClick={() => onCloseClick()}
                />
              </div>
              <div className="offerPopup_content">
                <div className="offerPopup_product">
                  <img
                    src={data.image !== null ? data.image : offerImg}
                    width="108"
                    height="92"
                    alt="offerImg"
                  />
                  <div className="offerPopup_product_content">
                    <p className="offerPopup_product_heading">
                      {data.offer_name}
                    </p>
                    <p className="offerPopup_product_desc">
                      {data.short_description}
                    </p>
                  </div>
                </div>
                <div className="offerPopup_product_desc"
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                >
                  {/* {data.description} */}
                </div>
                <br></br>
                <p className="offerPopup_product_nameLabel">
                  {data.terms_and_conditions !== null && "Terms and Conditions"}
                </p>
                <p className="offerPopup_product_desc"
                  dangerouslySetInnerHTML={{
                    __html: data?.terms_and_conditions,
                  }}
                >

                </p>
                {/* <div>
            <p className='offerPopup_product_head'>Terms&Conditions</p>
            <ol  className='offerPopup_product_terms'>
              <li className='offerPopup_product_desc'>minimum order amount</li>
            </ol>
          </div> */}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default OfferPopup;
