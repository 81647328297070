import React from 'react';
import './index.scss';
import { handleConvertToIST , handleGetDeliveryTypeText } from "../../helpers/sharedFunction";
import { ROUTES } from '../../constants/app-constants';
import { useHistory } from "react-router-dom";
import moment from "moment";

const ActiveOrderHistoryCard = ({ current_orders }) => {
    console.log("current_orders: " ,current_orders)
    const orderCreated = current_orders?.order_created_at;
    const order_id = current_orders?.divum_order_id;
    const amount = parseFloat(current_orders?.paid_amount).toString();
    const Payment = current_orders?.mode_of_payment;

    // const originalDate = new Date(orderCreated);
    // console.log(orderCreated,"order date")
    // debugger;
    // const monthNames = [
    //     "January", "February", "March", "April", "May", "June",
    //     "July", "August", "September", "October", "November", "December"
    // ];
    // const year = originalDate.getFullYear();
    // const month = originalDate.getMonth();
    // const day = originalDate.getDate();
    // const hour = originalDate.getHours();
    // const minute = originalDate.getMinutes();
    // const formattedDate = `${day}, ${monthNames[month]}, ${year}, ${hour}:${minute < 10 ? '0' : ''}${minute} ${hour < 12 ? 'AM' : 'PM'}`;
    
    const history = useHistory();

    const routeToTrack = () => {
        localStorage.setItem("purchaseDetailId", current_orders?._id);
        history.push({
          pathname: ROUTES.TRACK_ORDER,
          state: true
        })
        
    };

    return (
        <div className='orderHistoryCard' onClick={() => routeToTrack()}>
            <div className='orderHistoryCard_div'>
                <div className='orderHistoryCard_div_details'>
                    <span className='orderID'>Order ID: <span className='bold-text'>  {order_id}</span></span>
                    <span className=''>Order On :{moment(handleConvertToIST(orderCreated)).format(
                        "MMMM Do YYYY, h:mma"
                      )} </span>
                    {/* {"Placed On: " +
                      moment(handleConvertToIST(pastorders.created_at)).format(
                        "MMMM Do YYYY, h:mma"
                      )} */}
                    <span className=''>Amount :  <span className='bold-text'> ₹ {amount}</span> </span>
                    <span className=''>Payment Mode : {Payment}</span>
                </div>
                <div className={`orderHistoryCard_div_status ${current_orders.status === "order_cancelled" ? "red-text" : ""}`}>{handleGetDeliveryTypeText(current_orders.status)}</div>

            </div>
            <div className='orderHistoryCard_viewOrder'>
                <span >VIEW ORDER DETAILS</span>
                <img src='' />
            </div>
            <span className='dividerLine'></span>
        </div>
    )
}

export default ActiveOrderHistoryCard