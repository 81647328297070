import { fetchCall } from "../../utils/ajax";
import { API_CONSTANTS, API_METHODS } from "../../constants/api-constants";

export const getMapToken = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("grant_type", "client_credentials");
  urlencoded.append(
    "client_id",
    // staging
    // "33OkryzDZsKJYZ5MU3Zv8cxcrG66ldIJs1nDkhJwrI5ne9nKoCVh75TXqEF8tqOTHuSvHazEnKFMeJ5no2JApA=="
    //production
  "33OkryzDZsKwgjCUjTCzoGo1IrvTFJxQlI0xJ58uKvcPnZ-n7Vv7HfuVmOYVjawXo-Yb_o5sNGExeVZKcG-Y4w=="
  );
  urlencoded.append(
    "client_secret",
    //staging
    // "lrFxI-iSEg_L98Rc_fdtDKztKUhtzZpvPuCvwQiZ-t42_fIKcThCMOcns31kI2OuObWnsiyg5n-awsdvSHbYUzPSzXZdEvtf"
    //production
    "lrFxI-iSEg-TxvDOgD0c0CYNmARFWFHc0e2zd6bWXdwduz5jIC9UqzfyamqAue3CbWqKcBiToqd1TxNdEeUN4jhYYIP2ne0I"
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      "https://outpost.mappls.com/api/security/oauth/token",
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to fetch the access token.");
    }
    const result = await response.json();
    console.log("Access token:", result);
    return result.access_token;
  } catch (error) {
    console.log("Error fetching access token:", error.message);
    return null;
  }
};

export const getAddressCoordinates = (url, callback) => {
  fetchCall(
    `${API_CONSTANTS.GET_MAP_SEARCH_ADDRESS + url}`,
    API_METHODS.GET,
    "",
    callback
  );
};
