import React, { useEffect, useState } from 'react'
import './index.scss';
import cross from '../../assets/images/cancelclose.svg';
import { LocalStorage } from '../../utils/storage';

const OTPVerify = ({ mail, setAction, onOtpSubmission, resend }) => {
    document.getElementById('root').style.position = "relative";
    const [sec, setSec] = useState(30);
    const err = LocalStorage.get("otpErr");
    useEffect(() => {
        const id = setInterval(() => {
            setSec(sec => sec - 1);
        }, 1000)
        return () => clearInterval(id);
    }, [])
    const resendEmail = () => {
        resend()
        setSec(30)
    }
    return (
        <div className='email-verify'>
            <div className='email-verify_content'>
                <img src={cross} alt="X" onClick={() => setAction.setVerifyModal(false)} />
                <div className='email-verify_content_head'>
                    <h2>Enter OTP</h2>
                    <div className='email_text'>
                        <div className="email_text_top">
                            <p className='opt_text'>We have sent an OTP to </p>
                            <span className='otp_mail'> {mail} </span>
                        </div>
                        {sec <= 0 ?
                            <div className='otp_timer_span_resend' onClick={() => { resendEmail(); setAction.setOtp("") }}>Resend OTP</div>
                            : <div className='otp_timer_span'>Resend OTP in:{sec}sec</div>
                        }
                    </div>
                </div>
                <div>
                    <div className='email-verify_content_password'>
                        <p>One time password</p>
                        <input type='number' placeholder='Enter OTP' value={setAction.otp} onChange={(e) => { setAction.setOtp(e.target.value); LocalStorage.set("otpErr", "") }} />
                        <button onClick={() => { setAction.otp && setAction.otp.length >= 4 && onOtpSubmission() }} className={setAction.otp.length >= 4 ? 'email-verify_content_password_enable' : 'email-verify_content_password_disable'}>SUBMIT</button>
                    </div>
                    {err && <p className='err_msg'>Wrong OTP</p>}
                </div>
            </div>
        </div>
    )
}

export default OTPVerify
