import React, { useEffect, useState } from "react";
import { feedback } from "../../actions/feedback";
import { orderList } from "../../actions/orders";
import FeedbackCard from "../../components/feedback-card";
import FeedbackPopup from "../../components/feedback-popup";
import "./index.scss";
import { LocalStorage } from "../../utils/storage";
import AddAddressCard from "../../components/add-address-card";
import MapModal from "../../containers/mapModal";
import moment from "moment";
import {
  OutletMapping,
  getMenuList,
  handleSaveInterest,
} from "../../actions/home";
import Modal from "../../components/modal";
import AddAddress from "../../containers/add-address";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import {
  addAddressNew,
  getAddress,
  editAddress,
  deleteAddress,
} from "../../actions/location";
import goback from "../../assets/images/goback.svg";
import ProfileCard from "../../components/profile-card";
import { Authentication, Logout } from "../../actions/authentication";
import MenuSideBar from "../../containers/menuSideBar";
import Header from "../../containers/header";
import HeaderCommon from "../../components/header-common-component";
import AlertPopup from "../../components/alert-popup";
import Toaster from "../../components/toaster";
import { menuData } from "../../helpers/constants";
import { logOutIdentityBranch } from "../../utils/branch";
import { getSavedAddress } from "../../actions/home";
import SavedAddress from "../../components/saved-address";
import RenderMaps from "../../containers/MapsCommonApi";
import remove from "../../assets/images/close.svg";
import Button from "../../components/button";
import LocationSearch from "../../assets/images/homeLocation.svg";

const LocationAddress = (props) => {
  const { Dom, profileorder, routeName, customClassRoute } = props;
  const [popup, setPopup] = useState(false);
  const [inputData, setInputData] = useState("");
  const [latLng, setLatLng] = useState({ lat: "", lng: "" });
  const [active, setActive] = useState("delivery");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [activeOrder, setActiveOrder] = useState("orderNow");
  const [inputValue, setInputvalue] = useState({});
  const [errorData, setErrorData] = useState(false);
  const [data, setData] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [errorData1, setErrorData1] = useState(false);
  const [countData, setCountData] = useState({});
  const [location, setLocation] = useState(false);
  const [address, setAddress] = useState("");
  const [reach, setReach] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [place, setPlace] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [othersTxt, setOthersTxt] = useState("");
  const history = useHistory();
  const [addressData, setAddressData] = useState([]);
  const [editStat, setEditStat] = useState(false);
  const [editAddData, setEditAddData] = useState({});
  const [active1, setActive1] = useState();
  const [islog, setIsLog] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [toaster, setToaster] = useState({
    isShow: false,
  });
  const [savedAddress, setSavedAddress] = useState();
  const [alertData, setAlertData] = useState(false);
  const [alertPopup, setAlertPopup] = useState("");
  const [canShowLater, setCanShowLater] = useState(false);
  const [addAddressError, setAddAddressError] = useState(false);
  const [noOutletPopUp, setNoOutletPopUp] = useState("");
  const [addNew, setAddNew] = useState();
  const [addressToAdd, setAddressToAdd] = useState();
  const [saveAddressError, setSaveAddressError] = useState("");

  useEffect(() => {
    getSavedAddress((res) => {
      if (res?.response?.status == 200) {
        setSavedAddress(res.content.address);
      }
    });
    if (latLng.lat === "" && latLng.lng === "") {
      setLocation(false);
      // onCurrentLocation();
    }
    let locationDetails = LocalStorage.get("location");
    if (locationDetails) {
      setLocation(true);
      setLatLng({
        lat: locationDetails && locationDetails.lat,
        lng: locationDetails && locationDetails.lng,
      });
      setActiveOrder(locationDetails && locationDetails.orderTime);
      setActive(locationDetails && locationDetails.orderFor);
      // setInputData(locationDetails && locationDetails.address);
      setDate(locationDetails && locationDetails.date);
      setTime(locationDetails && locationDetails.time);
    }
    handleGetAddress();
  }, []);

  const handleGetAddress = () => {
    getAddress((res) => {
      if (res && res?.response?.status === 200) {
        setAddressData(res?.content?.address);
      }
    });
    getSavedAddress((res) => {
      if (res?.response?.status == 200) {
        setSavedAddress(res.content.address);
      }
    });
  };

  const addressChange = () => {
    history.push(ROUTES.HOME);
  };
  const addressdetailsChange = () => {
    setModalOpen(false);
    setPopup(true);
  };

  const onBackClick = () => {
    setPopup(false);
  };
  const onChangeInput = (value) => {
    setInputData(value);
  };
  const onCurrentLocationData = (value) => {
    setLatLng({ lat: value.latitude, lng: value.longitude });
    setInputData(value.location);
  };
  const onCenter = (value) => {
    if (value === false) {
      setLatLng({ lat: "", lng: "" });
    }
  };
  const confirmAddress = (address) => {
    // LocalStorage.set("addAddressData", latLng);
    LocalStorage.set("addressInput", address);
    // LocalStorage.set("inputdata", inputData);
    console.log(address.Address1, editAddData, " address on confirm")
    setAddressToAdd(address);
    setPopup(false);
    setModalOpen(true);
    setSaveAddressError("");
    if (!addNew) {
      setAddress(editAddData.Address1);
      setReach(editAddData.Address2);
      setOthersTxt(editAddData.AddressType);
    }
    else {
      setAddress("");
      setReach("");
      setOthersTxt("");
    }
  }

  const submitSaveAddress = () => {
    let payload = {}
    console.log(addNew, modalOpen, " come in address")
    if (addNew) {
      payload = {
        address_id: "0",
        city: addressToAdd.city,
        pin_code: addressToAdd.pincode,
        AddressType: othersTxt,
        location_name: addressToAdd.locality,
        address_1: address,
        address_2: reach,
        latitude: addressToAdd.lat ? addressToAdd.lat : latLng.lat,
        longitude: addressToAdd.lng ? addressToAdd.lng : latLng.lng,
        Google_Address: addressToAdd.formatted_address
      }
      addAddressNew(payload, (res) => {
        if (res && res?.response?.status === 200) {
          setToaster({
            toastType: true,
            toastText: res.response.message,
            isShow: true,
          });
          addAddress1();
          handleGetAddress();
          setModalOpen(false);
          setAddress("");
          setReach("");
          setOthersTxt("");
        }
        else {
          console.log("come in address inside edit");
          setSaveAddressError(res.response.message);
        }
      })
    }
    else {
      payload = {
        address_id: editAddData.AddressId,
        city: addressToAdd.city,
        pin_code: addressToAdd.pincode,
        AddressType: othersTxt,
        location_name: addressToAdd.locality,
        address_1: address,
        address_2: reach,
        latitude: addressToAdd.lat ? addressToAdd.lat : latLng.lat,
        longitude: addressToAdd.lng ? addressToAdd.lng : latLng.lng,
        Google_Address: addressToAdd.formatted_address
      }
      editAddress(payload, (res) => {
        if (res && res?.response?.status === 200) {
          setToaster({
            toastType: true,
            toastText: res.response.message,
            isShow: true,
          });
          addAddress1();
          handleGetAddress();
          setModalOpen(false);
          setAddress("");
          setReach("");
          setOthersTxt("");
        } else {
          console.log("come in address inside edit");
          setSaveAddressError(res.response.message);
          // addAddress1();
          // setModalOpen(false);
          // setAddress("");
          // setReach("");
          // setOthersTxt("");
        }
      })
    }
    console.log(addNew, modalOpen, " come in address")
  }
  const onMarkerPosition = (value) => {
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng: value },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[0] &&
            results[0].formatted_address
          ) {
            let pincode = "";
            results[0].address_components.forEach((data) => {
              if (data && data.types) {
                const filter = data.types.filter(
                  (item) => item === "postal_code"
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            // eslint-disable-next-line no-lone-blocks
            setInputvalue({
              location: results[0].formatted_address,
              pincode,
              latitude: value.lat,
              longitude: value.lng,
            });
            setLatLng({ lat: value.lat, lng: value.lng });
            setInputData(results[0].formatted_address);
          }
          console.log(results[0].formatted_address, " call out called")
        }
      },
      this
    );
  };

  // Old function
  // const onConfirmLocation = (value) => {
  //   console.log("onconfirmvalue", value);
  //   debugger;
  //   console.log(value);
  //   // setPopup(false);
  //   LocalStorage.set("addAddressData", latLng);
  //   LocalStorage.set("addressInput", value);
  //   LocalStorage.set("inputdata", inputData);
  //   let payload = {
  //     latitude: latLng.lat,
  //     longitude: latLng.lng,
  //     is_delivery: active === "delivery" ? true : false,
  //     is_order_now: activeOrder === "orderNow" ? true : false,
  //     order_date:
  //       activeOrder !== "orderNow" && date !== ""
  //         ? moment(date).format("YYYY-MM-DD")
  //         : "",
  //     order_time: activeOrder !== "orderNow" ? time : "",
  //     channel: "online_web",
  //   };
  //   OutletMapping(payload, (res) => {
  //     if (res.response.status === 200) {
  //       setErrorData(false);
  //       setErrorData1(false);
  //       if (res.content && res.content.areas) {
  //         setOutlets(res.content.areas);
  //       }
  //       setData([]);
  //       setAddAddressError(false);
  //       setModalOpen(true);
  //       setPopup(false);
  //     } else {
  //       setOutlets([]);
  //       setErrorData(true);
  //       if (active === "delivery") {
  //         payload = {
  //           latitude: latLng.lat,
  //           longitude: latLng.lng,
  //           is_delivery: false,
  //           is_order_now: activeOrder === "orderNow" ? true : false,
  //           order_date:
  //             activeOrder !== "orderNow" && date !== ""
  //               ? moment(date).format("YYYY-MM-DD")
  //               : "",
  //           order_time: activeOrder !== "orderNow" ? time : "",
  //           channel: "online_web",
  //         };
  //         OutletMapping(payload, (res) => {
  //           if (res.response.status === 200) {
  //             if (
  //               res.content &&
  //               res.content.areas &&
  //               res.content.areas.length > 0
  //             ) {
  //               setErrorData1(true);
  //               setCountData({
  //                 count: res.content.areas.length,
  //                 distance: parseFloat(
  //                   res.content.max_distance_user_to_restaurant
  //                 ).toFixed(2),
  //               });
  //               setData(res.content.areas);
  //             }
  //             setAddAddressError(false);
  //             setModalOpen(true);
  //             setPopup(false);
  //           } else {
  //             setAddAddressError(true);
  //             setErrorData(true);
  //             setCountData({});
  //             setData([]);
  //           }
  //         });
  //       } else {
  //         setErrorData1(false);
  //         setCountData({});
  //         setData([]);
  //       }
  //     }
  //   });
  //   // setModalOpen(true);
  // };


  const onConfirmLocation = (value) => {
    // debugger;

    console.log(value, " call out called")
    console.log("add new", addNew);
    // setPopup(false);
    LocalStorage.set("addAddressData", latLng);
    LocalStorage.set("addressInput", value);
    LocalStorage.set("inputdata", inputData);
    if (value)
      setInputData(value);
    let payload;
    if (addNew) {
      payload = {
        address_id: 0,
        city: value.city,

        // latitude: value.lat,
        // longitude: value.lng,
        // is_delivery: true,
        // is_order_now: activeOrder === "orderNow" ? true : false,
        // order_date:
        //   activeOrder !== "orderNow" && date !== ""
        //     ? moment(date).format("YYYY-MM-DD")
        //     : "",
        // order_time: activeOrder !== "orderNow" ? time : "",
        // channel: "online_web",
      }
    }

    if (addNew) {
      //  OutletMapping(payload, (res) => {
      addAddressNew(payload, (res) => {
        if (res.response.status === 200) {
          console.log(res, " call out called ");
          setErrorData(false);
          setErrorData1(false);
          if (res.content && res.content.areas) {
            setOutlets(res.content.areas);
            setCanShowLater(true);
          }
          setData([]);
          setAddAddressError(false); // to remove the no outlet msg under map popup
          setModalOpen(true); // to open add address form
          setPopup(false); // to close map popup
          closeModal(); // to close the saved addresses popup
        } else {
          setNoOutletPopUp(res?.response?.message); // to open no outlet alert popup
          setAddAddressError(true);
          setOutlets([]);
          setErrorData(true);
          closeModal(); // to close the saved addresses popup
          setAlertPopup(res?.response?.status ? res?.response?.message : "");
        }
      });
    }
    else {
      editAddress(payload, (res) => {
        if (res.response.status === 200) {
          console.log(res, " call out called ");
          setErrorData(false);
          setErrorData1(false);
          if (res.content && res.content.areas) {
            setOutlets(res.content.areas);
            setCanShowLater(true);
          }
          setData([]);
          setAddAddressError(false); // to remove the no outlet msg under map popup
          setModalOpen(true); // to open add address form
          setPopup(false); // to close map popup
          closeModal(); // to close the saved addresses popup
        } else {
          setNoOutletPopUp(res?.response?.message); // to open no outlet alert popup
          setAddAddressError(true);
          setOutlets([]);
          setErrorData(true);
          closeModal(); // to close the saved addresses popup
          setAlertPopup(res?.response?.status ? res?.response?.message : "");
        }
      });
    }
    // setModalOpen(true);
  };

  useEffect(() => {
    if (!popup) {
      setAddAddressError(false);
    }
  }, [popup]);


  const handleIamInterested = () => {
    const payload = {
      latitude: latLng.lng,
      longitude: latLng.lat,
      address: inputData,
    };
    handleSaveInterest(payload, (res) => {
      if (res && res?.response?.status === 200) {
        setToaster({
          toastType: true,
          toastText:
            "Thanks for your interest we would love to serve you soon as possible.",
          isShow: true,
        });
      } else if (res && res?.response?.status === 400) {
        setToaster({
          toastType: false,
          toastText: res?.data?.response?.message,
          isShow: false,
        });
      } else {
        setToaster({
          toastType: true,
          toastText: res.response.message,
          isShow: true,
        });
      }
    });
  };

  const addAddress1 = () => {
    console.log(" come in address address change")
    // debugger;
    setModalOpen((prevState) => !prevState);
    // setModalOpen(!modalOpen);
  };

  const submit = () => {
    //  debugger;  
    const locationData = LocalStorage.get("addressInput");
    const location = LocalStorage.get("inputdata");
    // const locationArr = location.split(",");
    let payload = {};
    if (editStat === false) {
      if (address !== "") {

        payload = {
          address_id: "0",
          city: locationData.city,
          pin_code: locationData.pincode,
          AddressType: othersTxt,
          location_name: locationData.locality,
          address_1: address,
          address_2: reach,
          latitude: locationData?.lat ? locationData.lat : latLng.lat,
          longitude: locationData?.lng ? locationData.lng : latLng.lng,
          Google_Address: locationData?.formatted_address,
        };
        addAddressNew(payload, (res) => {
          if (res && res?.response?.status === 200) {
            addAddress1();
            handleGetAddress();
          }
        });
      }
    } //end of if
    else {
      const locationArr = location.split(",");
      payload = {
        address_id: editAddData?.AddressId,
        city: locationArr[locationArr.length - 3]
          ? locationArr[locationArr.length - 3]
          : editAddData?.City,
        pin_code: locationArr[locationArr.length - 2]
          ? locationArr[locationArr.length - 2]
          : editAddData?.Pin,
        AddressType: othersTxt,
        location_name: locationArr[locationArr.length - 5]
          ? locationArr[locationArr.length - 5] +
          locationArr[locationArr.length - 4]
          : editAddData?.Locality,
        address_1: address,
        address_2: reach,
        latitude: locationArr[locationArr.length - 2]
          ? latLng.lat
          : editAddData?.guest_lat,
        longitude: locationArr[locationArr.length - 2]
          ? latLng.lat
          : editAddData?.guest_long,
        Google_Address: locationData.formatted_address,
      };
      editAddress(payload, (res) => {
        if (res && res?.response?.status === 200) {
          setToaster({
            toastType: true,
            toastText: res.response.message,
            isShow: true,
          });
          addAddress1();
          handleGetAddress();
        } else {
          setToaster({
            toastType: true,
            toastText: res.response.message,
            isShow: true,
          });
          addAddress1();
        }
      });
    }
  };

  const onEdit = (item) => {
    console.log(item, " call out");
    // debugger;
    setAddNew(false);
    setEditStat(true);
    setInputData(!item?.Google_Address ? item?.Address2 : item.Google_Address);
    setLatLng({ lat: item.guest_lat, lng: item.guest_long });
    console.log(item.guest_lat, item.guest_long, " lattlngg");
    setPopup(!popup);
    setEditAddData(item);
  };

  const deleteCard = (item) => {
    hanldeDeleteModal(item);
    setDeleteItem(item);
  };

  const handleDeleteItem = () => {
    setIsLoading(true);
    let payload = {};
    payload = {
      address_id: deleteItem?.AddressId,
      city: deleteItem?.City,
      pin_code: deleteItem?.Pin,
      AddressType: [deleteItem?.AddressType],
      location_name: deleteItem?.Locality,
      address_1: deleteItem?.Address1,
      address_2: deleteItem?.Address2,
      latitude: deleteItem?.guest_lat,
      longitude: deleteItem?.guest_long,
    };
    deleteAddress(payload, (res) => {
      if (res && res?.response?.status === 200) {
        //   setNavStat('2');
        setIsLoading(false);
        setDeleteModal(false);
        setToaster({
          toastType: true,
          toastText: res.response.message,
          isShow: true,
        });
        setTimeout(() => {
          handleGetAddress();
          // setTimeout(() => {
          window.location.reload();
          // },100);
        }, 1000);
      } else {
        setIsLoading(false);
        setDeleteModal(false);
        setToaster({
          toastType: false,
          toastText: res.response.message,
          isShow: true,
        });
      }
    });
  };

  const setOtherData = (value) => {
    setOthersTxt(value);
  };
  const { innerWidth } = window;
  const handleGoBack = () => {
    history.goBack();
  };

  const onClickCategory = (itm) => {
    if (itm.category_name === "Logout") {
      setIsLog(true);
      return;
    }
    setActive1(itm.category_name);
    // if (innerWidth <= 1023) {
    history.push(itm.key);
    // }
  };

  const confirmLogout = () => {
    Logout((re) => {
      if (re.response.status === 200) {
        //branch event
        logOutIdentityBranch();

        LocalStorage.set("isLoggedIn", false);
        LocalStorage.set("UserData", {});
        LocalStorage.remove("CartProducts");
        LocalStorage.remove("offerAmount");

        Authentication((res) => {
          if (res.response.status === 200) {
            if (res.content) {
              LocalStorage.set("AccessToken", res.content.access_token);
              LocalStorage.set("RefreshToken", res.content.refresh_token);
              history.push(ROUTES.HOME);
            } else {
              history.push(ROUTES.HOME);
            }
          }
        });
      }
    });
  };

  const hanldeDeleteModal = () => {
    setDeleteModal((prevState) => !prevState);
  };

  const closeToast = () => {
    setToaster((prevState) => ({
      ...prevState,
      isShow: false,
    }));
  };

  // const callOutlet = (ltln, val) => {
  //   console.log(" call out called ");
  //   if (val) {
  //     setInputData(val);
  //   }
  //   // setLatLng({ lat: ltln.lat, lng: ltln.lng });
  //   let payload = {
  //     latitude: ltln.lat,
  //     longitude: ltln.lng,
  //     is_delivery: active === "delivery" ? true : false,
  //     is_order_now: true,
  //     // activeOrder === "orderNow" ? true : false,
  //     order_date:
  //       activeOrder !== "orderNow" && date !== ""
  //         ? moment(date).format("YYYY-MM-DD")
  //         : "",
  //     order_time: activeOrder !== "orderNow" ? time : "",
  //     channel: "online_web",
  //   };

  //   OutletMapping(payload, (res) => {
  //     if (res && res?.response?.status === 200) {
  //       LocalStorage.set("Outlet_Details", res?.content?.areas[0]);
  //       setCanShowLater(true);
  //     } else {
  //       setErrorData(true);
  //       setAlertPopup(res?.response?.status ? res?.response?.message : "");
  //     }
  //   });
  // };

  // Old function
  // const onFindFood = (text, value) => {
  //   console.log("onconfirmvalue", "onFindFood", value);
  //   if (text == "savedaddress") {
  //     setInputData(value);
  //     const dataValue = {
  //       orderFor: active,
  //       orderTime: activeOrder,
  //       deliveryTime: data.delivery_area_delivery_time,
  //       address: value.Address1,
  //       addressType: value.AddressType,
  //       date: date,
  //       time: time,
  //       lat: latLng.lat,
  //       lng: latLng.lng,
  //     };
  //     LocalStorage.set("Previouslocation", dataValue);
  //     LocalStorage.set("outlet_id", value.UnitId);
  //   }

  //   if (activeOrder === "orderLater") {
  //     if (date?.length === 0) {
  //       setToaster({
  //         toastType: true,
  //         toastText: "Date is required",
  //         isShow: true,
  //       });
  //       return;
  //     }
  //     if (time?.length === 0) {
  //       setToaster({
  //         toastType: true,
  //         toastText: "Time is required",
  //         isShow: true,
  //       });
  //       return;
  //     }
  //   }

  //   if (
  //     (active === "delivery" || active === "takeaway") &&
  //     (inputData || value.Address1)
  //   ) {
  //     //
  //     // setPopup(true);
  //     onConfirmLocation();
  //   } else {
  //     setAlertData(true);
  //     // onConfirmLocation();
  //   }
  // };
  const onFindFood = (text, value) => {
    console.log("onconfirmvalue", "onFindFood", value);
    if (text == "savedaddress") {
      setInputData(value);
      const dataValue = {
        orderFor: active,
        orderTime: activeOrder,
        deliveryTime: data.delivery_area_delivery_time,
        address: value.Address1,
        addressType: value.AddressType,
        date: date,
        time: time,
        lat: latLng.lat,
        lng: latLng.lng,
      };
      // LocalStorage.set("Previouslocation", dataValue);
      // LocalStorage.set("outlet_id", value.UnitId);
    }

    const formattedObj = {
      formatted_address: value.Google_Address,
      city: value.City,
      pincode: value.Pin,
      locality: value.Locality,
      lat: value.guest_lat,
      lng: value.guest_long,
      // area: "India",
      // district: "Bengaluru Urban District",
      // houseName: "Block B",
      // houseNumber: "",
      // poi: "Domlur Burial Ground",
      // poi_dist: "46",
      // responsecode: 200,
      // state: "Karnataka",
      // street: "Intermediate Ring Road",
      // street_dist: "80",
      // subDistrict: "Bengaluru North",
      // subLocality: "ISRO Colony",
      // subSubLocality: "",
      // village: "",
    };
    onConfirmLocation(formattedObj);
  };

  const onCloseAlert = () => {
    setNoOutletPopUp("");
  }
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const AddressModal = () => {
    return (
      isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <img
              src={remove}
              className="deliveryContainer_right_removeImg"
              onClick={() => closeModal()}
              alt="remove"
            />
            <h2 className="savedAddress">Previous Saved Addresses</h2>
            <SavedAddress data={savedAddress} onClick={onFindFood} />
          </div>
        </div>
      )
    );
  };
  const handleMap = () => {
    setPopup((prevState) => !prevState);
    setAddNew(true);
  };

  const selectSavedAddress = () => {
    return (
      // active == "delivery" &&
      savedAddress &&
      savedAddress.length > 0 && (
        <>
          <Button
            content="Show Saved Addresses"
            className="map-search_button1"
            onClick={openModal}
            customClass="map-search_button_content"
          />
          <AddressModal />
          {/* <div className="savedAddress__divider"></div> */}
        </>
      )
    );
  };
  const onMapClose = (val) => {
    setPopup(false);
    // setErrorData(true);
  };

  return (
    <>
      {islog && (
        <AlertPopup
          title="Logout?"
          description="Are you sure you want logout?"
          okayClick={() => confirmLogout()}
          logout
          close={() => setIsLog(false)}
        />
      )}
      <div className="locationAddress">
        {innerWidth <= 1023 ? (
          <>
            <div className="locationAddress_wholeWrapper">
              <img
                src={goback}
                className="locationAddress_img"
                onClick={() => handleGoBack()}
                alt="back"
              />
              <p className="locationAddress_nameLabel">Location & Address</p>
            </div>
            <div className="saved_address">
              <p className="saved_address_heading ">Saved Addresses</p>
              <div className="list-address__add-address-btn-sticky">
                <Button
                  className="list-address__add-address-btn"
                  content={"+ Add Address"}
                  onClick={handleMap}
                  customClass="list-address_btncontent"
                // isLoading={outletLoader}
                />
              </div>
            </div>
            <div className="locationAddress_data">
              {/* <AddAddressCard add={true} onClick={() => setPopup(!popup)} /> */}
              <AddAddressCard
                deleteCard={deleteCard}
                onEdit={onEdit}
                data={addressData}
                add={false}
                onClick={() => setPopup(!popup)}
              />
            </div>

            {
              popup && (
                // <MapModal
                //   onCurrentDetectLocation={(value) => onCurrentLocationData(value)}
                //   onBackClick={() => onBackClick()}
                //   activeTheme={active}
                //   inputData={inputData}
                //   onChangeInput={() => onChangeInput()}
                //   onCenterChange={() => onCenter()}
                //   latLng={latLng}
                //   onMarkerPosition={onMarkerPosition}
                //   onConfirmLocation={() => onConfirmLocation()}
                // />
                <div className="mapModal">
                  <Modal
                    hideClose
                    modalCustomClass="mapModal_map"
                    contentCustomClass="mapModal_map_content"
                  >
                    <RenderMaps
                      // callOutlet={callOutlet}
                      confirmAddress={confirmAddress}
                      latLng={latLng}
                      activeTheme={"delivery"}
                      addNew={addNew}
                      selectSavedAddress={selectSavedAddress}
                      stylechange
                    />
                  </Modal>
                </div>
              )}

            {modalOpen && (
              <Modal
                onCloseHandler={addAddress1}
                hideClose
                modalCustomClass="list-address-modal"
              // contentCustomClass="location_content"
              >
                <AddAddress
                  address={address}
                  reach={reach}
                  alternateNumber={alternateNumber}
                  submit={submitSaveAddress}
                  setPlace={setPlace}
                  place={place}
                  othersTxt={othersTxt}
                  setOthersTxt={setOtherData}
                  setAddress={setAddress}
                  setReach={setReach}
                  setAlternateNumber={setAlternateNumber}
                  addressChange={addressChange}
                  loc2={
                    LocalStorage.get("addressInput") &&
                    LocalStorage.get("addressInput")
                  }
                  deliveryText={` ${""
                    // LocalStorage.get("location") &&
                    // LocalStorage.get("location").deliveryTime
                    }50-60 min delivery`}
                  customPopUp={true}
                  customAddress="customAddress"
                  typeOfPlace="typeOfPlace"
                  deliverycontainer={true}
                  saveAddressError={saveAddressError}
                />
              </Modal>
            )}
          </>
        ) : (
          <>
            {Dom && (
              <>
                <HeaderCommon />
                <div className="locationAddress_wholeSection">
                  <div className="locationAddress_wholeSection_one">
                    <ProfileCard />
                  </div>
                  <div className="locationAddress_wholeSection_two">
                    <div className="locationAddress_subSectionLeft">
                      <MenuSideBar
                        data={menuData}
                        active={active1}
                        onClickCategory={onClickCategory}
                        routeName={routeName}
                        customClassRoute={customClassRoute}
                      />
                      {/* {getMenuData()} */}
                    </div>
                    <div className="locationAddress_subSectionRight">
                      <div className="saved_address">
                        <h3 className="locationAddress_nameLabel">Address</h3>
                        <div className="list-address_add-address-btn-sticky">
                          <Button
                            className="list-address__add-address-btn"
                            content={"+ Add Address"}
                            onClick={handleMap}
                            customClass="list-address_btncontent"
                          // isLoading={outletLoader}
                          />
                        </div>
                      </div>

                      <div className="locationAddress_data">
                        {/* <AddAddressCard
                        add={true}
                        onClick={() => setPopup(!popup)}
                      /> */}
                        <AddAddressCard
                          deleteCard={deleteCard}
                          onEdit={onEdit}
                          data={addressData}
                          add={false}
                          onClick={() => setPopup(!popup)}
                        />
                      </div>
                      {popup && (
                        <Modal onCloseHandler={() => onMapClose()}>
                          <RenderMaps
                            // callOutlet={callOutlet}
                            confirmAddress={confirmAddress}
                            latLng={latLng}
                            activeTheme={"delivery"}
                            addNew={addNew}
                            selectSavedAddress={selectSavedAddress}
                            onMapClose={onMapClose}
                          />
                          {addAddressError && (
                            <div className="deliveryContainer_error add_address_error_container">
                              <h2 className="deliveryContainer_error_text add_address_error_text">
                                Sorry! No outlet found near to your location!
                                Please click here if you want BBK outlet to be
                                open near to your location -
                                <span
                                  className="deliveryContainer_interest-text"
                                  onClick={handleIamInterested}
                                >
                                  &nbsp;Yes I Want
                                </span>
                              </h2>
                              <img
                                src={LocationSearch}
                                width="132"
                                // height="117"
                                height="90"
                                alt="Location"
                              />
                            </div>
                          )}
                        </Modal>
                      )}

                      {modalOpen && (
                        <Modal
                          onCloseHandler={addAddress1}
                          modalCustomClass={`${modalOpen ? "enter-address-details" : ""
                            }`}
                        >
                          <AddAddress
                            address={address}
                            data={addressData}
                            reach={reach}
                            alternateNumber={alternateNumber}
                            submit={submitSaveAddress}
                            setPlace={setPlace}
                            place={place}
                            othersTxt={othersTxt}
                            setOthersTxt={setOtherData}
                            setAddress={setAddress}
                            setReach={setReach}
                            setAlternateNumber={setAlternateNumber}
                            addressChange={addressdetailsChange}
                            loc2={
                              LocalStorage.get("addressInput") &&
                              LocalStorage.get("addressInput")
                            }
                            deliveryText={` ${""
                              // LocalStorage.get("location") &&
                              // LocalStorage.get("location").deliveryTime
                              }50-60 min delivery`}
                            isHideOrderType={true}
                            saveAddressError={saveAddressError}
                          />
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {profileorder && (
              <>
                <p className="locationAddress_nameLabel">Address</p>
                <div className="locationAddress_data">
                  {/* <AddAddressCard add={true} onClick={() => setPopup(!popup)} /> */}
                  <AddAddressCard
                    deleteCard={deleteCard}
                    onEdit={onEdit}
                    data={addressData}
                    add={false}
                    onClick={() => setPopup(!popup)}
                  />
                </div>
                {popup && console.log("profileorder", "1", editStat)}
                {popup && (
                  <Modal>
                    <RenderMaps
                      // callOutlet={callOutlet}
                      confirmAddress={confirmAddress}
                      latLng={latLng}
                      activeTheme={"delivery"}
                      selectSavedAddress={selectSavedAddress}
                      addNew={addNew}
                    />
                  </Modal>
                  // <MapModal
                  //   onCurrentDetectLocation={(value) =>
                  //     onCurrentLocationData(value)
                  //   }
                  //   onBackClick={() => onBackClick()}
                  //   activeTheme={active}
                  //   inputData={inputData}
                  //   onChangeInput={() => onChangeInput()}
                  //   onCenterChange={() => onCenter()}
                  //   latLng={latLng}
                  //   onMarkerPosition={onMarkerPosition}
                  //   onConfirmLocation={() => onConfirmLocation()}
                  // />
                )}
                {console.log(address, reach, "data")}
                {modalOpen && (
                  <Modal
                    onCloseHandler={addAddress1}
                    modalCustomClass={`${modalOpen ? "enter-address-details" : ""
                      }`}
                  >
                    <AddAddress
                      address={address}
                      reach={reach}
                      alternateNumber={alternateNumber}
                      submit={submitSaveAddress}
                      setPlace={setPlace}
                      place={place}
                      othersTxt={othersTxt}
                      setOthersTxt={setOtherData}
                      setAddress={setAddress}
                      setReach={setReach}
                      setAlternateNumber={setAlternateNumber}
                      addressChange={addressChange}
                      loc2={
                        LocalStorage.get("addressInput") &&
                        LocalStorage.get("addressInput")
                      }
                      deliveryText={` ${""
                        // LocalStorage.get("location") &&
                        // LocalStorage.get("location").deliveryTime
                        }50-60 min delivery`}
                      isHideOrderType={true}
                    />
                  </Modal>
                )}
              </>
            )}
          </>
        )}
        {noOutletPopUp && (
          <AlertPopup
            title="Sorry!!"
            description={noOutletPopUp}
            okayClick={() => onCloseAlert()}
            alertCart={true}
          />
        )}
        {deleteModal && (
          <AlertPopup
            title={`Delete !`}
            description={`Are you sure you want to delete?`}
            okayClick={() => handleDeleteItem()}
            close={() => hanldeDeleteModal()}
            alertCart
            logout={true}
            isLoading={isLoading}
          />
        )}
        {toaster.isShow && (
          <Toaster closeToast={closeToast} toastText={toaster.toastText} />
        )}
      </div>
    </>
  );
};

export default LocationAddress;
