import React, { useEffect, useState } from "react";
import { Link, Router, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/whiteLogo.png";
import Button from "../../components/button";
import Input from "../../components/input";
import locationHeader from "../../components/location-header";
import Location from "../../components/location-header";
import userImg from "../../assets/images/userIcon.svg";
import WalletIcon from "../../assets/images/wallet.png";
import Option from "../../components/option";
import { ROUTES } from "../../constants/app-constants";
import { LocalStorage, SessionStorage } from "../../utils/storage";
import DeliveryIcon from "../../assets/images/deliveryIcon.svg";
import locationIcon from "../../assets/images/locationIcon.svg";
import deliveryTimeIcon from "../../assets/images/deliverytimeIcon.svg";
import SearchIcon from "../../assets/images/shape.svg";
import DineInIcon from "../../assets/images/group-53.svg";
import CateringIcon from "../../assets/images/cateringIcon.svg";
import MenuIcon from "../../assets/images/menuIcon.svg";
import SearchInput from "../../components/input";
import "./index.scss";
import { Authentication } from "../../actions/authentication";
import ProfileDropdown from "../../components/profile-dropdown";
import { getStaticPage } from "../../actions/staticpages";
import { Analytics } from "../../helpers/analytics";
import takeAwayIcon from "../../assets/images/work.svg";
import moment from "moment";
import {
  getDistanceFromLatLong,
  handleParseAmount,
} from "../../helpers/sharedFunction";
import AlertLocationChange from "../../components/alert-location-change";
import { viewWalletBalance } from "../../actions/wallet";
import { walletgcStatusCheckApi } from "../../actions/giftcards";
import { getDeliveryDistance } from "../../actions/location";
import { OutletMapping } from "../../actions/home";

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    showButton,
    LoginCLick,
    SigninClick,
    deliveryClick,
    handleScrollToCategory,
    profileData,
    headerDeliveryTime
  } = props;
  const { innerWidth } = window;
  const [isProfile, setIsProfile] = useState(false);
  const [walletStatus, setWalletStatus] = useState(null);
  const [walletBalance, setWalletBalance] = useState();
  const [showLocation, setShowLocation] = useState(true);
  const [active, setActive] = useState("");
  const [data, setData] = useState({});
  // const [profileData, setProfileData] = useState({});
  const [staticPage, setStaticPage] = useState(false);
  const profileImage = LocalStorage.get("profileImg");
  const [loginActive, setLoginActive] = useState(false);
  const [changeLocationAlert, setChangeLocationAlert] = useState(false);
  const [previousLocationData, setPreviousLocationData] = useState();
  const [walletgcStatus, setWalletgcStatus] = useState();
  const [showWalletBalance, setShowWalletBalance] = useState(false);

  const [selectedAddressType, setSelectedAddressType] = useState(() => {
    let localValue = localStorage.getItem("selectedAddressType");
    return localValue ? localValue : null;
  });
  let outletDt = LocalStorage.get("Outlet_Details");
  useEffect(() => {
    if (LocalStorage.get("location")) {
      const value = LocalStorage.get("location");
      // debugger;
      setData(value);
    }
    Analytics.logEventWithVariable(
      Analytics.Order.location,
      JSON.stringify(LocalStorage.get("location"))
    );

    if (LocalStorage.get("Previouslocation")) {
      const previousValue = LocalStorage.get("Previouslocation");
      setPreviousLocationData(previousValue);
    }
    viewWalletBalance((res) => {
      if (res && res?.response?.status === 200) {
        setWalletStatus(res?.content?.status);
        setWalletBalance(res?.content?.balance);
      }
    });
  }, []);

  useEffect(() => {
    let locationDetails = LocalStorage.get("location");
    const isShowLocationAlert = SessionStorage.get("locationAlert");
    if (navigator.geolocation && !isShowLocationAlert) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            if (
              locationDetails &&
              locationDetails.lat &&
              locationDetails.lng &&
              json
            ) {
              const disInKm = getDistanceFromLatLong(
                locationDetails.lat,
                locationDetails.lng,
                json.lat,
                json.lng
              );
              if (disInKm > 5) {
                setChangeLocationAlert(true);
                // handleAlertChangeLocation()
              }
            }
          }
        },
        (_error) => { }
      );
    }
  }, []);
  useEffect(() => {
    walletgcStatusCheckApi((res) => {
      if (res && res?.response?.status === 200) {
        if (res && res.content) {
          console.log("Setting walletgcStatus:", res.content);
          setWalletgcStatus(res.content);
          setShowWalletBalance(res.content.is_wallet_active);
        }
      }
    });
  }, []);

  const handleAlertChangeLocation = () => {
    setChangeLocationAlert((prevState) => !prevState);
    SessionStorage.set("locationAlert", true);
  };

  const staticRender = (url) => {
    const queryParams = url;
    history.push({
      pathname: queryParams,
    });
  };

  const onProfileClick = () => {
    // history.push(ROUTES.PROFILE);
    setIsProfile(!isProfile);
  };

  const onLoginClick = () => {
    LocalStorage.set("loginflow", true);
    history.push({
      pathname: ROUTES.LOGIN,
      state: { redirectToCheckOut: true },
    });
  };
  const onSigninClick = () => {
    history.push({
      pathname: ROUTES.SIGNIN,
      state: { redirectToCheckOut: true },
    });
  };

  const onClickMenuRoute = (value) => {
    if (
      history.location.pathname === "/" ||
      history.location.pathname === "/webapp"
    ) {
      handleScrollToCategory();
    } else {
      SessionStorage.set("isScrollToCategory", true);
      history.push({
        pathname: ROUTES.HOME,
      });
    }
  };

  const onHomeClick = () => {
    if (
      location.pathname !== ROUTES.HOME ||
      location.pathname !== ROUTES.PRODUCTLIST
    ) {
      LocalStorage.remove("EndKey");
      // LocalStorage.remove("CancelCountDown");
      history.push(ROUTES.HOME);
    }
  };

  // const onClickMenu = (value) => {
  //   history.push(value);
  // };
  const locationPage = () => {
    history.push(ROUTES.HOME);
  };

  const onDineInClick = (key) => {
    // debugger;
    // staticRender(key);
    history.push(ROUTES.STORE_LOCATOR);
  };
  const onClickWallet = (key) => {
    if (LocalStorage.get("isLoggedIn") === true) {
      history.push(ROUTES.WALLET);
    } else {
      LoginCLick();
    }
  };
  const onClickCatering = (key) => {
    history.push(ROUTES.CATERING);
  };

  const onChangeInput = (e) => {
    const { target } = e;
    const { value } = target;
    let locationDetails = LocalStorage.get("location");
    if (value !== locationDetails && locationDetails?.orderTime) {
      // history.push(ROUTES.HOME);
    }
  };

  let locationDetails = LocalStorage.get("location");
  let previousLocationDetails = LocalStorage.get("Previouslocation");

  const getLocationImgType = () => {
    let imgType = locationIcon;
    if (locationDetails && locationDetails.orderFor === "takeaway") {
      imgType = takeAwayIcon;
    }
    return <img src={imgType} alt="locationIcon" width="20" height="24" />;
  };

  const getLocationType = () => {
    let locationType = "";
    if (locationDetails && locationDetails.orderFor === "takeaway") {
      locationType = "Takeaway. ";
    }
    return locationType;
  };

  const handleChangeLocation = () => {
    deliveryClick();
    handleAlertChangeLocation();
    SessionStorage.set("locationAlert", true);
  };

  const handleConvertTime = (time) => {
    const splitTime = `${Number(parseFloat(time)) + 1}`;
    const formatedTime = moment(time, "HH:mm").format("hh A");
    const formatedTimeEnd = moment(splitTime, "HH").format("hh A");
    return `${formatedTime} - ${formatedTimeEnd}`;
  };

  const [preparationTime, setPreprationTime] = useState("");
  const callOutletMapping = async (locationDetails) => {
    let payload = {
      latitude: locationDetails?.lat,
      longitude: locationDetails?.lng,
      is_delivery: true,
      is_order_now: true,
      order_date: "",
      order_time: "",
      channel: "online_web",
    };

    OutletMapping(payload, (res) => {
      if (res && res?.response?.status === 200) {
        if (res && res?.content && res?.content?.areas) {
          let newPayload = {
            source_latitude: Number(locationDetails?.lat),
            source_longitude: Number(locationDetails?.lng),
            destination_latitude: Number(res?.content?.areas[0].latitude),
            destination_longitude: Number(res?.content?.areas[0].longitude),
          }
          getDeliveryDistance(newPayload, (response) => {
            setPreprationTime(
              Number(
                (res?.content?.areas[0]?.food_preparation_time)) +
              Number(response?.content?.duration.toFixed(0)));
          });
        }
      } else {
        return null;
      }
    })
  }

  useEffect(() => {
    let sourceType = getSelectedLocationType();
    if (sourceType) {
      switch (sourceType) {
        case "saved_address":
          callOutletMapping(LocalStorage.get("Previouslocation"));
          break;
        case "picked_address":
          callOutletMapping(LocalStorage.get("location"));
          break;
        case "no_address":
        case "take_away":
        default:
          break;
      }
    }
  }, []);

  const getSelectedLocationType = () => {
    let sourceType = null;
    let previousLocationData = LocalStorage.get("Previouslocation");
    let data = LocalStorage.get("location");
    let selectedAddressType = LocalStorage.get("selectedAddressType");
    if ((data && data?.address !== "") || (previousLocationData && previousLocationData?.address !== "")) {
      sourceType = `${(data || previousLocationData) &&
        (data || previousLocationData)?.orderFor &&
        (data || previousLocationData)?.orderFor === "takeaway"
        ? "take_away"
        : (selectedAddressType && selectedAddressType === "SAVED_ADDRESS") ? "saved_address" :
          (data?.address !== "") ? "picked_address" : "no_address"
        }`
    } else {
      sourceType = "no_address"
    }
    return sourceType;
  }

  useEffect(() => {
    let data = LocalStorage.get("location");
    let previousLocationData = LocalStorage.get("Previouslocation");
    if ((data || previousLocationData)?.orderFor === "takeaway") {
      LocalStorage.set("isDistanceCategory", false);
    } else {
      // LocalStorage.set("isDistanceCategory", true);
    }
  }, [previousLocationData, data, selectedAddressType])

  return (
    <div className="header">
      <div className="header_logo">
        {innerWidth >= 1023 && (
          // <a
          // style={{ color: "#0091ff", textDecoration: "none" }}
          // href="http://biryanibykilo.com/"
          // >
          <img
            src={Logo}
            height="70"
            alt="logo"
            onClick={() => onHomeClick()}
          />
          // </a>
        )}
      </div>
      <AlertLocationChange
        changeLocationAlert={changeLocationAlert}
        handleChangeLocation={handleChangeLocation}
        handleAlertChangeLocation={handleAlertChangeLocation}
      />

      <div className="header_section">
        {location.pathname === ROUTES.HOME ||
          location.pathname === ROUTES.PRODUCTLIST ||
          location.pathname === ROUTES.MENU ||
          location.pathname === ROUTES.WALLET ||
          location.pathname === ROUTES.BLOGS ||
          location.pathname === ROUTES.CHECKOUT ||
          location.pathname === ROUTES.PROFILE ||
          location.pathname === ROUTES.PASTORDERS ||
          location.pathname === ROUTES.SEARCH ||
          location.pathname === ROUTES.TRACK_ORDER ||
          location.pathname === ROUTES.GIFTCARD ||
          location.pathname === ROUTES.LOYALTY ||
          location.pathname === ROUTES.REFER_EARN ||
          location.pathname === ROUTES.MYADDRESS ||
          location.pathname === ROUTES.FEEDBACK ||
          location.pathname === ROUTES.GIFT_CARDS ||
          location.pathname === ROUTES.STORE_LOCATOR ||
          location.pathname === ROUTES.MYORDERS ||
          location.pathname === "/our-vision" ||
          location.pathname === "/safety-standards" ||
          location.pathname === "/bbk-cares" ||
          location.pathname === "/sponsor-biryani" ||
          location.pathname === "/loyalty" ||
          location.pathname === "/dinein" ||
          location.pathname === "/media" ||
          location.pathname === "/catering" ||
          location.pathname === "/contact-us" ||
          location.pathname === "/about-us" ||
          location.pathname === "/status" ||
          location.pathname === "/current-blog" ||
          location.pathname === "/terms-conditions" ||
          location.pathname === location.pathname ? (
          <>
            <div className="category-header_category-location">
              <div
                onClick={
                  // locationPage
                  () => deliveryClick()
                }
                className="category-header_category-location_div"
              >
                <div className="category-header_category-location_icon">
                  {getLocationImgType()}
                </div>
                <div className="category-header_category-location__data1">
                  <div className="category-header_category-location_location">
                    {/* <div className='category-header_category-location_location1'>
                      <p> Home.</p>
                    </div> */}
                    <div className="category-header_category-location_location2">


                      <p className="one">
                        {((selectedAddressType && selectedAddressType === "SAVED_ADDRESS") &&
                          !((locationDetails || previousLocationDetails) &&
                            (locationDetails || previousLocationDetails)?.orderFor &&
                            (locationDetails || previousLocationDetails).orderFor === "takeaway")) &&
                          previousLocationData?.address !== "" && `${previousLocationData?.addressType + `,`}`}
                      </p>
                      <p className="two">
                        {(data.address !== "" || (previousLocationData && previousLocationData.address !== ""))
                          ? `${getLocationType()}${(locationDetails || previousLocationDetails) &&
                            (locationDetails || previousLocationDetails)?.orderFor &&
                            (locationDetails || previousLocationDetails).orderFor === "takeaway"
                            ? outletDt.display_name
                            : (selectedAddressType && selectedAddressType === "SAVED_ADDRESS") ? previousLocationData?.address :
                              (data?.address !== "") ? data?.address : "Select your Location"
                          }`
                          : "Select your Location"}
                      </p>
                    </div>
                  </div>

                  <div className="category-header_category-location_time">
                    <div className="category-header_category-location_select">
                      <Option
                        img="true"
                        selectedOption={
                          (locationDetails || previousLocationDetails) &&
                          (locationDetails || previousLocationDetails).orderTime
                        }
                        onChangeInput={onChangeInput}
                      />
                    </div>
                    {(locationDetails || previousLocationDetails) &&
                      (locationDetails || previousLocationDetails).orderFor ===
                      "delivery" &&
                      (locationDetails || previousLocationDetails).orderTime !==
                      "orderLater" &&
                      data.address !== "" && (
                        <div className="category-header_category-location_deliveryTime">
                          <img
                            src={DeliveryIcon}
                            className="category-header_category-location_deliveryTime_img"
                            alt="deliveryIcon"
                          />
                          <h1 className="category-header_category-location_timeDelivery">
                            {`${(headerDeliveryTime && headerDeliveryTime !== "...")
                              ? headerDeliveryTime
                              : preparationTime
                                ? preparationTime
                                : "..."
                              } min delivery`}
                          </h1>
                        </div>
                      )}
                    <div className="category-header_category-location_deliveryTime">
                      {(locationDetails || previousLocationDetails)
                        ?.orderTime &&
                        (locationDetails || previousLocationDetails)
                          ?.orderTime !== "orderNow" && (
                          <p className="category-header_category-location_timeDelivery2">
                            {moment(
                              (locationDetails || previousLocationDetails).date
                            ).format("MMMM Do, ") +
                              handleConvertTime(
                                (locationDetails || previousLocationDetails)
                                  .time
                              )}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {innerWidth >= 1023 && (
              <>
                <Link to={ROUTES.SEARCH} className="link_router">
                  <div
                    className={`category-header_dineIn-div ${location.pathname === "/search" ? "active" : ""
                      }`}
                  // onClick={() => onClickMenu("/search")}
                  >
                    <img src={SearchIcon} alt="dineInIcon" />
                    <div className="category-header_dine-In">
                      <h4>Search</h4>
                    </div>
                  </div>
                </Link>
                <Link to={ROUTES.STORE_LOCATOR} className="link_router">
                  <div
                    className={`category-header_dineIn-div ${location.pathname === ROUTES.STORE_LOCATOR ? "active" : ""
                      }`}
                  // onClick={() => onDineInClick("dinein")}
                  >
                    <img src={DineInIcon} alt="dineInIcon" />
                    <div className="category-header_dine-In">
                      <h4>Dine In</h4>
                    </div>
                  </div>
                </Link>
                <div
                  className={`category-header_dineIn-div ${location.pathname === "/menu" ? "active" : ""
                    }`}
                  onClick={() => onClickMenuRoute("/search")}
                >
                  <img src={MenuIcon} alt="menuIcon" />
                  <div className="category-header_dine-In">
                    <h4>Menu</h4>
                  </div>
                </div>
                <div
                  className="category-header_dineIn-div"
                  onClick={() => {
                    // staticRender("/useful-links/catering");
                    staticRender("/catering");
                  }}
                >
                  <img src={CateringIcon} alt="menuIcon" />
                  <div className="category-header_dine-In">
                    <h4>Catering</h4>
                  </div>
                </div>
                {/* <div
                  className={`category-header_wallet-div ${
                    location.pathname === ROUTES.WALLET ? "active" : ""
                  }`}
                  onClick={() => onClickWallet("wallet")}
                >
                  {walletgcStatus && walletgcStatus.is_wallet_active ? (
                    <>
                      <div className="category-header_walletBalance">
                        ₹ {walletBalance == 0 ? "0" : walletBalance}
                      </div>
                      <img
                        src={WalletIcon}
                        alt="walletIcon"
                        className="category-header_walletImg"
                      />
                    </>
                  ) : null}
                </div> */}
                {LocalStorage.get("isLoggedIn") === true ? (
                  <div
                    className={`category-header_wallet-div ${location.pathname === ROUTES.WALLET ? "active" : ""
                      }`}
                    onClick={() => onClickWallet("wallet")}
                  >
                    {showWalletBalance && (
                      <>
                        <div className="category-header_walletBalance">
                          ₹ {walletBalance == 0 ? "0" : walletBalance}
                        </div>
                        <img
                          src={WalletIcon}
                          alt="walletIcon"
                          className="category-header_walletImg"
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    <div
                      className={`category-header_wallet-div ${location.pathname === ROUTES.WALLET ? "active" : ""
                        }`}
                      onClick={() => onClickWallet("wallet")}
                    >
                      {showWalletBalance && (
                        <>
                          <img
                            src={WalletIcon}
                            alt="walletIcon"
                            className="category-header_walletImg"
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {location.pathname === ROUTES.LOGIN ||
              location.pathname === ROUTES.SIGNIN ? (
              ""
            ) : LocalStorage.get("isLoggedIn") === true ? (
              <div
                className="category-header_profile-icon"
                onMouseEnter={() => onProfileClick()}
              >
                <img
                  className="pImg"
                  src={
                    profileImage && profileImage !== "" ? profileImage : userImg
                  }
                  alt="profile"
                  width="35"
                  height="35"
                />
                {isProfile && (
                  <div
                    onMouseEnter={() => setIsProfile(true)}
                    onMouseLeave={() => setIsProfile(false)}
                    className="category-header_profile-icon_profile"
                  >
                    <ProfileDropdown
                      staticRender={staticRender}
                      data={profileData}
                      walletBalance={walletBalance}
                      walletStatus={walletStatus}
                    />
                  </div>
                )}
              </div>
            ) : (
              showButton && (
                <>
                  <Button
                    type="button"
                    className="header_section_login"
                    content="Login"
                    onClick={() =>
                      // onLoginClick()
                      LoginCLick()
                    }
                  />
                  {/* <Button
                    type='button'
                    className='header_section_signup'
                    content='Signup'
                    onClick={() => 
                      // onSigninClick()
                      SigninClick()
                    }
                  /> */}
                </>
              )
            )}
          </>
        ) : (
          <div className="header_data">
            {location.pathname === ROUTES.LOGIN ||
              location.pathname === ROUTES.SIGNIN ? (
              ""
            ) : LocalStorage.get("isLoggedIn") === true ? (
              <div
                className="category-header_profile-icon"
                onMouseOver={() => onProfileClick()}
              >
                <img
                  className="pImg"
                  src={
                    profileImage && profileImage !== "" ? profileImage : userImg
                  }
                  alt="profile"
                  width="25"
                  height="25"
                />
              </div>
            ) : (
              showButton ||
              (location.pathname !== ROUTES.HOME && (
                <>
                  <Button
                    type="button"
                    className="header_section_login"
                    content="Login"
                    onClick={() => onLoginClick()}
                  />
                </>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
