import React, { useState } from "react";
import "./index.scss";

const Loader = (props) => {
    const { customClass } = props;

    return (
        <div class={`lds-ellipsis ${customClass}`}><div></div><div></div><div></div><div></div></div>
    )
}

export default Loader;