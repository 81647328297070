import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import "./index.scss";
import deliveryImg from "../../assets/images/delivery-cuate.svg";
import infoImg from "../../assets/images/info.svg";
import Feedback from "../../pages/feedback";
import moment from "moment";
import Button from "../button";
import { ROUTES } from "../../constants/app-constants";
import { LocalStorage } from "../../utils/storage";

const FeedbackCard = (props) => {
  const { data, CartShow } = props;
  const [itemsName, setItemsName] = useState("");

  const { innerWidth } = window;
  const history = useHistory()

  let orderCreated = data?.order_created_at;
  // let sTime = new Date(orderCreated);
  var currentTimeUTC = new Date(orderCreated);
  var currentTimeIST = moment(currentTimeUTC)
    .add(5, "hours")
    .add(30, "minutes");
  let createdTime = moment(currentTimeIST._d.toString()).format("h:mm a");

  const routeToTrack = () => {
    //  
    LocalStorage.set("pastOrder", data);
    history.push(ROUTES.PASTORDERS);
  };

  return (
    <>
      {Object.keys(data).length > 0 ? (
        <>
          {innerWidth <= 1023 ? (
            data?.is_cancelled === false && (
              <>
                <div className="feedbackCard">
                  <img src={deliveryImg} className="feedbackCard_img" alt="deliveryImg" />
                  <div className="feedbackCard_wholediv">
                    <div className="feedbackCard_division">
                      {data &&
                        data.cart_items &&
                        data.cart_items.length > 0 &&
                        data.cart_items.map((item) => {
                          return (
                            <span className="feedbackCard_division_nameLabel">
                              {item.name}
                            </span>
                          );
                        })}
                      <p className="feedbackCard_division_subLabel">
                        {moment(data?.order_created_at).format("D, MMMM YY , ")}
                        {createdTime}{" "}
                      </p>
                      <p className="feedbackCard_division_subLabel">
                        Order id : {data.divum_order_id}
                      </p>
                      <Button
                  content="View Order"
                  onClick={() => routeToTrack()}
                  className="feedbackCard_view-order-btn"
                  customClass="customClass"
                />
                    </div>
                    {data?.is_feedback_submitted === false ? (
                      <Button
                        className="feedbackCard_btn"
                        onClick={() =>
                          CartShow(
                            data?.outlet_id,
                            data?.delivery_type,
                            data?._id,
                            data?.cart_items[0].name
                          )
                        }
                        content="Rate now"
                        customClass="feedbackCard_btn_content"
                      />
                    ) : (
                      <h3 className="feedbackCard_submitted">Submitted!</h3>
                    )}
                  </div>
                </div>
              </>
            )
          ) : (
            <>
              {data?.is_cancelled === false && (
                <div className="feedbackCard">
                  <img src={deliveryImg} className="feedbackCard_img" alt="deliveryImg"/>
                  <div className="feedbackCard_division">
                    {data &&
                      data.cart_items &&
                      data.cart_items.length > 0 &&
                      data.cart_items.map((item) => {
                        return (
                          <span className="feedbackCard_division_nameLabel">
                            {item.name}
                          </span>
                        );
                      })}
                    <p className="feedbackCard_division_subLabel">
                      {moment(data?.order_created_at).format("D, MMMM YY , ")}
                      {createdTime}{" "}
                    </p>
                    <p className="feedbackCard_division_subLabel">
                        Order id : {data.divum_order_id}
                    </p>
                    <Button
                  content="View Order"
                  onClick={() => routeToTrack()}
                  className="feedbackCard_view-order-btn"
                  customClass="customClass"
                />
                  </div>
                  {data?.is_feedback_submitted === false ? (
                    <Button
                      className="feedbackCard_btn"
                      onClick={() =>
                        CartShow(
                          data?.outlet_id,
                          data?.delivery_type,
                          data?._id
                        )
                      }
                      content="Rate now"
                      customClass="feedbackCard_btn_content"
                    />
                  ) : (
                    <h3 className="feedbackCard_submitted">Submitted!</h3>
                  )}
                
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <p className="feedbackCard_noOrders">
          You have no-orders to give feedback. Please Go and Order something :)
        </p>
      )}
    </>
  );
};
export default FeedbackCard;
