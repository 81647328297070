import React from 'react';

const ThanksContent = ({ type }) => {
    return (
        type === 'giftcard' ? (
            <div className='contactContent'>
                <span className='contactDesc'>We have sent the gift card details on the email ID provided.</span>
                <span className='contactText'>Call us at <b>1800-212-2-212</b> for any assistance</span>
            </div>
        ) : ""
    );
};

export default ThanksContent;
