import React, { useState } from "react";
import "./index.scss";
import google from "../../assets/images/google-color.svg";
import facebook from "../../assets/images/facebook-round-color.svg";
import button from "../../components/button";
import Button from "../../components/button";
import Input from "../../components/input";
import { NameValidation } from "../../utils/validation";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";

const CreateAccount = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [change, setChange] = useState();

  const { isLoginBtnLoading, onNav, getMobileData, errorTxt1, onValidate, isSignIn, setIsSignIn, setErrorTxt1 } =
    props;
  const [mobile, setMobile] = useState("");
  const onLoginClick = () => {
    if (location.pathname === ROUTES.SIGNIN || isSignIn === true) {
      if (innerWidth <= 1023) {
        history.push(ROUTES.LOGIN);
      } else {
        setIsSignIn(false);
        setErrorTxt1('')
        setMobile('')
      }
    } else {
      if (innerWidth <= 1023) {
        history.push(ROUTES.SIGNIN);
      } else {
        setIsSignIn(true);
        setErrorTxt1('')
        setMobile('')
      }
    }
  };

  const onSigninClick = () => {
    history.push(ROUTES.SIGNIN);
  };

  const validation = () => {
     
    if (mobile === "" || mobile.length < 10 || mobile.length > 10) {
       
      onValidate("Please Enter a valid mobile number");
    } else {
       
      // onNav('');
      getMobileData(mobile);
    }
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      validation();
    }
  };
  const { innerWidth } = window;

  return (
    <div className="createAccountWrapper">
      <p className="createAccountWrapper_hdn">
        {location.pathname === ROUTES.LOGIN && innerWidth <= 1023
          ? "Login"
          : location.pathname === ROUTES.LOGIN ||
            (isSignIn === false && innerWidth >= 1024)
          ? "Welcome back !"
          : ""}
        {location.pathname === ROUTES.SIGNIN && innerWidth <= 1023
          ? "Create a new account"
          : location.pathname === ROUTES.SIGNIN ||
            (isSignIn === true && innerWidth >= 1024)
          ? "I want to create new account"
          : ""}
      </p>
      {/* <div className='createAccountWrapper__accountWrapper'>
  };
  return (
    <div className='createAccountWrapper'>
      <p className='createAccountWrapper_hdn'>
        {location.pathname === ROUTES.LOGIN && 'Login'}
        {location.pathname === ROUTES.SIGNIN && 'Create a new account'}
      </p>
      {/* <div className='createAccountWrapper__accountWrapper'>
                <div className='createAccountWrapper__accountWrapper_signinWrapper'>
                    <img src={google} className='img' width='24' height='24' />
                    <p className='imgLabel'>Google</p>
                </div>
                <div className='createAccountWrapper__accountWrapper_signinWrapper'>
                    <img src={facebook} className='img' width='24' height='24' />
                    <p className='imgLabel'>Facebook</p>
                </div>
            </div> */}

      {/* <div className='createAccountWrapper_divideWrapper'>
                <div className='createAccountWrapper_divideWrapper_divider' />
                <p className='createAccountWrapper_divideWrapper_label'>Or</p>
                <div className='createAccountWrapper_divideWrapper_divider' />
            </div> */}

      <div className="createAccountWrapper_mobileWrapper">
        <p className="createAccountWrapper_mobileWrapper_labelName">
          {(innerWidth <= 1023 && location.pathname === ROUTES.LOGIN) ||
          isSignIn === false
            ? "Please enter your mobile number"
            : "Enter your mobile number"}
        </p>
        {
          innerWidth < 1023 ?
          <Input
          type="number"
          onChange={(id, value) => {
            setChange(true);
            setMobile(value);
          }}
          value={mobile}
          // onClick={innerWidth <= 1023 && 'createAccountWrapper_mobileWrapper_mobileNumber'}
          isMobileNumber
          autoComplete="true"
          onKeyPress={(e) => handleKeypress(e)}
          autoFocus
          customClass="createAccountWrapper_mobileWrapper_inpTag"
          placeholder={
            innerWidth <= 1023 ? "Enter mobile number" : "Enter here"
          }
        />
        :
        <Input
          type="text"
          onChange={(id, value) => {
            setChange(true);
            setMobile(value);
          }}
          value={mobile}
          // onClick={innerWidth <= 1023 && 'createAccountWrapper_mobileWrapper_mobileNumber'}
          isMobileNumber
          autoComplete="true"
          onKeyPress={(e) => handleKeypress(e)}
          autoFocus
          customClass="createAccountWrapper_mobileWrapper_inpTag"
          placeholder={
            innerWidth <= 1023 ? "Enter mobile number" : "Enter here"
          }
        />
        }
        {/* <Input
          type="number"
          onChange={(id, value) => {
            setChange(true);
            setMobile(value);
          }}
          value={mobile}
          // onClick={innerWidth <= 1023 && 'createAccountWrapper_mobileWrapper_mobileNumber'}
          isMobileNumber
          autoComplete="true"
          onKeyPress={(e) => handleKeypress(e)}
          autoFocus
          customClass="createAccountWrapper_mobileWrapper_inpTag"
          placeholder={
            innerWidth <= 1023 ? "Enter mobile number" : "Enter here"
          }
        /> */}
        {innerWidth <= 1023 && (
          <p className={"createAccountWrapper_errorTxt"}>{errorTxt1}</p>
        )}
        <Button
          content={location.pathname === ROUTES.LOGIN ? "Login" : "Next"}
          onClick={mobile === "" || mobile.length < 10 || mobile.length > 10 ? "" : () => validation()}
          disabled={mobile === "" && true || isLoginBtnLoading}
          autoFocus
          className={`${
            mobile === "" || mobile.length < 10 || mobile.length > 10
              ? "createAccountWrapper_mobileWrapper_nxtBtnDisabled"
              : "createAccountWrapper_mobileWrapper_nxtBtn"
          }`}
          customClass={"customclass"}
        />
      </div>

      {/* {location.pathname === ROUTES.SIGNIN && (
        <div className="createAccountWrapper_divider" />
      )} */}
      {((innerWidth >= 1024 && location.pathname === ROUTES.SIGNIN) ||
        isSignIn === true ||
        isSignIn === false) && (
        <h4 className={"createAccountWrapper_errorTxt"}>
          {errorTxt1 !== "" &&
            isSignIn === false ?
            errorTxt1 !== false && errorTxt1 + (errorTxt1 !== '' ? " Please Signup." : '')
            :
            errorTxt1 !== false && errorTxt1 + (errorTxt1 !== '' ? " Please Login." : '')
          }
        </h4>
      )}
      {/* {errorTxt1 !== '' && (
        <p
          
          className='createAccountWrapper_errorhandle'
        >
          Sign In
        </p>
      )} */}
      {/* <div className="createAccountWrapper_wrapper">
        <p className="createAccountWrapper_wrapper_light">
          {location.pathname === ROUTES.SIGNIN || isSignIn === true
            ? "Already have an account?"
            : "Don't have an account?"}
        </p>
        <p
          className="createAccountWrapper_wrapper_dark"
          onClick={() => onLoginClick()}
        >
          {location.pathname === ROUTES.SIGNIN || isSignIn === true
            ? "Login"
            : "Sign up"}
        </p>
      </div> */}
    </div>
  );
};
export default CreateAccount;
