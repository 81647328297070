import { fetchCall } from "../../utils/ajax";
import { API_CONSTANTS, API_METHODS } from "../../constants/api-constants";

export const getWalletOffer = (payload, callback) => {
  fetchCall(API_CONSTANTS.GET_WALLET_OFFER, API_METHODS.GET, "", callback);
};
export const viewWalletBalance = (callback) => {
  fetchCall(API_CONSTANTS.VIEW_WALLET_BALANCE, API_METHODS.GET, "", callback);
};

export const termsAndConditionsWallet = (callback) => {
  fetchCall(
    API_CONSTANTS.TERMS_AND_CONDITIONS_WALLET,
    API_METHODS.GET,
    "",
    callback
  );
};

export const walletApplyOffer = (payload, callback) => {
  fetchCall(
    API_CONSTANTS.WALLET_APPLY_OFFER,
    API_METHODS.POST,
    payload,
    callback
  );
};

export const addWallet = (payload, callback) => {
  fetchCall(
    API_CONSTANTS.ADD_WALLET_MONEY,
    API_METHODS.POST,
    payload,
    callback
  );
};

export const showHistory = (callback) => {
  fetchCall(API_CONSTANTS.SHOW_WALLET_HISTORY, API_METHODS.GET, "", callback);
};
