import React, { useState } from "react";
import Button from "../button";
import ClockImg from "../../assets/images/deliverytimeIcon.svg";
import DeliveryImg from "../../assets/images/deliveryIcon.svg";
import location from "../../assets/images/addresslocation.svg";
import back from "../../assets/images/goback.svg";
import "./index.scss";
import Modal from "../modal";
import Option from "../option";
import AddAddress from "../../containers/add-address";
import { LocalStorage } from "../../utils/storage";
import { useHistory } from "react-router-dom";
import moment from "moment";

const CheckoutLocation = (props) => {
  const {
    logStatus,
    img,
    locHeader,
    loc2,
    deliveryText,
    address,
    reach,
    alternateNumber,
    submit,
    setAddress,
    setReach,
    othersTxt,
    setOthersTxt,
    setAlternateNumber,
    addAddress,
    modalOpen,
    addressChange,
    customClassName,
    loggedIn,
    onCheckoutClick,
    customPopUp,
    address_headerSection,
    address_bodySection,
    address_confirmButton,
    handleClick,
    close,
    isHideOrderType,
    selectedAddress,
    dontShowAddress,
    data
  } = props;
  console.log("location address",loc2)
  let locationDetails = LocalStorage.get("location");
  const { innerWidth } = window;
  const history = useHistory();
  const handleGoBack = () => history.goBack();

  const handleConvertTime=(time)=>{
    const formatedTime = moment(time, 'HH:mm').format('hh A')
    return formatedTime; 
  }

  const handleAddress = () => {
    //  debugger;
    console.log("selectedadreess", selectedAddress);
    console.log("dontShowAddress", dontShowAddress);

    let formateAddress = '';
    let addressType =""
    if (selectedAddress) {
       
      if (selectedAddress.AddressType) {
        addressType += `${selectedAddress.AddressType}, `;
      }
      if (selectedAddress.Address1) {
        formateAddress += `${selectedAddress.Address1}`;
      }
      
    }
    if (address && !dontShowAddress) {
      formateAddress += dontShowAddress ? '': address;
    }
     else {
      formateAddress += dontShowAddress ? "" : loc2;
    }

    return (
      <div>
        <p className='location_info_address-type'>{addressType}</p>
        <p className='location_info_address'>{formateAddress}</p>
      </div>
    );
  };

  console.log("location", data);
  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div
            className={`${
              customClassName
                ? `checkout_headerSection`
                : `location_headerSection`
            } ${address_headerSection && `address_headerSection`}`}
          >
            <img
              src={back}
              className={`${
                customClassName ? `checkout_backImg` : `location_backImg`
              }`}
              onClick={() => handleGoBack()}
              alt="backImg"
            />
            <p
              className={`${
                customClassName
                  ? `checkout_HeaderPhrase`
                  : `location_HeaderPhrase`
              }`}
            >
              Enter address detail
            </p>
          </div>
          <div className={`${customClassName} address_location`}>
            <div
              className={`${
                customClassName
                  ? `checkout_bodySection`
                  : `location_bodySection`
              } ${address_bodySection}`}
            >
              {!customPopUp && (
                <div
                  className={`${
                    customClassName ? `checkout_img` : `location_img`
                  }`}
                >
                  <img
                    src={location}
                    alt="locationImg"
                    className={`${
                      customClassName ? `checkoutimage` : `locationimage`
                    }`}
                  />
                </div>
              )}
              <div className="checkout_location_wholeWrapper">
                <div
                  className={`${
                    customClassName ? `checkout_location` : `location`
                  }`}
                >
                  <div
                    className={`${
                      customClassName ? `checkout_data` : `location_data`
                    }`}
                  >
                    <div
                      className={`${
                        customClassName ? `checkout_div` : `location_div`
                      }`}
                    >
                      <div
                        className={`${
                          customClassName
                            ? `checkout_header`
                            : `location_header`
                        }`}
                      >
                        <p>{locHeader}</p>
                      </div>
                      <div
                        className={`${
                          customClassName ? `checkout_info` : `location_info`
                        }`}
                      >
                        <p
                          className={`${
                            customClassName
                              ? `checkout_address`
                              : `location_address`
                          }`}
                        >
                          {/* {address !== "" && address + ", "}  */}
                          {loc2}
                        </p>
                      </div>
                    </div>
                  </div>

                  {!customPopUp && (
                    <div
                      className={`${
                        customClassName
                          ? `checkout_delivery`
                          : `location_delivery`
                      }`}
                    >
                      <div
                        className={`${
                          customClassName ? `checkout_order` : `location_order`
                        }`}
                      >
                        <img
                          src={ClockImg}
                          width="14px"
                          height="14px"
                          alt="clock"
                          className={`${
                            customClassName
                              ? `checkout_order_clock`
                              : `location_order_clock`
                          }`}
                        />
                        <Option
                          present
                          selectedOption={
                            locationDetails && locationDetails.orderTime
                          }
                        />
                      </div>
                      {locationDetails &&
                        locationDetails.orderFor === "delivery" && (
                          <div
                            className={`${
                              customClassName
                                ? `checkout_time`
                                : `location_time`
                            }`}
                          >
                            <img
                              src={DeliveryImg}
                              width="14px"
                              height="14px"
                              alt="deliveryIcon"
                              className={`${
                                customClassName
                                  ? `checkout_time_time1`
                                  : `location_time_time1`
                              }`}
                            />
                            <p
                              className={`${
                                customClassName
                                  ? `checkout_time_text`
                                  : `location_time_text`
                              }`}
                            >
                      <h5 className="location_time_text">50-60 min delivery</h5>
                            </p>
                          </div>
                        )}
                    </div>
                  )}
                </div>
                {!customPopUp && (
                  <div>
                    <div
                      className={`${
                        customClassName
                          ? `checkout_button1`
                          : `location_button1`
                      }`}
                    >
                      <Button
                        className={`${
                          customClassName
                            ? `checkout_button`
                            : `location_button`
                        } ${logStatus && `location_disab`}`}
                        content="Change"
                        onClick={addAddress}
                        disabled={logStatus}
                        customClass={`${
                          customClassName
                            ? `checkoutCustomClass`
                            : `locationCustomClass`
                        }`}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Button
              content={
                loggedIn
                  ? address !== ""
                    ? "Checkout"
                    : "Confirm address"
                  : "Login to proceed checkout"
              }
              className={`checkout-page_side2_submit ${address_confirmButton}`}
              customClass={`${
                customClassName
                  ? `checkout_bottom_button`
                  : `location_bottom_button`
              }`}
              onClick={
                address !== ""
                  ? () => onCheckoutClick(loggedIn)
                  : () => addAddress()
              }
            />
          </div>
        </>
      ) : (
        <div className="location_wholeWrapper">
          <div className="location">
            <div className="location_data">
              <div className="location_img">
                <img src={img} alt="locationImg" className={customClassName} />
              </div>
              <div className="location_div">
                <div className="location_header">
                  <p>{locHeader}</p>
                </div>
                <div className="location_info">
                  <p>
                    {console.log(loc2,"loc2")}
                    {loc2?.formatted_address ? loc2.formatted_address : handleAddress() } 
                    {/* {address ? address + ", " : ""}
                     {loc2} */}
                    
                  </p>
                </div>
              </div>
            </div>
             {!isHideOrderType && <>
            {locationDetails && locationDetails.orderTime === "orderNow" ? (
              <>
                <div className="location_delivery">
                  <div className="location_order">
                    <img
                      src={ClockImg}
                      width="14px"
                      height="14px"
                      alt="clock"
                      className="location_order_clock"
                    />
                    <Option
                      present
                      selectedOption={
                        locationDetails && locationDetails.orderTime
                      }
                      customClassName="location_option"
                    />
                  </div>
                  {locationDetails && locationDetails.orderFor === "delivery" && (
                    <div className="location_time">
                      <img
                        src={DeliveryImg}
                        width="14px"
                        height="14px"
                        alt="deliveryIcon"
                        className="location_time_time1"
                      />
                      <p className="location_time_text">{deliveryText}</p>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="location_delivery">
                  <div className="location_order">
                    <img
                      src={ClockImg}
                      width="14px"
                      height="14px"
                      alt="clock"
                      className="location_order_clock"
                    />
                    <Option
                      present
                      selectedOption={
                        locationDetails && locationDetails.orderTime
                      }
                      customClassName="location_option"
                    />
                  </div>
                  <p className="rightSide_phrase">
                    {moment(locationDetails.date).format("MMMM Do, ") +
                      handleConvertTime(locationDetails.time)}
                  </p>
                </div>
              </>
            )}
            </>}
          </div>
          <div>
            <div className="location_button1">
              <Button
                className={`location_button ${logStatus && "location_disab"}`}
                content="Change"
                onClick={addAddress}
                disabled={logStatus}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutLocation;
