import { displayRazorpay } from "../../utils/payment";
import { paymentSuccess } from "../../actions/cart";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";

export const makeOnlinePayment = (res) => {
  return new Promise((resolve, reject) => {
    let content = res?.content;

    const option = {
      key: res.content.payment_info.key,
      amount: res.content.payment_info.amount,
      currency: "INR",
      name: res.content.payment_info.name,
      description: res.content.payment_info.description,
      image: { BBK },
      order_id: res.content.payment_info.order_id,
      prefill: res.content.payment_info.prefill,
      notes: res.content.payment_info.notes,
      retry: { enabled: false },
      features: {
        wallet_on_checkout: false
      },
      handler: (response) => {
        const payload = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };
        paymentSuccess(payload, (res) => {
          if (res.response.status === 200) {
            resolve(res);
          } else {
            reject(new Error("Payment failed"));
          }
        });
      },
    };

    displayRazorpay(option, (res) => {
      console.log("Res same", JSON.stringify(res));
    });
  });
};
