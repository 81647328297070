import axios from 'axios';
import BBK from '../assets/images/Biryani-by-Kilo.jpg';

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function displayRazorpay(option, onFailure) {
  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

  if (!res) {
    alert('Razorpay SDK failed to load. Are you online?');
    return;
  }

  if (!option) {
    alert('Server error. Are you online?');
    return;
  }

  const options = option;

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
  paymentObject.on('payment.failed', function (response) {
    paymentObject.close();
    onFailure(response);
  });
}
