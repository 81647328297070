import React from 'react';

import ErrImg from '../../assets/images/ErrorImage.png';
import Button from '../button';

import './index.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.logErrorToMyService(error, errorInfo);
  }

  logErrorToMyService = (error) => {
    // console.log(error);
  };

  handleNavigareToHome = () => {
    window.location.href = '/';
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className='errBoundary'>
          <img
            className='errBoundary__img'
            src={ErrImg}
            alt='Something went wrong'
          />
          <div className='errBoundary__btn-wrapper'>
            <Button
              className='addButton'
              content={'Home'}
              onClick={() => this.handleNavigareToHome()}
            />
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
