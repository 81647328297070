import React from 'react';
import moment from 'moment';

import './index.scss';

const StepProcess = (props) => {
  const { data, time } = props;
console.log("current_orders steps",data)
  let isCancelled = false;
  return (
    <div className='step'>
      {data.map((item,itemNo) => {
        {console.log(item,"step")}
        if(item.id === "order_cancelled"){
          isCancelled = true;
        }
        return (
          
          <div
          className={`step_item ${item.textGreyedOut ? 'text-greyed-out' : ''} `}
          >
           { console.log(item.id,"item check")}
            <div className='customcb'>
              <input
                type='checkbox'
                className='inp'
                checked={item.checked}
                id={item._id}
                value={item.display_name}
                readOnly
              />
              <label for={item._id} className='inner' />
              { data.length - 1 !== itemNo && 
                  <div className='line' />}
            </div>
            <div className='step_item_data'>
            {/* className={`${item.display_name === 'Order Cancelled' ? 'inner_red-text' : (item.display_name === 'Order Delivered' ? 'inner_green-text' : '')}` */}
              {/* <p className={`step_item_data_nameLabel${item.textRed  ? '_textRed' : (item.display_name === 'Order Delivered' ? 'textGreen' : '')}`}>{item.display_name}</p> */}
              <p className= {`step_item_data_nameLabel ${((item.id === "order_cancelled" || item.id === "order_delayed") && item.checked) && "canceled-text"} ${(item.id === "order_delivered" && item.checked) && "success-text"} ${(item.id !== "order_cancelled" && isCancelled) && "opacity-50-percent"}`}>{item.display_name}</p>
              <p className='step_item_data_subLabel'>
                {item.time && 
                  item.id !== 'order_arriving' &&
              moment(item.time).format("h:mm a")}</p>
              {/* <p className='step_item_data_status'>{item.checked && item.id}</p> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default StepProcess;
