import { data } from "browserslist";
import React from "react";
import "./index.scss";

const Switch = (props) => {
  const { data, active, onFilter, customClass, customClassName } = props;
  const { innerWidth } = window;
  return (
    <div className={`switch ${customClassName}`}>
      {data.map((item) => {
        return (
          <>
            {innerWidth <= 1023 && (
              <div
                className={`switch_wholeWrapper ${
                  item.id === active && "active"
                } ${customClass}`}
                onClick={() => onFilter(item)}
              >
                <div
                  className={`switch_card ${
                    item.id === active ? "active" : "nonActive"
                  }`}
                  onClick={() => onFilter(item)}
                >
                  {item.name}
                </div>
              </div>
            )}
            {innerWidth >= 1024 && (
              <div
                className={`switch_card ${item.id === active && "active"}`}
                onClick={() => onFilter(item)}
              >
                {item.name}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};
export default Switch;
