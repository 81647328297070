import React from "react";
import PropTypes from "prop-types";
import home from "../../assets/images/homedark.svg";
import work from "../../assets/images/work.svg";
import homeOthers from "../../assets/images/homeOthers.svg";
import edit from "../../assets/images/editdark.svg";
import deleteicon from "../../assets/images/delete.svg";
import "./index.scss";

const AddressCard = ({
  address,
  handleSelectAddress,
  selectedAddress,
  handleEditAddress,
  deleteCard,
  deliveryAddress,
  handleNotdeliverableAddress,
}) => {
  // debugger;
  const { innerWidth } = window;
  if (innerWidth <= 1023 && deliveryAddress && address.length === 0) {
    return (
      <p className="addresserror">
        <span className="addresserror_text">No address found.</span>
        <span>Please click above to add your address for delivery</span>
      </p>
    );
  }

  const hanldeAddresTypeImg = (type) => {
    let img = "";
    switch (type) {
      case "Home":
        return (img = home);
      case "Work":
        return (img = work);
      default:
        return (img = homeOthers);
    }
  };

  const handleAddressClassName = (item) => {
    let clsName = "address-card__list__container";
    if (!item.is_available) {
      clsName += " address-card__disabled";
    }

    if (selectedAddress && selectedAddress?.AddressId) {
      if (item?.AddressId === selectedAddress?.AddressId) {
        clsName += " address-card__active";
      }
    }
    if (selectedAddress && selectedAddress?.is_local_address) {
      if (item?.id === selectedAddress?.id) {
        clsName += " address-card__active";
      }
    }
    return clsName;
  };

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="address-card">
            {address &&
              address.map(
                (item) =>
                
                  item.Address1 && (
                   
                    <div className={`${handleAddressClassName(item)}`}>
                       
                      <div
                        className="address-card__address"
                        onClick={() =>
                          deliveryAddress ? handleSelectAddress(item) :
                          item.is_available
                            ? handleSelectAddress(item)
                            : handleNotdeliverableAddress(item)
                        }
                        
                      >
                        <div className="address-card__address__img-wrapper">
                          <img
                            src={hanldeAddresTypeImg(item.AddressType)}
                            alt="Address"
                            className="address-card__address__img-type"
                          />
                        </div>
                        <div>
                          <p className="address-card__address__address-type">
                            {item.AddressType}
                          </p>
                          <p className="address-card__address__location">
                            {`${item.Address1 ? `${item.Address1},` : ""}
                   ${
                     item?.Google_Address && item?.Google_Address !== "N/A"
                       ? item.Google_Address
                       : item.Google_Address
                       ? item.Google_Address
                       : ""
                   }`}
                          </p>
                        </div>
                      </div>
                      <div className="address-card__editContainer">
                      <img
                        onClick={() => handleEditAddress(item)}
                        src={edit}
                        alt="edit"
                        className="address-card__edit"
                      />
                      <img
                        onClick={() => deleteCard(item)}
                        src={deleteicon}
                        alt="edit"
                        className="address-card__edit"
                      />
                      </div>
                    </div>
                  )
              )}
          </div>
        </>
      ) : (
        <>
        <div className="address-card">
            {address &&
              address.map(
                (item) =>
                  item.Address1 && (
                    <div className={`${handleAddressClassName(item)}`}>
                      <div
                        className="address-card__address"
                        onClick={() =>
                          item.is_available
                            ? handleSelectAddress(item)
                            : handleNotdeliverableAddress(item)
                        }
                      >
                        <div className="address-card__address__img-wrapper">
                          <img
                            src={hanldeAddresTypeImg(item.AddressType)}
                            alt="Address"
                            className="address-card__address__img-type"
                          />
                        </div>
                        <div>
                          <p className="address-card__address__address-type">
                            {item.AddressType}
                          </p>
                          <p className="address-card__address__location">
                            {`${item.Address1 ? `${item.Address1},` : ""}
                   ${
                     item?.Google_Address && item?.Google_Address !== "N/A"
                       ? item.Google_Address
                       : item.Google_Address
                       ? item.Google_Address
                       : ""
                   }`}
                          </p>
                        </div>
                      </div>
                      {/* <img
                        onClick={() => handleEditAddress(item)}
                        src={edit}
                        alt="edit"
                        className="address-card__edit"
                      />
                      <img
                        onClick={() => deleteCard(item)}
                        src={deleteicon}
                        alt="edit"
                        className="address-card__edit"
                      /> */}
                    </div>
                  )
              )}
          </div></>
      )}
    </>
  );
};

AddressCard.propTypes = {
  address: PropTypes.array,
  handleEditAddress: PropTypes.func,
  handleSelectAddress: PropTypes.func,
};

AddressCard.defaultProps = {
  address: [],
  handleEditAddress: () => {},
  handleSelectAddress: () => {},
};

export default AddressCard;
