import React, { useState, useEffect } from "react";
import "./index.scss";
import Modal from "../../components/modal";
import VoucherModalCard from "../../components/voucherModalCard";
import SenderDetails from "../../components/senderDetails";
import Button from "../../components/button";
import PaymentMethod from "../../components/payment-method";
import Close from "../../assets/images/close.svg";
import BBK from "../../assets/images/Biryani-by-Kilo.jpg";
import { applyGiftCard } from "../../actions/giftcards";
import { paymentSuccess } from "../../actions/cart";
import { displayRazorpay } from "../../utils/payment";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/app-constants";
import { LocalStorage, SessionStorage } from "../../utils/storage";
// import AmountSummary from "../amount-summary";
import AlertPopup from "../../components/alert-popup";
import AmountSummary from "../../components/amount-summary";
import { regex } from "../../constants/regex";

const billDatae = [
  { name: "Item total", amount: "₹ 0", show: true },
  { name: "Tax", amount: "₹ 0", show: true },
  { name: "Discount", amount: "₹ 0", show: true },
  { name: "Total Amount", amount: "₹ 0", show: true },
];

const GiftVoucherModal = (props) => {
  const {
    data,
    onUpdateContent,
    tax,
    onAddRecieve,
    recieverData,
    onClose,
    message,
    setFailurePay,
    onDefaultRecieve,
  } = props;

  const history = useHistory();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [nameAlert, setNameAlert] = useState("");
  const [mobileAlert, setMobileAlert] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [alert, setAlert] = useState("");
  const [item, setItem] = useState(data);
  const [billData, setBillData] = useState(billDatae);
  const [payMode, setPaymentMode] = useState("");

  useEffect(() => {
    if (SessionStorage.get("sender_details")) {
      const data = SessionStorage.get("sender_details");
      if (data && data.name) {
        setName(data.name);
        setMobile(data.mobile);
        setEmail(data.email);
      }
    }
  }, []);

  useEffect(() => {
    let cgst = 0;
    let sgst = 0;
    let totalPrice = 0;
    let offer =
      Number(
        parseFloat(
          item.count * Number(parseFloat(item.total_card_amount).toFixed(2))
        ).toFixed(2)
      ) -
      Number(
        parseFloat(
          item.count *
            Number(parseFloat(item.offer_amount_to_be_paid).toFixed(2))
        ).toFixed(2)
      );
    const outletData = LocalStorage.get("Outlet_Details");
    tax.map((items) => {
      if (items.tax_id === "1") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        cgst = (price * Number(items.tax_value)) / 100;
      }
      if (items.tax_id === "2") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        sgst = (price * Number(items.tax_value)) / 100;
      }
    });

    const billDatae = [
      {
        name: "Item total",
        amount: `₹ ${Number(
          parseFloat(
            item.count * Number(parseFloat(item.total_card_amount).toFixed(2))
          ).toFixed(2)
        )}`,
        show: true,
      },
      { name: "Tax", amount: `₹ ${cgst + sgst}`, show: true },
      {
        name: "Discount",
        amount: `₹ ${offer}`,
        show: true,
      },
      {
        name: "Total Amount",
        amount: `₹ ${
          Number(
            parseFloat(
              item.count *
                Number(parseFloat(item.offer_amount_to_be_paid).toFixed(2))
            ).toFixed(2)
          ) +
          cgst +
          sgst
        }`,
        show: true,
      },
    ];
    setBillData(billDatae);
  }, []);

  const SubCount = (itm, value) => {
    let cgst = 0;
    let sgst = 0;
    let totalPrice = 0;
    let offer =
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
        ).toFixed(2)
      ) -
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.offer_amount_to_be_paid).toFixed(2))
        ).toFixed(2)
      );
    const outletData = LocalStorage.get("Outlet_Details");
    if (value === "add") {
      itm.count = itm.count + 1;
    } else {
      if (itm.count > 0) {
        itm.count = itm.count - 1;
      }
      Number(
        parseFloat(
          itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
        ).toFixed(2)
      );
    }
    setItem(itm);
    tax.map((items) => {
      if (items.tax_id === "1") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        cgst = (price * Number(items.tax_value)) / 100;
      }
      if (items.tax_id === "2") {
        const price =
          totalPrice +
          (outletData.delivery_area_delivery_charge
            ? Number(
                parseFloat(outletData.delivery_area_delivery_charge).toFixed(2)
              )
            : 0);
        sgst = (price * Number(items.tax_value)) / 100;
      }
    });

    const billDatae = [
      {
        name: "Item total",
        amount: `₹ ${Number(
          parseFloat(
            itm.count * Number(parseFloat(itm.total_card_amount).toFixed(2))
          ).toFixed(2)
        )}`,
        show: true,
      },
      { name: "Tax", amount: `₹ ${cgst + sgst}`, show: true },
      {
        name: "Discount",
        amount: `₹ ${offer}`,
        show: true,
      },
      {
        name: "Total Amount",
        amount: `₹ ${
          Number(
            parseFloat(
              itm.count *
                Number(parseFloat(itm.offer_amount_to_be_paid).toFixed(2))
            ).toFixed(2)
          ) +
          cgst +
          sgst
        }`,
        show: true,
      },
    ];

    setBillData(billDatae);
    onUpdateContent(itm);
  };

  const onPayClick = () => {
    if (
      name.length > 0 &&
      mobile.length > 0 &&
      email &&
      (recieverData &&
        recieverData.length > 0 || isChecked)
    ) {
      setNameAlert("");
      setMobileAlert("");
      setEmailAlert("");
      setAlert("");
      let receiverPayload = [{
        receiver_mobile: mobile,
        receiver_email: email,
        quantity: data.count,
        name: name,
      }]
      const payload = {
        sender: {
          receiver: isChecked ? receiverPayload : recieverData,
          message: message || "",
          gift_card_detail_id: data._id,
          sender_name: name,
        },
      };
      applyGiftCard(payload, (res) => {
        if (res.response.status === 200) {
          if (res.content && res.content.payment_info) {
            const option = {
              key: res.content.payment_info.key,
              amount: res.content.payment_info.amount,
              currency: "INR",
              name: res.content.payment_info.name,
              description: res.content.payment_info.description,
              image: { BBK },
              order_id: res.content.payment_info.order_id,
              handler: (response) => {
                const payload = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                };
                paymentSuccess(payload, (res) => {
                  if (res.response.status === 200) {
                    SessionStorage.remove("sender_details");
                    SessionStorage.set("gift", true);
                    history.push(ROUTES.ORDER_STATUS);
                  }
                });
              },
              prefill: res.content.payment_info.prefill,
              notes: res.content.payment_info.notes,
              retry: { enabled: false },
            };
            displayRazorpay(option, (res) => {
              if (res.error && res.error.reason === "payment_failed") {
                setFailurePay(true);
              }
            });
          }
        }
      });
    } else {
      if (name.length === 0) {
        setNameAlert("Please enter a sender name");
      }
      if (mobile.length === 0) {
        setMobileAlert("Please enter a sender mobile");
      }
      if (email.length === 0) {
        setEmailAlert("Please enter an email");
      }
      if (recieverData && recieverData.length === 0) {
        setAlert("Add atleast 1 reciever");
      }
    }
  };

  const onAddRecieveData = () => {
    SessionStorage.set("sender_details", {
      name: name,
      mobile: mobile,
      email: email,
    });
    onAddRecieve();
  };

  const onPaymentClick = (value) => {
    setPaymentMode(value);
  };

  const hadnleDisable = () => {
    let isDisbled = false;
    if (name.length === 0 || mobile.length === 0 || email.length === 0) {
      isDisbled = true;
    } else if (mobile.length > 0 && !regex.mobileRegex.test(mobile)) {
      isDisbled = true;
    } else if (email.length > 0 && !regex.emailRegex.test(email)) {
      isDisbled = true;
    } else {
      isDisbled = false;
    }
    return isDisbled;
  };

  const { innerWidth } = window;

  return (
    <>
      {innerWidth <= 1023 ? (
        <Modal hideClose contentCustomClass="giftVoucherModal_modal">
          <div className="giftVoucherModal_heading">
            <h2 className="giftVoucherModal_name">Cart</h2>
            <img
              src={Close}
              alt="close"
              width="20"
              height="20"
              className="giftVoucherModal_close"
              onClick={() => onClose()}
            />
          </div>
          <div className="giftVoucherModal">
            <div className="giftVoucherModal_right">
              <VoucherModalCard
                data={data}
                onUpdateContent={onUpdateContent}
                tax={tax}
              />
              <div className="giftVoucherModal_left">
                <p className="giftVoucherModal_senderDetails">Sender Details</p>
                <div className="giftVoucherModal_sender">
                  <SenderDetails
                    name={name}
                    mobile={mobile}
                    email={email}
                    isChecked={isChecked}
                    onChangeName={(value) => {
                      setName(value);
                      setNameAlert("");
                    }}
                    onChangeMobile={(value) => {
                      setMobile(value);
                      setMobileAlert("");
                    }}
                    onChangeEmail={(value) => {
                      setEmail(value);
                      setEmailAlert("");
                    }}
                    onChangeChecked={(value) => {
                      setIsChecked(value);
                    }}
                    nameAlert={nameAlert}
                    mobileAlert={mobileAlert}
                    emailAlert={emailAlert}
                  />
                </div>
                <h3 className="giftVoucherModal_senderDetails">
                  Reciever Details
                </h3>
                <div className="giftVoucherModal_receiver">
                  {recieverData &&
                    recieverData.length > 0 &&
                    recieverData.map((item) => {
                      return (
                        <div className="giftVoucherModal_descriptionContainer_foodContainer">
                          <p className="itemName">
                            {item?.name} ,{item?.receiver_mobile}
                          </p>
                          <span className="itemLabel">
                            Quantity: {item?.quantity}
                          </span>
                        </div>
                      );
                    })}
                  <Button
                    content="+ Add Reciever"
                    className="giftVoucherModal_reciever"
                    onClick={onAddRecieveData}
                    customClass="giftVoucherModal_customClass"
                  />
                  {alert && <p className="giftVoucherModal_alert">{alert}</p>}
                </div>
              </div>
              <AmountSummary
                billingDetails={billData}
                customClass="amountSummary_customClass"
              />
              <PaymentMethod
                noCheckout
                onPaymentClick={onPaymentClick}
                voucher={false}
                customClass="giftVoucherModal_payment"
              />
              <div className="giftVoucherModal_footer">
                <Button
                  content="Buy Now"
                  onClick={onPayClick}
                  className="giftVoucherModal_button"
                  disabled={hadnleDisable()}
                />
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal hideClose modalCustomClass="giftVoucherModal_modal">
          <div className="giftVoucherModal_heading">
            <p className="giftVoucherModal_name">Cart</p>
            <img
              src={Close}
              alt="close"
              width="20"
              height="20"
              className="giftVoucherModal_close"
              onClick={() => onClose()}
            />
          </div>
          <div className="giftVoucherModal">
            <div className="giftVoucherModal_left">
              <p className="giftVoucherModal_senderDetails">Sender Details</p>
              <div className="giftVoucherModal_sender">
                <SenderDetails
                  name={name}
                  mobile={mobile}
                  email={email}
                  isChecked={isChecked}
                  onChangeName={(value) => {
                    setName(value);
                    setNameAlert("");
                  }}
                  onChangeMobile={(value) => {
                    setMobile(value);
                    setMobileAlert("");
                  }}
                  onChangeEmail={(value) => {
                    setEmail(value);
                    setEmailAlert("");
                  }}
                  onChangeChecked={(value) => {
                    setIsChecked(value);
                  }}
                  nameAlert={nameAlert}
                  mobileAlert={mobileAlert}
                  emailAlert={emailAlert}
                />
              </div>
              <p className="giftVoucherModal_senderDetails">Reciever Details</p>
              <div className="giftVoucherModal_sender">
                {recieverData &&
                  recieverData.length > 0 &&
                  recieverData.map((item) => {
                    return (
                      <div className="giftVoucherModal_descriptionContainer_foodContainer">
                        <p className="itemName">
                          {item?.name} ,{item?.receiver_mobile}
                        </p>
                        <span className="itemLabel">
                          Quantity: {item?.quantity}
                        </span>
                      </div>
                    );
                  })}
                <Button
                  content="+ Add Receiver"
                  className="giftVoucherModal_reciever"
                  onClick={onAddRecieveData}
                />
                {alert && <p className="giftVoucherModal_alert">{alert}</p>}
              </div>
            </div>
            <div className="giftVoucherModal_right">
              <VoucherModalCard
                data={data}
                onUpdateContent={onUpdateContent}
                tax={tax}
              />
              <PaymentMethod noCheckout 
              onPaymentClick={onPaymentClick}
              />
              <div className="giftVoucherModal_footer">
                <Button
                  content="Buy Now"
                  onClick={onPayClick}
                  className="giftVoucherModal_button"
                  disabled={hadnleDisable()}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default GiftVoucherModal;
