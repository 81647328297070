import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';


export const sendOtp = (payload, callback) => {
    const url = `${API_CONSTANTS.SIGN_UP}`;
    return fetchCall(url, API_METHODS.POST, payload, callback);
  };

  export const verifyOtp = (payload, callback) => {
    const url = `${API_CONSTANTS.VERIFY_OTP}`;
    return fetchCall(url, API_METHODS.POST, payload, callback);
  };


  

  export const makeProfile = (payload, callback) => {
    const url = `${API_CONSTANTS.PROFILE}`;
    return fetchCall(url, API_METHODS.PUT, payload, callback);
  };
