import React from "react";
import PropTypes from "prop-types";
import Loader from "../../components/Loader";
import "./index.scss";
import DishImg from "../../assets/images/imli-adral-lemonade@3x.png";
import TrackOrderImg from "../../assets/images/icon-track.png";
import ItemCardNew from "../item-card-new";
import Button from "../button";
import moment from "moment";
import { ROUTES } from "../../constants/app-constants";
import { useHistory } from "react-router-dom";
import { LocalStorage } from "../../utils/storage";

const MultipleOrderCard = (props) => {
  const history = useHistory();
  const { newData, main } = props;
  console.log("multipleOrder", main);
  let orderCreated = main?.order_created_at;
  // let sTime = new Date(orderCreated);
  var currentTimeUTC = new Date(orderCreated);
  var currentTimeIST = moment(currentTimeUTC)
    .add(5, "hours")
    .add(30, "minutes");
  let createdTime = moment(currentTimeIST._d.toString()).format("h:mm a");
  const data = [
    {
      id: 1,
      img: DishImg,
      name: "Chicken Biryani X1",
      isVeg: false,
    },
    {
      id: 2,
      img: DishImg,
      name: "Veg Biryani Special X1",
      isVeg: true,
    },
  ];
  const routeToTrack = () => {
    localStorage.setItem("purchaseDetailId", main?._id);
    history.push(ROUTES.TRACK_ORDER);
    LocalStorage.set("EndKey", 2);
  };
  const { innerWidth } = window;
  let status;
  const handleStatus = (status) => {
    switch (status) {
      case "order_placed":
        return (status = "Order Placed");
      case "order_accepted":
        return (status = "Order Accepted");
      case "order_cancelled":
        return (status = "Order Cancelled");
      default:
    }
  };

  return (
    <>
      {innerWidth <= 1023 ? (
        <div className="multipleOrder">
          <div className="multipleOrder_main">
            {main &&
              main?.cart_items?.length > 0 &&
              main?.cart_items.map((item) => {
                //  
                status = item?.status;
                return <ItemCardNew data={item} />;
              })}
            <p className="multipleOrder_main_phrase">
              Status:{" "}
              <span className="multipleOrder_main_span">
                {status !== "" && handleStatus(status)}
              </span>
            </p>
            <div className="multipleOrder_idDiv">
              <h1 className="multipleOrder_idDiv_nameLabel">Order ID:  </h1>
              <p className="multipleOrder_idDiv_subLabel">
                {main?.divum_order_id +
                  " | " +
                  // moment(main?.created_at).format("Do MMM, h:mm a")
                  createdTime}
              </p>
            </div>
            <h2 className="multipleOrder_main_phrase">
              Paid mode:
              <span className="multipleOrder_main_span">
                {main?.mode_of_payment}
              </span>
            </h2>
            <div className="multipleOrder_main_one">
              <p className="multipleOrder_main_one_nameLabel">
                Total ₹{Number(parseFloat(main?.paid_amount).toFixed(2))}/-
              </p>
              <div className="multipleOrder_main_one_div">
                <img src={TrackOrderImg} className="multipleOrder_main_one_div_img" alt="track order img"/>
                <Button
                  content="Track Order"
                  onClick={() => routeToTrack()}
                  className="multipleOrder_main_btn"
                  customClass="multipleOrder_main_btn_content"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="multipleOrder">
          <div className="multipleOrder_idDiv">
            <h3 className="multipleOrder_idDiv_nameLabel">Order ID: </h3>
            <p className="multipleOrder_idDiv_subLabel">
              {main?.divum_order_id +
                " | " +
                // moment(main?.created_at).format("MMMM Do YYYY, h:mm a")
                createdTime}
              <span className="multipleOrder_idDiv_subLabel_OrderType">
                {main.is_preorder ? `${" | "} Pre Order` : ""}
              </span>
            </p>
          </div>
          <div className="multipleOrder_main">
            {main &&
              main?.cart_items?.length > 0 &&
              main?.cart_items.slice(0, 4).map((item) => {
                return <ItemCardNew data={item} />;
              })}
            <div className="multipleOrder_main_one">
              <p className="multipleOrder_main_one_nameLabel">
                Total ₹{Number(parseFloat(main?.paid_amount).toFixed(2))}/-
              </p>
            </div>
            <Button
              content="Track Order"
              onClick={() => routeToTrack()}
              className="multipleOrder_main_btn"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MultipleOrderCard;
