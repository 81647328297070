import React from "react";
import "./index.scss";

const ToggleSwitch = (props) => {
  const {
    onSwitch,
    data,
    className,
    isNotDelivery,
    noToggle,
    onSwitchBestSeller,
    bestSeller,
    distanceCategory,
    distanceCategoryOnClick,
    isDistanceCategory
  } = props;
  const { innerWidth } = window;
  console.log(data, "datataa", bestSeller);
  return (
    <div className={`switchWrap_data ${className}`}>
      {data.map((items, index) => {
        return (
          <div key={`${items?.name}${index}`} className="switchWrap">
            {!noToggle && (
              <label className="switch1">
                <input
                  type="checkbox"
                  onChange={(e) => onSwitch(e, items, "checkbox")}
                  checked={items.checked}
                />
                <span
                  className={`slider round ${
                    items.tag_name && items.tag_name === "Vegetarian"
                      ? "veg"
                      : "nonveg"
                  } ${items.name ? "name" : ""}`}
                ></span>
              </label>
            )}
            <p
              className={`switchLabel ${
                noToggle && items.tag_id && items.tag_id === "1"
                  ? items.checked
                    ? "switchWrapVeg_fill__veg"
                    : "switchWrapVeg_outline__veg"
                  : items.tag_id && items.tag_id === "2"
                  ? items.checked
                    ? "switchWrapVeg_fill__nonveg"
                    : "switchWrapVeg_outline__nonveg"
                  : items.checked
                  ? "switchWrapVeg_fill__bestseller"
                  : "switchWrapVeg_outline__bestseller"
              }`}
              
              onClick={
                noToggle
                  ? () => onSwitch(!items.checked, items, "label")
                  : () => {}
              }
            >
              {innerWidth <= 1023 ? (
                <>
                  {items.tag_name
                    ? items.tag_name === "Vegetarian"
                      ? "Veg"
                      : items.tag_name === "Non-vegetarian"
                      ? "Non-Veg"
                      : "Best Seller"
                    : `${items.name}`}
                </>
              ) : (
                <>
                  {items.tag_name
                    ? items.tag_name === "Vegetarian"
                      ? "Veg"
                      : items.tag_name === "Non-vegetarian"
                      ? "Non-Veg"
                      : "Best Seller"
                    : `${items.name}`}
                </>
              )}
            </p>
          </div>
        );
      })}
      {bestSeller &&
        bestSeller.length > 0 &&
        bestSeller.map((items, index) => {
          return (
            <div key={`${items?.name}${index}`} className="switchWrap">
              {!noToggle && (
                <label className="switch1">
                  <input
                    type="checkbox"
                    onChange={(e) => onSwitchBestSeller(e, items, "checkbox")}
                    checked={items.checked}
                  />
                  <span
                    className={`slider round ${
                      items.tag_name && items.tag_name === "Vegetarian"
                        ? "veg"
                        : "nonveg"
                    } ${items.name ? "name" : ""}`}
                  ></span>
                </label>
              )}
              <p
                className={`switchLabel ${
                  noToggle && items.tag_id && items.tag_id === "1"
                    ? items.checked
                      ? "switchWrapVeg_fill__veg"
                      : "switchWrapVeg_outline__veg"
                    : items.tag_id && items.tag_id === "2"
                    ? items.checked
                      ? "switchWrapVeg_fill__nonveg"
                      : "switchWrapVeg_outline__nonveg"
                    : items.checked
                    ? "switchWrapVeg_fill__bestseller"
                    : "switchWrapVeg_outline__bestseller"
                }`}
                onClick={
                  noToggle
                    ? () => onSwitchBestSeller(!items.checked, items, "label")
                    : () => {}
                }
              >
                {innerWidth <= 1023 ? (
                  <>
                    {items.tag_name
                      ? items.tag_name === "Vegetarian"
                        ? "Veg"
                        : items.tag_name === "Non-vegetarian"
                        ? "Non-Veg"
                        : "Best Seller"
                      : `${items.name}`}
                  </>
                ) : (
                  <>
                    {items.tag_name
                      ? items.tag_name === "Vegetarian"
                        ? "Veg"
                        : items.tag_name === "Non-vegetarian"
                        ? "Non-Veg"
                        : "Best Seller"
                      : `${items.name}`}
                  </>
                )}
              </p>
            </div>
          );
        })}
        {console.log("itemTags",">>",isDistanceCategory)}
        {
          isDistanceCategory && distanceCategory &&
          <div className={`distance_category_switch_wrapper ${distanceCategory.checked ? "checked":""}`} 
          onClick = {() => 
            distanceCategoryOnClick(!distanceCategory.checked,distanceCategory,"label")
          }>
            <p className="distance_category_text">{distanceCategory.tag_name}</p>
          </div>
        }
    </div>
  );
};

export default ToggleSwitch;
