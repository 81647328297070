import React from "react";
import "./index.scss";
import Location from "../../assets/images/location.svg";
import Timer from "../../assets/images/timer.svg";
import bbkLogo from "../../assets/images/whiteLogo.png";
import Button from "../../components/button";
import locationShort from "../../assets/images/locationShort.svg";
import moment from "moment";
const StoreCardProfile = (props) => {
  const { data, modalOpen } = props;
  const { innerWidth } = window;

  const handelGetParsedTime = (time) => {
    if (time) {
      return moment(time, 'HH:mm:ss').format('hh A')
    } else {
      return "-"
    }
  }

  return (
    <>
      {innerWidth <= 1023 ? (
        <>
          <div className="storeCardProfileMain">
            {data &&
              data.length > 0 &&
              data.map((item) => {
                return (
                  <div className="storeCardProfileMain_wholeDiv">
                    <div
                      className="storeCardProfile"
                    >
                      <img
                        onClick={() => modalOpen(item)}
                        src={bbkLogo}
                        className="storeCardProfile_img"
                        alt="Timer" />
                      <div
                        onClick={() => modalOpen(item)}
                        className="storeCardProfile_data">
                        <p className="storeCardProfile_data_nameLabel">
                          {item?.display_name}
                        </p>
                        <p className="storeCardProfile_data_address">
                          {item?.address}
                        </p>
                        <div className="storeCardProfile_data_two">
                          {item?.user_distance_in_km && <> <img
                            src={locationShort}
                            className="storeCardProfile_data_two_img"
                            alt="timer"
                          />
                            <p className="storeCardProfile_data_two_km">
                              {(item?.user_distance_in_km).toFixed(2)} km
                            </p></>}
                          <div className="storeCardProfile_data_two_bottomSection">
                            <div className="storeCardProfile_data_two_wholeWrapper">
                              <img
                                className="storeCardProfile_data_two_clockImg"
                                src={Timer}
                                alt="timer"
                              />
                              <p className="storeCardProfile_data_two_nameLabel">
                                {handelGetParsedTime(item?.outletdeliverytiming__start_time)}
                                {" "}
                                {handelGetParsedTime(item?.outletdeliverytiming__end_time)}
                              </p>
                            </div>
                            <p className="storeCardProfile_data_two_status">
                              {item?.is_outlet_open == 1 ? "Open" : "Closed"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="storeCardProfileMain_button">
                      <Button
                        content="Book a Table"
                        className="storeCardProfile_data_button"
                        customClass="storeCardProfile_data_button_content"
                        onClick={() =>
                          window.open(
                            "https://biryanibykilo.com/table-booking"
                          )
                        }

                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <>
          <div className="storeCardProfileMain">
            {data &&
              data.length > 0 &&
              data.map((item) => {
                return (
                  <div
                    className="storeCardProfile"
                  // onClick={() => modalOpen(item)}
                  >
                    <img
                      src={bbkLogo}
                      onClick={() => modalOpen(item)}
                      className="storeCardProfile_img"
                      alt="timer" />
                    <div
                      className="storeCardProfile_data">
                      <p
                        onClick={() => modalOpen(item)}
                        className="storeCardProfile_data_nameLabel">
                        {item?.display_name}
                      </p>
                      <div
                        onClick={() => modalOpen(item)}
                        className="storeCardProfile_data_one">
                        <img
                          className="storeCardProfile_data_one_img"
                          src={Timer}
                          alt="timer"
                        />
                        <p className="storeCardProfile_data_one_nameLabel">
                          {handelGetParsedTime(item?.outletdeliverytiming__start_time)}{" - "}
                          {handelGetParsedTime(item?.outletdeliverytiming__end_time)}

                        </p>
                      </div>
                      <div
                        onClick={() => modalOpen(item)}

                        className="storeCardProfile_data_two">
                        <img
                          src={locationShort}
                          className="storeCardProfile_data_two_img"
                          alt="timer"
                        />
                        {
                          item?.user_distance_in_km &&
                          <p className="storeCardProfile_data_two_km">
                            {(item?.user_distance_in_km).toFixed(2)} km

                          </p>
                        }

                        <p className="storeCardProfile_data_two_status">
                          {item?.is_outlet_open == 1 ? "Open" : "Closed"}
                        </p>
                      </div>
                      {
                        <Button
                          disabled={item.is_outlet_open > 0 ? false : true}
                          content="Book a Table"
                          className="storeCardProfile_data_button"
                          onClick={() =>
                            window.open(
                              "https://biryanibykilo.com/table-booking"
                            )
                          }
                        />
                      }

                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );
};
export default StoreCardProfile;
