import React from 'react';
import './index.scss';
import whatsappIcon from "../../assets/images/whatsapp .png";
import emailIcon from "../../assets/images/gmail.png";
const ShareButtons = ({ referalCode,refersdata }) => {
  console.log("came",refersdata)
  const { innerWidth } = window;
  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(refersdata[0]?.share_message + refersdata[0]?.share_link)}`;
    window.open(whatsappUrl, '_blank');
  };

  const shareViaGmail = () => {
    const subject = encodeURIComponent('Check out this Briyani by Kilo Referral Code');
    // const body = encodeURIComponent(`Hey! I just ordered fresh dum-cooked handi Biryani from Biryani by Kilo.\nUse my offer code ${encodeURIComponent(`${referalCode}`)} to get Rs.200 off on your first order! Download the app here --> https://biryanibykiloapp.page.link/refer`);
    const body = encodeURIComponent(refersdata[0]?.share_message + refersdata[0]?.share_link);
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${subject}&body=${body}`;
    

    window.open(gmailUrl, '_blank');
  };

  const shareViaMail = () => {
    const subject = encodeURIComponent('Check out this Briyani by Kilo Referral Code');
    const body = encodeURIComponent(refersdata[0]?.share_message + refersdata[0]?.share_link);
    const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;

    window.open(mailtoUrl, '_blank');
  };
  const shareViaTextMessage = () => {
    const smsText = `${referalCode}`;
    window.open(`sms:?&body=${encodeURIComponent(smsText)}`, '_blank');
  };

  return (
    <>
    {innerWidth <= 1023 ? (
    <div className="referandearn">
      <span className="refer-and-earn-img" onClick={shareOnWhatsApp}>
        <img src={whatsappIcon} alt="WhatsApp" />
      </span>
      <span className="refer-and-earn-img">
        <img src={emailIcon} alt="Email" onClick={shareViaMail} />
      </span>
    </div>
    ):(<div className="referandearn">
    <span className="refer-and-earn-img" onClick={shareOnWhatsApp}>
      <img src={whatsappIcon} alt="WhatsApp" />
    </span>
    <span className="refer-and-earn-img">
      <img src={emailIcon} alt="Email" onClick={shareViaGmail} />
    </span>
  </div>)}
  </>
  );
};
export default ShareButtons;