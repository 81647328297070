import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';

export const Authentication = (callback) => {
  fetchCall(API_CONSTANTS.TOKEN, API_METHODS.GET, '', callback);
};

export const Logout = (callback) => {
  fetchCall(API_CONSTANTS.LOGOUT, API_METHODS.POST, {}, callback);
};
