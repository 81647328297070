import React from "react";
import "./index.scss";
import { useHistory } from "react-router-dom";
import congrats from "../../assets/images/congrats.svg";
import { ROUTES } from "../../constants/app-constants";
// import Accept from '../../assets/images/acceptOrder.svg';

const Support = (props) => {
  const { data, img } = props;
  const history = useHistory();
  const routeIt = () => {
    if (data.name === "Need Support?") {
      history.push(ROUTES.CONTACTUS);
    }
  };

  return (
    <div className="support">
      <div className="support_data">
        <div className="support_data_main">
          <div className="support_data_main_one">
            <p className="support_data_main_one_name">{data.name}</p>
          </div>
        </div>
        <img
          src={img}
          className="support_data_message"
          onClick={() => routeIt()}
          alt="img"
        />
      </div>
    </div>
  );
};

export default Support;
