import { fetchCall } from '../../utils/ajax';
import { API_CONSTANTS, API_METHODS } from '../../constants/api-constants';

export const updateImg = (payload, callback) => {
  fetchCall(
    `${API_CONSTANTS.PROFILE_IMAGE}`,
    API_METHODS.POST,
    payload,
    callback
  );
};

export const sendProfileOtp = (payload, callback) => {
  const url = `${API_CONSTANTS.SEND_PROFILE_OTP}`;
  return fetchCall(url, API_METHODS.POST, payload, callback);
};


export const verifyProfileOtp = (payload, callback) => {
  const url = `${API_CONSTANTS.PROFILE}`;
  return fetchCall(url, API_METHODS.PUT, payload, callback);
};


export const getProfile = (callback) => {
  fetchCall(API_CONSTANTS.PROFILE, API_METHODS.GET, '', callback);
};

export const updateProfile = (payload, callback) => {
  fetchCall(API_CONSTANTS.PROFILE, API_METHODS.PUT, payload, callback);
}

export const v2GetProfile = (callback) => {
  fetchCall('https://web-backend.bbkbiryani.com/backend-app/authentication/api/v2/profile', API_METHODS.GET, '', callback)
}

export const getOTP = (callback) => {
  fetchCall(API_CONSTANTS.VERIFY_OTP_V1, API_METHODS.GET, "", callback)
}
export const getEmailVerification = (payload, callback) => {
  fetchCall(API_CONSTANTS.VERIFY_OTP_V1, API_METHODS.POST, payload, callback)
}